import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';

type TStepItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  label?: string;
  completed?: boolean;
  active?: boolean;
  disabled?: boolean;
  showDivider?: boolean;
  sequenceNumber?: number;
  value: string;
  dividerOrientation?: 'horizontal' | 'vertical';
  orientation?: 'horizontal' | 'vertical';
  onStepClick?: (value: string, event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const StepItem: React.FC<TStepItemProps> = (props) => {
  const {
    label,
    completed = false,
    active = false,
    disabled = false,
    sequenceNumber = 1,
    value,
    orientation = 'horizontal',
    dividerOrientation = 'horizontal',
    showDivider = false,
    className,
    onStepClick,
    ...rest
  } = props;

  return (
    <button
      type="button"
      disabled={disabled}
      className={cn('group inline-flex items-center gap-2 outline-none relative', {
        'flex-col': orientation === 'vertical',
        'pointer-events-none': disabled || active,
        'after:content-[""] after:absolute after:order-[-1]': showDivider,
        'after:h-[2px] after:w-1/2 after:bg-grey-200 after:top-4 after:start-3/4': showDivider && dividerOrientation === 'horizontal',
        'after:w-[2px] after:h-[32px] after:bg-grey-200 after:bottom-[-38px] after:start-[9%]': showDivider && dividerOrientation === 'vertical',
        'after:bg-brand-blue-700': showDivider && completed,
      }, className)}
      onClick={(event) => onStepClick?.(value, event)}
      {...rest}
    >
      <span
        className={cn('size-8 inline-flex justify-center items-center rounded-full border border-brand-blue-400 transition-colors duration-200 group-hover:group-enabled:border-brand-blue-700 group-focus:group-enabled:border-brand-blue-700 group-focus-visible:group-enabled:border-brand-blue-700', {
          'text-white bg-brand-blue-700 border-brand-blue-700': completed,
          'border-brand-blue-700': active,
        })}
      >
        {completed
          ? (
              <PurpleIcon name="check" className="size-4 shrink-0 text-white" />
            )
          : (
              <span className={cn('text-sm font-semibold text-brand-blue-400 transition-colors duration-200 group-hover:group-enabled:text-brand-blue-700 group-focus:group-enabled:text-brand-blue-700 group-focus-visible:group-enabled:text-brand-blue-700', {
                'text-brand-blue-700': active,
                'text-grey-400 font-normal': disabled && !active,
              })}
              >
                {sequenceNumber}
              </span>
            )}
      </span>
      {label && (
        <strong className={cn('font-semibold text-grey-500 text-sm transition-colors duration-200 group-hover:group-enabled:text-grey-950 group-focus:group-enabled:text-grey-950 group-focus-visible:group-enabled:text-grey-950 text-nowrap', {
          'text-grey-950': active,
          'text-grey-300': disabled && !active,
        })}
        >
          {label}
        </strong>
      )}
    </button>
  );
};
