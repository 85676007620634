import { Link, useSearchParams } from 'react-router-dom';
import { useToggle } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { ForgotPasswordForm, ForgotSentSection, NewPasswordForm } from './components';

export const ForgotPasswordPage: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const resetPasswordUserId = searchParameters.get('userId') || '';
  const resetPasswordToken = searchParameters.get('token') || '';
  const isResetConfirmed = resetPasswordUserId.length > 0 && resetPasswordToken.length > 0;

  const [isRequestSent, setIsRequestSent] = useToggle(false);

  return (
    <div className="relative flex w-[564px] flex-col items-center justify-center rounded-3xl border border-grey-200 bg-white px-4 py-16 pt-6 shadow-custom-standard max-h-720:py-[48px]">
      <Button
        variant="link"
        size="link"
        iconLeft={<PurpleIcon name="chevron-left" />}
        className="mb-3 self-start text-sm font-semibold"
        asChild
      >
        <Link to={AdminRoutes.Auth.Login.Root.path}>Back to Login</Link>
      </Button>
      {isResetConfirmed
        ? (
            <div className="flex w-full flex-col gap-8 px-14">
              <Heading tag="h1" type="heading-600" variant="size-22" className="text-center text-grey-950">
                New Password
              </Heading>
              <NewPasswordForm userId={resetPasswordUserId} token={resetPasswordToken} />
            </div>
          )
        : (
            <div className="flex w-full flex-col gap-8 px-14">
              <Heading tag="h1" type="heading-600" variant="size-22" className="text-center text-grey-950">
                Forgot Password
              </Heading>
              {isRequestSent ? <ForgotSentSection /> : <ForgotPasswordForm onResetSuccess={setIsRequestSent} />}
            </div>
          )}
    </div>
  );
};
