import axiosInstance from '../axios-config';
import { ROLES_ENDPOINTS } from '../endpoints';
import type { TRolesSearchRequestParameters, TRolesSearchResponse } from './roles.types';

export const getRolesSearch = async (requestParameters: TRolesSearchRequestParameters) => {
  const response = await axiosInstance.get<TRolesSearchResponse>(ROLES_ENDPOINTS.SEARCH_LIST, {
    params: requestParameters,
  });
  return response.data;
};
