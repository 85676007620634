import { type FC, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, MultiSelect, MultiSelectItem, SearchInput, Separator } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useSafEmails } from '~/services';
import { SAF_EMAIL_KEYS, SAF_EMAIL_STATUSES_OPTIONS } from './constants';
import { emailsColumns } from './emailColumns';
import { EmailDetails } from './EmailDetails';

export const EmailTab: FC = () => {
  const [searchParams] = useSearchParams();
  const { safId } = useParams();

  const { query: emailId } = useQueryParameter({
    queryName: SAF_EMAIL_KEYS.emailId,
  });

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { data: emailsList, isLoading } = useSafEmails({
    parameters: {
      safId: safId!,
      search: debounceSearch,
      limit: searchParams.get(LIMIT_QUERY_NAME),
      offset: searchParams.get(OFFSET_QUERY_NAME),
      ordering: searchParams.get(SORT_QUERY_NAME),
      status: selectedStatuses.join(','),
    },
  });

  const { table } = useDataTable({
    columns: emailsColumns,
    data: emailsList?.results || [],
    rowCount: emailsList?.count || 0,
  });

  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Emails</Heading>
      </div>
      <Separator className="bg-grey-200" />
      {emailId
        ? (
            <EmailDetails />
          )
        : (
            <DataTable table={table} loading={isLoading} className="p-0">
              <div className="flex items-center justify-between gap-2 px-4 pb-6 pt-4">
                <div className="flex w-full items-center gap-4">
                  <MultiSelect
                    selectedOptions={SAF_EMAIL_STATUSES_OPTIONS.filter((status) => selectedStatuses.includes(status.value))}
                    modalPopover
                    placeholder="Select Email Status"
                    className="w-fit min-w-40"
                    maxCount={1}
                    onOptionChange={setSelectedStatuses}
                  >
                    {SAF_EMAIL_STATUSES_OPTIONS.map((status) => (
                      <MultiSelectItem
                        key={status.value}
                        option={status}
                        value={status.value}
                        isSelected={selectedStatuses.includes(status.value)}
                      />
                    ))}
                  </MultiSelect>
                  <SearchInput
                    value={search}
                    onChange={onSearchChange}
                    onClear={onClearSearch}
                    placeholder="Search..."
                    className="w-[300px]"
                  />
                </div>
                <DataTableViewOptions table={table} />
              </div>
            </DataTable>
          ) }

    </div>
  );
};
