import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateColorTrendsPullDates } from './update-pull-dates';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerPullDatesPayload, TVisualizerPullDatesResponse } from './visualizer.types';

export const useUpdatePullDates = () => {
  const queryClient = useQueryClient();

  return useMutation<TVisualizerPullDatesResponse, AxiosError, TVisualizerPullDatesPayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_COLOR_TRENDS_PULL_DATES],
    mutationFn: updateColorTrendsPullDates,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_COLOR_TRENDS_PULL_DATES, data.id.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.id.toString()],
      });
      showSuccessToast('System message', 'Color trends pull dates were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update color trends pull dates. Please try again later');
    },
  });
};
