import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictUsers } from './get-district-users';

export const useDistrictUsersMutation = () => {
  // NOTE: we use useMutation hook to get all users by clicking on the button (or another trigger)
  return useMutation({
    mutationKey: [DISTRICTS_QUERY_KEYS.GET_USERS_MUTATION],
    mutationFn: getDistrictUsers,

    onError: () => {
      showErrorToast('System message', 'Failed to fetch district users data');
    },
  });
};
