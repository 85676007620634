import { useMemo } from 'react';
import { Heading, Progress } from '@purple/ui';
import { GroupPermissionStepper, StepperProgressStepsLabels } from '../../stepper';
import type { FC } from 'react';

export const GroupStepperProgress: FC = () => {
  const { current, all } = GroupPermissionStepper.useStepper();

  const progressValue = useMemo(() => (current.index / all.length) * 100, [current, all]);

  return (
    <section className="flex w-full flex-col gap-3 pb-6">
      <div className="flex w-full items-baseline justify-start gap-1">
        <span className="text-base font-normal text-grey-950">
          Step
          {' '}
          {current.index + 1}
          {' '}
          :
        </span>
        <Heading tag="h2" type="heading-600" variant="size-18" className="text-base text-grey-950">
          {StepperProgressStepsLabels[current.id]}
        </Heading>
      </div>
      <Progress value={progressValue} />
    </section>
  );
};
