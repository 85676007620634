import type { ValueOf } from '@purple/shared-types';

export const ActionsDetailsTabsValues = {
  basicDetails: 'basic-details',
  linkedStudents: 'linked-students',
  notes: 'notes',
  historyTimeline: 'history-timeline',
} as const;

export type TActionsDetailsTabsValue = ValueOf<typeof ActionsDetailsTabsValues>;

export const ActionsDetailsQueryKeys = {
  actionType: 'actionType',
} as const;

export const ActionsDetailsQueryValues = {
  individualAction: 'individual-action',
  groupAction: 'group-action',
} as const;

export type TActionsDetailsQueryKeys = ValueOf<typeof ActionsDetailsQueryValues>;
