import { Checkbox, Text } from '@purple/ui';
import type { FC } from 'react';

type TTableHeaderProperties = {
  isAllSelected: boolean;
  onSelectAll: () => void;
};

export const TableHeader: FC<TTableHeaderProperties> = ({ isAllSelected, onSelectAll }) => {
  return (
    <div className="grid grid-cols-[280px,120px,1fr,100px] border-b border-b-grey-200">
      <Text variant="size-12" type="body-600" className="self-baseline px-3 py-4 uppercase text-grey-600">Section/resources</Text>
      <Text variant="size-12" type="body-600" className="self-baseline px-3 py-4 uppercase text-grey-600">Platform</Text>
      <Text variant="size-12" type="body-600" className="self-baseline px-3 py-4 uppercase text-grey-600">Description</Text>
      <div className="flex items-center justify-end gap-2 px-3 py-4">
        <Text variant="size-12" type="body-600" className="uppercase text-grey-600">Access</Text>
        <Checkbox onCheckedChange={onSelectAll} checked={isAllSelected} />
      </div>
    </div>
  );
};
