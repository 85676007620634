import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';

export const publishDistrict = async ({
  districtId,
}: {
  districtId: number;
}) => {
  const URL = DISTRICTS_ENDPOINTS.PUBLISH_DISTRICT(districtId.toString());

  const response = await axiosInstance.patch<void>(URL);
  return response.data;
};
