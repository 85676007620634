import { Link } from 'react-router-dom';
import { formateDateShortMonthWithTime } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSafHistory } from '@purple/shared-types';

type THistoryColumns = ColumnDef<TSafHistory>[];

export const historyColumns: THistoryColumns = [
  {
    accessorKey: 'modified_by',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Modified by" />,
    cell: ({ row }) => {
      const userFullName = row.original.user?.full_name;

      if (!userFullName) return <span className="line-clamp-1 break-all text-grey-950">-</span>;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to={AdminRoutes.App.Users.UserDetail.makePath({
                dynamicParameters: { id: row.original.user.id },
              })}
            >
              <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{userFullName}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {userFullName}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Modified by' },
    enableSorting: false,
  },
  {
    accessorKey: 'field',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Field" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('field')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('field')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 120,
    meta: { label: 'Field' },
    enableSorting: false,
  },
  {
    accessorKey: 'original_value',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Original value" />,
    cell: ({ row }) => {
      const value = row.original.original_value;

      if (!value) return <span className="line-clamp-1 break-all text-grey-950">-</span>;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{value}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {value}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Original value' },
    enableSorting: false,
  },
  {
    accessorKey: 'new_value',
    header: ({ column }) => <DataTableColumnHeader column={column} title="New value" />,
    cell: ({ row }) => {
      const value = row.original.new_value;

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{value}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {value}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'New value' },
    enableSorting: false,
  },
  {
    accessorKey: 'datetime',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
    cell: ({ row }) => {
      const dateTime = formateDateShortMonthWithTime(row.getValue('datetime'));

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{dateTime}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {dateTime}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Date' },
    enableSorting: false,
  },
];
