import { Heading, Text } from '@purple/ui';

type TStatCardProperties = {
  title: string;
  value?: number;
};

const StatCard = ({ title, value }: TStatCardProperties) => {
  return (
    <div className="flex w-full flex-col gap-1 rounded-lg border border-grey-200 px-4 py-3">
      <Text variant="size-14" type="body-500" className="text-grey-950">{title}</Text>
      <div className="flex items-end gap-1">
        <Heading variant="size-36" type="heading-700" className="text-brand-blue-950" tag="h4">{value}</Heading>
        <Text variant="size-14" type="body-600" className="pb-1.5 text-grey-600">Last week</Text>
      </div>
    </div>
  );
};

export { StatCard };
