export const replaceUrlParameters = (url: string, ...parameters: string[]): string => {
  let parameterIndex = 0;
  // @ts-expect-error ...
  return url.replaceAll(/\{[^}]+\}/g, () => {
    if (parameterIndex < parameters.length) {
      return parameters[parameterIndex++];
    }
    throw new Error('Not enough parameters provided');
  });
};
