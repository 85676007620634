import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const healthSchema = zodAlwaysRefine(
  z.object({
    nurse_or_health_office_visits: floatNumber(
      z.number().gte(0, 'Nurse/Health Office Visits must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type THealthSchema = z.infer<typeof healthSchema>;

export const healthSchemaSuperRefine = (data: TFlagCalculationBase<typeof healthSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.HEALTH) return;

  if (!isNumber(data.nurse_or_health_office_visits)) {
    ctx.addIssue({
      path: ['nurse_or_health_office_visits'],
      code: z.ZodIssueCode.custom,
      message: 'Nurse/Health Office Visits is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
