import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUserLoginHistory } from './get-user-login-history';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUserLoginHistoryRequestParameters, TAdminUserLoginHistoryResponse } from './user.types';

export const useUserLoginHistory = ({
  enabled = true,
  requestParameters,
  userId,
}:
{
  requestParameters: TAdminUserLoginHistoryRequestParameters;
  userId?: string;
  enabled?: boolean;
}) => {
  const id = userId || '';

  const { isError, error, ...rest } = useQuery<TAdminUserLoginHistoryResponse, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_LOGIN_HISTORY, userId, requestParameters],
    queryFn: () => getUserLoginHistory({ userId: id, requestParameters }),
    enabled: enabled && !!userId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch user login history');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
