import { Link } from 'react-router-dom';
import { cutGradeName } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import { ListItemOptions } from './components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafRuleItem } from '~/services';

type TSafsColumns = ColumnDef<TAdminSafRuleItem>[];

type TUseSafsRuleColumnsProperties = {
  onDelete: (saf: TAdminSafRuleItem) => void;
  onEdit: (saf: TAdminSafRuleItem) => void;
};

export const useSafsRuleColumns = ({ onDelete, onEdit }: TUseSafsRuleColumnsProperties): TSafsColumns => {
  return [
    SelectableCell(),
    {
      accessorKey: 'need__name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Need Type" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-grey-950">{row.original.need}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.original.need}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 100,
      meta: { label: 'Need Type' },
    },
    {
      accessorKey: 'grades',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Grade" />,
      cell: ({ row }) => {
        const gradesLabel = row.original.grades.length ? row.original.grades.map((grade) => cutGradeName(grade)).join(', ') : '-';
        return (
          <span className="line-clamp-1 break-all text-grey-950">{gradesLabel}</span>
        );
      },
      size: 180,
      meta: { label: 'Grade' },
      enableSorting: false,
    },
    {
      accessorKey: 'assigned_user__full_name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Assigned Contact" />,
      cell: ({ row }) => {
        if (!row.original.assigned_user) {
          return <span className="line-clamp-1 break-all text-grey-950">-</span>;
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to={AdminRoutes.App.Users.UserDetail.makePath({
                dynamicParameters: { id: row.original.assigned_user.id },
              })}
              >
                <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{row.original.assigned_user.full_name}</span>
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {row.original.assigned_user.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 100,
      meta: { label: 'Assigned Contact' },
    },
    {
      accessorKey: 'assigned_role__name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Assigned Role" />,
      cell: ({ row }) => {
        return <span className="line-clamp-1 break-all text-grey-950">{row.original.assigned_role ? row.original.assigned_role.name : '-'}</span>;
      },
      size: 100,
      meta: { label: 'Assigned Role' },
    },
    {
      accessorKey: 'student_last_name_ranges',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Student last name is between" />,
      cell: ({ row }) => {
        const ranges = row.original.student_last_name_ranges.map((range) => `${range.start} - ${range.end}`);

        return (
          <div className="flex gap-3">
            {ranges.map((range) => (
              <span key={range} className="line-clamp-1 break-all text-grey-950">{range}</span>
            ))}
          </div>
        );
      },
      size: 100,
      meta: { label: 'Student last name is between' },
      enableSorting: false,
    },
    {
      id: 'options',
      cell: ({ row }) => (
        <div>
          <ListItemOptions onDelete={() => onDelete(row.original)} onEdit={() => onEdit(row.original)} />
        </div>
      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
