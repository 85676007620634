import * as z from 'zod';
import { CONTAIN_DIGIT_REGEX, CONTAIN_LETTER_REGEX } from '@purple/shared-utils';

export const forgotPasswordFormSchema = z.object({
  email: z.string().trim().min(1, 'Email is required').email('Please enter a valid email address'),
});

export const newPasswordFormSchema = z
  .object({
    new_password1: z
      .string()
      .min(1, {
        message: 'Password is required',
      })
      .min(8, {
        message: 'Password must be at least 8 characters',
      })
      .regex(CONTAIN_DIGIT_REGEX, {
        message: 'Password must contain at least one number',
      })
      .regex(CONTAIN_LETTER_REGEX, {
        message: 'Password must contain at least one letter',
      }),
    new_password2: z.string().min(1, {
      message: 'New password is required',
    }),
  })
  .refine((data) => data.new_password1 === data.new_password2, {
    message: 'Passwords do not match',
    path: ['new_password2'],
  });
