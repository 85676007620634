import { Card, Skeleton } from '@purple/ui';

export const SkeletonCardStatistic = () => {
  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <Card>
        <Skeleton className="h-7 w-10/12" />
        <Skeleton className="h-10 w-2/3" />
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-10/12" />
          <Skeleton className="h-8 w-1/4" />
        </div>
      </Card>
      <Card>
        <Skeleton className="h-7 w-10/12" />
        <Skeleton className="h-10 w-2/3" />
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-10/12" />
          <Skeleton className="h-8 w-1/4" />
        </div>
      </Card>
      <Card>
        <Skeleton className="h-7 w-10/12" />
        <Skeleton className="h-10 w-2/3" />
        <div className="flex items-center gap-2">
          <Skeleton className="h-4 w-10/12" />
          <Skeleton className="h-8 w-1/4" />
        </div>
      </Card>
    </div>
  );
};
