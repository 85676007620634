import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateDataTableView } from './update-data-table-view';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerDataTableViewPayload, TVisualizerDataTableViewResponse } from './visualizer.types';

export const useUpdateDataTableView = () => {
  const queryClient = useQueryClient();

  return useMutation<TVisualizerDataTableViewResponse, AxiosError, TVisualizerDataTableViewPayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_DATA_TABLE_VIEW],
    mutationFn: updateDataTableView,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_DATA_TABLE_VIEW, data.id.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.id.toString()],
      });
      showSuccessToast('System message', 'Data table view was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update data table view. Please try again later');
    },
  });
};
