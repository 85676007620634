import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
import { setDistrictSetUp } from '~/store/features/district-set-up';
import { showErrorToast } from '~/utils/toasts';
import { convertDraftedDetailToState } from './converter';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDraftDistrictDetail } from './get-draft-district-detail';

export const useDraftDistrictDetail = (districtId: number | null) => {
  const dispatch = useAppDispatch();

  const { data, isError, isSuccess, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL, districtId],
    queryFn: () => getDraftDistrictDetail(districtId as number),
    enabled: !!districtId,
  });

  useEffect(() => {
    if (data) {
      const converted = convertDraftedDetailToState(data);
      dispatch(setDistrictSetUp(converted));
    }
  }, [isSuccess, data, dispatch]);

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch draft district data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError, isSuccess, data };
};
