import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from 'recharts';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
  Text,
} from '@purple/ui';
import { PERIOD_SELECT_OPTIONS } from '~/constants';
import type { FC } from 'react';
import type { ChartConfig } from '@purple/ui';

type TLoggedInByRoleChartProperties = {
  barChartData: { role: string; users: number }[];
  barChartConfig: ChartConfig;
  currentSelectedTimePeriod: string;
  onTimePeriodChange: (timePeriod: string) => void;
  onReportView: () => void;
};

export const HomeBarChart: FC<TLoggedInByRoleChartProperties> = ({
  barChartData,
  barChartConfig,
  currentSelectedTimePeriod,
  onTimePeriodChange,
  onReportView,
}) => {
  return (
    <Card className="lg:col-span-2">
      <CardHeader className="flex flex-row items-center justify-between gap-2">
        <Text variant="size-16" type="body-600">
          Logged In Users by Role
        </Text>
        <RadixSelect onValueChange={onTimePeriodChange} value={currentSelectedTimePeriod}>
          <RadixSelectTrigger size="sm" className="w-[220px] gap-2">
            <RadixSelectValue placeholder="All time" />
          </RadixSelectTrigger>
          <RadixSelectContent align="end">
            {PERIOD_SELECT_OPTIONS.map((period) => (
              <RadixSelectItem key={period.value} value={period.value} className="text-nowrap">
                {period.label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
      </CardHeader>
      <CardContent className="mt-4">
        <ReChartContainer config={barChartConfig} className="aspect-auto h-[200px] w-full">
          <BarChart
            accessibilityLayer
            data={barChartData}
            margin={{
              top: 32,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <XAxis dataKey="role" tickLine={false} tickMargin={10} axisLine={false} className="font-primary" />
            <ReChartTooltip content={<ReChartTooltipContent />} />
            <Bar dataKey="users" fill="var(--color-desktop)" radius={8} maxBarSize={100}>
              <LabelList
                dataKey="users"
                position="top"
                offset={12}
                className="fill-grey-title font-primary font-semibold"
                fontSize={12}
              />
            </Bar>
          </BarChart>
        </ReChartContainer>
      </CardContent>
      <CardFooter className="flex items-center justify-end">
        <Button variant="tertiary" size="small" onClick={onReportView}>
          View Report
        </Button>
      </CardFooter>
    </Card>
  );
};
