import { formatHolidayDate } from '@purple/shared-utils';
import {
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import { HolidaysDialog } from './HolidaysDialog';
import type React from 'react';
import type { TSchoolBasicDetails } from '~/services';

type THolidaysSectionProperties = {
  data: TSchoolBasicDetails;
};

export const HolidaysSection: React.FC<THolidaysSectionProperties> = (props) => {
  const { data } = props;

  const { toggleModal } = useModal(ModalType.UPDATE_SCHOOL_HOLIDAYS);

  const editClickHandler = () => {
    toggleModal(true);
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <SchoolSectionHeader
        title="Holidays & School Closures"
        onEdit={editClickHandler}
      />
      <DescriptionList className="gap-2">
        <DescriptionItem className="flex-nowrap items-start">
          <DescriptionTerm className="w-1/2 shrink-0">Holidays & School Closures</DescriptionTerm>
          <DescriptionDetails className="inline-flex w-1/2 flex-col gap-0.5">
            {data.holidays && data.holidays.length > 0
              ? data.holidays.map((item) => (
                <span key={item.id} className="inline-block min-h-6">
                  {formatHolidayDate(item.name, item.start_datetime, item.end_datetime)}
                </span>
              ))
              : 'No holidays or school closures'}
          </DescriptionDetails>
        </DescriptionItem>
      </DescriptionList>
      <HolidaysDialog data={data} />
    </div>
  );
};
