import { type FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { AppTabs, Heading, Text } from '@purple/ui';
import { AllSafs, NeedTypes, SafRoutingRules, SafTransferProcessOwner, SubmitterTypes } from './Tabs';
import { AutomatedEmail } from './Tabs/AutomatedEmail/AutomatedEmail';
import type { TAppTab } from '@purple/ui';

const SAFS_TOTAL_QUERY_NAME = 'safs_total';

export const Saf: FC = () => {
  const [searchParameters] = useSearchParams();

  const totalSafs = searchParameters.get(SAFS_TOTAL_QUERY_NAME);

  const allSafsLabel = useMemo(() => totalSafs ? `All SAFs (${totalSafs})` : 'All SAFs', [totalSafs]);

  const safsTabList: TAppTab[] = useMemo(
    () => [
      {
        label: allSafsLabel,
        value: 'all',
        content: <AllSafs />,
        permissions: [],
      },
      {
        label: 'Submitter Types',
        value: 'submitter-types',
        content: <SubmitterTypes />,
        permissions: [],
      },
      {
        label: 'Need Types',
        value: 'need-types',
        content: <NeedTypes />,
        permissions: [],
      },
      {
        label: 'SAF Routing Rules',
        value: 'saf-routing-rules',
        content: <SafRoutingRules />,
        permissions: [],
      },
      {
        label: 'SAF Transfer Process Owner',
        value: 'saf-transfer-process-owner',
        content: <SafTransferProcessOwner />,
        permissions: [],
      },
      {
        label: 'Automated Email',
        value: 'automated-email',
        content: <AutomatedEmail />,
        permissions: [],
      },
    ],
    [allSafsLabel],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: safsTabList,
  });

  if (!activeTab) return null;

  return (
    <div className="flex flex-col gap-7">
      <div className="flex flex-col gap-1">
        <Heading tag="h1" variant="size-22" type="heading-600">
          SAFs
        </Heading>
        <Text variant="size-14" type="body-400" className="text-grey-800">
          Manage SAFs, set up layouts and SAF routing rules
        </Text>
      </div>
      <AppTabs tabs={safsTabList} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} />
    </div>
  );
};
