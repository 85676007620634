import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { deleteSsoSetting } from './delete-sso-setting';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError, AxiosResponse } from 'axios';

export const useDeleteSsoSettings = () => {
  return useMutation<AxiosResponse<void>, AxiosError, { districtId: string }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.DELETE_DISTRICT_SSO_SETTING],
    mutationFn: deleteSsoSetting,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to delete connection. Please try again later.');
      }
    },
  });
};
