import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { DescriptionEdit } from './DescriptionEdit';
import { DescriptionView } from './DescriptionView';
import { updateSafDescriptionSchema } from './updateSafDescriptionSchema';
import type * as z from 'zod';

type TDescriptionAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const DescriptionArea = ({ data }: TDescriptionAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { description_translated } = data;

    return {
      description_translated: description_translated || '',
    };
  }, [data]);

  const form = useForm<z.infer<typeof updateSafDescriptionSchema>>({
    resolver: zodResolver(updateSafDescriptionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateSafDescriptionSchema>) => {
    const { description_translated } = newFormData;

    const payload = {
      description_translated,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Description</Text>}
      />
      {mode === 'view' ? <DescriptionView data={data} /> : <DescriptionEdit form={form} />}
    </div>
  );
};

export { DescriptionArea };
