import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolsHierarchy } from './get-school-hierarchy';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolHierarchy = (options: { schoolId?: string | number | null; enabled?: boolean }) => {
  const { schoolId, enabled = true } = options;

  const { isError, ...queryData } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_ADMIN_SCHOOL_HIERARCHY, schoolId],
    queryFn: () => getSchoolsHierarchy(schoolId!),
    enabled: enabled && !!schoolId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch school hierarchy data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
