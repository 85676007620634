import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getRolesSearch } from './get-roles-search';
import { ROLES_QUERY_KEYS } from './roles.const';
import type { AxiosError } from 'axios';
import type { TRolesSearchRequestParameters, TRolesSearchResponse } from './roles.types';

export const useRoleSearch = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TRolesSearchRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TRolesSearchResponse, AxiosError>({
    queryKey: [ROLES_QUERY_KEYS.GET_ADMIN_ROLES_SEARCH, requestParameters],
    queryFn: () => getRolesSearch(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch roles list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
