import { forwardRef, useMemo } from 'react';
import { useQueryParameter } from '@purple/hooks';
import { Heading, Progress } from '@purple/ui';
import { VisualizerStep, VisualizerStepReadable, VisualizerStepSequence } from '~/constants';
import { useStudentVisualizerSetupState } from '~/services';
import { VisualizerSetupProgressSkeleton } from './VisualizerSetupProgressSkeleton';
import type { TVisualizerStep } from '~/constants';

export const VisualizerSetupProgress = forwardRef<HTMLDivElement>((_, ref) => {
  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { data: setupState, isFetching } = useStudentVisualizerSetupState({
    enabled: !!districtId,
    districtId,
  });

  const step = useMemo(() => stepQuery
    ? Object.values(VisualizerStep).find((step) => step === stepQuery) ?? null
    : null, [stepQuery]);

  const title = useMemo(() => step
    ? VisualizerStepReadable[step] ?? null
    : null, [step]);
  const stepSequenceNumber = useMemo(() => step
    ? VisualizerStepSequence[step] ?? null
    : null, [step]);

  const progressValue = useMemo(() => {
    if (!setupState) return 0;
    const completedSteps = Object.values(setupState).filter((value) => typeof value === 'boolean' && value);
    return (completedSteps.length / Object.keys(VisualizerStep).length) * 100;
  }, [setupState]);

  if (step === null) return null;

  return (
    <section ref={ref} className="flex w-full flex-col gap-3 pb-6">
      {isFetching
        ? <VisualizerSetupProgressSkeleton />
        : (
            <>
              <div className="flex w-full items-baseline justify-start gap-1">
                <span className="text-base font-normal text-grey-950">
                  Step
                  {' '}
                  {stepSequenceNumber}
                  {' '}
                  :
                </span>
                <Heading tag="h2" type="heading-600" variant="size-18" className="text-base text-grey-950">
                  {title}
                </Heading>
              </div>
              <Progress value={progressValue} />
            </>
          )}
    </section>
  );
});
