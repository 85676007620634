import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { RolesActions } from './components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TListRoleItem } from '~/services';

type TRolesOptions = {
  onSelectRole: (roleId: string) => void;
};

type TRolesListColumns = (options: TRolesOptions) => ColumnDef<TListRoleItem>[];

export const useRolesColumns: TRolesListColumns = (options) => {
  const { onSelectRole } = options;

  const { hasPermissions } = usePermissions();

  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Role name" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all">{row.getValue('name')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('name')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 220,
      meta: { label: 'Role name' },
    },
    {
      accessorKey: 'district__name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all">{original.district_name}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {original.district_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 200,
      meta: { label: 'District' },
    },
    {
      accessorKey: 'description',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all">{row.getValue('description')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('description')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 450,
      enableSorting: false,
      meta: { label: 'Description' },
    },
    {
      accessorKey: 'is_custom',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Role type" />,
      cell: ({ row: { original } }) => (
        <span className="line-clamp-1 break-all capitalize">{original.is_custom ? 'Custom' : 'Default'}</span>
      ),
      size: 100,
      enableSorting: false,
      meta: { label: 'Role type' },
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) => {
        if (!original.is_custom || !hasPermissions(APP_PERMISSIONS.CAN_ACCESS_ADMIN_MANAGE_PERMISSIONS)) return null;
        return (
          <RolesActions
            role={original}
            onSelectRole={onSelectRole}
          />
        );
      },
      size: 64,
      enableHiding: false,
      enableSorting: false,
      meta: { className: 'text-right' },

    },
  ];
};
