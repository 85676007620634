import { formatDateToLocalTime, formateDateShortMonth } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type React from 'react';

type TDateAndUserCellProperties = {
  date?: string | null;
  name?: string | null;
};

export const DateAndUserCell: React.FC<TDateAndUserCellProperties> = ({ date = null, name = null }) => (
  <p className="inline-flex flex-col font-primary text-xs">
    <Tooltip>
      <TooltipTrigger asChild>
        <strong className="line-clamp-1 font-medium text-grey-950">{date ? formateDateShortMonth(date) : '-'}</strong>
      </TooltipTrigger>
      {date && (
        <TooltipPortal>
          <TooltipContent withArrow={false}>{formatDateToLocalTime(date)}</TooltipContent>
        </TooltipPortal>
      )}
    </Tooltip>
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="line-clamp-1 font-normal text-grey-600">{name ?? '-'}</span>
      </TooltipTrigger>
      {name && (
        <TooltipPortal>
          <TooltipContent withArrow={false}>{name}</TooltipContent>
        </TooltipPortal>
      )}
    </Tooltip>
  </p>
);
