import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';
import type { PurpleIconType } from '@purple/icons';
import type { THierarchyNode } from './types';

type THierarchyNodeIcon = {
  item: THierarchyNode;
  isOpen?: boolean;
  isSelected?: boolean;
  default?: PurpleIconType;
};

export const HierarchyNodeIcon: React.FC<THierarchyNodeIcon> = (props) => {
  const {
    item,
    isOpen,
    isSelected,
    default: defaultIcon,
  } = props;

  const iconName = useMemo(
    () =>
      isSelected && item.selectedIcon
        ? item.selectedIcon
        : isOpen && item.openIcon
          ? item.openIcon
          : item.icon || defaultIcon,
    [item, isSelected, isOpen, defaultIcon],
  );

  return iconName
    ? (
        <PurpleIcon name={iconName} className={cn('size-4 shrink-0 text-grey-800', iconName === 'point' && '[&_path]:fill-grey-600 text-grey-600')} />
      )
    : null;
};
