import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useDebouncedCallback, useQueryParameter } from '@purple/hooks';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Heading, Input, MultiSelect, MultiSelectItem, TabsContent, Textarea } from '@purple/ui';
import { PERMISSIONS_QUERY_KEYS, useDistrictsListBasicInfo } from '~/services';
import { ROLE_PARAMETERS_KEY } from '../../constants';
import { useManageRolesTabs } from '../../useManageRolesTabs';
import type { TPermissionStructure } from '~/services';
import type { TFormValues } from '../../manageRolesSchema';

const DEFAULT_SEARCH_DELAY = 500;

export const ManageRolesFormContent = () => {
  const { control, watch } = useFormContext<TFormValues>();
  const queryClient = useQueryClient();

  const { query: roleId } = useQueryParameter({ queryName: ROLE_PARAMETERS_KEY.roleId });

  const permissionStructure = queryClient.getQueryData<TPermissionStructure>([PERMISSIONS_QUERY_KEYS.GET_PERMISSIONS_STRUCTURE]);

  const [districtsDebouncedSearchValue, setDistrictsDebouncedSearchValue] = useState<string>('');

  const { tabs } = useManageRolesTabs({ permissionStructure });

  const selectedDistrict = watch('districts');

  const { data: districtList, isLoading: isDistrictsLoading } = useDistrictsListBasicInfo({
    search: districtsDebouncedSearchValue,
    enabled: true,
    status: 'published',
    limit: 50,
  });

  const selectDistrictsOptions = useMemo(() => {
    return (
      districtList?.results.map((district) => ({
        label: district.name,
        value: district.id.toString(),
      })) || []
    );
  }, [districtList]);

  const districtsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDistrictsDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white p-6">
      <Heading variant="size-18" type="heading-500" className="text-grey-title">
        Basic Information
      </Heading>
      <div className="grid grid-cols-2 gap-x-4 gap-y-3">
        <FormField
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Role Name</FormLabel>
              <FormControl>
                <Input {...field} placeholder="Enter role name" isError={!!fieldState.error} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {roleId && roleId.length > 0 && (
          <FormItem>
            <FormLabel required>Districts</FormLabel>
            <Input
              value={selectedDistrict[0]}
              placeholder="Enter district name"
              disabled
            />
          </FormItem>
        )}
        {!roleId && (
          <FormField
            control={control}
            name="districts"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Districts</FormLabel>
                <FormControl>
                  <MultiSelect
                    {...field}
                    isError={!!fieldState.error}
                    selectedOptions={selectDistrictsOptions.filter((tag) => field.value.includes(tag.value))}
                    showSearch
                    loading={isDistrictsLoading}
                    shouldFilter={false}
                    searchPlaceholder="Search districts"
                    placeholder="Select districts"
                    modalPopover
                    onOptionChange={field.onChange}
                    onSearchChange={districtsDebouncedSearch}
                  >
                    {selectDistrictsOptions.map((option) => (
                      <MultiSelectItem
                        key={option.value}
                        value={option.value}
                        option={option}
                        isSelected={field.value?.includes(option.value)}
                      />
                    ))}
                  </MultiSelect>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={control}
          name="description"
          render={({ field, fieldState }) => (
            <FormItem className="col-span-2">
              <FormLabel>Shared Notes</FormLabel>
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Explain the responsibilities of this role."
                  responsiveHeight
                  className="max-h-[200px] min-h-[80px] resize-none"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </div>
  );
};
