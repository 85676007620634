import axiosInstance from '../axios-config';
import { NEEDS_ENDPOINTS } from '../endpoints';
import type { IPaginatedResponse, TSafNeedWithTranslation } from '@purple/shared-types';
import type { TAdminNeedsRequestParameters } from './needs.types';

export const getNeedsList = async (parameters: TAdminNeedsRequestParameters) => {
  const response = await axiosInstance.get<IPaginatedResponse<TSafNeedWithTranslation[]>>(NEEDS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
