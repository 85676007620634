import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { checkUsersDeactivation } from './check-users-deactivation';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';

export const useUserDeactivateCheck = () => {
  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USERS_DEACTIVATE_CHECK],
    mutationFn: checkUsersDeactivation,
    onError: () => {
      showErrorToast('System Message', 'Failed to check the users');
    },
  });
};
