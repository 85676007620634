import { useMemo } from 'react';
import { Badge } from '@purple/ui';

const MAX_COUNT = 12;

type TSelectedItem = {
  id: string;
  name: string;
};

type TSelectedItemsRowProperties = {
  selectedItems: TSelectedItem[];
  onClearAll: () => void;
  onClose: (student: TSelectedItem) => void;
};

const SelectedItemsRow = ({ selectedItems, onClearAll, onClose }: TSelectedItemsRowProperties) => {
  const totalCount = useMemo(() => selectedItems.length, [selectedItems]);
  const items = useMemo(() => {
    return selectedItems.slice(0, MAX_COUNT);
  }, [selectedItems]);

  const moreCount = useMemo(() => {
    const leftStudents = selectedItems.length - MAX_COUNT;
    return leftStudents > 0 ? leftStudents : 0;
  }, [selectedItems]);

  if (totalCount === 0) return null;

  return (
    <div className="flex flex-row flex-wrap items-center gap-2">
      {items.map((item) => (
        <Badge
          key={item.id}
          label={item.name}
          onClose={() => onClose(item)}
          className="bg-brand-blue-100 text-brand-blue-700"
        />
      ))}
      {Boolean(moreCount) && (
        <Badge label={`+${moreCount} More`} className="bg-brand-blue-100 text-brand-blue-700" />
      )}
      {Boolean(totalCount) && (
        <Badge label="Clear All" onClick={onClearAll} className="bg-white text-brand-blue-700" />
      )}
    </div>
  );
};

export { SelectedItemsRow };
