import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { BANNERS_QUERY_KEYS } from './banners.const';
import { createBanner } from './create-banner';
import type { AxiosError } from 'axios';
import type { TBannerCreatePayload } from './banners.types';

export const useCreateBanner = () => {
  const queryClient = useQueryClient();

  return useMutation<TBannerCreatePayload, AxiosError, TBannerCreatePayload>({
    mutationKey: [BANNERS_QUERY_KEYS.CREATE_BANNER],
    mutationFn: createBanner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_PUBLISHED_BANNERS] });
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_DRAFTED_BANNERS] });
      showSuccessToast('System message', 'New banner has been created successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to create a new banner. Check provided information and try again');
      }
    },
  });
};
