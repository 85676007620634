import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@purple/ui';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateSafDescriptionSchema } from './updateSafDescriptionSchema';

type TDescriptionEditProperties = {
  form: UseFormReturn<z.infer<typeof updateSafDescriptionSchema>>;
};

const DescriptionEdit = ({ form }: TDescriptionEditProperties) => {
  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <FormField
          control={form.control}
          name="description_translated"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter description"
                  responsiveHeight
                  className="max-h-[150px] min-h-[120px] resize-none"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
};

export { DescriptionEdit };
