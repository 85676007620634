import { cutGradeName } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserManagement } from '~/services';

export const addUserColumns: ColumnDef<TUserManagement>[] = [
  SelectableCell(),
  {
    accessorKey: 'full_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
    cell: ({ row: { original } }) => (
      original.full_name && original.full_name.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all">
                  {original.full_name}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  <span>{original.full_name}</span>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    meta: { label: 'Full Name' },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
    cell: ({ row: { original } }) => (
      original.email && original.email.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all">
                  {original.email}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  <span>{original.email}</span>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    size: 250,
    meta: { label: 'Email' },
  },
  {
    accessorKey: 'district',
    header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
    cell: ({ row: { original } }) => (
      original.district && original.district.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all">
                  {original.district.join(', ')}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  <ul>
                    {
                      original.district?.map((district) => (
                        <li key={district} className="list-inside list-decimal">{district}</li>
                      ))
                    }
                  </ul>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    size: 200,
    enableSorting: false,
    meta: { label: 'District' },
  },
  {
    accessorKey: 'role',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
    cell: ({ row: { original } }) => (
      original.prounitas_roles && original.prounitas_roles.length > 0
        ? (
            <Tooltip>
              <TooltipTrigger asChild className="w-fit">
                <span className="line-clamp-1 break-all">
                  {original.prounitas_roles.join(', ')}
                </span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>
                  <ul>
                    {
                      original.prounitas_roles?.map((role) => (
                        <li key={role} className="list-inside list-decimal">{role}</li>
                      ))
                    }
                  </ul>
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          )
        : <span>—</span>
    ),
    size: 200,
    enableSorting: false,
    meta: { label: 'Role' },
  },
  {
    accessorKey: 'grades',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
    cell: ({ row }) => {
      const readableGrades = row.original.grades?.map((grade) => cutGradeName(grade));
      return (
        row.original.grades && row.original.grades.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-[50px] p-1">
                  <div className="rounded-3xl bg-grey-100 ">
                    <span className="line-clamp-1 break-all text-center">
                      {readableGrades.join(', ')}
                    </span>
                  </div>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>
                    <ul>
                      {
                        readableGrades.map((grade) => (
                          <li key={grade} className="list-inside list-decimal">{grade}</li>
                        ))
                      }
                    </ul>
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    meta: { label: 'Grades' },
    enableSorting: false,
  },

];
