import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DistrictStatusToBadgeVariant } from '@purple/shared-utils';
import {
  Button,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  NumberBadge,
  Progress,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { DateAndUserCell } from './DateAndUserCell';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDraftDistrict } from '~/services';

type TUseDraftDistrictColumns = () => ColumnDef<TDraftDistrict>[];

export const useDraftDistrictColumns: TUseDraftDistrictColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="District Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">{row.getValue('name')}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.getValue('name')}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 260,
        },
        {
          accessorKey: 'status',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Set Up Status" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={DistrictStatusToBadgeVariant[row.original.status]}
              className="rounded-full capitalize"
            >
              {row.getValue('status')}
            </NumberBadge>
          ),
          size: 120,
          enableSorting: false,
        },
        {
          accessorKey: 'progress',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Progress" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <Progress
                  value={row.original.progress}
                  showValue
                  className="h-3"
                  valueClassName="text-[10px] leading-3"
                />
              </HoverCardTrigger>
              {row.original.not_completed_steps.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent>
                    <strong className="inline-flex w-full p-1 pl-4 font-primary text-xs font-medium text-grey-950">
                      Not completed steps:
                    </strong>
                    <ul className="flex w-full flex-col">
                      {row.original.not_completed_steps.map((step, index) => (
                        <li
                          key={step}
                          className="inline-flex w-full p-1 pl-4 font-primary text-xs font-normal text-grey-950"
                        >
                          {index + 1}
                          .
                          {step}
                        </li>
                      ))}
                    </ul>
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 160,
          enableSorting: false,
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Create Date/User" />,
          cell: ({ row }) => (
            <DateAndUserCell date={row.original.created_at} name={row.original.created_by?.full_name} />
          ),
          size: 150,
          meta: { label: 'Create Date/User' },
        },
        {
          accessorKey: 'updated_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Last Updated" />,
          cell: ({ row }) => (
            <DateAndUserCell date={row.original.updated_at} name={row.original.last_modified_by?.full_name} />
          ),
          size: 150,
          meta: { label: 'Last Updated' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => (
            <Button variant="table" size="small" asChild>
              <Link to={AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: row.original.id } })}>
                Continue Setup
              </Link>
            </Button>
          ),
          size: 152,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TDraftDistrict>[],
    [],
  );

  return columns;
};
