import { SAF_PRIORITY, SAF_STATUS } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';

export const SAFS_STATUS_OPTIONS = Object.values(SAF_STATUS).map((status) => ({
  label: snakeCaseToCapitalized(status),
  value: status,
}));

export const SAFS_PRIORITY_OPTIONS = Object.values(SAF_PRIORITY).map((priority) => ({
  label: snakeCaseToCapitalized(priority),
  value: priority,
}));
