import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DASHBOARD_QUERY_KEYS } from './dashboard.const';
import { getDashboardLastUpdates } from './get-latest-update';
import type { AxiosError } from 'axios';
import type { TDashboardLastUpdatesRequestParameters, TDashboardLastUpdatesResponse } from './dashboard.types';

export const useDashboardLatestUpdates = (parameters: TDashboardLastUpdatesRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TDashboardLastUpdatesResponse, AxiosError>({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_LATEST_UPDATES, parameters],
    queryFn: () => getDashboardLastUpdates(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to get latest updates');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
