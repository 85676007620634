import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLazyTimeout } from '@purple/hooks';
import { LocalStorageKey } from '@purple/shared-utils';
import { AdminRoutes } from '~/constants';
import { useAppDispatch } from '~/hooks';
import { setAuthenticated } from '~/store/features/user';
import { showErrorToast } from '~/utils/toasts';
import { ADMIN_CURRENT_USER_QUERY_KEYS } from '../user/user.const';
import { ADMIN_AUTH_QUERY_KEYS } from './auth.const';
import { adminLogin } from './login';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TLoginErrorResponse, TLoginPayload, TLoginResponse } from './auth.types';

type LocationState = {
  from: {
    pathname: string;
  };
};

export const useLogin = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { timeout } = useLazyTimeout(5000);

  const state = location.state as LocationState;
  const from = state?.from.pathname ?? AdminRoutes.App.Home.Root.path;

  return useMutation<AxiosResponse<TLoginResponse>, AxiosError<TLoginErrorResponse>, TLoginPayload>({
    mutationKey: [ADMIN_AUTH_QUERY_KEYS.LOGIN],
    mutationFn: adminLogin,
    onSuccess: ({ data }) => {
      if (data.access && data.refresh) {
        localStorage.setItem(LocalStorageKey.Admin.Token.AccessToken, data.access);
        localStorage.setItem(LocalStorageKey.Admin.Token.RefreshToken, data.refresh);
      }
      dispatch(setAuthenticated(true));
      queryClient.invalidateQueries({ queryKey: [ADMIN_CURRENT_USER_QUERY_KEYS.CURRENT_USER] });
      navigate(from, { replace: true });
    },
    onError: (responseError) => {
      const { response } = responseError;
      const redirectSubdomain = response?.data?.subdomain;
      if (response && response.status === 303 && redirectSubdomain) {
        showErrorToast(
          'System Message',
          'Incorrect platform. You will be automatically redirected to the correct platform in 5 seconds.',
        );
        return timeout(() =>
          window.location.replace(
            `https://${redirectSubdomain}.${import.meta.env.VITE_PURPLE_SENSE_DOMAIN}/auth/sign-in`,
          ),
        );
      }
      return responseError;
    },
  });
};
