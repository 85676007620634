import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import { replaceUrlParameters } from '../helpers';
import type { TDraftDistrictBasicInfoRequest } from './districts.types';

export const updateDraftDistrictBasic = async ({
  districtId,
  parameters,
}: {
  districtId: number;
  parameters: TDraftDistrictBasicInfoRequest;
}) => {
  const URL = replaceUrlParameters(DISTRICTS_ENDPOINTS.UPDATE_DRAFT_BASIC, districtId.toString());

  const response = await axiosInstance.patch<void>(URL, parameters);
  return response;
};
