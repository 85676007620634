import { useParams } from 'react-router-dom';
import { Heading, Separator, Text } from '@purple/ui';
import { useUserBasicInfo } from '~/services';
import { BasicDescriptionArea } from '../../BasicDescriptionArea';
import { BasicDetailArea } from '../../BasicDetailArea';
import { BasicGroupsArea } from '../../BasicGroupsArea';
import { StatCard } from '../../StatCard';
import { BasicDetailTabSkeleton } from './BasicDetailTabSkeleton';
import type { TUserBasicInfo } from '~/services';

const BasicDetailTab = () => {
  const { userId } = useParams();

  const { data: basicData, isLoading: isBasicDataLoading } = useUserBasicInfo({ userId, enabled: !!userId });

  const { purple_stats } = basicData || {};

  if (isBasicDataLoading || !basicData) return <BasicDetailTabSkeleton />;

  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="grid grid-cols-2 py-8">
        <div className="border-r border-grey-200 px-6 py-0">
          <BasicDetailArea data={basicData as TUserBasicInfo} />
        </div>
        <div className="flex flex-col gap-[20px] px-6 py-0">
          <BasicDescriptionArea data={basicData as TUserBasicInfo} />
          <Separator className="bg-grey-200" />
          <BasicGroupsArea data={basicData as TUserBasicInfo} />
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-4 px-6 py-4">
        <Text variant="size-16" type="body-500" className="text-grey-title">Purple Stats</Text>
        <div className="flex w-full gap-4">
          <StatCard title="Actions Created" value={purple_stats?.actions_created} />
          <StatCard title="SAF Prioritization Rate " value={purple_stats?.saf_prioritization_rate} />
          <StatCard title="Log In" value={purple_stats?.log_in} />
        </div>
      </div>
    </div>
  );
};

export { BasicDetailTab };
