import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TBehaviorNumericalSchema } from './behaviorNumericalSchema';

export const BehaviorNumericalForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Behavior Metrics
      </Heading>
      <FloatNumberField<TBehaviorNumericalSchema> name="iss" label="ISS" />
      <FloatNumberField<TBehaviorNumericalSchema> name="oss" label="OSS" />
      <FloatNumberField<TBehaviorNumericalSchema> name="daep" label="DAEP" />
      <NumberOfMaxFlagsField className="col-span-1" />
      <BaselineField />
    </div>
  );
};
