import { useEffect, useMemo } from 'react';
import { DISTRICT_SETUP_STEP } from '@purple/shared-types';
import { useStepper } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { maxDistrictStepSelector } from '~/store/features/district-set-up';
import { SetUpProgressBar } from '../SetUpProgressBar';
import { BasicStep, BrandingStep, DistrictUsersStep, SafProcessStep, SisDataMapping } from '../steps';

const STEP_FORM_MAP = {
  [DISTRICT_SETUP_STEP.BASIC]: <BasicStep />,
  [DISTRICT_SETUP_STEP.BRANDING]: <BrandingStep />,
  [DISTRICT_SETUP_STEP.SAF_PROCESS]: <SafProcessStep />,
  [DISTRICT_SETUP_STEP.USERS_AND_PERMISSIONS]: <DistrictUsersStep />,
  [DISTRICT_SETUP_STEP.DATA_UPLOADS]: <SisDataMapping />,
} as const;
type StepFormMapKeys = keyof typeof STEP_FORM_MAP;

const StepContainer = () => {
  const maxStep = useAppSelector(maxDistrictStepSelector);

  const { currentStep, setStep } = useStepper();

  useEffect(() => {
    setStep(maxStep);
  }, [maxStep, setStep]);

  const stepCard = useMemo(() => {
    if (currentStep?.id && currentStep.id in STEP_FORM_MAP) {
      return STEP_FORM_MAP[currentStep.id as StepFormMapKeys];
    }
    return null;
  }, [currentStep]);

  return (
    <div className="flex flex-1 flex-col gap-6">
      <SetUpProgressBar />
      {stepCard}
    </div>
  );
};

export { StepContainer };
