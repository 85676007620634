import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getColorTrendsPullDates } from './get-pull-dates';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';

export const usePullDates = ({ districtId = null, enabled }: { districtId?: number | string | null; enabled?: boolean }) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [VISUALIZER_QUERY_KEYS.GET_COLOR_TRENDS_PULL_DATES, districtId?.toString()],
    queryFn: () => getColorTrendsPullDates(districtId!),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch color trends pull dates', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
