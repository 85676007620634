import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getRoleDetails } from './get-role-details';
import { ROLES_QUERY_KEYS } from './roles.const';
import type { AxiosError } from 'axios';
import type { TRoleDetailResponse } from './roles.types';

export const useRoleDetails = (id: string) => {
  const { isError, error, ...rest } = useQuery<TRoleDetailResponse, AxiosError>({
    queryKey: [ROLES_QUERY_KEYS.ROLE_DETAILS, id],
    queryFn: () => getRoleDetails(id),
    staleTime: 0,
    enabled: !!id,
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch role details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
