export const ADMIN_CURRENT_USER_QUERY_KEYS = {
  CURRENT_USER: 'admin-current-user',
  LIST: 'admin-users-list',
  LIST_MANAGEMENT: 'admin-users-list-management',
  USERS_FILTER_OPTIONS: 'users-management-filter-options',
};

export const ADMIN_USERS_MANAGEMENT_QUERY_KEYS = {
  LIST_MANAGEMENT: 'admin-users-list-management',
  GET_LIST_MANAGEMENT_MUTATION: 'admin-users-get-list-management-mutation',
  USERS_FILTER_OPTIONS: 'users-management-filter-options',
  USERS_DEACTIVATE_CHECK: 'users-deactivate-check',
  ACTIVATE_USERS: 'activate-users',
  DELETE_USER: 'delete-user',
  DELETE_USER_ACCESS_TO_SCHOOL: 'delete-user-access-to-school',
  USER_DETAILS: 'user-details',
  USER_LOGIN_HISTORY: 'user-login-history',
  USER_BASIC_DETAILS: 'user-basic-details',
  USER_BASIC_DETAILS_UPDATE: 'update-user-basic-details',
};
