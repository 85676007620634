import { type FC, memo, useCallback, useId, useState } from 'react';
import { ActionSectionHeader } from '../ActionSectionHeader';
import { DetailsContent } from './DetailsContent';
import { DetailsEditForm } from './DetailsEditForm';
import type { TActionDetails } from '@purple/shared-types';

type TBasicDetailsSectionProps = {
  action: TActionDetails;
};

export const BasicDetailsSection: FC<TBasicDetailsSectionProps> = memo(({ action }) => {
  const formId = useId();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prev) => !prev);
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader title="Details" editing={isEditMode} loading={isLoading} onEdit={toggleEditMode} formId={formId} onCancel={toggleEditMode} />
      {isEditMode ? <DetailsEditForm action={action} formId={formId} loadingChange={setIsLoading} editModeChange={setIsEditMode} /> : <DetailsContent action={action} />}
    </div>
  );
});
