import { PurpleIcon } from '@purple/icons';
import { US_STATES } from '@purple/shared-types';
import { getGradeGroups, getGradeLabel } from '@purple/shared-utils';
import { Badge, Button, cn, HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger, NumberBadge, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminUserSchool } from '~/services';

type TSchoolsColumns = ColumnDef<TAdminUserSchool>[];

type TUseSchoolsColumnsProperties = {
  onDelete: (schoolId: string) => void;
  isDeleting: boolean;
};

export const useSchoolsColumns = ({ onDelete, isDeleting }: TUseSchoolsColumnsProperties): TSchoolsColumns => {
  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="School Name" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all text-brand-blue-700">{row.getValue('name')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('name')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 180,
      meta: { label: 'School Name' },
      enableSorting: false,
    },
    {
      accessorKey: 'status',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      cell: ({ row }) => {
        const isActive = row.original.is_active;

        return (
          <Badge
            label={isActive ? 'Active' : 'Inactive'}
            className={cn({
              'cursor-default text-success-main bg-success-bg': isActive,
              'cursor-default text-error-main bg-error-bg': !isActive,
            })}
          />
        );
      },
      size: 100,
      meta: { className: 'text-center' },
      enableSorting: false,
    },
    {
      accessorKey: 'parent',
      header: ({ column }) => <DataTableColumnHeader column={column} title="School Parent" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
              {row.original.school_parent || '-'}
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
              {row.original.school_parent}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'school_code',
      header: ({ column }) => <DataTableColumnHeader column={column} title="School Code" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
              {row.original.school_code || '-'}
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
              {row.original.school_code}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'school_level',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Level" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
              {row.original.school_level.join(', ') || '-'}
            </Text>
          </TooltipTrigger>
          {row.original.school_level?.length > 0 && (
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                {row.original.school_level.join('\n')}
              </TooltipContent>
            </TooltipPortal>
          )}
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'grades',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
      cell: ({ row }) => (
        <HoverCard>
          <HoverCardTrigger asChild>
            <NumberBadge variant="info" className="col-span-1 place-self-center text-nowrap rounded-full px-3 text-center text-sm font-medium text-grey-950">
              {getGradeLabel(row.original.grades)}
            </NumberBadge>
          </HoverCardTrigger>
          {row.original.grades?.length > 0 && (
            <HoverCardPortal>
              <HoverCardContent
                onClick={(evt) => evt.stopPropagation()}
                className="inline-flex w-full max-w-64 justify-center whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950"
              >
                {getGradeGroups(row.original.grades)}
              </HoverCardContent>
            </HoverCardPortal>
          )}
        </HoverCard>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'city',
      header: ({ column }) => <DataTableColumnHeader column={column} title="City" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
              {row.original.address?.city || '-'}
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
              {row.original.address?.city}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'state',
      header: ({ column }) => <DataTableColumnHeader column={column} title="State" />,
      cell: ({ row }) => {
        const state = row.original.address?.state ? US_STATES[row.original.address?.state as keyof typeof US_STATES] : '-';
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text type="body-500" variant="size-14" className="line-clamp-1 break-all capitalize text-grey-950">
                {state || '-'}
              </Text>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                {row.original.address?.state}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      enableSorting: false,
    },
    {
      id: 'delete',
      cell: ({ row }) => (
        <div>
          <Button variant="link_destructive" size="icon_32" iconLeft={<PurpleIcon name="trash" onClick={() => onDelete(row.original.id)} />} isLoading={isDeleting} />
        </div>
      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
