import { Skeleton } from '@purple/ui';

const DetailHeaderSkeleton = () => {
  return (
    <div className="flex w-full flex-row items-start justify-between rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex gap-3">
        <Skeleton className="size-[60px] rounded-lg" />
        <div className="flex flex-col gap-4">
          <Skeleton className="h-6 w-[200px]" />
          <Skeleton className="h-[52px] w-[650px]" />
        </div>
      </div>
    </div>
  );
};

export { DetailHeaderSkeleton };
