import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getGroupDetails } from './get-group-details';
import { GROUPS_QUERY_KEYS } from './groups.const';
import type { AxiosError } from 'axios';
import type { TGroupDetailsResponse } from './groups.types';

export const useGroupDetails = (groupId: string) => {
  const { isError, error, ...rest } = useQuery<TGroupDetailsResponse, AxiosError>({
    queryKey: [GROUPS_QUERY_KEYS.GET_GROUPS_BY_ID, groupId],
    queryFn: () => getGroupDetails(groupId),
    enabled: !!groupId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch Permission Group details. Please try again later.');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
