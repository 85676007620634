import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '~/hooks';
import { setAuthenticated, setIsLoading, setUser } from '~/store/features/user';
import { getAdminCurrentUser } from './get-user';
import { ADMIN_CURRENT_USER_QUERY_KEYS } from './user.const';

export const useUser = () => {
  const dispatch = useAppDispatch();

  const { isSuccess, isError, data, ...rest } = useQuery({
    queryKey: [ADMIN_CURRENT_USER_QUERY_KEYS.CURRENT_USER],
    queryFn: getAdminCurrentUser,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuthenticated(true));
      dispatch(setIsLoading(false));
      dispatch(setUser(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(setAuthenticated(false));
      dispatch(setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return { isSuccess, data, ...rest };
};
