import { Skeleton } from '@purple/ui';
import type { FC } from 'react';

export const SettingsItemSkeleton: FC = () => {
  return (
    <div className="flex flex-col gap-2.5">
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-7 w-[250px]" />
        <div className="flex items-center gap-2">
          <Skeleton className="size-8" />
          <Skeleton className="size-8" />
        </div>
      </div>
      <div className="grid grid-cols-[max-content,1fr] gap-x-9 gap-y-3.5">
        {Array.from({ length: 10 }).map(() => (
          <Skeleton key={Math.random().toString(36)} className="h-6 w-[400px]" />
        ))}
      </div>
    </div>
  );
};
