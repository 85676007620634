import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateSsoSettings } from './update-sso-settings';
import type { AxiosError } from 'axios';
import type { TDistrictSsoSettingItem, TDistrictSsoSettingsPayload } from './districts.types';

export const useUpdateSsoSettings = () => {
  return useMutation<TDistrictSsoSettingItem, AxiosError<Partial<{ idp_certificate: string[]; idp_sha_fingerprint: string[] }>>, Partial<TDistrictSsoSettingsPayload> & { is_enabled: boolean; districtId: string }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_DISTRICT_SSO_SETTING],
    mutationFn: updateSsoSettings,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to update SSO setting. Please check provided data and try again.');
      }
    },
  });
};
