import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolsHierarchyChoices } from './get-school-hierarchy-choices';
import { SCHOOLS_QUERY_KEYS } from './schools.const';
import type { TSchoolHierarchyChoiceParameters } from './schools.types';

export const useSchoolHierarchyChoices = (options: { enabled?: boolean } & TSchoolHierarchyChoiceParameters) => {
  const { enabled = true, ...parameters } = options;

  const { isError, ...queryData } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_ADMIN_SCHOOL_HIERARCHY_CHOICES, parameters],
    queryFn: () => getSchoolsHierarchyChoices(parameters),
    enabled: enabled && !!parameters.schoolId && parameters.node_types?.length !== 0,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch school hierarchy choices data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
