import { type FC, memo } from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TGoalsContentProperties = {
  action: TActionDetails;
};

export const GoalsContent: FC<TGoalsContentProperties> = memo(({ action }) => {
  return (
    <p className="text-balance pb-1 font-primary text-base font-normal text-grey-600">
      {action.goal_or_progress_towards_goal || 'No goals set'}
    </p>
  );
});
