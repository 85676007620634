import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { closingContextAreaSchema } from './closingContextAreaSchema';
import { SafClosingContextEdit } from './SafClosingContextEdit';
import { SafClosingContextView } from './SafClosingContextView';
import type * as z from 'zod';

type TSafClosingContextAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const SafClosingContextArea = ({ data }: TSafClosingContextAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { closing_context } = data;

    return {
      closing_context: closing_context || '',
    };
  }, [data]);

  const form = useForm<z.infer<typeof closingContextAreaSchema>>({
    resolver: zodResolver(closingContextAreaSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof closingContextAreaSchema>) => {
    const { closing_context } = newFormData;

    const payload = {
      closing_context,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">SAF Closing Context</Text>}
      />
      {mode === 'view' ? <SafClosingContextView data={data} /> : <SafClosingContextEdit form={form} />}
    </div>
  );
};

export { SafClosingContextArea };
