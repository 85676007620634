import { useCallback, useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import {
  AvatarUploader,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Separator,
  Text,
  useStepper,
} from '@purple/ui';
import { ColorSelect } from '~/components';
import { AdminRoutes } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useUpdateDraftDistrictBranding } from '~/services';
import {
  districtBrandingSelector,
  districtIdSelector,
  maxDistrictStepSelector,
  setMaxStep,
} from '~/store/features/district-set-up';
import { showErrorToast } from '~/utils/toasts';
import { StepCardContainer } from '../../StepCardContainer';
import { brandingSchema } from './schema';
import type { z } from 'zod';

// 10MB
const MAX_PHOTO_SIZE_BYTES = 1024 * 1024 * 10;

const BrandingStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { nextStep, activeStep } = useStepper();

  const formId = useId();

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const { mutate: updateDraft, isPending: isUpdating } = useUpdateDraftDistrictBranding();

  const districtId = useAppSelector(districtIdSelector);
  const branding = useAppSelector(districtBrandingSelector);
  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const defaultValues = useMemo(() => {
    return {
      ...branding,
    };
  }, [branding]);

  const form = useForm<z.infer<typeof brandingSchema>>({
    resolver: zodResolver(brandingSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const submitHandler = (data: z.infer<typeof brandingSchema>, onSaveCallback?: () => void) => {
    if (districtId) {
      const body = {
        branding_color: data.branding_color,
        ...(data.logo instanceof File ? { logo: data.logo } : {}),
      };
      updateDraft(
        {
          districtId,
          parameters: body,
        },
        {
          onSuccess: () => {
            setShouldShowUnsaved(false);
            onSaveCallback?.();
            nextStep();
          },
        },
      );
    } else {
      showErrorToast('System message', 'Could not find district id. Check the provided information and try again');
    }
  };

  const navigateToDistrictList = useCallback(() => {
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  const increaseMaxStep = () => {
    if (maxDistrictStep === activeStep) {
      dispatch(setMaxStep(maxDistrictStep + 1));
    }
  };

  const photoChangeHandler = (newFiles: File[] | null) => {
    if (!newFiles || newFiles.length === 0) {
      form.setValue('logo', null);
    } else {
      const newFile = newFiles[0];
      if (newFile) {
        form.setValue('logo', newFile);
      }
    }
  };

  return (
    <StepCardContainer
      isStepValid
      onSave={() => submitHandler(form.getValues(), navigateToDistrictList)}
      formId={formId}
      isLoading={isUpdating}
    >
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col gap-6"
        onSubmit={form.handleSubmit((data) => submitHandler(data, increaseMaxStep))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500" className="text-grey-950">
              Upload Logo
              <span className="text-error-main">*</span>
            </Heading>
            <FormField
              control={form.control}
              name="logo"
              render={({ field }) => {
                const logoValue = typeof field.value === 'string' ? field.value : field.value ? [field.value] : null;

                return (
                  <FormItem className="space-y-3">
                    <FormControl>
                      <AvatarUploader
                        value={logoValue}
                        onValueChange={photoChangeHandler}
                        maxSize={MAX_PHOTO_SIZE_BYTES}
                        height={216}
                        disabled={isUpdating}
                      />
                    </FormControl>
                    <FormMessage className="text-center" />
                  </FormItem>
                );
              }}
            />
          </div>
          <Separator />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Heading variant="size-18" type="heading-500" className="text-grey-950">
                Branding Colors
                <span className="text-error-main">*</span>
              </Heading>
              <Text variant="size-14" type="body-400" className="text-grey-700">
                Select your branding color from the list or add your custom color.
              </Text>
            </div>
            <FormField
              control={form.control}
              name="branding_color"
              render={({ field }) => (
                <FormItem className="max-w-[530px]">
                  <FormLabel>Color</FormLabel>
                  <FormControl>
                    <ColorSelect
                      showContrastWarning
                      contrastWarningText="Accent color isn’t legible. This accent color will make parts of your app hard to see."
                      color={field.value}
                      onColorChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center gap-2">
              <PurpleIcon name="information-circle" className="text-grey-700 size-[20px]" />
              <Text variant="size-14" type="body-500" className="text-grey-950">
                This settings will be automatically applied to your SAFs and the Student Profile pdf / print page
              </Text>
            </div>
          </div>
        </div>
      </Form>
    </StepCardContainer>
  );
};

export { BrandingStep };
