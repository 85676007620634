import { Badge, Text } from '@purple/ui';
import type { TUserBasicInfo } from '~/services';

type TBasicGroupsViewProperties = {
  data: TUserBasicInfo;
};

const BasicGroupsView = ({ data }: TBasicGroupsViewProperties) => {
  const { groups } = data;

  return (
    <div className="flex flex-row gap-2">
      {groups.length === 0 && <Text variant="size-16" type="body-500" className="w-full text-grey-950">-</Text>}
      {groups.map((group) => (
        <Badge key={`${group.name}-${Math.random()}`} label={group.name} className="whitespace-nowrap rounded-full bg-brand-blue-100 capitalize text-brand-blue-700" />
      ))}
    </div>
  );
};

export { BasicGroupsView };
