import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { SafDetailsEdit } from './SafDetailsEdit';
import { SafDetailsView } from './SafDetailsView';
import { updateSafDetailsSchema } from './updateSafDetailsSchema';
import type * as z from 'zod';
import type { TSafPriority, TSafStatus } from '@purple/shared-types';

type TSafDetailsAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const SafDetailsArea = ({ data }: TSafDetailsAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { current_owner, previous_owner, priority_assigned_at, status, priority, needs } = data;

    // NOTE: we take first need because we only support one, but backend has array in the database
    const firstNeed = needs?.[0]?.id || '';

    return {
      current_owner: current_owner?.id || '',
      previous_owner: previous_owner?.id || '',
      priority_assigned_at: priority_assigned_at ? new Date(priority_assigned_at) : undefined,
      status,
      priority: priority?.toString() || '',
      needs: firstNeed,
    };
  }, [data]);

  const form = useForm<z.infer<typeof updateSafDetailsSchema>>({
    resolver: zodResolver(updateSafDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateSafDetailsSchema>) => {
    const {
      current_owner,
      previous_owner,
      priority_assigned_at,
      status,
      priority,
      needs,
    } = newFormData;

    const payloadPriorityValue = priority === '' ? null : priority as TSafPriority;

    const payload = {
      ...(current_owner ? { current_owner } : {}),
      ...(previous_owner ? { previous_owner } : {}),
      ...(priority_assigned_at ? { priority_assigned_at: priority_assigned_at?.toISOString() } : {}),
      ...(status ? { status: status as TSafStatus } : {}),
      priority: payloadPriorityValue,
      ...(needs ? { needs: [needs] } : {}),
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">SAF Details</Text>}
      />
      {mode === 'view' ? <SafDetailsView data={data} /> : <SafDetailsEdit data={data} form={form} />}
    </div>
  );
};

export { SafDetailsArea };
