import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@purple/ui';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { closingContextAreaSchema } from './closingContextAreaSchema';

type TSafClosingContextEditProperties = {
  form: UseFormReturn<z.infer<typeof closingContextAreaSchema>>;
};

const SafClosingContextEdit = ({ form }: TSafClosingContextEditProperties) => {
  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <FormField
          control={form.control}
          name="closing_context"
          render={({ field, fieldState }) => (
            <FormItem className="relative w-full">
              <FormControl>
                <Textarea
                  {...field}
                  isError={!!fieldState.error}
                  placeholder="Enter context"
                  responsiveHeight
                  className="max-h-[150px] min-h-[120px] resize-none"
                />
              </FormControl>
              <FormMessage className="absolute" />
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
};

export { SafClosingContextEdit };
