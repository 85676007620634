import { Skeleton } from '@purple/ui';

export const SafDataChartSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex w-full flex-col space-y-1">
        <Skeleton className="h-5 w-full max-w-[150px]" />
        <Skeleton className="h-4 w-full max-w-[650px]" />
      </div>
      <div className="flex w-full flex-col">
        <Skeleton className="h-60 w-full" />
        <div className="flex w-full flex-wrap items-center justify-start gap-x-4 gap-y-2 pt-2">
          <div className="flex w-full max-w-[75px] items-center gap-1">
            <Skeleton className="size-2 rounded-full" />
            <Skeleton className="h-3 w-full rounded" />
          </div>
          <div className="flex w-full max-w-[115px] items-center gap-1">
            <Skeleton className="size-2 rounded-full" />
            <Skeleton className="h-3 w-full rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};
