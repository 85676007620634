import { Skeleton } from '@purple/ui';

export const ActionDetailsHeaderSkeleton = () => {
  return (
    <div className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-col gap-2">
        <Skeleton className="h-5 w-[500px]" />
        <div className="flex flex-col gap-1">
          <Skeleton className="h-5 w-20" />
          <Skeleton className="h-5 w-[100px] rounded-full" />
        </div>
      </div>
      <Skeleton className="ml-auto size-10 shrink-0" />
    </div>
  );
};
