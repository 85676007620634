import type { FC } from 'react';

export const CustomTick: FC<any> = ({ x, y, payload }) => {
  const value = typeof payload.value === 'string' ? payload.value : '';
  const [startDate, endDate] = value.split(' - ');
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle">
        {startDate}
      </text>
      <text x={0} y={0} dy={30} textAnchor="middle">
        {endDate}
      </text>
    </g>
  );
};
