import { showErrorToast } from './toasts';
import type { AxiosError } from 'axios';

// Improve this function latter if needed

/**
 * Handles Axios errors by returning user-friendly error messages.
 *
 * @param {AxiosError} error - The Axios error object to handle.
 * @returns {void} - A toast notification with message in it.
 */
export const defaultErrorHandling = ({ request, response }: AxiosError) => {
  if (response) {
    // Handle specific HTTP status codes
    switch (response.status) {
      case 500: {
        return showErrorToast('Server error', 'An error occurred on the server. Please try again later.');
      }
      case 502: {
        return showErrorToast('Server error', 'The server is down or being upgraded. Please try again later.');
      }
      default: {
        return;
      }
    }
  }

  // Handle cases where the response is not available (network issues)
  if (request) {
    return showErrorToast('Server error', 'The server is down or being upgraded. Please try again later.');
  }

  return showErrorToast('Network error', 'A network error occurred. Please check your internet connection.');
};
