import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictUserFilterOptions, TDistrictUserFilterOptionsRequestParameters } from './districts.types';

export const getDistrictUsersFilterOptions = async (parameters: TDistrictUserFilterOptionsRequestParameters) => {
  const response = await axiosInstance.get<TDistrictUserFilterOptions>(
    DISTRICTS_ENDPOINTS.USERS_FILTER_OPTIONS,
    {
      params: parameters,
    },
  );
  return response.data;
};
