import { Skeleton } from '@purple/ui';
import { VisualizerStep } from '~/constants';

export const VisualizerNavigationSkeleton: React.FC = () => {
  return (
    <div className="flex w-full max-w-64 flex-col">
      <div className="flex w-full flex-col gap-8">
        {Object.values(VisualizerStep).map((step) => (
          <div key={step} className="inline-flex items-center gap-2 outline-none">
            <Skeleton className="size-8 rounded-full" />
            <Skeleton className="h-5 w-full max-w-[110px]" />
          </div>
        ))}
      </div>
    </div>
  );
};
