import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictUsersFilterOptions } from './get-district-users-filter-options';
import type { TDistrictUserFilterOptionsRequestParameters } from './districts.types';

export const useDistrictUsersFilterOptions = ({
  enabled,
  ...parameters
}: TDistrictUserFilterOptionsRequestParameters & { enabled?: boolean }) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.USERS_FILTER_OPTIONS, parameters],
    queryFn: () => getDistrictUsersFilterOptions(parameters),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch district users filter options data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
