import axiosInstance from '~/services/axios-config';
import { SAF_ENDPOINTS } from '../endpoints';

export const safBulkDelete = async ({ safIds }: {
  safIds: string[];
}) => {
  const response = await axiosInstance.post<void>(
    SAF_ENDPOINTS.BULK_DELETE,
    {
      safs: safIds,
    },
  );
  return response.data;
};
