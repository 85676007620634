import axiosInstance from '../axios-config';
import { SCHOOLS_ENDPOINTS } from '../endpoints';
import type { TAdminUserSchoolsRequestParameters, TAdminUserSchoolsResponse } from './schools.types';

export const getUserSchoolsList = async (parameters: TAdminUserSchoolsRequestParameters) => {
  const response = await axiosInstance.get<TAdminUserSchoolsResponse>(SCHOOLS_ENDPOINTS.USER, {
    params: parameters,
  });
  return response.data;
};
