import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUserBasicDetails } from './get-user-basic-details';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TUserBasicInfo } from './user.types';

export const useUserBasicInfo = ({ enabled = true, userId }: { userId?: string; enabled?: boolean }) => {
  const id = userId || '';

  const { isError, error, ...rest } = useQuery<TUserBasicInfo, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_BASIC_DETAILS, userId],
    queryFn: () => getUserBasicDetails({ userId: id }),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch user basic details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
