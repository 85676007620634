import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  MultiSelect,
  MultiSelectItem,
  NumberBadge,
} from '@purple/ui';
import { CreateTagModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useCustomTags, useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const tagsSchema = z
  .object({
    tags: z.array(z.string()),
  });

type TTagsSectionProps = {
  data: TSchoolBasicDetails;
};

export const TagsSection: React.FC<TTagsSectionProps> = (props) => {
  const { data } = props;

  const { toggleModal: toggleTagModal } = useModal(ModalType.CREATE_CUSTOM_TAG);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();
  const { data: tagsData } = useCustomTags({ content_type: 'school', district: data.district });

  const tagsOptions = useMemo(() => tagsData?.results?.map(({ id, name, color }) => ({
    value: id,
    label: name,
    color,
  })) ?? [], [tagsData]);

  const defaultValues: z.infer<typeof tagsSchema> = useMemo(
    () => ({
      tags: data.tags.map((tag) => tag.id),
    }),
    [data],
  );

  const form = useForm<z.infer<typeof tagsSchema>>({
    resolver: zodResolver(tagsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof tagsSchema>) => {
    updateSchool(
      {
        id: data.id,
        tags: formData.tags,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  const addNewTagClickHandler = () => {
    toggleTagModal(true);
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Tags"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="tags"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-col gap-2 space-y-0">
                    <FormControl>
                      <MultiSelect
                        {...field}
                        isError={!!fieldState.error}
                        selectedOptions={tagsOptions.filter((option) => field?.value?.some((tag) => tag === option.value))}
                        placeholder="Select tags"
                        modalPopover
                        onOptionChange={field.onChange}
                      >
                        {tagsOptions.map((option) => (
                          <MultiSelectItem
                            key={option.value}
                            value={option.value}
                            option={option}
                            isSelected={field.value?.includes(option.value)}
                          />
                        ))}
                        <Button type="button" className="w-full" iconLeft={<PurpleIcon name="plus" />} onClick={addNewTagClickHandler}>
                          Create New Tag
                        </Button>
                      </MultiSelect>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <ul className="flex flex-row flex-wrap items-center gap-2">
              {data.tags.map(({ id, name }) => (
                <li key={id}>
                  <NumberBadge variant="info" className="line-clamp-1 h-auto min-h-7 min-w-0 break-all rounded-full py-0.5">
                    {name}
                  </NumberBadge>
                </li>
              ))}
            </ul>
          )}
      <CreateTagModal
        tagsType="school"
        relatedEntityId={data.id}
        relatedDistrictId={data.district}
        onSuccess={cancelClickHandler}
      />
    </div>
  );
};
