import { useLocalStorage } from 'usehooks-ts';
import { useStorageCountdown } from '@purple/hooks';
import { LocalStorageKey } from '@purple/shared-utils';
import { Button, Text } from '@purple/ui';
import { useResetPassword } from '~/services';

export const ForgotSentSection = () => {
  const [userResetPasswordEmail] = useLocalStorage<string>(LocalStorageKey.Admin.ResetPassword.Email, '');

  const { mutate, isPending } = useResetPassword();

  const { isCounting, startCountdown, time } = useStorageCountdown({
    minutes: 1,
    name: LocalStorageKey.Admin.ResetPassword.CountDown,
  });

  const resendPasswordHandler = () => {
    mutate(
      { email: userResetPasswordEmail },
      {
        onSuccess: () => {
          startCountdown();
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-6">
      <Text className="text-pretty break-words text-center">
        We sent a link to
        {' '}
        <span className="font-semibold text-brand-blue-700">{userResetPasswordEmail}</span>
        {' '}
        Follow
        that link to reset your password.
      </Text>
      <Button disabled={isCounting} onClick={resendPasswordHandler} isLoading={isPending}>
        {isCounting ? time : 'Send Link Again'}
      </Button>
    </div>
  );
};
