import { SafSubmitterType } from '@purple/shared-types';
import type { ValueOf } from '@purple/shared-types';

export const SubmitterOptions = {
  ALL: 'all',
  [SafSubmitterType.STUDENT]: SafSubmitterType.STUDENT,
  [SafSubmitterType.TEACHER]: SafSubmitterType.TEACHER,
  [SafSubmitterType.PARENT]: SafSubmitterType.PARENT,
  [SafSubmitterType.PROVIDER]: SafSubmitterType.PROVIDER,
} as const;
export type TSubmitterOptions = ValueOf<typeof SubmitterOptions>;

export const ReadableSubmitterOptions = {
  [SubmitterOptions.ALL]: 'All',
  [SubmitterOptions[SafSubmitterType.STUDENT]]: 'Students',
  [SubmitterOptions[SafSubmitterType.TEACHER]]: 'Teacher/Staff Member',
  [SubmitterOptions[SafSubmitterType.PARENT]]: 'Parent/Caregiver',
  [SubmitterOptions[SafSubmitterType.PROVIDER]]: 'Community Member/Service Provider',
} as const;
