import axiosInstance from '../axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictDetailsItem } from './districts.types';

export const getSisMappingDetails = async (districtId: string, mappingId: string) => {
  const response = await axiosInstance.get<TDistrictDetailsItem>(
    DISTRICTS_ENDPOINTS.MAPPINGS_DETAILS(districtId, mappingId),
  );
  return response.data;
};
