import { useMemo } from 'react';
import { RoleFormTable } from './components';
import type { TAppTab } from '@purple/ui';
import type { TPermissionStructure } from '~/services';

type TUseManageRolesTabsParameters = {
  permissionStructure: TPermissionStructure | undefined;
};

export const useManageRolesTabs = (props: TUseManageRolesTabsParameters) => {
  const { permissionStructure } = props || {};

  const tabs: TAppTab[] = useMemo(() => {
    if (!permissionStructure) return [];

    return Object.entries(permissionStructure).map(([key, value]) => ({
      id: key.toLowerCase(),
      label: key,
      value: key.toLowerCase(),
      content: <RoleFormTable category={key} permissionList={value} />,
      permissions: [],
    }));
  }, [permissionStructure]);

  return { tabs };
};
