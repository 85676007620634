import { useFormContext } from 'react-hook-form';
import {
  cn,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  MultiSelect,
  MultiSelectItem,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { ColorTrendMetricType } from '~/services';
import { DEFAULT_DISABLED_METRICS, GRADE_LEVELS_OPTIONS, METRIC_DATA_TYPE_OPTIONS, METRIC_TYPE_OPTIONS } from '../const';
import type { z } from 'zod';
import type { metricTypeSchema } from './metricSchema';

type TMetricTypeFormProps = {
  mode?: 'create' | 'edit';
  disabledOptions?: typeof DEFAULT_DISABLED_METRICS;
};

export const MetricTypeForm: React.FC<TMetricTypeFormProps> = (props) => {
  const { mode = 'create', disabledOptions = DEFAULT_DISABLED_METRICS } = props;

  const ctx = useFormContext<z.infer<typeof metricTypeSchema>>();

  const type = ctx.watch('type');

  return (
    <div className="mx-auto flex w-full max-w-[516px] flex-col gap-6">
      <Heading tag="h2" type="heading-600" className="text-center text-base text-grey-950">
        Choose Metric Type
      </Heading>
      <FormField
        control={ctx.control}
        name="name"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Flag Calculation Name</FormLabel>
            <FormControl>
              <Input
                {...field}
                name="name"
                isError={!!fieldState.error}
                placeholder="Enter name"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={ctx.control}
        name="type"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Metric Type</FormLabel>
            <RadixSelect onValueChange={field.onChange} value={field.value}>
              <FormControl>
                <RadixSelectTrigger
                  disabled={mode === 'edit'}
                  className={cn({
                    'border-error-main': !!fieldState.error,
                  })}
                >
                  <RadixSelectValue placeholder="Select type" />
                </RadixSelectTrigger>
              </FormControl>
              <RadixSelectContent viewportClassName="max-h-max">
                {METRIC_TYPE_OPTIONS.map(({ label, value }) => (
                  <RadixSelectItem
                    key={value}
                    value={value}
                    disabled={disabledOptions.find((option) => option.type === value)?.disabled}
                  >
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
            <FormMessage />
          </FormItem>
        )}
      />
      {(type === ColorTrendMetricType.BEHAVIOR || type === ColorTrendMetricType.GRADE) && (
        <FormField
          control={ctx.control}
          name="data_type"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Data Type</FormLabel>
              <RadixSelect onValueChange={field.onChange} value={field.value ?? ''}>
                <FormControl>
                  <RadixSelectTrigger
                    className={cn({
                      'border-error-main': !!fieldState.error,
                    })}
                  >
                    <RadixSelectValue placeholder="Select data type" />
                  </RadixSelectTrigger>
                </FormControl>
                <RadixSelectContent>
                  {METRIC_DATA_TYPE_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem
                      key={value}
                      value={value}
                      disabled={disabledOptions.find((option) => option.type === type)?.disabledDataTypes.includes(value)}
                    >
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
      {type !== ColorTrendMetricType.BEHAVIOR && type !== ColorTrendMetricType.GRADE && (
        <FormField
          control={ctx.control}
          name="grades"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>Grade Levels</FormLabel>
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={GRADE_LEVELS_OPTIONS.filter((grade) => field.value?.includes(grade.value))}
                  placeholder="Select grades"
                  modalPopover
                  onOptionChange={field.onChange}
                >
                  {GRADE_LEVELS_OPTIONS.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value?.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </div>
  );
};
