import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  ReChartContainer,
  ReChartLegend,
  ReChartLegendContent,
  ReChartTooltip,
  ReChartTooltipContent,
} from '@purple/ui';
import { useSchoolSafPlot } from '~/services';
import { SafDataChartSkeleton } from './SafDataChartSkeleton';
import type { ChartConfig } from '@purple/ui';

const chartConfig = {
  total_student: {
    label: 'Total students',
    color: '#84A0D6',
  },
  student_with_safs: {
    label: 'Students with SAFs',
    color: '#4574AA',
  },
} satisfies ChartConfig;

export const SafDataChart: React.FC = () => {
  const { id } = useParams();

  const { data: schoolStats, isFetching } = useSchoolSafPlot(id);

  const chartData = useMemo(() => schoolStats?.map((item) => ({
    date: format(new Date(item.year, item.month - 1, 1), 'MMM yy'),
    total_student: item.total_student,
    student_with_safs: item.student_with_safs,
  })) ?? [], [schoolStats]);

  if (isFetching) {
    return <SafDataChartSkeleton />;
  }

  return (
    <Card className="gap-4 p-4">
      <CardHeader className="space-y-0.5">
        <CardTitle className="text-base">Students with SAFs</CardTitle>
        <CardDescription>
          Total # of unique students with SAFs compared to the total student population for current Academic Year
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ReChartContainer config={chartConfig} className="max-h-64 w-full shrink-0">
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <YAxis
              dataKey="total_student"
              width={40}
              tickSize={40}
              tickCount={10}
              interval={0}
              tick={{ fontSize: 10, fontWeight: 500, textAnchor: 'left' }}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              interval={0}
              tick={{ fontSize: 10, fontWeight: 500 }}
              label={{
                value: 'Month',
                position: 'left',
                className: 'fill-grey-600',
                fontSize: 10,
                dx: 6,
                dy: -4,
                lineHeight: 12,
                fontWeight: 400,
              }}
            />
            <ReChartTooltip cursor={false} content={<ReChartTooltipContent hideLabel />} />
            <ReChartLegend content={<ReChartLegendContent className="justify-start pt-2" />} />

            <Bar
              dataKey="student_with_safs"
              stackId="a"
              fill="var(--color-student_with_safs)"
              radius={[0, 0, 4, 4]}
            />
            <Bar
              dataKey="total_student"
              stackId="a"
              fill="var(--color-total_student)"
              radius={[4, 4, 0, 0]}
              animationBegin={300}
            />
          </BarChart>
        </ReChartContainer>
      </CardContent>
    </Card>
  );
};
