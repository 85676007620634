import { useMemo, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useLazyTimeout } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Input, Text } from '@purple/ui';
import { showErrorToast } from '~/utils/toasts';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

type TDistrictSafLinkSectionProperties = {
  district: TDistrictDetails;
};

export const DistrictSafLinkSection: React.FC<TDistrictSafLinkSectionProperties> = (props) => {
  const { district } = props;

  const [, copyToClipboard] = useCopyToClipboard();
  const { timeout } = useLazyTimeout(5000);

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const formUrl = useMemo(
    () => `https://${district.subdomain}.${import.meta.env.VITE_PURPLE_SENSE_DOMAIN}/form/student-assistance`,
    [district.subdomain],
  );

  const copyLinkClickHandler = () => {
    copyToClipboard(formUrl)
      .then(() => {
        setIsCopied(true);
        timeout(() => setIsCopied(false));
      })
      .catch(() => {
        setIsCopied(false);
        showErrorToast('Failed to copy link', 'Seems like there was an issue copying the link. Please try again.');
      });
  };

  return (
    <section className="flex w-full flex-col gap-1">
      <div className="flex w-full flex-col gap-2">
        <Heading tag="h3" variant="size-18" type="heading-500" className="text-base text-grey-title">
          SAF form Link
        </Heading>
        <Text variant="size-14" type="body-400" className="text-grey-700">
          Select a link below, copy it and share with the district.
        </Text>
      </div>
      <div className="flex w-full items-center gap-2">
        <div className="relative flex basis-[524px]">
          <PurpleIcon name="link" className="absolute left-3 top-1/2 size-4 shrink-0 -translate-y-1/2 text-grey-950" />
          <Input
            type="text"
            readOnly
            value={formUrl}
            containerClassName="w-full"
            className="w-full min-w-48 max-w-[524px] shrink grow pl-10 text-grey-600 focus-visible:border-grey-300 active:border-grey-300"
          />
        </div>
        <Button
          type="button"
          variant="tertiary"
          size="small"
          iconLeft={isCopied ? <PurpleIcon name="check" /> : undefined}
          onClick={copyLinkClickHandler}
        >
          {isCopied ? 'Copied!' : 'Copy Link'}
        </Button>
      </div>
    </section>
  );
};
