export const ACTIONS_QUERY_KEYS = {
  GET_ACTIONS_LIST: 'get-actions-list',
  GET_ACTION_TYPES_OPTIONS: 'get-action-types-options',
  GET_ACTIONS_LIST_FILTER_OPTIONS: 'get-actions-list-filter-options',
  GET_INDIVIDUAL_ACTION_DETAILS: 'get-individual-action-details',
  DELETE_ACTION: 'delete-action',
  UPDATE_ACTION_DETAILS: 'update-action_details',
  UPDATE_ACTION_TAGS: 'update-action-tags',
  GET_ACTION_CHOICES: 'get-action-choices',
  GET_ACTION_TYPES_LIST: 'get-action-types-list',
  GET_ACTIONS_STATISTICS: 'get-actions-statistics',
  GET_ACTION_TYPE: 'get-action-type',
  UPDATE_ACTION_TYPE: 'update-action-type',
} as const;
