import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { LocalStorageKey } from '@purple/shared-utils';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import { isSideBarOpenSelector, sidebarSlice, type TSidebarState } from '~/store/features/sidebar';

type TUseSidebarReturn = TSidebarState & {
  /**
   * Handle function to close main sidebar.
   */
  closeSidebar: () => void;
  /**
   * Handle function to open main sidebar.
   */
  openSidebar: () => void;
  /**
   * Handle function to toggle state of main sidebar.
   */
  toggleSidebar: (open: boolean) => void;
};

export const useSidebar = (): TUseSidebarReturn => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isSideBarOpenSelector);
  const { closeSidebar, openSidebar, toggleSidebar } = sidebarSlice.actions;

  const [storedOpenState, setStoredOpenState] = useLocalStorage(LocalStorageKey.Admin.Sidebar, isOpen);

  useEffect(() => {
    if (storedOpenState !== isOpen) {
      dispatch(toggleSidebar(storedOpenState));
    }
  }, [dispatch, isOpen, storedOpenState, toggleSidebar]);

  return {
    isOpen: storedOpenState,
    closeSidebar: useCallback(() => {
      setStoredOpenState(false);
      dispatch(closeSidebar());
    }, [dispatch, closeSidebar, setStoredOpenState]),
    openSidebar: useCallback(() => {
      setStoredOpenState(true);
      dispatch(openSidebar());
    }, [dispatch, openSidebar, setStoredOpenState]),
    toggleSidebar: useCallback(
      (open: boolean) => {
        setStoredOpenState(open);
        dispatch(toggleSidebar(open));
      },
      [dispatch, toggleSidebar, setStoredOpenState],
    ),
  };
};
