import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { createRole } from './create-role';
import { ROLES_QUERY_KEYS } from './roles.const';
import type { AxiosError } from 'axios';
import type { TRoleCreatePayload, TRoleCreateResponse } from './roles.types';

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<TRoleCreateResponse, AxiosError, TRoleCreatePayload>({
    mutationKey: [ROLES_QUERY_KEYS.CREATE_ROLE],
    mutationFn: createRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ROLES_QUERY_KEYS.LIST_ROLES] });
      showSuccessToast('System message', 'New custom role has been created successfully');
      navigate(AdminRoutes.App.Users.Roles.Root.path);
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to create a new custom role. Check provided information and try again');
      }
    },
  });
};
