import axios from 'axios';
import { LocalStorageKey } from '@purple/shared-utils';
import { resetApp, store } from '~/store';
import { defaultErrorHandling } from '~/utils/api-requests';
import { ADMIN_USER_ENDPOINTS, PUBLIC_ENDPOINTS } from './endpoints';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

type TRefreshTokenResponse = {
  access: string;
};

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    if (config.url && PUBLIC_ENDPOINTS.includes(config.url)) return config;

    const token = localStorage.getItem(LocalStorageKey.Admin.Token.AccessToken);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { retry?: boolean };

    // Check if error is due to expired access token
    if (
      error.response?.status === 401
      && !originalRequest.retry
      && error.config?.url
      && !PUBLIC_ENDPOINTS.includes(error.config.url)
    ) {
      originalRequest.retry = true;

      // Attempt to refresh token
      const refreshToken = localStorage.getItem(LocalStorageKey.Admin.Token.RefreshToken);
      if (refreshToken) {
        try {
          const { data } = await axios.post<TRefreshTokenResponse>(
            `${import.meta.env.VITE_API_URL}${ADMIN_USER_ENDPOINTS.REFRESH}`,
            {
              refresh: refreshToken,
            },
          );

          // Store new access token
          localStorage.setItem(LocalStorageKey.Admin.Token.AccessToken, data.access);

          // Update Authorization header and retry the original request
          originalRequest.headers = originalRequest.headers || {};
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          // Refresh token is invalid or expired
          store.dispatch(resetApp());
          localStorage.clear();

          throw refreshError;
        }
      } else {
        // No refresh token available
        store.dispatch(resetApp());
        localStorage.clear();

        throw error;
      }
    }
    defaultErrorHandling(error);
    throw error;
  },
);

export default axiosInstance;
