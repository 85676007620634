import { DragOverlay } from '@dnd-kit/core';
import { DataTableViewFieldItem } from './DataTableViewFieldItem';
import type React from 'react';

type TCustomDataViewOverlayProperties = {
  /**
   * Index of currently dragged field.
   */
  fieldId?: number | null;
};

export const CustomDataViewOverlay: React.FC<TCustomDataViewOverlayProperties> = (props) => {
  const { fieldId = null } = props;

  return (
    <DragOverlay>
      {typeof fieldId === 'number' && <DataTableViewFieldItem fieldId={fieldId} dragging />}
    </DragOverlay>
  );
};
