import { type FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Heading, Message, SearchInput, Text } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useUserManagementFilterOptions, useUsersListManagement } from '~/services';
import { offsetHeight } from '../../constants';
import { GroupPermissionStepper } from '../../stepper';
import { GroupStepContainer } from '../GroupStepContainer';
import { addUserColumns } from './addUserColumns';
import type { TFiltersConfig } from '@purple/ui';
import type { TGroupPermissionBase } from '../../schema';

export const GroupAddUsersForm: FC = () => {
  const [searchParameters] = useSearchParams();
  const { setValue, watch, formState } = useFormContext<TGroupPermissionBase>();

  const { current } = GroupPermissionStepper.useStepper();

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { data, isLoading } = useUsersListManagement({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    role: searchParameters.get('role'),
  });

  const { data: filterOptions, isLoading: isFiltersLoading } = useUserManagementFilterOptions({
    enabled: true,
  });

  const filterConfig: TFiltersConfig = useMemo(() => ({
    categories: [
      {
        label: 'User Role',
        value: 'role',
        filters: filterOptions?.role?.map((role) => ({
          label: role,
          value: role,
        })) || [],
      },
    ],
  }), [filterOptions?.role]);

  const users = watch('users');

  const { table } = useDataTable({
    data: data?.results || [],
    columns: addUserColumns,
    rowCount: data?.count || 0,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => `${originalRow.id}`,
    onSelectionChange(selectedRows) {
      setValue('users', Object.keys(selectedRows));
    },
  });

  useEffect(() => {
    const selectedUsers = users?.reduce<Record<string, boolean>>((acc, user) => {
      acc[user] = true;
      return acc;
    }, {}) || {};
    table.setRowSelection(selectedUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current.id]);

  return (
    <GroupStepContainer offsetHeight={offsetHeight}>
      <div className="flex items-center justify-between gap-2">
        <div className="flex flex-col gap-1">
          <Heading tag="h2" type="heading-500" variant="size-18">
            Users
          </Heading>
          <Text variant="size-14" className="text-grey-950">
            Select one or more users and mass add to the group
          </Text>
        </div>
        {formState.errors.users && (
          <Message variant="error" className="max-w-[300px]">
            <Text variant="size-14">Please select at least one user</Text>
          </Message>
        )}

      </div>
      <DataTable table={table} loading={isLoading}>
        <div className="flex w-full items-center justify-between gap-4 p-4">
          <div className="flex items-center gap-4">
            <AppFilters config={filterConfig} loading={isFiltersLoading} />
            <SearchInput
              value={search}
              placeholder="Search by name "
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </div>
          <DataTableViewOptions table={table} />
        </div>
        <AppSelectedFiltersList config={filterConfig} className="pb-4" />
      </DataTable>
    </GroupStepContainer>
  );
};
