/* eslint-disable react/no-array-index-key */
import { Skeleton, TableBody, TableCell, TableRow } from '@purple/ui';
import type React from 'react';

const DEFAULT_CELL_WIDTH = ['auto'];

type TDataTableSkeletonProperties = React.HTMLAttributes<HTMLTableSectionElement> & {
  /**
   * The number of columns in the table.
   * @type number
   */
  columnCount: number;
  /**
   * The number of rows in the table.
   * @default 10
   * @type number | undefined
   */
  rowCount?: number;
  /**
   * Flag to show the table view options.
   * @default undefined
   * @type boolean | undefined
   */
  showViewOptions?: boolean;
  /**
   * The width of each cell in the table.
   * The length of the array should be equal to the columnCount.
   * Any valid CSS width value is accepted.
   * @default ["auto"]
   * @type string[] | undefined
   */
  cellWidths?: string[];
  /**
   * Flag to prevent the table cells from shrinking.
   * @default false
   * @type boolean | undefined
   */
  shrinkZero?: boolean;
};

export const DataTableSkeleton: React.FC<TDataTableSkeletonProperties> = (props) => {
  const {
    columnCount,
    rowCount = 10,
    cellWidths = DEFAULT_CELL_WIDTH,
    shrinkZero = false,
    className,
    ...skeletonProperties
  } = props;

  return (
    <TableBody className={className} {...skeletonProperties}>
      {Array.from({ length: rowCount }).map((_, index) => (
        <TableRow key={index} className="h-14 border-b border-grey-200 bg-white hover:bg-transparent">
          {Array.from({ length: columnCount }).map((__, cellIndex) => (
            <TableCell
              key={cellIndex}
              style={{
                width: cellWidths[cellIndex],
                minWidth: shrinkZero ? cellWidths[cellIndex] : 'auto',
              }}
              className="px-4 py-2 align-middle font-primary font-medium text-grey-950 [&:has([role=checkbox])]:leading-[0] [&>[role=checkbox]]:translate-y-0"
            >
              <Skeleton className="h-6 w-full" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
