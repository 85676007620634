import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, PasswordInput } from '@purple/ui';
import { useNewPassword } from '~/services';
import { newPasswordFormSchema } from '../../forgot-password-form-schema';
import type { FC } from 'react';
import type * as z from 'zod';

type TFormValues = z.infer<typeof newPasswordFormSchema>;

type TNewPasswordFormProperties = {
  userId: string;
  token: string;
};

export const NewPasswordForm: FC<TNewPasswordFormProperties> = ({ userId, token }) => {
  const { mutate, isPending } = useNewPassword();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(newPasswordFormSchema),
    defaultValues: {
      new_password1: '',
      new_password2: '',
    },
  });

  const handleFormSubmit = (data: TFormValues) => {
    mutate({ ...data, uid: userId, token });
  };

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4" onSubmit={form.handleSubmit(handleFormSubmit)}>
      <FormField
        control={form.control}
        name="new_password1"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>New Password</FormLabel>
            <FormControl>
              <PasswordInput
                placeholder="Enter new password"
                {...field}
                type="password"
                isError={!!form.formState.errors.new_password1}
                withRules
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="new_password2"
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Confirm New Password</FormLabel>
            <FormControl>
              <PasswordInput
                placeholder="Enter new password"
                {...field}
                type="password"
                isError={!!form.formState.errors.new_password2}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <Button type="submit" className="mt-3" isLoading={isPending} disabled={!form.formState.isValid}>
        Save & Continue
      </Button>
    </Form>
  );
};
