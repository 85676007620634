export const SCHOOLS_QUERY_KEYS = {
  GET_ADMIN_USER_SCHOOLS_LIST: 'get-admin-user-schools-list',
  GET_ADMIN_SCHOOL_HIERARCHY: 'get-admin-school-hierarchy',
  GET_ADMIN_SCHOOL_HIERARCHY_CHOICES: 'get-admin-school-hierarchy-choices',
  UPDATE_ADMIN_SCHOOL_HIERARCHY: 'update-admin-school-hierarchy',
  GET_ALL_SCHOOLS_LIST: 'get-all-schools-list',
  GET_SCHOOL_FILTER_CHOICES: 'get-school-filter-choices',
  DEACTIVATE_SCHOOL: 'deactivate-school',
  ACTIVATE_SCHOOL: 'activate-school',
  GET_SCHOOL_BY_ID: 'get-school-by-id',
  UPDATE_SCHOOL_DETAILS: 'update-school-details',
  GET_SCHOOL_SAF_PLOT: 'get-school-saf-plot',
  GET_SCHOOL_SAF_INSIGHTS: 'get-school-saf-insights',
  GET_SCHOOL_BASIC_DETAILS: 'get-school-basic-details',
  GET_SCHOOL_UPDATE_CHOICES: 'get-school-update-choices',
  UPDATE_SCHOOL_BASIC_DETAILS: 'update-school-basic-details',
} as const;
