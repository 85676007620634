import { Fragment, memo, useCallback, useMemo } from 'react';
import { isFieldExist } from '@purple/shared-utils';
import { Separator } from '@purple/ui';
import { ActionLayoutComponentType, IndividualActionDetailsLayout } from '../../config';
import { BasicDetailsSection } from '../BasicDetailsSection';
import { CrisisDetailsSection } from '../CrisisDetailsSection';
import { CrisisFieldsSection } from '../CrisisFieldsSection';
import { DateTimeSection } from '../DateTimeSection';
import { GoalsSection } from '../GoalsSection';
import { ScreenersResultsSection } from '../ScreenersResultsSection';
import { TagsSection } from '../TagsSection';
import type { FC } from 'react';
import type { TActionDetails } from '@purple/shared-types';
import type { TActionLayoutComponentType } from '../../config';

type TIndividualDetailsProperties = {
  action: TActionDetails;
};

export const IndividualDetails: FC<TIndividualDetailsProperties> = memo(({ action }) => {
  const getSectionNode = useCallback(
    (type: TActionLayoutComponentType) => {
      switch (type) {
        case ActionLayoutComponentType.DETAILS: {
          return {
            type,
            element: <BasicDetailsSection action={action} />,
            isPresented: isFieldExist(action.details),
          };
        }
        case ActionLayoutComponentType.TAGS: {
          return {
            type,
            element: <TagsSection action={action} />,
            isPresented: isFieldExist(action.tags),
          };
        }
        case ActionLayoutComponentType.GOALS: {
          return {
            type,
            element: <GoalsSection action={action} />,
            isPresented: isFieldExist(action.goal_or_progress_towards_goal),
          };
        }
        case ActionLayoutComponentType.DATE_AND_TIME: {
          return {
            type,
            element: <DateTimeSection action={action} />,
            isPresented: isFieldExist(action.date_and_time_details),
          };
        }
        case ActionLayoutComponentType.SCREENERS_AND_RESULTS: {
          return {
            type,
            element: <ScreenersResultsSection action={action} />,
            isPresented: isFieldExist(action.screeners_and_results),
          };
        }
        case ActionLayoutComponentType.CRISIS_FIELDS: {
          return {
            type,
            element: <CrisisFieldsSection action={action} />,
            isPresented:
              isFieldExist(action.parent_caregiver_conversation_summary)
              || isFieldExist(action.risk_level_result_explanation)
              || isFieldExist(action.follow_up_plan),
          };
        }
        case ActionLayoutComponentType.CRISIS_DETAILS: {
          return {
            type,
            element: <CrisisDetailsSection action={action} />,
            isPresented: isFieldExist(action?.crisis_details),
          };
        }
        default: {
          return null;
        }
      }
    },
    [action],
  );

  const layout = useMemo(
    () => IndividualActionDetailsLayout[action.record_action_type] ?? null,
    [action.record_action_type],
  );
  const rawSections = useMemo(
    () => ({
      left:
        layout?.LEFT.map((type) => getSectionNode(type)).flatMap((item) => (item && item.isPresented ? [item] : []))
        ?? [],
      right:
        layout?.RIGHT.map((type) => getSectionNode(type)).flatMap((item) => (item && item.isPresented ? [item] : []))
        ?? [],
    }),
    [layout, getSectionNode],
  );

  const sections = useMemo(() => {
    if (
      (rawSections.left.length === 0 && rawSections.right.length > 0)
      || (rawSections.right.length === 0 && rawSections.left.length > 0)
    ) {
      const source = rawSections.left.length > 0 ? rawSections.left : rawSections.right;
      const mid = Math.floor(source.length / 2);
      return {
        left: source.slice(0, mid),
        right: source.slice(mid),
      };
    }
    return rawSections;
  }, [rawSections]);

  if (layout === null) return null;

  return (
    <div className="flex w-full gap-10">
      {sections.left.length > 0 && (
        <div className="flex w-full flex-col gap-3">
          {sections.left.map((item, index, self) => (
            <Fragment key={item.type}>
              {item.element}
              {index < self.length - 1 && <Separator className="first:hidden last:hidden" />}
            </Fragment>
          ))}
        </div>
      )}
      <Separator orientation="vertical" className="first:hidden last:hidden" />
      {sections.right.length > 0 && (
        <div className="flex w-full flex-col gap-3">
          {sections.right.map((item, index, self) => (
            <Fragment key={item.type}>
              {item.element}
              {index < self.length - 1 && <Separator className="first:hidden last:hidden" />}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
});
