import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Checkbox,
  Form,
  Input,
  Label,
  NoDataAvailable,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
} from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType, VisualizerStep } from '~/constants';
import { useModal } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useBulkUpdateFlagCalculations, useDeleteFlagCalculation, useFlagCalculationsList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ColorTrendsFieldItem } from './ColorTrendsFieldItem';
import { ColorTrendsSkeleton } from './ColorTrendsSkeleton';
import { METRIC_TYPE_OPTIONS } from './const';
import { FlagCalculationModal } from './FlagCalculationModal';
import { NoColorTrends } from './NoColorTrends';
import { colorTrendsSchema } from './schema';
import type { z } from 'zod';
import type { TVisualizerStep } from '~/constants';
import type { TColorTrendMetricType } from '~/services';

type TColorTrendsProps = {
  formId: string;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const ColorTrends: React.FC<TColorTrendsProps> = (props) => {
  const { formId, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const [search, setSearch] = useState<string>('');
  const [selectedType, setSelectedType] = useState<TColorTrendMetricType | null>(null);
  const [deleteFlagId, setDeleteFlagId] = useState<number | null>(null);
  const [editFlagId, setEditFlagId] = useState<number | null>(null);

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_TREND_FLAG);
  const { openModal: openEditModal } = useModal(ModalType.FLAG_CALCULATION);

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { data, refetch: refetchFlags, isFetching } = useFlagCalculationsList({
    districtId,
    enabled: stepQuery === VisualizerStep.COLOR_TRENDS && !!districtId,
  });
  const { mutate: bulkUpdateFlags } = useBulkUpdateFlagCalculations({ shouldRevalidate: false });
  const { mutate: deleteFlag, isPending } = useDeleteFlagCalculation({ districtId });

  const colorTrendsData = useMemo(() => data?.flag_calculations ?? [], [data?.flag_calculations]);
  const defaultValues: z.infer<typeof colorTrendsSchema> = useMemo(
    () => ({
      flag_calculations: colorTrendsData.map((flag) => ({
        id: flag.id,
        name: flag.name,
        type: flag.type,
        grades: flag.grades ?? [],
        number_of_maximum_flags: flag.number_of_maximum_flags,
        is_displayed: flag.is_displayed ?? false,
        is_total: flag.is_total ?? false,
        is_baseline_added: flag.is_baseline_added ?? false,
      })),
    }),
    [colorTrendsData],
  );

  const form = useForm<z.infer<typeof colorTrendsSchema>>({
    resolver: zodResolver(colorTrendsSchema),
    mode: 'onChange',
    defaultValues,
  });

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: 'flag_calculations',
    keyName: 'fieldId',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.fieldId, index])), [fields]);
  const indicators = form.watch('flag_calculations');
  const filteredIndicators = useMemo(
    () => fields.filter((field) =>
      field.name.trim().toLowerCase().includes(search.trim().toLowerCase()) && (!selectedType || field.type === selectedType),
    ),
    [fields, search, selectedType],
  );
  const selectedFlag = useMemo(() => colorTrendsData.find((field) => field.id === editFlagId), [colorTrendsData, editFlagId]);

  const isSomeIndicatorsDisplayed = indicators.some((field) => field.is_displayed);
  const isAllIndicatorsDisplayed = indicators.every((field) => field.is_displayed);

  const isSomeIndicatorsSelected = indicators.some((field) => field.is_total);
  const isAllIndicatorsSelected = indicators.every((field) => field.is_total);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    } else {
      setShouldShowUnsaved(false);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const saveDetailsClickHandler = (formData: z.infer<typeof colorTrendsSchema>) => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    onSubmit?.();
    bulkUpdateFlags(
      {
        district_id: districtId,
        flag_calculations: formData.flag_calculations.map((flag) => ({
          id: flag.id,
          is_displayed: flag.is_displayed,
          is_total: flag.is_total,
        })),
      },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );
  };

  const selectAllIndicatorsDisplayedClickHandler = (value: boolean) => {
    replace(fields.map(
      (field) =>
        filteredIndicators.some((item) => item.id === field.id)
          ? ({ ...field, is_displayed: value })
          : field,
    ));
  };

  const selectAllIndicatorsClickHandler = (value: boolean) => {
    replace(fields.map(
      (field) =>
        filteredIndicators.some((item) => item.id === field.id)
          ? ({ ...field, is_total: value })
          : field,
    ));
  };

  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const typeSelectChangeHandler = (value: TColorTrendMetricType) => {
    setSelectedType(value);
  };

  const clearTypeSelectHandler = () => {
    setSelectedType(null);
  };

  const deleteFlagClickHandler = (id: number) => {
    setDeleteFlagId(id);
    openDeleteModal();
  };

  const deleteFlagHandler = () => {
    if (!deleteFlagId) return;
    deleteFlag(deleteFlagId, {
      onSuccess: () => {
        closeDeleteModal();
        setDeleteFlagId(null);
      },
    });
  };

  const cancelDeleteFlagHandler = () => {
    closeDeleteModal();
    setDeleteFlagId(null);
  };

  const editFlagClickHandler = (id: number) => {
    setEditFlagId(id);
    openEditModal();
  };

  const updateFlagCloseHandler = () => {
    setEditFlagId(null);
  };

  if (isFetching) return <ColorTrendsSkeleton />;

  return (
    <div className="flex size-full flex-col gap-4">
      <div className="flex w-full items-center gap-4">
        <RadixSelect onValueChange={typeSelectChangeHandler} value={selectedType ?? ''}>
          <RadixSelectTrigger className="gap-6" hasClearButton onClearCallback={clearTypeSelectHandler}>
            <RadixSelectValue placeholder="All Types" />
          </RadixSelectTrigger>
          <RadixSelectContent align="start" className="min-w-64">
            {METRIC_TYPE_OPTIONS.map(({ value, label }) => (
              <RadixSelectItem key={value} value={value}>
                {label}
              </RadixSelectItem>
            ))}
          </RadixSelectContent>
        </RadixSelect>
        <div className="relative w-full max-w-[300px]">
          <PurpleIcon name="search" className="absolute left-3 top-2.5 size-5 text-grey-600" />
          <Input type="text" placeholder="Search by flag name" className="pl-10" value={search} onChange={searchChangeHandler} />
        </div>
      </div>
      {indicators.length === 0
        ? (
            <NoColorTrends onSynchronizeData={refetchFlags} />
          )
        : (
            <div className="flex w-full flex-1 flex-col">
              <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
                <strong className="col-span-3 w-full px-3 text-xs font-semibold uppercase text-grey-600">
                  Flag Calculation Name
                </strong>
                <strong className="col-span-2 w-full px-3 text-xs font-semibold uppercase text-grey-600">
                  Type
                </strong>
                <strong className="col-span-1 w-full place-self-center px-3 text-center text-xs font-semibold uppercase text-grey-600">
                  Grades
                </strong>
                <strong className="col-span-2 w-full place-self-center px-3 text-center text-xs font-semibold uppercase text-grey-600">
                  Max Flag Value
                </strong>
                <div className="col-span-3 flex items-center justify-between gap-2 px-3">
                  <div className="flex w-full items-center gap-2">
                    {filteredIndicators.length > 0 && (
                      <Checkbox
                        id="select-all-displayed"
                        variant={isAllIndicatorsDisplayed ? 'default' : 'checkedAll'}
                        checked={isSomeIndicatorsDisplayed}
                        onCheckedChange={selectAllIndicatorsDisplayedClickHandler}
                      />
                    )}
                    <Label htmlFor="select-all-displayed" className="text-xs font-semibold uppercase text-grey-600">
                      Display
                    </Label>
                  </div>
                  <div className="flex w-full items-center gap-2">
                    {filteredIndicators.length > 0 && (
                      <Checkbox
                        id="select-all-total"
                        variant={isAllIndicatorsSelected ? 'default' : 'checkedAll'}
                        checked={isSomeIndicatorsSelected}
                        onCheckedChange={selectAllIndicatorsClickHandler}
                      />
                    )}
                    <Label htmlFor="select-all-total" className="text-xs font-semibold uppercase text-grey-600">
                      Is&nbsp;Total
                    </Label>
                  </div>
                </div>
                <strong className="col-span-1 w-full px-3 text-center text-[0] font-semibold uppercase text-grey-600">
                  Actions
                </strong>
              </div>
              <Form
                providerProps={form}
                id={formId}
                className="flex w-full flex-1 flex-col"
                onSubmit={form.handleSubmit(saveDetailsClickHandler)}
                onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
              >
                {filteredIndicators.map(({ fieldId }) => (
                  <ColorTrendsFieldItem
                    key={fieldId}
                    fieldIndex={indexes.get(fieldId)!}
                    onDelete={deleteFlagClickHandler}
                    onEdit={editFlagClickHandler}
                  />
                ))}
                {filteredIndicators.length === 0 && (
                  <NoDataAvailable
                    iconName="folder-open"
                    className="col-span-12 my-auto px-4 py-12"
                    title="No trends indicators found"
                    description="No trends indicators found for the search criteria. Please try again with a different search term."
                  />
                )}
              </Form>
            </div>
          )}
      <CallToActionModal
        modalName={ModalType.DELETE_TREND_FLAG}
        modalTitle="Delete Flag"
        modalDescription="By deleting the flag, you will lose all the data associated with it. Are you sure you want to delete it?"
        modalTextContent="Are you sure you want to delete the flag? By deleting the flag, you will lose all the data associated with it."
        primaryButtonText={isPending ? 'Deleting...' : 'Delete'}
        secondaryButtonText="Cancel"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={deleteFlagHandler}
        onSecondaryButtonClick={cancelDeleteFlagHandler}
        isLoading={isPending}
      />
      <FlagCalculationModal
        flags={colorTrendsData}
        flagData={selectedFlag}
        onSubmitSuccess={updateFlagCloseHandler}
        onClose={updateFlagCloseHandler}
      />
    </div>
  );
};
