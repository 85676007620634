/* eslint-disable react/no-array-index-key */
import { Link, useLocation } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Guard } from '@purple/permissions';
import { cn, ScrollArea, Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@purple/ui';
import { getNavigationList } from '~/constants';
import { CollapseMenuButton } from './CollapseMenuButton';
import type React from 'react';

type TMenuProperties = {
  isOpen?: boolean;
};

export const Menu: React.FC<TMenuProperties> = (props) => {
  const { isOpen = false } = props;

  const { pathname } = useLocation();
  const menuList = getNavigationList(pathname);

  return (
    <ScrollArea className="[&>div>div[style]]:!block">
      <nav className="size-full">
        <ul className="flex min-h-max flex-col items-start">
          {menuList.map(({ groupLabel, menus }, menuIndex) => (
            <li className={cn('w-full', groupLabel ? 'pt-5' : '')} key={menuIndex}>
              {isOpen && groupLabel
                ? (
                    <p className="max-w-[224px] truncate px-4 pb-2 text-sm font-medium text-grey-600">{groupLabel}</p>
                  )
                : !isOpen && groupLabel
                    ? (
                        <TooltipProvider>
                          <Tooltip delayDuration={100}>
                            <TooltipTrigger className="flex w-full items-center justify-center">
                              <PurpleIcon name="dots-horizontal" className="size-5" />
                            </TooltipTrigger>
                            <TooltipPortal>
                              <TooltipContent side="right">{groupLabel}</TooltipContent>
                            </TooltipPortal>
                          </Tooltip>
                        </TooltipProvider>
                      )
                    : null}
              {menus.map(({ href, label, iconName, active, submenus, permissions }, index) =>
                submenus.length === 0
                  ? (
                      <Guard key={index} requiredPermissions={permissions || []}>
                        <TooltipProvider disableHoverableContent>
                          <Tooltip delayDuration={100}>
                            <TooltipTrigger asChild>
                              <Link
                                to={href}
                                className={cn(
                                  'inline-flex min-h-10 w-full items-center justify-center border-r-4 border-transparent px-4 py-2.5 pr-2 font-primary text-sm font-normal text-grey-title outline-none transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 active:opacity-60 active:transition-none',
                                  {
                                    'pointer-events-none border-brand-blue-700 bg-brand-blue-100 pr-4 font-medium text-brand-blue-700':
                                active,
                                  },
                                  { 'justify-start': isOpen },
                                )}
                              >
                                <PurpleIcon name={iconName} className={cn('size-5 shrink-0', { 'mr-1': isOpen })} />
                                <span
                                  className={cn(
                                    'inline-block max-w-[240px] truncate',
                                    isOpen ? 'translate-x-0 opacity-100' : 'w-0 -translate-x-96 opacity-0',
                                  )}
                                >
                                  {label}
                                </span>
                              </Link>
                            </TooltipTrigger>
                            {!isOpen && (
                              <TooltipPortal>
                                <TooltipContent side="right">{label}</TooltipContent>
                              </TooltipPortal>
                            )}
                          </Tooltip>
                        </TooltipProvider>
                      </Guard>
                    )
                  : (
                      <CollapseMenuButton
                        key={index}
                        iconName={iconName}
                        label={label}
                        active={active}
                        submenus={submenus}
                        isOpen={isOpen}
                      />
                    ),
              )}
            </li>
          ))}
        </ul>
      </nav>
    </ScrollArea>
  );
};
