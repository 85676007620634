import axiosInstance from '../axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';

export const resyncMappingEvents = async ({
  districtId,
  mappingId,
}: { districtId: string; mappingId: string }) => {
  const response = await axiosInstance.post<void>(DISTRICTS_ENDPOINTS.MAPPINGS_EVENTS(districtId, mappingId));
  return response;
};
