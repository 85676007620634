import { PurpleIcon } from '@purple/icons';
import { cn, DropdownContent, DropdownItem, DropdownRoot, DropdownSeparator, DropdownTrigger } from '@purple/ui';
import type { Column } from '@tanstack/react-table';

type TDataTableColumnHeaderProperties<TData, TValue> = React.HTMLAttributes<HTMLDivElement> & {
  column: Column<TData, TValue>;
  title: string;
};

export const DataTableColumnHeader = <TData, TValue>({
  column,
  title,
  className,
}: TDataTableColumnHeaderProperties<TData, TValue>) => {
  if (!column.getCanSort() && !column.getCanHide()) {
    return (
      <span
        className={cn(
          'inline-flex h-8 items-center justify-center whitespace-nowrap font-primary text-xs font-semibold uppercase text-grey-600',
          className,
        )}
      >
        {title}
      </span>
    );
  }

  return (
    <DropdownRoot>
      <DropdownTrigger
        type="button"
        aria-label={
          column.getIsSorted() === 'desc'
            ? 'Sorted descending. Click to sort ascending.'
            : column.getIsSorted() === 'asc'
              ? 'Sorted ascending. Click to sort descending.'
              : 'Not sorted. Click to sort ascending.'
        }
        className="-ml-3 inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded-md px-3 font-primary text-xs font-semibold uppercase text-grey-600 transition-colors hover:bg-brand-blue-100 hover:text-brand-blue-700 focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=open]:bg-brand-blue-100 data-[state=open]:text-brand-blue-700"
      >
        {title}
        {column.getCanSort() && column.getIsSorted() === 'desc'
          ? (
              <PurpleIcon name="arrow-down" className="size-4" aria-hidden="true" />
            )
          : column.getIsSorted() === 'asc'
            ? (
                <PurpleIcon name="arrow-up" className="size-4" aria-hidden="true" />
              )
            : (
                <PurpleIcon name="selector" className="size-4" aria-hidden="true" />
              )}
      </DropdownTrigger>
      <DropdownContent align="start" className="min-w-28 border-grey-200">
        {column.getCanSort() && (
          <>
            <DropdownItem
              aria-label="Sort ascending"
              onClick={() => column.toggleSorting(false)}
              className="relative flex cursor-default select-none items-center px-2 py-1.5 text-sm text-grey-950 outline-none transition-colors focus:bg-brand-blue-200 focus:text-brand-blue-700 data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
            >
              <PurpleIcon name="arrow-up" className="mr-2 size-4" aria-hidden="true" />
              Asc
            </DropdownItem>
            <DropdownItem
              aria-label="Sort descending"
              className="relative flex cursor-default select-none items-center px-2 py-1.5 text-sm text-grey-950 outline-none transition-colors focus:bg-brand-blue-200 focus:text-brand-blue-700 data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              onClick={() => column.toggleSorting(true)}
            >
              <PurpleIcon name="arrow-down" className="mr-2 size-4" aria-hidden="true" />
              Desc
            </DropdownItem>
          </>
        )}
        {column.getCanSort() && column.getCanHide() && <DropdownSeparator className="bg-grey-200" />}
        {column.getCanHide() && (
          <DropdownItem
            aria-label="Hide column"
            className="relative flex cursor-default select-none items-center px-2 py-1.5 text-sm text-grey-950 outline-none transition-colors focus:bg-brand-blue-200 focus:text-brand-blue-700 data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
            onClick={() => column.toggleVisibility(false)}
          >
            <PurpleIcon name="eye-off" className="mr-2 size-4" aria-hidden="true" />
            Hide
          </DropdownItem>
        )}
      </DropdownContent>
    </DropdownRoot>
  );
};
