import * as z from 'zod';

export const dateAndTimeSchema = z.object({
  date: z.date({
    message: 'Date is invalid.',
    required_error: 'Date is required.',
  }),
  duration: z
    .number({
      message: 'Duration must be an integer.',
    })
    .int()
    .min(0, {
      message: 'Duration must be a positive number or zero.',
    })
    .max(720, { message: 'Duration must be less than 720 minutes.' }),
});
