import {
  Card,
  CardContent,
  CardData,
  CardDataDescription,
  CardDataValue,
  CardTitle,
  Heading,
  Text,
} from '@purple/ui';
import type { TSchoolSafInsights } from '~/services';

type TSafAccountabilityProps = {
  insights: TSchoolSafInsights;
};

export const SafAccountability: React.FC<TSafAccountabilityProps> = (props) => {
  const { insights } = props;

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-0.5">
        <Heading tag="h2" type="heading-500" variant="size-18" className="text-base text-grey-title">
          SAF Accountability
        </Heading>
        <Text type="body-400" variant="size-14">
          Tracking key metrics to measure school staff accountability and performance.
        </Text>
      </div>
      <ul className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Average Time from Open to Closed</CardTitle>
          <CardContent className="mt-auto">
            <CardData value={insights.avg_time_until_closed_days ?? 0}>
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataDescription className="text-sm font-semibold text-grey-600">
                Days
              </CardDataDescription>
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Average time Until Prioritized</CardTitle>
          <CardContent className="mt-auto">
            <CardData value={insights.student_safs ?? 0}>
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataDescription className="text-sm font-semibold text-grey-600">
                Days
              </CardDataDescription>
            </CardData>
          </CardContent>
        </Card>
      </ul>
    </section>
  );
};
