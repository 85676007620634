import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName, getLastUrlSegment } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Heading,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@purple/ui';
import defaultDistrictLogo from '~/assets/images/default-district-logo.png';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useUpdateDistrictBranding } from '~/services';
import { UploadImageDialog } from './UploadImageDialog';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

type TBrandingLogoProperties = {
  district: TDistrictDetails;
};

export const BrandingLogo: React.FC<TBrandingLogoProperties> = (props) => {
  const { district } = props;

  const { openModal } = useModal(ModalType.UPLOAD_DISTRICT_LOGO);
  const { openModal: openResetModal, closeModal: closeResetModal } = useModal(ModalType.DELETE_DISTRICT_LOGO);

  const { mutate: updateDistrictBranding, isPending } = useUpdateDistrictBranding();

  const resetDistrictLogoHandler = () => {
    updateDistrictBranding({ id: district.id, logo: null }, { onSuccess: closeResetModal });
  };

  return (
    <section className="flex w-full flex-col items-start justify-between gap-4 rounded-lg border border-grey-100 p-4 sm:flex-row sm:items-center">
      <div className="flex flex-col gap-2">
        <Heading tag="h3" type="heading-500" variant="size-18" className="text-title text-base">
          Logo
        </Heading>
        <div className="flex items-center gap-2">
          <Avatar size={40} variant="circle">
            <AvatarImage src={district.logo ?? defaultDistrictLogo} alt={district.name} className="object-cover" />
            <AvatarFallback>{getInitialsFromName(district.name)}</AvatarFallback>
          </Avatar>
          <Text variant="size-14" type="body-400" className="break-all text-grey-700">
            {district.logo ? getLastUrlSegment(district.logo) : 'Default Logo'}
          </Text>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button type="button" variant="secondary" onClick={openModal}>
          {district.logo ? 'Change Logo' : 'Upload Logo'}
        </Button>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="destructive_secondary"
              size="icon_40"
              className="border-0"
              iconLeft={<PurpleIcon name="trash" />}
              onClick={openResetModal}
            />
          </TooltipTrigger>
          <TooltipContent>Reset logo to default.</TooltipContent>
        </Tooltip>
      </div>
      <UploadImageDialog district={district} />
      <CallToActionModal
        modalName={ModalType.DELETE_DISTRICT_LOGO}
        modalTitle="Reset district logo"
        modalDescription="Resetting the logo will remove the current logo and replace it with the default logo."
        modalTextContent="Resetting the logo will remove the current logo and replace it with the default logo."
        primaryButtonText="Reset"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={resetDistrictLogoHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
      />
    </section>
  );
};
