import type { ValueOf } from '@purple/shared-types';

export const VisualizerStep = {
  DATA_TABLE_VIEW: 'data-table-view',
  SUBPOPULATION_FILTERS: 'subpopulation-filters',
  ABSENCES_VISUALIZER: 'absences-visualizer',
  COLOR_TRENDS_PULL_DATES: 'color-trends-pull-dates',
  COLOR_TRENDS: 'color-trends',
} as const;
export type TVisualizerStep = ValueOf<typeof VisualizerStep>;

export const VisualizerStepReadable = {
  [VisualizerStep.DATA_TABLE_VIEW]: 'Data Table View',
  [VisualizerStep.SUBPOPULATION_FILTERS]: 'Subpopulation Filters',
  [VisualizerStep.ABSENCES_VISUALIZER]: 'Absences Visualizer',
  [VisualizerStep.COLOR_TRENDS_PULL_DATES]: 'Color Trends Pull Dates',
  [VisualizerStep.COLOR_TRENDS]: 'Color Trends',
} as const;

export const VisualizerStepSequence = {
  [VisualizerStep.DATA_TABLE_VIEW]: 1,
  [VisualizerStep.SUBPOPULATION_FILTERS]: 2,
  [VisualizerStep.ABSENCES_VISUALIZER]: 3,
  [VisualizerStep.COLOR_TRENDS_PULL_DATES]: 4,
  [VisualizerStep.COLOR_TRENDS]: 5,
} as const;

export const VisualizerStepToStateKey = {
  [VisualizerStep.DATA_TABLE_VIEW]: 'is_data_table_view_configured',
  [VisualizerStep.SUBPOPULATION_FILTERS]: 'is_subpopulation_filters_configured',
  [VisualizerStep.ABSENCES_VISUALIZER]: 'is_absences_visualizer_configured',
  [VisualizerStep.COLOR_TRENDS_PULL_DATES]: 'is_color_trends_pull_dates_configured',
  [VisualizerStep.COLOR_TRENDS]: 'is_color_trends_configured',
} as const;

export const VisualizerStepDescription = {
  [VisualizerStep.DATA_TABLE_VIEW]: 'Set up available data point you would like to see on the color trends visualizer in their system',
  [VisualizerStep.SUBPOPULATION_FILTERS]: 'These filters are based on student demographic file provided by district SIS',
  [VisualizerStep.ABSENCES_VISUALIZER]: 'Set up the absences threshold for each month for the district',
  [VisualizerStep.COLOR_TRENDS_PULL_DATES]: 'Flag pull dates for the current academic year',
  [VisualizerStep.COLOR_TRENDS]: 'Set up color trends indicators including designated baseline metric',
} as const;
