import { useFormContext } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import { Banner, BannerDescription, Checkbox, FormControl, FormField, FormItem, FormLabel, Text } from '@purple/ui';
import type { z } from 'zod';
import type { baselineSchema, flagCalculationBaseSchema } from '../schema';

export const BaselineField: React.FC = () => {
  const ctx = useFormContext<z.infer<typeof baselineSchema & typeof flagCalculationBaseSchema>>();

  const isBaselineChecked = ctx.watch('is_baseline_added');
  const isBaselineExists = ctx.watch('_isBaselineExists');
  const isFlagBaseline = ctx.watch('_isFlagBaseline');

  return (
    <div className="col-span-2 flex w-full flex-col gap-4">
      <FormField
        control={ctx.control}
        name="is_baseline_added"
        render={({ field }) => (
          <FormItem className="col-span-2 flex w-full flex-row gap-2 space-y-0">
            <FormControl>
              <Checkbox
                {...field}
                value={field.value.toString()}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="flex flex-col items-start gap-0.5">
              <FormLabel>Add Baseline</FormLabel>
              <Text tag="p" type="body-400" variant="size-14" className="text-grey-600">
                Only one metric can serve as the baseline
              </Text>
            </div>
          </FormItem>
        )}
      />
      {isBaselineExists && isBaselineChecked && !isFlagBaseline && (
        <Banner variant="warning" className="w-full [&>svg]:text-warning-main">
          <PurpleIcon
            name="information-circle"
            className="size-5"
          />
          <BannerDescription className="text-sm">
            A baseline is already set for another metric. If you choose this metric as the baseline, the current baseline will be removed.
          </BannerDescription>
        </Banner>
      )}
    </div>
  );
};
