import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionsListFilterOptions } from './get-actions-list-filter-options';
import type { AxiosError } from 'axios';
import type { TStudentCenteredActionsFilterOptions } from './actions.types';

export const useActionsListFilterOptions = () => {
  const { isError, error, ...rest } = useQuery<TStudentCenteredActionsFilterOptions, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST_FILTER_OPTIONS],
    queryFn: getActionsListFilterOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch student centered actions filter options');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
