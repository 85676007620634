import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  PasswordInput,
} from '@purple/ui';
import AdminPanelLogo from '~/assets/images/default-logo.png';
import { AdminRoutes, APP_NAME } from '~/constants';
import { useLogin } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { loginFormSchema } from './login-form-schema';
import type * as z from 'zod';

type TFormValues = z.infer<typeof loginFormSchema>;

export const LoginPage: React.FC = () => {
  const formId = useId();

  const { mutate, isPending } = useLogin();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const adminLoginHandler = (data: TFormValues) => {
    mutate(data, {
      onError: (responseError) => {
        const { response } = responseError;
        if (response && response.status === 401) {
          form.setError('email', { message: 'Invalid email or password' });
          form.setError('password', { message: 'Invalid email or password' });
          showErrorToast('System Message', 'Unable to login with provided credentials');
        }
      },
    });
  };

  return (
    <div className="relative flex w-[564px] items-center justify-center rounded-3xl border border-grey-200 bg-white px-[64px] py-[72px] shadow-custom-standard max-h-720:py-[48px]">
      <div className="flex w-full flex-col items-center gap-8">
        <Heading tag="h1" type="heading-600" variant="size-22" className="text-grey-950">
          Login to
          {' '}
          {APP_NAME}
        </Heading>
        <img src={AdminPanelLogo} alt="purple admin logo" className="max-h-auto aspect-square w-[108px] text-center" />

        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4"
          onSubmit={form.handleSubmit(adminLoginHandler)}
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel required>Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    placeholder="Enter your email"
                    type="email"
                    isError={!!form.formState.errors.email}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Password</FormLabel>
                <FormControl>
                  <PasswordInput
                    {...field}
                    placeholder="Enter your password"
                    isError={!!form.formState.errors.password}
                    type="password"
                  />
                </FormControl>
                <div className="grid grid-cols-2">
                  <div>
                    <FormMessage />
                  </div>
                  <Button type="button" variant="link" className="justify-end pt-1" size="link" asChild>
                    <Link to={AdminRoutes.Auth.ForgotPassword.Root.path}>Forgot Password?</Link>
                  </Button>
                </div>
              </FormItem>
            )}
          />
        </Form>
        <Button type="submit" className="w-full" form={formId} isLoading={isPending}>
          Login
        </Button>
      </div>
    </div>
  );
};
