import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@purple/ui';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateBasicDescriptionSchema } from './updateBasicDescriptionSchema';

type TBasicDescriptionEditProperties = {
  form: UseFormReturn<z.infer<typeof updateBasicDescriptionSchema>>;
};

const BasicDescriptionEdit = ({ form }: TBasicDescriptionEditProperties) => {
  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="description"
        render={({ field, formState }) => (
          <FormItem className="col-span-2">
            <FormControl>
              <Textarea
                {...field}
                isError={!!formState.errors.description}
                placeholder="Enter description text"
                responsiveHeight
                className="min-h-[96px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
};

export { BasicDescriptionEdit };
