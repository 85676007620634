import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, Heading, Text } from '@purple/ui';
import { usePublishedDistricts } from '~/services';

type TVisualizerDistrictSelectorProps = {
  selectedDistrictId?: string | null;
  onDistrictChange?: (districtId: string) => void;
};

export const VisualizerDistrictSelector: React.FC<TVisualizerDistrictSelectorProps> = (props) => {
  const { selectedDistrictId = null, onDistrictChange } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data, isFetching } = usePublishedDistricts({ search: debouncedSearchValue, status: DISTRICT_STATUS.PUBLISHED });

  const districts = useMemo(() => data?.results || [], [data]);
  const districtOptions = useMemo(() => districts.map(({ id, name }) => ({ label: name, value: id.toString() })), [districts]);

  const debouncedSearch = useDebouncedCallback(setDebouncedSearchValue, 500);

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <div className="flex flex-col gap-1">
        <Heading tag="h2" type="heading-500" variant="size-18">Student Data Setup Process</Heading>
        <Text type="body-400" variant="size-14" className="text-grey-700">Follow the steps to complete setup process for students data</Text>
      </div>
      <ComboBox modal>
        <ComboBoxTrigger
          placeholder="Select District"
          selectedLabel={districtOptions.find((option) => option.value === selectedDistrictId)?.label}
          className="w-fit min-w-32 max-w-72"
        />
        <ComboBoxContent
          searchPlaceholder="Search district..."
          emptyContent="District not found."
          loading={isFetching}
          shouldFilter={false}
          className="min-w-64"
          align="end"
          onSearchChange={debouncedSearch}
        >
          {districtOptions.map(({ label, value }) => (
            <ComboBoxItem key={value} value={value} selected={value === selectedDistrictId} onSelect={onDistrictChange}>
              {label}
            </ComboBoxItem>
          ))}
        </ComboBoxContent>
      </ComboBox>
    </div>
  );
};
