import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { editRoutingRule } from './edit-routing-rules';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminEditSafRoutingRulesPayload } from './saf.types';

export const useEditRoutingRules = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { id: string; payload: TAdminEditSafRoutingRulesPayload }>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.EDIT_SAF_ROUTING_RULE],
    mutationFn: editRoutingRule,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System Message', 'Failed to edit routing rule');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE] });
    },
  });
};
