import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from '../user';
import { bulkDistrictUsersDeactivate } from './bulk-district-users-deactivate';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError } from '@purple/shared-services';
import type { TDistrictUserDeactivatePayload, TDistrictUserDeactivateResponse } from './districts.types';

export const useDistrictBulkDeactivateUsers = () => {
  const queryClient = useQueryClient();

  return useMutation<TDistrictUserDeactivateResponse, AxiosError, TDistrictUserDeactivatePayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.BULK_DEACTIVATE_USERS],
    mutationFn: bulkDistrictUsersDeactivate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_USERS] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
      showSuccessToast('System message', 'Users were deactivated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to deactivate Users. Please try again later');
    },
  });
};
