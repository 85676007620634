import { z } from 'zod';

export const subpopulationFilterSchema = z.object({
  subpopulations: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      is_displayed: z.boolean(),
      alias: z.string({ invalid_type_error: 'Alias must be a string' }).trim().min(1, { message: 'Alias is required' }),
    }),
  ),
}).superRefine((data, context) => {
  // Check if there are any duplicate of alias
  for (const [index, item] of data.subpopulations.entries()) {
    const otherAliases = [...data.subpopulations.slice(0, index), ...data.subpopulations.slice(index + 1)];
    if (otherAliases.some((p) => p.alias.trim().toLowerCase() === item.alias.trim().toLowerCase())) {
      context.addIssue({
        path: ['subpopulations', index, 'alias'],
        code: z.ZodIssueCode.custom,
        message: 'Alias must be unique',
      });
    }
  }
});
