import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, type TAdminSafSubCategoryView, useUpdateSafDetails } from '~/services';
import { ServiceCategoriesEdit } from './ServiceCategoriesEdit';
import { serviceCategoriesSchema } from './serviceCategoriesSchema';
import { ServiceCategoriesView } from './ServiceCategoriesView';
import type * as z from 'zod';

type TServiceCategoriesAreaProperties = {
  data: TAdminSafBasicDetailsView;
  categories: TAdminSafSubCategoryView[];
};

const ServiceCategoriesArea = ({ data, categories }: TServiceCategoriesAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { service_area_subcategories } = data;

    if (!categories) {
      return {
        service_area_subcategories: service_area_subcategories.map((category) => {
          return {
            custom_id: category.id,
            name: category.name,
            sub_categories: category.sub_categories.map((subCategory) => subCategory.id),
          };
        }),
      };
    }

    const allCategories = categories.map((category) => {
      const foundCategory = service_area_subcategories.find((item) => item.id === category.id);

      /*
      *  NOTE: we show all possible categories
      *  but fill the selected subcategories from the current SAF
      */
      const sub_categories = foundCategory ? foundCategory.sub_categories.map((item) => item.id) : [];

      return {
        custom_id: category.id,
        name: category.name,
        sub_categories,
      };
    });

    return {
      service_area_subcategories: allCategories,
    };
  }, [data, categories]);

  const form = useForm<z.infer<typeof serviceCategoriesSchema>>({
    resolver: zodResolver(serviceCategoriesSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof serviceCategoriesSchema>) => {
    const selectedSubCategories = newFormData.service_area_subcategories.map((category) => category.sub_categories).flat();

    const payload = {
      service_area_subcategories: selectedSubCategories,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Service Categories</Text>}
      />
      {mode === 'view' ? <ServiceCategoriesView data={data} categories={categories} /> : <ServiceCategoriesEdit categories={categories} form={form} />}
    </div>
  );
};

export { ServiceCategoriesArea };
