import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { useDistrictsListBasicInfo } from '~/services';
import type React from 'react';
import type { TDistrictStatus } from '@purple/shared-types';

type TDistrictFilterComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value?: string | null;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange?: (value: string) => void;
  /**
   * Callback that is called when the combobox is cleared.
   */
  onClear?: () => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
  /**
   * The maximum number of districts to fetch.
   * @default 10
   */
  limit?: number;
  /**
   * The status of the districts to fetch.
   */
  status?: TDistrictStatus;
  /**
   * Additional class name for the combobox.
   * @default ''
   */
  className?: string;
  /**
   * Additional class name for the content.
   * @default ''
   */
  contentClassName?: string;
  /**
   * The alignment of the content.
   */
  align?: React.ComponentProps<typeof ComboBoxContent>['align'];
};

export const DistrictFilterComboBox: React.FC<TDistrictFilterComboBoxProperties> = (props) => {
  const {
    value = null,
    isError = false,
    onChange,
    onClear,
    placeholder = 'All districts',
    className,
    limit = 10,
    status,
    contentClassName,
    align,
  } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: allDistrictData, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearchValue,
    limit,
    status,
  });

  const districts = useMemo(() => allDistrictData?.results ?? [], [allDistrictData?.results]);
  const selectedDistrict = useMemo(() => districts.find((district) => district.id.toString() === value) ?? null, [value, districts]);

  const debouncedSearch = useDebouncedCallback(setDebouncedSearchValue, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={isError} placeholder={placeholder} selectedLabel={selectedDistrict?.name} hasClearButton={selectedDistrict !== null} onClear={onClear} className={className} />
      <ComboBoxContent
        loading={isFetching}
        shouldFilter={false}
        searchPlaceholder="Search district..."
        emptyContent="District not found."
        onSearchChange={debouncedSearch}
        className={contentClassName}
        align={align}
      >
        {districts.map(({ id, name }) => (
          <ComboBoxItem key={id} value={id.toString()} selected={value === id.toString()} onSelect={onChange}>
            {name}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
