import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DASHBOARD_QUERY_KEYS } from './dashboard.const';
import { getRoleLogins } from './get-role-logins';
import type { AxiosError } from 'axios';
import type { TDashboardRoleLoginsRequestParameters, TDashboardRoleLoginsResponse } from './dashboard.types';

export const useRoleLogins = (parameters: TDashboardRoleLoginsRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TDashboardRoleLoginsResponse, AxiosError>({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_LOGINS_BY_ROLE, parameters],
    queryFn: () => getRoleLogins(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to get role logins');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
