import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateSubpopulationFilters } from './update-subpopulation-filters';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerSubpopulationFiltersPayload, TVisualizerSubpopulationFiltersResponse } from './visualizer.types';

export const useUpdateSubpopulationFilters = () => {
  const queryClient = useQueryClient();

  return useMutation<TVisualizerSubpopulationFiltersResponse, AxiosError, TVisualizerSubpopulationFiltersPayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_SUBPOPULATION_FILTERS],
    mutationFn: updateSubpopulationFilters,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_SUBPOPULATION_FILTERS, data.id.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.id.toString()],
      });
      showSuccessToast('System message', 'Subpopulation filters were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update subpopulation filters. Please try again later');
    },
  });
};
