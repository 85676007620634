import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { publishDistrict } from './publishDistrict';
import type { AxiosError } from 'axios';

export const usePublishDistrict = () => {
  return useMutation<void, AxiosError, {
    districtId: number;
  }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.PUBLISH_DISTRICT],
    mutationFn: publishDistrict,
    onSuccess: () => {
      showSuccessToast('System message', 'The district was published successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not publish a district. Check the provided information and try again');
      }
    },
  });
};
