import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { parse } from 'date-fns';
import z from 'zod';
import { PurpleIcon } from '@purple/icons';
import { DaysOfWeek } from '@purple/shared-types';
import { DayOfWeekSorter } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormField,
  FormMessage,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useUpdateSchoolDetails } from '~/services';
import { BusinessHoursFieldItem } from './BusinessHoursFieldItem';
import type { TSchoolBasicDetails } from '~/services';

const businessHoursSchema = z
  .object({
    business_days: z.array(
      z.object({
        id: z.number(),
        day: z.nativeEnum(DaysOfWeek),
        start_time: z.string(),
        end_time: z.string(),
        is_active: z.boolean(),
      }),
    ),
  })
  .superRefine((data, context) => {
    if (data.business_days.length === 0) {
      context.addIssue({
        path: ['business_days'],
        code: z.ZodIssueCode.custom,
        message: 'At least one business day is required.',
      });
    }

    if (data.business_days.every((day) => !day.is_active)) {
      context.addIssue({
        path: ['business_days'],
        code: z.ZodIssueCode.custom,
        message: 'At least one business day must be active.',
      });
    }

    if (data.business_days.some((day) => day.is_active)) {
      for (const [index, day] of data.business_days.entries()) {
        if (
          day.is_active
          && parse(day.start_time, 'HH:mm:ss', new Date()) >= parse(day.end_time, 'HH:mm:ss', new Date())
        ) {
          context.addIssue({
            path: ['business_days', index, 'end_time'],
            code: z.ZodIssueCode.custom,
            message: 'End time must be after start time.',
          });
        }
      }
    }
  });

type TBusinessHoursDialogProperties = {
  data: TSchoolBasicDetails;
  onSuccess?: () => void;
};

export const BusinessHoursDialog: React.FC<TBusinessHoursDialogProperties> = (props) => {
  const { data, onSuccess } = props;
  const { isOpen, toggleModal } = useModal(ModalType.UPDATE_SCHOOL_BUSINESS_DAYS);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();

  const defaultValues: z.infer<typeof businessHoursSchema> = useMemo(
    () => ({
      business_days: data.business_days?.length === 7
        ? data.business_days
        : Object.values(DaysOfWeek).map((day, index) => ({
          id: index,
          day,
          is_active: data.business_days?.find((item) => item.day === day)?.is_active ?? false,
          start_time: data.business_days?.find((item) => item.day === day)?.start_time ?? '09:00:00',
          end_time: data.business_days?.find((item) => item.day === day)?.end_time ?? '17:00:00',
        })),
    }),
    [data.business_days],
  );

  const form = useForm<z.infer<typeof businessHoursSchema>>({
    resolver: zodResolver(businessHoursSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'business_days',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.id, index])), [fields]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const cancelClickHandler = () => {
    toggleModal(false);
    form.reset(defaultValues);
  };

  const saveClickHandler = (formData: z.infer<typeof businessHoursSchema>) => {
    updateSchool(
      {
        id: data.id,
        ...formData,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          toggleModal(false);
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] max-w-[564px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>Edit Business Hours</DialogTitle>
            <DialogDescription className="sr-only">
              By editing the business hours, you can set the start and end times for each day of the week.
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="w-full p-6 pr-[30px]">
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {fields
                .sort((a, b) => DayOfWeekSorter[a.day] - DayOfWeekSorter[b.day])
                .map((field) => (
                  <BusinessHoursFieldItem<z.infer<typeof businessHoursSchema>>
                    key={field.id}
                    control={form.control}
                    day={field.day}
                    startTimeName={`business_days.${indexes.get(field.id)!}.start_time`}
                    endTimeName={`business_days.${indexes.get(field.id)!}.end_time`}
                    isActiveName={`business_days.${indexes.get(field.id)!}.is_active`}
                  />
                ))}
              <FormField
                name={'business_days.root' as 'business_days'}
                control={form.control}
                render={() => <FormMessage />}
              />
            </Form>
          </div>
        </ScrollArea>

        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={cancelClickHandler}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            onClick={form.handleSubmit(saveClickHandler)}
            isLoading={isPending}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
