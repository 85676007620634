export const DASHBOARD_QUERY_KEYS = {
  GET_DASHBOARD_TOP_STATISTIC: 'get-dashboard-top-statistic',
  GET_DASHBOARD_LOGINS_BY_ROLE: 'get-dashboard-logins-by-role',
  GET_DASHBOARD_LOGIN_FREQUENCY: 'get-dashboard-login-frequency',
  GET_DASHBOARD_DOCUMENTED_ACTIONS: 'get-dashboard-documented-actions',
  GET_DASHBOARD_LATEST_UPDATES: 'get-dashboard-latest-updates',
} as const;

export const LATEST_UPDATES_EVENT_TYPES = {
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
  PERMISSIONS_UPDATED: 'permissions_updated',
} as const;
