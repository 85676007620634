import { Skeleton } from '@purple/ui';

export const DistrictSafProcessSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[110px]" />
      </div>
      <div className="flex w-full flex-col gap-4 px-6 py-8 pb-0">
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="flex w-full flex-col gap-2 border-b border-grey-200 pb-4 last-of-type:border-0"
          >
            <div className="flex w-full items-center justify-between gap-4">
              <Skeleton
                className="h-6 w-full max-w-[200px]"
                style={{
                  maxWidth: `${Math.floor(Math.random() * 200) + 100}px`,
                }}
              />
              <Skeleton className="h-6 w-full max-w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-0.5">
              <Skeleton
                className="h-5 w-full"
                style={{
                  maxWidth: `${Math.floor(Math.random() * 200) + 800}px`,
                }}
              />
              {index % 2 === 0 && (
                <Skeleton
                  className="h-5 w-full"
                  style={{
                    maxWidth: `${Math.floor(Math.random() * 200) + 800}px`,
                  }}
                />
              )}
            </div>
            <div className="flex w-full flex-col gap-0.5 pt-2">
              <Skeleton
                className="h-5 w-full"
                style={{
                  maxWidth: `${Math.floor(Math.random() * 200) + 200}px`,
                }}
              />
              {index === 4 && (
                <div className="flex w-full flex-col gap-4">
                  <Skeleton className="h-9 w-full max-w-[760px]" />
                  <div className="flex w-full flex-col gap-0.5">
                    {Array.from({ length: 3 }).map((__, _index) => (
                      <Skeleton
                        // eslint-disable-next-line react/no-array-index-key
                        key={_index}
                        className="h-6 w-full"
                        style={{
                          maxWidth: `${Math.floor(Math.random() * 200) + 100}px`,
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
              {index % 2 === 0 && (
                <Skeleton
                  className="h-5 w-full"
                  style={{
                    maxWidth: `${Math.floor(Math.random() * 200) + 200}px`,
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
