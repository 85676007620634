import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Checkbox, Form, Input, Label, NoDataAvailable } from '@purple/ui';
import { VisualizerStep } from '~/constants';
import { useUnsavedChanges } from '~/providers';
import { useSubpopulationFilters, useUpdateSubpopulationFilters } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { subpopulationFilterSchema } from './schema';
import { SubpopulationFiltersFieldItem } from './SubpopulationFiltersFieldItem';
import { SubpopulationFiltersSkeleton } from './SubpopulationFiltersSkeleton';
import type { z } from 'zod';
import type { TVisualizerStep } from '~/constants';

type TSubpopulationFiltersProps = {
  formId: string;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const SubpopulationFilters: React.FC<TSubpopulationFiltersProps> = (props) => {
  const { formId, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const [search, setSearch] = useState<string>('');

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const { data, isFetching } = useSubpopulationFilters({
    districtId,
    enabled: stepQuery === VisualizerStep.SUBPOPULATION_FILTERS && !!districtId,
  });
  const { mutate: updateFilters } = useUpdateSubpopulationFilters();

  const defaultValues: z.infer<typeof subpopulationFilterSchema> = useMemo(
    () => ({
      subpopulations: data?.subpopulations ?? [],
    }),
    [data?.subpopulations],
  );

  const form = useForm<z.infer<typeof subpopulationFilterSchema>>({
    resolver: zodResolver(subpopulationFilterSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'subpopulations',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.id, index])), [fields]);
  const filters = form.watch('subpopulations');
  const filteredSubpopulations = useMemo(
    () => fields.filter((field) =>
      field.name.trim().toLowerCase().includes(search.trim().toLowerCase())
      || field.alias.trim().toLowerCase().includes(search.trim().toLowerCase()),
    ),
    [fields, search],
  );

  const isSomeFilterSelected = filters.some((field) => field.is_displayed);
  const isAllFiltersSelected = filters.every((field) => field.is_displayed);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    } else {
      setShouldShowUnsaved(false);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveDetailsClickHandler = (formData: z.infer<typeof subpopulationFilterSchema>) => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    onSubmit?.();
    updateFilters(
      {
        district_id: districtId,
        subpopulations: formData.subpopulations,
      },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );
  };

  const selectAllFiltersClickHandler = (value: boolean) => {
    form.setValue('subpopulations', fields.map(
      (field) =>
        filteredSubpopulations.some((item) => item.id === field.id)
          ? ({ ...field, is_displayed: value })
          : field,
    ));
  };

  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  if (isFetching) return <SubpopulationFiltersSkeleton />;

  return (
    <div className="flex size-full flex-col gap-4">
      <div className="relative w-full max-w-[300px]">
        <PurpleIcon name="search" className="absolute left-3 top-2.5 size-5 text-grey-600" />
        <Input type="text" placeholder="Search by subpopulation name" className="pl-10" value={search} onChange={searchChangeHandler} />
      </div>
      <div className="flex w-full flex-col">
        <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
          <strong className="col-span-4 w-full px-3 text-xs font-semibold uppercase text-grey-600">
            Subpopulation Name
          </strong>
          <div className="col-span-4 flex w-full items-center gap-2 px-3">
            {filteredSubpopulations.length > 0 && (
              <Checkbox
                id="select-all-filter"
                variant={isAllFiltersSelected ? 'default' : 'checkedAll'}
                checked={isSomeFilterSelected}
                onCheckedChange={selectAllFiltersClickHandler}
              />
            )}
            <Label htmlFor="select-all-filter" className="text-xs font-semibold uppercase text-grey-600">
              Display on Panel
            </Label>
          </div>
          <strong className="col-span-4 w-full px-3 text-xs font-semibold uppercase text-grey-600">
            District Alias
          </strong>
        </div>
        <Form
          providerProps={form}
          id={formId}
          className="flex w-full flex-1 flex-col"
          onSubmit={form.handleSubmit(saveDetailsClickHandler)}
          onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
        >
          {filteredSubpopulations.map((field) => (
            <SubpopulationFiltersFieldItem key={field.id} fieldIndex={indexes.get(field.id)!} />
          ))}
          {filteredSubpopulations.length === 0 && (
            <NoDataAvailable
              iconName="folder-open"
              className="col-span-12 my-auto px-4 py-12"
              title="No subpopulations found"
              description="No subpopulations found for the search criteria. Please try again with a different search term."
            />
          )}
        </Form>
      </div>
    </div>
  );
};
