import axiosInstance from '../axios-config';
import { DASHBOARD_ENDPOINTS } from '../endpoints';
import type {
  TDashboardDocumentedActionsRequestParameters,
  TDashboardDocumentedActionsResponse,
} from './dashboard.types';

export const getDocumentedActions = async (parameters: TDashboardDocumentedActionsRequestParameters) => {
  const response = await axiosInstance.get<TDashboardDocumentedActionsResponse>(
    DASHBOARD_ENDPOINTS.DOCUMENTED_ACTIONS,
    {
      params: {
        ...parameters,
        districts: parameters?.districts?.join(','),
      },
    },
  );
  return response.data;
};
