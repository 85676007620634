import type { ZodNumber, ZodType } from 'zod';

/**
 * Refines a Zod number schema to ensure the number has a maximum precision of the specified decimal places.
 *
 * @template T - A type extending ZodNumber.
 * @param {T} schema - The Zod number schema to refine.
 * @param {number} precision - The maximum number of decimal places allowed.
 * @returns {ZodType<number>} - The refined Zod number schema.
 *
 * @example
 * ```typescript
 * const schema = z.number();
 * const refinedSchema = floatNumber(schema, 2);
 * refinedSchema.parse(1.23); // Passes
 * refinedSchema.parse(1.234); // Fails
 * ```
 */
export const floatNumber = <T extends ZodNumber>(
  schema: T,
  precision: number,
): ZodType<number> =>
  schema.refine((value) => {
    const precisionValue = 10 ** precision;
    return Math.round(value * precisionValue) / precisionValue === value;
  }, {
    message: `Number must have a maximum precision of ${precision} decimal places.`,
  });
