import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';

type TListItemOptionsProperties = {
  onDelete: () => void;
  onTransfer: () => void;
};

const ListItemOptions = ({
  onDelete,
  onTransfer,
}: TListItemOptionsProperties) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const transferClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);

    onTransfer();
  };

  const removeClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);

    onDelete();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          iconLeft={<PurpleIcon name="dots-vertical" />}
          variant="tertiary_icon_only"
          size="icon_40"
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[196px] gap-1" align="end" sideOffset={-2}>
        <Button
          variant="link"
          onClick={transferClickHandler}
          className="w-full justify-start text-grey-950 hover:text-grey-950 active:text-grey-950"
          iconLeft={<PurpleIcon name="share" />}
        >
          Transfer SAF
        </Button>
        <Button
          variant="link_destructive"
          onClick={removeClickHandler}
          className="w-full justify-start"
          iconLeft={<PurpleIcon name="trash" />}
        >
          Delete
        </Button>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ListItemOptions };
