import { useMutation } from '@tanstack/react-query';
import { queryClient } from '~/constants';
import { useAppDispatch } from '~/hooks';
import { resetApp } from '~/store';
import { showSuccessToast } from '~/utils/toasts';
import { ADMIN_AUTH_QUERY_KEYS } from './auth.const';
import { adminLogout } from './log-out';

export const useLogOut = () => {
  const dispatch = useAppDispatch();

  return useMutation({
    mutationKey: [ADMIN_AUTH_QUERY_KEYS.LOG_OUT],
    mutationFn: adminLogout,
    onSettled: () => {
      queryClient.clear();
      localStorage.clear();
      dispatch(resetApp());
      showSuccessToast('System message', 'You have been logged out');
    },
  });
};
