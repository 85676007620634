import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { enUS } from 'date-fns/locale';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, FormControl, FormField, FormItem, FormMessage, HoverCard, HoverCardContent, HoverCardTrigger, NumberInput, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { useUnsavedChanges } from '~/providers';
import type { Month } from 'date-fns';
import type { z } from 'zod';
import type { absenceThresholdSchema } from './schema';

type TAbsencesVisualizerThresholdItemProps = {
  fieldIndex: number;
  onItemSave?: () => void;
};

export const AbsencesVisualizerThresholdItem: React.FC<TAbsencesVisualizerThresholdItemProps> = (props) => {
  const { fieldIndex, onItemSave } = props;

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const ctx = useFormContext<z.infer<typeof absenceThresholdSchema>>();
  const month = (ctx.watch(`thresholds.${fieldIndex}.month`) - 1) as Month;
  const value = ctx.watch(`thresholds.${fieldIndex}.threshold`);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<number>(value);

  useEffect(() => {
    if (savedValue !== value) {
      setShouldShowUnsaved(true);
    } else {
      setShouldShowUnsaved(false);
    }
  }, [savedValue, setShouldShowUnsaved, value]);

  const editThresholdClickHandler = () => {
    setSavedValue(value);
    ctx.trigger(`thresholds.${fieldIndex}.threshold`);
    setIsEditing(true);
  };

  const saveClickHandler = async () => {
    const isValid = await ctx.trigger(`thresholds.${fieldIndex}.threshold`);
    if (!isValid) return;
    setIsEditing(false);
    setShouldShowUnsaved(false);
    onItemSave?.();
  };

  const cancelClickHandler = () => {
    if (savedValue !== value) {
      ctx.setValue(`thresholds.${fieldIndex}.threshold`, savedValue);
    }
    setIsEditing(false);
  };

  useKeyPress('Escape', cancelClickHandler);
  useKeyPress('Enter', saveClickHandler);

  return (
    <div className="grid min-h-14 w-full grid-cols-12 items-center border-b border-grey-300 px-3 py-1.5 last-of-type:border-none">
      <span className="col-span-6 text-sm font-medium text-grey-950">
        {enUS.localize.month(month, { width: 'wide' })}
      </span>
      {isEditing
        ? (
            <FormField
              control={ctx.control}
              name={`thresholds.${fieldIndex}.threshold`}
              render={({ field, fieldState }) => (
                <FormItem className="col-span-5 flex flex-col items-center">
                  <HoverCard open={isEditing}>
                    <HoverCardTrigger asChild>
                      <FormControl>
                        <span className="flex w-fit">
                          <NumberInput
                            {...field}
                            type="number"
                            isError={!!fieldState.error}
                            placeholder="Enter threshold"
                            className="max-w-44"
                            min={0}
                            changeOnWheel
                          />
                        </span>
                      </FormControl>
                    </HoverCardTrigger>
                    <HoverCardContent
                      align="end"
                      side={fieldState.error ? 'top' : 'bottom'}
                      sideOffset={fieldState.error ? 8 : 0}
                      className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                    >
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            type="button"
                            variant="primary"
                            size="icon_32"
                            className="shadow-custom-medium"
                            disabled={!!fieldState.error}
                            iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                            onClick={saveClickHandler}
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent>Save</TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            type="button"
                            variant="secondary"
                            size="icon_32"
                            className="border-0 shadow-custom-medium"
                            iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                            onClick={cancelClickHandler}
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent>Cancel</TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                    </HoverCardContent>
                  </HoverCard>
                  <FormMessage />
                </FormItem>
              )}
            />
          )
        : (
            <span className="col-span-5 text-center text-sm font-medium text-grey-950">{value}</span>
          )}
      {!isEditing && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="col-span-1 size-7 place-self-center justify-self-end"
              iconLeft={<PurpleIcon name="pencil" className="shrink-0" />}
              onClick={editThresholdClickHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>
              Edit threshold
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
    </div>
  );
};
