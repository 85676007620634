import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictsListQueryParameters, TPublishedDistrictsResponse } from './districts.types';

export const getPublishedDistricts = async (parameters?: TDistrictsListQueryParameters) => {
  const response = await axiosInstance.get<TPublishedDistrictsResponse>(DISTRICTS_ENDPOINTS.PUBLISHED_LIST, {
    params: parameters,
  });
  return response.data;
};
