import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { deleteFlagCalculation } from './delete-flag-calculation';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';

export const useDeleteFlagCalculation = (options?: { districtId?: string | number | null }) => {
  const { districtId } = options || {};
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [VISUALIZER_QUERY_KEYS.DELETE_FLAG_CALCULATION],
    mutationFn: deleteFlagCalculation,
    onSuccess: () => {
      if (districtId) {
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_LIST_OF_FLAG_CALCULATIONS, districtId.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, districtId.toString()],
        });
      }
      showSuccessToast('System message', 'Flag calculation was deleted successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to delete flag calculation. Please try again later');
    },
  });
};
