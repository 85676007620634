import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { getInitialsFromName } from '@purple/shared-utils';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
// hooks
import { useCurrentUser, useModal } from '~/hooks';
// helpers
import { AdminRoutes, ModalType } from '~/constants';

export const UserDropdown: React.FC = () => {
  const { openModal } = useModal(ModalType.LOG_OUT);

  const { user, isLoading } = useCurrentUser();

  const userName = useMemo(() => user?.full_name ?? 'Unknown User', [user]);

  if (isLoading || !user) {
    return <Skeleton className="size-9 rounded-lg" />;
  }

  return (
    <DropdownRoot>
      <DropdownTrigger className="rounded-lg border border-transparent transition-colors duration-200 hover:border-brand-blue-700 focus-visible:border-brand-blue-700 focus-visible:outline-none">
        <Tooltip>
          <TooltipTrigger asChild>
            <Avatar size={36} variant="square">
              <AvatarImage src={user.avatar ?? undefined} alt={userName} />
              <AvatarFallback>{getInitialsFromName(userName)}</AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>{userName}</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </DropdownTrigger>
      <DropdownContent align="end" sideOffset={4} className="min-w-[232px] border-brand-blue-700">
        <DropdownItem asChild>
          <Link
            to={AdminRoutes.App.Home.Root.path}
            className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
          >
            <PurpleIcon name="user" className="mr-2 size-5 shrink-0" />
            My Profile
          </Link>
        </DropdownItem>
        <DropdownItem
          iconName="logout"
          className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
          onClick={openModal}
        >
          Log Out
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};
