import { type FC, Fragment, memo, useMemo } from 'react';
import { isFieldExist } from '@purple/shared-utils';
import { Separator } from '@purple/ui';
import { ActionSectionHeader } from '../ActionSectionHeader';
import type { TActionDetails } from '@purple/shared-types';

type TCrisisFieldsSectionProperties = {
  action: TActionDetails;
};

export const CrisisFieldsSection: FC<TCrisisFieldsSectionProperties> = memo(({ action }) => {
  const fieldsData = useMemo(
    () =>
      [
        {
          title: 'Parent / Caregiver Conversation Summary',
          content: action.parent_caregiver_conversation_summary ?? '-',
          available: isFieldExist(action.parent_caregiver_conversation_summary),
        },
        {
          title: 'Risk Level Result Explanation',
          content: action.risk_level_result_explanation ?? '-',
          available: isFieldExist(action.risk_level_result_explanation),
        },
        {
          title: 'Follow Up Plan',
          content: action.follow_up_plan ?? '-',
          available: isFieldExist(action.follow_up_plan),
        },
      ].filter(({ available }) => available),
    [action],
  );

  const isEmpty = useMemo(() => fieldsData.length === 0, [fieldsData]);

  if (isEmpty) {
    return null;
  }

  return (
    <div className="flex w-full flex-col gap-2">
      {fieldsData.map(({ title, content }, index) => (
        <Fragment key={title}>
          <ActionSectionHeader title={title} disableEdit />
          <p className="text-balance pb-2 font-primary text-base font-normal text-grey-600">{content}</p>
          {index < fieldsData.length - 1 && <Separator />}
        </Fragment>
      ))}
    </div>
  );
});
