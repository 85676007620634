import { useFormContext } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import { cutGradeName } from '@purple/shared-utils';
import {
  Button,
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  NumberBadge,
  TipBox,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { ReadableColorTrendMetricType } from './const';
import type { z } from 'zod';
import type { colorTrendsSchema } from './schema';

type TColorTrendsFieldItemItemProps = {
  fieldIndex: number;
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
};

export const ColorTrendsFieldItem: React.FC<TColorTrendsFieldItemItemProps> = (props) => {
  const { fieldIndex, onDelete, onEdit } = props;

  const ctx = useFormContext<z.infer<typeof colorTrendsSchema>>();

  const id = ctx.watch(`flag_calculations.${fieldIndex}.id`);
  const grades = ctx.watch(`flag_calculations.${fieldIndex}.grades`) ?? [];
  const [firstGrade] = grades;

  const isGradesAvailable = grades.length > 1;

  return (
    <div className="grid min-h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-1.5 last-of-type:border-none">
      <div className="col-span-3 flex items-center gap-2 px-3">
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 min-w-0 text-sm font-medium text-grey-950">
              {ctx.watch(`flag_calculations.${fieldIndex}.name`)}
            </span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {ctx.watch(`flag_calculations.${fieldIndex}.name`)}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
        {ctx.watch(`flag_calculations.${fieldIndex}.is_baseline_added`) && (
          <TipBox
            text="This flag serves as the baseline"
            iconName="badge-check"
            className="[&>svg]:size-5 [&>svg]:text-success-main"
          />
        )}
      </div>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="col-span-2 line-clamp-1 min-w-0 px-3 text-sm font-medium text-grey-950">
            {ReadableColorTrendMetricType[ctx.watch(`flag_calculations.${fieldIndex}.type`)]}
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {ReadableColorTrendMetricType[ctx.watch(`flag_calculations.${fieldIndex}.type`)]}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <HoverCard>
        <HoverCardTrigger asChild>
          <NumberBadge variant="neutral" className="col-span-1 place-self-center rounded-full px-3 text-center text-sm font-medium text-grey-950">
            {firstGrade ? cutGradeName(firstGrade) : 'All'}
          </NumberBadge>
        </HoverCardTrigger>
        {isGradesAvailable && (
          <HoverCardPortal>
            <HoverCardContent>
              <ul className="flex w-full flex-col">
                {[...grades]
                  .sort((a, b) => a.localeCompare(b, 'en', { numeric: true }))
                  .map((grade) => (
                    <li
                      key={grade}
                      className="inline-flex w-full p-1 pl-4 font-primary text-xs font-normal text-grey-950"
                    >
                      {(grade)}
                    </li>
                  ))}
              </ul>
            </HoverCardContent>
          </HoverCardPortal>
        )}
      </HoverCard>
      <span className="col-span-2 place-self-center px-3 text-center text-sm font-medium text-grey-950">
        {ctx.watch(`flag_calculations.${fieldIndex}.number_of_maximum_flags`) ?? 'N/A'}
      </span>
      <div className="col-span-3 flex items-center justify-between gap-2 px-3">
        <FormField
          control={ctx.control}
          name={`flag_calculations.${fieldIndex}.is_displayed`}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={ctx.control}
          name={`flag_calculations.${fieldIndex}.is_total`}
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      <div className="col-span-1 flex items-center gap-1 justify-self-end px-3">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="shrunk-0 size-8"
              iconLeft={<PurpleIcon name="pencil" className="shrink-0" />}
              onClick={() => onEdit?.(id)}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Edit flag</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="destructive_secondary"
              size="icon_32"
              className="shrunk-0 size-8 border-0"
              iconLeft={<PurpleIcon name="trash" className="shrink-0" />}
              onClick={() => onDelete?.(id)}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Delete flag</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    </div>
  );
};
