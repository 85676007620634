import * as z from 'zod';
import { SSO_PROVIDERS } from '~/services';

export const addSsoConnectionSchema = z.object({
  provider_type: z.nativeEnum(SSO_PROVIDERS, { message: 'Please select a provider' }),
  idp_sso_url: z
    .string()
    .trim()
    .url({ message: 'Please provide a valid url' })
    .min(1, { message: 'SSO URL is required' }),
  idp_entity_id: z
    .string()
    .trim()
    .url({ message: 'Please provide a valid url' })
    .min(1, { message: 'Entity ID is required' }),
  idp_certificate: z
    .string()
    .trim()
    .min(1, { message: 'Certificate is required' }),
  idp_sha_fingerprint: z.string().trim().optional(),
  idp_logout_url: z
    .string()
    .trim()
    .optional()
    .refine((val) => val === undefined || val === '' || z.string().url().safeParse(val).success, {
      message: 'Please provide a valid url',
    }),
});
