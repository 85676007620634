import { z } from 'zod';

export const absenceThresholdSchema = z.object({
  thresholds: z.array(
    z.object({
      id: z.number(),
      threshold: z
        .number({
          invalid_type_error: 'Threshold must be a valid number',
          required_error: 'Threshold is required',
        })
        .gte(0, 'Threshold must be greater than or equal to 0'),
      month: z.number(),
    }),
  ),
});
