import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { differenceInSeconds, parseISO } from 'date-fns';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, type ValueOf } from '@purple/shared-types';
import { Button, cn, Skeleton, Text, Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@purple/ui';
import { DataTable } from '~/components';
import { useDataTable } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, useMappingEvents, useResyncMappingEvents, useSisMappingDetails } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { SisDataQueryName, SisDataViewType } from './constants';
import { useSisMappingEventsColumns } from './useSisMappingEventsColumns';

export const SisDataDetails = () => {
  const queryClient = useQueryClient();
  const [searchParameters] = useSearchParams();
  const { id: districtId } = useParams();

  const { onQueryChange: onViewChange } = useQueryParameter<ValueOf<typeof SisDataViewType>>({
    queryName: SisDataQueryName.VIEW,
  });
  const { query: mappingId } = useQueryParameter({
    queryName: SisDataQueryName.MAPPING_ID,
  });

  const { data: mappingDetails, isLoading: isDetailsLOading } = useSisMappingDetails(districtId as string, mappingId as string);
  const { data: mappingEvents, isLoading: isEventsLoading, refetch, isRefetching } = useMappingEvents({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    districtId: districtId as string,
    mappingId: mappingId as string,
  });
  const { mutate, isPending } = useResyncMappingEvents();

  const isContentLoading = useMemo(() => isDetailsLOading || isEventsLoading, [isDetailsLOading, isEventsLoading]);

  const isButtonDisabled = useMemo(() => {
    if (isContentLoading || isRefetching || !mappingEvents) {
      return true;
    }
    if (mappingEvents.results.length === 0) {
      return true; // No events to resync
    }
    const firstSyncPendingActivity = mappingEvents.results.find(
      (event) => event.status === 'pending' && event.event_type === 'sync',
    );
    if (firstSyncPendingActivity) {
      const createdAt = parseISO(firstSyncPendingActivity.created_at);
      const currentTime = new Date();
      const timeDifferenceInSeconds = differenceInSeconds(currentTime, createdAt);
      const remainingTimeInSeconds = 10 * 60 - timeDifferenceInSeconds;
      return remainingTimeInSeconds > 0;
    }
    return false;
  }, [mappingEvents, isContentLoading, isRefetching]);

  const disabledInfoText = useMemo(() => {
    if (isButtonDisabled && mappingEvents && mappingEvents.results.length === 0) {
      return 'There are no events to resync. You need to create a new mapping event first.';
    }
    if (isButtonDisabled && mappingEvents && mappingEvents.results.length > 0) {
      return 'You have pending synchronisation activity. You should wait for the pending activity to be completed, or for 10 minutes.';
    }
    return null;
  }, [isButtonDisabled, mappingEvents]);

  const columns = useSisMappingEventsColumns();
  const { table } = useDataTable({
    columns,
    data: mappingEvents?.results ?? [],
    rowCount: mappingEvents?.count ?? 0,
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  const resyncSisEventHandler = () => {
    if (!districtId || !mappingId) {
      showErrorToast('System message', 'Unable to resync events for this sis mapping. District ID or Mapping ID is missing');
    }
    mutate({ districtId: districtId as string, mappingId: mappingId as string }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_EVENTS, {
          districtId: districtId as string,
          mappingId: mappingId as string,
        }] });
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, districtId as string] });
      },
    });
  };

  const backToSisDataListClickHandler = () => {
    onViewChange(SisDataViewType.LIST, {
      onSuccess: (urlParameters) => {
        urlParameters.delete(SisDataQueryName.MAPPING_ID);
      },
    });
  };

  return (
    <TooltipProvider>
      <section className="flex flex-col gap-4">
        <Button
          type="button"
          variant="tertiary"
          size="link"
          iconLeft={<PurpleIcon name="chevron-left" />}
          className="w-fit hover:border-transparent hover:bg-transparent hover:text-brand-blue-800 active:border-transparent active:bg-transparent active:text-brand-blue-900 active:opacity-60"
          onClick={backToSisDataListClickHandler}
        >
          Back to SIS Data List
        </Button>
        {isContentLoading
          ? (
              <div className="flex items-center justify-between gap-2">
                <Skeleton className="h-6 w-60" />
                <Skeleton className="h-10 w-[150px]" />
              </div>
            )
          : (
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-4">
                  <Text variant="size-16" type="body-500">{mappingDetails?.name ?? 'Unidentified mapping'}</Text>
                  <Button variant="tertiary" onClick={() => refetch()} size="small" disabled={isRefetching} iconLeft={<PurpleIcon name="refresh" className={cn('w-4 h-4', isRefetching && 'animate-spin')} />}>Refresh</Button>
                </div>
                <div className="flex items-center gap-2">
                  {isButtonDisabled && (
                    <Tooltip>
                      <TooltipTrigger>
                        <PurpleIcon name="exclamation-circle" className="size-6 text-warning-main" />
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          {disabledInfoText}
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )}
                  <Button isLoading={isPending} disabled={isButtonDisabled} onClick={resyncSisEventHandler}>Resync SIS Data</Button>
                </div>
              </div>
            ) }

        <DataTable table={table} loading={isContentLoading} />
      </section>
    </TooltipProvider>
  );
};
