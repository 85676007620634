import axiosInstance from '../axios-config';
import { ADMIN_USER_ENDPOINTS } from '../endpoints';

import type { TAdimResetPasswordPayload, TAdminResetPasswordConfirmPayload } from './auth.types';

export const adminResetPassword = async (payload: TAdimResetPasswordPayload) => {
  const response = await axiosInstance.post<void>(ADMIN_USER_ENDPOINTS.RESET_PASSWORD, payload);
  return response;
};

export const adminResetPasswordConfirm = async (payload: TAdminResetPasswordConfirmPayload) => {
  const { uid, token, ...rest } = payload;
  const response = await axiosInstance.post<void>(
    `${ADMIN_USER_ENDPOINTS.RESET_PASSWORD_CONFIRM}${uid}/${token}/`,
    rest,
  );
  return response;
};
