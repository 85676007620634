import React from 'react';
import { useGetUserPermissions, useUser } from '~/services';

export const useAppInit = () => {
  const { isLoading: isUserInfoLoading, isSuccess: isUserInfoSuccess } = useUser();
  const { isLoading: isPermissionsLoading, data: userPermissions } = useGetUserPermissions({
    enabled: isUserInfoSuccess,
  });

  const isLoading = React.useMemo(
    () => isUserInfoLoading || isPermissionsLoading,
    [isUserInfoLoading, isPermissionsLoading],
  );

  return { isLoading, userPermissions };
};
