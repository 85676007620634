import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TDescriptionViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const DescriptionView = ({ data }: TDescriptionViewProperties) => {
  const { description_translated } = data;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-950">{description_translated}</Text>
      </div>
    </div>
  );
};

export { DescriptionView };
