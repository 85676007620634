import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import { replaceUrlParameters } from '../helpers';
import type { TDistrictLeaderUserStepRequest } from './districts.types';

export const updateDraftDistrictLeadersUsers = async ({
  districtId,
  parameters,
}: {
  districtId: number;
  parameters: TDistrictLeaderUserStepRequest;
}) => {
  const URL = replaceUrlParameters(DISTRICTS_ENDPOINTS.UPDATE_DRAFT_LEADERS_USERS, districtId.toString());

  const response = await axiosInstance.patch<void>(URL, parameters);
  return response;
};
