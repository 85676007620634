import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { createRoutingRule } from './create-routing-rules';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminSafRoutingRulesPayload, TAdminSafRoutingRulesRepose } from './saf.types';

export const useCreateRoutingRules = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<TAdminSafRoutingRulesRepose>, AxiosError, TAdminSafRoutingRulesPayload>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.CREATE_SAF_ROUTING_RULE],
    mutationFn: createRoutingRule,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System Message', 'Failed to create routing rule');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE] });
    },
  });
};
