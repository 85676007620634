import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { deleteAction } from './delete-action';
import type { AxiosError } from '@purple/shared-services';

export const useDeleteAction = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, string>({
    mutationKey: [ACTIONS_QUERY_KEYS.DELETE_ACTION],
    mutationFn: deleteAction,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to delete action');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST] });
    },
  });
};
