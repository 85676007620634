import { Text } from '@purple/ui';
import { TimelineView } from './TimelineView';
import type { TAdminSafBasicDetailsView } from '~/services';

type TTimelineAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const TimelineArea = ({ data }: TTimelineAreaProperties) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <Text variant="size-16" type="body-500" className="text-grey-title">Timeline</Text>
      <TimelineView data={data} />
    </div>
  );
};

export { TimelineArea };
