import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { Form, FormControl, FormField, MultiSelect, MultiSelectItem } from '@purple/ui';
import { useGroupList } from '~/services';
import { DEFAULT_SEARCH_DELAY } from '../BasicDetailArea/constants';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateBasicGroupsSchema } from './updateBasicGroupsSchema';

type TBasicGroupsEditProperties = {
  form: UseFormReturn<z.infer<typeof updateBasicGroupsSchema>>;
};

const BasicGroupsEdit = ({ form }: TBasicGroupsEditProperties) => {
  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const { data: groupsData, isLoading: isGroupsLoading } = useGroupList({
    requestParameters: {
      search: rolesDebouncedSearchValue,
    },
  });

  const groupsSelectOptions = useMemo(() => {
    return (
      groupsData?.results.map((group) => ({
        label: group.name,
        value: group.id.toString(),
      })) ?? []
    );
  }, [groupsData]);

  const groupsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="groups"
        render={({ field }) => (
          <FormControl>
            <MultiSelect
              {...field}
              selectedOptions={groupsSelectOptions.filter((option) => field?.value?.includes(option?.value))}
              showSearch
              shouldFilter={false}
              searchPlaceholder="Search group name..."
              placeholder="Select groups"
              modalPopover
              onOptionChange={field.onChange}
              size="sm"
              onSearchChange={groupsDebouncedSearch}
              emptyContent="Role not found."
              loading={isGroupsLoading}
            >
              {groupsSelectOptions.map((option) => (
                <MultiSelectItem
                  key={option.value}
                  value={option.value}
                  option={option}
                  isSelected={field?.value?.includes(option?.value)}
                />
              ))}
            </MultiSelect>
          </FormControl>
        )}
      />
    </Form>
  );
};

export { BasicGroupsEdit };
