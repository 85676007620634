import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formateDateShortMonthWithTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import type { TAdminSafBasicDetailsView } from '~/services';

type TSafDetailsViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const SafDetailsView = ({ data }: TSafDetailsViewProperties) => {
  const { subject, current_owner, previous_owner, created_at, priority_assigned_at, status, priority, needs } = data;

  const needLabel = useMemo(() => {
    // NOTE: we take first need because we only support one, but backend has array in the database
    const firstNeed = needs?.[0]?.name || '-';
    return firstNeed;
  }, [needs]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Subject</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{subject}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">SAF Owner</Text>
        {current_owner
          ? (
              <Link
                to={AdminRoutes.App.Users.UserDetail.makePath({
                  dynamicParameters: { id: current_owner.id },
                })}
                className="w-full"
              >
                <Text variant="size-16" type="body-500" className="w-full text-brand-blue-700">
                  {
                    current_owner.full_name
                  }
                </Text>
              </Link>
            )
          : (
              <Text variant="size-16" type="body-500" className="w-full text-grey-950">-</Text>
            )}
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Previous SAF Owner</Text>
        {previous_owner
          ? (
              <Link
                to={AdminRoutes.App.Users.UserDetail.makePath({
                  dynamicParameters: { id: previous_owner.id },
                })}
                className="w-full"
              >
                <Text variant="size-16" type="body-500" className="w-full text-brand-blue-700">
                  {
                    previous_owner.full_name
                  }
                </Text>
              </Link>
            )
          : (
              <Text variant="size-16" type="body-500" className="w-full text-grey-950">-</Text>
            )}
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Date</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{formateDateShortMonthWithTime(created_at)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Prioritized Date</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{priority_assigned_at ? formateDateShortMonthWithTime(priority_assigned_at) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Status</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{snakeToCamelWithSpaces(status)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Priority</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{priority ? snakeToCamelWithSpaces(priority) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Need Type</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{needLabel}</Text>
      </div>
    </div>
  );
};

export { SafDetailsView };
