import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { PERMISSION_GROUP_PARAMETERS_KEY } from '../../constants';
import { GroupPermissionStepper } from '../../stepper';
import type { FC } from 'react';

type TGroupPermissionsFooter = {
  formId: string;
  isLoading?: boolean;
};

export const GroupPermissionsFooter: FC<TGroupPermissionsFooter> = ({ formId, isLoading }) => {
  const navigate = useNavigate();
  const { isFirst, isLast, prev } = GroupPermissionStepper.useStepper();

  const { query: groupId } = useQueryParameter({ queryName: PERMISSION_GROUP_PARAMETERS_KEY.groupId });

  const createButtonText = useMemo(() => isLoading ? 'Creating...' : isLast ? 'Create' : 'Next Step', [isLast, isLoading]);
  const updateButtonText = useMemo(() => isLoading ? 'Updating...' : isLast ? 'Update' : 'Next Step', [isLast, isLoading]);

  return (
    <div className="gpa-2 flex items-center justify-between border-t px-6 py-4">
      <Button variant="secondary" onClick={() => navigate(AdminRoutes.App.Users.Permissions.Root.path)}>Cancel</Button>
      <div className="flex items-center gap-4">
        {!isFirst && <Button variant="tertiary" onClick={() => prev()}>Previous Step</Button>}
        <Button variant="primary" type="submit" form={formId} isLoading={isLoading}>{groupId ? updateButtonText : createButtonText}</Button>
      </div>
    </div>
  );
};
