import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { updateIndividualAction } from './update-individual-action';
import type { AxiosError } from 'axios';
import type { TActionDetailsUpdateBody, TActionDetailsUpdateResponse } from '@purple/shared-types';

export const useUpdateAction = () => {
  const queryClient = useQueryClient();

  return useMutation<TActionDetailsUpdateResponse, AxiosError, TActionDetailsUpdateBody>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_ACTION_DETAILS],
    mutationFn: updateIndividualAction,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_INDIVIDUAL_ACTION_DETAILS, data.id] });
      showSuccessToast('System message', 'The action was updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update the action. Check the provided information and try again');
      }
    },
  });
};
