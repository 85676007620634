import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDraftDistrictBranding } from './update-draft-disrtict-branding';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TDraftDistrictBrandingRequest } from './districts.types';

export const useUpdateDraftDistrictBranding = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError<{
    responsible_for_setup: string[];
  }>, {
    districtId: number;
    parameters: TDraftDistrictBrandingRequest;
  }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_DRAFT_DISTRICT_BRANDING],
    mutationFn: updateDraftDistrictBranding,
    onSuccess: () => {
      showSuccessToast('System message', 'Draft was updated successfully');
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL] });
    },
    onError: (error) => {
      if (error.response && error.response.status === 400 && Boolean(error.response.data.responsible_for_setup.length)) {
        const message = error.response.data.responsible_for_setup.join(', ');
        showErrorToast('System message', message);
      } else {
        showErrorToast('System message', 'Could not update a draft. Check the provided information and try again');
      }
    },
  });
};
