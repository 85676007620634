import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { Button, Text } from '@purple/ui';
import { AdminRoutes, ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useDistrict, useDistrictCustomPages } from '~/services';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { DistrictCustomPages } from './DistrictCustomPages';
import { DistrictNavigationPageDialog } from './DistrictNavigationPageDialog';
import { DistrictNavigationSkeleton } from './DistrictNavigationSkeleton';

export const DistrictNavigationTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { openModal } = useModal(ModalType.CREATE_DISTRICT_CUSTOM_PAGE);
  const { data: district, isFetching } = useDistrict({ id: id as string });
  const { data: customPages } = useDistrictCustomPages({ districtId: id as string });

  const pages = useMemo(() => customPages?.results || [], [customPages]);

  if (isFetching || !district) return <DistrictNavigationSkeleton />;

  if (district.status === DISTRICT_STATUS.DRAFT) {
    navigate(AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: district.id } }), {
      replace: true,
    });
  }

  return (
    <DistrictTabContainer
      title="Set Up Navigation"
      className="flex-1 gap-4 py-4"
      headerClassName="py-3"
      actions={(
        <Button type="button" iconLeft={<PurpleIcon name="plus" />} onClick={openModal}>
          Add New Page
        </Button>
      )}
    >
      <Text type="body-400" variant="size-14" className="text-grey-700">
        Standard pages are
        {' '}
        <span className="font-medium">Home, Identify & Act, Monitor, Analytics, Community, and Support.</span>
        <br />
        You can add more pages under the menu item
        {' '}
        <span className="font-medium">&quot;More.&quot;</span>
      </Text>
      <DistrictCustomPages district={district} />
      <DistrictNavigationPageDialog
        districtId={district.id}
        existingPaths={pages.map((page) => page.path)}
        existingNames={pages.map((page) => page.name)}
        modalName={ModalType.CREATE_DISTRICT_CUSTOM_PAGE}
      />
    </DistrictTabContainer>
  );
};
