import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictUserRequestParameters, TDistrictUsersResponse } from './districts.types';

export const getDistrictUsers = async (parameters: TDistrictUserRequestParameters) => {
  const response = await axiosInstance.get<TDistrictUsersResponse>(DISTRICTS_ENDPOINTS.USERS, {
    params: parameters,
  });
  return response.data;
};
