import { Link } from 'react-router-dom';
import { Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import type { TSafOwner } from '~/services';

type TOwnerViewProperties = {
  owner?: TSafOwner;
};

const OwnerView = ({ owner }: TOwnerViewProperties) => {
  if (!owner || !owner.id) {
    return (
      <div className="flex h-8 items-center">
        <Text variant="size-16" type="body-600" className="text-grey-950">-</Text>
      </div>

    );
  }

  return (
    <div className="flex">
      <Link to={AdminRoutes.App.Users.UserDetail.makePath({ dynamicParameters: { id: owner.id } })}>
        <Text variant="size-16" type="body-600" className="text-brand-blue-700">
          {owner.full_name}
        </Text>
      </Link>
    </div>
  );
};

export { OwnerView };
