import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { Button } from '@purple/ui';
import { DataMappingModal } from '~/components/Modals/District';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, useCreateCustomMapping, useSisMappingList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { SisDataListItem } from './SisDataListItem';
import { SisDataListSkeleton } from './SisDataListSkeleton';
import type { TDistrictMapping } from '~/services';

export const SisDataList = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { openModal } = useModal(ModalType.DATA_MAPPING);

  const [selectedMapping, setSelectedData] = useState<TDistrictMapping | null>(null);

  const { data, isLoading } = useSisMappingList(id as string);
  const { mutate, isPending } = useCreateCustomMapping();

  const createUpdateMappingHandler = (dataItem: TDistrictMapping) => {
    setSelectedData(dataItem);
    openModal();
  };

  const createCustomMappingHandler = () => {
    if (!id) {
      showErrorToast('System message', 'Unable to create custom mapping. District ID is missing');
    }
    mutate(
      { districtId: id as string },
      {
        onSuccess: (responseData) => {
          setSelectedData({
            id: responseData.id,
            name: '',
            is_mapping_completed: false,
            is_required: false,
            mapping_type: '',
            last_sync_at: null,
            is_last_sync_successful: null,
          });
          queryClient.invalidateQueries({
            queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, id?.toString() ?? ''],
          });
          openModal();
        },
      },
    );
  };

  return (
    <>
      {isLoading && !data && <SisDataListSkeleton />}
      {data && !isLoading && (
        <div className="flex w-full flex-col gap-4">
          {data.map((item) => (
            <SisDataListItem
              key={item.id}
              mappingItem={item}
              onClick={() => createUpdateMappingHandler(item)}
            />
          ),
          )}
          <Button
            variant="tertiary"
            size="small"
            iconLeft={
              isPending ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="plus" />
            }
            className="w-fit"
            onClick={createCustomMappingHandler}
          >
            Add Custom Students Data
          </Button>
        </div>
      )}
      <DataMappingModal selectedMapping={selectedMapping} districtId={Number.parseInt(id ?? '')} />
    </>
  );
};
