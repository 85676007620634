import { useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { usePublishedDistricts } from '~/services';

type TDistrictSelectorProps = {
  selectedDistrictId?: string | null;
  onDistrictChange?: (districtId: string | null) => void;
};

export const DistrictSelector: React.FC<TDistrictSelectorProps> = (props) => {
  const { selectedDistrictId = null, onDistrictChange } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data, isFetching } = usePublishedDistricts({ search: debouncedSearchValue, status: DISTRICT_STATUS.PUBLISHED });

  const districts = useMemo(() => data?.results || [], [data]);
  const districtOptions = useMemo(() => districts.map(({ id, name }) => ({ label: name, value: id.toString() })), [districts]);

  const debouncedSearch = useDebouncedCallback(setDebouncedSearchValue, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger
        hasClearButton={selectedDistrictId !== null}
        placeholder="All Districts"
        selectedLabel={districtOptions.find((option) => option.value === selectedDistrictId)?.label}
        className="w-fit min-w-32 max-w-72 gap-8"
        onClear={() => onDistrictChange?.(null)}
      />
      <ComboBoxContent
        searchPlaceholder="Search district..."
        emptyContent="District not found."
        loading={isFetching}
        shouldFilter={false}
        className="min-w-64"
        align="start"
        onSearchChange={debouncedSearch}
      >
        {districtOptions.map(({ label, value }) => (
          <ComboBoxItem key={value} value={value} selected={value === selectedDistrictId} onSelect={onDistrictChange}>
            {label}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
