import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminUsersManagementRequestParameters, TUsersManagementResponse } from './user.types';

export const getUsersListManagement = async (parameters: TAdminUsersManagementRequestParameters) => {
  const response = await axiosInstance.get<TUsersManagementResponse>(ADMIN_USER_MANAGEMENT.LIST, {
    params: parameters,
  });
  return response.data;
};
