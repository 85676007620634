import { useParams } from 'react-router-dom';
import { Heading, Separator } from '@purple/ui';
import { useSafBasicDetails, useSafServiceCategories } from '~/services';
import { BasicDetailsTabSkeleton } from './BasicDetailsTabSkeleton';
import { DescriptionArea, SafClosingContextArea, SafDetailsArea, ServiceCategoriesArea, StudentDetailsArea, SubmissionDetailsArea, TimelineArea } from './components';
import { ResolutionArea } from './components/ResolutionArea';

const BasicDetailsTab = () => {
  const { safId } = useParams();

  const { data: basicData, isFetching: isBasicDataLoading } = useSafBasicDetails({
    safId,
    queryOptions: { enabled: !!safId },
  });

  const { data: safServicesCategoriesData, isFetching: isSafServicesLoading } = useSafServiceCategories({
    requestParameters: {
      safId,
    },
    enabled: !!safId,
  });

  if (isBasicDataLoading || !basicData || isSafServicesLoading || !safServicesCategoriesData) return <BasicDetailsTabSkeleton />;

  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="grid grid-cols-2 py-8">
        <div className="flex flex-col gap-4 border-r border-grey-200 px-6 py-0">
          <SafDetailsArea data={basicData} />
          <Separator className="bg-grey-200" />
          <StudentDetailsArea data={basicData} />
          <Separator className="bg-grey-200" />
          <TimelineArea data={basicData} />
        </div>
        <div className="flex flex-col gap-4 px-6 py-0">
          <SubmissionDetailsArea data={basicData} />
          <Separator className="bg-grey-200" />
          <ServiceCategoriesArea data={basicData} categories={safServicesCategoriesData} />
          <Separator className="bg-grey-200" />
          <DescriptionArea data={basicData} />
          <Separator className="bg-grey-200" />
          <ResolutionArea data={basicData} />
          <Separator className="bg-grey-200" />
          <SafClosingContextArea data={basicData} />
        </div>
      </div>
    </div>
  );
};

export { BasicDetailsTab };
