import { type FC, useCallback } from 'react';
import { useQueryParameter } from '@purple/hooks';
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { SAF_EMAIL_KEYS } from './constants';
import type { TSafCorrespondenceListItem } from '@purple/shared-types';

type TEmailSubjectRowProps = {
  email: TSafCorrespondenceListItem;
};

export const EmailSubjectRow: FC<TEmailSubjectRowProps> = ({ email }) => {
  const { onQueryChange } = useQueryParameter({
    queryName: SAF_EMAIL_KEYS.emailId,
  });

  const onEmailSubjectClick = useCallback(() => {
    onQueryChange(email.id);
  }, [email.id, onQueryChange]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="link" onClick={onEmailSubjectClick} className="h-auto w-fit p-0">
          <span className="line-clamp-1 break-all">{email.subject && email.subject.length > 0 ? email.subject : 'No Subject'}</span>
        </Button>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent align="start" withArrow={false}>
          {email.subject && email.subject.length > 0 ? email.subject : 'No Subject'}
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
};
