import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const gradeTextSchema = zodAlwaysRefine(
  z.object({
    unsatisfactory: floatNumber(
      z.number().gte(0, 'Unsatisfactory must be greater than or equal to 0'),
      2,
    ).nullish(),
    needs_improvement: floatNumber(
      z.number().gte(0, 'Needs Improvement must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TGradeTextSchema = z.infer<typeof gradeTextSchema>;

export const gradeTextSchemaSuperRefine = (data: TFlagCalculationBase<typeof gradeTextSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.GRADE || data.data_type !== MetricDataType.TEXT) return;

  if (!isNumber(data.unsatisfactory)) {
    ctx.addIssue({
      path: ['unsatisfactory'],
      code: z.ZodIssueCode.custom,
      message: 'Unsatisfactory is required',
    });
  }
  if (!isNumber(data.needs_improvement)) {
    ctx.addIssue({
      path: ['needs_improvement'],
      code: z.ZodIssueCode.custom,
      message: 'Needs Improvement is required',
    });
  }

  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
