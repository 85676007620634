import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AccessDenied, AppTabs, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useGoBack } from '~/hooks';
import { useSafDetails } from '~/services';
import { BasicDetailsTab, DetailHeader, DetailHeaderSkeleton, DocumentedActionsTab, EmailTab, HistoryTab } from './components';
import { SAF_EMAIL_KEYS } from './components/tabs/EmailTab/constants';
import { SAF_DETAILS_TABS_VALUE } from './constants';
import type { TAppTab } from '@purple/ui';
import type { TAdminSafDetailResponse } from '~/services';

const SafDetails = () => {
  const { safId } = useParams();

  const { onGoBack } = useGoBack({ fallbackPath: AdminRoutes.App.Safs.Root.path });

  const { data: headerData, isLoading: isHeaderDataLoading } = useSafDetails({ safId, queryOptions: { enabled: !!safId } });

  const tabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: SAF_DETAILS_TABS_VALUE.BASIC_DETAILS,
        content: (<BasicDetailsTab />),
        permissions: [],
      },
      {
        label: 'Documented Actions',
        value: SAF_DETAILS_TABS_VALUE.DOCUMENTED_ACTIONS,
        content: (<DocumentedActionsTab />),
        permissions: [],
      },
      {
        label: 'Emails',
        value: SAF_DETAILS_TABS_VALUE.EMAILS,
        content: <EmailTab />,
        permissions: [],
      },
      {
        label: 'History / Timeline',
        value: SAF_DETAILS_TABS_VALUE.HISTORY,
        content: (<HistoryTab />),
        permissions: [],
      },
    ],
    [],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs,
  });

  const showSkeleton = useMemo(() => isHeaderDataLoading || !headerData, [headerData, isHeaderDataLoading]);

  const handleTabChange = (value: string) => {
    tabChangeHandler(value, {
      onSuccess: (params) => {
        if (!params.has(SAF_EMAIL_KEYS.emailId)) return;
        params.delete(SAF_EMAIL_KEYS.emailId);
      },
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <button type="button" className="flex flex-row items-center gap-2" onClick={onGoBack}>
        <PurpleIcon name="chevron-left" className="size-4 text-brand-blue-700" />
        <Text variant="size-14" type="body-600" className="text-brand-blue-700">Back to SAFs</Text>
      </button>
      {showSkeleton ? <DetailHeaderSkeleton /> : <DetailHeader data={headerData as TAdminSafDetailResponse} />}
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={tabs} listClassName="pl-2" value={activeTab} onValueChange={handleTabChange} />
          )}
    </div>
  );
};

export { SafDetails };
