import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { nanoid } from '@reduxjs/toolkit';
import { addDays, format, isFuture, parse } from 'date-fns';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Form } from '@purple/ui';
import { VisualizerStep } from '~/constants';
import { useUnsavedChanges } from '~/providers';
import { useDistrict, usePullDates, useUpdatePullDates } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { PullDateFieldItem } from './PullDateFieldItem';
import { PullDatesSkeleton } from './PullDatesSkeleton';
import { pullDatesSchema } from './schema';
import type { z } from 'zod';
import type { TVisualizerStep } from '~/constants';

type TPullDatesProps = {
  formId: string;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const PullDates: React.FC<TPullDatesProps> = (props) => {
  const { formId, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const { data, isFetching } = usePullDates({
    districtId,
    enabled: stepQuery === VisualizerStep.COLOR_TRENDS_PULL_DATES && !!districtId,
  });
  const { data: district } = useDistrict({ id: districtId as string, enabled: stepQuery === VisualizerStep.COLOR_TRENDS_PULL_DATES && !!districtId });
  const { mutate: updatePullDates } = useUpdatePullDates();

  const defaultValues: z.infer<typeof pullDatesSchema> = useMemo(
    () => ({
      pullDates: (data?.pull_dates ?? []).map((item) => ({
        ...item,
        pull_date: parse(item.pull_date, 'yyyy-MM-dd', new Date()),
      })),
      schoolYearStartDate: district?.school_year_start_datetime ? new Date(district.school_year_start_datetime) : new Date(),
      schoolYearEndDate: district?.school_year_end_datetime ? new Date(district.school_year_end_datetime) : new Date(),
    }),
    [data, district],
  );

  const form = useForm<z.infer<typeof pullDatesSchema>>({
    resolver: zodResolver(pullDatesSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'pullDates',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.id, index])), [fields]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    } else {
      setShouldShowUnsaved(false);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveDetailsClickHandler = (formData: z.infer<typeof pullDatesSchema>) => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    onSubmit?.();
    updatePullDates(
      {
        district_id: districtId,
        pull_dates: formData.pullDates.map((item) => ({
          ...item,
          id: typeof item.id === 'number' ? item.id : undefined,
          pull_date: format(item.pull_date, 'yyyy-MM-dd'),
        })),
      },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );
  };

  const addPullDateClickHandler = () => {
    const lastItem = fields[fields.length - 1];
    const lastItemPullDate = lastItem?.pull_date ?? new Date();
    const refinedLastItemPullDate = isFuture(lastItemPullDate)
      ? addDays(lastItemPullDate, 1)
      : addDays(new Date(), 1);
    append({
      id: nanoid(),
      is_report_card_date: false,
      pull_date: refinedLastItemPullDate,
    });
  };

  const removePullDateClickHandler = (index: number) => {
    remove(index);
  };

  if (isFetching) return <PullDatesSkeleton />;

  return (
    <Form
      providerProps={form}
      id={formId}
      className="flex w-full flex-col gap-4"
      onSubmit={form.handleSubmit(saveDetailsClickHandler)}
      onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
    >
      {fields.map((field, index) => (
        <PullDateFieldItem key={field.id} index={index} fieldIndex={indexes.get(field.id)!} onRemove={removePullDateClickHandler} />
      ))}
      <Button
        type="button"
        variant="tertiary"
        size="small"
        className="w-fit"
        iconLeft={<PurpleIcon name="plus" />}
        onClick={addPullDateClickHandler}
      >
        Add Pull Date
      </Button>
    </Form>
  );
};
