import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { createDistrictSsoSetting } from './create-sso-setting';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError } from 'axios';
import type { TDistrictSsoSettingItem, TDistrictSsoSettingsPayload } from './districts.types';

export const useCreateSsoSettings = () => {
  return useMutation<TDistrictSsoSettingItem, AxiosError<Partial<{ idp_certificate: string[]; idp_sha_fingerprint: string[] }>>, TDistrictSsoSettingsPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.CREATE_DISTRICT_SSO_SETTING],
    mutationFn: createDistrictSsoSetting,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to create SSO setting. Please check provided data and try again.');
      }
    },
  });
};
