import { useCallback, useEffect, useMemo } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, ScrollArea, Separator, Skeleton, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useNote } from '~/services';
import type { JSONContent } from '@tiptap/react';
import type { FC } from 'react';

type TActionNotePreviewModalProperties = {
  noteId: string | null;
  changeNoteId: (noteId: string | null) => void;
};

export const ActionNotePreviewModal: FC<TActionNotePreviewModalProperties> = ({ noteId, changeNoteId }) => {
  const { toggleModal, isOpen } = useModal(ModalType.ACTION_NOTE_PREVIEW);

  const { data: noteDetails, isLoading } = useNote({
    noteId: noteId!,
    queryOptions: {
      enabled: isOpen,
    },
  });

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
      }),
      Placeholder.configure({
        placeholder: () => 'Enter a note here',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: noteDetails ? (JSON.parse(noteDetails.text) as JSONContent) : '',
    editable: false,
    onCreate: ({ editor }) => {
      if (noteDetails?.text) {
        editor.commands.setContent(JSON.parse(noteDetails.text) as string);
      }
    },
  });

  const noteLabel = useMemo(() => (noteDetails?.is_private ? 'My Note' : 'Shared Note'), [noteDetails?.is_private]);

  const toggleModalHandler = useCallback(() => {
    toggleModal(!isOpen);
    changeNoteId(null);
  }, [toggleModal, isOpen, changeNoteId]);

  useEffect(() => {
    if (noteDetails) {
      editorConfig?.commands.setContent(JSON.parse(noteDetails.text) as string);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteDetails?.text]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            {isLoading ? <Skeleton className="h-7 w-[250px]" /> : <DialogTitle className="leading-5">{noteDetails?.title ?? 'Untitled Note'}</DialogTitle>}
            <DialogDescription className="sr-only">{`Details view of the note with the name ${noteDetails?.title}`}</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea
          type="auto"
          className="flex size-full max-h-[640px] min-h-[160px] flex-col px-6 py-4"
          scrollBarClassName="p-2 w-[22px]"
        >
          {isLoading
            ? (
                <Skeleton className="h-28 w-full" />
              )
            : (
                <div className="flex w-full flex-col gap-1">
                  <Text variant="size-14" type="body-600">{noteLabel}</Text>
                  <EditorContent
                    editor={editorConfig}
                    className={cn(
                      'minimal-tiptap-editor flex h-full min-h-[104px] w-full flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 p-2',
                    )}
                  />
                </div>
              )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};
