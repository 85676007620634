import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getListRoles } from './get-list-roles';
import { ROLES_QUERY_KEYS } from './roles.const';
import type { TListRolesRequestParameters } from './roles.types';

type TUseListRolesParameters = {
  requestParameters: TListRolesRequestParameters;
  enabled?: boolean;
};

export const useListRoles = (parameters?: TUseListRolesParameters) => {
  const { requestParameters, enabled = true } = parameters || {};
  const { isError, ...queryData } = useQuery({
    queryKey: [ROLES_QUERY_KEYS.LIST_ROLES, requestParameters],
    queryFn: () => getListRoles(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch roles data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
