import { useTabs } from '@purple/hooks';
import { ACTION_TYPES_TABS_VALUES } from '../constants';
import { BasicDetails } from './BasicDetails';
import { CustomTags } from './CustomTags';
import type { TAppTab } from '@purple/ui';

export const useActionTypesTabs = () => {
  const actionsTypesTabs: TAppTab[] = [
    {
      label: 'Basic Details',
      value: ACTION_TYPES_TABS_VALUES.basicDetails,
      content: <BasicDetails />,
      permissions: [],
    },
    {
      label: 'Custom Tags',
      value: ACTION_TYPES_TABS_VALUES.customTags,
      content: <CustomTags />,
      permissions: [],
    },
  ];

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: actionsTypesTabs,
  });

  return {
    activeTab,
    actionsTypesTabs,
    tabChangeHandler,
  };
};
