import { useMemo, useState } from 'react';
import { useMask } from '@react-input/mask';
import { useDebouncedCallback } from '@purple/hooks';
import { CONTAIN_DIGIT_REGEX, mergeReferences, snakeCaseToCapitalized } from '@purple/shared-utils';
import { Checkbox, Form, FormControl, FormField, FormItem, FormMessage, InfoItem, Input, MultiSelect, MultiSelectItem, Text } from '@purple/ui';
import { PHONE_MASK } from '~/constants/phone';
import { type TUserBasicInfo, useRoleSearch } from '~/services';
import { DEFAULT_SEARCH_DELAY, GRADE_OPTIONS } from './constants';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { updateBasicDetailSchema } from './updateBasicDetailSchema';

type TBasicDetailEditProperties = {
  data: TUserBasicInfo;
  form: UseFormReturn<z.infer<typeof updateBasicDetailSchema>>;
};

const BasicDetailEdit = ({ data, form }: TBasicDetailEditProperties) => {
  const { current_status, email } = data;

  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const { data: rolesData, isLoading: isRolesLoading } = useRoleSearch({
    requestParameters: {
      search: rolesDebouncedSearchValue,
    },
  });

  const rolesSelectOptions = useMemo(() => {
    return (
      rolesData?.results.map((role) => ({
        ...role,
        label: role.name,
        value: role.id.toString(),
      })) ?? []
    );
  }, [rolesData]);

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const phoneInputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  return (
    <Form providerProps={form} className="flex flex-col gap-4">
      <InfoItem label="First Name" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="first_name"
          render={({ field, formState }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!formState.errors.first_name}
                  placeholder="Enter First Name"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Last Name" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="last_name"
          render={({ field, formState }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!formState.errors.last_name}
                  placeholder="Enter Last Name"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      {/* NOTE: Status can not be editable */}
      <div className="flex justify-between gap-2">
        <Text variant="size-16" type="body-400" className="w-fit text-grey-600">Status</Text>
        <Text variant="size-16" type="body-500" className="w-[300px] capitalize text-grey-950">{snakeCaseToCapitalized(current_status)}</Text>
      </div>
      <InfoItem label="Role" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="roles"
          render={({ field }) => (
            <FormControl>
              <MultiSelect
                {...field}
                selectedOptions={rolesSelectOptions.filter((option) => field?.value?.includes(option?.value))}
                showSearch
                shouldFilter={false}
                searchPlaceholder="Search role name..."
                placeholder="Select roles"
                modalPopover
                onOptionChange={field.onChange}
                size="sm"
                onSearchChange={rolesDebouncedSearch}
                emptyContent="Role not found."
                loading={isRolesLoading}
              >
                {rolesSelectOptions.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    isSelected={field?.value?.includes(option?.value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
          )}
        />
      </InfoItem>
      {/* NOTE: Email can not be editable */}
      <div className="flex justify-between gap-2">
        <Text variant="size-16" type="body-400" className="w-fit text-grey-600">Email</Text>
        <Text variant="size-16" type="body-500" className="w-[300px] text-grey-950">{email || '-'}</Text>
      </div>
      <InfoItem label="Phone Number" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="phone_number"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  ref={mergeReferences([field.ref, phoneInputReference])}
                  isError={!!form.formState.errors.phone_number}
                  placeholder={PHONE_MASK}
                  type="tel"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Title/Position" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="title"
          render={({ field, formState }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!formState.errors.first_name}
                  placeholder="Enter Title/Position"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Department" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="department"
          render={({ field, formState }) => (
            <FormItem>
              <FormControl>
                <Input
                  {...field}
                  isError={!!formState.errors.first_name}
                  placeholder="Enter Department"
                  type="text"
                  sizeVariant="small"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </InfoItem>
      <InfoItem label="Responsible for Grades" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="responsible_for_grades"
          render={({ field, fieldState }) => (
            <FormControl>
              <MultiSelect
                {...field}
                isError={!!fieldState.error}
                selectedOptions={GRADE_OPTIONS.filter((option) => field.value?.includes(option.value))}
                shouldFilter={false}
                placeholder="Select Grades"
                modalPopover
                onOptionChange={field.onChange}
                size="sm"
              >
                {GRADE_OPTIONS.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    isSelected={field?.value?.includes(option.value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
          )}
        />
      </InfoItem>
      <InfoItem label="Show On Scorecard" contentClassName="max-w-[300px] w-[300px]">
        <FormField
          control={form.control}
          name="is_show_on_scorecard"
          render={({ field }) => (
            <FormItem className="flex items-center gap-2 space-y-0">
              <FormControl>
                <Checkbox
                  {...field}
                  value={field.value?.toString()}
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </InfoItem>
    </Form>
  );
};

export { BasicDetailEdit };
