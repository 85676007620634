import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { THealthSchema } from './healthSchema';

export const HealthForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Health Metrics
      </Heading>
      <FloatNumberField<THealthSchema> name="nurse_or_health_office_visits" label="Nurse/Health Office Visits" />
      <NumberOfMaxFlagsField className="col-span-1" />
      <BaselineField />
    </div>
  );
};
