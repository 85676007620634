import { APP_PERMISSIONS, usePermissions } from '@purple/permissions';
import { formatDate } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { GroupsActions } from './components/GroupsActions/GroupsActions';
import type { ColumnDef } from '@tanstack/react-table';
import type { TGroupItem } from '~/services';

type TGroupsOptions = {
  onSelectGroup: (groupId: string) => void;
};

type TGroupsListColumns = (options: TGroupsOptions) => ColumnDef<TGroupItem>[];

export const useGroupsColumns: TGroupsListColumns = (options) => {
  const { onSelectGroup } = options;
  const { hasPermissions } = usePermissions();

  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Group name" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all">{row.getValue('name')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('name')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 200,
      meta: { label: 'Group name' },
    },
    {
      accessorKey: 'description',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Description" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 break-all">{row.getValue('description')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('description')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 350,
      meta: { label: 'Description' },
      enableSorting: false,
    },
    {
      accessorKey: 'members_count',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Members" />,
      cell: ({ row }) => (
        <span>{row.getValue('members_count')}</span>
      ),
      size: 60,
      meta: { label: 'Members' },
    },
    {
      accessorKey: 'created_at',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date created" />,
      cell: ({ row: { original } }) => (
        <span>{original.created_at ? formatDate(original.created_at) : '—'}</span>
      ),
      size: 100,
      meta: { label: 'Date created' },
    },
    {
      accessorKey: 'updated_at',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Last modified" />,
      cell: ({ row: { original } }) => (
        <span>{original.updated_at ? formatDate(original.updated_at) : '—'}</span>
      ),
      size: 100,
      meta: { label: 'Last modified' },
    },
    {
      id: 'actions',
      cell: ({ row: { original } }) => {
        if (!hasPermissions(APP_PERMISSIONS.CAN_ACCESS_ADMIN_MANAGE_PERMISSIONS)) return null;
        return (
          <GroupsActions
            groupItem={original}
            onSelectGroup={onSelectGroup}
          />
        );
      },
      size: 64,
      enableHiding: false,
      enableSorting: false,
      meta: { className: 'text-right' },
    },

  ];
};
