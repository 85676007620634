import { z } from 'zod';
import { floatNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, FormulaCondition, GradeFormulaFlagType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const gradeNumericalSchema = zodAlwaysRefine(
  z.object({
    grade_flag_formulas: z.array(
      z.object({
        id: z.number().or(z.string()),
        name: z.string().trim().min(1, 'Name is required'),
        type: z.nativeEnum(GradeFormulaFlagType),
        grade_value: floatNumber(
          z
            .number({ invalid_type_error: 'Grade value should be a number' })
            .gte(0, 'Grade value should be greater than or equal to 0'),
          2,
        ).nullish(),
        flag_value: floatNumber(
          z
            .number({ invalid_type_error: 'Flag value should be a number' })
            .gte(0, 'Flag value should be greater than or equal to 0'),
          2,
        ).nullish(),
        min_grade: floatNumber(
          z
            .number({ invalid_type_error: 'Min grade value should be a number' })
            .gte(0, 'Min grade value should be greater than or equal to 0'),
          2,
        ).nullish(),
        max_grade: floatNumber(
          z
            .number({ invalid_type_error: 'Max grade value should be a number' })
            .gte(0, 'Max grade value should be greater than or equal to 0'),
          2,
        ).nullish(),
        condition: z.nativeEnum(FormulaCondition, { invalid_type_error: 'Condition is required' }).nullish(),
      }),
    ),
  }),
);

export type TGradeNumericalSchema = z.infer<typeof gradeNumericalSchema>;

export const gradeNumericalSchemaSuperRefine = (data: TFlagCalculationBase<typeof gradeNumericalSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.GRADE || data.data_type !== MetricDataType.NUMERICAL) return;

  if (data.grade_flag_formulas.length === 0) {
    ctx.addIssue({
      path: ['grade_flag_formulas'],
      code: z.ZodIssueCode.custom,
      message: 'At least one formula is required',
    });
  }

  for (const [index, formula] of data.grade_flag_formulas.entries()) {
    if (formula.type !== GradeFormulaFlagType.RANGE && (formula.grade_value === undefined || formula.grade_value === null)) {
      ctx.addIssue({
        path: ['grade_flag_formulas', index, 'grade_value'],
        code: z.ZodIssueCode.custom,
        message: 'Grade value is required',
      });
    }
    if (formula.flag_value === undefined || formula.flag_value === null) {
      ctx.addIssue({
        path: ['grade_flag_formulas', index, 'flag_value'],
        code: z.ZodIssueCode.custom,
        message: 'Flag value is required',
      });
    }
    if ((formula.type === GradeFormulaFlagType.THRESHOLD || formula.type === GradeFormulaFlagType.RANGE) && (formula.condition === undefined || formula.condition === null)) {
      ctx.addIssue({
        path: ['grade_flag_formulas', index, 'condition'],
        code: z.ZodIssueCode.custom,
        message: 'Condition is required',
      });
    }
    if (formula.type === GradeFormulaFlagType.RANGE && (formula.max_grade === undefined || formula.max_grade === null)) {
      ctx.addIssue({
        path: ['grade_flag_formulas', index, 'max_grade'],
        code: z.ZodIssueCode.custom,
        message: 'Max grade is required',
      });
    }
    if (formula.type === GradeFormulaFlagType.RANGE && (formula.min_grade === undefined || formula.min_grade === null)) {
      ctx.addIssue({
        path: ['grade_flag_formulas', index, 'min_grade'],
        code: z.ZodIssueCode.custom,
        message: 'Min grade is required',
      });
    }

    // TODO: wait till Den to confirm the logic
    // if (formula.type === GradeFormulaFlagType.THRESHOLD && isNumber(formula.grade_value) && isNumber(formula.flag_value) && formula.condition) {
    //   if (formula.condition === FormulaCondition.GREATER_THAN && formula.grade_value <= formula.flag_value) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'grade_value'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Grade value should be greater than Flag value',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.LESS_THAN && formula.grade_value >= formula.flag_value) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'grade_value'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Grade value should be less than Flag value',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.GREATER_THAN_EQUAL && formula.grade_value < formula.flag_value) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'grade_value'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Grade value should be greater than or equal to Flag value',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.LESS_THAN_EQUAL && formula.grade_value > formula.flag_value) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'grade_value'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Grade value should be less than or equal to Flag value',
    //     });
    //   }
    // }

    // if (formula.type === GradeFormulaFlagType.RANGE && isNumber(formula.min_grade) && isNumber(formula.max_grade)) {
    //   if (formula.condition === FormulaCondition.GREATER_THAN && formula.min_grade <= formula.max_grade) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'max_grade'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Max grade should be less than Min grade',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.LESS_THAN && formula.min_grade >= formula.max_grade) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'max_grade'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Max grade should be greater than Min grade',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.GREATER_THAN_EQUAL && formula.min_grade < formula.max_grade) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'max_grade'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Max grade should be less than or equal to Min grade',
    //     });
    //   }
    //   if (formula.condition === FormulaCondition.LESS_THAN_EQUAL && formula.min_grade > formula.max_grade) {
    //     ctx.addIssue({
    //       path: ['grade_flag_formulas', index, 'max_grade'],
    //       code: z.ZodIssueCode.custom,
    //       message: 'Max grade should be greater than or equal to Min grade',
    //     });
    //   }
    // }
  }

  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
