import { useState } from 'react';
import { Link } from 'react-router-dom';
/* eslint-disable react/no-array-index-key */
import { PurpleIcon } from '@purple/icons';
import { Guard } from '@purple/permissions';
import {
  cn,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownArrow,
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownRoot,
  DropdownSeparator,
  DropdownTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@purple/ui';
import type React from 'react';
import type { TNavigationMenu } from '~/constants';

type TCollapseMenuButtonProperties = Omit<TNavigationMenu, 'href'> & {
  isOpen?: boolean;
};

export const CollapseMenuButton: React.FC<TCollapseMenuButtonProperties> = (props) => {
  const { iconName, label, active, submenus, isOpen = false, permissions } = props;

  const isSubmenuActive = submenus.some((submenu) => submenu.active);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive);

  return isOpen
    ? (
        <Guard requiredPermissions={permissions || []}>
          <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="w-full">
            <CollapsibleTrigger
              className={cn(
                'inline-flex min-h-10 w-full items-center justify-center border-r-4 border-transparent px-4 py-2.5 pr-2 font-primary text-sm font-normal text-grey-title outline-none transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700',
                { 'border-brand-blue-700 bg-brand-blue-100 pr-4 font-medium text-brand-blue-700': active },
                { 'justify-start': isOpen },
              )}
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex items-center">
                  <PurpleIcon name={iconName} className={cn('size-5 shrink-0', { 'mr-1': isOpen })} />
                  <span
                    className={cn(
                      'inline-block max-w-[240px] truncate',
                      isOpen ? 'translate-x-0 opacity-100' : 'w-0 -translate-x-96 opacity-0',
                    )}
                  >
                    {label}
                  </span>
                </div>
                <PurpleIcon
                  name="chevron-down"
                  className={cn(
                    'size-5 shrink-0 whitespace-nowrap transition-transform duration-200',
                    isCollapsed ? 'rotate-180' : 'rotate-0',
                  )}
                />
              </div>
            </CollapsibleTrigger>
            <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
              {submenus.map(({ href, label: subMenuLabel, active: subMenuActive, permissions }, index) => (
                <Guard key={index} requiredPermissions={permissions || []}>
                  <Link
                    to={href}
                    className={cn(
                      'inline-flex min-h-10 w-full items-center justify-start py-2.5 pl-10 pr-2 font-primary text-sm font-normal text-grey-title outline-none transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 active:opacity-60 active:transition-none',
                      isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0',
                      { 'pointer-events-none bg-brand-blue-100 font-medium text-brand-blue-700': subMenuActive },
                    )}
                  >
                    {subMenuLabel}
                  </Link>
                </Guard>
              ))}
            </CollapsibleContent>
          </Collapsible>
        </Guard>
      )
    : (
        <Guard requiredPermissions={permissions || []}>
          <DropdownRoot>
            <TooltipProvider disableHoverableContent>
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <DropdownTrigger
                    className={cn(
                      'inline-flex min-h-10 w-full items-center justify-center border-r-4 border-transparent px-4 py-2.5 pr-2 font-primary text-sm font-normal text-grey-title outline-none transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 active:opacity-60 active:transition-none',
                      { 'border-brand-blue-700 bg-brand-blue-100 pr-4 font-medium text-brand-blue-700': active },
                    )}
                  >
                    <PurpleIcon name={iconName} className="size-5 shrink-0" />
                  </DropdownTrigger>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent side="right">{label}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </TooltipProvider>
            <DropdownContent side="right" align="start" className="border-brand-blue-700">
              <DropdownLabel className="max-w-[180px] truncate text-center text-sm font-medium text-grey-title">
                {label}
              </DropdownLabel>
              <DropdownSeparator className="bg-grey-200" />
              {submenus.map(({ href, label: subMenuLabel, active: subMenuActive }, index) => (
                <DropdownItem
                  key={index}
                  asChild
                  className={cn(
                    'max-w-[180px] cursor-pointer truncate text-grey-950 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none',
                    {
                      'pointer-events-none bg-brand-blue-100 text-brand-blue-700': subMenuActive,
                    },
                  )}
                >
                  <Link to={href}>{subMenuLabel}</Link>
                </DropdownItem>
              ))}
              <DropdownArrow className="fill-brand-blue-700" />
            </DropdownContent>
          </DropdownRoot>
        </Guard>
      );
};
