import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { deleteDistrictNavigationPage } from './delete-district-navigation-page';
import { DISTRICTS_QUERY_KEYS } from './districts.const';

export const useDeleteCustomPage = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [DISTRICTS_QUERY_KEYS.DELETE_DISTRICT_CUSTOM_PAGE],
    mutationFn: deleteDistrictNavigationPage,
    onSuccess: () => {
      // if (districtId) {
      queryClient.invalidateQueries({
        queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_CUSTOM_PAGES],
      });
      // }
      showSuccessToast('System message', 'District custom page was created successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create district custom page. Please try again later');
    },
  });
};
