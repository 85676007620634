import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictNavigationPage } from './update-district-navigation-page';
import type { AxiosError } from '@purple/shared-services';
import type { TDistrictCustomPage } from '@purple/shared-types';
import type { TUpdateDistrictCustomPageOrderPayload } from './districts.types';

export const useUpdateCustomPageOrder = () => {
  return useMutation<TDistrictCustomPage, AxiosError, TUpdateDistrictCustomPageOrderPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_DISTRICT_CUSTOM_PAGE_ORDER],
    mutationFn: updateDistrictNavigationPage,
    onError: () => {
      showErrorToast('System message', 'Failed to update district custom page order. Please try again later');
    },
  });
};
