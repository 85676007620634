import { useParams } from 'react-router-dom';
import { Separator } from '@purple/ui';
import { useSchoolBasicDetails } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { AddressSection } from './AddressSection';
import { BasicDetailsSkeleton } from './BasicDetailsSkeleton';
import { BusinessHoursSection } from './BusinessHoursSection';
import { DescriptionSection } from './DescriptionSection';
import { DetailsSection } from './DetailsSection';
import { HolidaysSection } from './HolidaysSection';
import { OperationalInfoSection } from './OperationalInfoSection';
import { SchoolPerformanceSection } from './SchoolPerformanceSection';
import { TagsSection } from './TagsSection';

export const BasicDetailsTab: React.FC = () => {
  const { id: schoolId } = useParams();

  const { data: schoolDetails, isFetching } = useSchoolBasicDetails(schoolId as string);

  if (isFetching || !schoolDetails) {
    return <BasicDetailsSkeleton />;
  }

  return (
    <SchoolTabContainer title="Basic Details" className="gap-6 lg:flex-row">
      <div className="flex w-full flex-col gap-4">
        <DetailsSection data={schoolDetails} />
        <Separator orientation="horizontal" className="bg-grey-200" />
        <OperationalInfoSection data={schoolDetails} />
        <BusinessHoursSection data={schoolDetails} />
        <HolidaysSection data={schoolDetails} />
        <Separator orientation="horizontal" className="bg-grey-200" />
        <SchoolPerformanceSection data={schoolDetails} />
      </div>
      <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
      <div className="flex w-full flex-col gap-4">
        <AddressSection data={schoolDetails} />
        <Separator orientation="horizontal" className="bg-grey-200" />
        <DescriptionSection data={schoolDetails} />
        <Separator orientation="horizontal" className="bg-grey-200" />
        <TagsSection data={schoolDetails} />
      </div>
    </SchoolTabContainer>
  );
};
