import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictSsoSettings } from './get-sso-settings';
import type { AxiosError } from 'axios';
import type { TDistrictSsoSettingItem } from './districts.types';

export const useDistrictSsoSettings = (districtId: string) => {
  const { isError, error, ...rest } = useQuery<TDistrictSsoSettingItem, AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId],
    queryFn: () => getDistrictSsoSettings({ districtId }),
    enabled: !!districtId,
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve SSO settings. Please try again later.');
    }
  }, [isError, error]);

  return {
    ...rest,
    isError,
    error,
  };
};
