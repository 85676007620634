import { cn } from '@purple/ui';
import type React from 'react';

type TMainContainerProps = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

export const MainContainer: React.FC<TMainContainerProps> = ({ children, className, ...rest }) => {
  return (
    <div className={cn('flex h-full min-h-screen w-full flex-col items-center justify-start bg-grey-50', className)} {...rest}>
      {children}
    </div>
  );
};
