import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from '@purple/ui';
import { ColorSelect } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useUpdateDistrictBranding } from '~/services';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const colorSchema = z.object({
  colorHash: z
    .string({
      invalid_type_error: 'Color code must be a string.',
    })
    .trim()
    .min(1, {
      message: 'Color code is required.',
    })
    .min(4, {
      message: 'Color code must be at least 4 characters.',
    })
    .max(9, {
      message: 'Color code must be less than 9 characters.',
    })
    .startsWith('#', {
      message: 'Color code must start with a #.',
    }),
});

type TBrandColorDialogProperties = {
  district: TDistrictDetails;
};

export const BrandColorDialog: React.FC<TBrandColorDialogProperties> = (props) => {
  const { district } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.BRAND_COLOR_PICKER);

  const { mutate: updateBranding, isPending } = useUpdateDistrictBranding();

  const defaultValues = useMemo(
    () => ({
      colorHash: district.branding_color,
    }),
    [district.branding_color],
  );

  const form = useForm<z.infer<typeof colorSchema>>({
    resolver: zodResolver(colorSchema),
    mode: 'onChange',
    defaultValues,
  });
  const color = form.watch('colorHash');
  const isSubmitDisabled = useMemo(() => district.branding_color === color, [district.branding_color, color]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const submitDistrictColorHandler = (data: z.infer<typeof colorSchema>) => {
    updateBranding(
      {
        id: district.id,
        branding_color: data.colorHash,
      },
      {
        onSuccess: () => {
          closeModal();
          form.reset(defaultValues);
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Change Branding Color</DialogTitle>
          <DialogDescription className="sr-only">Change the branding color for this district.</DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitDistrictColorHandler)}
        >
          <FormField
            control={form.control}
            name="colorHash"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Color</FormLabel>
                <FormControl>
                  <ColorSelect
                    showContrastWarning
                    contrastWarningText="Accent color isn’t legible. This accent color will make parts of your app hard to see."
                    color={field.value}
                    onColorChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} disabled={isSubmitDisabled} isLoading={isPending}>
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
