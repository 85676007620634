import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import { replaceUrlParameters } from '../helpers';
import type { TDistrictDraftDetail } from '@purple/shared-types';

export const getDraftDistrictDetail = async (districtId: number) => {
  const URL = replaceUrlParameters(DISTRICTS_ENDPOINTS.DRAFTED_DETAIL, districtId.toString());

  const response = await axiosInstance.get<TDistrictDraftDetail>(URL);
  return response.data;
};
