import * as z from 'zod';

export const manageRolesSchema = z.object({
  name: z.string().trim().min(1, { message: 'Role Name is required' }),
  districts: z.array(z.string()).min(1, { message: 'At least one district is required' }),
  description: z.string().optional(),
  permissions: z.array(z.string()).min(1, { message: 'Please select at least one permission' }),
});

export type TFormValues = z.infer<typeof manageRolesSchema>;
