import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { ResolutionEdit } from './ResolutionEdit';
import { ResolutionView } from './ResolutionView';
import { safResolutionSchema } from './safResolutionSchema';
import type * as z from 'zod';

type TResolutionAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const ResolutionArea = ({ data }: TResolutionAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { resolution } = data;

    return {
      resolution: resolution || [],
    };
  }, [data]);

  const form = useForm<z.infer<typeof safResolutionSchema>>({
    resolver: zodResolver(safResolutionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof safResolutionSchema>) => {
    const { resolution } = newFormData;

    const payload = {
      resolution,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Resolution</Text>}
      />
      {mode === 'view' ? <ResolutionView data={data} /> : <ResolutionEdit form={form} />}
    </div>
  );
};

export { ResolutionArea };
