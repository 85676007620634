import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDraftDistrictBasic } from './update-draft-disrtict-basic';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TDraftDistrictBasicInfoRequest } from './districts.types';

export const useUpdateDraftDistrictBasic = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError<{
    responsible_for_setup: string[];
    name: string[];
    subdomain: string[];
  }>, {
    districtId: number;
    parameters: TDraftDistrictBasicInfoRequest;
  }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_DRAFT_DISTRICT_BASIC],
    mutationFn: updateDraftDistrictBasic,
    onSuccess: () => {
      showSuccessToast('System message', 'Draft was updated successfully');
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        const { responsible_for_setup, name, subdomain } = error.response?.data || {
          responsible_for_setup: [],
          name: [],
          subdomain: [],
        };
        const isErrorMessageExist = Boolean(responsible_for_setup?.length) || Boolean(name?.length) || Boolean(subdomain?.length);

        if (isErrorMessageExist) {
          const nameErrorMessage = name?.length ? name.join(', ') : '';
          const responsibleForSetupErrorMessage = responsible_for_setup?.length ? responsible_for_setup.join(', ') : '';
          const subdomainErrorMessage = subdomain?.length ? subdomain.join(', ') : '';

          const aggregatedErrorMessage = [nameErrorMessage, responsibleForSetupErrorMessage, subdomainErrorMessage].filter(Boolean).map(capitalizeFirstLetter).join(' ');
          showErrorToast('System message', aggregatedErrorMessage);
        } else {
          showErrorToast('System message', 'Could not update a draft. Check the provided information and try again');
        }
      }
    },
  });
};
