import {
  Card,
  CardContent,
  CardData,
  CardDataCompareValue,
  CardDataValue,
  CardTitle,
  Heading,
} from '@purple/ui';
import type { TSchoolSafInsights } from '~/services';

type TGeneralSafDataProps = {
  insights: TSchoolSafInsights;
};

export const GeneralSafData: React.FC<TGeneralSafDataProps> = (props) => {
  const { insights } = props;

  return (
    <section className="flex w-full flex-col gap-4">
      <Heading tag="h2" type="heading-500" variant="size-18" className="text-base text-grey-title">
        General SAF Data
      </Heading>
      <ul className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Total SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData value={insights.total ?? 0}>
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Student SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData
              value={insights.student_safs ?? 0}
              compareValue={insights.total ? (insights.student_safs ?? 0) / insights.total : 0}
            >
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataCompareValue
                enableAnimation
                formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
                className="text-sm text-brand-blue-900"
              />
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Teacher/Staff SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData
              value={insights.staff_safs ?? 0}
              compareValue={insights.total ? (insights.staff_safs ?? 0) / insights.total : 0}
            >
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataCompareValue
                enableAnimation
                formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
                className="text-sm text-brand-blue-900"
              />
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Parent & Caregiver SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData
              value={insights.parent_safs ?? 0}
              compareValue={insights.total ? (insights.parent_safs ?? 0) / insights.total : 0}
            >
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataCompareValue
                enableAnimation
                formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
                className="text-sm text-brand-blue-900"
              />
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Community Member SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData
              value={insights.community_safs ?? 0}
              compareValue={insights.total ? (insights.community_safs ?? 0) / insights.total : 0}
            >
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataCompareValue
                enableAnimation
                formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
                className="text-sm text-brand-blue-900"
              />
            </CardData>
          </CardContent>
        </Card>
        <Card tag="li">
          <CardTitle className="text-sm text-grey-950">Purple User SAFs</CardTitle>
          <CardContent className="mt-auto">
            <CardData
              value={insights.purple_user_safs ?? 0}
              compareValue={insights.total ? (insights.purple_user_safs ?? 0) / insights.total : 0}
            >
              <CardDataValue
                applyTrendingColor
                enableAnimation
                formatterOptions={{ maximumFractionDigits: 0 }}
                className="text-brand-blue-950"
              />
              <CardDataCompareValue
                enableAnimation
                formatterOptions={{ style: 'percent', minimumFractionDigits: 1 }}
                className="text-sm text-brand-blue-900"
              />
            </CardData>
          </CardContent>
        </Card>
      </ul>
    </section>
  );
};
