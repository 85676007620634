import axiosInstance from '~/services/axios-config';
import { BANNERS_ENDPOINTS } from '../endpoints';
import type { TBannersListQueryParameters, TPublishedBannersResponse } from './banners.types';

export const getPublishedBanners = async (parameters?: TBannersListQueryParameters) => {
  const response = await axiosInstance.get<TPublishedBannersResponse>(BANNERS_ENDPOINTS.PUBLISHED, {
    params: parameters,
  });
  return response.data;
};
