import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafsRuleList } from './get-safs-rule-list';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafsRuleListRequestParameters, TAdminSafsRuleListResponse } from './saf.types';

export const useSafsRuleList = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminSafsRuleListRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TAdminSafsRuleListResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE, requestParameters],
    queryFn: () => getSafsRuleList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch safs routing rules list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
