import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Text } from '@purple/ui';

type TNoColorTrendsProps = {
  onSynchronizeData?: () => void;
};

export const NoColorTrends: React.FC<TNoColorTrendsProps> = (props) => {
  const { onSynchronizeData } = props;

  return (
    <div className="my-auto flex w-full flex-col items-center gap-5 p-4">
      <div className="flex size-20 shrink-0 items-center justify-center rounded-xl bg-grey-100">
        <PurpleIcon name="folder-open" className="size-14 shrink-0 text-grey-600" />
      </div>
      <div className="flex flex-col items-center gap-1">
        <Heading tag="h3" type="heading-500" variant="size-18" className="text-grey-700">
          No Data Found
        </Heading>
        <Text type="body-400" variant="size-14" className="text-grey-700">
          Please open district page to synchronize data
        </Text>
      </div>
      <Button type="button" onClick={onSynchronizeData}>
        Synchronize Data
      </Button>
    </div>
  );
};
