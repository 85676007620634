import { useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormField, FormItem, FormMessage, Input } from '@purple/ui';
import type { z } from 'zod';
import type { subpopulationFilterSchema } from './schema';

type TSubpopulationFiltersFieldItemItemProps = {
  fieldIndex: number;
};

export const SubpopulationFiltersFieldItem: React.FC<TSubpopulationFiltersFieldItemItemProps> = (props) => {
  const { fieldIndex } = props;

  const ctx = useFormContext<z.infer<typeof subpopulationFilterSchema>>();

  return (
    <div className="grid min-h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-1.5 last-of-type:border-none">
      <span className="col-span-4 px-3 text-sm font-medium text-grey-950">
        {ctx.watch(`subpopulations.${fieldIndex}.name`)}
      </span>
      <FormField
        control={ctx.control}
        name={`subpopulations.${fieldIndex}.is_displayed`}
        render={({ field }) => (
          <FormItem className="col-span-4 px-3">
            <FormControl>
              <Checkbox
                {...field}
                value={field.value.toString()}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={ctx.control}
        name={`subpopulations.${fieldIndex}.alias`}
        render={({ field, fieldState }) => (
          <FormItem className="col-span-4 px-3">
            <FormControl>
              <Input
                {...field}
                isError={!!fieldState.error}
                placeholder="Enter alias"
                className="max-h-9 w-full min-w-48 max-w-80"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
