import type { ValueOf } from '../utils';
import type { IBasePaginationRequestParameters, IPaginatedResponse, TNameableEntity } from './general';
import type { IStudentIncidentCreator } from './students';

export const SchoolDetailsView = {
  DETAILS: 'details',
  HIERARCHY: 'hierarchy',
  SAF_INSIGHTS: 'saf-insights',
  SAFS: 'safs',
  ACTIONS: 'actions',
  SERVICE_LINKS: 'service-links',
  USERS: 'users',
  CONTACTS: 'contacts',
} as const;
export type TSchoolDetailsView = ValueOf<typeof SchoolDetailsView>;

export type TSchoolListRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string;
};

export type TSchool = {
  id: string;
  name: string;
  description: string;
  tags: unknown[];
  logo: string | null;
  subtype: string;
  principal: null;
  details: {
    name: string;
    school_level: unknown[];
    grades: string[];
    subtype: string;
    subgroup: string;
    is_active: boolean;
    phone: string;
    website: string;
    school_code: string;
    super_neighborhood: string;
  };
  stats: {
    average_teacher_experience: null;
    teacher_to_student_ratio: null;
    campus_attendance_rate: null;
  };
  political_regions: {
    trustee_district_region: string;
    texas_house_district_region: null;
    state_board_of_education_district: number;
    city_council_district: string;
    texas_state_senate_district: number;
  };
  address: {
    address: null;
  };
};

export type TAllSchoolsResponse = IPaginatedResponse<TSchool[]>;

export type TSchoolItem = {
  /**
   * The ID of the school.
   */
  id: string;
  /**
   * The name of the school.
   */
  name: string;
  /**
   * The grades that the school supports.
   */
  grades: string[];
};

export type TSchoolListResponse = IPaginatedResponse<TSchoolItem[]>;

// School SAFs
export type TSchoolSafItem = {
  closed_at: string | null;
  id: string;
  saf_number: string;
  subject: string | null;
  needs: TNameableEntity[];
  date_time_opened: string;
  priority: string | null;
};

export type TSchoolSafFilters = Record<string, string[] | TNameableEntity[]>;

export const SCHOOL_SAFS_FILTERS = {
  PRIORITY: 'priority',
  DATE_TIME_OPENED: 'date_time_opened',
  NEEDS: 'needs',
} as const;

export type TSchoolSafsRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  time_period?: string | null;
  priority?: string | null;
  needs?: string | null;
};

// Schools Actions
export type TSchoolActionsRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  record_action_type?: string | null;
  document_as?: string | null;
  time_period?: string | null;
};

export type TSchoolTotalActions = {
  attempted: number;
  targeted: number;
  general: number;
};

export type TSchoolActionListItem = {
  id: string;
  title: string;
  document_as: string;
  date_and_time: string;
  created_by: IStudentIncidentCreator | null;
};

export type TSchoolActionsPaginatedResponse<T> = IPaginatedResponse<T> & {
  results: T;
  count: number;
  next: string | null;
  previous: string | null;
  totals: TSchoolTotalActions;
};

// Schools Users with Access
export type TSchoolUsersAccessRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  title?: string | null;
  is_active?: string | null;
};

export type TSchoolUserWithAccessFilterChoices = {
  title: string[];
};

export type TSchoolUserAccessListItem = {
  id: string;
  full_name: string;
  is_active: boolean;
  email: string;
  phone: string | null;
  title: string | null;
};

export const SCHOOL_USER_ACCESS_FILTERS = {
  TITLE: 'title',
  IS_ACTIVE: 'is_active',
} as const;

export type TSchoolAttendanceTrends = {
  total_students: number;
  previous_total_students: number;
  yearly_attendance_avg: number;
  previous_yearly_attendance_avg: number;
  yearly_absenteeism_avg: number;
  previous_yearly_absenteeism_avg: number;
};

export type TSchoolMonthMetrics = {
  attendance: number | null;
  absenteeism: number | null;
  at_risk_percentage: number | null;
  homeless_percentage: number | null;
  refugee_percentage: number | null;
  immigrant_percentage: number | null;
};

export type TSchoolSevereSatisfactoryAttendance = {
  extreme_chronic: number;
  severe_chronic: number;
  moderate_chronic: number;
  at_risk: number;
  satisfactory: number;
};

export type TSchoolDataStats = {
  attendance_trends: TSchoolAttendanceTrends;
  schools_metrics_by_month: Record<string, TSchoolMonthMetrics>;
  severe_satisfactory_attendance_chart: TSchoolSevereSatisfactoryAttendance;
};

export type TSchoolDataFilterParameters = {
  school?: string | null;
  grade?: string | null;
  gender?: string | null;
  current_color?: string | null;
  ethnicity?: string | null;
  student_status?: string | null;
  action?: string | null;
  subpopulation?: string | null;
};

export type TSchoolDataStatsRequestParameters = TSchoolDataFilterParameters & {
  year: string | number;
};
export type TSchoolDataStatsResponse = TSchoolDataStats;

export type TSchoolDataCalendarRequestParameters = TSchoolDataFilterParameters & {
  month: string | number;
  year: string | number;
};

export type TSchoolDataCalendar = Record<string, number>;
export type TSchoolDataCalendarResponse = TSchoolDataCalendar;

export type TSchoolFilterChoices = {
  school: Pick<TSchoolItem, 'id' | 'name'>[];
  gender: Record<string, string>;
  current_color: Record<string, string>;
  ethnicity: Record<string, string>;
  student_status: Record<string, string>;
  action: Record<string, string>;
  subpopulation: Record<string, string>;
};

export type TTSchoolFilterChoicesResponse = TSchoolFilterChoices;

// Schools Overview
export type TSchoolOverviewStatisticItem = {
  [key in string]: number;
};

export type TSchoolOverviewResponse = {
  id: string;
  chronically_absent: number;
  ada: number;
  safs: TSchoolOverviewStatisticItem[];
  actions: TSchoolOverviewStatisticItem[];
  programs: TSchoolOverviewStatisticItem[];
  color_trend: TSchoolOverviewStatisticItem[];
  subpopulations: TSchoolOverviewStatisticItem[];
};

export type TSchoolOverviewData = {
  id: string;
  chronically_absent: number;
  ada: number;
  safs: Array<{ label: string; value: number; color?: string }>;
  actions: Array<{ label: string; value: number; color?: string }>;
  programs: Array<{ label: string; value: number; color?: string }>;
  color_trend: Array<{ label: string; value: number; color?: string }>;
  subpopulations: Array<{ label: string; value: number; color?: string }>;
};

export type TSchoolOverviewLinkedStudentsRequest = {
  schoolId: string;
  chart_type: string;
};

export type TSchoolOverviewLinkedStudentsResponse = {
  id: string;
  data: TSchoolOverviewStatisticItem[];
};

export type TSchoolOverviewLinkedStudentsData = {
  linked_students: Array<{ label: string; value: number }>;
};

export type TSchoolPerson = {
  id: string;
  email: string;
  full_name: string;
  avatar: string | null;
};

export type TSchoolDetails = {
  name: string;
  school_level: string[];
  grades: string[];
  subtype: string;
  school_office?: string;
  school_division?: string;
  subgroup: string;
  is_active: boolean;
  phone: string;
  website: string;
  school_code: string;
  super_neighborhood: string;
};

export type TSchoolStats = {
  average_teacher_experience: number | null;
  teacher_to_student_ratio: number | null;
  campus_attendance_rate: number | null;
};

export type TSchoolPoliticalRegions = {
  trustee_district_region: string;
  texas_house_district_region: number | null;
  state_board_of_education_district: number | null;
  city_council_district: string | null;
  texas_state_senate_district: number | null;
};

export type TSchoolAddress = {
  id: string;
  street: string;
  city: string;
  state: string;
  postal_code: string;
  latitude: number;
  longitude: number;
};

export type TSchoolTag = {
  name: string;
  color: string;
};

export type TSchoolInfo = {
  id: string;
  name: string;
  description: string;
  tags: TSchoolTag[];
  logo: string | null;
  subtype: string;
  principal?: TSchoolPerson | null;
  wraparound_resource_manager?: TSchoolPerson | null;
  wraparound_resource_specialist_1?: TSchoolPerson | null;
  wraparound_resource_specialist_2?: TSchoolPerson | null;
  details: TSchoolDetails;
  stats: TSchoolStats;
  political_regions: TSchoolPoliticalRegions;
  address: {
    address: TSchoolAddress | null;
  };
};

export type TSchoolUpdateDetails = Partial<Omit<TSchoolDetails, 'name' | 'school_code' | 'is_active'>>;

export type TSchoolUpdateRequest = {
  details?: TSchoolUpdateDetails;
  political_regions?: Partial<TSchoolPoliticalRegions>;
  address?: {
    address: Partial<TSchoolAddress> | null;
  };
};
