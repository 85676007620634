import axiosInstance from '../axios-config';
import { NOTES_ENDPOINTS } from '../endpoints';
import type { TNoteListResponse, TNoteQueryParameters } from '@purple/shared-types';

export const getNotesList = async (parameters: TNoteQueryParameters) => {
  const response = await axiosInstance.get<TNoteListResponse>(NOTES_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
