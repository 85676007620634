import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { BANNERS_QUERY_KEYS } from './banners.const';
import { updateBanner } from './update-banner';
import type { AxiosError } from 'axios';
import type { TBannerUpdatePayload } from './banners.types';

export const useUpdateBanner = () => {
  const queryClient = useQueryClient();

  return useMutation<TBannerUpdatePayload, AxiosError, TBannerUpdatePayload>({
    mutationKey: [BANNERS_QUERY_KEYS.UPDATE_BANNER],
    mutationFn: updateBanner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_PUBLISHED_BANNERS] });
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_DRAFTED_BANNERS] });
      showSuccessToast('System message', 'Banner has been updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to update the banner. Check provided information and try again');
      }
    },
  });
};
