import { DISTRICT_SETUP_STEP } from '@purple/shared-types';
import type { TStepItem } from '@purple/ui';

export const SET_UP_STEPS = [
  { id: DISTRICT_SETUP_STEP.BASIC },
  { id: DISTRICT_SETUP_STEP.BRANDING },
  { id: DISTRICT_SETUP_STEP.SAF_PROCESS },
  { id: DISTRICT_SETUP_STEP.USERS_AND_PERMISSIONS },
  { id: DISTRICT_SETUP_STEP.DATA_UPLOADS },
] satisfies TStepItem[];

export const SET_UP_STEP_LABELS = {
  [DISTRICT_SETUP_STEP.BASIC]: 'Basic Details',
  [DISTRICT_SETUP_STEP.BRANDING]: 'Branding',
  [DISTRICT_SETUP_STEP.SAF_PROCESS]: 'SAF Process',
  [DISTRICT_SETUP_STEP.USERS_AND_PERMISSIONS]: 'District Users',
  [DISTRICT_SETUP_STEP.DATA_UPLOADS]: 'SIS Data Mapping',
} as const;

export const SET_UP_STEP_TITLE = {
  [DISTRICT_SETUP_STEP.BASIC]: 'Set Up District Profile Basic Information',
  [DISTRICT_SETUP_STEP.BRANDING]: 'Set Up District Visual Identity',
  [DISTRICT_SETUP_STEP.SAF_PROCESS]: 'Set Up SAF Process',
  [DISTRICT_SETUP_STEP.USERS_AND_PERMISSIONS]: 'Add District Users',
  [DISTRICT_SETUP_STEP.DATA_UPLOADS]: 'SIS Data Mapping',
} as const;

export const DISTRICT_ID_QUERY_NAME = 'districtId';

export const HEADER_PORTAL_ID = 'set-up-district-header';
