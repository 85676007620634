import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolFilterChoices } from './get-school-filter-choices';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolFilterChoices = () => {
  const { isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_FILTER_CHOICES],
    queryFn: getSchoolFilterChoices,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch school filter choices');
    }
  }, [isError]);

  return { isError, ...rest };
};
