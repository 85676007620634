import type { PurpleIconType } from '@purple/icons';
import type { ValueOf } from '@purple/shared-types';

export const HierarchyNodeType = {
  STATE: 'state',
  REGION: 'region',
  FEEDER_PATTERN: 'feeder_pattern',
  CAMPUS: 'campus',
  CITY: 'city',
  DIVISION: 'division',
  SYSTEM: 'system',
  SCHOOL: 'school',
  DISTRICT: 'district',
} as const;
export type THierarchyNodeType = ValueOf<typeof HierarchyNodeType>;

export type THierarchyNodeId = number | string;

export type THierarchyNode = {
  id: THierarchyNodeId;
  name: string;
  type: THierarchyNodeType;
  icon?: PurpleIconType;
  selectedIcon?: PurpleIconType;
  openIcon?: PurpleIconType;
  children?: THierarchyNode[];
  disableActions?: boolean;
  onClick?: () => void;
};

export type THierarchyUpdateNode = Pick<THierarchyNode, 'id' | 'name' | 'type'>;

export type THierarchyBranch = {
  id?: THierarchyNodeId;
  name: string;
  type: THierarchyNodeType;
};

export type THierarchyBranchOption = THierarchyBranch & {
  id: THierarchyNodeId;
};
