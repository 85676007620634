import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictBasicDetails } from './update-district-basic-details';
import type { AxiosError } from '@purple/shared-services';
import type { TUpdateDistrictDetailsPayload, TUpdateDistrictDetailsResponse } from './districts.types';

export const useUpdateDistrictBasicDetails = () => {
  const queryClient = useQueryClient();

  return useMutation<TUpdateDistrictDetailsResponse, AxiosError, TUpdateDistrictDetailsPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_BASIC_DETAILS],
    mutationFn: updateDistrictBasicDetails,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DETAILS, data.id.toString()] });
      showSuccessToast('System message', 'District basic details were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update district basic details. Please try again later');
    },
  });
};
