import axiosInstance from '~/services/axios-config';
import { BANNERS_ENDPOINTS } from '../endpoints';
import type { TBannersBulkDeletePayload, TBannersBulkDeleteResponse } from './banners.types';

export const bulkBannersDelete = async (payload: TBannersBulkDeletePayload) => {
  const response = await axiosInstance.post<TBannersBulkDeleteResponse>(
    BANNERS_ENDPOINTS.BULK_DELETE,
    payload,
  );
  return response.data;
};
