import { type FC, memo, useMemo } from 'react';
import { convertBooleanToText, isFieldExist, snakeCaseToCapitalized, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { ActionSectionHeader } from '../ActionSectionHeader';
import type { TActionDetails } from '@purple/shared-types';

type TCrisisDetailsSectionProperties = {
  action: TActionDetails;
};

export const CrisisDetailsSection: FC<TCrisisDetailsSectionProperties> = memo(({ action }) => {
  const participants = useMemo(
    () =>
      Array.isArray(action.crisis_details?.your_consultants) && action.crisis_details.your_consultants.length > 0
        ? action.crisis_details.your_consultants.map((consultant) => consultant.full_name).join(', ')
        : null,
    [action.crisis_details?.your_consultants],
  );
  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader title="Crisis Details" disableEdit />
      <DescriptionList>
        {isFieldExist(action.crisis_details?.crisis_types) && (
          <DescriptionItem>
            <DescriptionTerm>Type</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{action.crisis_details.crisis_types.length > 0 ? action.crisis_details.crisis_types.map((type) => snakeCaseToCapitalized(type)) : '—'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_law_enforcement_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Law Enforcement Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_law_enforcement_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_CPS_notified) && (
          <DescriptionItem>
            <DescriptionTerm>CPS Notified</DescriptionTerm>
            <DescriptionDetails>{convertBooleanToText(action.crisis_details?.is_CPS_notified)}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_parent_caregiver_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Parents/Caregiver Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_parent_caregiver_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_administration_notified) && (
          <DescriptionItem>
            <DescriptionTerm>Administration Notified</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_administration_notified)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.your_consultants) && (
          <DescriptionItem>
            <DescriptionTerm>Consulted With</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{participants ?? '—'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_threat_assessment_administered) && (
          <DescriptionItem>
            <DescriptionTerm>Threat Assessment Administered</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_threat_assessment_administered)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.threat_assessment_result) && (
          <DescriptionItem>
            <DescriptionTerm>Threat Assessment Result</DescriptionTerm>
            <DescriptionDetails className="max-w-[300px] text-right">{action.crisis_details.threat_assessment_result && action.crisis_details.threat_assessment_result.length > 0 ? snakeToCamelWithSpaces(action.crisis_details.threat_assessment_result) : '—' }</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_parent_caregiver_notified_of_assessment) && (
          <DescriptionItem>
            <DescriptionTerm>Parent/Caregiver Notified of Assessment</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_parent_caregiver_notified_of_assessment)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(action.crisis_details?.is_weapons_accessible_to_the_individual) && (
          <DescriptionItem>
            <DescriptionTerm>Weapons Accessible to this Individual</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(action.crisis_details?.is_weapons_accessible_to_the_individual)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
      </DescriptionList>
    </div>
  );
});
