import { Separator, Skeleton, Text } from '@purple/ui';

export const ManageRolesFormSideBarSkeleton = () => {
  return (
    <div className="flex h-fit w-full max-w-[320px] flex-col gap-4 rounded-lg border border-grey-200 bg-white px-4 py-6">
      <Skeleton className="h-10 w-full" />
      <div className="flex flex-col gap-2">
        <Text variant="size-12" type="body-400" className="uppercase text-grey-400">Section Permission</Text>
        <div className="flex flex-col items-start gap-2">
          {Array.from({ length: 8 }).map(() => (
            <Skeleton key={Math.random().toString()} className="h-6 w-full" />
          ))}
        </div>
      </div>
      <Separator />
      <div className="flex flex-col gap-2">
        <Text variant="size-12" type="body-400" className="uppercase text-grey-400">Objects Permission</Text>
        <div className="flex flex-col items-start gap-2">
          {Array.from({ length: 8 }).map(() => (
            <Skeleton key={Math.random().toString()} className="h-6 w-full" />
          ))}
        </div>
      </div>
    </div>
  );
};
