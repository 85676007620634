import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUsersListManagement } from './get-users-management';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TAdminUsersManagementRequestParameters, TUsersManagementResponse } from './user.types';

export const useUsersListManagement = ({
  enabled = true,
  ...parameters
}:
TAdminUsersManagementRequestParameters & { enabled?: boolean }) => {
  const { isError, error, ...rest } = useQuery<TUsersManagementResponse, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT, parameters],
    queryFn: () => getUsersListManagement(parameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch list of users');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
