import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafsRuleList } from './get-safs-rule-list';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';

export const useSafRuleListMutation = () => {
  // NOTE: we use useMutation hook to get all safs by clicking on the button (or another trigger)
  return useMutation({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE_MUTATION],
    mutationFn: getSafsRuleList,

    onError: () => {
      showErrorToast('System message', 'Unable to fetch all safs list');
    },
  });
};
