import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { TABLE_DEFAULT_PAGE_OFFSET, TABLE_DEFAULT_PAGE_SIZE } from '~/components/DataTable';
import type {
  OnChangeFn,
  PaginationState,
  RowSelectionState,
  SortingState,
  TableOptions,
  TableState,
  VisibilityState,
} from '@tanstack/react-table';

export type TTableOption = {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
  withCount?: boolean;
};

export type TDataTableFilterField<TData> = {
  label: string;
  value: keyof TData;
  placeholder?: string;
  options?: TTableOption[];
};

export type TUseDataTableProperties<TData> = Omit<
  TableOptions<TData>,
  'getCoreRowModel' | 'manualFiltering' | 'manualPagination' | 'manualSorting'
> & {
  /**
   * Defines filter fields for the table. Supports both dynamic faceted filters and search filters.
   * - Faceted filters are rendered when `options` are provided for a filter field.
   * - Otherwise, search filters are rendered.
   *
   * The indie filter field `value` represents the corresponding column name in the database table.
   * @default []
   * @type { label: string, value: keyof TData, placeholder?: string, options?: { label: string, value: string, icon?: React.ComponentType<{ className?: string }> }[] }[]
   * @example
   * ```ts
   * // Render a search filter
   * const filterFields = [
   *   { label: "Title", value: "title", placeholder: "Search titles" }
   * ];
   * // Render a faceted filter
   * const filterFields = [
   *   {
   *     label: "Status",
   *     value: "status",
   *     options: [
   *       { label: "Todo", value: "todo" },
   *       { label: "In Progress", value: "in-progress" },
   *     ]
   *   }
   * ];
   * ```
   */
  filterFields?: TDataTableFilterField<TData>[];
  /**
   * The method to use when updating the URL.
   * - "push" - Pushes a new entry onto the history stack.
   * - "replace" - Replaces the current entry on the history stack.
   * @default "replace"
   */
  method?: 'push' | 'replace';
  /**
   * Indicates whether the page should scroll to the top when the URL changes.
   * @default false
   */
  scroll?: boolean;
  /**
   * Delimiter used to separate the sorting column and order in the URL.
   * @default "-"
   */
  sortingDelimiter?: string;
  /**
   * Table initial state, but extended to make sure that the sorting `id` is type safe.
   */
  initialState?: Omit<Partial<TableState>, 'sorting'> & {
    sorting?: {
      id: Extract<keyof TData, string>;
      desc: boolean;
    }[];
  };
  onSelectionChange?: (selectedRows: RowSelectionState) => void;
};

export const useDataTable = <TData>({
  pageCount,
  rowCount,
  method = 'push',
  sortingDelimiter = '-',
  scroll = false,
  onSelectionChange,
  ...props
}: TUseDataTableProperties<TData>) => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const limit = Number(searchParameters.get(LIMIT_QUERY_NAME)) || TABLE_DEFAULT_PAGE_SIZE;
  const offset = Number(searchParameters.get(OFFSET_QUERY_NAME)) || TABLE_DEFAULT_PAGE_OFFSET;
  const [initialSorting] = props.initialState?.sorting ?? [];
  const urlSort = searchParameters.get(SORT_QUERY_NAME);
  const sort
    = urlSort ?? (initialSorting ? `${initialSorting.desc ? sortingDelimiter : ''}${initialSorting.id}` : null);
  const sortingDesc = sort?.startsWith(sortingDelimiter) ?? false;
  const sortingColumn = sort?.replace(sortingDelimiter, '') ?? null;
  const initialVisibleColumns = props.initialState?.columnVisibility ?? {};
  const initialSelection = props.initialState?.rowSelection ?? {};

  // Table states
  const refinedPageCount = React.useMemo(
    () => pageCount ?? (typeof rowCount === 'number' ? Math.ceil(rowCount / limit) : 1),
    [pageCount, rowCount, limit],
  );
  const [rowSelection, setRowSelection] = React.useState(initialSelection);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>(initialVisibleColumns);
  const [sorting, setSorting] = React.useState<SortingState>([
    ...(sort === null
      ? []
      : [
          {
            id: sortingColumn ?? '',
            desc: sortingDesc,
          },
        ]),
  ]);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: offset / limit,
    pageSize: limit,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const onSortingChange: OnChangeFn<SortingState> = React.useCallback(
    (updaterOrValue) => {
      let updatedSorting: SortingState = sorting;
      updatedSorting = typeof updaterOrValue === 'function' ? updaterOrValue(sorting) : updaterOrValue;
      setSorting(updatedSorting);
      setSearchParameters(
        (previousParameters) => {
          const updatedSearchParameters = new URLSearchParams(previousParameters);

          const [firstSorting] = updatedSorting;
          if (firstSorting) {
            updatedSearchParameters.set(
              SORT_QUERY_NAME,
              `${firstSorting.desc ? sortingDelimiter : ''}${firstSorting.id}`,
            );
            updatedSearchParameters.set(OFFSET_QUERY_NAME, '0');
          }

          return updatedSearchParameters;
        },
        { replace: method === 'replace', preventScrollReset: !scroll },
      );
    },
    [sorting, setSearchParameters, method, scroll, sortingDelimiter],
  );

  const onPaginationChange: OnChangeFn<PaginationState> = React.useCallback(
    (updaterOrValue) => {
      let updatedPagination: PaginationState = pagination;
      updatedPagination = typeof updaterOrValue === 'function' ? updaterOrValue(pagination) : updaterOrValue;
      setPagination(updatedPagination);
      const { pageIndex: newPageIndex, pageSize: newPageSize } = updatedPagination;
      setSearchParameters(
        (previousParameters) => {
          const updatedSearchParameters = new URLSearchParams(previousParameters);
          updatedSearchParameters.set(LIMIT_QUERY_NAME, String(newPageSize));
          updatedSearchParameters.set(OFFSET_QUERY_NAME, String(newPageIndex * newPageSize));
          return updatedSearchParameters;
        },
        { replace: method === 'replace', preventScrollReset: !scroll },
      );
    },
    [pagination, setSearchParameters, method, scroll],
  );

  React.useEffect(() => {
    // Update pagination state when the URL changes
    if (limit !== pageSize || offset !== pageIndex * pageSize) {
      setPagination({
        pageIndex: offset / limit,
        pageSize: limit,
      });
    }
  }, [limit, offset, pageIndex, pageSize]);

  React.useEffect(() => {
    // Set initial sorting state in the URL
    if (initialSorting && searchParameters.get(SORT_QUERY_NAME) === null) {
      onSortingChange([
        {
          id: initialSorting.id,
          desc: initialSorting.desc,
        },
      ]);
    }
  }, [initialSorting]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // Update sorting state when the URL changes
    if (sort !== null) {
      setSorting([
        {
          id: sortingColumn ?? '',
          desc: sortingDesc,
        },
      ]);
    }
  }, [sort]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectionChangeHandler: OnChangeFn<RowSelectionState> = (newValue) => {
    setRowSelection(newValue);

    let updatedValueSelection: RowSelectionState = rowSelection;
    updatedValueSelection = typeof newValue === 'function' ? newValue(rowSelection) : newValue;
    onSelectionChange?.(updatedValueSelection);
  };

  const table = useReactTable({
    ...props,
    pageCount: refinedPageCount,
    rowCount,
    state: {
      pagination,
      sorting,
      columnVisibility,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: selectionChangeHandler,
    onPaginationChange,
    onSortingChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
  });

  return { table };
};
