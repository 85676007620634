import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { uploadMappingFile } from './patch-mapping-file';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TUploadMappingFilePayload } from './districts.types';

export const useUploadMappingFile = () => {
  return useMutation<AxiosResponse, AxiosError, TUploadMappingFilePayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPLOAD_MAPPING_FILE],
    mutationFn: uploadMappingFile,
    onError: (error) => {
      if (error.response && error.response?.status < 500) {
        showErrorToast('System message', 'Failed to upload mapping file');
      }
    },
  });
};
