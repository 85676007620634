import { createSlice } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { PayloadAction } from '@reduxjs/toolkit';

export type TSidebarState = {
  /**
   * Whether the sidebar is open or not.
   */
  isOpen: boolean;
};

const initialState: TSidebarState = {
  isOpen: true,
};

export const sidebarSlice = createSlice({
  name: AppReduxReducer.SIDEBAR,
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    closeSidebar: (state) => {
      state.isOpen = false;
    },
    openSidebar: (state) => {
      state.isOpen = true;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
