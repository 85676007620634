import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { SCHOOLS_QUERY_KEYS } from './schools.const';
import { updateSchoolHierarchy } from './update-school-hierarchy';

export const useUpdateSchoolHierarchy = (options?: { shouldRevalidate?: boolean }) => {
  const { shouldRevalidate = true } = options || {};

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.UPDATE_ADMIN_SCHOOL_HIERARCHY],
    mutationFn: updateSchoolHierarchy,
    onSuccess: () => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({
          queryKey: [SCHOOLS_QUERY_KEYS.GET_ADMIN_SCHOOL_HIERARCHY],
        });
      }
      showSuccessToast('System message', 'School hierarchy was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update school hierarchy. Please try again later');
    },
  });
};
