import { useFormContext } from 'react-hook-form';
import { Heading, Message, Text, TooltipProvider } from '@purple/ui';
import { TableHeader } from './TableHeader';
import { TableItem } from './TableItem';
import type { FC } from 'react';
import type { TAppPermissions } from '@purple/permissions';
import type { TPermissionStructureItem } from '~/services';
import type { TFormValues } from '../../manageRolesSchema';

type TRoleFormTabItemProperties = {
  category: string;
  permissionList: TPermissionStructureItem[];
};

export const RoleFormTable: FC<TRoleFormTabItemProperties> = ({ category, permissionList }) => {
  const { watch, setValue, formState } = useFormContext<TFormValues>();
  const allPermissions = watch('permissions');

  const isAllSelected = permissionList.every((permission) => allPermissions.includes(permission.codename));

  const selectAllPermissionFromCategory = () => {
    const permissions = permissionList.map((permission) => permission.codename);
    if (isAllSelected) {
    // Deselect all permissions in the category
      setValue('permissions', allPermissions.filter((permission) => !permissions.includes(permission as TAppPermissions)));
    } else {
    // Select all permissions in the category, avoiding duplicates
      setValue('permissions', Array.from(new Set([...allPermissions, ...permissions])));
    }
  };

  return (
    <TooltipProvider>
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500">Section Permissions</Heading>
            <Text variant="size-14" type="body-400" className="text-grey-700">Select permission below for the role</Text>
          </div>
          {formState.errors.permissions && (
            <Message variant="error" className="max-w-[400px]">
              <Text variant="size-14">{formState.errors.permissions?.message || 'Please select at least one permission'}</Text>
            </Message>
          )}

        </div>
        <div className="flex flex-col gap-2">
          <Text variant="size-16" type="body-500" className="text-grey-title">
            {category}
            {' '}
            access
          </Text>
        </div>
        <div className="flex flex-col">
          <TableHeader isAllSelected={isAllSelected} onSelectAll={selectAllPermissionFromCategory} />
          {permissionList.map((permission) => (
            <TableItem key={permission.id} permission={permission} />
          ))}
        </div>
      </div>
    </TooltipProvider>
  );
};
