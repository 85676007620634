import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getNeedsList } from './get-needs-list';
import { NEEDS_QUERY_KEYS } from './needs.const';
import type { AxiosError } from 'axios';
import type { IPaginatedResponse, TSafNeedWithTranslation } from '@purple/shared-types';
import type { TAdminNeedsRequestParameters } from './needs.types';

export const useNeeds = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminNeedsRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<IPaginatedResponse<TSafNeedWithTranslation[]>, AxiosError>({
    queryKey: [NEEDS_QUERY_KEYS.GET_ADMIN_NEEDS_LIST, requestParameters],
    queryFn: () => getNeedsList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch needs list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
