import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getTagsList } from './get-tags-list';
import { TAGS_QUERY_KEY } from './tags.const';
import type { AxiosError } from 'axios';
import type { TCustomTagListResponse, TCustomTagsListRequestParameters } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

export const useCustomTags = (
  parameters: TCustomTagsListRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TCustomTagListResponse, AxiosError>,
) => {
  const { isError, ...rest } = useQuery<TCustomTagListResponse, AxiosError>({
    queryKey: [TAGS_QUERY_KEY.GET_CUSTOM_TAGS, parameters],
    queryFn: () => getTagsList(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch list of custom tags');
    }
  }, [isError]);

  return { isError, ...rest };
};
