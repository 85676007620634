import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TSafCorrespondenceListResponse, TSafEmailListRequestParameters } from '@purple/shared-types';

export const getSafEmailsList = async ({ safId, ...params }: TSafEmailListRequestParameters & { safId: string }) => {
  const response = await axiosInstance.get<TSafCorrespondenceListResponse>(SAF_ENDPOINTS.SAF_EMAILS(safId), {
    params,
  });
  return response.data;
};
