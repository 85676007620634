import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, NumberBadge, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useIndividualActionDetails } from '~/services';
import { ActionDetailsHeaderSkeleton } from './ActionDetailsHeaderSkeleton';

export const ActionDetailsHeader: FC = () => {
  const { actionId } = useParams();

  const { openModal } = useModal(ModalType.DELETE_ACTION);

  const { isLoading, data } = useIndividualActionDetails({ actionId: actionId! });

  const deleteIndividualActionHandler = useCallback(() => {
    openModal();
  }, [openModal]);

  if (isLoading && !data) {
    return <ActionDetailsHeaderSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-col gap-2">
        <Text variant="size-16" type="body-600">{data?.title ?? 'Untitled Action'}</Text>
        <DescriptionList className="flex-col items-start gap-6 md:flex-row">
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Status</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant="info" className="rounded-full text-sm font-medium capitalize">
                {data?.document_as}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
        </DescriptionList>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            variant="secondary"
            size="icon_40"
            className="ml-auto size-10"
            iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
          />
        </DropdownTrigger>
        <DropdownContent align="end">
          <DropdownItem iconName="trash" className="text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main" onClick={deleteIndividualActionHandler}>Delete Action</DropdownItem>
        </DropdownContent>
      </DropdownRoot>
    </section>
  );
};
