import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDraftedDistricts } from './get-drafted-districts';
import type { TDistrictsListQueryParameters } from './districts.types';

export const useDraftedDistricts = (parameters?: TDistrictsListQueryParameters) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICTS, parameters],
    queryFn: () => getDraftedDistricts(parameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch draft districts data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
