import type { TAdminSafBasicDetailsResponse, TAdminSafBasicDetailsView, TAdminSafRuleConvertedFilters, TAdminSafRuleFiltersResponse, TAdminSafSubCategoryDto, TAdminSafSubCategoryView } from './saf.types';

export const convertSafRuleFilters = (data: TAdminSafRuleFiltersResponse): TAdminSafRuleConvertedFilters => {
  return {
    ...data,
    assigned_users: data.assigned_users.map((user) => ({
      id: user.id,
      name: user.full_name,
    })),
  };
};

export const convertSafDetailSubcategoryDtoToView = (data: TAdminSafSubCategoryDto[]): TAdminSafSubCategoryView[] => {
  return Object.values(
    data.reduce((acc: { [key: string]: TAdminSafSubCategoryView }, item) => {
      const { id, name } = item.category;

      if (!acc[id]) {
        acc[id] = {
          id,
          name,
          sub_categories: [],
        };
      }

      acc[id].sub_categories.push({
        id: item.id,
        name: item.name,
      });

      return acc;
    }, {}),
  );
};

export const convertSafDetailDtoToView = (data: TAdminSafBasicDetailsResponse): TAdminSafBasicDetailsView => {
  return {
    ...data,
    service_area_subcategories: convertSafDetailSubcategoryDtoToView(data.service_area_subcategories),
  };
};
