import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TBehaviorTextSchema } from './behaviorTextSchema';

export const BehaviorTextForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Behavior Metrics
      </Heading>
      <FloatNumberField<TBehaviorTextSchema> name="contacted_parent" label="Contacted Parent" />
      <FloatNumberField<TBehaviorTextSchema> name="parent_or_guardian_conference" label="Parent/Guardian Conference" />
      <FloatNumberField<TBehaviorTextSchema> name="behavior_contact" label="Behavior Contract" />
      <FloatNumberField<TBehaviorTextSchema> name="oss" label="OSS" />
      <FloatNumberField<TBehaviorTextSchema> name="iss" label="ISS" />
      <FloatNumberField<TBehaviorTextSchema> name="partial_day_oss" label="Partial Day OSS" />
      <FloatNumberField<TBehaviorTextSchema> name="partial_day_iss" label="Partial Day ISS" />
      <FloatNumberField<TBehaviorTextSchema> name="after_school_detention" label="After School Detention" />
      <FloatNumberField<TBehaviorTextSchema> name="reverse_suspension" label="Reverse Suspension" />
      <FloatNumberField<TBehaviorTextSchema> name="teacher_detention_hall" label="Teacher Detention Hall" />
      <FloatNumberField<TBehaviorTextSchema> name="peer_mediation" label="Peer Mediation" />
      <FloatNumberField<TBehaviorTextSchema> name="saturday_detention" label="Saturday Detention" />
      <NumberOfMaxFlagsField />
      <BaselineField />
    </div>
  );
};
