import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateUserBasicDetails } from './update-user-basic-details';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';

export const useUpdateUserBasicDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_BASIC_DETAILS_UPDATE],
    mutationFn: updateUserBasicDetails,
    onSuccess: () => {
      showSuccessToast('System message', 'User was updated successfully');
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_BASIC_DETAILS] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_DETAILS] });
    },
    onError: () => {
      showErrorToast('System message', 'Could not update a user. Check the provided information and try again');
    },
  });
};
