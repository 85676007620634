/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const ColorTrendsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full items-center gap-4">
        <Skeleton className="h-10 w-full max-w-[110px]" />
        <Skeleton className="h-10 w-full max-w-[300px]" />
      </div>
      <div className="flex w-full flex-col">
        <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
          <Skeleton className="col-span-3 h-5 w-full max-w-[160px]" />
          <Skeleton className="col-span-2 h-5 w-full max-w-[32px]" />
          <Skeleton className="col-span-1 h-5 w-full max-w-[50px] place-self-center" />
          <Skeleton className="col-span-2 h-5 w-full max-w-[105px] place-self-center" />
          <div className="col-span-3 flex items-center justify-between gap-2 px-3">
            <div className="flex w-full items-center gap-2">
              <Skeleton className="size-5 shrink-0" />
              <Skeleton className="h-5 w-full max-w-[50px]" />
            </div>
            <div className="flex w-full items-center gap-2">
              <Skeleton className="size-5 shrink-0" />
              <Skeleton className="h-5 w-full max-w-[50px]" />
            </div>
          </div>
          <Skeleton className="col-span-1 h-0 w-full max-w-[60px]" />
        </div>
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="grid h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-3 last-of-type:border-none" key={index}>
            <Skeleton className="col-span-3 h-5 w-full max-w-[100px]" style={{ maxWidth: `${index % 2 === 0 ? '100px' : '145px'}` }} />
            <Skeleton className="col-span-2 h-5 w-full max-w-[32px]" style={{ maxWidth: `${index % 2 === 0 ? '50px' : '75px'}` }} />
            <Skeleton className="col-span-1 h-7 w-full max-w-[63px] place-self-center rounded-full" />
            <Skeleton className="col-span-2 h-5 w-full max-w-[28px] place-self-center" />
            <div className="col-span-3 flex items-center justify-between gap-2 px-3">
              <div className="flex w-full items-center">
                <Skeleton className="size-5 shrink-0" />
              </div>
              <div className="flex w-full items-center">
                <Skeleton className="size-5 shrink-0" />
              </div>
            </div>
            <div className="col-span-1 flex items-center gap-1 place-self-end px-3">
              <Skeleton className="size-8 shrink-0" />
              <Skeleton className="size-8 shrink-0" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
