import axiosInstance from '../axios-config';
import { STUDENTS_ENDPOINTS } from '../endpoints';
import type { IBasePaginationRequestParameters } from '@purple/shared-types';
import type { TStudentsListResponse } from './students.types';

export const getStudentsList = async (params: IBasePaginationRequestParameters) => {
  const response = await axiosInstance.get<TStudentsListResponse>(STUDENTS_ENDPOINTS.ROOT, {
    params,
  });
  return response.data;
};
