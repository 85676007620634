import { Skeleton } from '@purple/ui';

export const DistrictUsersSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[210px]" />
      </div>
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full items-center justify-between gap-4 px-6 py-4">
          <div className="flex items-center gap-4">
            <Skeleton className="h-10 w-[90px] shrink" />
            <Skeleton className="h-10 w-[240px] shrink" />
          </div>
          <Skeleton className="size-10 shrink" />
        </div>
        <div className="flex w-full flex-col">
          <div className="flex h-12 w-full items-center justify-evenly gap-8 border-b border-b-grey-300 px-5 py-4">
            <Skeleton className="h-5 w-full max-w-[20px]" />
            <Skeleton className="h-5 w-full max-w-[180px]" />
            <Skeleton className="h-5 w-full max-w-[100px]" />
            <Skeleton className="h-5 w-full max-w-[180px]" />
            <Skeleton className="h-5 w-full max-w-[180px]" />
            <Skeleton className="h-5 w-full max-w-[120px]" />
            <Skeleton className="h-5 w-full max-w-[120px]" />
          </div>

          {Array.from({ length: 10 }).map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="flex h-14 w-full items-center justify-evenly gap-8 border-b border-b-grey-200 px-5 py-4"
            >
              <Skeleton className="h-5 w-full max-w-[20px]" />
              <Skeleton className="h-5 w-full max-w-[180px]" />
              <Skeleton className="h-5 w-full max-w-[100px]" />
              <Skeleton className="h-5 w-full max-w-[180px]" />
              <Skeleton className="h-5 w-full max-w-[180px]" />
              <Skeleton className="h-5 w-full max-w-[120px]" />
              <Skeleton className="h-5 w-full max-w-[120px]" />
            </div>
          ))}

          <div className="flex w-full flex-col flex-wrap items-center justify-center gap-4 overflow-auto p-4 sm:flex-row sm:justify-between">
            <div className="flex flex-row items-center gap-2">
              <Skeleton className="h-5 w-[100px]" />
              <Skeleton className="h-8 w-[60px]" />
              <Skeleton className="h-5 w-[80px]" />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Skeleton className="size-8" />

              <div className="flex items-center gap-2">
                <Skeleton className="size-8" />
                <Skeleton className="size-8" />
                <Skeleton className="size-8" />
              </div>

              <Skeleton className="size-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
