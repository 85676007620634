import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ADMIN_AUTH_QUERY_KEYS } from './auth.const';
import { adminResetPasswordConfirm } from './password';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useNewPassword = (queryOptions?: TCustomUseMutationOptions<AxiosResponse, AxiosError<{ token: string[] }>>) => {
  const navigate = useNavigate();
  return useMutation({
    ...queryOptions,
    mutationKey: [ADMIN_AUTH_QUERY_KEYS.RESET_PASSWORD_CONFIRM],
    mutationFn: adminResetPasswordConfirm,
    onSuccess: () => {
      showSuccessToast(
        'System message',
        'You have successfully reset your password. Please log in with your new credentials',
      );
      navigate(AdminRoutes.Auth.Login.Root.path, { replace: true });
    },
    onError: (error) => {
      if (error.response && error.response.data.token.length > 0) {
        showErrorToast('System message', 'The password has already been reset via this link, or the link has expired.');
        return;
      }
      showErrorToast('System message', 'Could not reset password. Check the provided information and try again');
    },
  });
};
