import { PurpleIcon } from '@purple/icons';
import { AccessDenied, AppTabs, Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useGoBack } from '~/hooks';
import { ActionsTabsValues } from '../Root/constants';
import { ActionTypeDetailsHeader } from './components';
import { useActionTypesTabs } from './Tabs/useActionTypesTab';

export const ActionTypesDetailsPage = () => {
  const { onGoBack } = useGoBack({ fallbackPath: AdminRoutes.App.Actions.Student.Root.makePath({ queries: { tab: ActionsTabsValues.actionTypes } }) });

  const { actionsTypesTabs, activeTab, tabChangeHandler } = useActionTypesTabs();

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
        className="h-auto p-1 font-semibold"
        onClick={onGoBack}
      >
        Back to Action List
      </Button>
      <ActionTypeDetailsHeader />
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={actionsTypesTabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} />
          )}
    </div>
  );
};
