import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TGradeTextSchema } from './gradeTextSchema';

export const GradeTextForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Non-Numerical (Text) Grades Metrics
      </Heading>
      <FloatNumberField<TGradeTextSchema> name="unsatisfactory" label="Unsatisfactory" />
      <FloatNumberField<TGradeTextSchema> name="needs_improvement" label="Needs Improvement" />
      <NumberOfMaxFlagsField />
      <BaselineField />
    </div>
  );
};
