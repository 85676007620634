import { AccessDenied, AppTabs, Heading, Text } from '@purple/ui';
import { useActionsTabs } from './Tabs/useActionsTabs';
import type { FC } from 'react';

export const ActionsPage: FC = () => {
  const { actionsTabs, activeTab, tabChangeHandler } = useActionsTabs();

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Heading tag="h1" variant="size-22" type="heading-600" className="text-grey-title">
          Student Centered Actions
        </Heading>
        <Text variant="size-14" type="body-400" className="text-grey-950">
          Manage existing student action types
        </Text>
      </div>
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={actionsTabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} />
          )}
    </div>
  );
};
