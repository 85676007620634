import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictSafProcessOwner } from './get-district-saf-process-owner';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TDistrictSafProcessOwnerResponse } from './districts.types';

type TUseDistrictSafProcessOwnerProperties = {
  districtId: string;
  queryOptions?: TCustomUseQueryOptions<TDistrictSafProcessOwnerResponse, AxiosError>;
};

export const useDistrictSafProcessOwner = ({ districtId, queryOptions }: TUseDistrictSafProcessOwnerProperties) => {
  const { isError, error, ...queryData } = useQuery<TDistrictSafProcessOwnerResponse, AxiosError> ({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_SAF_PROCESS_OWNER, districtId],
    queryFn: () => getDistrictSafProcessOwner({ districtId }),
    enabled: queryOptions?.enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('Failed to fetch district saf process owner', 'Please try again later');
    }
  }, [error, isError]);

  return { ...queryData, isError };
};
