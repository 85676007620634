import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, Input, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, Separator, Textarea } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, SSO_PROVIDERS, SSO_PROVIDERS_SELECT_OPTIONS, useCreateSsoSettings, useUpdateSsoSettings } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { addSsoConnectionSchema } from './schema';
import type { FC } from 'react';
import type * as z from 'zod';
import type { TDistrictSsoSettingItem } from '~/services';

type TFormValues = z.infer<typeof addSsoConnectionSchema>;

export const AddSsoConnectionModal: FC = () => {
  const formId = useId();
  const { id: districtId } = useParams();
  const queryClient = useQueryClient();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.ADD_SSO_CONNECTION);

  const districtSsoSettingsData = queryClient.getQueryData<TDistrictSsoSettingItem>([DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId!]);

  const modalTitle = useMemo(() => districtSsoSettingsData ? 'Edit Connection' : 'Add Connection', [districtSsoSettingsData]);
  const defaultFormValues = useMemo(() => ({
    provider_type: districtSsoSettingsData?.provider_type ?? SSO_PROVIDERS.GOOGLE,
    idp_sso_url: districtSsoSettingsData?.idp_sso_url ?? '',
    idp_entity_id: districtSsoSettingsData?.idp_entity_id ?? '',
    idp_certificate: districtSsoSettingsData?.idp_certificate ?? '',
    idp_sha_fingerprint: districtSsoSettingsData?.idp_sha_fingerprint ?? '',
    idp_logout_url: districtSsoSettingsData?.idp_logout_url ?? '',
  }), [districtSsoSettingsData]);

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(addSsoConnectionSchema),
    defaultValues: defaultFormValues,
  });

  const { mutate: createSsoSetting, isPending: isCreating } = useCreateSsoSettings();
  const { mutate: updateSsoSetting, isPending: isUpdating } = useUpdateSsoSettings();

  const toggleModalHandler = () => {
    form.reset();
    toggleModal(!isOpen);
  };

  const createSsoSettingHandler = (createDataPayload: TFormValues, districtId: string) => {
    createSsoSetting({
      districtId,
      ...createDataPayload,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId] });
        showSuccessToast('System message', 'Single Sign-On was successfully configured');
        toggleModalHandler();
      },
      onError: (error) => {
        const errorResponseData = error.response?.data;
        if (errorResponseData) {
          const { idp_certificate, idp_sha_fingerprint } = errorResponseData;
          if (idp_certificate) {
            form.setError('idp_certificate', {
              type: 'manual',
              message: idp_certificate[0],
            });
          }
          if (idp_sha_fingerprint) {
            form.setError('idp_sha_fingerprint', {
              type: 'manual',
              message: idp_sha_fingerprint[0],
            });
          }
        }
      },
    });
  };

  const updateSsoSettingHandler = (updateDataPayload: TFormValues, districtId: string) => {
    updateSsoSetting({
      districtId,
      ...updateDataPayload,
      is_enabled: districtSsoSettingsData?.is_enabled ?? false,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId] });
        showSuccessToast('System message', 'Single Sign-On settings were successfully updated');
        toggleModalHandler();
      },
      onError: (error) => {
        const errorResponseData = error.response?.data;
        if (errorResponseData) {
          const { idp_certificate, idp_sha_fingerprint } = errorResponseData;
          if (idp_certificate) {
            form.setError('idp_certificate', {
              type: 'manual',
              message: idp_certificate[0],
            });
          }
          if (idp_sha_fingerprint) {
            form.setError('idp_sha_fingerprint', {
              type: 'manual',
              message: idp_sha_fingerprint[0],
            });
          }
        }
      },
    });
  };

  const formSubmitHandler = (data: TFormValues) => {
    if (!districtId) {
      return showErrorToast('System message', 'Could not find district with provided ID');
    }
    districtSsoSettingsData ? updateSsoSettingHandler(data, districtId) : createSsoSettingHandler(data, districtId);
  };

  useEffect(() => {
    form.reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModalHandler}>
      <DialogContent className="max-w-[564px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogDescription className="sr-only">A modal window to add new SSO connection</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form providerProps={form} className="flex flex-col gap-4 p-6" id={formId} onSubmit={form.handleSubmit(formSubmitHandler)}>
          <FormField
            control={form.control}
            name="provider_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Provider Type</FormLabel>
                <RadixSelect value={field.value} onValueChange={field.onChange}>
                  <FormControl>
                    <RadixSelectTrigger>
                      <RadixSelectValue placeholder="Select provider type" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {SSO_PROVIDERS_SELECT_OPTIONS.map((option) => (
                      <RadixSelectItem key={option.value} value={option.value} onSelect={field.onChange}>
                        {option.label}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="idp_sso_url"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>SSO URL</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter link" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="idp_entity_id"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Entity ID</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter ID" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="idp_certificate"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Certificate</FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    isError={!!fieldState.error}
                    placeholder="Enter certificate"
                    responsiveHeight
                    className="max-h-[150px] min-h-[120px] resize-none"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="idp_sha_fingerprint"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>SHA-256 fingerprint</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter fingerprint" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="idp_logout_url"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel>Logout URL</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter link" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button type="button" variant="tertiary" onClick={closeModal}>Cancel</Button>
          <Button type="submit" variant="primary" form={formId} isLoading={isCreating || isUpdating}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
