import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import { replaceUrlParameters } from '../helpers';
import type { TDistrictUpdateResponsibleForSetupResponse } from '@purple/shared-types';

export const updateResponsibleForSetup = async ({
  districtId,
  responsible_for_setup,
}: {
  districtId: number;
  responsible_for_setup: string;
}) => {
  const URL = replaceUrlParameters(DISTRICTS_ENDPOINTS.UPDATE_RESPONSIBLE_FOR_SETUP, districtId.toString());

  const response = await axiosInstance.patch<TDistrictUpdateResponsibleForSetupResponse>(URL, {
    responsible_for_setup,
  });
  return response.data;
};
