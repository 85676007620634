import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading } from '@purple/ui';
import type { FC } from 'react';
import type { PurpleIconType } from '@purple/icons';

type TActionSectionHeaderProps = {
  title: string;
  editing?: boolean;
  formId?: string;
  loading?: boolean;
  editButtonIcon?: PurpleIconType;
  editButtonLabel?: string;
  disableEdit?: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
};

export const ActionSectionHeader: FC<TActionSectionHeaderProps> = ({ editing, onCancel, onEdit, title, loading = false, editButtonLabel = 'Edit', formId, editButtonIcon = 'pencil', disableEdit = false }) => {
  const editingControls = useMemo(
    () =>
      editing
        ? (
            <div className="ml-auto flex flex-row items-center gap-3">
              <Button type="button" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" size="small" isLoading={loading} disabled={loading} form={formId}>
                Save
              </Button>
            </div>
          )
        : (
            <Button
              type="button"
              variant="tertiary"
              size="small"
              className="ml-auto"
              iconLeft={<PurpleIcon name={editButtonIcon} className="size-4 shrink-0" />}
              onClick={onEdit}
            >
              {editButtonLabel}
            </Button>
          ),
    [editing, loading, onCancel, onEdit, editButtonLabel, formId, editButtonIcon],
  );

  return (
    <div className="flex flex-row items-center justify-between gap-2">
      {title && (
        <Heading tag="h3" className="text-base font-semibold text-grey-950">
          {title}
        </Heading>
      )}
      {!disableEdit && editingControls}
    </div>
  );
};
