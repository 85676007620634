import axiosInstance from '../axios-config';
import { TAGS_ENDPOINTS } from '../endpoints';
import type { TCustomTagListResponse, TCustomTagsListRequestParameters } from '@purple/shared-types';

export const getTagsList = async (parameters: TCustomTagsListRequestParameters) => {
  const response = await axiosInstance.get<TCustomTagListResponse>(TAGS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
