import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictSafProcess } from './update-district-saf-process';
import type { AxiosError } from '@purple/shared-services';
import type { TUpdateDistrictSafProcessPayload, TUpdateDistrictSafProcessResponse } from './districts.types';

export const useUpdateDistrictSafProcess = (options?: { disableInvalidate?: boolean }) => {
  const { disableInvalidate = false } = options ?? {};
  const queryClient = useQueryClient();

  return useMutation<TUpdateDistrictSafProcessResponse, AxiosError<{
    responsible_for_setup: string[];
  }>, TUpdateDistrictSafProcessPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_SAF_PROCESS],
    mutationFn: updateDistrictSafProcess,
    onSuccess: (data) => {
      if (!disableInvalidate) {
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DETAILS, data.id.toString()] });
      }
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL] });
      showSuccessToast('System message', 'District SAF process was updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status === 400 && Boolean(error.response.data.responsible_for_setup.length)) {
        const message = error.response.data.responsible_for_setup.join(', ');
        showErrorToast('System message', message);
      } else {
        showErrorToast('System message', 'Failed to update district SAF process. Please try again later');
      }
    },
  });
};
