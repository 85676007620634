/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const DataTableViewSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <Skeleton className="h-10 w-full max-w-[300px]" />
      <div className="flex w-full flex-col">
        <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
          <Skeleton className="col-span-1 size-5" />
          <Skeleton className="col-span-1 h-0 w-full" />
          <Skeleton className="col-span-3 h-5 w-full max-w-[144px]" />
          <div className="col-span-3 flex w-full items-center gap-2 px-3">
            <Skeleton className="size-5" />
            <Skeleton className="h-5 w-full max-w-[114px]" />
          </div>
          <Skeleton className="col-span-4 h-5 w-full max-w-[94px]" />
        </div>
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="grid h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-3 last-of-type:border-none" key={index}>
            <Skeleton className="col-span-1 size-5" />
            <Skeleton className="col-span-1 h-0 w-full" />
            <Skeleton className="col-span-3 h-5 w-full max-w-[100px]" style={{ maxWidth: `${index % 2 === 0 ? '100px' : '145px'}` }} />
            <div className="col-span-3 flex w-full items-center px-3">
              <Skeleton className="size-5" />
            </div>
            <Skeleton className="col-span-4 h-9 w-full max-w-[300px]" />
          </div>
        ))}
      </div>
    </div>
  );
};
