import { Text } from '@purple/ui';
import type { TUserBasicInfo } from '~/services';

type TBasicDescriptionViewProperties = {
  data: TUserBasicInfo;
};

const BasicDescriptionView = ({ data }: TBasicDescriptionViewProperties) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <Text variant="size-16" type="body-400" className="w-full text-grey-950">{data.description || '-'}</Text>
    </div>
  );
};

export { BasicDescriptionView };
