import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { updateActionTag } from './update-action-tag';
import type { AxiosError } from 'axios';
import type { TActionTagUpdateBody, TActionTagUpdateResponse } from '@purple/shared-types';

export const useUpdateActionTags = (actionId: string) => {
  const queryClient = useQueryClient();

  return useMutation<TActionTagUpdateResponse, AxiosError, TActionTagUpdateBody & { actionId: string }>({
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_ACTION_TAGS],
    mutationFn: updateActionTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_INDIVIDUAL_ACTION_DETAILS, actionId] });
      showSuccessToast('System message', 'The action tags were updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update the action tags. Check the provided information and try again');
      }
    },
  });
};
