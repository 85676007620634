import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateResponsibleForSetup } from './update-responsible-for-setup';

export const useUpdateResponsibleForSetup = () => {
  return useMutation({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_RESPONSIBLE_FOR_SETUP],
    mutationFn: updateResponsibleForSetup,
    onSuccess: () => {
      showSuccessToast('System message', 'Responsible was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Could not update a responsible. Check the provided information and try again');
    },
  });
};
