import axiosInstance from '~/services/axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TUserActivatePayload } from './user.types';

export const bulkUsersActivate = async (payload: TUserActivatePayload) => {
  const response = await axiosInstance.post<void>(
    ADMIN_USER_MANAGEMENT.USERS_ACTIVATION,
    payload,
  );
  return response.data;
};
