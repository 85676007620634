import { useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { cutGradeName, formateDateShortMonthWithTime } from '@purple/shared-utils';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger, Text } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useGoBack, useModal } from '~/hooks';
import { type TAdminSafDetailResponse, useSafBulkDelete } from '~/services';
import { PriorityColumn } from '../PriorityColumn';
import { StatusColumn } from '../StatusColumn';

type TDetailHeaderProperties = {
  data: TAdminSafDetailResponse;
};

const DetailHeader = ({ data }: TDetailHeaderProperties) => {
  const { id, subject, saf_number, status, priority, student, submitted_student_first_name, submitted_student_middle_name, submitted_student_last_name, submitted_student_grade, created_at } = data;

  const dateTime = useMemo(() => formateDateShortMonthWithTime(created_at), [created_at]);

  const isStudentLinked = useMemo(() => Boolean(student), [student]);

  const studentNameLabel = useMemo(() => {
    const submittedStudentFullName = `${submitted_student_first_name} ${submitted_student_middle_name} ${submitted_student_last_name}`;
    const linkedStudentFullName = student?.full_name || '-';

    if (isStudentLinked) {
      return linkedStudentFullName;
    } else {
      return submittedStudentFullName;
    }
  }, [isStudentLinked, student?.full_name, submitted_student_first_name, submitted_student_last_name, submitted_student_middle_name]);

  const gradeLabel = useMemo(() => isStudentLinked ? cutGradeName(student?.grade as string) : cutGradeName(submitted_student_grade), [isStudentLinked, student, submitted_student_grade]);

  const { onGoBack } = useGoBack({ fallbackPath: AdminRoutes.App.Safs.Root.path });

  const { openModal: openDeleteSafModal, closeModal: closeDeleteSafModal } = useModal(
    ModalType.SAF_DELETE,
  );

  const { mutate: bulkDelete, isPending: isSafDeleting } = useSafBulkDelete();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const confirmDeleteSafHandler = () => {
    if (id) {
      bulkDelete({ safIds: [id] }, {
        onSuccess: () => {
          closeDeleteSafModal();
          onGoBack();
        },
      });
    }
  };

  return (
    <div className="flex w-full flex-row items-start justify-between rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex gap-3">
        <div className="flex aspect-[1/1] size-[60px] items-center justify-center rounded-lg bg-grey-100">
          <PurpleIcon name="saf" className="text-brand-blue-700" />
        </div>
        <div className="flex flex-col gap-4">
          <Text variant="size-16" type="body-600" className="text-grey-950">{subject}</Text>
          <div className="flex flex-row gap-6">
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">SAF Number</Text>
              <Text variant="size-14" type="body-500" className="text-grey-950">{saf_number}</Text>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Status</Text>
              <div>
                <StatusColumn status={status} />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Priority</Text>
              <div>
                <PriorityColumn priority={priority} />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Student</Text>
              {isStudentLinked
                ? (
                  // TODO: add link to the StudentDetail page when it is created
                    <Text variant="size-14" type="body-500" className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{studentNameLabel}</Text>
                  )
                : (
                    <Text variant="size-14" type="body-500" className="text-grey-950">{studentNameLabel}</Text>
                  )}

            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Grade</Text>
              <Text variant="size-14" type="body-500" className="text-grey-950">{gradeLabel}</Text>
            </div>
            <div className="flex flex-col gap-1">
              <Text variant="size-14" type="body-400" className="text-grey-600">Date & Time Opened</Text>
              <Text variant="size-14" type="body-500" className="text-grey-950">{dateTime}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <Button variant="secondary" type="button" onClick={() => {}}>Transfer SAF</Button>
        <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
          <DropdownTrigger asChild>
            <Button
              iconLeft={<PurpleIcon name="dots-vertical" />}
              variant="secondary"
              size="icon_40"
            />
          </DropdownTrigger>
          <DropdownContent className="max-w-[196px] gap-1" align="end" sideOffset={-2}>
            <Button
              variant="link_destructive"
              onClick={openDeleteSafModal}
              className="w-full justify-start"
              iconLeft={<PurpleIcon name="trash" />}
            >
              Delete
            </Button>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <CallToActionModal
        modalName={ModalType.SAF_DELETE}
        modalTitle="Delete SAF"
        modalDescription="Are you sure you want to delete the selected SAF?"
        modalTextContent="Are you sure you want to delete the selected SAF?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
    </div>
  );
};

export { DetailHeader };
