import { Link } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage, cn } from '@purple/ui';
import defaultLogo from '~/assets/images/default-logo.png';
import { AdminRoutes } from '~/constants';
import { useSidebar } from '~/hooks';
import { Menu } from '../Menu';
import { SidebarToggle } from './SidebarToggle';

export const Sidebar: React.FC = () => {
  const { isOpen } = useSidebar();

  return (
    <aside
      className={cn(
        'fixed left-0 top-0 z-20 h-screen -translate-x-full border-r border-grey-200 bg-white transition-[width] duration-300 ease-in-out lg:translate-x-0',
        isOpen ? 'w-[260px]' : 'w-[76px]',
      )}
    >
      <SidebarToggle />
      <div className="relative flex h-full flex-col overflow-y-auto">
        <div className={cn('flex items-center justify-center px-3 pb-4 pt-5', { 'justify-start': isOpen })}>
          <Link
            to={AdminRoutes.App.Home.Root.path}
            className={cn(
              'flex select-none items-center gap-2 font-primary text-base font-semibold text-grey-950 outline-none transition-transform duration-300 ease-in-out hover:text-brand-blue-700 focus-visible:text-brand-blue-700 active:text-brand-blue-400',
            )}
          >
            <Avatar size={32} variant="square">
              <AvatarImage src={defaultLogo} alt="ProUnitas brand logo" />
              <AvatarFallback>PU</AvatarFallback>
            </Avatar>
            <strong className={cn(isOpen ? 'block font-semibold' : 'hidden')}>ProUnitas</strong>
          </Link>
        </div>
        <Menu isOpen={isOpen} />
      </div>
    </aside>
  );
};
