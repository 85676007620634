import { TakeActionDocumentType, TakeActionLinkType, type ValueOf } from '@purple/shared-types';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';

export const ACTION_TYPES_TABS_VALUES = {
  basicDetails: 'basicDetails',
  customTags: 'customTags',
} as const;

export type TActionTypesTabsValues = ValueOf<typeof ACTION_TYPES_TABS_VALUES>;

export const ACTION_TYPE_STATUS_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
] as const;

export const DEFAULT_DOCUMENT_AS_OPTIONS = Object.values(TakeActionDocumentType).map((value) => ({ value, label: snakeToCamelWithSpaces(value) }));

export const DEFAULT_LINKED_AS_OPTIONS = Object.values(TakeActionLinkType).map((value) => ({ value, label: snakeToCamelWithSpaces(value) }));
