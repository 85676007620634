import axiosInstance from '../axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictMappingEventsRequestParameters, TDistrictMappingEventsResponse } from './districts.types';

export const getMappingEvents = async ({ districtId, mappingId, ...rest }: TDistrictMappingEventsRequestParameters) => {
  const response = await axiosInstance.get<TDistrictMappingEventsResponse>(DISTRICTS_ENDPOINTS.MAPPINGS_EVENTS(districtId, mappingId), {
    params: rest,
  });
  return response.data;
};
