import { Heading, Separator, Skeleton, Text } from '@purple/ui';

const BasicDetailTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="grid grid-cols-2 py-8">
        <div className="border-r border-grey-200 px-6 py-0">
          <Skeleton className="h-[380px]" />
        </div>
        <div className="flex flex-col gap-[20px] px-6 py-0">
          <Skeleton className="h-[380px]" />
        </div>
      </div>
      <Separator className="bg-grey-200" />
      <div className="flex flex-col gap-4 px-6 py-4">
        <Text variant="size-16" type="body-500" className="text-grey-title">Purple Stats</Text>
        <div className="flex w-full gap-4">
          <Skeleton className="h-[100px] w-full" />
          <Skeleton className="h-[100px] w-full" />
          <Skeleton className="h-[100px] w-full" />
        </div>
      </div>
    </div>
  );
};

export { BasicDetailTabSkeleton };
