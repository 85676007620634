import * as z from 'zod';
import { s3PathRegex } from '@purple/shared-utils';

// Define the allowed file types and max file size
const ACCEPTED_FILE_TYPES_SET = new Set([
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]);

const MAX_FILE_SIZE = 1024 * 1024 * 10;

// Mapping form schema
export const mappingFormSchema = z
  .object({
    currentStep: z.number().default(0),
    custom_created: z.boolean().default(false),
    initialUploadedFile: z.string().optional(),
    // STEP 1
    uploaded_file: z.array(z.instanceof(File)).optional(),
    // STEP 2
    file_path: z.string().optional(),
    name: z.string().trim().optional(),
    mapping: z
      .array(
        z.object({
          name: z.string().trim().optional(),
          display_name: z.string().trim().optional(),
          value: z.string().trim().optional(),
          is_mapped: z.boolean().optional(),
          is_required: z.boolean().optional(),
          is_custom: z.boolean().optional(),
        }),
      )
      .optional(),
  })
  .superRefine((data, context) => {
    // Validation for step 0
    if (data.currentStep === 0 && !data.initialUploadedFile && data.uploaded_file && data.uploaded_file.length === 0) {
      context.addIssue({
        path: ['uploaded_file'],
        code: z.ZodIssueCode.custom,
        message: 'Please make sure the file format is XLSX or CSV and it is less than 10MB. Only one file at a time can be uploaded.',
      });
    }
    if (data.currentStep === 0 && data.uploaded_file && data.uploaded_file.length > 0 && data.uploaded_file[0]) {
      const [file] = data.uploaded_file;
      if (!ACCEPTED_FILE_TYPES_SET.has(file.type)) {
        context.addIssue({
          path: ['uploaded_file'],
          code: z.ZodIssueCode.custom,
          message: 'Invalid file type. Only CSV and XLSX are supported',
        });
      }
      if (file.size > MAX_FILE_SIZE) {
        context.addIssue({
          path: ['uploaded_file'],
          code: z.ZodIssueCode.custom,
          message: 'File size must be less than 10MB',
        });
      }
    }

    // Validation for step 1
    if (data.currentStep === 1) {
      if (!data.file_path || !s3PathRegex.test(data.file_path)) {
        context.addIssue({
          path: ['file_path'],
          code: z.ZodIssueCode.custom,
          message: 'Invalid file path. Example: bucket-name/folder/file.csv',
        });
      }

      if (data.custom_created && !data.name) {
        context.addIssue({
          path: ['name'],
          code: z.ZodIssueCode.custom,
          message: 'File name is required',
        });
      }
      if (data.mapping) {
        for (const [index, item] of data.mapping.entries()) {
          if (item.is_required && !item.value) {
            context.addIssue({
              path: ['mapping', index, 'value'],
              code: z.ZodIssueCode.custom,
              message: 'This field is required',
            });
          }
        }
      }
    }
  });
