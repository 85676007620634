import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { PERMISSION_GROUP_PARAMETERS_KEY } from '../../constants';

export const ManageGroupHeader = () => {
  const navigate = useNavigate();

  const navigateToGroupsListHandler = useCallback(() => navigate(AdminRoutes.App.Users.Permissions.Root.path), [navigate]);

  const { query: groupId } = useQueryParameter({ queryName: PERMISSION_GROUP_PARAMETERS_KEY.groupId });

  return (
    <div className="flex w-full items-center justify-between gap-2 border-b border-b-grey-300 bg-white px-8 py-3.5">
      <div className="flex items-center gap-2">
        <Button variant="tertiary_icon_only" size="icon_32" onClick={navigateToGroupsListHandler} iconLeft={<PurpleIcon name="chevron-left" />} />
        <Text variant="size-16" type="body-600">{groupId ? 'Update Permissions Group ' : 'Create Permissions Group '}</Text>
      </div>
    </div>
  );
};
