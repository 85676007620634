import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getGroupList } from './get-group-list';
import { GROUPS_QUERY_KEYS } from './groups.const';
import type { AxiosError } from 'axios';
import type { TGroupListResponse, TGroupsListRequestParameters } from './groups.types';

type TUseGroupListParameters = {
  requestParameters: TGroupsListRequestParameters;
  enabled?: boolean;
};

export const useGroupList = (parameters?: TUseGroupListParameters) => {
  const { requestParameters, enabled = true } = parameters || {};
  const { isError, error, ...rest } = useQuery<TGroupListResponse, AxiosError>({
    queryKey: [GROUPS_QUERY_KEYS.GET_GROUPS_LIST, requestParameters],
    queryFn: () => getGroupList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'An error occurred while fetching groups list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
