import { useState } from 'react';
import { type Control, type FieldValues, type Path, useController } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';

type TColumnNameFormInputProperties<TFormValues extends FieldValues = FieldValues> = {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  value: string;
  displayName: string;
  isRequired: boolean;
  isCustom: boolean;
};

export const ColumnNameFormItem = <TFormValues extends FieldValues = FieldValues>(
  props: TColumnNameFormInputProperties<TFormValues>,
) => {
  const { control, name, isCustom, isRequired } = props;

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<string>(value);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const saveClickHandler = () => {
    setSavedValue(value);
    setIsEditing(false);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    onChange(savedValue);
  };
  if (isEditing && isCustom) {
    return (
      <FormField
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormItem className="relative">
            <HoverCard open={isEditing && isCustom}>
              <HoverCardTrigger asChild>
                <FormControl>
                  <Input {...field} placeholder="Input column name here" isError={!!fieldState.error} />
                </FormControl>
              </HoverCardTrigger>
              <HoverCardContent
                align="end"
                sideOffset={0}
                className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
              >
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="primary"
                      size="icon_32"
                      className="shadow-custom-medium"
                      disabled={!!fieldState.error}
                      iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                      onClick={saveClickHandler}
                    />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Save</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="secondary"
                      size="icon_32"
                      className="border-0 shadow-custom-medium"
                      iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                      onClick={cancelClickHandler}
                    />
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>Cancel</TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </HoverCardContent>
            </HoverCard>
            <FormMessage className="absolute" />
          </FormItem>
        )}
      />
    );
  }

  return (
    <div className="flex items-center gap-2">
      <Label className="text-sm">
        {value || '—'}
        {isRequired && <span className="text-error-main">*</span>}
      </Label>
      {isCustom && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-7"
              iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
              onClick={editClickHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Edit need name</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
    </div>
  );
};
