import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormMessage, Textarea } from '@purple/ui';
import { useUpdateAction } from '~/services';
import { goalsSchema } from './schema';
import type { FC } from 'react';
import type * as z from 'zod';
import type { TActionDetails } from '@purple/shared-types';

type TFormValues = z.infer<typeof goalsSchema>;

type TGoalsEditFormProperties = {
  action: TActionDetails;
  formId: string;
  loadingChange: (isLoading: boolean) => void;
  editModeChange: (isEditMode: boolean) => void;
};

export const GoalsEditForm: FC<TGoalsEditFormProperties> = memo(({ action, formId, loadingChange, editModeChange }) => {
  const defaultValues = useMemo(
    () => ({
      goal: action.goal_or_progress_towards_goal || '',
    }),
    [action.goal_or_progress_towards_goal],
  );

  const { mutate: updateAction } = useUpdateAction();

  const form = useForm<TFormValues>({
    resolver: zodResolver(goalsSchema),
    mode: 'onChange',
    defaultValues,
  });

  const updateGoalHandler = async (formValues: TFormValues) => {
    loadingChange(true);
    updateAction({ id: action.id, goal_or_progress_towards_goal: formValues.goal }, {
      onSettled: () => {
        loadingChange(false);
      },
      onSuccess: () => {
        form.reset();
        editModeChange(false);
      },
    });
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Form providerProps={form} id={formId} className="flex w-full flex-col gap-1" onSubmit={form.handleSubmit(updateGoalHandler)}>
      <FormField
        control={form.control}
        name="goal"
        render={({ field, fieldState }) => (
          <FormItem className="col-span-2">
            <FormControl>
              <Textarea
                {...field}
                isError={!!fieldState.error}
                placeholder="Enter goals or progress towards goals"
                responsiveHeight
                className="min-h-[80px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
});
