import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { convertSafDetailSubcategoryDtoToView } from './converter';
import { getSafServiceCategories } from './get-saf-service-categories';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafServiceCategoriesRequestParameters, TAdminSafSubCategoryDto } from './saf.types';

export const useSafServiceCategories = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminSafServiceCategoriesRequestParameters;
  enabled?: boolean;
}) => {
  const safId = requestParameters.safId || '';

  const { isError, error, data, ...rest } = useQuery<TAdminSafSubCategoryDto[], AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.SAF_SERVICE_CATEGORIES, requestParameters],
    queryFn: () => getSafServiceCategories({ safId }),
    enabled: Boolean(safId) && enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch saf service categories');
    }
  }, [isError, error]);

  return { isError, error, data: data ? convertSafDetailSubcategoryDtoToView(data) : data, ...rest };
};
