import { useState } from 'react';
import { useController } from 'react-hook-form';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { Control, FieldValues, Path } from 'react-hook-form';

type TSafNeedTypeFormItemProperties<TFormValues extends FieldValues = FieldValues> = {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  value: string;
  id: string;
  selected?: boolean;
  intermediate?: boolean;
  disableRemove?: boolean;
  onRemove?: () => void;
  onCheckedChange?: (value: boolean) => void;
};

export const SafNeedTypeFormItem = <TFormValues extends FieldValues = FieldValues>(
  props: TSafNeedTypeFormItemProperties<TFormValues>,
): JSX.Element => {
  const {
    control,
    id,
    selected = false,
    disableRemove = false,
    intermediate = false,
    name,
    onCheckedChange,
    onRemove,
  } = props;

  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<string>(value);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const saveClickHandler = () => {
    setSavedValue(value);
    setIsEditing(false);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    onChange(savedValue);
  };

  useKeyPress('Escape', cancelClickHandler);

  return (
    <div className="flex items-start gap-2">
      <div className="flex h-8 items-center justify-center">
        <Checkbox
          id={id}
          variant={intermediate ? 'checkedAll' : 'default'}
          checked={selected}
          onCheckedChange={onCheckedChange}
        />
      </div>
      {isEditing
        ? (
            <FormField
              control={control}
              name={name}
              render={({ field, fieldState }) => (
                <FormItem className="flex basis-[470px] flex-col">
                  <HoverCard open={isEditing}>
                    <HoverCardTrigger asChild>
                      <FormControl>
                        <Input
                          type="text"
                          {...field}
                          sizeVariant="small"
                          placeholder="Enter need name"
                          containerClassName="w-full"
                          className="w-full min-w-48 max-w-[470px] shrink grow rounded px-3 py-1.5"
                        />
                      </FormControl>
                    </HoverCardTrigger>
                    <HoverCardContent
                      align="end"
                      sideOffset={0}
                      className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                    >
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            type="button"
                            variant="primary"
                            size="icon_32"
                            className="shadow-custom-medium"
                            disabled={!!fieldState.error}
                            iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                            onClick={saveClickHandler}
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent>Save</TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            type="button"
                            variant="secondary"
                            size="icon_32"
                            className="border-0 shadow-custom-medium"
                            iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                            onClick={cancelClickHandler}
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent>Cancel</TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                    </HoverCardContent>
                  </HoverCard>
                  <FormMessage />
                </FormItem>
              )}
            />
          )
        : (
            <Label
              htmlFor={id}
              className="inline-flex min-h-8 items-center font-primary text-base font-normal text-grey-800"
            >
              {value}
            </Label>
          )}
      {!isEditing && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-7"
              iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
              onClick={editClickHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Edit need name</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      )}
      {!disableRemove && (
        <div className="flex h-8 items-center justify-center">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="destructive_secondary"
                size="icon_32"
                className="size-7 border-0"
                iconLeft={<PurpleIcon name="trash" className="size-4 shrink-0" />}
                onClick={onRemove}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Remove need item</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
