import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TUpdateDistrictSafProcessOwnerPayload } from './districts.types';

export const updateDistrictSafProcessOwner = async ({ id, payload }: {
  id: string;
  payload: TUpdateDistrictSafProcessOwnerPayload;
}) => {
  const response = await axiosInstance.put<void>(DISTRICTS_ENDPOINTS.UPDATE_SAFE_PROCESS_OWNER(id), payload);
  return response.data;
};
