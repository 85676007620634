/**
 * Checks if the provided value is of type number.
 *
 * @param value - The value to be checked.
 * @returns A boolean indicating whether the value is a number.
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

/**
 * Generates a random integer between min (inclusive) and max (inclusive).
 * @param min - The minimum value of the range (inclusive).
 * @param max - The maximum value of the range (inclusive).
 * @returns A random integer within the given range.
 */
export function getRandomIntegerInRange(min: number, max: number): number {
  if (min > max) {
    throw new Error('Min should be less than or equal to Max');
  }
  const range = max - min + 1;
  return Math.floor(Math.random() * range) + min;
}
