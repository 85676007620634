import { useFormContext } from 'react-hook-form';
import { cn, FormControl, FormField, FormItem, FormLabel, FormMessage, NumberInput } from '@purple/ui';
import type { Path } from 'react-hook-form';

type TFloatNumberFieldProps<TSchema extends object = object> = {
  name: Path<TSchema>;
  label?: string;
  min?: number;
  step?: number;
  precision?: number;
  decimalSeparator?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: number | string | null) => void;
  className?: string;
};

export const FloatNumberField = <TSchema extends object = object>(props: TFloatNumberFieldProps<TSchema>): JSX.Element => {
  const {
    name,
    label,
    min = 0,
    step = 0.1,
    precision = 2,
    decimalSeparator = '.',
    placeholder = '0.00',
    required = true,
    disabled = false,
    onChange,
    className,
  } = props;

  const ctx = useFormContext<TSchema>();

  return (
    <FormField
      control={ctx.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('col-span-1', className)}>
          {label && (
            <FormLabel required={required}>
              {label}
            </FormLabel>
          )}
          <FormControl>
            <NumberInput
              {...field}
              onChange={(value) => {
                field.onChange(value);
                onChange?.(value);
              }}
              name={name}
              min={min}
              step={step}
              precision={precision}
              decimalSeparator={decimalSeparator}
              changeOnWheel
              isError={!!fieldState.error}
              placeholder={placeholder}
              disabled={disabled}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
