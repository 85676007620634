import { useNavigate, useParams } from 'react-router-dom';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { AdminRoutes } from '~/constants';
import { useDistrict } from '~/services';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { BrandingColor } from './BrandingColor';
import { BrandingLogo } from './BrandingLogo';
import { DistrictBrandingSkeleton } from './DistrictBrandingSkeleton';

export const DistrictBrandingTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: district, isFetching } = useDistrict({ id: id as string });

  if (isFetching || !district) return <DistrictBrandingSkeleton />;

  if (district.status === DISTRICT_STATUS.DRAFT) {
    navigate(AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: district.id } }), {
      replace: true,
    });
  }

  return (
    <DistrictTabContainer title="Branding" className="gap-4">
      <BrandingLogo district={district} />
      <BrandingColor district={district} />
    </DistrictTabContainer>
  );
};
