import { useMemo } from 'react';
import { convertBooleanToText, snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TUserBasicInfo } from '~/services';

type TBasicDetailViewProperties = {
  data: TUserBasicInfo;
};

const BasicDetailView = ({ data }: TBasicDetailViewProperties) => {
  const { first_name, last_name, current_status, roles, email, phone_number, title, department, responsible_for_grades, is_show_on_scorecard } = data;

  const fullName = useMemo(() => `${first_name} ${last_name}`, [first_name, last_name]);
  const rolesNames = useMemo(() => roles.length ? roles.map((role) => role.name).join(', ') : '-', [roles]);
  const possibleGradesNames = useMemo(() => responsible_for_grades.join(', '), [responsible_for_grades]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Full Name</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{fullName}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Status</Text>
        <Text variant="size-16" type="body-500" className="w-full capitalize text-grey-950">{snakeCaseToCapitalized(current_status)}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Role</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{rolesNames}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Email</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{email || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Phone</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{phone_number || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Title/Position</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{title || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Department</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{department || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Responsible for Grades</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{possibleGradesNames || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Show On Scorecard</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{convertBooleanToText(is_show_on_scorecard)}</Text>
      </div>
    </div>
  );
};

export { BasicDetailView };
