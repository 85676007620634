import { nanoid } from '@reduxjs/toolkit';
import { HierarchyNodeOrderMap, HierarchyNodeType } from '~/components';
import type { THierarchyBranch, THierarchyNode } from '~/components';
import type { TSchoolHierarchy, TSchoolHierarchyUpdateHierarchy } from '~/services';

const getHierarchyNodes = (node: TSchoolHierarchyUpdateHierarchy | null): THierarchyNode[] => {
  if (!node) return [];

  const { id, name, type, child } = node;

  return [
    {
      id: id ?? nanoid(),
      name,
      type,
      icon: 'point',
    },
    ...getHierarchyNodes(child),
  ];
};

export const adaptSchoolHierarchyToTreeData = (hierarchy?: TSchoolHierarchy): THierarchyNode[] => {
  if (!hierarchy) return [];

  const prependedNodes = [...getHierarchyNodes(hierarchy.nodes)].sort((a, b) => HierarchyNodeOrderMap[a.type] - HierarchyNodeOrderMap[b.type]);

  return [
    {
      id: nanoid(),
      name: hierarchy.district,
      type: HierarchyNodeType.DISTRICT,
      icon: 'disctrict',
      disableActions: true,
      children: [
        ...prependedNodes,
        {
          id: nanoid(),
          type: HierarchyNodeType.SCHOOL,
          name: hierarchy.school,
          icon: 'School',
          disableActions: true,
        },
      ],
    },
  ];
};

export const adaptTreeDataToHierarchy = (data: THierarchyNode[]): TSchoolHierarchyUpdateHierarchy | null => {
  if (data.length === 0) return null;

  const nodes = data.find((item) => item.type === HierarchyNodeType.DISTRICT)?.children ?? [];

  let hierarchy: TSchoolHierarchyUpdateHierarchy | null = null;
  let currentNode: TSchoolHierarchyUpdateHierarchy | null = null;

  for (const [type] of Object.entries(HierarchyNodeOrderMap).sort(([_a, orderA], [_b, orderB]) => orderA - orderB)) {
    const node = nodes.find((item) => item.type === type);
    if (node && node.type !== HierarchyNodeType.SCHOOL && node.type !== HierarchyNodeType.DISTRICT) {
      const newNode: TSchoolHierarchyUpdateHierarchy = {
        ...(typeof node.id === 'number' ? { id: node.id } : {}),
        name: node.name,
        type: node.type,
        child: null,
      };

      if (!hierarchy) {
        hierarchy = newNode;
      } else if (currentNode) {
        currentNode.child = newNode;
      }

      currentNode = newNode;
    }
  }

  return hierarchy;
};

export const addNodeToTreeData = (data: THierarchyNode[], node: THierarchyBranch): THierarchyNode[] => {
  const treeData = [...data];
  const districtNode = treeData.find((item) => item.type === HierarchyNodeType.DISTRICT);
  if (!districtNode) return treeData;

  const nodes = districtNode.children ? [...districtNode.children] : [];

  const newNode: THierarchyNode = {
    id: typeof node.id === 'number' ? node.id : nanoid(),
    name: node.name,
    type: node.type,
    icon: 'point',
  };

  const index = nodes.findIndex((item) => HierarchyNodeOrderMap[item.type] > HierarchyNodeOrderMap[node.type]);

  const updatedNodes = index === -1 ? [...nodes, newNode] : [...nodes.slice(0, index), newNode, ...nodes.slice(index)];

  const updatedDistrictNode = { ...districtNode, children: updatedNodes };

  return treeData.map((item) => (item.type === HierarchyNodeType.DISTRICT ? updatedDistrictNode : item));
};

export const deleteNodeFromTreeData = (data: THierarchyNode[], nodeId: string | number): THierarchyNode[] => {
  const treeData = [...data];
  const districtNode = treeData.find((item) => item.type === HierarchyNodeType.DISTRICT);
  if (!districtNode) return treeData;

  const nodes = districtNode.children ?? [];

  const updatedNodes = nodes.filter((item) => item.id !== nodeId);

  const updatedDistrictNode = { ...districtNode, children: updatedNodes };

  return treeData.map((item) => (item.type === HierarchyNodeType.DISTRICT ? updatedDistrictNode : item));
};

export const editNodeInTreeData = (data: THierarchyNode[], node: Pick<THierarchyNode, 'id' | 'name' | 'type'>): THierarchyNode[] => {
  const treeData = [...data];
  const districtNode = treeData.find((item) => item.type === HierarchyNodeType.DISTRICT);
  if (!districtNode) return treeData;

  const nodes = districtNode.children ?? [];
  const nodeIndex = nodes.findIndex((item) => item.id === node.id);

  if (nodeIndex === -1) return treeData;

  const updatedNodes = [
    ...nodes.slice(0, nodeIndex),
    { ...nodes[nodeIndex], ...node },
    ...nodes.slice(nodeIndex + 1),
  ];

  const updatedDistrictNode = { ...districtNode, children: updatedNodes };

  return treeData.map((item) => (item.type === HierarchyNodeType.DISTRICT ? updatedDistrictNode : item));
};
