import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { Form } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { ColorTrendMetricType, MetricDataType, useCreateFlagCalculation, useUpdateFlagCalculation } from '~/services';
import { FlagCalculationNavigation } from './FlagCalculationNavigation';
import { flagCalculationSchema } from './schema';
import {
  AssessmentsForm,
  AttendanceForm,
  BehaviorLevelsForm,
  BehaviorNumericalForm,
  BehaviorTextForm,
  GradeLevelsForm,
  GradeNumericalForm,
  GradeTextForm,
  HealthForm,
  MetricTypeForm,
  SubpopulationsForm,
} from './step';
import { FlagCalculationStep, FlagStepper } from './stepper';
import type { z } from 'zod';
import type { TFormulaCondition, TVisualizerFlag } from '~/services';
import type { TFlagCalculationStep } from './stepper';

type TFlagCalculationFormProps = {
  formId: string;
  flagData?: TVisualizerFlag | null;
  flags?: TVisualizerFlag[];
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const FlagCalculationForm: React.FC<TFlagCalculationFormProps> = (props) => {
  const { formId, flagData, flags, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const { isOpen: isFlagModalOpen, closeModal } = useModal(ModalType.FLAG_CALCULATION);

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { when, next, current, reset } = FlagStepper.useStepper();

  const { mutate: createFlag } = useCreateFlagCalculation();
  const { mutate: updateFlag } = useUpdateFlagCalculation();

  const updateFlagId = useMemo(() => flagData?.id ?? null, [flagData?.id]);
  const disabledMetrics = useMemo(() => flags?.map((item) => ({
    type: item.type,
    disabled:
    (item.type === ColorTrendMetricType.BEHAVIOR
      && flags.flatMap(
        (flag) => flag.type === ColorTrendMetricType.BEHAVIOR && flag.data_type ? [flag.data_type] : [],
      ).length === Object.values(MetricDataType).length
    )
    || (item.type === ColorTrendMetricType.GRADE
      && flags.flatMap(
        (flag) => flag.type === ColorTrendMetricType.GRADE && flag.data_type ? [flag.data_type] : [],
      ).length === Object.values(MetricDataType).length
    )
    || (item.type !== ColorTrendMetricType.BEHAVIOR && item.type !== ColorTrendMetricType.GRADE),

    disabledDataTypes: [...new Set(flags.flatMap((flag) => (flag.type === item.type && flag.data_type && flagData?.data_type !== flag.data_type ? [flag.data_type] : [])))],
  })) ?? [], [flags, flagData]);

  const defaultValues: Partial<z.infer<typeof flagCalculationSchema>> = useMemo(
    () => ({
      _isBaselineExists: flags?.some((flag) => flag.is_baseline_added) ?? false,
      _isFlagBaseline: flagData?.is_baseline_added ?? false,
      currentStep: current.id,
      name: flagData?.name ?? '',
      type: flagData?.type,
      is_baseline_added: flagData?.is_baseline_added ?? false,
      number_of_maximum_flags: flagData?.number_of_maximum_flags === null
        ? 'N/A'
        : flagData?.number_of_maximum_flags ?? undefined,
      data_type: flagData?.data_type || undefined,
      grades: flagData?.grades ?? [],
      grade_flag_formulas: flagData?.grade_flag_formulas?.map(({ id, condition, ...formula }) => ({ ...formula, id: id!, condition: condition || null })) ?? [],
      nurse_or_health_office_visits: flagData?.nurse_or_health_office_visits,
      option1: flagData?.option1,
      option2: flagData?.option2,
      option3: flagData?.option3,
      option4: flagData?.option4,
      special_education: flagData?.special_education,
      english_language_learner: flagData?.english_language_learner,
      five_o_four: flagData?.five_o_four,
      refugee: flagData?.refugee,
      excused: flagData?.excused,
      unexcused: flagData?.unexcused,
      total_absences: flagData?.total_absences,
      iss: flagData?.iss,
      oss: flagData?.oss,
      daep: flagData?.daep,
      level1: flagData?.level1,
      level2: flagData?.level2,
      level3: flagData?.level3,
      level4: flagData?.level4,
      level5: flagData?.level5,
      contacted_parent: flagData?.contacted_parent,
      parent_or_guardian_conference: flagData?.parent_or_guardian_conference,
      behavior_contact: flagData?.behavior_contact,
      partial_day_oss: flagData?.partial_day_oss,
      partial_day_iss: flagData?.partial_day_iss,
      after_school_detention: flagData?.after_school_detention,
      reverse_suspension: flagData?.reverse_suspension,
      teacher_detention_hall: flagData?.teacher_detention_hall,
      peer_mediation: flagData?.peer_mediation,
      saturday_detention: flagData?.saturday_detention,
      unsatisfactory: flagData?.unsatisfactory,
      needs_improvement: flagData?.needs_improvement,
    }),
    [flagData, flags], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const form = useForm<z.infer<typeof flagCalculationSchema>>({
    resolver: zodResolver(flagCalculationSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.setValue('currentStep', current.id);
  }, [current.id, form]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  useEffect(() => {
    /**
     * Resetting stepper here because we can't reset it in modal root component due to
     * the fact that stepper context is not available in the root component.
     * This workaround is messy but it needs to be done because of the way the stepper is implemented.
     */
    if (!isFlagModalOpen) {
      reset();
    }
  }, [isFlagModalOpen, reset]);

  const metricType = form.watch('type');
  const dataType = form.watch('data_type');

  const successHandler = () => {
    onSubmitSuccess?.();
    closeModal();
    reset();
    form.reset(defaultValues);
  };

  const submitFormHandler = (formData: z.infer<typeof flagCalculationSchema>) => {
    if (formData.currentStep === FlagCalculationStep.METRIC_TYPE) {
      next();
      return;
    }

    if (formData.currentStep === FlagCalculationStep.DETAILS) {
      if (!districtId) return;
      onSubmit?.();

      const body: Omit<TVisualizerFlag, 'id'> = {
        district: Number(districtId),
        name: formData.name,
        type: formData.type,
        number_of_maximum_flags: formData.number_of_maximum_flags === 'N/A' ? null : formData.number_of_maximum_flags ?? null,
        is_baseline_added: formData.is_baseline_added,
        data_type: formData.data_type,
        grades: formData.grades,
        nurse_or_health_office_visits: formData.nurse_or_health_office_visits,
        option1: formData.option1,
        option2: formData.option2,
        option3: formData.option3,
        option4: formData.option4,
        special_education: formData.special_education,
        english_language_learner: formData.english_language_learner,
        five_o_four: formData.five_o_four,
        refugee: formData.refugee,
        ...(formData.type === ColorTrendMetricType.ATTENDANCE && {
          excused: formData.excused ?? null,
          unexcused: formData.unexcused ?? null,
          total_absences: formData.total_absences ?? null,
        }),
        iss: formData.iss,
        oss: formData.oss,
        daep: formData.daep,
        level1: formData.level1,
        level2: formData.level2,
        level3: formData.level3,
        level4: formData.level4,
        level5: formData.level5,
        contacted_parent: formData.contacted_parent,
        parent_or_guardian_conference: formData.parent_or_guardian_conference,
        behavior_contact: formData.behavior_contact,
        partial_day_oss: formData.partial_day_oss,
        partial_day_iss: formData.partial_day_iss,
        after_school_detention: formData.after_school_detention,
        reverse_suspension: formData.reverse_suspension,
        teacher_detention_hall: formData.teacher_detention_hall,
        peer_mediation: formData.peer_mediation,
        saturday_detention: formData.saturday_detention,
        unsatisfactory: formData.unsatisfactory,
        needs_improvement: formData.needs_improvement,
        grade_flag_formulas: formData.grade_flag_formulas.map(({ id, ...formula }) => ({
          ...formula,
          ...(typeof id === 'number' && { id }),
          flag_value: formula.flag_value as number,
          condition: formula.condition as TFormulaCondition,
        })),
      };

      if (updateFlagId) {
        updateFlag({
          id: updateFlagId,
          ...body,
        }, {
          onSuccess: successHandler,
          onError: onSubmitError,
        });
        return;
      }

      createFlag(body, {
        onSuccess: successHandler,
        onError: onSubmitError,
      });
    }
  };

  const stepNavigationItemClickHandler = (
    goToStep: (stepId: TFlagCalculationStep) => void,
    stepId: TFlagCalculationStep,
  ) => {
    if (stepId === current.id) return;

    if (stepId === FlagCalculationStep.METRIC_TYPE) {
      goToStep(stepId);
    }

    if (stepId === FlagCalculationStep.DETAILS) {
      form.trigger().then((isValid) => isValid && goToStep(stepId));
    }
  };

  return (
    <div className="flex w-full flex-col">
      <FlagCalculationNavigation onStepClick={stepNavigationItemClickHandler} />
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col"
        onSubmit={form.handleSubmit(submitFormHandler)}
        onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
      >
        {when(FlagCalculationStep.METRIC_TYPE, () => (
          <MetricTypeForm mode={updateFlagId === null ? 'create' : 'edit'} disabledOptions={disabledMetrics} />
        ))}
        {when(FlagCalculationStep.DETAILS, () => (
          <>
            {metricType === ColorTrendMetricType.BEHAVIOR && dataType === MetricDataType.NUMERICAL && <BehaviorNumericalForm />}
            {metricType === ColorTrendMetricType.BEHAVIOR && dataType === MetricDataType.LEVELS && <BehaviorLevelsForm />}
            {metricType === ColorTrendMetricType.BEHAVIOR && dataType === MetricDataType.TEXT && <BehaviorTextForm />}
            {metricType === ColorTrendMetricType.GRADE && dataType === MetricDataType.NUMERICAL && <GradeNumericalForm />}
            {metricType === ColorTrendMetricType.GRADE && dataType === MetricDataType.LEVELS && <GradeLevelsForm />}
            {metricType === ColorTrendMetricType.GRADE && dataType === MetricDataType.TEXT && <GradeTextForm />}
            {metricType === ColorTrendMetricType.ATTENDANCE && <AttendanceForm />}
            {metricType === ColorTrendMetricType.HEALTH && <HealthForm />}
            {metricType === ColorTrendMetricType.SUBPOPULATIONS && <SubpopulationsForm />}
            {metricType === ColorTrendMetricType.ASSESSMENTS && <AssessmentsForm />}
          </>
        ))}
      </Form>
    </div>
  );
};
