import { cutGradeName } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, FormMessage, Text } from '@purple/ui';
import { StudentFilterComboBox } from '~/components';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { TAdminSafBasicDetailsView } from '~/services';
import type { updateStudentDetailSchema } from './updateStudentDetailSchema';

type TStudentDetailsEditProperties = {
  data: TAdminSafBasicDetailsView;
  form: UseFormReturn<z.infer<typeof updateStudentDetailSchema>>;
};

const StudentDetailsEdit = ({ data, form }: TStudentDetailsEditProperties) => {
  const { student } = data;

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Linked Student</Text>
        <FormField
          control={form.control}
          name="student"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <StudentFilterComboBox value={field.value} onChange={field.onChange} hasClearButton onClear={() => field.onChange('')} className="h-[34px]" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Student ID</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.student_id || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Student Phone</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.phone?.join(' ') || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Grade</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.grade ? cutGradeName(student.grade) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">School</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.school?.name || '-'}</Text>
      </div>
    </Form>
  );
};

export { StudentDetailsEdit };
