import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isNumber } from '@purple/shared-utils';
import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { z } from 'zod';
import type { numberOfMaxFlagsSchema } from '../schema';
import type { attendanceSchema, TAttendanceSchema } from './attendanceSchema';

export const AttendanceForm: React.FC = () => {
  const ctx = useFormContext<z.infer<typeof attendanceSchema & typeof numberOfMaxFlagsSchema>>();

  const excused = ctx.watch('excused');
  const unexcused = ctx.watch('unexcused');
  const totalAbsences = ctx.watch('total_absences');

  const isExcusedDisabled = useMemo(() => isNumber(totalAbsences), [totalAbsences]);
  const isTotalDisabled = useMemo(() => isNumber(excused) || isNumber(unexcused), [excused, unexcused]);

  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Attendance Metrics
      </Heading>
      <FloatNumberField<TAttendanceSchema>
        name="excused"
        label="Excused"
        required={!isExcusedDisabled}
        disabled={isExcusedDisabled}
        onChange={(value) => {
          if (isNumber(value)) {
            ctx.trigger('unexcused');
            ctx.trigger('total_absences');
            ctx.trigger('number_of_maximum_flags');
          }
        }}
      />
      <FloatNumberField<TAttendanceSchema>
        name="unexcused"
        label="Unexcused"
        required={!isExcusedDisabled}
        disabled={isExcusedDisabled}
        onChange={(value) => {
          if (isNumber(value)) {
            ctx.trigger('excused');
            ctx.trigger('total_absences');
            ctx.trigger('number_of_maximum_flags');
          }
        }}
      />
      <FloatNumberField<TAttendanceSchema>
        name="total_absences"
        label="Total Absences"
        required={!isTotalDisabled}
        disabled={isTotalDisabled}
        onChange={(value) => {
          if (isNumber(value)) {
            ctx.trigger('excused');
            ctx.trigger('unexcused');
            ctx.trigger('number_of_maximum_flags');
          }
        }}
      />
      <NumberOfMaxFlagsField className="col-span-1" />
      <BaselineField />
    </div>
  );
};
