import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { transformToSnakeCase } from '@purple/shared-utils';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  ScrollArea,
  Separator,
  Text,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, type TDistrictMapping, useSisMappingDetails, useUploadMappingFile } from '~/services';
import { useUpdateMappingColumns } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { ColumMappingForm } from './ColumMappingForm';
import { mappingFormSchema } from './schema';
import { UploadMappingFileForm } from './UploadMappingFileForm';
import type { FC } from 'react';
import type * as z from 'zod';

const MAX_STEPS = 2;

type TDataMappingModalProperties = {
  selectedMapping?: TDistrictMapping | null;
  districtId?: number | null;
};

type TFormValues = z.infer<typeof mappingFormSchema>;

export const DataMappingModal: FC<TDataMappingModalProperties> = ({ selectedMapping, districtId }) => {
  const { name, is_mapping_completed, id } = selectedMapping ?? {};

  const formId = useId();
  const queryClient = useQueryClient();

  const [currentActiveStep, setCurrentActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);

  const { isOpen, toggleModal } = useModal(ModalType.DATA_MAPPING);

  const { data, isLoading, isRefetching, isError } = useSisMappingDetails(districtId?.toString() ?? '', id?.toString() ?? '');

  const modalMethod = useMemo(() => (is_mapping_completed ? 'Update' : 'Create'), [is_mapping_completed]);
  const isCustomData = useMemo(() => data?.mapping_type === 'custom', [data?.mapping_type]);
  const isContentLoading = useMemo(() => isLoading || isRefetching, [isLoading, isRefetching]);

  const { mutate: updateMappingFile, isPending: isFileUpdating } = useUploadMappingFile();
  const { mutate: updateMappingColumns, isPending: isColumnsUpdating } = useUpdateMappingColumns();

  const defaultValues = useMemo(() => {
    return {
      currentStep: currentActiveStep,
      initialUploadedFile: data?.uploaded_file ?? '',
      custom_created: isCustomData,
      uploaded_file: [],
      file_path: data?.file_path ?? '',
      name: data?.name ?? '',
      mapping: data?.mapping,
    };
  }, [data, currentActiveStep, isCustomData]);

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(mappingFormSchema),
    defaultValues,
  });

  const closeModalHandler = () => {
    form.reset();
    toggleModal(!isOpen);
    setCurrentActiveStep(0);
    setCompletedSteps([]);
  };

  const submitFormHandler = (formData: TFormValues) => {
    if ((completedSteps.includes(currentActiveStep) || data?.uploaded_file) && currentActiveStep !== 1) {
      setCurrentActiveStep((previous) => previous + 1);
      form.setValue('currentStep', currentActiveStep + 1, { shouldValidate: true });
      return;
    }
    if (
      currentActiveStep === 0
      && districtId
      && id
      && formData.uploaded_file
      && formData.uploaded_file.length > 0
    ) {
      updateMappingFile(
        {
          uploaded_file: formData.uploaded_file,
          districtId,
          mappingId: id,
        },
        {
          onSuccess: () => {
            setCurrentActiveStep((previous) => previous + 1);
            setCompletedSteps([...completedSteps, currentActiveStep]);
            form.setValue('currentStep', currentActiveStep + 1, { shouldValidate: true });
            showSuccessToast('System Message', 'File uploaded successfully');
            queryClient.invalidateQueries({
              queryKey: [
                DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_DETAILS,
                districtId.toString(),
                id.toString(),
              ],
            });
          },
        },
      );
    }
    if (currentActiveStep === 1 && districtId && id && formData.mapping) {
      updateMappingColumns(
        {
          districtId,
          mappingId: id,
          mapping: formData.mapping.map((item) => ({
            name: item.name && item.name.length > 0 ? item.name : transformToSnakeCase(item.display_name ?? ''),
            display_name: item.display_name ?? '',
            value: item.value ?? '',
            is_mapped: item.is_mapped ?? false,
            is_required: item.is_required ?? false,
            is_custom: item.is_custom ?? false,
          })),
          file_path: formData.file_path ?? '',
          ...(isCustomData && { name: formData.name }),
        },
        {
          onSuccess: () => {
            closeModalHandler();
            showSuccessToast('System Message', 'Columns updated successfully');
            queryClient.invalidateQueries({
              queryKey: [
                DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_DETAILS,
                districtId.toString(),
                id.toString(),
              ],
            });
            queryClient.invalidateQueries({
              queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, districtId.toString()],
            });
            queryClient.invalidateQueries({
              queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL],
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>{isCustomData ? 'Create Custom Data' : `${modalMethod} ${name}`}</DialogTitle>
            <DialogDescription className="sr-only">
              Modal window for providing mapping information for
              {' '}
              {name}
            </DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="flex w-full flex-col items-center gap-6 p-6">
            {isContentLoading && !data && (
              <div className="flex h-56 w-full items-center justify-center">
                <PurpleIcon name="loader" className="size-9 animate-spin" />
              </div>
            )}

            {data && !isContentLoading && !isError && (
              <Form providerProps={form} id={formId} className="w-full" onSubmit={form.handleSubmit(submitFormHandler)}>
                {currentActiveStep === 0 && (
                  <UploadMappingFileForm
                    form={form}
                    currentStep={currentActiveStep}
                    maxSteps={MAX_STEPS}
                    initialUploadedFile={data.uploaded_file}
                    districtId={districtId?.toString() ?? ''}
                    mappingId={data.id.toString()}
                  />
                )}
                {currentActiveStep === 1 && (
                  <ColumMappingForm
                    form={form}
                    mappingColumns={data.current_columns}
                    isCustomData={data.mapping_type === 'custom'}
                    currentStep={currentActiveStep}
                    maxSteps={MAX_STEPS}
                  />
                )}
              </Form>
            )}
            {isError && !isContentLoading && (
              <div className="flex h-56 w-full flex-col items-center justify-center gap-3">
                <PurpleIcon name="exclamation-circle" className="size-12 text-error-main" />
                <div className="flex flex-col gap-1">
                  <Text variant="size-16" type="body-600" className="text-center text-grey-title">
                    Ooops! Something went wrong
                  </Text>
                  <Text variant="size-14" type="body-400" className="text-center text-grey-600">
                    Some unexpected error occurred, please visit this page latter.
                  </Text>
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
        <Separator />
        <DialogFooter>
          {currentActiveStep !== 0 && (
            <Button
              variant="secondary"
              onClick={() => {
                setCurrentActiveStep((previous) => previous - 1);
                form.setValue('currentStep', currentActiveStep - 1, { shouldValidate: true });
              }}
            >
              Previous Step
            </Button>
          )}
          <Button type="submit" isLoading={isFileUpdating || isColumnsUpdating} form={formId}>
            {currentActiveStep === 1 ? 'Import' : 'Next'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
