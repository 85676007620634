import axiosInstance from '../axios-config';
import { SCHOOLS_ENDPOINTS } from '../endpoints';
import type { TSchoolListRequestParameters, TSchoolListResponse } from './schools.types';

export const getSchoolsList = async (parameters?: TSchoolListRequestParameters) => {
  const response = await axiosInstance.get<TSchoolListResponse>(SCHOOLS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
