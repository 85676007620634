import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { MATCH_TEXT_WITH_HYPHEN_UNDERSCORE } from '@purple/shared-utils';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Separator,
  Switch,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { useCreateCustomPage, useUpdateCustomPage } from '~/services';
import type React from 'react';
import type { TDistrictCustomPage } from '@purple/shared-types';
import type { ModalType } from '~/constants';

const customPageSchema = z
  .object({
    name: z.string().trim().min(1, 'Name is required').max(255, 'Name is too long'),
    path: z
      .string()
      .trim()
      .regex(MATCH_TEXT_WITH_HYPHEN_UNDERSCORE, {
        message: 'Invalid format. Only latin letters, hyphens, and underscores are allowed.',
      })
      .min(1, 'Path is required'),
    embeded_code: z.string().trim().uuid('Code should be a valid UUID').min(1, 'Embeded code is required'),
    is_open_new_tab: z.boolean(),
    existing_paths: z.array(z.string()),
    existing_names: z.array(z.string()),
  })
  .superRefine((data, context) => {
    if (data.existing_paths.map((path) => path.trim().toLowerCase()).includes(data.path.trim().toLowerCase())) {
      context.addIssue({
        path: ['path'],
        code: z.ZodIssueCode.custom,
        message: 'Page with this path already exists.',
      });
    }
    if (data.existing_names.map((name) => name.trim().toLowerCase()).includes(data.name.trim().toLowerCase())) {
      context.addIssue({
        path: ['name'],
        code: z.ZodIssueCode.custom,
        message: 'Page with this name already exists.',
      });
    }
  });

const DEFAULT_EXISTING_PATHS: string[] = [];
const DEFAULT_EXISTING_NAMES: string[] = [];

type TDistrictNavigationPageDialogProperties = {
  districtId: number;
  existingPaths?: string[];
  existingNames?: string[];
  modalName: typeof ModalType.CREATE_DISTRICT_CUSTOM_PAGE | typeof ModalType.EDIT_DISTRICT_CUSTOM_PAGE;
  customPage?: TDistrictCustomPage | null;
};

export const DistrictNavigationPageDialog: React.FC<TDistrictNavigationPageDialogProperties> = (props) => {
  const { districtId, modalName, existingPaths = DEFAULT_EXISTING_PATHS, existingNames = DEFAULT_EXISTING_NAMES, customPage = null } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(modalName);

  const { mutate: createCustomPage, isPending: isCreatePending } = useCreateCustomPage();
  const { mutate: updateCustomPage, isPending: isUpdatePending } = useUpdateCustomPage();
  const isLoading = useMemo(() => isCreatePending || isUpdatePending, [isCreatePending, isUpdatePending]);

  const defaultValues = useMemo(
    () => ({
      name: customPage?.name || '',
      path: customPage?.path || '',
      embeded_code: customPage?.embeded_code || '',
      is_open_new_tab: customPage?.is_open_new_tab || false,
      existing_paths: existingPaths,
      existing_names: existingNames,
    }),
    [customPage, existingPaths, existingNames],
  );

  const form = useForm<z.infer<typeof customPageSchema>>({
    resolver: zodResolver(customPageSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
  };

  const submitCustomPageHandler = (data: z.infer<typeof customPageSchema>) => {
    const { existing_paths: _existingPaths, existing_names: _existingNames, ...formPageData } = data;
    if (customPage === null) {
      createCustomPage(
        {
          district: districtId,
          ...formPageData,
        },
        {
          onSuccess: successCallback,
        },
      );
      return;
    }

    updateCustomPage(
      {
        id: customPage.id,
        ...formPageData,
      },
      {
        onSuccess: successCallback,
      },
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Add New Page</DialogTitle>
          <DialogDescription className="sr-only">
            Add new page to the navigation menu of the district.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitCustomPageHandler)}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Page Name</FormLabel>
                <FormControl>
                  <Input {...field} isError={!!form.formState.errors.name} placeholder="Enter name here" type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="path"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Path for Page</FormLabel>
                <FormControl>
                  <Input {...field} isError={!!form.formState.errors.path} placeholder="Enter path here" type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="embeded_code"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Superset Embeded Code</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    isError={!!form.formState.errors.embeded_code}
                    placeholder="Enter code here"
                    type="text"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="is_open_new_tab"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center gap-4 space-y-0">
                <FormControl>
                  <Switch checked={field.value} onCheckedChange={field.onChange} className="cursor-pointer" />
                </FormControl>
                <FormLabel className={cn('font-normal text-grey-400', { 'text-grey-950': field.value })}>Open in new tab</FormLabel>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isLoading}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
