import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getPublishedDistricts } from './get-published-districts';
import type { AxiosError } from 'axios';
import type { TDistrictsListQueryParameters, TPublishedDistrictsResponse } from './districts.types';

export const usePublishedDistricts = (parameters?: TDistrictsListQueryParameters) => {
  const { isError, error, ...queryData } = useQuery<TPublishedDistrictsResponse, AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.PUBLISHED_DISTRICTS, parameters],
    queryFn: () => getPublishedDistricts(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('Failed to fetch published districts data', 'Please try again later');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
