import { useFormContext } from 'react-hook-form';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, Heading, Input, Text, Textarea } from '@purple/ui';
import type { FC } from 'react';
import type { TGroupPermissionBase } from '../../schema';

export const GroupInformationForm: FC = () => {
  const { control } = useFormContext<TGroupPermissionBase>();
  return (
    <div className="flex flex-1 flex-col gap-4 p-6">
      <div className="flex flex-col gap-1">
        <Heading tag="h2" type="heading-500" variant="size-18">
          Group Information
        </Heading>
        <Text variant="size-14" className="text-grey-950">
          Please provide all information relevant to the new Group.
        </Text>
      </div>
      <FormField
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>Group Name</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter group name" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="description"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel>Description</FormLabel>
            <FormControl>
              <Textarea
                {...field}
                isError={!!fieldState.error}
                placeholder="Describe the purpose of this group"
                className="max-h-[120px] min-h-[80px] resize-none"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
