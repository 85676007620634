import { Link } from 'react-router-dom';
import { Button, Heading, Text } from '@purple/ui';
import { AdminRoutes, VisualizerStep } from '~/constants';
import type { TStudentVisualizerSetupState } from '~/services';

type TVisualizerSetupListProps = {
  setupState: TStudentVisualizerSetupState;
};

export const VisualizerSetupList: React.FC<TVisualizerSetupListProps> = (props) => {
  const { setupState } = props;

  return (
    <ul className="flex w-full flex-col gap-4">
      <li className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex flex-col gap-1">
          <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">Data Table View</Heading>
          <Text type="body-400" variant="size-14" className="text-grey-600">
            Select available data points you would like to see on the color trends visualizer in their system
          </Text>
        </div>
        <Button asChild variant="secondary" className="min-w-32">
          <Link to={AdminRoutes.App.Visualizer.StudentSetUp.makePath({ queries: { districtId: setupState.id, step: VisualizerStep.DATA_TABLE_VIEW } })}>
            {setupState.is_data_table_view_configured ? 'Update' : 'Setup'}
          </Link>
        </Button>
      </li>
      <li className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex flex-col gap-1">
          <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">Subpopulation Filters</Heading>
          <Text type="body-400" variant="size-14" className="text-grey-600">
            Select display options for filters, which is based on student demographic file provided by district SIS
          </Text>
        </div>
        <Button asChild variant="secondary" className="min-w-32">
          <Link to={AdminRoutes.App.Visualizer.StudentSetUp.makePath({ queries: { districtId: setupState.id, step: VisualizerStep.SUBPOPULATION_FILTERS } })}>
            {setupState.is_subpopulation_filters_configured ? 'Update' : 'Setup'}
          </Link>
        </Button>
      </li>
      <li className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex flex-col gap-1">
          <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">Absences Visualizer</Heading>
          <Text type="body-400" variant="size-14" className="text-grey-600">
            Enter the absences threshold for each month for the district. This threshold determines when the visual cue will be applied
          </Text>
        </div>
        <Button asChild variant="secondary" className="min-w-32">
          <Link to={AdminRoutes.App.Visualizer.StudentSetUp.makePath({ queries: { districtId: setupState.id, step: VisualizerStep.ABSENCES_VISUALIZER } })}>
            {setupState.is_absences_visualizer_configured ? 'Update' : 'Setup'}
          </Link>
        </Button>
      </li>
      <li className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex flex-col gap-1">
          <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">Color Trends Pull Dates</Heading>
          <Text type="body-400" variant="size-14" className="text-grey-600">
            Enter the flag pull dates for the current academic year
          </Text>
        </div>
        <Button asChild variant="secondary" className="min-w-32">
          <Link to={AdminRoutes.App.Visualizer.StudentSetUp.makePath({ queries: { districtId: setupState.id, step: VisualizerStep.COLOR_TRENDS_PULL_DATES } })}>
            {setupState.is_color_trends_pull_dates_configured ? 'Update' : 'Setup'}
          </Link>
        </Button>
      </li>
      <li className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex flex-col gap-1">
          <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">Color Trends</Heading>
          <Text type="body-400" variant="size-14" className="text-grey-600">
            Configure color trends flag indicators including designated baseline metric
          </Text>
        </div>
        <Button asChild variant="secondary" className="min-w-32">
          <Link to={AdminRoutes.App.Visualizer.StudentSetUp.makePath({ queries: { districtId: setupState.id, step: VisualizerStep.COLOR_TRENDS } })}>
            {setupState.is_color_trends_configured ? 'Update' : 'Setup'}
          </Link>
        </Button>
      </li>
    </ul>
  );
};
