import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TActionChoicesResponse } from '@purple/shared-types';
import type { TActionChoicesPayload } from './actions.types';

export const getActionChoices = async ({ record_action_type, students, actionId, ...params }: TActionChoicesPayload) => {
  const response = await axiosInstance.post<TActionChoicesResponse>(ACTIONS_ENDPOINTS.ACTION_CHOICES(actionId), {
    record_action_type,
    students,
  }, {
    params,
  });
  return response.data;
};
