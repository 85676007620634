import { useQueryClient } from '@tanstack/react-query';
import { Tabs, TabsContent } from '@purple/ui';
import { useManageRolesTabs } from '~/pages';
import { PERMISSIONS_QUERY_KEYS } from '~/services';
import { ManageRolesFormSideBar } from '../../../Roles/components';
import { offsetHeight } from '../../constants';
import { GroupStepContainer } from '../GroupStepContainer';
import type { TPermissionStructure } from '~/services';

export const GroupPermissionForm = () => {
  const queryClient = useQueryClient();

  const permissionStructure = queryClient.getQueryData<TPermissionStructure>([PERMISSIONS_QUERY_KEYS.GET_PERMISSIONS_STRUCTURE]);
  const { tabs } = useManageRolesTabs({ permissionStructure });

  return (
    <Tabs className="flex" defaultValue={tabs[0]?.value}>
      <ManageRolesFormSideBar className="rounded-none border-y-0 border-l-0" />
      <GroupStepContainer offsetHeight={offsetHeight}>
        {tabs.map((tab) => (
          <TabsContent key={tab.value} value={tab.value}>
            {tab.content}
          </TabsContent>
        ))}
      </GroupStepContainer>
    </Tabs>
  );
};
