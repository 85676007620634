import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { MultiSelect, MultiSelectItem } from '@purple/ui';
import { useUserSchools } from '~/services';

const LIMIT = 5;

type TSchoolsFilterComboBoxProperties = {
  /*
   * The name of the property that the combobox is filtering.
  */
  districtId?: string;
  /*
   * Whether the district is required.
  */
  isDistrictRequired?: boolean;
  /**
   * The selected value of the combobox.
   */
  values: string[];
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string[]) => void;
  /*
  * Callback that is called when the combobox is cleared
  */
  onClear: () => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
};

const SchoolsFilterComboBox = (props: TSchoolsFilterComboBoxProperties) => {
  const { values, onChange, placeholder = 'Select school(s)', districtId, isDistrictRequired = false } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: schoolsData, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      district: districtId,
      search: debouncedSearchValue,
      limit: LIMIT,
    },
    enabled: isDistrictRequired && Boolean(districtId),
  });

  const schoolsSelectOptions = useMemo(() => {
    return (
      schoolsData?.results.map((school) => ({
        ...school,
        label: school.name,
        value: school.id,
      })) ?? []
    );
  }, [schoolsData]);

  const selectValueHandler = useCallback(
    (newValue: string[]) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <MultiSelect
      selectedOptions={schoolsSelectOptions.filter((item) => values.includes(item.value))}
      modalPopover
      placeholder={placeholder}
      showSearch
      shouldFilter={false}
      disabled={isDistrictRequired && !districtId}
      loading={isSchoolsLoading}
      onSearchChange={debouncedSearch}
      onOptionChange={selectValueHandler}
      maxCount={1}
    >
      {schoolsSelectOptions.map((school) => (
        <MultiSelectItem
          key={school.value}
          value={school.value}
          option={school}
          isSelected={values.includes(school.value)}
        />
      ))}
    </MultiSelect>
  );
};

export { SchoolsFilterComboBox };
