import { forwardRef, useMemo, useState } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { PurpleIcon } from '@purple/icons';
import { cn } from '@purple/ui';
import { getLeftPadding, getTargetItemDepthLevel, ReadableHierarchyNodeType } from './helpers';
import { HierarchyItem } from './HierarchyItem';
import { HierarchyNodeActions } from './HierarchyNodeActions';
import { HierarchyNodeIcon } from './HierarchyNodeIcon';
import { treeLineVariants, treeVariants } from './variants';
import type { PurpleIconType } from '@purple/icons';
import type { THierarchyBranch, THierarchyBranchOption, THierarchyNode, THierarchyNodeId, THierarchyUpdateNode } from './types';

const AccordionTrigger = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 w-full items-center py-1 transition-all first:[&[data-state=open]>svg[data-name=indicator]]:rotate-90',
        className,
      )}
      {...props}
    >
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      'overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className,
    )}
    {...props}
  >
    <div className="pb-1 pt-0">{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

type THierarchyNodeProps = {
  item: THierarchyNode;
  nodeOptions?: THierarchyBranchOption[];
  expandedItemIds: THierarchyNodeId[];
  selectedItemId?: THierarchyNodeId;
  isLast?: boolean;
  isTopLevel?: boolean;
  disableCollapse?: boolean;
  rawData: THierarchyNode[];
  defaultNodeIcon?: PurpleIconType;
  defaultLeafIcon?: PurpleIconType;
  onSelectChange?: (item?: THierarchyNode) => void;
  onNodeAdd?: (item: THierarchyBranch) => void;
  onNodeDelete?: (nodeId: THierarchyNodeId) => void;
  onNodeUpdate?: (node: THierarchyUpdateNode) => void;
};

export const HierarchyNode: React.FC<THierarchyNodeProps> = (props) => {
  const {
    item,
    rawData,
    nodeOptions,
    expandedItemIds,
    selectedItemId,
    isLast = false,
    isTopLevel = false,
    disableCollapse = false,
    defaultNodeIcon,
    defaultLeafIcon,
    onSelectChange,
    onNodeAdd,
    onNodeDelete,
    onNodeUpdate,
  } = props;

  const [value, setValue] = useState<string[]>(
    expandedItemIds.includes(item.id) ? [item.id.toString()] : [],
  );
  const level = useMemo(() => getTargetItemDepthLevel(rawData, item.id), [rawData, item.id]);

  return (
    <AccordionPrimitive.Root
      type="multiple"
      disabled={disableCollapse}
      value={value}
      onValueChange={(s) => !disableCollapse && setValue(s)}
    >
      <AccordionPrimitive.Item value={item.id.toString()}>
        <AccordionTrigger asChild>
          <div
            className={cn(
              treeVariants({ selected: selectedItemId === item.id }),
              treeLineVariants({ last: isLast, hidden: isTopLevel, first: level === 1 }),
            )}
            onClick={() => {
              onSelectChange?.(item);
              item.onClick?.();
            }}
            style={getLeftPadding(level, isTopLevel)}
          >
            {!disableCollapse && (
              <PurpleIcon
                name="chevron-right"
                data-name="indicator"
                className="size-4 shrink-0 text-grey-800 transition-transform duration-200"
              />
            )}
            <HierarchyNodeIcon
              item={item}
              isSelected={selectedItemId === item.id}
              isOpen={value.includes(item.id.toString())}
              default={defaultNodeIcon}
            />
            <p className="inline-flex select-none flex-col gap-0.5">
              <span className="grow truncate text-[10px] font-normal uppercase leading-[12px] text-grey-700">
                {ReadableHierarchyNodeType[item.type]}
              </span>
              <strong className="grow truncate text-xs font-medium text-grey-950">{item.name}</strong>
            </p>
            {!item.disableActions && (
              <HierarchyNodeActions id={item.id} isSelected={selectedItemId === item.id} />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent
          className={cn('relative', {
            'after:absolute after:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width)-var(--hierarchy-line-gap))] after:top-0 after:h-full after:w-3 after:border-l after:border-brand-blue-200 after:-translate-x-1/2': !isTopLevel && !isLast,
            'after:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width))]': level === 1,
          })}
          style={getLeftPadding(level, isTopLevel)}
        >
          <HierarchyItem
            data={item.children ? item.children : item}
            rawData={rawData}
            selectedItemId={selectedItemId}
            expandedItemIds={expandedItemIds}
            defaultLeafIcon={defaultLeafIcon}
            defaultNodeIcon={defaultNodeIcon}
            disableCollapse={disableCollapse}
            nodeOptions={nodeOptions}
            onSelectChange={onSelectChange}
            onNodeAdd={onNodeAdd}
            onNodeDelete={onNodeDelete}
            onNodeUpdate={onNodeUpdate}
          />
        </AccordionContent>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
};
