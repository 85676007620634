import { ScrollArea } from '@purple/ui';

type TVisualizerStepContainerProps = React.PropsWithChildren<{
  offsetHeight?: number | null;
}>;

export const VisualizerStepContainer: React.FC<TVisualizerStepContainerProps> = ({ offsetHeight = null, children }) => {
  return (
    <div
      className="flex w-full flex-1 flex-col"
      style={{
        maxHeight: typeof offsetHeight === 'number' ? `calc(100vh - ${offsetHeight}px)` : 'unset',
      }}
    >
      <ScrollArea type="auto" className="flex w-full flex-1 flex-col p-0" viewportClassName="[&>div]:h-full" scrollBarClassName="p-2 w-[22px]">
        <div className="size-full px-6 py-4 pr-[30px]">
          {children}
        </div>
      </ScrollArea>
    </div>
  );
};
