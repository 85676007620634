import { PurpleIcon } from '@purple/icons';
import { Skeleton } from '@purple/ui';

export const DistrictInfoSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex size-[60px] shrink-0 items-center justify-center rounded-lg bg-grey-100">
        <PurpleIcon name="School" className="size-6 text-brand-blue-700" />
      </div>
      <div className="flex flex-col gap-4">
        <Skeleton className="h-6 w-[100px]" />
        <div className="flex flex-col items-start gap-6 md:flex-row">
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[42px]" />
            <Skeleton className="h-7 w-[86px] rounded-full" />
          </div>
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[82px]" />
            <Skeleton className="h-7 w-[80px] rounded-md" />
          </div>
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[104px]" />
            <div className="flex flex-col gap-0.5">
              <Skeleton className="h-5 w-[80px]" />
              <Skeleton className="h-5 w-[119px]" />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[94px]" />
            <div className="flex flex-col gap-0.5">
              <Skeleton className="h-5 w-[80px]" />
              <Skeleton className="h-5 w-[119px]" />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[69px]" />
            <div className="flex flex-col gap-0.5">
              <Skeleton className="h-5 w-[80px]" />
              <Skeleton className="h-5 w-[119px]" />
            </div>
          </div>
        </div>
      </div>
      <Skeleton className="ml-auto size-10 shrink-0" />
    </div>
  );
};
