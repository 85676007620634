import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TUpdateDistrictDetailsPayload, TUpdateDistrictDetailsResponse } from './districts.types';

export const updateDistrictBasicDetails = async ({ id, ...payload }: TUpdateDistrictDetailsPayload) => {
  const response = await axiosInstance.patch<TUpdateDistrictDetailsResponse>(
    DISTRICTS_ENDPOINTS.BASIC_DETAILS(id),
    payload,
  );
  return response.data;
};
