import { Skeleton } from '@purple/ui';

export const DistrictCustomPagesSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <Skeleton className="h-6 w-14" />
      <div className="flex min-h-[116px] w-full flex-col items-start justify-between gap-4 rounded-lg border border-grey-100 p-4 sm:flex-row sm:items-center">
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <Skeleton className="h-5 w-2" />
          <Skeleton className="size-6" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-[18px] w-24" />
            <Skeleton className="h-5 w-[320px]" />
            <Skeleton className="h-7 w-16 rounded-full" />
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Skeleton className="h-6 w-11 rounded-full" />
          <Skeleton className="size-8" />
          <Skeleton className="size-8" />
        </div>
      </div>
      <div className="flex min-h-[116px] w-full flex-col items-start justify-between gap-4 rounded-lg border border-grey-100 p-4 sm:flex-row sm:items-center">
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <Skeleton className="h-5 w-2" />
          <Skeleton className="size-6" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-[18px] w-24" />
            <Skeleton className="h-5 w-[320px]" />
            <Skeleton className="h-7 w-16 rounded-full" />
          </div>
        </div>

        <div className="flex items-center gap-4">
          <Skeleton className="h-6 w-11 rounded-full" />
          <Skeleton className="size-8" />
          <Skeleton className="size-8" />
        </div>
      </div>
    </div>
  );
};
