export type TDefaultDragAndDropData = {
  id: string | number;
  order_number?: number;
};

/**
 * The drag-and-drop utility function mutates the order of an array of table data based on input parameters.
 * @param previousData array of current data.
 * @param newPosition new position of the updated array item.
 * @param oldPosition old position of the updated array item.
 * @returns reordered array of data.
 */
export const updateDataOrder = <TData extends TDefaultDragAndDropData = TDefaultDragAndDropData>(
  previousData: TData[],
  newPosition: number,
  oldPosition: number,
): TData[] => {
  // Sort the data by order_number in case it is not already sorted
  const sortedData = [...previousData].sort((a, b) => (a.order_number || 0) - (b.order_number || 0));

  // Remove the item from the old position
  const [updatedItem] = sortedData.splice(oldPosition, 1);

  if (!updatedItem) {
    return sortedData; // Return original data if item is not found
  }

  // Insert the item into the new position
  sortedData.splice(newPosition, 0, updatedItem);

  // Recalculate the order_number based on the new order
  return sortedData.map((item, index) => ({
    ...item,
    order_number: index + 1,
  }));
};
