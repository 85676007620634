import { useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { DistrictUserStatus } from '@purple/shared-types';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { useUserDeactivateCheck } from '~/services';
import type { TUserManagement, TUsersDeactivateCheck } from '~/services';

type TListItemOptionsProperties = {
  user: TUserManagement;
  onRemove: () => void;
  onDeactivate: (checkResponse: TUsersDeactivateCheck) => void;
  onActivate: () => void;
};

const ListItemOptions = ({
  user,
  onRemove,
  onDeactivate,
  onActivate,
}: TListItemOptionsProperties) => {
  const { id, status } = user;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isDeactivateChecking, setIsDeactivateChecking] = useState(false);

  const { mutate: checkUsersDeactivation } = useUserDeactivateCheck();

  const deactivateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDeactivateChecking(true);

    checkUsersDeactivation({ usersIds: [id] }, {
      onSuccess: (response) => {
        setIsDropdownOpen(false);
        onDeactivate(response);
        setIsDeactivateChecking(false);
      },
      onError: () => {
        setIsDeactivateChecking(false);
        setIsDropdownOpen(false);
      },
    });
  };

  const removeClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    setIsDropdownOpen(false);
    onRemove();
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger asChild>
        <Button
          iconLeft={<PurpleIcon name="dots-vertical" />}
          variant="tertiary_icon_only"
          size="icon_40"
        />
      </DropdownTrigger>
      <DropdownContent className="max-w-[196px] gap-1" align="end" sideOffset={-2}>
        {status !== DistrictUserStatus.INACTIVE && (
          <Button
            variant="link_destructive"
            onClick={deactivateClickHandler}
            className="w-full justify-start"
            iconLeft={<PurpleIcon name="X" />}
            isLoading={isDeactivateChecking}
          >
            Deactivate
          </Button>
        )}
        {status === DistrictUserStatus.INACTIVE && (
          <Button
            variant="link"
            onClick={onActivate}
            className="w-full justify-start text-grey-700 hover:text-grey-700 active:text-grey-700"
            iconLeft={<PurpleIcon name="check" />}
          >
            Activate
          </Button>
        )}
        <Guard requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_ADMIN_DELETE_USERS]}>
          <Button
            variant="link_destructive"
            onClick={removeClickHandler}
            className="w-full justify-start"
            iconLeft={<PurpleIcon name="trash" />}
          >
            Delete
          </Button>
        </Guard>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { ListItemOptions };
