import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AdminRoutes } from '~/constants';
import { useCurrentUser } from '~/hooks';

export const AuthLayout: React.FC = () => {
  const location = useLocation();

  const { isAuthenticated, isLoading, user } = useCurrentUser();

  // Prevent logged in user from accessing login page
  // We are checking for id because the user object is not null when the user is authenticated
  if (isAuthenticated && !isLoading && user.id) {
    return <Navigate to={AdminRoutes.App.Home.Root.path} replace state={{ from: location }} />;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center bg-grey-50">
      <Outlet />
    </div>
  );
};
