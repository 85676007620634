import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { createGroup } from './create-group';
import { GROUPS_QUERY_KEYS } from './groups.const';
import type { AxiosError } from 'axios';
import type { TCreateGroupPayload } from './groups.types';

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<void, AxiosError, TCreateGroupPayload>({
    mutationKey: [GROUPS_QUERY_KEYS.CREATE_GROUP],
    mutationFn: createGroup,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to create Permission Group. Check provided information and try again.');
      }
    },
    onSuccess: () => {
      showSuccessToast('System message', 'Permission Group created successfully');
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEYS.GET_GROUPS_LIST] });
      navigate(AdminRoutes.App.Users.Permissions.Root.path);
    },
  });
};
