export const VISUALIZER_QUERY_KEYS = {
  GET_STUDENT_VISUALIZER_SETUP_STATE: 'GET_STUDENT_VISUALIZER_SETUP_STATE',
  GET_VISUALIZER_ABSENCES_THRESHOLDS: 'GET_VISUALIZER_ABSENCES_THRESHOLDS',
  UPDATE_VISUALIZER_ABSENCES_THRESHOLDS: 'UPDATE_VISUALIZER_ABSENCES_THRESHOLDS',
  GET_COLOR_TRENDS_PULL_DATES: 'GET_COLOR_TRENDS_PULL_DATES',
  UPDATE_COLOR_TRENDS_PULL_DATES: 'UPDATE_COLOR_TRENDS_PULL_DATES',
  GET_SUBPOPULATION_FILTERS: 'GET_SUBPOPULATION_FILTERS',
  UPDATE_SUBPOPULATION_FILTERS: 'UPDATE_SUBPOPULATION_FILTERS',
  GET_DATA_TABLE_VIEW: 'GET_DATA_TABLE_VIEW',
  UPDATE_DATA_TABLE_VIEW: 'UPDATE_DATA_TABLE_VIEW',
  UPDATE_DATA_TABLE_VIEW_ORDER: 'UPDATE_DATA_TABLE_VIEW_ORDER',
  // Flag calculations
  GET_LIST_OF_FLAG_CALCULATIONS: 'GET_LIST_OF_FLAG_CALCULATIONS',
  BULK_UPDATE_FLAG_CALCULATIONS: 'BULK_UPDATE_FLAG_CALCULATIONS',
  DELETE_FLAG_CALCULATION: 'DELETE_FLAG_CALCULATION',
  CREATE_FLAG_CALCULATION: 'CREATE_FLAG_CALCULATION',
  UPDATE_FLAG_CALCULATION: 'UPDATE_FLAG_CALCULATION',
} as const;
