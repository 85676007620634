import { useEffect, useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { updateDataOrder } from '@purple/shared-utils';
import { Heading } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useDeleteCustomPage, useDistrictCustomPages, useUpdateCustomPageOrder } from '~/services';
import { CustomPageItem } from './CustomPageItem';
import { CustomPageOverlay } from './CustomPageOverlay';
import { DistrictCustomPagesSkeleton } from './DistrictCustomPagesSkeleton';
import { DistrictNavigationPageDialog } from './DistrictNavigationPageDialog';
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import type React from 'react';
import type { TDistrictCustomPage } from '@purple/shared-types';
import type { TDistrictDetails } from '~/services';

type TDistrictCustomPagesProperties = {
  district: TDistrictDetails;
};

export const DistrictCustomPages: React.FC<TDistrictCustomPagesProperties> = (props) => {
  const { district } = props;

  const [dragActiveId, setDragActiveId] = useState<string | number | null>(null);
  const [editSelectedPage, setEditSelectedPage] = useState<TDistrictCustomPage | null>(null);
  const [deleteSelectedPage, setDeleteSelectedPage] = useState<TDistrictCustomPage | null>(null);
  const [innerData, setInnerData] = useState<TDistrictCustomPage[]>([]);

  const { openModal: openEditPageModal } = useModal(ModalType.EDIT_DISTRICT_CUSTOM_PAGE);
  const { openModal: openDeletePageModal, closeModal: closeDeleteModal } = useModal(
    ModalType.DELETE_DISTRICT_CUSTOM_PAGE,
  );

  const { mutate: updateOrder } = useUpdateCustomPageOrder();
  const { mutate: deletePage, isPending: isDeletePending } = useDeleteCustomPage();
  const { data, isFetching } = useDistrictCustomPages({ districtId: district.id });

  const customPages = useMemo(() => data?.results || [], [data]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  useEffect(() => {
    setInnerData(customPages);
  }, [customPages]);

  const itemDragStartHandler = (event: DragStartEvent) => {
    setDragActiveId(event.active.id);
  };

  const itemDragEndHandler = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const targetItem = innerData.find((item) => item.id === active.id);
      const oldPosition = innerData.findIndex((item) => item.id === active.id);
      const newPosition = innerData.findIndex((item) => item.id === over.id);
      setInnerData((previousData) =>
        updateDataOrder<TDistrictCustomPage>(previousData, newPosition, oldPosition),
      );
      if (targetItem) {
        updateOrder({ id: targetItem.id, new_order_number: newPosition + 1 });
      }
    }
    setDragActiveId(null);
  };

  const itemDragCancelHandler = () => {
    setDragActiveId(null);
  };

  const editPageClickHandler = (page: TDistrictCustomPage) => {
    setEditSelectedPage(page);
    openEditPageModal();
  };

  const deletePageClickHandler = (page: TDistrictCustomPage) => {
    setDeleteSelectedPage(page);
    openDeletePageModal();
  };

  const deleteDistrictPageHandler = () => {
    if (deleteSelectedPage) {
      deletePage(deleteSelectedPage.id, {
        onSuccess: () => {
          setInnerData(innerData.filter((item) => item.id !== deleteSelectedPage.id));
          closeDeleteModal();
        },
      });
    }
  };

  if (isFetching) {
    return <DistrictCustomPagesSkeleton />;
  }

  if (customPages.length === 0) {
    return null;
  }

  return (
    <section className="flex w-full flex-col gap-4">
      <Heading tag="h3" type="heading-500" variant="size-18" className="text-base text-grey-title">
        Pages
      </Heading>
      <ul className="flex w-full flex-col gap-4">
        <DndContext
          sensors={sensors}
          onDragEnd={itemDragEndHandler}
          onDragStart={itemDragStartHandler}
          onDragCancel={itemDragCancelHandler}
          collisionDetection={closestCenter}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext items={innerData} strategy={verticalListSortingStrategy}>
            {innerData.map((item, index) => (
              <CustomPageItem
                key={item.id}
                data={item}
                order={index + 1}
                onEdit={editPageClickHandler}
                onDelete={deletePageClickHandler}
              />
            ))}
          </SortableContext>
          <CustomPageOverlay dragActiveId={dragActiveId} data={innerData} />
        </DndContext>
      </ul>
      <DistrictNavigationPageDialog
        districtId={district.id}
        existingPaths={innerData.filter((item) => item.id !== editSelectedPage?.id).map((item) => item.path)}
        existingNames={innerData.filter((item) => item.id !== editSelectedPage?.id).map((item) => item.name)}
        modalName={ModalType.EDIT_DISTRICT_CUSTOM_PAGE}
        customPage={editSelectedPage}
      />
      <CallToActionModal
        modalName={ModalType.DELETE_DISTRICT_CUSTOM_PAGE}
        modalTitle="Delete custom page"
        modalDescription="By deleting this custom page, you will remove it from the navigation menu of the district."
        modalTextContent="Are you sure you want to delete this page?"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteDistrictPageHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
    </section>
  );
};
