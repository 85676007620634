import { Separator, Skeleton } from '@purple/ui';

export const DistrictBasicDetailsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[112px]" />
      </div>
      <div className="flex w-full flex-col gap-6 px-6 py-8 lg:flex-row">
        <div className="flex w-full flex-col gap-6">
          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[120px]" />
                <Skeleton className="h-6 w-[96px]" />
              </div>
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[66px]" />
                <Skeleton className="h-6 w-[102px]" />
              </div>
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[160px]" />
                <Skeleton className="h-6 w-[110px]" />
              </div>
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[190px]" />
                <Skeleton className="h-6 w-[120px]" />
              </div>
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[100px]" />
                <div className="flex flex-col gap-0.5">
                  <Skeleton className="h-6 w-[110px]" />
                  <Skeleton className="h-6 w-[60px]" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col gap-4">
            <div className="flex h-8 flex-row items-center justify-between gap-2">
              <Skeleton className="h-[24px] w-[98px]" />
              <Skeleton className="h-[24px] w-[54px]" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <div className="flex w-full items-center justify-between gap-2">
                <Skeleton className="h-6 w-[144px]" />
                <div className="flex flex-col gap-0.5">
                  <Skeleton className="h-6 w-[140px]" />
                  <Skeleton className="h-6 w-[138px]" />
                  <Skeleton className="h-6 w-[142px]" />
                  <Skeleton className="h-6 w-[140px]" />
                  <Skeleton className="h-6 w-[136px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
        <div className="flex w-full flex-col gap-4">
          <div className="flex h-8 flex-row items-center justify-between gap-2">
            <Skeleton className="h-[24px] w-[98px]" />
            <Skeleton className="h-[24px] w-[54px]" />
          </div>
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-2">
              <Skeleton className="h-6 w-[164px]" />
              <div className="flex flex-col gap-0.5">
                <Skeleton className="h-6 w-[160px]" />
                <Skeleton className="h-6 w-[148px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
