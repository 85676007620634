export const constructNavigationUrl = (
  baseUrl: string,
  parameters?: Record<string, string | number | boolean | undefined | null>,
): string => {
  const urlSearchParameters = new URLSearchParams();
  if (!parameters) {
    return baseUrl;
  }

  for (const [key, value] of Object.entries(parameters)) {
    if (value !== undefined && value !== null) {
      urlSearchParameters.append(key, value.toString());
    }
  }

  return `${baseUrl}?${urlSearchParameters.toString()}`;
};
