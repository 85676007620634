import { forwardRef, useState } from 'react';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { cn, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Text } from '@purple/ui';
import { getLeftPadding, ReadableHierarchyNodeType } from './helpers';
import { treeLineVariants, treeVariants } from './variants';
import type { THierarchyBranchOption } from './types';

type TAddHierarchyNodeProps = React.HTMLAttributes<HTMLButtonElement> & {
  level: number;
  isTopLevel?: boolean;
  options?: THierarchyBranchOption[];
  onOptionSelect?: (item: THierarchyBranchOption) => void;
  onAddClick?: () => void;
};

export const AddHierarchyNode = forwardRef<HTMLButtonElement, TAddHierarchyNodeProps>((props, ref) => {
  const {
    level,
    options,
    isTopLevel = false,
    className,
    onOptionSelect,
    onAddClick,
  } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const typeClickHandler = (option: THierarchyBranchOption) => {
    onOptionSelect?.(option);
    setIsDropdownOpen(false);
    setIsDropdownVisible(false);
  };

  const triggerClickHandler = () => {
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  const createNodeClickHandler = () => {
    onAddClick?.();
    setIsDropdownOpen(false);
    setIsDropdownVisible(false);
  };

  const addNodeButtonClickHandler = () => {
    if (options && options?.length > 0) {
      setIsDropdownVisible(true);
    } else {
      createNodeClickHandler();
    }
  };

  useKeyPress('Escape', () => {
    setIsDropdownOpen(false);
    setIsDropdownVisible(false);
  });

  return (
    <>
      {isDropdownVisible
        ? (
            <div
              className={cn(
                treeVariants(),
                treeLineVariants({ hidden: isTopLevel, first: level === 1 }),
                'w-full hover:bg-transparent focus:bg-transparent focus-visible:bg-transparent active:bg-transparent',
                className,
              )}
              style={getLeftPadding(level, isTopLevel)}
            >
              <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
                <DropdownTrigger
                  onClick={triggerClickHandler}
                  className={cn(
                    'group flex h-10 min-w-80 w-full items-center justify-between gap-2 rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 hover:border-brand-blue-700 hover:bg-transparent focus:border-brand-blue-700 focus:bg-transparent focus-visible:border-brand-blue-700 focus-visible:bg-transparent active:border-brand-blue-700 active:bg-transparent active:text-grey-950 disabled:pointer-events-none disabled:opacity-50 data-[state="open"]:border-brand-blue-700 data-[state="open"]:bg-transparent',
                  )}
                >
                  <span className="inline-block truncate text-sm font-normal text-grey-950">
                    Select Node
                  </span>
                  <PurpleIcon
                    name="chevron-down"
                    className={cn(
                      'h-5 w-5 shrink-0 text-grey-200 transition-all duration-200 group-hover:text-grey-950 group-focus:text-grey-950 group-focus-visible:text-grey-950',
                      {
                        'rotate-180 transform text-grey-950': isDropdownOpen,
                      },
                    )}
                  />
                </DropdownTrigger>
                <DropdownContent useTriggerWidth className="h-full max-h-64 overflow-y-auto">
                  {options?.map((option) => (
                    <DropdownItem
                      key={option.id}
                      onSelect={() => typeClickHandler(option)}
                      className='group relative flex cursor-default select-none flex-col items-start justify-center gap-px rounded-md py-2.5 pl-11 pr-2 font-primary text-sm font-semibold leading-5 text-brand-blue-700 outline-none transition-colors duration-300 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 data-[disabled=true]:pointer-events-none data-[disabled="true"]:opacity-50'
                    >
                      <Text
                        tag="span"
                        className="text-[10px] font-normal uppercase leading-[12px] text-grey-950 transition-colors duration-300 group-hover:text-brand-blue-700 group-focus:text-brand-blue-700 group-focus-visible:text-brand-blue-700"
                      >
                        {ReadableHierarchyNodeType[option.type]}
                      </Text>
                      <Text
                        tag="span"
                        className="text-sm font-medium text-grey-950 transition-colors duration-300 group-hover:text-brand-blue-700 group-focus:text-brand-blue-700 group-focus-visible:text-brand-blue-700"
                      >
                        {option.name}
                      </Text>
                    </DropdownItem>
                  ))}
                  <DropdownItem
                    iconName="plus"
                    className="w-full justify-center bg-brand-blue-700 text-white hover:bg-brand-blue-600 hover:text-white focus:bg-brand-blue-600 focus:text-white focus-visible:bg-brand-blue-600 focus-visible:text-white active:bg-brand-blue-800 active:text-white [&>svg]:text-white"
                    onSelect={createNodeClickHandler}
                  >
                    Create New Node
                  </DropdownItem>
                </DropdownContent>
              </DropdownRoot>
            </div>
          )
        : (
            <button
              ref={ref}
              type="button"
              className={cn(
                treeVariants(),
                treeLineVariants({ hidden: isTopLevel, first: level === 1 }),
                'w-full hover:bg-brand-blue-100 focus:bg-brand-blue-100 focus-visible:bg-brand-blue-100 active:bg-brand-blue-50',
                className,
              )}
              onClick={addNodeButtonClickHandler}
              style={getLeftPadding(level, isTopLevel)}
            >
              <PurpleIcon name="plus" className="size-4 shrink-0 text-brand-blue-700" />
              <span className="grow truncate text-sm font-medium text-brand-blue-700">
                Add Node
              </span>
            </button>
          )}
    </>
  );
});
AddHierarchyNode.displayName = 'AddHierarchyNode';
