import type { IBasePaginationRequestParameters, IPaginatedResponse, MakeOptional, Nullish, TCustomTag, TDistrictBusinessDay, TDistrictHoliday, TSchool, TSchoolAddress, ValueOf } from '@purple/shared-types';

export type TAdminUserSchool = Pick<TSchool, 'id' | 'name'> & {
  grades: string[];
  is_active: boolean;
  school_code: string;
  school_level: string[];
  address: TSchoolAddress | null;
  school_parent: string | null;
};

export type TAdminUserSchoolsResponse = IPaginatedResponse<TAdminUserSchool[]>;

export type TAdminUserSchoolsRequestParameters = IBasePaginationRequestParameters & {
  district?: string | null;
  user?: string | null;
  grades?: string | null;
  ordering?: string | null;
};

export const SchoolHierarchyNodeType = {
  STATE: 'state',
  REGION: 'region',
  FEEDER_PATTERN: 'feeder_pattern',
  CAMPUS: 'campus',
  CITY: 'city',
  DIVISION: 'division',
  SYSTEM: 'system',
} as const;
export type TSchoolHierarchyNodeType = ValueOf<typeof SchoolHierarchyNodeType>;

export type TSchoolHierarchyNode = {
  id: number;
  name: string;
  type: TSchoolHierarchyNodeType;
  child: TSchoolHierarchyNode | null;
};

export type TSchoolHierarchy = {
  /**
   * Name of the school that the hierarchy belongs to.
   */
  school: string;
  /**
   * District of the school that the hierarchy belongs to.
   */
  district: string;
  /**
   * Hierarchy structure in object like format.
   */
  nodes: TSchoolHierarchyNode | null;
};

export type TSchoolHierarchyChoice = Omit<TSchoolHierarchyNode, 'child'>;

export type TSchoolHierarchyChoiceParameters = {
  schoolId: number | string;
  node_types?: TSchoolHierarchyNodeType[];
};

export type TSchoolHierarchyChoiceList = TSchoolHierarchyChoice[];

export type TSchoolHierarchyUpdateHierarchy = {
  id?: number;
  name: string;
  type: TSchoolHierarchyNodeType;
  child: TSchoolHierarchyUpdateHierarchy | null;
};

export type TSchoolHierarchyUpdatePayload = {
  school_id: number | string;
  nodes: TSchoolHierarchyUpdateHierarchy | null;
};
export type TSchoolHierarchyUpdateResponse = Record<string, unknown>;

export type TSchoolListRequestParameters = IBasePaginationRequestParameters & {
  district?: string | number | null;
  ordering?: string | null;
  grades?: string | null;
  school_level?: string | null;
  is_active?: string | null;
  city?: string | null;
  state?: string | null;
};

export type TSchoolItem = {
  id: string;
  name: string;
  district: string;
  school_code: string;
  grades: string[];
  school_level: string[];
  city: string | null;
  state: string | null;
  is_active: boolean | null;
};

export type TSchoolListResponse = IPaginatedResponse<TSchoolItem[]>;

export type TSchoolFilterChoices = Record<string, string[]>;

export type TSchoolDetails = {
  id: string;
  name: string;
  tags: TCustomTag[];
  is_active: boolean | null;
  is_hidden_on_safs: boolean | null;
  phone: string;
  website: string;
  updated_at: string;
};

export type TSchoolUpdateDetails = {
  name?: string;
  is_active?: boolean;
  school_office?: string;
  type?: string;
  subtype?: string;
  super_neighborhood?: string;
  school_code?: string;
  school_level?: string[];
  grades?: string[];
  is_hidden_on_safs?: boolean | null;
  staff_count?: number;
  average_teacher_experience?: number;
  teacher_to_student_ratio?: number;
  campus_attendance_rate?: number;
  state_accountability_rating?: string | null;
  percent_meeting_standards?: number;
  percent_not_meeting_standards?: number;
  address?: Partial<Omit<TSchoolAddress, 'id'>> | null;
  phone?: string;
  website?: string;
  description?: string;
  tags?: string[];
  holidays?: MakeOptional<TDistrictHoliday, 'id'>[];
};

export type TSchoolUpdatePayload = Nullish<TSchoolUpdateDetails> & {
  id: string;
};

export type TSchoolSafPlotChartData = {
  month: number;
  year: number;
  total_student: number;
  student_with_safs: number;
};

export type TSchoolSafPlotChartDataResponse = TSchoolSafPlotChartData[];

export type TSchoolSafInsights = {
  total: number | null;
  student_safs: number | null;
  parent_safs: number | null;
  staff_safs: number | null;
  community_safs: number | null;
  purple_user_safs: number | null;
  avg_time_until_closed_days: number | null;
  avg_time_until_prioritized_days: number | null;
  staff_count: number | null;
  unique_submitters_count: number | null;
  saf_saturation: number | null;
};

export type TSchoolBasicDetails = {
  id: string;
  name: string;
  district: number;
  is_active: boolean;
  school_office: string;
  feeder_pattern: string | null;
  type: string;
  subtype: string;
  super_neighborhood: string;
  school_code: string;
  school_level: string[];
  grades: string[];
  is_hidden_on_safs: boolean | null;
  staff_count: number;
  school_year_start_datetime?: string;
  school_year_end_datetime?: string;
  timezone: string;
  business_days?: TDistrictBusinessDay[];
  holidays?: TDistrictHoliday[];
  average_teacher_experience: number | null;
  teacher_to_student_ratio: number | null;
  campus_attendance_rate: number | null;
  state_accountability_rating: string | null;
  percent_meeting_standards: number | null;
  percent_not_meeting_standards: number | null;
  address?: Partial<Omit<TSchoolAddress, 'id'>> | null;
  phone: string;
  website?: string;
  description: string;
  tags: TCustomTag[];
};

export type TSchoolUpdateChoices = {
  school_office: Record<string, string>;
  subtype: Record<string, string>;
  super_neighborhood: Record<string, string>;
  school_level: Record<string, string>;
  state_accountability_rating: Record<string, string>;
};
