import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafRuleFiltersRequestParameters, TAdminSafRuleFiltersResponse } from './saf.types';

export const getSafsRuleFilters = async (parameters: TAdminSafRuleFiltersRequestParameters) => {
  const response = await axiosInstance.get<TAdminSafRuleFiltersResponse>(SAF_ENDPOINTS.LIST_ROUTING_RULES_FILTERS, {
    params: parameters,
  });
  return response.data;
};
