import type { ValueOf } from '@purple/shared-types';

export const ModalType = {
  // SAfs
  ADD_NEW_RULE: 'add-new-rule',
  EDIT_RULE: 'edit-rule',
  // GENERAL
  DATA_MAPPING: 'data-mapping',
  LOG_OUT: 'log-out',
  UNSAVED_CHANGES: 'unsaved-changes',
  SET_UP_TAKE_CONTROL: 'set-up-take-control',
  UPLOAD_DISTRICT_LOGO: 'upload-district-logo',
  CHANGE_DISTRICT_STATUS: 'change-district-status',
  CREATE_DISTRICT_CUSTOM_PAGE: 'create-district-custom-page',
  EDIT_DISTRICT_CUSTOM_PAGE: 'edit-district-custom-page',
  DELETE_DISTRICT_CUSTOM_PAGE: 'delete-district-custom-page',
  DELETE_DISTRICT_LOGO: 'delete-district-logo',
  USER_ACTIVATE_MODAL: 'user-activate-modal',
  BULK_USERS_ACTIVATE_MODAL: 'bulk-users-activate-modal',
  BULK_USERS_DEACTIVATE: 'bulk-users-deactivate',
  SAF_DELETE: 'saf-delete',
  SAF_DELETE_BULK: 'saf-delete-bulk',
  SAF_RULE_DELETE: 'saf-rule-delete',
  SAF_RULE_DELETE_BULK: 'saf-rule-delete-bulk',
  USER_DELETE: 'user-delete',
  USER_ROLES: 'user-roles',
  BULK_USERS_WITH_SAF_DEACTIVATE: 'bulk-users-with-saf-deactivate',
  BRAND_COLOR_PICKER: 'brand-color-picker',
  ADD_SSO_CONNECTION: 'add-sso-connection',
  DELETE_SSO_CONNECTION: 'delete-sso-connection',
  DELETE_ACTION: 'delete-action',
  ACTION_NOTE_PREVIEW: 'action-note-preview',
  // VISUALIZER
  FLAG_CALCULATION: 'visualizer-flag-calculation',
  DELETE_TREND_FLAG: 'visualizer-delete-flag',
  ADD_GRADE_FLAG: 'visualizer-add-grade-flag',
  UPDATE_FLAG_FORMULA_TYPE: 'visualizer-update-flag-formula-type',
  DELETE_ROLE: 'delete-role',
  DELETE_PERMISSION_GROUP: 'delete-permission-group',
  // SCHOOL
  DELETE_SCHOOL_HIERARCHY_NODE: 'delete-school-hierarchy-node',
  CHANGE_SCHOOL_STATUS: 'change-school-status',
  UPDATE_SCHOOL_BUSINESS_DAYS: 'update-school-business-days',
  UPDATE_SCHOOL_HOLIDAYS: 'update-school-holidays',
  CREATE_CUSTOM_TAG: 'create-tag',
  DELETE_CUSTOM_TAG: 'delete-tag',
  // BANNER
  DELETE_BANNER: 'delete-banner',
  BULK_DELETE_BANNERS: 'bulk-delete-banners',
  MANAGE_BANNER: 'manage-banner',
} as const;

export type TModalName = ValueOf<typeof ModalType>;
