import { type FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { Button, Checkbox, Label, Switch, Text, Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, SSO_PROVIDERS, SSO_PROVIDERS_LABELS, useUpdateSsoSettings } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import type { TDistrictSsoSettingItem } from '~/services';

type TDistrictSsoSettingItemProperties = {
  data: TDistrictSsoSettingItem;
};

export const SsoSettingsItem: FC<TDistrictSsoSettingItemProperties> = ({ data }) => {
  const { provider_type, sp_acs_url, sp_entity_id, sp_start_url, sp_is_signed_response_required } = data;

  const { id: districtId } = useParams();
  const queryClient = useQueryClient();

  const [isSsoEnabled, setIsSsoEnabled] = useState(data.is_enabled);

  const { openModal: openUpdateConnection } = useModal(ModalType.ADD_SSO_CONNECTION);
  const { openModal: openDeleteConnection } = useModal(ModalType.DELETE_SSO_CONNECTION);

  const { mutate, isPending } = useUpdateSsoSettings();

  const iconName = useMemo(() => {
    switch (provider_type) {
      case SSO_PROVIDERS.GOOGLE: {
        return 'Google';
      }
      case SSO_PROVIDERS.MICROSOFT: {
        return 'Microsoft';
      }
      case SSO_PROVIDERS.CLASS_LINK: {
        return 'ClassLink';
      }
      default: {
        return 'Google';
      }
    }
  }, [provider_type]);

  const toggleNotificationText = isSsoEnabled ? 'Single Sign-On disabled' : 'Single Sign-On enabled';

  const handleUpdateSsoSettings = (checked: boolean) => {
    if (!districtId) {
      showErrorToast('System message', 'Could not find district with provided ID');
      return;
    }
    setIsSsoEnabled(checked);
    mutate({
      districtId,
      is_enabled: checked,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId] });
        showSuccessToast('System message', toggleNotificationText);
      },
      onError: () => {
        setIsSsoEnabled(!checked);
        showErrorToast('System message', 'Unable to update SSO setting. Please check provided data and try again.');
      },
    });
  };

  return (
    <TooltipProvider>
      <div className="flex flex-col gap-2.5">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <PurpleIcon name={iconName} className="size-5" />
            <Text variant="size-14" type="body-500">{SSO_PROVIDERS_LABELS[provider_type]}</Text>
          </div>
          <div className="flex items-center gap-3">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="tertiary_icon_only"
                  size="icon_32"
                  onClick={openUpdateConnection}
                  iconLeft={
                    <PurpleIcon name="pencil" />
                  }
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Edit SSO Setting</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="tertiary_icon_only"
                  size="icon_32"
                  onClick={openDeleteConnection}
                  className="hover:border-error-bg hover:bg-error-bg"
                  iconLeft={
                    <PurpleIcon name="trash" className="text-error-main" />
                  }
                />
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Delete SSO</TooltipContent>
              </TooltipPortal>
            </Tooltip>
          </div>
        </div>
        <div className="grid grid-cols-[max-content,1fr] gap-x-9 gap-y-3.5">
          <Text variant="size-16" type="body-400" className="text-grey-600">Enable open ID Connect Authentication</Text>
          <div className="flex items-center space-x-2">
            <Switch checked={isSsoEnabled} onCheckedChange={handleUpdateSsoSettings} id="enable-sso" size="small" disabled={isPending} />
            <Label htmlFor="enable-sso" className="text-base">Enable</Label>
          </div>
          <Text variant="size-16" type="body-400" className="text-grey-600">ACS URL</Text>
          <Text variant="size-16" type="body-500">{sp_acs_url}</Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">Entity ID</Text>
          <Text variant="size-16" type="body-500">{sp_entity_id}</Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">Start URL</Text>
          <Text variant="size-16" type="body-500">{sp_start_url}</Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">Signed Response</Text>
          <div className="flex items-center space-x-2">
            <Checkbox checked={sp_is_signed_response_required} id="sp_is_signed_response_required" disabled className="size-4 text-grey-title" />
            <Label htmlFor="sp_is_signed_response_required" className="text-sm">Signed Response is required</Label>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};
