import axiosInstance from '~/services/axios-config';
import { BANNERS_ENDPOINTS } from '../endpoints';
import type { TBannersListQueryParameters, TDraftedBannersResponse } from './banners.types';

export const getDraftedBanners = async (parameters?: TBannersListQueryParameters) => {
  const response = await axiosInstance.get<TDraftedBannersResponse>(BANNERS_ENDPOINTS.DRAFT, {
    params: parameters,
  });
  return response.data;
};
