import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { BANNERS_QUERY_KEYS } from './banners.const';
import { bulkBannersDelete } from './bulk-banners-delete';
import type { AxiosError } from '@purple/shared-services';
import type { TBannersBulkDeletePayload, TBannersBulkDeleteResponse } from './banners.types';

export const useBannersBulkDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<TBannersBulkDeleteResponse, AxiosError, TBannersBulkDeletePayload>({
    mutationKey: [BANNERS_QUERY_KEYS.BANNERS_BULK_DELETE],
    mutationFn: bulkBannersDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_PUBLISHED_BANNERS] });
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_DRAFTED_BANNERS] });
      showSuccessToast('System message', 'Banners were deleted successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to delete banners. Please try again later');
    },
  });
};
