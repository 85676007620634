import { Skeleton } from '@purple/ui';

const EditRuleModalSkeleton = () => {
  return (
    <div className="flex flex-col gap-5 p-6">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <Skeleton className="h-[68px] w-full" />
        <Skeleton className="h-[68px] w-full" />
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <Skeleton className="h-[68px] w-full" />
        <Skeleton className="h-[68px] w-full" />
      </div>
      <div className="flex flex-col gap-1.5">
        <Skeleton className="h-[68px] w-full" />
      </div>
      <div className="flex flex-col gap-1">
        <Skeleton className="h-[120px] w-full" />
      </div>
    </div>
  );
};

export { EditRuleModalSkeleton };
