import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolSafPlot } from './get-saf-plot';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolSafPlot = (schoolId?: string | null) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_SAF_PLOT, schoolId?.toString()],
    queryFn: () => getSchoolSafPlot(schoolId!),
    enabled: !!schoolId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch students with SAFs data');
    }
  }, [isError]);

  return { isError, ...rest };
};
