import axiosInstance from '../axios-config';
import { DASHBOARD_ENDPOINTS } from '../endpoints';
import type { TDashboardLastUpdatesRequestParameters, TDashboardLastUpdatesResponse } from './dashboard.types';

export const getDashboardLastUpdates = async (parameters: TDashboardLastUpdatesRequestParameters) => {
  const response = await axiosInstance.get<TDashboardLastUpdatesResponse>(DASHBOARD_ENDPOINTS.LATEST_UPDATES, {
    params: {
      ...parameters,
      districts: parameters?.districts?.join(','),
    },
  });
  return response.data;
};
