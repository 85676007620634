import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionTypesOptions } from './get-action-types-list';
import type { AxiosError } from 'axios';
import type { TActionTypesOptionsResponse } from './actions.types';

export const useActionTypesOptions = (safId?: string) => {
  const { isError, error, ...rest } = useQuery<TActionTypesOptionsResponse, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPES_OPTIONS, safId],
    queryFn: () => getActionTypesOptions(safId as string),
    enabled: !!safId,
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch actions types');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
