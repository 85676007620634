import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { DistrictDetailsView, DistrictView } from '@purple/shared-types';
import { AccessDenied, AppTabs, Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import {
  DistrictBasicDetailsTab,
  DistrictBrandingTab,
  DistrictInfo,
  DistrictNavigationTab,
  DistrictUsersTab,
  SafProcessTab,
  SisDataTab,
  SsoSettings,
} from './components';
import type { TAppTab } from '@purple/ui';

export const DistrictDetailsPage: React.FC = () => {
  const districtDetailsTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: DistrictDetailsView.DETAILS,
        className: 'shadow-none',
        content: <DistrictBasicDetailsTab />,
        permissions: [],
      },
      {
        label: 'Branding',
        value: DistrictDetailsView.BRANDING,
        className: 'shadow-none',
        content: <DistrictBrandingTab />,
        permissions: [],
      },
      {
        label: 'Set Up Navigation',
        value: DistrictDetailsView.NAVIGATION,
        className: 'shadow-none flex-1 flex-col [&:not(&:hidden)]:flex',
        content: <DistrictNavigationTab />,
        permissions: [],
      },
      {
        label: 'SAF Process',
        value: DistrictDetailsView.SAF_PROCESS,
        className: 'shadow-none',
        content: <SafProcessTab />,
        permissions: [],
      },
      {
        label: 'District Users',
        value: DistrictDetailsView.USERS,
        className: 'shadow-none',
        content: <DistrictUsersTab />,
        permissions: [],
      },
      {
        label: 'SIS Data',
        value: DistrictDetailsView.SIS_DATA,
        className: 'shadow-none',
        content: <SisDataTab />,
        permissions: [],
      },
      {
        label: 'SSO Settings',
        value: DistrictDetailsView.SSO_SETTINGS,
        className: 'shadow-none',
        content: <SsoSettings />,
        permissions: [],
      },
    ],
    [],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: districtDetailsTabs,
  });

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Districts.Root.makePath({ queries: { tab: DistrictView.PUBLISHED } })}>
          Back to Districts
        </Link>
      </Button>
      <DistrictInfo />
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={districtDetailsTabs} listClassName="pl-2 justify-start" className="flex flex-1 flex-col" value={activeTab} onValueChange={tabChangeHandler} />
          )}
    </div>
  );
};
