import { z } from 'zod';
import { DaysOfWeek } from '@purple/shared-types';
import { SUBDOMAIN_NAME_REGEX } from '@purple/shared-utils';

export const basicStepSchema = z
  .object({
    name: z.string({ invalid_type_error: 'Name must be a string.' }).trim().min(1, { message: 'School is required.' }),
    states: z.array(z.string()).min(1, 'At least one state is required'),
    regions: z.array(z.string()).optional(),
    subdomain: z
      .string({ invalid_type_error: 'Subdomain must be a string.' })
      .trim()
      .min(1, { message: 'Subdomain is required.' })
      .refine((value) => SUBDOMAIN_NAME_REGEX.test(value) && !value.startsWith('-') && !value.endsWith('-'), {
        message: 'Subdomain can only contain letters, numbers, and dashes, and cannot start or end with a dash.',
      }),
    school_year_start_datetime: z.coerce.date({ required_error: 'Date is required.', message: 'Date is invalid.' }),
    school_year_end_datetime: z.coerce.date({ required_error: 'Date is required.', message: 'Date is invalid.' }),
    timezone: z.string().min(1, 'Timezone is required'),
    business_days: z
      .array(
        z.object({
          id: z.number().optional(),
          is_active: z.boolean(),
          day: z.nativeEnum(DaysOfWeek),
          start_time: z.coerce.date({ required_error: 'Date is required.', message: 'Time is invalid.' }),
          end_time: z.coerce.date({ required_error: 'Date is required.', message: 'Time is invalid.' }),
        }),
      )
      .min(1, 'At least one business day is required')
      .refine((data) => data.some((day) => day.is_active), { message: 'At least one business day must be active' }),
    holidays: z
      .array(
        z.object({
          id: z.union([z.string(), z.number()]).optional(),
          name: z
            .string({ invalid_type_error: 'Name must be a string.' })
            .trim()
            .min(1, { message: 'Name is required.' }),
          start_datetime: z.coerce.date({ required_error: 'Date is required.', message: 'Date is invalid.' }),
          end_datetime: z.coerce.date({ required_error: 'Date is required.', message: 'Date is invalid.' }),
          is_recurring: z.boolean(),
        }),
      )
      .min(1, 'At least one holiday is required'),
  })
  .superRefine((data, context) => {
    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_start_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'Start of fiscal year must be before end of fiscal year.',
      });
    }

    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_end_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'End of fiscal year must be after start of fiscal year.',
      });
    }

    for (const [index, day] of data.business_days.entries()) {
      if (day.is_active && day.end_time <= day.start_time) {
        context.addIssue({
          path: ['business_days', index, 'start_time'],
          code: z.ZodIssueCode.custom,
          message: 'Start time must be before end time.',
        });
        context.addIssue({
          path: ['business_days', index, 'end_time'],
          code: z.ZodIssueCode.custom,
          message: 'End time must be after start time.',
        });
      }
    }

    for (const [index, holiday] of data.holidays.entries()) {
      if (holiday.end_datetime < holiday.start_datetime) {
        context.addIssue({
          path: ['holidays', index, 'end_datetime'],
          code: z.ZodIssueCode.custom,
          message: 'End date cannot be earlier than start date.',
        });
        context.addIssue({
          path: ['holidays', index, 'start_datetime'],
          code: z.ZodIssueCode.custom,
          message: 'Start date cannot be earlier than end date.',
        });
      }
    }
  })
  .refine(
    (data) => {
      const start = new Date(data.school_year_start_datetime);
      const end = new Date(data.school_year_end_datetime);
      const oneYearLater = new Date(start);
      oneYearLater.setFullYear(start.getFullYear() + 1);
      return end <= oneYearLater;
    },
    {
      message: 'The difference between the start and end of the school year cannot be more than 1 year',
      path: ['school_year_end_datetime'],
    },
  );
