import type { MakeOptional, ValueOf } from '@purple/shared-types';

export type TStudentVisualizerSetupState = {
  id: number;
  is_data_table_view_configured: boolean;
  is_subpopulation_filters_configured: boolean;
  is_absences_visualizer_configured: boolean;
  is_color_trends_pull_dates_configured: boolean;
  is_color_trends_configured: boolean;
};

export type TVisualizerAbsenceThreshold = {
  id: number;
  threshold: number;
  month: number;
};

export type TVisualizerAbsenceThresholdsResponse = {
  id: number;
  absence_thresholds: TVisualizerAbsenceThreshold[];
};

export type TVisualizerAbsenceThresholdsPayload = {
  district_id: number | string;
  absence_thresholds: Omit<TVisualizerAbsenceThreshold, 'month'>[];
};

export type TVisualizerPullDate = {
  id: number;
  // Date format: YYYY-MM-DD
  pull_date: string;
  is_report_card_date: boolean;
};

export type TVisualizerPullDatesResponse = {
  id: number;
  pull_dates: TVisualizerPullDate[];
};

export type TVisualizerPullDatesPayload = {
  district_id: number | string;
  pull_dates: MakeOptional<TVisualizerPullDate, 'id'>[];
};

export type TVisualizerSubpopulationFilter = {
  id: string;
  name: string;
  is_displayed: boolean;
  alias: string;
};

export type TVisualizerSubpopulationFiltersResponse = {
  id: number;
  subpopulations: TVisualizerSubpopulationFilter[];
};

export type TVisualizerSubpopulationFiltersPayload = {
  district_id: number | string;
  subpopulations: TVisualizerSubpopulationFilter[];
};

export type TVisualizerDataTableView = {
  id: number;
  column_header_name: string;
  is_displayed: boolean;
  alias: string;
  is_default: boolean;
  order_number: number;
  key?: string;
};

export type TVisualizerDataTableViewResponse = {
  id: number;
  data_points: TVisualizerDataTableView[];
};

export type TVisualizerDataTableViewPayload = {
  district_id: number | string;
  data_points: Omit<TVisualizerDataTableView, 'is_required'>[];
};

export const ColorTrendMetricType = {
  ATTENDANCE: 'attendance',
  BEHAVIOR: 'behavior',
  GRADE: 'grades',
  HEALTH: 'health',
  SUBPOPULATIONS: 'subpopulations',
  ASSESSMENTS: 'assessments',
} as const;
export type TColorTrendMetricType = ValueOf<typeof ColorTrendMetricType>;

export const MetricDataType = {
  NUMERICAL: 'numerical',
  LEVELS: 'levels',
  TEXT: 'text',
} as const;
export type TMetricDataType = ValueOf<typeof MetricDataType>;

export const GradeFormulaFlagType = {
  VALUE: 'value',
  THRESHOLD: 'threshold',
  RANGE: 'range',
} as const;
export type TGradeFormulaFlagType = ValueOf<typeof GradeFormulaFlagType>;

export const FormulaCondition = {
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_EQUAL: '>=',
  LESS_THAN_EQUAL: '<=',
} as const;
export type TFormulaCondition = ValueOf<typeof FormulaCondition>;

export type TVisualizerFormula = {
  id?: number;
  name: string;
  type: TGradeFormulaFlagType;
  flag_value: number;
  grade_value?: number | null;
  condition?: TFormulaCondition | null;
  min_grade?: number | null;
  max_grade?: number | null;
};

export type TVisualizerFlag = {
  id: number;
  name: string;
  type: TColorTrendMetricType;
  district: number;
  is_baseline_added: boolean;
  is_displayed?: boolean;
  is_total?: boolean;
  grades?: string[];
  number_of_maximum_flags: number | null;
  data_type?: TMetricDataType | null;
  unsatisfactory?: number | null;
  needs_improvement?: number | null;
  excused?: number | null;
  unexcused?: number | null;
  total_absences?: number | null;
  iss?: number | null;
  oss?: number | null;
  daep?: number | null;
  contacted_parent?: number | null;
  parent_or_guardian_conference?: number | null;
  behavior_contact?: number | null;
  partial_day_oss?: number | null;
  partial_day_iss?: number | null;
  after_school_detention?: number | null;
  reverse_suspension?: number | null;
  teacher_detention_hall?: number | null;
  peer_mediation?: number | null;
  saturday_detention?: number | null;
  level1?: number | null;
  level2?: number | null;
  level3?: number | null;
  level4?: number | null;
  level5?: number | null;
  nurse_or_health_office_visits?: number | null;
  option1?: number | null;
  option2?: number | null;
  option3?: number | null;
  option4?: number | null;
  special_education?: number | null;
  english_language_learner?: number | null;
  five_o_four?: number | null;
  refugee?: number | null;
  grade_flag_formulas?: TVisualizerFormula[];
};

export type TVisualizerFlagCalculationsResponse = {
  id: number;
  flag_calculations: TVisualizerFlag[];
};

export type TVisualizerFlagBulkPayload = {
  district_id: number | string;
  flag_calculations: Required<Pick<TVisualizerFlag, 'id' | 'is_displayed' | 'is_total'>>[];
};

export type TVisualizerFlagCreatePayload = Omit<TVisualizerFlag, 'id'>;
export type TVisualizerFlagUpdatePayload = TVisualizerFlag;
