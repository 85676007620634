import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { updateActionType } from './update-action-type';
import type { AxiosError } from 'axios';
import type { TCustomUseMutationOptions } from '~/types/query';
import type { TActionTypeItem } from './actions.types';

export const useUpdateActionType = (options?: TCustomUseMutationOptions<TActionTypeItem, AxiosError>) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationKey: [ACTIONS_QUERY_KEYS.UPDATE_ACTION_TYPE],
    mutationFn: updateActionType,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPES_LIST] });
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPE, response.id] });
      showSuccessToast('Status message', 'Action type updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('Status message', 'Unable to update action type');
      }
    },
  });
};
