import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Form } from '@purple/ui';
import { useCreateGroup, useGroupDetails, usePermissionStructure, useUpdateGroup } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { PAGE_MEASUREMENTS, PERMISSION_GROUP_PARAMETERS_KEY } from '../../constants';
import { permissionGroupSchema } from '../../schema';
import { GroupPermissionStep, GroupPermissionStepper } from '../../stepper';
import { GroupAddUsersForm } from '../GroupAddUsersForm';
import { GroupInformationForm } from '../GroupInformationForm';
import { GroupPermissionForm } from '../GroupPermissionForm';
import { GroupPermissionsFooter } from '../GroupPermissionsFooter';
import { GroupStepperNavigation } from '../GroupStepperNavigation';
import { GroupStepperProgress } from '../GroupStepperProgress';
import type { TGroupPermissionBase } from '../../schema';
import type { TGroupPermissionStep } from '../../stepper';

export const GroupPermissionsContent = () => {
  const fromId = useId();
  const [_, setSearchParameters] = useSearchParams();

  const stepper = GroupPermissionStepper.useStepper();

  const { query: groupId } = useQueryParameter({ queryName: PERMISSION_GROUP_PARAMETERS_KEY.groupId });

  const { isLoading: isStructureLoading } = usePermissionStructure();
  const { data: groupDetailInfo, isLoading } = useGroupDetails(groupId!);
  const { mutate: createPermissionGroup, isPending: isCreating } = useCreateGroup();
  const { mutate: updatePermissionGroup, isPending: isUpdating } = useUpdateGroup();

  const formDefaultValues: TGroupPermissionBase = useMemo(() => {
    return {
      currentStep: GroupPermissionStep.GROUP_INFORMATION,
      name: groupDetailInfo?.name ?? '',
      description: groupDetailInfo?.description ?? '',
      permissions: groupDetailInfo?.permission_codenames ?? [],
      users: groupDetailInfo?.users ?? [],
    };
  }, [
    groupDetailInfo?.name,
    groupDetailInfo?.description,
    groupDetailInfo?.permission_codenames,
    groupDetailInfo?.users,
  ]);

  const isDataLoading = useMemo(() => isStructureLoading || isLoading, [isStructureLoading, isLoading]);

  const form = useForm<TGroupPermissionBase>({
    mode: 'onTouched',
    resolver: zodResolver(permissionGroupSchema),
    defaultValues: formDefaultValues,
  });

  const createPermissionGroupHandler = (formValues: TGroupPermissionBase) => {
    createPermissionGroup({
      name: formValues.name,
      description: formValues.description,
      permissions: formValues.permissions ?? [],
      users: formValues.users ?? [],
    });
  };

  const updatePermissionGroupHandler = (formValues: TGroupPermissionBase) => {
    if (!groupId) {
      showErrorToast('System message', `Could not find group with provided id:${groupId}`);
      return;
    }
    updatePermissionGroup({
      groupId,
      name: formValues.name,
      description: formValues.description,
      permissions: formValues.permissions ?? [],
      users: formValues.users ?? [],
    });
  };

  const formSubmitHandler = (formValues: TGroupPermissionBase) => {
    if (formValues.currentStep !== GroupPermissionStep.ADD_USERS) {
      stepper.next();
      return;
    }
    groupId ? updatePermissionGroupHandler(formValues) : createPermissionGroupHandler(formValues);
  };

  const stepNavigationItemClickHandler = (
    goToStep: (stepId: TGroupPermissionStep) => void,
    stepId: TGroupPermissionStep,
  ) => {
    const currentStepIndex = stepper.all.findIndex((step) => step.id === stepper.current.id);
    const pressedStepIndex = stepper.all.findIndex((step) => step.id === stepId);
    if (stepId !== GroupPermissionStep.ADD_USERS) {
      setSearchParameters((searchParams) => {
        const params = new URLSearchParams(searchParams);
        params.delete(LIMIT_QUERY_NAME);
        params.delete(OFFSET_QUERY_NAME);
        params.delete(SEARCH_QUERY_NAME);
        params.delete(SORT_QUERY_NAME);
        return params;
      });
    }
    if (pressedStepIndex < currentStepIndex) {
      goToStep(stepId);
      return;
    }
    form.trigger().then((isValid) => isValid && goToStep(stepId),
    );
  };

  useEffect(() => {
    form.setValue('currentStep', stepper.current.id);
  }, [form, stepper]);

  useEffect(() => {
    form.reset(formDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues]);

  return (
    <main className="flex w-full flex-1 flex-row gap-7 p-8">
      {isDataLoading
        ? <div className="flex w-full items-center justify-center" style={{ maxHeight: `calc(100vh - ${PAGE_MEASUREMENTS.HEADER}px)` }}><PurpleIcon name="loader" className="size-9 animate-spin" /></div>
        : (
            <>
              <GroupStepperNavigation onStepClick={stepNavigationItemClickHandler} />
              <div className="flex w-full flex-1 flex-col">
                <GroupStepperProgress />
                <section className="flex w-full flex-1 flex-col overflow-hidden rounded-lg border border-grey-200 bg-white">
                  <Form providerProps={form} className="flex-1" id={fromId} onSubmit={form.handleSubmit(formSubmitHandler)}>
                    {stepper.switch({
                      [GroupPermissionStep.GROUP_INFORMATION]: () => <GroupInformationForm />,
                      [GroupPermissionStep.MANAGE_PERMISSIONS]: () => <GroupPermissionForm />,
                      [GroupPermissionStep.ADD_USERS]: () => <GroupAddUsersForm />,
                    })}
                  </Form>
                  <GroupPermissionsFooter formId={fromId} isLoading={isCreating || isUpdating} />
                </section>
              </div>
            </>
          )}

    </main>
  );
};
