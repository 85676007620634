import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { safRuleBulkDelete } from './delete-saf-rule-bulk';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';

export const useSafRuleBulkDelete = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, {
    ids: string[];
  }>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.BULK_RULE_DELETE],
    mutationFn: safRuleBulkDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.LIST_SAF_ROUTING_RULE] });
      showSuccessToast('System message', 'SAF routing rule was successfully deleted from school');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to delete. Please try again later');
      }
    },
  });
};
