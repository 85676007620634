import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictUserDeactivatePayload, TDistrictUserDeactivateResponse } from './districts.types';

export const bulkDistrictUsersDeactivate = async (payload: TDistrictUserDeactivatePayload) => {
  const response = await axiosInstance.post<TDistrictUserDeactivateResponse>(
    DISTRICTS_ENDPOINTS.USERS_BULK_DEACTIVATE,
    payload,
  );
  return response.data;
};
