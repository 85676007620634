import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUsersListManagement } from './get-users-management';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';

export const useGetUsersListMutation = () => {
  // NOTE: we use useMutation hook to get all users by clicking on the button (or another trigger)
  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.GET_LIST_MANAGEMENT_MUTATION],
    mutationFn: getUsersListManagement,

    onError: () => {
      showErrorToast('System message', 'Could not get all users. Check the provided information and try again');
    },
  });
};
