import { useId, useMemo, useRef, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';
import { useKeyPress, useQueryParameter } from '@purple/hooks';
import { Button } from '@purple/ui';
import { AdminRoutes, VisualizerStep, VisualizerStepSequence } from '~/constants';
import { MainContainer, SetupHeader } from '~/layout';
import { useUnsavedChanges } from '~/providers';
import { VisualizerNavigation, VisualizerSetupProgress, VisualizerStepContainer, VisualizerStepFooter, VisualizerStepHeader } from './components';
import { AbsencesVisualizer, ColorTrends, DataTableView, PullDates, SubpopulationFilters } from './step';
import type { TVisualizerStep } from '~/constants';

const CONTAINER_PADDING = 32;
const SECTION_BORDER = 1;

export const VisualizerSetupPage: React.FC = () => {
  const formId = useId();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState<boolean>(false);

  const { setShouldShowUnsaved, checkUnsaved, shouldShowUnsaved } = useUnsavedChanges();
  const { query: stepQuery, onQueryChange } = useQueryParameter<TVisualizerStep>({
    queryName: 'step',
    defaultValue: VisualizerStep.DATA_TABLE_VIEW,
  });

  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const { height: pageHeaderHeight = 0 } = useResizeObserver({ ref: pageHeaderRef, box: 'border-box' });
  const { height: progressHeight = 0 } = useResizeObserver({ ref: progressRef, box: 'border-box' });
  const { height: headerHeight = 0 } = useResizeObserver({ ref: headerRef, box: 'border-box' });
  const { height: footerHeight = 0 } = useResizeObserver({ ref: footerRef, box: 'border-box' });

  const offsetHeight = useMemo(
    () =>
      pageHeaderHeight + (CONTAINER_PADDING * 2) + (SECTION_BORDER * 2) + progressHeight + headerHeight + footerHeight,
    [headerHeight, footerHeight, pageHeaderHeight, progressHeight],
  );

  const previousStepClickHandler = () => {
    if (!stepQuery) return;
    const currentStep = VisualizerStepSequence[stepQuery];
    const previousStep = Object.values(VisualizerStep).find((key) => VisualizerStepSequence[key] === currentStep - 1);
    if (previousStep) {
      checkUnsaved(() => onQueryChange(previousStep));
    }
  };

  const nextStepClickHandler = () => {
    setIsNextButtonClicked(true);
  };

  const nextStepHandler = () => {
    if (!stepQuery) return;
    const currentStep = VisualizerStepSequence[stepQuery];
    const nextStep = Object.values(VisualizerStep).find((key) => VisualizerStepSequence[key] === currentStep + 1);
    if (nextStep) {
      onQueryChange(nextStep);
    }
  };

  const submitFormHandler = () => {
    setIsLoading(true);
  };

  const submitFormSuccessHandler = () => {
    setShouldShowUnsaved(false);
    setIsLoading(false);
    if (isNextButtonClicked) {
      nextStepHandler();
    }
    setIsNextButtonClicked(false);
  };

  const submitFormErrorHandler = () => {
    setIsLoading(false);
  };

  const saveFormHandler = () => {
    if (isLoading) return;
    saveButtonRef.current?.click();
  };

  useKeyPress('KeyS', saveFormHandler, {
    withCtrl: true,
    preventDefault: true,
  });

  return (
    <MainContainer>
      <SetupHeader ref={pageHeaderRef} backRoute={AdminRoutes.App.Visualizer.Student.path}>
        <Button ref={saveButtonRef} type="submit" form={formId} isLoading={isLoading} disabled={!shouldShowUnsaved}>
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      </SetupHeader>
      <main className="flex w-full flex-1 flex-row gap-7 p-8">
        <VisualizerNavigation />
        <div className="flex w-full flex-1 flex-col">
          <VisualizerSetupProgress ref={progressRef} />
          <section className="flex w-full flex-1 flex-col overflow-hidden rounded-lg border border-grey-200 bg-white">
            <VisualizerStepHeader ref={headerRef} />
            <VisualizerStepContainer offsetHeight={offsetHeight}>
              {stepQuery === VisualizerStep.DATA_TABLE_VIEW && (
                <DataTableView
                  formId={formId}
                  onSubmit={submitFormHandler}
                  onSubmitSuccess={submitFormSuccessHandler}
                  onSubmitError={submitFormErrorHandler}
                />
              )}
              {stepQuery === VisualizerStep.SUBPOPULATION_FILTERS && (
                <SubpopulationFilters
                  formId={formId}
                  onSubmit={submitFormHandler}
                  onSubmitSuccess={submitFormSuccessHandler}
                  onSubmitError={submitFormErrorHandler}
                />
              )}
              {stepQuery === VisualizerStep.ABSENCES_VISUALIZER && (
                <AbsencesVisualizer
                  formId={formId}
                  onSubmit={submitFormHandler}
                  onSubmitSuccess={submitFormSuccessHandler}
                  onSubmitError={submitFormErrorHandler}
                />
              )}
              {stepQuery === VisualizerStep.COLOR_TRENDS_PULL_DATES && (
                <PullDates
                  formId={formId}
                  onSubmit={submitFormHandler}
                  onSubmitSuccess={submitFormSuccessHandler}
                  onSubmitError={submitFormErrorHandler}
                />
              )}
              {stepQuery === VisualizerStep.COLOR_TRENDS && (
                <ColorTrends
                  formId={formId}
                  onSubmit={submitFormHandler}
                  onSubmitSuccess={submitFormSuccessHandler}
                  onSubmitError={submitFormErrorHandler}
                />
              )}
            </VisualizerStepContainer>
            <VisualizerStepFooter
              ref={footerRef}
              formId={formId}
              isLoading={isLoading}
              onPreviousStep={previousStepClickHandler}
              onNextStep={nextStepClickHandler}
            />
          </section>
        </div>
      </main>
    </MainContainer>
  );
};
