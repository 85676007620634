import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';

type TSetupHeaderProps = React.PropsWithChildren<{
  backRoute: string;
}>;

export const SetupHeader = forwardRef<HTMLElement, TSetupHeaderProps>((props, ref) => {
  const { backRoute, children } = props;

  return (
    <header ref={ref} className="sticky top-0 z-10 flex min-h-[68px] w-full items-center justify-between gap-4 border-b border-b-grey-200 bg-white px-8 py-4">
      <div className="flex items-center gap-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="chevron-left" className="size-5 shrink-0" />} asChild>
              <Link to={backRoute} />
            </Button>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>
              Go back
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <strong className="text-base font-semibold text-grey-950">Student Data Setup</strong>
      </div>
      {children}
    </header>
  );
});
