import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const behaviorLevelsSchema = zodAlwaysRefine(
  z.object({
    level1: floatNumber(
      z.number().gte(0, 'Level 1 must be greater than or equal to 0'),
      2,
    ).nullish(),
    level2: floatNumber(
      z.number().gte(0, 'Level 2 must be greater than or equal to 0'),
      2,
    ).nullish(),
    level3: floatNumber(
      z.number().gte(0, 'Level 3 must be greater than or equal to 0'),
      2,
    ).nullish(),
    level4: floatNumber(
      z.number().gte(0, 'Level 4 must be greater than or equal to 0'),
      2,
    ).nullish(),
    level5: floatNumber(
      z.number().gte(0, 'Level 5 must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TBehaviorLevelsSchema = z.infer<typeof behaviorLevelsSchema>;

export const behaviorLevelsSchemaSuperRefine = (data: TFlagCalculationBase<typeof behaviorLevelsSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.BEHAVIOR || data.data_type !== MetricDataType.LEVELS) return;

  if (!isNumber(data.level1)) {
    ctx.addIssue({
      path: ['level1'],
      code: z.ZodIssueCode.custom,
      message: 'Level 1 is required',
    });
  }
  if (!isNumber(data.level2)) {
    ctx.addIssue({
      path: ['level2'],
      code: z.ZodIssueCode.custom,
      message: 'Level 2 is required',
    });
  }
  if (!isNumber(data.level3)) {
    ctx.addIssue({
      path: ['level3'],
      code: z.ZodIssueCode.custom,
      message: 'Level 3 is required',
    });
  }
  if (!isNumber(data.level4)) {
    ctx.addIssue({
      path: ['level4'],
      code: z.ZodIssueCode.custom,
      message: 'Level 4 is required',
    });
  }
  if (!isNumber(data.level5)) {
    ctx.addIssue({
      path: ['level5'],
      code: z.ZodIssueCode.custom,
      message: 'Level 5 is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
