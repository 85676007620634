import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateMappingColumns } from './update-mapping-columns';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TDistrictColumnMappingPayload } from './districts.types';

export const useUpdateMappingColumns = () => {
  return useMutation<AxiosResponse, AxiosError, TDistrictColumnMappingPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_MAPPING_COLUMNS],
    mutationFn: updateMappingColumns,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to update mapping columns');
      }
    },
  });
};
