import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const gradeLevelsSchema = zodAlwaysRefine(
  z.object({
    level1: floatNumber(
      z.number().gte(0, 'Level 1 must be greater than or equal to 0'),
      2,
    ).nullish(),
    level2: floatNumber(
      z.number().gte(0, 'Level 2 must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TGradeLevelsSchema = z.infer<typeof gradeLevelsSchema>;

export const gradeLevelsSchemaSuperRefine = (data: TFlagCalculationBase<typeof gradeLevelsSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.GRADE || data.data_type !== MetricDataType.LEVELS) return;

  if (!isNumber(data.level1)) {
    ctx.addIssue({
      path: ['level1'],
      code: z.ZodIssueCode.custom,
      message: 'Level 1 is required',
    });
  }
  if (!isNumber(data.level2)) {
    ctx.addIssue({
      path: ['level2'],
      code: z.ZodIssueCode.custom,
      message: 'Level 2 is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
