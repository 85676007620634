import * as z from 'zod';

export const updateBasicDetailSchema = z.object({
  first_name: z.string().trim().min(1, { message: 'This field is required' }),
  last_name: z.string().trim().min(1, { message: 'This field is required' }),
  roles: z.array(z.string()).optional(),
  phone_number: z.string().optional(),
  title: z.string().optional(),
  department: z.string().optional(),
  responsible_for_grades: z.array(z.string()).optional(),
  is_show_on_scorecard: z.boolean().optional(),
});
