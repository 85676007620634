import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { deactivateSchool } from './deactivate-school';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useDeactivateSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.DEACTIVATE_SCHOOL],
    mutationFn: deactivateSchool,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_SCHOOLS_LIST] });
      showSuccessToast('System message', 'School was deactivated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to deactivate school. Please try again later');
    },
  });
};
