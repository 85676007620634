import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Button, NoDataAvailable } from '@purple/ui';
import { AddSsoConnectionModal, CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, useDeleteSsoSettings, useDistrictSsoSettings } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { SettingsItemSkeleton } from './SettingsItemSkeleton';
import { SsoSettingsItem } from './SsoSettingsItem';
import type { FC } from 'react';

export const SsoSettings: FC = () => {
  const { id: districtId } = useParams();
  const { openModal: openAddConnection } = useModal(ModalType.ADD_SSO_CONNECTION);
  const { closeModal: closeDeleteConnection } = useModal(ModalType.DELETE_SSO_CONNECTION);

  const queryClient = useQueryClient();

  // We are handling undefined districtId in the hook if it is not provided we are not making the request
  const { data, isLoading } = useDistrictSsoSettings(districtId!);
  const { mutate: deleteConnection, isPending: isDeleting } = useDeleteSsoSettings();

  const deleteConnectionHandler = () => {
    if (!districtId) {
      return showErrorToast('System message', 'Could not find district with provided ID');
    }
    deleteConnection({ districtId }, {
      onSuccess: () => {
        closeDeleteConnection();
        queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SSO_SETTINGS, districtId] });
        showSuccessToast('System message', 'Connection was successfully deleted');
      },
    });
  };

  return (
    <>
      <DistrictTabContainer
        title="SSO Settings"
        className="gap-4 py-4"
        headerClassName="py-3"
        actions={
          !data && !isLoading && (
            <Button type="button" onClick={openAddConnection}>
              Add Connection
            </Button>
          )
        }
      >
        {isLoading && !data && <SettingsItemSkeleton />}
        {data && !isLoading && <SsoSettingsItem data={data} />}
        {!data && !isLoading
        && <NoDataAvailable iconSize={42} className="h-[445px]" iconName="lock-closed" title="Enable your Configuration" description="Single Sign-On is not currently configured. Add a Connection to get started." />}

      </DistrictTabContainer>
      <AddSsoConnectionModal />
      <CallToActionModal
        modalName={ModalType.DELETE_SSO_CONNECTION}
        modalTitle="Delete Connection"
        modalDescription="Modal for deleting connection"
        showModalDescription={false}
        modalTextContent="Are you sure you want to delete this connection? This action cannot be undone."
        isLoading={isDeleting}
        primaryButtonText="Yes, Delete"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={deleteConnectionHandler}
      />
    </>
  );
};
