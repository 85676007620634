import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { ACCEPTED_DISTRICT_LOGO_TYPES, MAX_DISTRICT_LOGO_SIZE } from '@purple/shared-utils';
import {
  AvatarUploader,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useUpdateDistrictBranding } from '~/services';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const logoSchema = z.object({
  logo: z
    .custom<File>((value) => value instanceof File, {
      message: 'Invalid file type.',
    })
    .nullish()
    .refine(
      (file) => ACCEPTED_DISTRICT_LOGO_TYPES.has(file?.type ?? ''),
      'Only .jpg, .jpeg and .png formats are supported.',
    )
    .refine(
      (file) => file && file.size <= MAX_DISTRICT_LOGO_SIZE,
      `Unable to upload a file that is more than ${MAX_DISTRICT_LOGO_SIZE / 1024 / 1024}MB.`,
    )
    .refine(
      (file) => file && file.name.length <= 100,
      'File name should be less than 100 characters.',
    ),
});

type TUploadImageDialogProperties = {
  district: TDistrictDetails;
};

export const UploadImageDialog: React.FC<TUploadImageDialogProperties> = (props) => {
  const { district } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.UPLOAD_DISTRICT_LOGO);

  const { mutate: updateBranding, isPending } = useUpdateDistrictBranding();

  const defaultValues = useMemo(
    () => ({
      logo: null,
    }),
    [],
  );

  const form = useForm<z.infer<typeof logoSchema>>({
    resolver: zodResolver(logoSchema),
    mode: 'onChange',
    defaultValues,
  });
  const logo = form.watch('logo');
  const isSubmitDisabled = useMemo(() => !(logo instanceof File), [logo]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const submitDistrictLogoHandler = (data: z.infer<typeof logoSchema>) => {
    updateBranding(
      {
        id: district.id,
        ...(data.logo && { logo: data.logo }),
      },
      {
        onSuccess: () => {
          closeModal();
          form.reset(defaultValues);
        },
      },
    );
  };

  const logoChangeHandler = (newFiles: File[] | null) => {
    if (!newFiles || newFiles.length === 0) {
      form.setValue('logo', null);
    } else {
      const [newFile] = newFiles;
      if (newFile) {
        form.setValue('logo', newFile);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>Change Logo</DialogTitle>
          <DialogDescription className="sr-only">
            Change district logo that will be displayed in purple sense application
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitDistrictLogoHandler)}
        >
          <FormField
            control={form.control}
            name="logo"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormControl>
                  <AvatarUploader
                    value={field.value ? [field.value] : null}
                    onValueChange={logoChangeHandler}
                    maxSize={MAX_DISTRICT_LOGO_SIZE}
                    className="min-h-[216px]"
                    accept={[...ACCEPTED_DISTRICT_LOGO_TYPES].reduce(
                      (accumulator, type) => ({ ...accumulator, [type]: [] }),
                      {},
                    )}
                    disabled={isPending}
                  />
                </FormControl>
                <FormMessage className="text-center" />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} disabled={isSubmitDisabled} isLoading={isPending}>
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
