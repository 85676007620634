import { useCallback } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { THierarchyNodeId } from './types';

type THierarchyNodeActionsProps = React.PropsWithChildren<{
  id: THierarchyNodeId;
  isSelected?: boolean;
  onEdit?: (id: THierarchyNodeId) => void;
  onDelete?: (id: THierarchyNodeId) => void;
}>;

export const HierarchyNodeActions: React.FC<THierarchyNodeActionsProps> = (props) => {
  const { onDelete, onEdit, id, isSelected = false } = props;

  const editClickHandler = useCallback((evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    onEdit?.(id);
  }, [id, onEdit]);

  const deleteClickHandler = useCallback((evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    onDelete?.(id);
  }, [id, onDelete]);

  return (
    <div
      className={cn(
        'ml-auto group-hover:opacity-100 group-hover:visible transition-opacity duration-200',
        isSelected ? 'visible opacity-100' : 'invisible opacity-0',
      )}
    >
      <div className="relative z-10 ml-4 flex items-center gap-1">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="shrunk-0 size-6"
              iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
              onClick={editClickHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Edit node</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="destructive_secondary"
              size="icon_32"
              className="shrunk-0 size-6 border-0 bg-transparent"
              iconLeft={<PurpleIcon name="trash" className="size-4 shrink-0" />}
              onClick={deleteClickHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Delete node</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    </div>
  );
};
