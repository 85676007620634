import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { cn, SearchInput, Separator, TabsList, TabsTrigger, Text } from '@purple/ui';
import { PERMISSIONS_QUERY_KEYS, type TPermissionStructure } from '~/services';
import { useManageRolesTabs } from '../../useManageRolesTabs';
import type { FC } from 'react';

type TManageRolesFormSideBarProperties = {
  className?: string;
};

export const ManageRolesFormSideBar: FC<TManageRolesFormSideBarProperties> = ({ className }) => {
  const queryClient = useQueryClient();

  const permissionStructure = queryClient.getQueryData<TPermissionStructure>([PERMISSIONS_QUERY_KEYS.GET_PERMISSIONS_STRUCTURE]);
  const { tabs } = useManageRolesTabs({ permissionStructure });

  const [searchTabValue, setSearchTabValue] = useState('');
  const filteredTabs = tabs.filter((tab) => tab.label.toLowerCase().includes(searchTabValue.toLowerCase()));

  const handleSearchTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTabValue(event.target.value);
  };

  return (
    <div className={cn('rounded-lg border border-grey-200 max-w-[320px] px-4 py-6 flex flex-col gap-4 bg-white h-fit', className)}>
      <SearchInput placeholder="Search" variant="border-bottom" value={searchTabValue} onChange={handleSearchTabChange} />
      <div className="flex flex-col gap-2">
        <Text variant="size-12" type="body-400" className="uppercase text-grey-400">Section Permission</Text>
        <TabsList className="flex flex-col items-start gap-2">
          {filteredTabs.length > 0
            ? (
                filteredTabs.map((tab) => (
                  <TabsTrigger key={tab.value} value={tab.value} className="border-none p-0 text-sm text-grey-title">
                    {tab.label}
                  </TabsTrigger>
                )))
            : (
                <Text variant="size-12" type="body-400" className="text-grey-600">No categories found.</Text>
              )}
        </TabsList>
      </div>
      <Separator />
      <div className="flex flex-col gap-2">
        <Text variant="size-12" type="body-400" className="uppercase text-grey-400">Objects Permission</Text>
        <Text variant="size-12" type="body-400" className="text-grey-600">No categories found.</Text>
      </div>
    </div>
  );
};
