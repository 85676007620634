import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DISTRICT_SETUP_STEP } from '@purple/shared-types';
import { AdminRoutes } from '~/constants';
import { useAppSelector } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { usePublishDistrict } from '~/services';
import { districtCurrentStepSelector } from '~/store/features/district-set-up';

export const useManagePublishDistrict = (districtId: number | null) => {
  const navigate = useNavigate();

  const { shouldShowUnsaved } = useUnsavedChanges();

  const { mutate: publishDistrict, isPending: isDistrictPublishing } = usePublishDistrict();

  const lastCompletedStep = useAppSelector(districtCurrentStepSelector);

  const [canPublish, publishMessage] = useMemo(() => {
    const isDistrictExist = Boolean(districtId);
    const isLastStep = lastCompletedStep === DISTRICT_SETUP_STEP.DATA_UPLOADS;

    const isAllow = isDistrictExist && !shouldShowUnsaved && isLastStep;

    let message = 'Click to publish district';

    if (!isDistrictExist) {
      message = 'District does not exist';
    } else if (!isLastStep) {
      message = 'Complete all required steps';
    } else if (shouldShowUnsaved) {
      message = 'There are unsaved changes';
    }

    return [isAllow, message];
  }, [districtId, lastCompletedStep, shouldShowUnsaved]);

  const publishHandler = () => {
    if (districtId) {
      publishDistrict({ districtId }, {
        onSuccess: () => {
          navigate(AdminRoutes.App.Districts.Root.path);
        },
      });
    }
  };

  return {
    publish: publishHandler,
    isPublishing: isDistrictPublishing,
    canPublish,
    publishMessage,
  };
};
