import { SchoolTabContainer } from '../../SchoolTabContainer';
import { SafDataChart } from './SafDataChart';
import { SafDataStats } from './SafDataStats';

export const SafInsightsTab: React.FC = () => {
  return (
    <SchoolTabContainer title="Data Insights" className="gap-6 px-4">
      <SafDataStats />
      <SafDataChart />
    </SchoolTabContainer>
  );
};
