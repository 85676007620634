import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { DistrictUserStatusToBadgeVariant, formateDateShortMonth, getGradeGroups, getGradeLabel, ReadableDistrictUserStatus } from '@purple/shared-utils';
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger, NumberBadge, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import { ListItemOptions } from './components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TUserManagement, TUsersDeactivateCheck } from '~/services';

type TUserManagementColumns = ColumnDef<TUserManagement>[];

type TUseUserManagementColumnsProperties = {
  onDeactivate: (checkResponse: TUsersDeactivateCheck) => void;
  onActivate: (user: TUserManagement) => void;
  onDelete: (user: TUserManagement) => void;
};

export const useUserManagementColumns = ({ onDeactivate, onActivate, onDelete }: TUseUserManagementColumnsProperties): TUserManagementColumns => {
  return [
    SelectableCell(),
    {
      accessorKey: 'full_name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to={AdminRoutes.App.Users.UserDetail.makePath({ dynamicParameters: { id: row.original.id } })}>
              <span className="text-brand-blue-700 line-clamp-1 break-all">{row.getValue('full_name')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('full_name')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 180,
      meta: { label: 'Full Name' },
    },
    {
      accessorKey: 'status',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      cell: ({ row }) =>
        row.original.status
          ? (
              <NumberBadge
                variant={DistrictUserStatusToBadgeVariant[row.original.status]}
                className="whitespace-nowrap rounded-full capitalize"
              >
                {ReadableDistrictUserStatus[row.original.status]}
              </NumberBadge>
            )
          : (
              '-'
            ),
      size: 100,
      meta: { className: 'text-center' },
      enableSorting: false,
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="text-grey-950 line-clamp-1 w-full break-all">
              {row.original.email || '-'}
            </Text>
          </TooltipTrigger>
          {row.original.email && (
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {row.original.email}
              </TooltipContent>
            </TooltipPortal>
          )}
        </Tooltip>
      ),
      size: 180,
      enableSorting: false,
    },
    {
      accessorKey: 'district',
      header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="text-grey-950 line-clamp-1 break-all capitalize">
              {row.original.district.join(', ') || '-'}
            </Text>
          </TooltipTrigger>
          {row.original.district?.length > 0 && (
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                {row.original.district.join('\n')}
              </TooltipContent>
            </TooltipPortal>
          )}
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'schools',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Schools" />,
      cell: ({ row }) => {
        const schools = row.original.schools.map((school) => school.name);

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Text type="body-500" variant="size-14" className="text-grey-950 line-clamp-1 break-all capitalize">
                {schools.join(', ') || '-'}
              </Text>
            </TooltipTrigger>
            {schools?.length > 0 && (
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                  {schools.join('\n')}
                </TooltipContent>
              </TooltipPortal>
            )}
          </Tooltip>
        );
      },
      size: 120,
    },
    {
      accessorKey: 'role',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="text-grey-950 line-clamp-1 break-all capitalize">
              {row.original.prounitas_roles.join(', ') || '-'}
            </Text>
          </TooltipTrigger>
          {row.original.prounitas_roles?.length > 0 && (
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false} className="whitespace-pre-wrap">
                {row.original.prounitas_roles.join('\n')}
              </TooltipContent>
            </TooltipPortal>
          )}
        </Tooltip>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'grades',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
      cell: ({ row }) => (
        <HoverCard>
          <HoverCardTrigger asChild>
            <NumberBadge variant="info" className="text-grey-950 col-span-1 place-self-center text-nowrap rounded-full px-3 text-center text-sm font-medium">
              {getGradeLabel(row.original.grades)}
            </NumberBadge>
          </HoverCardTrigger>
          {row.original.grades?.length > 0 && (
            <HoverCardPortal>
              <HoverCardContent
                onClick={(evt) => evt.stopPropagation()}
                className="border-grey-200 text-grey-950 inline-flex w-full max-w-64 justify-center whitespace-pre-wrap border p-2 text-xs font-medium leading-5"
              >
                {getGradeGroups(row.original.grades)}
              </HoverCardContent>
            </HoverCardPortal>
          )}
        </HoverCard>
      ),
      size: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'date_joined',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Text type="body-500" variant="size-14" className="text-grey-950 line-clamp-1 break-all capitalize">
              {formateDateShortMonth(row.original.date_joined) || '-'}
            </Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {format(parseISO(row.original.date_joined), 'MMMM dd, yyyy')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 120,
      meta: { label: 'Date Added' },
    },
    {
      id: 'options',
      cell: ({ row }) => (
        <ListItemOptions user={row.original} onActivate={() => onActivate(row.original)} onDeactivate={onDeactivate} onRemove={() => onDelete(row.original)} />
      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
