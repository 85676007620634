import { useWatch } from 'react-hook-form';
import { format, parse } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectTimeSection,
  Switch,
} from '@purple/ui';
import type { Control, FieldValues, Path } from 'react-hook-form';

type TBusinessHoursFieldItemProperties<TFormValues extends FieldValues = FieldValues> = {
  control: Control<TFormValues>;
  day: string;
  startTimeName: Path<TFormValues>;
  endTimeName: Path<TFormValues>;
  isActiveName: Path<TFormValues>;
};

export const BusinessHoursFieldItem = <TFormValues extends FieldValues = FieldValues>(
  props: TBusinessHoursFieldItemProperties<TFormValues>,
): JSX.Element => {
  const { control, startTimeName, day, endTimeName, isActiveName } = props;

  const isActive = useWatch({ control, name: isActiveName });

  return (
    <div className="flex items-start gap-2">
      <FormField
        control={control}
        name={isActiveName}
        render={({ field }) => (
          <FormItem className="flex min-h-10 flex-row items-center gap-4 space-y-0">
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormLabel className="text-base capitalize">{day}</FormLabel>
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name={startTimeName}
        render={({ field, fieldState }) => (
          <FormItem className="ml-auto flex basis-[124px] flex-col">
            <Popover>
              <FormControl>
                <div className="relative flex">
                  <PurpleIcon
                    name="clock"
                    className="absolute left-3 top-1/2 size-4 shrink-0 -translate-y-1/2 text-grey-950"
                  />
                  <PopoverTrigger asChild>
                    <Input
                      {...field}
                      disabled={!isActive}
                      value={format(parse(field.value, 'HH:mm:ss', new Date()), 'hh:mm a')}
                      type="text"
                      readOnly
                      placeholder="Enter start time"
                      isError={!!fieldState.error}
                      containerClassName="w-full"
                      className="w-full min-w-24 max-w-[124px] shrink grow px-3 py-1.5 pl-9"
                    />
                  </PopoverTrigger>
                </div>
              </FormControl>
              <PopoverContent align="end" className="flex w-auto gap-1 p-3">
                <SelectTimeSection
                  date={parse(field.value, 'HH:mm:ss', new Date())}
                  setDate={(date) => (date ? field.onChange(format(date, 'HH:mm:ss')) : field.value)}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name={endTimeName}
        render={({ field, fieldState }) => (
          <FormItem className="flex basis-[124px] flex-col">
            <Popover>
              <FormControl>
                <div className="relative flex">
                  <PurpleIcon
                    name="clock"
                    className="absolute left-3 top-1/2 size-4 shrink-0 -translate-y-1/2 text-grey-950"
                  />
                  <PopoverTrigger asChild>
                    <Input
                      {...field}
                      value={format(parse(field.value, 'HH:mm:ss', new Date()), 'hh:mm a')}
                      disabled={!isActive}
                      type="text"
                      readOnly
                      placeholder="Enter end time"
                      isError={!!fieldState.error}
                      containerClassName="w-full"
                      className="w-full min-w-24 max-w-[124px] shrink grow px-3 py-1.5 pl-9"
                    />
                  </PopoverTrigger>
                </div>
              </FormControl>
              <PopoverContent align="end" className="flex w-auto gap-1 p-3">
                <SelectTimeSection
                  date={parse(field.value, 'HH:mm:ss', new Date())}
                  setDate={(date) => (date ? field.onChange(format(date, 'HH:mm:ss')) : field.value)}
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
