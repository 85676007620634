import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AppLoaderScreen, Step, Stepper } from '@purple/ui';
import { useAppDispatch, useAppSelector } from '~/hooks/redux/useRedux';
import { useUnsavedChanges } from '~/providers';
import { useDraftDistrictDetail } from '~/services';
import { maxDistrictStepSelector, resetDistrictSetUp } from '~/store/features/district-set-up';
import { StepContainer } from './components';
import { DISTRICT_ID_QUERY_NAME, SET_UP_STEP_LABELS, SET_UP_STEPS } from './constants';

const DistrictSetUpPage = () => {
  const dispatch = useAppDispatch();

  const { checkUnsaved } = useUnsavedChanges();

  const [searchParameters] = useSearchParams();
  const districtId = searchParameters.get(DISTRICT_ID_QUERY_NAME);

  const maxStep = useAppSelector(maxDistrictStepSelector);

  const { isLoading } = useDraftDistrictDetail(districtId ? +districtId : null);

  useEffect(() => {
    return () => {
      dispatch(resetDistrictSetUp());
    };
  }, [dispatch]);

  if (districtId && isLoading) {
    return <AppLoaderScreen />;
  }

  const stepClickHandler = (step: number, setStep: (newStep: number) => void) => {
    if (maxStep >= step) {
      checkUnsaved(() => setStep(step));
    }
  };

  return (
    <div className="flex h-screen w-full flex-col items-center bg-grey-50">
      <div className="flex w-full max-w-[1440px] flex-1 px-[28px] pb-8 pt-[102px]">
        <Stepper
          initialStep={maxStep}
          steps={SET_UP_STEPS}
          variant="circle-alt"
          responsive
          styles={{ 'main-container': 'max-w-[228px]' }}
          className="h-fit"
          orientation="vertical"
          onClickStep={stepClickHandler}
        >
          {SET_UP_STEPS.map(({ id }, index) => (
            <Step key={id} label={SET_UP_STEP_LABELS[id]} id={id} isCompletedStep={maxStep > index} />
          ))}
          <StepContainer />
        </Stepper>
      </div>
    </div>
  );
};

export { DistrictSetUpPage };
