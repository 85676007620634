import axiosInstance from '../axios-config';
import { BANNERS_ENDPOINTS } from '../endpoints';
import type { TBannerCreatePayload } from './banners.types';

export const createBanner = async ({ status, ...payload }: TBannerCreatePayload) => {
  const response = await axiosInstance.post<TBannerCreatePayload>(BANNERS_ENDPOINTS.ROOT, payload, {
    params: { status },
  });
  return response.data;
};
