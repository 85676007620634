import { type FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { isFieldExist, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, Text } from '@purple/ui';
import type { TActionDetails } from '@purple/shared-types';

type TSectionContentProperties = {
  action: TActionDetails;
};

export const DetailsContent: FC<TSectionContentProperties> = memo(({ action }) => {
  const { details } = action;
  return (
    <DescriptionList>
      {isFieldExist(details.student) && (
        <DescriptionItem>
          <DescriptionTerm>Student</DescriptionTerm>
          <DescriptionDetails>
            {/* TODO: Add link to the student details */}
            <Button variant="link" className="p-0" asChild>
              <Link to="#" className="text-base font-medium text-brand-blue-700 transition-colors hover:text-brand-blue-800">
                {details.student.full_name}
              </Link>
            </Button>
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.school) && (
        <DescriptionItem>
          <DescriptionTerm>School</DescriptionTerm>
          <DescriptionDetails>
            {details.school && details.school.name.length > 0
              ? (
                  <Button variant="link" className="p-0" asChild>
                    <Link to="#" className="text-base font-medium text-brand-blue-700 transition-colors hover:text-brand-blue-800">
                      {details.school.name}
                    </Link>
                  </Button>
                )
              : <Text>—</Text>}

          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.service_provider) && (
        <DescriptionItem>
          <DescriptionTerm>Service Provider</DescriptionTerm>
          <DescriptionDetails>
            {/* TODO: change link path to actual service provider page */}
            <Button variant="link" className="p-0" asChild>
              <Link to="#" className="text-base font-medium text-brand-blue-700 transition-colors hover:text-brand-blue-800">{details.service_provider.name}</Link>
            </Button>
          </DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.document_as) && (
        <DescriptionItem>
          <DescriptionTerm>Document as</DescriptionTerm>
          <DescriptionDetails>{snakeToCamelWithSpaces(details.document_as)}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.type) && (
        <DescriptionItem>
          <DescriptionTerm>Type</DescriptionTerm>
          <DescriptionDetails>{snakeToCamelWithSpaces(details.type)}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.subtype) && (
        <DescriptionItem>
          <DescriptionTerm>Subtype</DescriptionTerm>
          <DescriptionDetails>{snakeToCamelWithSpaces(details.subtype)}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.number_of_resources_selected) && (
        <DescriptionItem>
          <DescriptionTerm>Number of Resources</DescriptionTerm>
          <DescriptionDetails>{details.number_of_resources_selected}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.focus_areas) && (
        <DescriptionItem>
          <DescriptionTerm>Focus Areas</DescriptionTerm>
          <DescriptionDetails className="max-w-[300px] text-right">{details.focus_areas.map((area) => snakeToCamelWithSpaces(area.name)).join(', ')}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.service_categories) && (
        <DescriptionItem>
          <DescriptionTerm>Service Categories</DescriptionTerm>
          <DescriptionDetails className="max-w-[300px] text-right">{details.service_categories.map((category) => snakeToCamelWithSpaces(category.name)).join(', ')}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.quantity) && (
        <DescriptionItem>
          <DescriptionTerm>Quantity</DescriptionTerm>
          <DescriptionDetails>{details.quantity}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.created_by) && (
        <DescriptionItem>
          <DescriptionTerm>Created By</DescriptionTerm>
          <DescriptionDetails>{details.created_by && details.created_by.full_name ? details.created_by.full_name : '—'}</DescriptionDetails>
        </DescriptionItem>
      )}
      {isFieldExist(details.saf) && (
        <DescriptionItem>
          <DescriptionTerm>Linked SAF</DescriptionTerm>
          <DescriptionDetails>
            {details.saf && details.saf.saf_number && details.saf.saf_number.length > 0
              ? (
                // TODO: Add link to the SAF details
                  <Link
                    to="#"
                    className="cursor-pointer truncate font-primary text-brand-blue-700 hover:underline hover:underline-offset-2"
                  >
                    {details.saf?.saf_number}
                  </Link>
                )
              : (
                  '—'
                )}
          </DescriptionDetails>
        </DescriptionItem>
      )}
    </DescriptionList>
  );
});
