import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import { userDelete } from './user-delete';

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.DELETE_USER],
    mutationFn: userDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_DETAILS] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_BASIC_DETAILS] });
      showSuccessToast('System message', 'User was successfully deleted');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to delete user. Please try again later');
    },
  });
};
