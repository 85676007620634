import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ADMIN_AUTH_QUERY_KEYS } from './auth.const';
import { adminResetPassword } from './password';

export const useResetPassword = () => {
  return useMutation({
    mutationKey: [ADMIN_AUTH_QUERY_KEYS.RESET_PASSWORD],
    mutationFn: adminResetPassword,
    onError: () => {
      showErrorToast(
        'System message',
        'Could not send reset link to provided email. Check the provided information and try again',
      );
    },
  });
};
