import axiosInstance from '../axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TUploadMappingFilePayload } from './districts.types';

export const uploadMappingFile = async ({ districtId, mappingId, ...rest }: TUploadMappingFilePayload) => {
  const formData = new FormData();

  for (const file of rest.uploaded_file) {
    formData.append('uploaded_file', file);
  }

  const response = await axiosInstance.patch<void>(
    DISTRICTS_ENDPOINTS.UPLOAD_MAPPING_FILE(districtId.toString(), mappingId.toString()),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response;
};
