import { useMemo } from 'react';
import { SAF_RESOLUTION } from '@purple/shared-types';
import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Form, FormControl, FormField, FormItem, MultiSelect, MultiSelectItem } from '@purple/ui';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { safResolutionSchema } from './safResolutionSchema';

type TResolutionEditProperties = {
  form: UseFormReturn<z.infer<typeof safResolutionSchema>>;
};

const ResolutionEdit = ({ form }: TResolutionEditProperties) => {
  const allOptions = useMemo(() => {
    return Object.values(SAF_RESOLUTION).map((item) => ({
      label: snakeCaseToCapitalized(item),
      value: item,
    }));
  }, []);

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <FormField
          control={form.control}
          name="resolution"
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormControl>
                <MultiSelect
                  {...field}
                  isError={!!fieldState.error}
                  selectedOptions={allOptions.filter((item) => field.value.includes(item.value))}
                  modalPopover
                  placeholder="Select resolution(s)"
                  showSearch
                  shouldFilter={false}
                  onOptionChange={field.onChange}
                >
                  {allOptions.map((option) => (
                    <MultiSelectItem
                      key={option.value}
                      value={option.value}
                      option={option}
                      isSelected={field.value.includes(option.value)}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
};

export { ResolutionEdit };
