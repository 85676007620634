import { forwardRef, useMemo } from 'react';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Text } from '@purple/ui';
import { ModalType, VisualizerStep, VisualizerStepDescription, VisualizerStepReadable } from '~/constants';
import { useModal } from '~/hooks';
import type { TVisualizerStep } from '~/constants';

export const VisualizerStepHeader = forwardRef<HTMLDivElement>((_, ref) => {
  const { openModal: openFlagCalculationModal } = useModal(ModalType.FLAG_CALCULATION);

  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const step = useMemo(() => stepQuery
    ? Object.values(VisualizerStep).find((step) => step === stepQuery) ?? null
    : null, [stepQuery]);

  const isColorTrendsStep = useMemo(() => step === VisualizerStep.COLOR_TRENDS, [step]);

  const title = useMemo(() => step
    ? VisualizerStepReadable[step] ?? null
    : null, [step]);
  const description = useMemo(() => step
    ? VisualizerStepDescription[step] ?? null
    : null, [step]);

  if (step === null) return null;

  return (
    <div ref={ref} className="flex w-full items-center justify-between gap-4 px-6 pb-4 pt-6">
      <div className="flex flex-col gap-1">
        <Heading tag="h3" type="heading-500" variant="size-18" className="text-grey-title">
          {title}
        </Heading>
        <Text type="body-400" variant="size-14" className="text-grey-700">
          {description}
        </Text>
      </div>
      {isColorTrendsStep && (
        <Button type="button" variant="secondary" iconLeft={<PurpleIcon name="plus" />} onClick={openFlagCalculationModal}>
          Add Flag Calculation
        </Button>
      )}
    </div>
  );
});
