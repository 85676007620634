import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { CONTACTS_QUERY_KEYS } from './contacts.const';
import { getContactsList } from './get-contacts-list';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TContactListResponse, TContactsListRequestParameters } from './contacts.types';

export const useContacts = (
  parameters?: TContactsListRequestParameters,
  queryOptions?: TCustomUseQueryOptions<TContactListResponse, AxiosError>,
) => {
  const { isError, ...rest } = useQuery<TContactListResponse, AxiosError>({
    queryKey: [CONTACTS_QUERY_KEYS.GET_CONTACTS_LIST, parameters],
    queryFn: () => getContactsList(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch list of contacts');
    }
  }, [isError]);

  return { isError, ...rest };
};
