import { MainContainer } from '~/layout';
import { GroupPermissionsContent, ManageGroupHeader } from './components';
import { GroupPermissionStepper } from './stepper';

export const ManagePermissionsGroupsPage = () => {
  return (
    <MainContainer>
      <ManageGroupHeader />
      <GroupPermissionStepper.Scoped>
        <GroupPermissionsContent />
      </GroupPermissionStepper.Scoped>
    </MainContainer>
  );
};
