import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DASHBOARD_QUERY_KEYS } from './dashboard.const';
import { getTopStatistic } from './get-top-statistic';
import type { AxiosError } from 'axios';
import type { TDashboardBaseRequestParameters, TDashboardTopStatisticResponse } from './dashboard.types';

export const useDashboardTopStatistic = (parameters?: TDashboardBaseRequestParameters) => {
  const { isError, error, ...queryData } = useQuery<
    TDashboardTopStatisticResponse,
    AxiosError,
    TDashboardTopStatisticResponse
  >({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_TOP_STATISTIC, parameters],
    queryFn: () => getTopStatistic(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Failed to fetch top statistic data');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
