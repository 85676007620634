import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { createDistrictNavigationPage } from './create-district-navigation-page';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError } from '@purple/shared-services';
import type { TDistrictCustomPage } from '@purple/shared-types';
import type { TCreateDistrictCustomPagePayload } from './districts.types';

export const useCreateCustomPage = () => {
  const queryClient = useQueryClient();

  return useMutation<TDistrictCustomPage, AxiosError, TCreateDistrictCustomPagePayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.CREATE_DISTRICT_CUSTOM_PAGE],
    mutationFn: createDistrictNavigationPage,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_CUSTOM_PAGES, data.district.toString()],
      });
      showSuccessToast('System message', 'District custom page was created successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create district custom page. Please try again later');
    },
  });
};
