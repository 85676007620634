import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafEmail } from './get-saf-email';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TSafCorrespondence } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

type TUseSafEmailParameters = {
  parameters: { safId: string; emailId: string };
  queryOptions?: TCustomUseQueryOptions<TSafCorrespondence, AxiosError>;
};

export const useSafEmail = ({ parameters, queryOptions }: TUseSafEmailParameters) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [ADMIN_SAF_QUERY_KEYS.SAF_EMAIL_DETAIL, parameters],
    queryFn: () => getSafEmail(parameters.safId, parameters.emailId),
    enabled: !!parameters.safId && !!parameters.emailId && queryOptions?.enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve SAF email details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
