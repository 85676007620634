import { useMemo } from 'react';
import { Badge, cn, Text } from '@purple/ui';

type TSelectedItem = {
  id: string;
  name: string;
};

type TSelectAllEntitiesRowProperties = React.PropsWithChildren<{
  totalCount: number;
  selectedItems: TSelectedItem[];
  allItemsOnPage: TSelectedItem[];
  onSelectAll: () => void;
  isSelectingAll: boolean;
  entityName: string;
}>;

const SelectAllEntitiesRow = ({
  totalCount,
  selectedItems,
  allItemsOnPage,
  onSelectAll,
  isSelectingAll,
  entityName,
}: TSelectAllEntitiesRowProperties) => {
  const isAllItemsOnPageSelected = useMemo(
    () =>
      allItemsOnPage.length > 0
      && allItemsOnPage.every((item) => selectedItems.some((selected) => selected.id === item.id) || false),
    [allItemsOnPage, selectedItems],
  );

  const isAllItemsSelected = useMemo(
    () => totalCount === selectedItems.length,
    [totalCount, selectedItems],
  );

  const label = useMemo(() => `All ${allItemsOnPage.length} ${entityName} on this page are selected`, [allItemsOnPage.length, entityName]);

  if (allItemsOnPage.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {isAllItemsOnPageSelected && (
        <Text variant="size-14" type="body-500" className="text-brand-blue-700">
          {label}
        </Text>
      )}
      {!isAllItemsSelected && (
        <Badge
          label={`Select All Found (${totalCount})`}
          onClick={onSelectAll}
          className={cn('bg-brand-blue-100 text-brand-blue-700', {
            'cursor-default text-transparent opacity-50': isSelectingAll,
          })}
          isLoading={isSelectingAll}
        />
      )}
    </div>
  );
};

export { SelectAllEntitiesRow };
