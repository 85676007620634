import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { BasicDetails, Notes } from './Tabs';
import type { TAppTab } from '@purple/ui';

export const useActionsDetailsTabs = () => {
  const actionsDetailsTabs: TAppTab[] = useMemo(() => [
    {
      label: 'Basic Details',
      value: 'basic-details',
      content: <BasicDetails />,
      permissions: [],
    },
    {
      label: 'Notes',
      value: 'notes',
      content: <Notes />,
      permissions: [],
    },
  ], []);

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: actionsDetailsTabs,
  });

  return {
    activeTab,
    tabChangeHandler,
    actionsDetailsTabs,
  };
};
