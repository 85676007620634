import { PurpleIcon } from '@purple/icons';
import { Button, cn, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { useSidebar } from '~/hooks';
import type React from 'react';

export const SidebarToggle: React.FC = () => {
  const { isOpen, toggleSidebar } = useSidebar();

  return (
    <div className="invisible absolute -right-3 top-6 z-20 leading-[0] lg:visible">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            type="button"
            onClick={() => toggleSidebar(!isOpen)}
            className="size-6 rounded p-0"
            variant="primary"
            size="icon_32"
            iconLeft={(
              <PurpleIcon
                name="chevron-left"
                className={cn(
                  'h-5 w-5 transition-transform duration-700 ease-in-out',
                  isOpen ? 'rotate-0' : 'rotate-180',
                )}
              />
            )}
          />
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>{isOpen ? 'Collapse Sidebar' : 'Expand Sidebar'}</TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </div>
  );
};
