import type { ChartConfig } from '@purple/ui';

export const barChartConfig = {
  desktop: {
    color: '#49BEA7',
  },
  users: {
    label: 'Amount of Users:',
  },
} satisfies ChartConfig;

export const loggedLineChartConfig = {
  label: {
    color: '#F89B3F',
  },
  value: {
    label: 'Logged In Users:',
  },
} satisfies ChartConfig;

export const actionsLineChartConfig = {
  label: {
    color: '#8BC34A',
  },
  value: {
    label: 'Documented Actions:',
  },
} satisfies ChartConfig;
