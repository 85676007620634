import type { TLocation } from '@purple/shared-types';

/**
 * Generates a readable address string from a given location object.
 *
 * @param location - An optional partial location object containing street, city, state, and postal_code.
 * @param fallback - An optional fallback string to return if no location is provided.
 * @returns A string representing the readable address. If no location is provided or if all fields are empty, returns 'No address provided'.
 */
export const getReadableAddress = (location?: Partial<TLocation> | null, fallback?: string) => {
  if (!location) return fallback;

  const { street, city, state, postal_code } = location ?? {};

  const address: string[] = [];

  if (street) address.push(street);
  if (city) address.push(city);
  if (state) address.push(state);
  if (postal_code) address.push(postal_code);

  if (!address.length) return fallback;

  return address.join(', ');
};

/**
 * Converts a decimal coordinate to Degrees, Minutes, and Seconds (DMS) format.
 *
 * @param coordinate - The decimal coordinate to convert.
 * @returns A string representing the coordinate in DMS format.
 */
export const convertToDMS = (coordinate: number) => {
  const degrees = Math.floor(coordinate); // Extract the degrees
  const minutesFloat = (coordinate - degrees) * 60; // Calculate minutes
  const minutes = Math.floor(minutesFloat);
  const seconds = Math.round((minutesFloat - minutes) * 60);

  return `${degrees}° ${minutes}′ ${seconds}″`;
};
