import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Badge, cn, Text } from '@purple/ui';
import { UserLoginHistoryStatus } from '~/services';

type TStatusColumnProperties = {
  status: string;
};

const StatusColumn = ({ status }: TStatusColumnProperties) => {
  if (status) {
    return (
      <Badge
        label={snakeToCamelWithSpaces(status)}
        className={cn({
          'bg-success-bg text-success-main': status === UserLoginHistoryStatus.SUCCESSFUL,
          'bg-error-bg text-error-main': status === UserLoginHistoryStatus.ERROR,
        })}
      />
    );
  }
  return (
    <Text variant="size-14" type="body-500" className="text-grey-960">
      -
    </Text>
  );
};

export { StatusColumn };
