import { Skeleton } from '@purple/ui';

export const DistrictBrandingSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[79px]" />
      </div>
      <div className="flex w-full flex-col gap-4 px-6 py-8">
        <div className="flex w-full flex-col items-start justify-between gap-4 rounded-lg border border-grey-100 p-4 sm:flex-row sm:items-center">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-6 w-[40px]" />
            <div className="flex items-center gap-2">
              <Skeleton className="size-[40px] rounded-full" />
              <Skeleton className="h-5 w-[58px]" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="h-10 w-[100px]" />
            <Skeleton className="size-10" />
          </div>
        </div>
        <div className="flex w-full flex-col items-start justify-between gap-4 rounded-lg border border-grey-100 p-4 sm:flex-row sm:items-center">
          <div className="flex flex-col gap-2">
            <Skeleton className="h-6 w-[120px]" />
            <div className="flex min-h-10 items-center gap-2">
              <Skeleton className="size-[20px] rounded-full" />
              <Skeleton className="h-5 w-[64px]" />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Skeleton className="size-10" />
          </div>
        </div>
      </div>
    </div>
  );
};
