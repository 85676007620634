import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMask } from '@react-input/mask';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import { CONTAIN_DIGIT_REGEX, convertToDMS, getReadableAddress, mergeReferences, zPhoneNumber } from '@purple/shared-utils';
import {
  Button,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { AddressComboBox, GoogleMap } from '~/components';
import { PHONE_MASK } from '~/constants';
import { useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const addressSchema = z
  .object({
    website: z
      .string()
      .max(255, 'Website URL is too long')
      .refine((value) => value === '' || z.string().url().safeParse(value).success, {
        message: 'Website must be a valid URL or an empty string',
      })
      .optional(),
    phone: z.string().trim().optional(),
    location: z.object({
      street: z.string().trim(),
      city: z.string().trim(),
      state: z.string().trim(),
      postal_code: z.string().trim(),
      latitude: z.number().transform((value) => Number(value.toFixed(6))),
      longitude: z.number().transform((value) => Number(value.toFixed(6))),
    }),
  })
  .superRefine(async (data, ctx) => {
    if (data.phone && data.phone.length > 0) {
      const phone = await zPhoneNumber.parseAsync(data.phone, { path: ['phone'] });
      if (phone === null) {
        ctx.addIssue({
          path: ['phone'],
          code: z.ZodIssueCode.custom,
          message: 'Invalid phone number.',
        });
      }
    }
  });

  type TAddressSectionProps = {
    data: TSchoolBasicDetails;
  };

export const AddressSection: React.FC<TAddressSectionProps> = (props) => {
  const { data } = props;

  const inputReference = useMask({
    mask: PHONE_MASK,
    replacement: { _: CONTAIN_DIGIT_REGEX },
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();

  const defaultValues: z.infer<typeof addressSchema> = useMemo(
    () => ({
      website: data.website ?? '',
      phone: data.phone ?? '',
      location: {
        city: data.address?.city ?? '',
        state: data.address?.state ?? '',
        postal_code: data.address?.postal_code ?? '',
        latitude: data.address?.latitude ?? 0,
        longitude: data.address?.longitude ?? 0,
        street: data.address?.street ?? '',
      },
    }),
    [data],
  );

  const form = useForm<z.infer<typeof addressSchema>>({
    resolver: zodResolver(addressSchema),
    mode: 'onChange',
    defaultValues,
  });
  const location = form.watch('location');
  const readableAddress = useMemo(() => getReadableAddress(data.address, '-'), [data.address]);

  const isMapAvailable = useMemo(() => typeof location?.latitude === 'number' && typeof location?.longitude === 'number', [location]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof addressSchema>) => {
    updateSchool(
      {
        id: data.id,
        website: formData.website,
        phone: formData.phone,
        address: formData.location,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Address & Contact Info"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="location"
                render={({ field }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Address
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <AddressComboBox
                          location={field.value}
                          onChange={field.onChange}
                          triggerProps={{ className: 'h-9' }}
                          contentProps={{ className: 'min-w-80', align: 'end' }}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Phone
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input
                          {...field}
                          ref={mergeReferences([field.ref, inputReference])}
                          isError={!!fieldState.error}
                          placeholder={PHONE_MASK}
                          type="tel"
                          className="h-9"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="website"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Website
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input {...field} isError={!!fieldState.error} placeholder="Enter website url" className="h-9" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <DescriptionItem tag="div" className="flex-nowrap">
                <DescriptionTerm tag="p" className="w-1/2 shrink-0">
                  Latitude Coordinate
                </DescriptionTerm>
                <DescriptionDetails tag="strong" className="w-1/2 leading-3">
                  {data.address?.latitude ? convertToDMS(data.address.latitude) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem tag="div" className="flex-nowrap">
                <DescriptionTerm tag="p" className="w-1/2 shrink-0">
                  Longitude Coordinate
                </DescriptionTerm>
                <DescriptionDetails tag="strong" className="w-1/2 leading-3">
                  {data.address?.longitude ? convertToDMS(data.address.longitude) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Address
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {readableAddress}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Phone
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.phone || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Website
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2 leading-3">
                  {typeof data.website === 'string' && data.website !== ''
                    ? (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              asChild
                              variant="link"
                              className="inline-flex h-auto max-w-48 justify-start gap-1 p-0"
                              iconLeft={<PurpleIcon name="external-link" className="shrink-0" />}
                            >
                              <a href={data.website} target="_blank" rel="noreferrer">
                                <span className="line-clamp-1">
                                  {new URL(data.website).hostname}
                                </span>
                              </a>
                            </Button>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent>
                              {data.website}
                            </TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      )
                    : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Latitude Coordinate
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.address?.latitude ? convertToDMS(data.address.latitude) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Longitude Coordinate
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.address?.longitude ? convertToDMS(data.address.longitude) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
      {isMapAvailable && <GoogleMap location={location} mapContainerClassName="rounded-lg" />}
    </div>
  );
};
