import { defineStepper } from '@stepperize/react';
import type { ValueOf } from '@purple/shared-types';

export const GroupPermissionStep = {
  GROUP_INFORMATION: 'group_information ',
  MANAGE_PERMISSIONS: 'manage_permissions',
  ADD_USERS: 'add_users',
} as const;
export type TGroupPermissionStep = ValueOf<typeof GroupPermissionStep>;

export const GroupPermissionSteps = [
  { id: GroupPermissionStep.GROUP_INFORMATION, title: 'Group Information' },
  { id: GroupPermissionStep.MANAGE_PERMISSIONS, title: 'Manage Permissions' },
  { id: GroupPermissionStep.ADD_USERS, title: 'Add Users' },
] as const;

export const StepperProgressStepsLabels = {
  [GroupPermissionStep.GROUP_INFORMATION]: 'Set Up Group Information',
  [GroupPermissionStep.MANAGE_PERMISSIONS]: 'Manage Permissions',
  [GroupPermissionStep.ADD_USERS]: 'Add Users to New Group',
} as const;

export const GroupPermissionStepper = defineStepper(...GroupPermissionSteps);
