import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { ROLE_PARAMETERS_KEY } from '../../constants';

type TManageRolesHeaderProperties = {
  fromId: string;
  isLoading?: boolean;
};

export const ManageRolesHeader = (props: TManageRolesHeaderProperties) => {
  const { fromId, isLoading } = props;
  const navigate = useNavigate();

  const navigateToRolesListHandler = useCallback(() => navigate(AdminRoutes.App.Users.Roles.Root.path), [navigate]);

  const { query: roleId } = useQueryParameter({ queryName: ROLE_PARAMETERS_KEY.roleId });

  const createButtonLabel = useMemo(() => isLoading ? 'Creating...' : 'Create', [isLoading]);
  const updateButtonLabel = useMemo(() => isLoading ? 'Updating...' : 'Update', [isLoading]);

  return (
    <div className="flex items-center justify-between gap-2 border-b border-b-grey-300 bg-white px-8 py-3.5">
      <div className="flex items-center gap-2">
        <Button variant="tertiary_icon_only" size="icon_32" onClick={navigateToRolesListHandler} iconLeft={<PurpleIcon name="chevron-left" />} />
        <Text variant="size-16" type="body-600">{roleId ? 'Update Custom Role' : 'Create Custom Role'}</Text>
      </div>
      <Button type="submit" variant="primary" form={fromId} isLoading={isLoading}>
        {roleId && roleId.length > 0 ? updateButtonLabel : createButtonLabel}
      </Button>
    </div>
  );
};
