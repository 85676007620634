import { useState } from 'react';
import { NumberBadge } from '@purple/ui';
import type { TCustomTag } from '@purple/shared-types';

const DEFAULT_AMOUNT_OF_VISIBLE_TAGS = 4;

type TSchoolTagsProps = {
  tags: TCustomTag[];
};

export const SchoolTags: React.FC<TSchoolTagsProps> = (props) => {
  const { tags } = props;

  const [visibleTagsAmount, setVisibleTagsAmount] = useState<number>(DEFAULT_AMOUNT_OF_VISIBLE_TAGS);

  const tagViewClickHandler = () => {
    setVisibleTagsAmount(
      (prev) => (prev === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
        ? tags.length
        : DEFAULT_AMOUNT_OF_VISIBLE_TAGS),
    );
  };

  if (tags.length === 0) {
    return null;
  }

  return (
    <ul className="flex flex-wrap items-center justify-start gap-2">
      {tags
        .slice(0, visibleTagsAmount)
        .map((tag) => (
          <li key={tag.name}>
            <NumberBadge variant="info" className="line-clamp-1 h-auto min-h-7 min-w-0 break-all rounded-full py-0.5">
              {tag.name}
            </NumberBadge>
          </li>
        ))}
      {tags.length > DEFAULT_AMOUNT_OF_VISIBLE_TAGS && (
        <li>
          <button
            type="button"
            className="inline-flex h-7 cursor-pointer items-center justify-center px-2 py-0.5 text-sm font-semibold text-brand-blue-700 outline-none transition-colors duration-200 hover:text-brand-blue-600 focus:text-brand-blue-600 focus-visible:text-brand-blue-600 active:text-brand-blue-800 active:transition-none"
            onClick={tagViewClickHandler}
          >
            {visibleTagsAmount === DEFAULT_AMOUNT_OF_VISIBLE_TAGS
              ? 'View All'
              : 'Show Less'}
          </button>
        </li>
      )}
    </ul>
  );
};
