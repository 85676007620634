/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const PullDatesSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      {Array.from({ length: 5 }).map((_, index) => (
        <div className="flex w-full max-w-[524px] flex-col items-center justify-start gap-3" key={index}>
          <div className="flex w-full items-center justify-between gap-1">
            <Skeleton className="h-5 w-full max-w-[60px]" />
            {index === 0 && (
              <Skeleton className="h-5 w-full max-w-[82px]" />
            )}
          </div>
          <div className="flex w-full items-center gap-3">
            <Skeleton className="h-5 w-full max-w-[14px]" />
            <Skeleton className="h-10 w-full max-w-[420px]" />
            <Skeleton className="size-5" />
            <Skeleton className="size-5" />
          </div>
        </div>
      ))}
      <Skeleton className="h-8 w-full max-w-[144px]" />
    </div>
  );
};
