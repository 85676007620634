import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/utils/toasts';
import { getNoteById } from './get-note-by-id';
import type { AxiosError } from 'axios';
import type { TNote } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

type TUseNoteProperties = {
  noteId: string;
  // queryOptions?: Omit<Partial<UseQueryOptions<TNote, AxiosError>>, 'queryKey' | 'queryFn'>;
  queryOptions?: TCustomUseQueryOptions<TNote, AxiosError>;
};

export const useNote = ({ noteId, queryOptions }: TUseNoteProperties) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [NOTE_QUERY_KEYS.CREATE_NOTE, noteId],
    queryFn: () => getNoteById(noteId),
    enabled: !!noteId && queryOptions?.enabled,

  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve note detail information');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
