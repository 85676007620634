import { useCallback } from 'react';
import { StepItem } from '~/components';
import { GroupPermissionStepper } from '../../stepper';
import type { FC } from 'react';
import type { TGroupPermissionStep } from '../../stepper';

type TGroupStepperNavigationProps = {
  onStepClick?: (cb: (stepId: TGroupPermissionStep) => void, stepId: TGroupPermissionStep) => void;
};

export const GroupStepperNavigation: FC<TGroupStepperNavigationProps> = ({ onStepClick }) => {
  const stepper = GroupPermissionStepper.useStepper();

  const stepClickHandler = useCallback((stepId: string) => {
    onStepClick?.(stepper.goTo, stepId as TGroupPermissionStep);
  }, [onStepClick, stepper]);

  return (
    <ul className="flex flex-col items-center gap-12">
      {stepper.all.map(({ id, title }, index) => (
        <li key={id} className="relative flex w-full">
          <StepItem
            value={id}
            label={title}
            sequenceNumber={index + 1}
            active={stepper.current.id === id}
            completed={stepper.all.findIndex((step) => step.id === id) < stepper.all.findIndex((step) => step.id === stepper.current.id)}
            showDivider={index !== stepper.all.length - 1}
            dividerOrientation="vertical"
            orientation="horizontal"
            onStepClick={stepClickHandler}
          />
        </li>
      ))}
    </ul>
  );
};
