export const ASSIGN_OPTIONS = {
  CONTACT: 'Contact',
  ROLE: 'Role',
} as const;

export const ASSIGN_OPTIONS_VALUES = {
  CONTACT: 'contact',
  ROLE: 'role',
} as const;

export const TEST_ROUTE_TO_OPTIONS = Object.entries(ASSIGN_OPTIONS).map(([value, label]) => ({
  value: value.toLowerCase(),
  label,
}));
