import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafsListRequestParameters, TAdminSafsListResponse } from './saf.types';

export const getSafsList = async (parameters: TAdminSafsListRequestParameters) => {
  const response = await axiosInstance.get<TAdminSafsListResponse>(SAF_ENDPOINTS.LIST, {
    params: parameters,
  });
  return response.data;
};
