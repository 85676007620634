import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TGradeLevelsSchema } from './gradeLevelsSchema';

export const GradeLevelsForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Non-Numerical (Levels) Grades Metrics
      </Heading>
      <FloatNumberField<TGradeLevelsSchema> name="level1" label="Level 1" />
      <FloatNumberField<TGradeLevelsSchema> name="level2" label="Level 2" />
      <NumberOfMaxFlagsField />
      <BaselineField />
    </div>
  );
};
