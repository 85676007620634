import { z } from 'zod';

export const dataPointsSchema = z.object({
  points: z.array(
    z.object({
      id: z.number(),
      column_header_name: z.string(),
      is_displayed: z.boolean(),
      is_default: z.boolean(),
      order_number: z.number(),
      alias: z.string({ invalid_type_error: 'Alias must be a string' }).trim().min(1, { message: 'Alias is required' }),
    }),
  ),
}).superRefine((data, context) => {
  // Check if there are any duplicate of alias
  for (const [index, point] of data.points.entries()) {
    const otherAliases = [...data.points.slice(0, index), ...data.points.slice(index + 1)];
    if (otherAliases.some((p) => p.alias.trim().toLowerCase() === point.alias.trim().toLowerCase())) {
      context.addIssue({
        path: ['points', index, 'alias'],
        code: z.ZodIssueCode.custom,
        message: 'Alias must be unique',
      });
    }
  }
});
