import { useParams } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { Message, Text } from '@purple/ui';
import { useIndividualActionDetails } from '~/services';
import { ActionTabContainer, IndividualDetails } from '../components';
import { BasicDetailsTabSkeleton } from '../components/BasicDetailsTabSkeleton';
import { ActionsDetailsQueryKeys, ActionsDetailsQueryValues } from '../constants';

export const BasicDetails = () => {
  const { actionId } = useParams();

  const { query: actionType } = useQueryParameter({
    queryName: ActionsDetailsQueryKeys.actionType,
  });

  const { data: action, isLoading } = useIndividualActionDetails({ actionId: actionId! });

  const isGroupAction = actionType === ActionsDetailsQueryValues.groupAction;

  return (
    <ActionTabContainer
      title="Basic Details"
      actions={
        isGroupAction && !isLoading && (
          <Message variant="warning" className="w-fit p-2">
            <Text variant="size-14" type="body-500">
              This action is part of a group. Any changes will only affect this specific action, not the entire group.
            </Text>
          </Message>
        )
      }
    >
      {isLoading && !action && <BasicDetailsTabSkeleton />}
      {action && !isLoading && <IndividualDetails action={action} />}
    </ActionTabContainer>
  );
};
