import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafsList } from './get-safs-list';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafsListRequestParameters, TAdminSafsListResponse } from './saf.types';

export const useSafsList = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminSafsListRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TAdminSafsListResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.LIST, requestParameters],
    queryFn: () => getSafsList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch safs list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
