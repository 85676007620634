import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useDistrictFilterOptions, useDraftedDistricts } from '~/services';
import { useDraftDistrictColumns } from './useDraftDistrictColumns';

export const DraftedDistricts: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useDraftedDistricts({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    grade: searchParameters.get('grade'),
    status: searchParameters.get('status'),
  });
  const { data: options, isFetching: isOptionsLoading } = useDistrictFilterOptions();

  const draftDistricts = useMemo(() => data?.results ?? [], [data?.results]);
  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
          (accumulator, [key, value]) => (key === 'grades' ? { ...accumulator, [key]: value } : accumulator),
          {},
        )
        : null,
    [options],
  );
  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const columns = useDraftDistrictColumns();

  const { table } = useDataTable({
    columns,
    data: draftDistricts,
    rowCount: data?.count ?? 0,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  return (
    <DataTable table={table} loading={isFetching}>
      <div className="flex w-full items-center gap-4 p-4 pt-6">
        <AppFilters config={filterConfig} loading={isOptionsLoading} />
        <SearchInput
          value={search}
          placeholder="Search by name, city or state"
          className="max-w-[300px]"
          onChange={onSearchChange}
          onClear={onClearSearch}
        />
        <DataTableViewOptions table={table} />
      </div>
    </DataTable>
  );
};
