import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { SafLinkExtention } from '~/pages/Main/Districts/Details/components/SafLinkExtention';
import type { UseFormReturn } from 'react-hook-form';
import type { z } from 'zod';
import type { safProcessStepSchema } from './schema';

const DEFAULT_EXTENSIONS = [
  StarterKit.configure({
    horizontalRule: false,
    codeBlock: false,
    paragraph: {
      HTMLAttributes: {
        class: 'text-node',
      },
    },
    bulletList: {
      HTMLAttributes: {
        class: 'list-node',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-node',
      },
    },
    dropcursor: {
      width: 2,
      class: 'ProseMirror-dropcursor border',
    },
  }),
  Underline,
  Typography,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  SafLinkExtention.configure({
    autolink: true,
    defaultProtocol: 'https',
  }),
  Placeholder.configure({
    placeholder: () => 'Enter your text here...',
  }),
];

const EDITOR_PROPS = {
  attributes: {
    role: 'textbox',
    class: 'focus:outline-none h-full',
  },
};

export const useEditorConfigs = (form: UseFormReturn<z.infer<typeof safProcessStepSchema>>) => {
  const introductionEditorConfig = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    editorProps: EDITOR_PROPS,
    content:
      form.getValues('introduction_text') === ''
        ? form.getValues('introduction_text')
        : (JSON.parse(form.getValues('introduction_text')) as string),
    editable: true,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('introduction_text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const acknowledgementEditorConfig = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    editorProps: EDITOR_PROPS,
    content:
      form.getValues('acknowledgement_text') === ''
        ? form.getValues('acknowledgement_text')
        : (JSON.parse(form.getValues('acknowledgement_text')) as string),
    editable: true,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('acknowledgement_text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const disclaimerEditorConfig = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    editorProps: EDITOR_PROPS,
    content:
      form.getValues('disclaimer_text') === ''
        ? form.getValues('disclaimer_text')
        : (JSON.parse(form.getValues('disclaimer_text')) as string),
    editable: true,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('disclaimer_text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const specialEdEditorConfig = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    editorProps: EDITOR_PROPS,
    content:
      form.getValues('special_ed_text') === ''
        ? form.getValues('special_ed_text')
        : (JSON.parse(form.getValues('special_ed_text')) as string),
    editable: true,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('special_ed_text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  return {
    introductionEditorConfig,
    acknowledgementEditorConfig,
    disclaimerEditorConfig,
    specialEdEditorConfig,
  };
};
