import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { Form, Tabs } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useCreateRole, usePermissionStructure, useRoleDetails, useUpdateRole } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ManageRolesFormContent, ManageRolesFormContentSkeleton, ManageRolesFormSideBar, ManageRolesHeader } from './components';
import { ManageRolesFormSideBarSkeleton } from './components/ManageRolesFormSideBar';
import { ROLE_PARAMETERS_KEY } from './constants';
import { manageRolesSchema } from './manageRolesSchema';
import { useManageRolesTabs } from './useManageRolesTabs';
import type { TFormValues } from './manageRolesSchema';

export const ManageRolesPage = () => {
  const formId = useId();
  const navigate = useNavigate();

  const { query: roleId } = useQueryParameter({ queryName: ROLE_PARAMETERS_KEY.roleId });

  const { data: initialPermissionStructure, isLoading: isStructureLoading } = usePermissionStructure();
  const { data: permissionDetails, isLoading: isDetailsLoading } = useRoleDetails(roleId!);

  const { tabs } = useManageRolesTabs({ permissionStructure: initialPermissionStructure });

  const { mutate: createRole, isPending: isCreating } = useCreateRole();
  const { mutate: updateRole, isPending: isUpdating } = useUpdateRole();

  const isLoading = useMemo(() => isStructureLoading || isDetailsLoading, [isStructureLoading, isDetailsLoading]);
  const defaultFormValues = useMemo(() => ({
    name: permissionDetails?.name || '',
    districts: permissionDetails?.district ? [permissionDetails?.district] : [],
    description: permissionDetails?.description || '',
    permissions: permissionDetails?.permission_codenames || [],
  }), [permissionDetails]);

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(manageRolesSchema),
    defaultValues: {
      name: '',
      districts: [],
      description: '',
      permissions: [],
    },
  });

  const updateRoleHandler = (formValues: TFormValues) => {
    const { districts, ...rest } = formValues;
    if (!roleId) {
      return showErrorToast('System message', `Could not find role with provided Id:${roleId}`);
    };
    updateRole({ id: roleId, ...rest });
  };

  const createRoleHandler = (formValues: TFormValues) => {
    createRole(formValues);
  };

  const formSubmitHandler = (formValues: TFormValues) => {
    roleId ? updateRoleHandler(formValues) : createRoleHandler(formValues);
  };

  useEffect(() => {
    form.reset(defaultFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFormValues]);

  useEffect(() => {
    if (permissionDetails && !permissionDetails.is_custom) {
      navigate(AdminRoutes.App.Users.Roles.Root.path);
      showErrorToast('System message', 'You can not edit system roles');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionDetails]);

  return (
    <div className="flex flex-col gap-8">
      <ManageRolesHeader fromId={formId} isLoading={isCreating || isUpdating} />
      <Form providerProps={form} className="px-7" id={formId} onSubmit={form.handleSubmit(formSubmitHandler)}>
        {isLoading && !initialPermissionStructure && (
          <div className="flex gap-7">
            <ManageRolesFormSideBarSkeleton />
            <ManageRolesFormContentSkeleton />
          </div>
        )}
        {initialPermissionStructure && !isLoading && (
          <Tabs className="flex gap-7" defaultValue={tabs[0]?.value}>
            <ManageRolesFormSideBar />
            <ManageRolesFormContent />
          </Tabs>
        )}
      </Form>
    </div>
  );
};
