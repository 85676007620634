import { useEffect, useRef, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import { BRAND_COLORS_OPTIONS, DEFAULT_BRAND_COLOR } from '@purple/shared-utils';
import {
  Button,
  cn,
  ColorPicker,
  DropdownContent,
  DropdownItem,
  DropdownPortal,
  DropdownRoot,
  DropdownSub,
  DropdownSubContent,
  DropdownSubTrigger,
  DropdownTrigger,
  Text,
} from '@purple/ui';
// types
import { useMediaQuery } from 'usehooks-ts';
import type React from 'react';

type TColorSelectProperties = {
  /**
   * The default value for the color picker when it is first rendered.
   * @default '#ffffff'
   */
  defaultColor?: string;
  /**
   * The color value. This is the hex value of the color. This is used to control the component.
   */
  color?: string | null;
  /**
   * The id of the component.
   */
  id?: string;
  /**
   * Whether the color picker has an error.
   * @default false
   */
  isError?: boolean;
  /**
   * Indicates whether the contrast warning should be shown.
   * @default false
   */
  showContrastWarning?: boolean;
  /**
   * The text color for the contrast warning.
   */
  contrastWarningText?: string;
  /**
   * Callback fired when the dropdown is opened or closed.
   * @param isOpen - Whether the dropdown is open.
   */
  onOpenChange?: (isOpen: boolean) => void;
  /**
   * Callback fired when the color is changed.
   * @param color - The new color value.
   */
  onColorChange?: (color: string) => void;
};

export const ColorSelect: React.FC<TColorSelectProperties> = (props) => {
  const { id, defaultColor = DEFAULT_BRAND_COLOR, color, contrastWarningText, showContrastWarning, isError = false, onOpenChange, onColorChange } = props;

  const triggerReference = useRef<HTMLButtonElement | null>(null);

  const [selectedColor, setSelectedColor] = useState<string>(defaultColor);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery('(max-width: 1156px)');

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    }
  }, [color]);

  const dropdownOpenChangeHandler = (isOpen: boolean) => {
    if (!isOpen) {
      setIsPickerOpen(false);
    }
    setIsDropdownOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  const pickerTriggerClickHandler = () => {
    setIsPickerOpen((previousOpen) => !previousOpen);
  };

  const closeColorPickerHandler = () => {
    setIsPickerOpen(false);
  };

  const colorClickHandler = (colorHash: string) => {
    setSelectedColor(colorHash);
    setIsPickerOpen(false);
    setIsDropdownOpen(false);
    onColorChange?.(colorHash);
  };

  const pickerColorApplyHandler = (colorHash: string) => {
    setSelectedColor(colorHash);
    setIsPickerOpen(false);
    setIsDropdownOpen(false);
    onColorChange?.(colorHash);
  };

  const resetColorHandler = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedColor(defaultColor);
    setIsPickerOpen(false);
    setIsDropdownOpen(false);
    onColorChange?.(defaultColor);
  };

  const triggerPointerDownHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    const resetButton = triggerReference.current?.querySelector('#color-reset');
    if (resetButton && resetButton.contains(event.target as Node)) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={dropdownOpenChangeHandler}>
      <DropdownTrigger
        ref={triggerReference}
        id={id}
        onPointerDown={triggerPointerDownHandler}
        className={cn(
          'group flex h-10 w-full items-center justify-between gap-2 rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 hover:border-brand-blue-700 hover:bg-transparent focus:border-brand-blue-700 focus:bg-transparent focus-visible:border-brand-blue-700 focus-visible:bg-transparent active:border-brand-blue-700 active:bg-transparent active:text-grey-950 disabled:pointer-events-none disabled:opacity-50 data-[state="open"]:border-brand-blue-700 data-[state="open"]:bg-transparent',
          {
            'border-error-main': isError,
          },
        )}
      >
        <div className="flex items-center gap-2">
          <div
            aria-hidden
            title={selectedColor}
            style={{ backgroundColor: selectedColor }}
            className="size-5 rounded-full"
          />
          <span title={selectedColor} className="inline-block truncate">
            {BRAND_COLORS_OPTIONS.find(({ value }) => value === selectedColor)?.label || selectedColor}
          </span>
        </div>
        <div className="flex items-center gap-2">
          {selectedColor !== defaultColor && (
            <PurpleIcon
              name="X"
              id="color-reset"
              className={cn(
                'h-5 w-5 shrink-0 text-grey-200 transition-all duration-200 group-hover:text-grey-950 group-focus:text-grey-950 group-focus-visible:text-grey-950',
                {
                  'text-grey-950': isDropdownOpen,
                },
              )}
              onClick={resetColorHandler}
            />
          )}
          <PurpleIcon
            name="chevron-down"
            className={cn(
              'h-5 w-5 shrink-0 text-grey-200 transition-all duration-200 group-hover:text-grey-950 group-focus:text-grey-950 group-focus-visible:text-grey-950',
              {
                'rotate-180 transform text-grey-950': isDropdownOpen,
              },
            )}
          />
        </div>
      </DropdownTrigger>
      <DropdownContent useTriggerWidth>
        {BRAND_COLORS_OPTIONS.map(({ label, value }) => (
          <DropdownItem
            key={value}
            onSelect={() => colorClickHandler(value)}
            title={value}
            className='group relative flex cursor-default select-none items-center gap-2.5 rounded-md py-2.5 pl-11 pr-2 font-primary text-sm font-semibold leading-5 text-brand-blue-700 outline-none transition-colors duration-300 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 data-[disabled=true]:pointer-events-none data-[disabled="true"]:opacity-50'
          >
            {selectedColor === value && (
              <span className="absolute left-4 flex size-4 items-center justify-center">
                <PurpleIcon name="check" className="size-4 text-brand-blue-700" />
              </span>
            )}
            <div aria-hidden style={{ backgroundColor: value }} className="size-2.5 rounded-full" />
            <Text
              tag="span"
              className={cn(
                'text-sm font-medium text-grey-950 transition-colors duration-300 group-hover:text-brand-blue-700 group-focus:text-brand-blue-700 group-focus-visible:text-brand-blue-700',
                {
                  'font-semibold text-brand-blue-700': selectedColor === value,
                },
              )}
            >
              {label || value}
            </Text>
          </DropdownItem>
        ))}
        <DropdownSub open={isPickerOpen}>
          <DropdownSubTrigger asChild>
            <Button
              type="button"
              variant="primary"
              className="w-full cursor-pointer rounded-lg data-[state=open]:bg-brand-blue-600"
              onClick={pickerTriggerClickHandler}
            >
              Select New Color
            </Button>
          </DropdownSubTrigger>
          <DropdownPortal>
            <DropdownSubContent sideOffset={isSmallScreen ? -300 : 4} alignOffset={-64} className="border-none p-0">
              <ColorPicker
                allowEyeDropper
                contrastWarningText={contrastWarningText}
                showContrastWarning={showContrastWarning}
                value={selectedColor}
                onClose={closeColorPickerHandler}
                onApply={pickerColorApplyHandler}
                onEyeDropperPick={pickerColorApplyHandler}
              />
            </DropdownSubContent>
          </DropdownPortal>
        </DropdownSub>
      </DropdownContent>
    </DropdownRoot>
  );
};
