import { z } from 'zod';
import { HEX_COLOR_REGEX } from '@purple/shared-utils';

export const brandingSchema = z.object({
  branding_color: z
    .string({ invalid_type_error: 'Color must be a string.' })
    .trim()
    .min(1, { message: 'Color is required.' })
    .regex(HEX_COLOR_REGEX, { message: 'Invalid hex color.' }),
  logo: z
    .custom<File | string>((value) => value instanceof File || typeof value === 'string', {
      message: 'Invalid file type.',
    })
    .nullish()
    .refine((file) => {
      if (file instanceof File) {
        return true;
      }
      if (typeof file === 'string') {
        return true;
      }
      return false;
    }, 'Logo is required.')
    .refine(
      (file) => file instanceof File && file.name.length <= 100,
      'File name should be less than 100 characters.',
    ),
});
