import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TUpdateDistrictBrandingPayload, TUpdateDistrictDetailsResponse } from './districts.types';

export const updateDistrictBranding = async ({ id, branding_color, logo }: TUpdateDistrictBrandingPayload) => {
  let contentType = 'application/json';
  let body: FormData | Omit<TUpdateDistrictBrandingPayload, 'id'> = {
    ...(logo instanceof File ? {} : { logo }),
    branding_color,
  };
  if (logo instanceof File) {
    contentType = 'multipart/form-data';
    body = new FormData();
    body.append('logo', logo);
    if (branding_color) {
      body.append('branding_color', branding_color);
    }
  }

  const response = await axiosInstance.patch<TUpdateDistrictDetailsResponse>(DISTRICTS_ENDPOINTS.BRANDING(id), body, {
    headers: {
      'Content-Type': contentType,
    },
  });
  return response.data;
};
