import { type FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormMessage, MultiSelect, MultiSelectItem } from '@purple/ui';
import { useActionChoices, useUpdateActionTags } from '~/services';
import { actionTagsSchema } from './schema';
import type * as z from 'zod';
import type { TActionDetails } from '@purple/shared-types';

type TTagsContentProperties = {
  action: TActionDetails;
  formId: string;
  loadingChange: (isLoading: boolean) => void;
  editModeChange: (isEditMode: boolean) => void;
};

type TFormValues = z.infer<typeof actionTagsSchema>;

export const TagsEditForm: FC<TTagsContentProperties> = ({ action, formId, loadingChange, editModeChange }) => {
  const { mutate: updateActionTags, isPending } = useUpdateActionTags(action.id);

  const defaultValues: TFormValues = useMemo(() => ({
    tags: action.tags.map((tag) => tag.name),
  }), [action.tags]);

  const { tagsOptions } = useActionChoices({
    payload: {
      actionId: action.id,
      record_action_type: action.record_action_type,
      students: action.details.student ? [action.details.student.id] : [],
    },
    shouldFetch: true,
  });

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(actionTagsSchema),
    defaultValues,
  });

  const allSelectedTags = form.watch('tags');

  const updateTagsHandler = () => {
    const updatedTags = tagsOptions
      .filter((tag) => allSelectedTags.includes(tag.value) && !action.tags.some((item) => item.name === tag.value))
      .map((tag) => ({ color: tag.color, name: tag.value }));
    if (updatedTags.length === 0) {
      editModeChange(false);
      return;
    }

    loadingChange(true);
    updateActionTags({ actionId: action.id, tags: updatedTags }, {
      onSuccess: () => {
        loadingChange(false);
        editModeChange(false);
      },
      onSettled: () => {
        loadingChange(false);
      },
    });
  };

  useEffect(() => {
    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Form providerProps={form} id={formId} onSubmit={form.handleSubmit(updateTagsHandler)}>
      <FormField
        control={form.control}
        name="tags"
        render={({ field, fieldState }) => (
          <FormItem>
            <FormControl>
              <MultiSelect
                {...field}
                isError={!!fieldState.error}
                blockedOptions={action.tags.map((tag) => tag.name)}
                selectedOptions={tagsOptions.filter((tag) => field.value.includes(tag.value))}
                placeholder="Select tags"
                loading={isPending}
                modalPopover
                onOptionChange={field.onChange}
              >
                {tagsOptions.map((option) => (
                  <MultiSelectItem
                    key={option.value}
                    value={option.value}
                    option={option}
                    disabled={action.tags.some((tag) => tag.name === option.value)}
                    isSelected={field.value.includes(option.value)}
                  />
                ))}
              </MultiSelect>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </Form>
  );
};
