import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TResolutionViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const ResolutionView = ({ data }: TResolutionViewProperties) => {
  const { resolution } = data;

  const label = resolution.length ? resolution.map((name) => snakeCaseToCapitalized(name)).join(', ') : '-';

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{label}</Text>
      </div>
    </div>
  );
};

export { ResolutionView };
