import { useMemo } from 'react';
import { ReadableContactType } from '@purple/shared-types';
import { formateDateShortMonth, formateDateShortMonthWithTime } from '@purple/shared-utils';
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TContactItem } from '~/services';

type TUseSchoolContactsColumns = () => ColumnDef<TContactItem>[];

export const useSchoolContactsColumns: TUseSchoolContactsColumns = () => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'full_name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Full Name" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.full_name || '-'}
                </span>
              </TooltipTrigger>
              {row.original.full_name && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.full_name}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 180,
          meta: { label: 'Full Name' },
        },
        {
          accessorKey: 'email',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.email || '-'}</span>
              </TooltipTrigger>
              {row.original.email && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.email}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 200,
          meta: { label: 'Email' },
        },
        {
          accessorKey: 'contact_type',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{ReadableContactType[row.original.contact_type]}</span>
              </TooltipTrigger>
              {row.original.contact_type && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {ReadableContactType[row.original.contact_type]}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          meta: { label: 'Type' },
        },
        {
          accessorKey: 'created_at',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Date Added" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">
                  {row.original.created_at ? formateDateShortMonth(row.original.created_at) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.created_at && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formateDateShortMonthWithTime(row.original.created_at)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'Date Added' },
        },
      ] satisfies ColumnDef<TContactItem>[],
    [],
  );

  return columns;
};
