import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TBehaviorLevelsSchema } from './behaviorLevelsSchema';

export const BehaviorLevelsForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Behavior Metrics
      </Heading>
      <FloatNumberField<TBehaviorLevelsSchema> name="level1" label="Level 1" />
      <FloatNumberField<TBehaviorLevelsSchema> name="level2" label="Level 2" />
      <FloatNumberField<TBehaviorLevelsSchema> name="level3" label="Level 3" />
      <FloatNumberField<TBehaviorLevelsSchema> name="level4" label="Level 4" />
      <FloatNumberField<TBehaviorLevelsSchema> name="level5" label="Level 5" />
      <NumberOfMaxFlagsField className="col-span-1" />
      <BaselineField />
    </div>
  );
};
