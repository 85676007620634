import { Card, cn, Skeleton } from '@purple/ui';
import type { FC } from 'react';

type TChartSkeletonProperties = {
  className?: string;
};
export const ChartSkeleton: FC<TChartSkeletonProperties> = ({ className }) => {
  return (
    <Card className={cn(className)}>
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-6 w-2/3" />
        <Skeleton className="h-8 w-[100px]" />
      </div>
      <Skeleton className="h-[210px] w-full" />
    </Card>
  );
};
