import { REVERSED_US_STATES, US_STATES } from '@purple/shared-types';

// Set up district allows only these states right now
const SET_DISTRICT_STATES = {
  [REVERSED_US_STATES.Texas]: US_STATES.TX,
  [REVERSED_US_STATES.Illinois]: US_STATES.IL,
  [REVERSED_US_STATES.Florida]: US_STATES.FL,
  [REVERSED_US_STATES.Louisiana]: US_STATES.LA,
  [REVERSED_US_STATES.Ohio]: US_STATES.OH,
};

export const STATE_OPTIONS = Object.entries(SET_DISTRICT_STATES).map(([value, label]) => ({
  value,
  label,
}));
