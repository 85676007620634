import { GRADE_CHOICES } from '@purple/shared-types';
import { ColorTrendMetricType, FormulaCondition, GradeFormulaFlagType, MetricDataType } from '~/services';
import type { TColorTrendMetricType, TMetricDataType } from '~/services';

export const ReadableColorTrendMetricType = {
  [ColorTrendMetricType.ATTENDANCE]: 'Attendance',
  [ColorTrendMetricType.BEHAVIOR]: 'Behavior',
  [ColorTrendMetricType.GRADE]: 'Grade',
  [ColorTrendMetricType.HEALTH]: 'Health / Nurse Office Visits',
  [ColorTrendMetricType.SUBPOPULATIONS]: 'Subpopulations',
  [ColorTrendMetricType.ASSESSMENTS]: 'Assessments',
} as const;

export const METRIC_TYPE_OPTIONS = Object.values(ColorTrendMetricType).map((value) => ({
  value,
  label: ReadableColorTrendMetricType[value],
}));

export const ReadableMetricDataType = {
  [MetricDataType.NUMERICAL]: 'Numerical',
  [MetricDataType.LEVELS]: 'Non-Numerical (Levels)',
  [MetricDataType.TEXT]: 'Non-Numerical (Text)',
} as const;

export const METRIC_DATA_TYPE_OPTIONS = Object.values(MetricDataType).map((value) => ({
  value,
  label: ReadableMetricDataType[value],
}));

export const MAXIMUM_FLAGS_OPTIONS = [
  { value: 'N/A', label: 'N/A' },
  ...Array.from({ length: 100 }, (_, i) => i + 1).map((value) => ({
    value,
    label: value.toString(),
  })),
] as const;

export const GRADE_LEVELS_OPTIONS = Object.values(GRADE_CHOICES).map((value) => ({
  value,
  label: value,
}));

export const ReadableGradeFormulaFlagType = {
  [GradeFormulaFlagType.VALUE]: 'Flag Value',
  [GradeFormulaFlagType.THRESHOLD]: 'Flag with Threshold',
  [GradeFormulaFlagType.RANGE]: 'Flag with Range',
} as const;

export const ReadableFormulaCondition = {
  [FormulaCondition.GREATER_THAN]: 'Greater Than',
  [FormulaCondition.LESS_THAN]: 'Less Than',
  [FormulaCondition.GREATER_THAN_EQUAL]: 'Greater Than or Equal',
  [FormulaCondition.LESS_THAN_EQUAL]: 'Less Than or Equal',
} as const;

export const DEFAULT_DISABLED_METRICS: { type: TColorTrendMetricType; disabled: boolean; disabledDataTypes: TMetricDataType[] }[] = [];
