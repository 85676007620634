import { type FC, memo } from 'react';
import { convertBooleanToText, isFieldExist } from '@purple/shared-utils';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import { ActionSectionHeader } from '../ActionSectionHeader';
import type { TActionDetails } from '@purple/shared-types';

type TScreenersAndResultsSectionProperties = {
  action: TActionDetails;
};

export const ScreenersResultsSection: FC<TScreenersAndResultsSectionProperties> = memo(({ action }) => {
  const { screeners_and_results } = action;
  if (!isFieldExist(screeners_and_results)) return null;

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader title="Screeners and Results" disableEdit />
      <DescriptionList>
        {isFieldExist(screeners_and_results.is_parent_acknowledgement_of_risk_form) && (
          <DescriptionItem>
            <DescriptionTerm>Parent Acknowledgement of Risk Form</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(screeners_and_results.is_parent_acknowledgement_of_risk_form)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(screeners_and_results?.is_suicide_screening_administered) && (
          <DescriptionItem>
            <DescriptionTerm>Suicide Screening Administered</DescriptionTerm>
            <DescriptionDetails>
              {convertBooleanToText(screeners_and_results.is_suicide_screening_administered)}
            </DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(screeners_and_results.suicide_screening_assessment_result) && (
          <DescriptionItem>
            <DescriptionTerm>Suicide Screening Assessment Result</DescriptionTerm>
            <DescriptionDetails>{screeners_and_results.suicide_screening_assessment_result ?? '—'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(screeners_and_results?.level_of_distress_start_of_session) && (
          <DescriptionItem>
            <DescriptionTerm>Level of Distress: Start of Session</DescriptionTerm>
            <DescriptionDetails>{screeners_and_results.level_of_distress_start_of_session ?? '—'}</DescriptionDetails>
          </DescriptionItem>
        )}
        {isFieldExist(screeners_and_results?.level_of_distress_end_of_session) && (
          <DescriptionItem>
            <DescriptionTerm>Level of Distress: End of Session</DescriptionTerm>
            <DescriptionDetails>{screeners_and_results.level_of_distress_end_of_session ?? '—'}</DescriptionDetails>
          </DescriptionItem>
        )}
      </DescriptionList>
    </div>
  );
});
