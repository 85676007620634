import type { TFiltersConfig } from '@purple/ui';
import type { TDistrictBasicInfo } from '~/services';

type TUseRolesFiltersOptionsParameters = {
  districtList?: TDistrictBasicInfo[];
};

export const useRolesFiltersOptions = (params: TUseRolesFiltersOptionsParameters) => {
  const { districtList } = params;

  const filterConfig = {
    categories: [
      {
        label: 'District',
        value: 'district',
        filters: districtList?.map((district) => ({
          label: district.name,
          value: district.id.toString(),
        })) || [],
      },
      {
        label: 'Role Type',
        value: 'is_custom',
        filters: [
          {
            label: 'Custom',
            value: 'true',
          },
          {
            label: 'Default',
            value: 'false',
          },
        ],
      },
    ],
  } satisfies TFiltersConfig;

  return { filterConfig };
};
