import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TUpdateDistrictSafProcessPayload, TUpdateDistrictSafProcessResponse } from './districts.types';

export const updateDistrictSafProcess = async ({ id, ...payload }: TUpdateDistrictSafProcessPayload) => {
  const response = await axiosInstance.patch<TUpdateDistrictSafProcessResponse>(
    DISTRICTS_ENDPOINTS.SAF_PROCESS(id),
    payload,
  );
  return response.data;
};
