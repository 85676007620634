import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PurpleIcon } from '@purple/icons';
import { Button, Checkbox, cn, FormControl, FormField, FormItem, FormMessage, Input, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { z } from 'zod';
import type { dataPointsSchema } from './schema';

type TDataTableViewFieldItemItemProps = {
  fieldId: number;
  dragging?: boolean;
};

export const DataTableViewFieldItem: React.FC<TDataTableViewFieldItemItemProps> = (props) => {
  const { fieldId, dragging = false } = props;

  const ctx = useFormContext<z.infer<typeof dataPointsSchema>>();
  const points = ctx.getValues('points');
  const point = useMemo(() => points.find((field) => field.id === fieldId), [fieldId, points]);
  const fieldIndex = useMemo(() => points.findIndex((field) => field.id === fieldId), [fieldId, points]);

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging: isPlaceholder,
  } = useSortable({ id: fieldId, disabled: point?.is_default });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (!point) return null;

  return (
    <li
      ref={point.is_default ? null : setNodeRef}
      style={style}
      className={cn('relative grid min-h-14 w-full grid-cols-12 items-center border-b border-grey-300 py-1.5 bg-white last-of-type:border-none', {
        'border-none': dragging,
      })}
    >
      {isPlaceholder
        ? (
            <div className="absolute inset-0 col-span-12 size-full bg-grey-50" />
          )
        : (
            <>
              <span className="col-span-1 px-3 text-sm font-medium text-grey-950">
                {fieldIndex + 1}
              </span>
              {point.is_default
                ? (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span className="inline-flex size-8 items-center justify-center">
                          <PurpleIcon name="lock-closed" className="size-5 shrink-0 text-error-main" />
                        </span>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          This column is required
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )
                : (
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      className={cn(
                        'col-span-1 size-8 cursor-grab focus:bg-grey-100 focus-visible:bg-grey-100 active:bg-grey-100 active:text-brand-blue-700',
                        {
                          'cursor-grabbing': dragging,
                        },
                      )}
                      iconLeft={<PurpleIcon name="dragdrop" />}
                      {...attributes}
                      {...listeners}
                    />
                  )}
              <span
                className={cn('col-span-3 px-3 text-sm font-medium text-grey-950', {
                  'text-grey-400': point.is_default,
                })}
              >
                {point.column_header_name}
              </span>
              <FormField
                control={ctx.control}
                name={`points.${fieldIndex}.is_displayed`}
                render={({ field }) => (
                  <FormItem className="col-span-3 px-3">
                    <FormControl>
                      <Checkbox
                        {...field}
                        value={field.value.toString()}
                        disabled={point.is_default}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={ctx.control}
                name={`points.${fieldIndex}.alias`}
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-4 px-3">
                    <FormControl>
                      <Input
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter alias"
                        className="max-h-9 w-full min-w-48 max-w-80"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
    </li>
  );
};
