import axiosInstance from '../axios-config';
import { ADMIN_USER_ENDPOINTS } from '../endpoints';
import type { TAdminUserLoginHistoryRequestParameters, TAdminUserLoginHistoryResponse } from './user.types';

export const getUserLoginHistory = async ({ userId, requestParameters }: { userId: string; requestParameters: TAdminUserLoginHistoryRequestParameters }) => {
  const response = await axiosInstance.get<TAdminUserLoginHistoryResponse>(ADMIN_USER_ENDPOINTS.USER_LOGIN_HISTORY(userId), {
    params: requestParameters,
  });

  return response.data;
};
