import { type FC, memo } from 'react';
import { invertColor } from '@purple/shared-utils';
import { cn } from '@purple/ui';
import type { TActionDetails } from '@purple/shared-types';

type TTagsContentProperties = {
  action: TActionDetails;
};

export const TagsContent: FC<TTagsContentProperties> = memo(({ action }) => {
  return (
    <ul className="flex flex-row flex-wrap items-center gap-2">
      {action.tags.map(({ name, color }) => (
        <li key={name}>
          <span
            className={cn(
              'inline-block max-h-6 max-w-[200px] items-center truncate rounded-full bg-brand-blue-100 px-2 py-0.5 text-center text-sm font-medium text-brand-blue-700',

            )}
            style={{ ...(color && { backgroundColor: color, color: invertColor(color, true) }) }}
          >
            {name}
          </span>
        </li>
      ))}
    </ul>
  );
});
