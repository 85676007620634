/* eslint-disable react/no-array-index-key */
import { PurpleIcon } from '@purple/icons';
import { Skeleton } from '@purple/ui';

export const SchoolInfoSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex size-[60px] shrink-0 items-center justify-center rounded-lg bg-grey-100">
        <PurpleIcon name="School" className="size-6 text-brand-blue-700" />
      </div>
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Skeleton className="h-6 w-full max-w-[190px]" />
          <div className="flex flex-wrap items-center justify-start gap-2">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="h-8 w-full max-w-[80px] rounded-full" />
            ))}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-start justify-start gap-6 lg:flex-row">
          <div className="flex w-full max-w-[86px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[42px]" />
            <Skeleton className="h-7 w-full max-w-[86px] rounded-full" />
          </div>
          <div className="flex w-full max-w-[82px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[82px]" />
            <Skeleton className="h-7 w-full max-w-[80px] rounded-full" />
          </div>
          <div className="flex w-full max-w-[120px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[60px]" />
            <Skeleton className="h-5 w-full max-w-[120px]" />
          </div>
          <div className="flex w-full max-w-[180px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[82px]" />
            <Skeleton className="h-5 w-full max-w-[180px]" />
          </div>
          <div className="flex w-full max-w-[140px] flex-1 flex-col gap-1">
            <Skeleton className="h-5 w-full max-w-[110px]" />
            <Skeleton className="h-5 w-full max-w-[140px]" />
          </div>
        </div>
      </div>
      <Skeleton className="ml-auto size-10 shrink-0" />
    </div>
  );
};
