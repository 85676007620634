import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { createFlagCalculation } from './create-flag-calculation';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerFlag, TVisualizerFlagCreatePayload } from './visualizer.types';

export const useCreateFlagCalculation = (options?: { shouldRevalidate?: boolean }) => {
  const { shouldRevalidate = true } = options || {};
  const queryClient = useQueryClient();

  return useMutation<TVisualizerFlag, AxiosError, TVisualizerFlagCreatePayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.CREATE_FLAG_CALCULATION],
    mutationFn: createFlagCalculation,
    onSuccess: (data) => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_LIST_OF_FLAG_CALCULATIONS, data.district.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.district.toString()],
        });
      }
      showSuccessToast('System message', 'Flag calculation was created successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to create flag calculation. Please try again later');
    },
  });
};
