import axiosInstance from '~/services/axios-config';
import { SCHOOLS_ENDPOINTS } from '../endpoints';
import type { TSchoolHierarchyUpdatePayload, TSchoolHierarchyUpdateResponse } from './schools.types';

export const updateSchoolHierarchy = async ({ school_id, ...payload }: TSchoolHierarchyUpdatePayload) => {
  const response = await axiosInstance.patch<TSchoolHierarchyUpdateResponse>(
    SCHOOLS_ENDPOINTS.HIERARCHY(school_id),
    payload,
  );
  return response.data;
};
