import { type FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, cn, FormControl, FormField, FormItem, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { TPermissionStructureItem } from '~/services';
import type { TFormValues } from '../../manageRolesSchema';

type TTableItemProperties = {
  permission: TPermissionStructureItem;
};

export const TableItem: FC<TTableItemProperties> = memo(({ permission }) => {
  const { control } = useFormContext<TFormValues>();
  return (
    <div className="grid grid-cols-[280px,120px,1fr,100px] items-center border-b-grey-200 [&:not(:last-child)]:border-b">
      <div className="px-3 py-4">
        <Tooltip>
          <TooltipTrigger className="line-clamp-1 break-all" asChild>
            <Text variant="size-14" type="body-500" className="w-fit">{permission.name}</Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent withArrow={false}>
              <Text variant="size-14" type="body-500" className="text-white">{permission.name}</Text>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
      <Text variant="size-14" type="body-500" className="px-3 py-4 capitalize">{permission.portal}</Text>
      <div className="px-3 py-4">
        <Tooltip>
          <TooltipTrigger className="line-clamp-1 break-all" asChild>
            <Text variant="size-14" type="body-500" className="w-fit">{permission.description}</Text>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent className="max-w-[450px]" withArrow={false}>
              <Text variant="size-14" type="body-500" className="text-white">{permission.description}</Text>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
      <FormField
        control={control}
        name="permissions"
        render={({ field, fieldState }) => {
          const isChecked = field.value.includes(permission.codename);
          return (
            <FormItem className="flex w-full justify-end px-3 py-4">
              <FormControl>
                <Checkbox
                  checked={isChecked}
                  className={cn(fieldState.error && 'border-error-main')}
                  onCheckedChange={(checked) => {
                    const updatedPermissions = checked
                      ? [...field.value, permission.codename]
                      : field.value.filter((item) => item !== permission.codename);
                    field.onChange(updatedPermissions);
                  }}
                  aria-label={`Toggle permission ${permission.name}`}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
    </div>
  );
});
