import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafRuleDetail } from './get-saf-rule-detail';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafRoutingRuleDetailResponse } from './saf.types';

export const useSafRuleDetail = ({
  id,
  enabled = true,
}: {
  id?: string;
  enabled?: boolean;
}) => {
  const ruleId = id || '';
  const { isError, error, ...rest } = useQuery<TAdminSafRoutingRuleDetailResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.SAF_ROUTING_RULE_DETAIL, id],
    queryFn: () => getSafRuleDetail({ id: ruleId }),
    enabled: enabled && !!ruleId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch saf routing rule detail');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
