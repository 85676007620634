import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const subpopulationsSchema = zodAlwaysRefine(
  z.object({
    special_education: floatNumber(
      z.number().gte(0, 'Special Education must be greater than or equal to 0'),
      2,
    ).nullish(),
    english_language_learner: floatNumber(
      z.number().gte(0, 'English Language Learner must be greater than or equal to 0'),
      2,
    ).nullish(),
    five_o_four: floatNumber(
      z.number().gte(0, '504 must be greater than or equal to 0'),
      2,
    ).nullish(),
    refugee: floatNumber(
      z.number().gte(0, 'Refugee must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TSubpopulationsSchema = z.infer<typeof subpopulationsSchema>;

export const subpopulationsSchemaSuperRefine = (data: TFlagCalculationBase<typeof subpopulationsSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.SUBPOPULATIONS) return;

  if (!isNumber(data.special_education)) {
    ctx.addIssue({
      path: ['special_education'],
      code: z.ZodIssueCode.custom,
      message: 'Special Education is required',
    });
  }
  if (!isNumber(data.english_language_learner)) {
    ctx.addIssue({
      path: ['english_language_learner'],
      code: z.ZodIssueCode.custom,
      message: 'English language learner is required',
    });
  }
  if (!isNumber(data.five_o_four)) {
    ctx.addIssue({
      path: ['five_o_four'],
      code: z.ZodIssueCode.custom,
      message: '504 is required',
    });
  }
  if (!isNumber(data.refugee)) {
    ctx.addIssue({
      path: ['refugee'],
      code: z.ZodIssueCode.custom,
      message: 'Refugee is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
