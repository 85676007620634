import { cutGradeName } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TStudentDetailsViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const StudentDetailsView = ({ data }: TStudentDetailsViewProperties) => {
  const { student } = data;

  if (!student) {
    return (
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full gap-2">
          <Text variant="size-16" type="body-400" className="w-full text-grey-600">Student is not linked</Text>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Linked Student</Text>
        {student.id
          ? (
        // TODO: Add the correct link when StudentDetail page is created
              <Text variant="size-16" type="body-500" className="w-full text-brand-blue-700">
                {
                  student.full_name
                }
              </Text>
            )
          : (
              <Text variant="size-16" type="body-500" className="w-full text-grey-950">-</Text>
            )}
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Student ID</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student.student_id || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Student Phone</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.phone?.join(' ') || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Grade</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student.grade ? cutGradeName(student.grade) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">School</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{student?.school?.name || '-'}</Text>
      </div>
    </div>
  );
};

export { StudentDetailsView };
