import { type FC, memo, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes, format } from 'date-fns';
import { DatePicker, DescriptionDetails, DescriptionItem, DescriptionTerm, Form, FormControl, FormField, FormItem, FormLabel, FormMessage, NumberInput } from '@purple/ui';
import { useUpdateAction } from '~/services';
import { dateAndTimeSchema } from './schema';
import type * as z from 'zod';
import type { TActionDetails } from '@purple/shared-types';

type TFormValues = z.infer<typeof dateAndTimeSchema>;

type TDateTimeEditFormProperties = {
  action: TActionDetails;
  formId: string;
  loadingChange: (isLoading: boolean) => void;
  editModeChange: (isEditing: boolean) => void;
};

export const DateTimeEditForm: FC<TDateTimeEditFormProperties> = memo(({ action, formId, loadingChange, editModeChange }) => {
  const { mutate: updateAction } = useUpdateAction();

  const defaultValues = useMemo(
    () => ({
      date: new Date(action.date_and_time_details.date_and_time),
      duration: action.date_and_time_details.duration,
    }),
    [action.date_and_time_details],
  );

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(dateAndTimeSchema),
    defaultValues,

  });

  const updateDateAndTime = async (formValues: TFormValues) => {
    loadingChange(true);
    updateAction({
      id: action.id,
      date_and_time: formValues.date.toISOString(),
      duration: formValues.duration,
    }, {
      onSuccess: () => {
        form.reset();
        editModeChange(false);
      },
      onSettled: () => {
        loadingChange(false);
      },
    });
  };

  return (
    <Form providerProps={form} className="flex w-full flex-col gap-1" id={formId} onSubmit={form.handleSubmit(updateDateAndTime)}>
      <FormField
        control={form.control}
        name="date"
        render={({ field, fieldState }) => (
          <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
            <FormLabel className="font-primary text-base font-normal text-grey-600">Start Date & Time</FormLabel>
            <div className="flex w-full max-w-[320px] flex-col gap-1">
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                  triggerClassName="max-h-9"
                />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <DescriptionItem>
        <DescriptionTerm tag="p">End Date & Time</DescriptionTerm>
        <DescriptionDetails tag="p">
          {format(addMinutes(new Date(form.watch('date')), form.watch('duration')), 'MMMM d, yyyy, h:mm a')}
        </DescriptionDetails>
      </DescriptionItem>
      <FormField
        control={form.control}
        name="duration"
        render={({ field }) => (
          <FormItem className="flex w-full flex-row flex-wrap items-center justify-between gap-2 space-y-0">
            <FormLabel className="font-primary text-base font-normal text-grey-600">Duration</FormLabel>
            <div className="flex w-full max-w-[320px] flex-col gap-1">
              <FormControl>
                <NumberInput
                  {...field}
                  type="number"
                  isError={!!form.formState.errors.duration}
                  placeholder="Enter duration"
                  className="max-h-9"
                  min={0}
                  changeOnWheel
                />
              </FormControl>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </Form>
  );
});
