export const HARDCODED_ROLES_OPTIONS = [
  {
    label: 'District Admin',
    value: '1',
  },
  {
    label: 'District Leader',
    value: '2',
  },
];

export const DEFAULT_SEARCH_DELAY = 500;
