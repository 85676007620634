import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolBasicDetails } from './get-school-basic-details';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolBasicDetails = (schoolId?: string | null) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BASIC_DETAILS, schoolId?.toString()],
    queryFn: () => getSchoolBasicDetails(schoolId!),
    enabled: !!schoolId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch school basic details');
    }
  }, [isError]);

  return { isError, ...rest };
};
