import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger } from '@purple/ui';
import type { TBannerItem } from '~/services';

type TBodyCellProperties = {
  banner: TBannerItem;
};

export const BodyCell: React.FC<TBodyCellProperties> = (props) => {
  const { banner } = props;

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your banner text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content: banner.body ? JSON.parse(banner.body) : '',
    editable: false,
  });

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <span className="line-clamp-1 min-w-0 break-all">
          {editorConfig?.getText() || '-'}
        </span>
      </HoverCardTrigger>
      {banner.body && (
        <HoverCardPortal>
          <HoverCardContent
            onClick={(evt) => evt.stopPropagation()}
            className="inline-flex w-full min-w-80 justify-center whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950"
          >
            <EditorContent
              editor={editorConfig}
              className="minimal-tiptap-editor flex max-h-[320px] w-full flex-col border-grey-300 font-primary text-sm font-medium text-grey-950"
            />
          </HoverCardContent>
        </HoverCardPortal>
      )}
    </HoverCard>
  );
};
