import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import { replaceUrlParameters } from '../helpers';
import type { TDraftDistrictBrandingRequest } from './districts.types';

export const updateDraftDistrictBranding = async ({
  districtId,
  parameters,
}: {
  districtId: number;
  parameters: TDraftDistrictBrandingRequest;
}) => {
  const formData = new FormData();

  if (parameters.logo instanceof File) {
    formData.append('logo', parameters.logo);
  }
  if (parameters.branding_color) {
    formData.append('branding_color', parameters.branding_color);
  }

  const URL = replaceUrlParameters(DISTRICTS_ENDPOINTS.UPDATE_DRAFT_BRANDING, districtId.toString());

  const response = await axiosInstance.patch<void>(URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};
