import { Skeleton } from '@purple/ui';
import type { FC } from 'react';

export const NeedTypesSkeleton: FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1">
        {Array.from({ length: 5 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} className="h-9" style={{ width: index === 0 ? 100 : (index + 1) * 50 }} />
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <Skeleton className="h-7 w-1/3" />
        {Array.from({ length: 6 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={index} className="h-6 w-1/2" />
        ))}
      </div>

    </div>
  );
};
