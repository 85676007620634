import { forwardRef, useMemo } from 'react';
import { HierarchyLeaf } from './HierarchyLeaf';
import { HierarchyNode } from './HierarchyNode';
import type { PurpleIconType } from '@purple/icons';
import type { THierarchyViewProps } from './HierarchyView';
import type { THierarchyBranch, THierarchyBranchOption, THierarchyNode, THierarchyNodeId, THierarchyUpdateNode } from './types';

type THierarchyItemProps = THierarchyViewProps & {
  selectedItemId?: THierarchyNodeId;
  expandedItemIds: THierarchyNodeId[];
  isTopLevel?: boolean;
  rawData: THierarchyNode[];
  nodeOptions?: THierarchyBranchOption[];
  defaultNodeIcon?: PurpleIconType;
  defaultLeafIcon?: PurpleIconType;
  disableCollapse?: boolean;
  onSelectChange?: (item?: THierarchyNode) => void;
  onNodeAdd?: (item: THierarchyBranch) => void;
  onNodeDelete?: (nodeId: THierarchyNodeId) => void;
  onNodeUpdate?: (node: THierarchyUpdateNode) => void;
};

export const HierarchyItem = forwardRef<HTMLDivElement, THierarchyItemProps>((props, ref) => {
  const {
    className,
    data,
    rawData,
    selectedItemId,
    expandedItemIds,
    nodeOptions,
    isTopLevel = false,
    disableCollapse = false,
    defaultNodeIcon,
    defaultLeafIcon,
    onSelectChange,
    onNodeAdd,
    onNodeDelete,
    onNodeUpdate,
    ...rest
  } = props;

  const treeData = useMemo(() => Array.isArray(data) ? data : [data], [data]);

  return (
    <div ref={ref} role="tree" className={className} {...rest}>
      <ul>
        {treeData.map((item) => (
          <li key={item.id}>
            {item.children
              ? (
                  <HierarchyNode
                    item={item}
                    rawData={rawData}
                    isLast={treeData.indexOf(item) === treeData.length - 1}
                    nodeOptions={nodeOptions}
                    isTopLevel={isTopLevel}
                    selectedItemId={selectedItemId}
                    expandedItemIds={expandedItemIds}
                    defaultNodeIcon={defaultNodeIcon}
                    defaultLeafIcon={defaultLeafIcon}
                    disableCollapse={disableCollapse}
                    onSelectChange={onSelectChange}
                    onNodeAdd={onNodeAdd}
                    onNodeDelete={onNodeDelete}
                    onNodeUpdate={onNodeUpdate}
                  />
                )
              : (
                  <HierarchyLeaf
                    item={item}
                    rawData={rawData}
                    nodeOptions={nodeOptions}
                    isLast={treeData.indexOf(item) === treeData.length - 1}
                    isTopLevel={isTopLevel}
                    selectedItemId={selectedItemId}
                    defaultLeafIcon={defaultLeafIcon}
                    onSelectChange={onSelectChange}
                    onNodeAdd={onNodeAdd}
                    onNodeDelete={onNodeDelete}
                    onNodeUpdate={onNodeUpdate}
                  />
                )}
          </li>
        ))}
      </ul>
    </div>
  );
});
HierarchyItem.displayName = 'HierarchyItem';
