import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { StudentDetailsEdit } from './StudentDetailsEdit';
import { StudentDetailsView } from './StudentDetailsView';
import { updateStudentDetailSchema } from './updateStudentDetailSchema';
import type * as z from 'zod';

type TStudentDetailsAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const StudentDetailsArea = ({ data }: TStudentDetailsAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { student } = data;

    return {
      student: student?.id || '',
    };
  }, [data]);

  const form = useForm<z.infer<typeof updateStudentDetailSchema>>({
    resolver: zodResolver(updateStudentDetailSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateStudentDetailSchema>) => {
    const { student } = newFormData;

    const isStudentUnassigned = student === '';

    const studentValue = isStudentUnassigned ? { student: null } : student ? { student } : {};

    const payload = {
      ...studentValue,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Student Details</Text>}
      />
      {mode === 'view' ? <StudentDetailsView data={data} /> : <StudentDetailsEdit data={data} form={form} />}
    </div>
  );
};

export { StudentDetailsArea };
