import { Skeleton } from '@purple/ui';

export const TypeBasicDetailsSkeleton = () => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="flex flex-col gap-2">
        {Array.from({ length: 8 }).map(() => (
          <div key={Math.random().toString(36)} className="flex w-full items-center gap-3">
            <Skeleton className="h-7 w-1/2" />
            <Skeleton className="h-7 w-1/2" />
          </div>
        ))}
      </div>
    </div>
  );
};
