import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { GROUPS_QUERY_KEYS } from './groups.const';
import { updateGroup } from './update-group';
import type { AxiosError } from 'axios';
import type { TGroupDetailsResponse, TUpdateGroupPayload } from './groups.types';

export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<TGroupDetailsResponse, AxiosError, TUpdateGroupPayload>({
    mutationKey: [GROUPS_QUERY_KEYS.UPDATE_GROUP],
    mutationFn: updateGroup,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to update Permission Group. Check provided information and try again.');
      }
    },
    onSuccess: () => {
      showSuccessToast('System message', 'Permission Group updated successfully');
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEYS.GET_GROUPS_LIST] });
      navigate(AdminRoutes.App.Users.Permissions.Root.path);
    },
  });
};
