import { z } from 'zod';
import { zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType } from '~/services';
import {
  assessmentsSchema,
  assessmentsSchemaSuperRefine,
  attendanceSchema,
  attendanceSchemaSuperRefine,
  behaviorLevelsSchema,
  behaviorLevelsSchemaSuperRefine,
  behaviorNumericalSchema,
  behaviorNumericalSchemaSuperRefine,
  behaviorTextSchema,
  behaviorTextSchemaSuperRefine,
  gradeLevelsSchema,
  gradeLevelsSchemaSuperRefine,
  gradeNumericalSchema,
  gradeNumericalSchemaSuperRefine,
  gradeTextSchema,
  gradeTextSchemaSuperRefine,
  healthSchema,
  healthSchemaSuperRefine,
  metricTypeSchema,
  metricTypeSchemaSuperRefine,
  subpopulationsSchema,
  subpopulationsSchemaSuperRefine,
} from './step';
import { FlagCalculationStep } from './stepper';

export const colorTrendsSchema = z.object({
  flag_calculations: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      type: z.nativeEnum(ColorTrendMetricType),
      grades: z.array(z.string()).nullish(),
      number_of_maximum_flags: z.number().nullish(),
      is_displayed: z.boolean(),
      is_total: z.boolean(),
      is_baseline_added: z.boolean(),
    }),
  ),
});

export const baselineSchema = z.object({
  is_baseline_added: z.boolean(),
});

export const numberOfMaxFlagsSchema = z.object({
  number_of_maximum_flags: z.literal('N/A').or(z.number()).nullish(),
});

export const flagCalculationBaseSchema = z
  .object({
    currentStep: z.nativeEnum(FlagCalculationStep),
    _isBaselineExists: z.boolean(),
    _isFlagBaseline: z.boolean(),
  });

export type TFlagCalculationBase<T = object> = z.infer<
  typeof flagCalculationBaseSchema & typeof metricTypeSchema & typeof baselineSchema & typeof numberOfMaxFlagsSchema & T
>;

const baselineSchemaSuperRefine = (data: TFlagCalculationBase, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS) return;

  if (data.is_baseline_added === undefined) {
    ctx.addIssue({
      path: ['is_baseline_added'],
      code: z.ZodIssueCode.custom,
      message: 'Baseline is required',
    });
  }
};

const numberOfMaxFlagsSchemaSuperRefine = (data: TFlagCalculationBase, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS) return;

  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};

export const flagCalculationSchema = zodAlwaysRefine(
  flagCalculationBaseSchema
    .and(metricTypeSchema)
    .superRefine(metricTypeSchemaSuperRefine)
    .and(baselineSchema)
    .superRefine(baselineSchemaSuperRefine)
    .and(numberOfMaxFlagsSchema)
    .superRefine(numberOfMaxFlagsSchemaSuperRefine)
    .and(assessmentsSchema)
    .superRefine(assessmentsSchemaSuperRefine)
    .and(subpopulationsSchema)
    .superRefine(subpopulationsSchemaSuperRefine)
    .and(healthSchema)
    .superRefine(healthSchemaSuperRefine)
    .and(attendanceSchema)
    .superRefine(attendanceSchemaSuperRefine)
    .and(behaviorNumericalSchema)
    .superRefine(behaviorNumericalSchemaSuperRefine)
    .and(behaviorLevelsSchema)
    .superRefine(behaviorLevelsSchemaSuperRefine)
    .and(behaviorTextSchema)
    .superRefine(behaviorTextSchemaSuperRefine)
    .and(gradeLevelsSchema)
    .superRefine(gradeLevelsSchemaSuperRefine)
    .and(gradeTextSchema)
    .superRefine(gradeTextSchemaSuperRefine)
    .and(gradeNumericalSchema)
    .superRefine(gradeNumericalSchemaSuperRefine),
);
