import * as z from 'zod';
import { TakeActionDocumentType } from '@purple/shared-types';
import { isFieldExist } from '@purple/shared-utils';

export const detailsSchema = z
  .object({
    title: z.string(),
    document: z.nativeEnum(TakeActionDocumentType).optional(),
    studentId: z.string().optional(),
    schoolId: z.string().optional(),
    type: z
      .string({
        invalid_type_error: 'Type name must be a string.',
      })
      .trim()
      .optional(),
    subType: z
      .string({
        invalid_type_error: 'Subtype must be a string.',
      })
      .trim()
      .optional(),
    numberOfResources: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Quantity must be a positive number or zero.',
      }),
    focusAreas: z.array(z.string()).optional(),
    services: z.array(z.string()).optional(),
    quantity: z
      .number({
        message: 'Number of resources must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Quantity must be a positive number or zero.',
      }),
  })
  .superRefine((data, context) => {
    if (isFieldExist(data.type) && data.type.length === 0) {
      context.addIssue({
        path: ['type'],
        code: z.ZodIssueCode.custom,
        message: 'Type is required.',
      });
    }
    if (isFieldExist(data.subType) && data.subType.length === 0) {
      context.addIssue({
        path: ['subType'],
        code: z.ZodIssueCode.custom,
        message: 'SubType is required.',
      });
    }
    if (isFieldExist(data.focusAreas) && data.focusAreas.length === 0) {
      context.addIssue({
        path: ['focusAreas'],
        code: z.ZodIssueCode.custom,
        message: 'At least one focus area is required.',
      });
    }
    if (isFieldExist(data.services) && data.services.length === 0) {
      context.addIssue({
        path: ['services'],
        code: z.ZodIssueCode.custom,
        message: 'At least one service is required.',
      });
    }
    if (isFieldExist(data.document) && !data.document) {
      context.addIssue({
        path: ['document'],
        code: z.ZodIssueCode.custom,
        message: 'Document type is required.',
      });
    }
    if (isFieldExist(data.studentId) && !data.studentId) {
      context.addIssue({
        path: ['studentId'],
        code: z.ZodIssueCode.custom,
        message: 'Student is required.',
      });
    }
    if (isFieldExist(data.schoolId) && !data.schoolId) {
      context.addIssue({
        path: ['schoolId'],
        code: z.ZodIssueCode.custom,
        message: 'School is required.',
      });
    }
  });
