import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { deleteRole } from './delete-role';
import { ROLES_QUERY_KEYS } from './roles.const';
import type { AxiosError, AxiosResponse } from 'axios';

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>({
    mutationKey: [ROLES_QUERY_KEYS.DELETE_ROLE],
    mutationFn: deleteRole,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', ' Unable to delete this custom role. Please try again later.');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ROLES_QUERY_KEYS.LIST_ROLES] });
    },
  });
};
