import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictNavigationPage } from './update-district-navigation-page';
import type { AxiosError } from '@purple/shared-services';
import type { TDistrictCustomPage } from '@purple/shared-types';
import type { TUpdateDistrictCustomPagePayload } from './districts.types';

export const useUpdateCustomPage = () => {
  const queryClient = useQueryClient();

  return useMutation<TDistrictCustomPage, AxiosError, TUpdateDistrictCustomPagePayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_DISTRICT_CUSTOM_PAGE],
    mutationFn: updateDistrictNavigationPage,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_CUSTOM_PAGES, data.district.toString()],
      });
      showSuccessToast('System message', 'District custom page was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update district custom page. Please try again later');
    },
  });
};
