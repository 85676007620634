import { z } from 'zod';
import { zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import type { flagCalculationBaseSchema } from '../schema';

export const metricTypeSchema = zodAlwaysRefine(
  z.object({
    name: z.string().trim().min(1, 'Flag Calculation Name is required'),
    type: z.nativeEnum(ColorTrendMetricType, {
      required_error: 'Metric Type is required',
      invalid_type_error: 'Invalid Metric Type',
    }),
    grades: z.array(z.string()).optional(),
    data_type: z.nativeEnum(MetricDataType).optional(),
  }),
);

export const metricTypeSchemaSuperRefine = (data: z.infer<typeof metricTypeSchema & typeof flagCalculationBaseSchema>, ctx: z.RefinementCtx) => {
  if (!data.data_type && (data.type === ColorTrendMetricType.BEHAVIOR || data.type === ColorTrendMetricType.GRADE)) {
    ctx.addIssue({
      path: ['data_type'],
      code: z.ZodIssueCode.custom,
      message: 'Data Type is required',
    });
  }

  if (data.type === undefined && !data.grades) {
    ctx.addIssue({
      path: ['grades'],
      code: z.ZodIssueCode.custom,
      message: 'Grade Levels are required',
    });
  }

  if (data.type !== ColorTrendMetricType.BEHAVIOR && data.type !== ColorTrendMetricType.GRADE && (!data.grades || data.grades.length === 0)) {
    ctx.addIssue({
      path: ['grades'],
      code: z.ZodIssueCode.custom,
      message: 'Grade Levels are required',
    });
  }
};
