import { PurpleIcon } from '@purple/icons';
import { formateDateShortMonthWithTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TStudentCenteredAction } from '~/services/actions';

type TActionsColumns = ColumnDef<TStudentCenteredAction>[];

type TUseDocumentedActionsColumnsProperties = {
  onDetails: (action: TStudentCenteredAction) => void;
  onDelete: (action: TStudentCenteredAction) => void;
};

export const useDocumentedActionsColumns = ({ onDelete, onDetails }: TUseDocumentedActionsColumnsProperties): TActionsColumns => {
  return [
    {
      accessorKey: 'name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild className="w-fit">
            <button type="button" onClick={() => onDetails(original)}>
              <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{original.title}</span>
            </button>

          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {original.title}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 180,
      meta: { label: 'Name' },
      enableSorting: false,
    },
    {
      accessorKey: 'record_action_type',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
      cell: ({ row: { original } }) => (
        <Tooltip>
          <TooltipTrigger asChild className="w-fit">
            <span className="line-clamp-1 break-all">{snakeToCamelWithSpaces(original.record_action_type)}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {snakeToCamelWithSpaces(original.record_action_type)}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 120,
      meta: { label: 'Type' },
      enableSorting: false,
    },
    {
      accessorKey: 'document_as',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Document as" />,
      cell: ({ row: { original } }) => {
        if (!original.document_as) {
          return (<span className="line-clamp-1 break-all">-</span>);
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild className="w-fit">
              <span className="line-clamp-1 break-all">{snakeToCamelWithSpaces(original.document_as)}</span>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {snakeToCamelWithSpaces(original.document_as)}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      meta: { label: 'Document as' },
      enableSorting: false,
    },
    {
      accessorKey: 'date_and_time',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date & Time" />,
      cell: ({ row: { original } }) => (
        <span>{original.date_and_time ? formateDateShortMonthWithTime(original.date_and_time) : '-'}</span>
      ),
      size: 180,
      meta: { label: 'Date & Time' },
    },
    {
      id: 'options',
      cell: ({ row }) => (
        <button type="button" onClick={() => onDelete(row.original)}>
          <PurpleIcon name="trash" className="size-5 text-error-main" />
        </button>

      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
