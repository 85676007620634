import { forwardRef, useMemo } from 'react';
import { MarkerF, GoogleMap as ReactGoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Skeleton } from '@purple/ui';
import { GOOGLE_MAP_LIBRARIES } from '~/constants';
import type { GoogleMapProps } from '@react-google-maps/api';
import type { TLocation } from '@purple/shared-types';

const DEFAULT_HEIGHT = '200px';
const DEFAULT_WIDTH = '100%';

type TGoogleMapProperties = GoogleMapProps & {
  location: TLocation;
  height?: string;
  width?: string;
  streetViewControl?: boolean;
  mapTypeControl?: boolean;
};

export const GoogleMap = forwardRef<ReactGoogleMap, TGoogleMapProperties>((props, ref) => {
  const {
    location,
    height = DEFAULT_HEIGHT,
    width = DEFAULT_WIDTH,
    streetViewControl = false,
    mapTypeControl = false,
    ...googleMapProps
  } = props;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API,
    libraries: GOOGLE_MAP_LIBRARIES,
    language: 'en',
  });

  const mapCenter = useMemo(() => ({ lat: location.latitude, lng: location.longitude }), [location.latitude, location.longitude]);

  if (
    !location
    || (location.latitude === 0 && location.longitude === 0)
    || (typeof location.latitude !== 'number' && typeof location.longitude !== 'number')
  ) {
    return null;
  }

  if (!isLoaded) {
    return <Skeleton style={{ width, height }} />;
  }

  return (
    <ReactGoogleMap
      ref={ref}
      zoom={15}
      center={mapCenter}
      mapContainerStyle={{ height, width }}
      options={{
        streetViewControl,
        mapTypeControl,
      }}
      {...googleMapProps}
    >
      <MarkerF position={mapCenter} />
    </ReactGoogleMap>
  );
});
GoogleMap.displayName = 'GoogleMap';
