import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getPermissionsStructure } from './get-permissions-structure';
import { PERMISSIONS_QUERY_KEYS } from './permissions.const';
import type { AxiosError } from 'axios';
import type { TPermissionStructure } from './permissions.types';

export const usePermissionStructure = () => {
  const { isError, error, ...rest } = useQuery<TPermissionStructure, AxiosError>({
    queryKey: [PERMISSIONS_QUERY_KEYS.GET_PERMISSIONS_STRUCTURE],
    queryFn: getPermissionsStructure,
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to retrieve permissions structure');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
