import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { FormulaCondition, GradeFormulaFlagType } from '~/services';
import { ReadableFormulaCondition } from '../const';
import { FloatNumberField } from './FloatNumberField';
import type { TGradeNumericalSchema } from './gradeNumericalSchema';

type TFormulaFieldItemProps = {
  fieldIndex: number;
  onUpdate?: (index: number) => void;
  onRemove?: (index: number) => void;
  className?: string;
};

export const FormulaFieldItem: React.FC<TFormulaFieldItemProps> = (props) => {
  const { fieldIndex, onUpdate, onRemove, className } = props;

  const ctx = useFormContext<TGradeNumericalSchema>();

  const type = ctx.watch(`grade_flag_formulas.${fieldIndex}.type`);
  const name = ctx.watch(`grade_flag_formulas.${fieldIndex}.name`);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<string>(name);

  const updateClickHandler = () => {
    onUpdate?.(fieldIndex);
  };

  const deleteFlagClickHandler = () => {
    onRemove?.(fieldIndex);
  };

  const editNameClickHandler = () => {
    setSavedValue(name);
    ctx.trigger(`grade_flag_formulas.${fieldIndex}.name`);
    setIsEditing(true);
  };

  const saveClickHandler = () => {
    setIsEditing(false);
  };

  const cancelClickHandler = () => {
    ctx.setValue(`grade_flag_formulas.${fieldIndex}.name`, savedValue);
    setIsEditing(false);
  };

  useKeyPress('Escape', cancelClickHandler);
  useKeyPress('Enter', saveClickHandler);

  return (
    <div className={cn('flex flex-col w-full col-span-2 gap-3 bg-grey-50 rounded-lg p-2', className)}>
      <div className="flex min-h-8 w-full items-center justify-between gap-4">
        {isEditing
          ? (
              <FormField
                control={ctx.control}
                name={`grade_flag_formulas.${fieldIndex}.name`}
                render={({ field, fieldState }) => (
                  <FormItem className="col-span-5 flex flex-col items-center">
                    <HoverCard open={isEditing}>
                      <HoverCardTrigger asChild>
                        <FormControl>
                          <span className="flex w-fit">
                            <Input
                              {...field}
                              type="text"
                              isError={!!fieldState.error}
                              placeholder="Enter name"
                              className="h-8 max-w-56 bg-grey-50"
                            />
                          </span>
                        </FormControl>
                      </HoverCardTrigger>
                      <HoverCardContent
                        align="end"
                        sideOffset={0}
                        className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
                      >
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              type="button"
                              variant="primary"
                              size="icon_32"
                              className="shadow-custom-medium"
                              disabled={!!fieldState.error}
                              iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                              onClick={saveClickHandler}
                            />
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent>Save</TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              type="button"
                              variant="secondary"
                              size="icon_32"
                              className="border-0 shadow-custom-medium"
                              iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                              onClick={cancelClickHandler}
                            />
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent>Cancel</TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </HoverCardContent>
                    </HoverCard>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )
          : (
              <Text type="body-500" variant="size-14" className="text-grey-950">
                {name}
                <span className="text-error-main">*</span>
              </Text>
            )}
        <DropdownRoot>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownTrigger asChild>
                <Button
                  type="button"
                  variant="tertiary"
                  size="icon_32"
                  onFocusCapture={(event) => event.stopPropagation()}
                  iconLeft={<PurpleIcon name="dots-vertical" className="size-4 shrink-0 text-brand-blue-800" />}
                />
              </DropdownTrigger>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>
                Flag actions
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
          <DropdownContent align="end" sideOffset={4} className="min-w-[240px] border-grey-200">
            <DropdownItem
              iconName="pencil"
              className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
              onClick={editNameClickHandler}
            >
              Rename
            </DropdownItem>
            <DropdownItem
              iconName="variable"
              className="cursor-pointer transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none"
              onClick={updateClickHandler}
            >
              Change Formula Type
            </DropdownItem>
            <DropdownItem
              iconName="trash"
              className="cursor-pointer text-error-main transition-colors duration-200 hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main focus-visible:outline-none [&>svg]:text-error-main"
              onClick={deleteFlagClickHandler}
            >
              Delete Flag
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <div className="grid w-full auto-cols-auto grid-flow-col-dense items-start gap-3">
        <FloatNumberField<TGradeNumericalSchema>
          name={type === GradeFormulaFlagType.RANGE ? `grade_flag_formulas.${fieldIndex}.min_grade` : `grade_flag_formulas.${fieldIndex}.grade_value`}
          className={cn('col-span-6 w-full [&_input]:!bg-grey-50', {
            'col-span-4': type === GradeFormulaFlagType.THRESHOLD,
            'col-span-3': type === GradeFormulaFlagType.RANGE,
          })}
          placeholder={type === GradeFormulaFlagType.RANGE ? 'Minimum Grade' : 'Grade Value'}
        />
        {(type === GradeFormulaFlagType.THRESHOLD || type === GradeFormulaFlagType.RANGE) && (
          <FormField
            control={ctx.control}
            name={`grade_flag_formulas.${fieldIndex}.condition`}
            render={({ field, fieldState }) => (
              <FormItem className={cn('w-full', {
                'col-span-4': type === GradeFormulaFlagType.THRESHOLD,
                'col-span-3': type === GradeFormulaFlagType.RANGE,
              })}
              >
                <RadixSelect onValueChange={field.onChange} value={field.value ?? ''}>
                  <FormControl>
                    <RadixSelectTrigger
                      className={cn('bg-grey-50 gap-1', {
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select condition" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent className="min-w-56">
                    {Object.values(FormulaCondition).map((value) => (
                      <RadixSelectItem key={value} value={value}>
                        {ReadableFormulaCondition[value]}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {type === GradeFormulaFlagType.RANGE && (
          <FloatNumberField<TGradeNumericalSchema>
            name={`grade_flag_formulas.${fieldIndex}.max_grade`}
            className="col-span-3 w-full [&_input]:!bg-grey-50"
            placeholder="Maximum Grade"
          />
        )}
        <Text
          type="body-500"
          variant="size-16"
          className="inline-flex min-h-10 flex-1 items-center justify-center self-start text-center italic text-grey-950"
        >
          is
        </Text>
        <FloatNumberField<TGradeNumericalSchema>
          name={`grade_flag_formulas.${fieldIndex}.flag_value`}
          className={cn('col-span-6 w-full [&_input]:!bg-grey-50', {
            'col-span-4': type === GradeFormulaFlagType.THRESHOLD,
            'col-span-3': type === GradeFormulaFlagType.RANGE,
          })}
          placeholder="Flag Value"
        />
      </div>
    </div>
  );
};
