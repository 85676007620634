import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafsList } from './get-safs-list';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';

export const useSafListMutation = () => {
  // NOTE: we use useMutation hook to get all safs by clicking on the button (or another trigger)
  return useMutation({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.LIST],
    mutationFn: getSafsList,

    onError: () => {
      showErrorToast('System message', 'Unable to fetch all safs list');
    },
  });
};
