import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getSisMappingList } from './get-sis-mapping-list';
import type { AxiosError } from 'axios';
import type { TDistrictMapping } from './districts.types';

export const useSisMappingList = (districtId: string) => {
  const { isError, error, ...rest } = useQuery<TDistrictMapping[], AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, districtId],
    queryFn: () => getSisMappingList(districtId),
    enabled: !!districtId,
  });
  useEffect(() => {
    if (isError && error.response && error.response?.status < 500) {
      showErrorToast('System message', 'Failed to fetch SIS mapping list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
