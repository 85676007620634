import { Skeleton } from '@purple/ui';

export const SisDataListSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-2">
      {Array.from({ length: 6 }).map(() => (
        <div key={Math.random().toString(36)} className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-4">
            <Skeleton className="size-6 rounded-full" />
            <div className="flex flex-col gap-1">
              <Skeleton className="h-5 w-[200px]" />
              <Skeleton className="h-5 w-[180px]" />
            </div>
          </div>
          <Skeleton className="h-8 w-[150px]" />
        </div>
      ))}
    </div>
  );
};
