import { useQueryParameter } from '@purple/hooks';
import { StepItem } from '~/components';
import { VisualizerStep, VisualizerStepReadable, VisualizerStepSequence, VisualizerStepToStateKey } from '~/constants';
import { useUnsavedChanges } from '~/providers';
import { useStudentVisualizerSetupState } from '~/services';
import { VisualizerNavigationSkeleton } from './VisualizerNavigationSkeleton';
import type { TVisualizerStep } from '~/constants';

export const VisualizerNavigation: React.FC = () => {
  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery, onQueryChange } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { checkUnsaved } = useUnsavedChanges();

  const { data: setupState, isFetching } = useStudentVisualizerSetupState({
    enabled: !!districtId,
    districtId,
  });

  const stepClickHandler = (step: string) => {
    if (stepQuery === step) return;
    onQueryChange(step as TVisualizerStep);
  };

  if (isFetching) return <VisualizerNavigationSkeleton />;

  return (
    <nav className="flex w-full max-w-64 shrink-0 flex-col">
      <ul className="flex w-full flex-col gap-8">
        {Object.values(VisualizerStep).map((step) => (
          <li key={step}>
            <StepItem
              label={VisualizerStepReadable[step]}
              active={stepQuery === step}
              completed={setupState ? setupState[VisualizerStepToStateKey[step]] : false}
              sequenceNumber={VisualizerStepSequence[step]}
              value={step}
              onStepClick={(step) => checkUnsaved(() => stepClickHandler(step))}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};
