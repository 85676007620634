import { z } from 'zod';

export const pullDatesSchema = z.object({
  pullDates: z.array(
    z.object({
      id: z.number().or(z.string()),
      is_report_card_date: z.boolean(),
      pull_date: z.date(),
    }),
  ),
  schoolYearStartDate: z.date(),
  schoolYearEndDate: z.date(),
}).superRefine((data, ctx) => {
  if (data.pullDates.length > 0) {
    for (const [index, pullDate] of data.pullDates.entries()) {
      const prevDate = data.pullDates[index - 1]?.pull_date;

      if (prevDate && pullDate.pull_date <= prevDate) {
        ctx.addIssue({
          path: ['pullDates', index, 'pull_date'],
          code: z.ZodIssueCode.custom,
          message: 'Date must be after the previous pull date',
        });
      }

      if (pullDate.pull_date < data.schoolYearStartDate || pullDate.pull_date > data.schoolYearEndDate) {
        ctx.addIssue({
          path: ['pullDates', index, 'pull_date'],
          code: z.ZodIssueCode.custom,
          message: `Pull date must be within the school year start and end date. School year start date: ${data.schoolYearStartDate.toDateString()}, School year end date: ${data.schoolYearEndDate.toDateString()}`,
        });
      }
    }
  }
});
