import { Heading, Text } from '@purple/ui';

export const NotFoundPage: React.FC = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex w-[500px] flex-col items-center justify-center gap-5">
        <Heading tag="h1" variant="size-28" type="heading-700">
          Page Not Found
        </Heading>
        <Text variant="size-16" type="body-400" className="text-grey-600">
          We&apos;re sorry, but the page you are looking for could not be found. It seems that the link you followed may
          be outdated or the page may have been moved or deleted.
        </Text>
        <div className="flex flex-col items-start justify-center gap-3">
          <Text variant="size-16" type="body-600" className="text-grey-600">
            Here are a few options to help you get back on track:
          </Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">
            1) Double-check the URL: Please make sure that the URL you entered is spelled correctly and matches the
            intended page.
          </Text>
          <Text variant="size-16" type="body-400" className="text-grey-600">
            2) Go back to the previous page: Use your browser&apos;s back button to return to the previous page and try
            navigating from there.
          </Text>
        </div>
      </div>
    </div>
  );
};
