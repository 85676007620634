import axiosInstance from '../axios-config';
import { ADMIN_USER_ENDPOINTS } from '../endpoints';
import type { TUserSearchResponse } from '@purple/shared-types';
import type { TAdminUsersRequestParameters } from './user.types';

export const getUsersList = async (queryParameters: TAdminUsersRequestParameters) => {
  const response = await axiosInstance.get<TUserSearchResponse>(ADMIN_USER_ENDPOINTS.LIST, {
    // BE expects schools to be schools='1'&schools='2'&schools='3'
    // axios will convert it to schools[]=1&schools[]=2&schools[]=3
    // so we need to override the default behavior
    params: queryParameters,
    paramsSerializer: (parameters) => {
      return Object.entries(parameters)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map((v) => `${key}=${v}`).join('&');
          }
          return `${key}=${value}`;
        })
        .join('&');
    },
  });
  return response.data;
};
