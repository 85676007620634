import { flexRender } from '@tanstack/react-table';
import { cn, NoDataAvailable, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@purple/ui';
import { DataTablePagination } from './DataTablePagination';
import { DataTableSkeleton } from './DataTableSkeleton';
import { getCommonPinningStyles } from './styles';
import type { RowData, Table as TanstackTable } from '@tanstack/react-table';
import type React from 'react';
import type { PurpleIconType } from '@purple/icons';

type TDataTableProperties<TData extends RowData> = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * The table instance returned from useDataTable hook with pagination, sorting, filtering, etc.
   * @type TanstackTable<TData>
   */
  table: TanstackTable<TData>;
  /**
   * The floating bar to render at the bottom of the table on row selection.
   * @default null
   * @type React.ReactNode | null
   * @example floatingBar={<TasksTableFloatingBar table={table} />}
   */
  floatingBar?: React.ReactNode | null;
  /**
   * Whether the table is in a loading state.
   * @default false
   */
  loading?: boolean;
  /**
   * No data available state to render when there is no data to display.
   */
  emptyState?: {
    iconName?: PurpleIconType;
    title?: string;
    message?: string;
  };
  /**
   * Callback fired when a row is clicked.
   * @param row - The row data that was clicked.
   */
  onRowClick?: (row: TData) => void;
};

export const DataTable = <TData extends RowData = unknown>(props: TDataTableProperties<TData>): JSX.Element => {
  const { table, floatingBar = null, loading = false, emptyState, children, className, onRowClick, ...rest } = props;

  return (
    <div className={cn('w-full overflow-auto', className)} {...rest}>
      {children}
      <div className="overflow-hidden">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="border-grey-200 bg-white">
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn(
                      'h-12 px-4 py-2 text-left align-middle font-primary font-semibold text-grey-600 [&:has([role=checkbox])]:leading-[0] [&>[role=checkbox]]:translate-y-0',
                      header.column.columnDef.meta?.className,
                    )}
                    style={{
                      ...getCommonPinningStyles({ column: header.column }),
                    }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          {loading
            ? (
                <DataTableSkeleton
                  columnCount={table.getAllColumns().length}
                  cellWidths={table.getAllColumns().map((column) => `${column.getSize()}px`)}
                  shrinkZero
                />
              )
            : (
                <TableBody>
                  {table.getRowModel().rows?.length > 0
                    ? (
                        table.getRowModel().rows.map((row) => (
                          <TableRow
                            key={row.id}
                            data-state={row.getIsSelected() && 'selected'}
                            className={cn('h-14 border-b border-grey-200 bg-white', {
                              'cursor-pointer hover:bg-grey-100 [&:hover>td]:!bg-grey-100': !!onRowClick,
                            })}
                            onClick={() => onRowClick?.(row.original)}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell
                                key={cell.id}
                                className={cn(
                                  'px-4 py-2 align-middle font-primary font-medium text-grey-950 transition-colors [&:has([role=checkbox])]:leading-[0] [&>[role=checkbox]]:translate-y-0',
                                  cell.column.columnDef.meta?.className,
                                )}
                                style={{
                                  ...getCommonPinningStyles({ column: cell.column }),
                                }}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      )
                    : (
                        <TableRow className="border-0">
                          <TableCell colSpan={table.getAllColumns().length} className="h-auto px-4 py-8">
                            <NoDataAvailable
                              title={emptyState?.title ?? 'No Data Available'}
                              description={
                                emptyState?.message
                                ?? 'There is currently no data to display. Please check back later or adjust your filters.'
                              }
                              iconName={emptyState?.iconName ?? 'folder-open'}
                              iconSize={48}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                </TableBody>
              )}
        </Table>
      </div>
      {(table.getRowModel().rows.length > 0 || loading) && <DataTablePagination table={table} />}
      {table.getFilteredSelectedRowModel().rows.length > 0 && floatingBar}
    </div>
  );
};
