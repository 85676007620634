import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { EmailStatus } from '@purple/shared-types';
import { formateDateShortMonthWithTime } from '@purple/shared-utils';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Button, FileItem, FileList, Heading, Separator, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { useSafEmail } from '~/services';
import { SAF_EMAIL_KEYS } from './constants';
import { EmailDetailsSkeleton } from './EmailDetailsSkeleton';

export const EmailDetails = () => {
  const { safId } = useParams();

  const { query: emailId, onClearQuery } = useQueryParameter({
    queryName: SAF_EMAIL_KEYS.emailId,
  });

  const { data: emailDetails, isLoading } = useSafEmail({
    parameters: {
      safId: safId!,
      emailId: emailId!,
    },
  });

  const defaultOpenItems = useMemo(() => {
    const [firstItem] = emailDetails?.email_messages || [];
    return firstItem ? [firstItem.id] : [];
  }, [emailDetails]);

  if (isLoading && !emailDetails) {
    return <EmailDetailsSkeleton />;
  }

  return (
    <section className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col items-start gap-4 px-4 pt-6">
        <Button
          type="button"
          variant="tertiary"
          size="link"
          iconLeft={<PurpleIcon name="chevron-left" />}
          className="hover:border-transparent hover:bg-transparent hover:text-brand-blue-800 active:border-transparent active:bg-transparent active:text-brand-blue-900 active:opacity-60"
          onClick={onClearQuery}
        >
          Back to Emails
        </Button>
        <Heading tag="h2" className="text-lg font-semibold text-grey-title">
          Subject:
          {' '}
          {emailDetails?.subject}
        </Heading>
        <Separator className="bg-gray-200" />
      </div>
      <Accordion type="multiple" defaultValue={defaultOpenItems} className="px-4">
        {emailDetails?.email_messages.map((item) => (
          <AccordionItem key={item.id} value={item.id} className="first:border-t last:border-none">
            <AccordionTrigger textClassName="text-base flex-col font-medium text-grey-title inline-flex items-start gap-1">
              <div className="flex w-full items-center justify-start gap-2">
                <span>
                  From:
                  {' '}
                  <span className="text-brand-blue-700">{item.sender_email}</span>
                </span>
                {item.status === EmailStatus.Failed && item.failed_reason && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span>
                        <PurpleIcon name="exclamation-circle" className="size-4 text-error-main" />
                      </span>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent>{item.failed_reason}</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                )}
              </div>
              <Text className="text-sm text-grey-600">{formateDateShortMonthWithTime(item.created_at)}</Text>
            </AccordionTrigger>
            <AccordionContent className="minimal-tiptap-editor flex w-full flex-col gap-4">
              <FileList>
                {item.attachments.map((attachment) => (
                  <FileItem
                    key={attachment.id}
                    filename={attachment.filename}
                    fileSize={attachment.size}
                    fileUrl={attachment.file}
                  />
                ))}
              </FileList>
              {/* eslint-disable-next-line react-dom/no-dangerously-set-innerhtml */}
              <div
                className="ProseMirror whitespace-pre-wrap font-primary text-base font-medium text-grey-950"
                dangerouslySetInnerHTML={{ __html: item.message_html }}
              />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};
