import { Heading } from '@purple/ui';
import { BaselineField } from './BaselineField';
import { FloatNumberField } from './FloatNumberField';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import type { TAssessmentsSchema } from './assessmentsSchema';

export const AssessmentsForm: React.FC = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Assessments Metrics
      </Heading>
      <FloatNumberField<TAssessmentsSchema> name="option1" label="Option 1" />
      <FloatNumberField<TAssessmentsSchema> name="option2" label="Option 2" />
      <FloatNumberField<TAssessmentsSchema> name="option3" label="Option 3" />
      <FloatNumberField<TAssessmentsSchema> name="option4" label="Option 4" />
      <NumberOfMaxFlagsField />
      <BaselineField />
    </div>
  );
};
