import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { useSafListExportMutation } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { DISTRICT_QUERY_NAME, PRIORITY_QUERY_NAME, STATUS_QUERY_NAME } from '../../constants';

const PrintDropdown = () => {
  const [searchParameters] = useSearchParams();

  const { mutate: exportSafs, isPending: isExportingSafs } = useSafListExportMutation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const exportClickHandler = () => {
    exportSafs({
      priority: searchParameters.get(PRIORITY_QUERY_NAME),
      status: searchParameters.get(STATUS_QUERY_NAME),
      district: searchParameters.get(DISTRICT_QUERY_NAME),
      search: searchParameters.get(SEARCH_QUERY_NAME),
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    }, {
      onSuccess: () => {
        showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        setIsDropdownOpen(false);
      },
      onError: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const printClickHandler = () => {
    setIsDropdownOpen(false);
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button type="button" variant="secondary" size="icon_40" iconLeft={<PurpleIcon name="printer" />} />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Print/Export</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </DropdownTrigger>
      <DropdownContent align="end" sideOffset={4} className="w-[240px] border-grey-200">
        <Button
          variant="link"
          type="button"
          className="w-full justify-start text-grey-950 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={<PurpleIcon name="printer" />}
          onClick={printClickHandler}
        >
          Print Preview
        </Button>
        <Button
          disabled={isExportingSafs}
          variant="link"
          onClick={exportClickHandler}
          type="button"
          className="w-full justify-start text-grey-950 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={
            isExportingSafs ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="document-text" />
          }
        >
          Export to xlsx
        </Button>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { PrintDropdown };
