import { format } from 'date-fns';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AppTipBox, Button, cn, Text } from '@purple/ui';
import { SisDataQueryName, SisDataViewType } from './constants';
import type { FC } from 'react';
import type { ValueOf } from '@purple/shared-types';
import type { TDistrictMapping } from '~/services';

type TSisDataListItem = {
  mappingItem: TDistrictMapping;
  onClick: () => void;
};

export const SisDataListItem: FC<TSisDataListItem> = ({
  mappingItem,
  onClick,
}) => {
  const { is_required, is_mapping_completed, is_last_sync_successful, last_sync_at, name } = mappingItem;

  const { onQueryChange: onViewChange } = useQueryParameter<ValueOf<typeof SisDataViewType>>({
    queryName: SisDataQueryName.VIEW,
  });

  const buttonLabel = is_mapping_completed ? 'Update Mapping' : 'Create Mapping';

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-4">
        {is_mapping_completed
          ? (
              <div className="flex size-6 items-center justify-center rounded-full bg-brand-blue-600">
                <PurpleIcon name="check" className="text-white" />
              </div>
            )
          : (
              <div className="size-6 rounded-full bg-gray-100" />
            )}
        <div className="flex flex-col">
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {name}
            {is_required && <span className="text-error-main">*</span>}
          </Text>
          <div className="flex items-center gap-1">
            <Text variant="size-14" className={cn('text-grey-600', is_last_sync_successful === false && 'text-error-main')}>
              {`Last synchronization: ${last_sync_at ? format(last_sync_at, 'MM.dd.yy') : 'Not Synced'}`}
            </Text>
            {/*
              Because this could be null.
              If it is null, we should not display the tip box.
              We should only display the tip box if it is false. It means that the last synchronization was unsuccessful.
            */}
            {is_last_sync_successful === false && (
              <AppTipBox
                iconName="exclamation-circle"
                text="Last synchronization was unsuccessful"
                className="text-error-main hover:text-error-main"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-6">
        <Button variant="tertiary" size="small" onClick={onClick}>{buttonLabel}</Button>
        <Button
          variant="tertiary_icon_only"
          size="icon_32"
          onClick={() => {
            onViewChange(SisDataViewType.DETAILS, {
              onSuccess: (urlParameters) => {
                urlParameters.set(SisDataQueryName.MAPPING_ID, mappingItem.id.toString());
              },
            });
          }}
          iconLeft={(
            <PurpleIcon
              name="chevron-right"
            />
          )}
        />
      </div>
    </div>
  );
};
