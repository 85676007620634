import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { BANNERS_QUERY_KEYS } from './banners.const';
import { getDraftedBanners } from './get-drafted-banners';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TBannersListQueryParameters, TDraftedBannersResponse } from './banners.types';

export const useDraftedBanners = (
  parameters?: TBannersListQueryParameters,
  queryOptions?: TCustomUseQueryOptions<TDraftedBannersResponse, AxiosError>,
) => {
  const { isError, error, ...queryData } = useQuery({
    queryKey: [BANNERS_QUERY_KEYS.GET_DRAFTED_BANNERS, parameters],
    queryFn: () => getDraftedBanners(parameters),
    ...queryOptions,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve draft banners');
    }
  }, [isError, error]);

  return { ...queryData, isError };
};
