import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getMappingEvents } from './get-mappings-events';
import type { AxiosError } from 'axios';
import type { TDistrictMappingEventsRequestParameters, TDistrictMappingEventsResponse } from './districts.types';

export const useMappingEvents = (parameters: TDistrictMappingEventsRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TDistrictMappingEventsResponse, AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_EVENTS, parameters],
    queryFn: () => getMappingEvents(parameters),
    enabled: !!parameters.districtId && !!parameters.mappingId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to fetch events for this sis mapping');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
