import axiosInstance from '../axios-config';
import { DASHBOARD_ENDPOINTS } from '../endpoints';
import type { TDashboardRoleLoginsRequestParameters, TDashboardRoleLoginsResponse } from './dashboard.types';

export const getRoleLogins = async (parameters: TDashboardRoleLoginsRequestParameters) => {
  const response = await axiosInstance.get<TDashboardRoleLoginsResponse>(DASHBOARD_ENDPOINTS.LOGINS_BY_ROLE, {
    params: {
      ...parameters,
      districts: parameters?.districts?.join(','),
    },
  });
  return response.data;
};
