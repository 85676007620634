import { SAF_PRIORITY } from '@purple/shared-types';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import { Badge, cn, Text } from '@purple/ui';
import type { TSafPriority } from '@purple/shared-types';

type TPriorityColumnProperties = {
  priority: TSafPriority;
};

const PriorityColumn = ({ priority }: TPriorityColumnProperties) => {
  if (priority) {
    return (
      <Badge
        label={capitalizeFirstLetter(priority)}
        className={cn({
          'bg-error-bg text-error-main': priority === SAF_PRIORITY.HIGH,
          'bg-warning-bg text-warning-main': priority === SAF_PRIORITY.MEDIUM,
          'bg-brand-blue-100 text-brand-blue-700': priority === SAF_PRIORITY.LOW,
        })}
      />
    );
  }
  return (
    <Text variant="size-14" type="body-500" className="text-grey-960">
      -
    </Text>
  );
};

export { PriorityColumn };
