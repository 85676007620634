import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { GradeFormulaFlagType } from '~/services';
import { ReadableGradeFormulaFlagType } from '../const';
import type React from 'react';
import type { TGradeFormulaFlagType } from '~/services';

const updateFormulaTypeSchema = z.object({
  type: z.nativeEnum(GradeFormulaFlagType, {
    required_error: 'Formula Type is required',
    invalid_type_error: 'Invalid Formula Type',
  }),
});

type TUpdateFormulaTypeModalProps = {
  type?: TGradeFormulaFlagType;
  onSave?: (type: TGradeFormulaFlagType) => void;
  onCancel?: () => void;
};

export const UpdateFormulaTypeModal: React.FC<TUpdateFormulaTypeModalProps> = (props) => {
  const { type, onSave, onCancel } = props;

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.UPDATE_FLAG_FORMULA_TYPE);

  const defaultValues = useMemo(() => ({
    type,
  }), [type]);

  const form = useForm<z.infer<typeof updateFormulaTypeSchema>>({
    resolver: zodResolver(updateFormulaTypeSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const submitFormHandler = useCallback((formData: z.infer<typeof updateFormulaTypeSchema>) => {
    onSave?.(formData.type);
    closeModal();
    form.reset();
  }, [closeModal, form, onSave]);

  const cancelClickHandler = useCallback(() => {
    closeModal();
    form.reset();
    onCancel?.();
  }, [closeModal, form, onCancel]);

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[564px] flex-col">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle className="leading-7 tracking-normal">
            Add Grade Flag
          </DialogTitle>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <DialogDescription asChild>
          <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            <div className="w-full p-6 pr-[30px]">
              <Form
                providerProps={form}
                className="flex w-full flex-col gap-4"
                onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
              >
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel required>Formula Type</FormLabel>
                      <RadixSelect onValueChange={field.onChange} value={field.value}>
                        <FormControl>
                          <RadixSelectTrigger
                            className={cn({
                              'border-error-main': !!fieldState.error,
                            })}
                          >
                            <RadixSelectValue placeholder="Select type" />
                          </RadixSelectTrigger>
                        </FormControl>
                        <RadixSelectContent>
                          {Object.values(GradeFormulaFlagType).map((value) => (
                            <RadixSelectItem key={value} value={value}>
                              {ReadableGradeFormulaFlagType[value]}
                            </RadixSelectItem>
                          ))}
                        </RadixSelectContent>
                      </RadixSelect>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Form>
            </div>
          </ScrollArea>
        </DialogDescription>
        <Separator />
        <DialogFooter className="flex w-full shrink-0 flex-row items-center justify-end gap-4">
          <Button type="button" variant="tertiary" onClick={cancelClickHandler}>
            Cancel
          </Button>
          <Button type="button" onClick={form.handleSubmit(submitFormHandler)}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
