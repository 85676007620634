import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getIndividualActionDetails } from './get-individual-action-details';
import type { AxiosError } from 'axios';
import type { TActionDetails } from '@purple/shared-types';

type TUseIndividualActionDetailsProperties = {
  actionId: string;
  enabled?: boolean;
};

export const useIndividualActionDetails = ({ actionId, enabled = true }: TUseIndividualActionDetailsProperties) => {
  const { isError, error, ...queryData } = useQuery<TActionDetails, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_INDIVIDUAL_ACTION_DETAILS, actionId],
    queryFn: () => getIndividualActionDetails(actionId),
    enabled: !!actionId && enabled,
  });

  useEffect(() => {
    if (isError && error?.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve action details');
    }
  }, [isError, error]);

  return { ...queryData, isError, error };
};
