import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const attendanceSchema = zodAlwaysRefine(
  z.object({
    excused: floatNumber(
      z.number().gte(0, 'Excused must be greater than or equal to 0'),
      2,
    ).nullish(),
    unexcused: floatNumber(
      z.number().gte(0, 'Unexcused must be greater than or equal to 0'),
      2,
    ).nullish(),
    total_absences: floatNumber(
      z.number().gte(0, 'Total absences must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TAttendanceSchema = z.infer<typeof attendanceSchema>;

export const attendanceSchemaSuperRefine = (data: TFlagCalculationBase<typeof attendanceSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.ATTENDANCE) return;

  if (!isNumber(data.excused) && !isNumber(data.unexcused) && !isNumber(data.total_absences)) {
    ctx.addIssue({
      path: ['excused'],
      code: z.ZodIssueCode.custom,
      message: 'Excused is required',
    });
    ctx.addIssue({
      path: ['unexcused'],
      code: z.ZodIssueCode.custom,
      message: 'Unexcused is required',
    });
    ctx.addIssue({
      path: ['total_absences'],
      code: z.ZodIssueCode.custom,
      message: 'Total absences is required',
    });
  }
  if (isNumber(data.excused) && !isNumber(data.unexcused)) {
    ctx.addIssue({
      path: ['unexcused'],
      code: z.ZodIssueCode.custom,
      message: 'Unexcused is required',
    });
  }
  if (!isNumber(data.excused) && isNumber(data.unexcused)) {
    ctx.addIssue({
      path: ['excused'],
      code: z.ZodIssueCode.custom,
      message: 'Excused is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
