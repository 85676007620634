import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, DateRangePicker, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes } from '~/constants';
import { useDataTable } from '~/hooks';
import { useActionsList } from '~/services/actions';
import { ActionsTabContainer } from '../../components';
import { ActionsDetailsQueryValues, ActionsDetailsTabsValues } from '../../Details/constants';
import { allActionsColumns } from './allActionsColumns';
import { useAllActionsFilterOptions } from './useAllActionsFilterOptions';
import type { DateRange } from '@purple/ui';
import type { TStudentCenteredAction } from '~/services/actions';

export const AllActions = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();
  const navigate = useNavigate();

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { filterConfig, isLoading: isFiltersLoading } = useAllActionsFilterOptions();

  const { data: allActionsList, isLoading } = useActionsList({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    search: debounceSearch,
    ordering: searchParameters.get(SORT_QUERY_NAME),
    creator: searchParameters.get('creator'),
    date_and_time__gte: searchParameters.get('date_and_time__gte'),
    date_and_time__lte: searchParameters.get('date_and_time__lte'),
    type: searchParameters.get('type'),
    school: searchParameters.get('school'),
  });

  const { table } = useDataTable({
    columns: allActionsColumns,
    data: allActionsList?.results || [],
    rowCount: allActionsList?.count || 0,
    getRowId: (originalRow) => originalRow.id,
  });

  const updateDateRange = (range: DateRange) => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('date_and_time__gte', format(range.from, 'yyyy-MM-dd'));
      range.to && newSearchParameters.set('date_and_time__lte', format(range.to, 'yyyy-MM-dd'));
      return newSearchParameters;
    });
  };

  const removeDateRange = useCallback(() => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('date_and_time__gte');
      newSearchParameters.delete('date_and_time__lte');
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  const navigateToActionDetails = useCallback(({ id, group_service }: TStudentCenteredAction) => {
    const isGroupAction = group_service !== null && group_service !== undefined && group_service.length > 0;
    const actionType = isGroupAction ? ActionsDetailsQueryValues.groupAction : ActionsDetailsQueryValues.individualAction;

    navigate(AdminRoutes.App.Actions.Student.Details.makePath({ dynamicParameters: { actionId: id }, queries: {
      tab: ActionsDetailsTabsValues.basicDetails,
      actionType,
    } }));
  }, [navigate]);

  return (
    <ActionsTabContainer title="All Actions">
      <DataTable table={table} loading={isLoading} onRowClick={navigateToActionDetails}>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between gap-2 ">
            <div className="h flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isFiltersLoading} />
              <DateRangePicker onUpdate={updateDateRange} align="start" triggerClassNames="h-10 w-64" onClear={removeDateRange} hasClearButton />
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search"
                className="max-w-[300px]"
              />
            </div>
            <div className="flex items-center gap-3">
              {/* TODO: Add Bulk import functional here */}
              <Button variant="secondary" iconLeft={<PurpleIcon name="clipboard-copy" />}>
                Actions Bulk Import
              </Button>
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <AppSelectedFiltersList config={filterConfig} className="pb-4" />
        </div>
      </DataTable>
    </ActionsTabContainer>
  );
};
