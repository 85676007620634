import { memo, useCallback, useId, useState } from 'react';
import { ActionSectionHeader } from '../ActionSectionHeader';
import { GoalsContent } from './GoalsContent';
import { GoalsEditForm } from './GoalsEditForm';
import type { FC } from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TGoalsSectionProps = {
  action: TActionDetails;
};

export const GoalsSection: FC<TGoalsSectionProps> = memo(({ action }) => {
  const formId = useId();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prev) => !prev);
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader
        title="Goals / Progress Towards Goals"
        editing={isEditMode}
        loading={isLoading}
        onEdit={toggleEditMode}
        formId={formId}
        onCancel={toggleEditMode}
      />
      {isEditMode ? <GoalsEditForm action={action} formId={formId} loadingChange={setIsLoading} editModeChange={setIsEditMode} /> : <GoalsContent action={action} />}
    </div>
  );
});
