/* eslint-disable react/no-array-index-key */
import { Skeleton } from '@purple/ui';

export const AbsencesVisualizerSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 p-3">
        <Skeleton className="col-span-6 h-5 w-full max-w-[50px]" />
        <Skeleton className="col-span-5 h-5 w-full max-w-[176px] place-self-center" />
      </div>
      {Array.from({ length: 12 }).map((_, index) => (
        <div className="grid h-14 w-full grid-cols-12 items-center border-b border-grey-300 p-3 last-of-type:border-none" key={index}>
          <Skeleton className="col-span-6 h-5 w-full max-w-[120px]" />
          <Skeleton className="col-span-5 h-5 w-full max-w-[48px] place-self-center" />
          <Skeleton className="col-span-1 size-7 place-self-end" />
        </div>
      ))}
    </div>
  );
};
