import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { parse } from 'date-fns';
import { z } from 'zod';
import { DaysOfWeek } from '@purple/shared-types';
import { DayOfWeekSorter, formatBusinessHour } from '@purple/shared-utils';
import {
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormField,
  FormMessage,
} from '@purple/ui';
import { useUpdateDistrictBasicDetails } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import { BusinessHoursFieldItem } from './BusinessHoursFieldItem';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const businessHoursSchema = z
  .object({
    business_days: z.array(
      z.object({
        id: z.number(),
        day: z.nativeEnum(DaysOfWeek),
        start_time: z.string(),
        end_time: z.string(),
        is_active: z.boolean(),
      }),
    ),
  })
  .superRefine((data, context) => {
    if (data.business_days.length === 0) {
      context.addIssue({
        path: ['business_days'],
        code: z.ZodIssueCode.custom,
        message: 'At least one business day is required.',
      });
    }

    if (data.business_days.every((day) => !day.is_active)) {
      context.addIssue({
        path: ['business_days'],
        code: z.ZodIssueCode.custom,
        message: 'At least one business day must be active.',
      });
    }

    if (data.business_days.some((day) => day.is_active)) {
      for (const [index, day] of data.business_days.entries()) {
        if (
          day.is_active
          && parse(day.start_time, 'HH:mm:ss', new Date()) >= parse(day.end_time, 'HH:mm:ss', new Date())
        ) {
          context.addIssue({
            path: ['business_days', index, 'end_time'],
            code: z.ZodIssueCode.custom,
            message: 'End time must be after start time.',
          });
        }
      }
    }
  });

type TDistrictBusinessHoursSectionProperties = {
  district: TDistrictDetails;
};

export const DistrictBusinessHoursSection: React.FC<TDistrictBusinessHoursSectionProperties> = (props) => {
  const {
    district: { id, business_days = [] },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrict, isPending } = useUpdateDistrictBasicDetails();

  const defaultValues: z.infer<typeof businessHoursSchema> = useMemo(
    () => ({
      business_days,
    }),
    [business_days],
  );

  const form = useForm<z.infer<typeof businessHoursSchema>>({
    resolver: zodResolver(businessHoursSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'business_days',
  });
  const indexes = useMemo(() => new Map(fields.map((field, index) => [field.id, index])), [fields]);

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof businessHoursSchema>) => {
    updateDistrict(
      {
        id,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <DistrictSectionHeader
        title="Business Hours"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              {fields
                .sort((a, b) => DayOfWeekSorter[a.day] - DayOfWeekSorter[b.day])
                .map((field) => (
                  <BusinessHoursFieldItem<z.infer<typeof businessHoursSchema>>
                    key={field.id}
                    control={form.control}
                    day={field.day}
                    startTimeName={`business_days.${indexes.get(field.id)!}.start_time`}
                    endTimeName={`business_days.${indexes.get(field.id)!}.end_time`}
                    isActiveName={`business_days.${indexes.get(field.id)!}.is_active`}
                  />
                ))}
              <FormField
                name={'business_days.root' as 'business_days'}
                control={form.control}
                render={() => <FormMessage />}
              />
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap items-start">
                <DescriptionTerm className="w-1/2 shrink-0">Business Hours</DescriptionTerm>
                <DescriptionDetails className="inline-flex w-1/2 flex-col gap-0.5">
                  {business_days
                    .filter((day) => day.is_active)
                    .sort((a, b) => DayOfWeekSorter[a.day] - DayOfWeekSorter[b.day])
                    .map((day) => (
                      <span key={day.id} className="inline-block min-h-6">
                        {formatBusinessHour(day.day, day.start_time, day.end_time)}
                      </span>
                    ))}
                </DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
