import { useCallback, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';
import { useLazyTimeout } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, Separator, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type React from 'react';

type LinkInlineControlsProperties = {
  url: string;
  onClear: () => void;
  onEdit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const LinkInlineControls: React.FC<LinkInlineControlsProperties> = (props) => {
  const { url, onClear, onEdit } = props;
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [, copyToClipboard] = useCopyToClipboard();
  const { timeout } = useLazyTimeout(3000);

  const copyLinkHandler = useCallback(() => {
    copyToClipboard(url)
      .then(() => {
        setIsCopied(true);
        timeout(() => setIsCopied(false));
      })
      .catch(console.error);
  }, [url, copyToClipboard, timeout]);

  const openLinkHandler = useCallback(() => {
    window.open(url, '_blank', 'noopener,noreferrer');
  }, [url]);

  return (
    <div className="flex w-fit items-center justify-start gap-2 rounded-md border border-grey-300 bg-white p-1">
      <div className="inline-flex items-center gap-1">
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-6"
              iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
              onClick={onEdit}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Edit link</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Separator orientation="vertical" className="h-4" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-6"
              iconLeft={<PurpleIcon name="external-link" className="size-4 shrink-0" />}
              onClick={openLinkHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Open link in a new tab</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Separator orientation="vertical" className="h-4" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-6"
              iconLeft={<PurpleIcon name="X-Circle" className="size-4 shrink-0" />}
              onClick={onClear}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Clear link</TooltipContent>
          </TooltipPortal>
        </Tooltip>
        <Separator orientation="vertical" className="h-4" />
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="size-6"
              iconLeft={<PurpleIcon name={isCopied ? 'check' : 'duplicate'} className="size-4 shrink-0" />}
              onClick={copyLinkHandler}
            />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Copy link</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </div>
    </div>
  );
};
