import { forwardRef, useMemo, useState } from 'react';
import { cn } from '@purple/ui';
import { useModal } from '~/hooks';
import { AddHierarchyNode } from './AddHierarchyNode';
import { getLeftPadding, getNotUsedBranchTypes, getTargetItemDepthLevel, ReadableHierarchyNodeType, walkTreeItemNames } from './helpers';
import { HierarchyNodeActions } from './HierarchyNodeActions';
import { HierarchyNodeIcon } from './HierarchyNodeIcon';
import { ManageNodeDialog } from './ManageNodeDialog';
import { HierarchyNodeType } from './types';
import { treeLineVariants, treeVariants } from './variants';
import type { PurpleIconType } from '@purple/icons';
import type { THierarchyBranch, THierarchyBranchOption, THierarchyNode, THierarchyNodeId, THierarchyUpdateNode } from './types';

type THierarchyLeafProps = React.HTMLAttributes<HTMLDivElement> & {
  item: THierarchyNode;
  rawData: THierarchyNode[];
  nodeOptions?: THierarchyBranchOption[];
  selectedItemId?: THierarchyNodeId;
  isLast?: boolean;
  isTopLevel?: boolean;
  defaultLeafIcon?: PurpleIconType;
  onSelectChange?: (item?: THierarchyNode) => void;
  onNodeAdd?: (item: THierarchyBranch) => void;
  onNodeDelete?: (nodeId: THierarchyNodeId) => void;
  onNodeUpdate?: (node: THierarchyUpdateNode) => void;
};

export const HierarchyLeaf = forwardRef<HTMLDivElement, THierarchyLeafProps>((props, ref) => {
  const {
    rawData,
    nodeOptions,
    className,
    item: treeItem,
    selectedItemId,
    isLast = false,
    isTopLevel = false,
    defaultLeafIcon,
    onSelectChange,
    onNodeAdd,
    onNodeDelete,
    onNodeUpdate,
    ...rest
  } = props;

  const { toggleModal: toggleCreateNodeModal } = useModal(treeItem.id.toString());

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const level = useMemo(() => getTargetItemDepthLevel(rawData, treeItem.id), [rawData, treeItem.id]);

  const allTreeExistingNames = [
    ...(nodeOptions?.map((option) => option.name) ?? []),
    ...walkTreeItemNames(rawData),
  ];
  const notUsedTypes = getNotUsedBranchTypes(rawData, treeItem);
  const allowedNodeOptions = nodeOptions?.filter((option) => notUsedTypes.includes(option.type));

  const nodeSubmitHandler = (node: THierarchyBranch) => {
    if (isEditing) {
      onNodeUpdate?.({ ...node, id: treeItem.id });
    } else {
      onNodeAdd?.(node);
    }
    toggleCreateNodeModal(false);
  };

  const editClickHandler = () => {
    setIsEditing(true);
    toggleCreateNodeModal(true);
  };

  const addClickHandler = () => {
    setIsEditing(false);
    toggleCreateNodeModal(true);
  };

  return (
    <>
      {notUsedTypes.length > 0 && (
        <AddHierarchyNode level={level} isTopLevel={isTopLevel} options={allowedNodeOptions} onOptionSelect={onNodeAdd} onAddClick={addClickHandler} />
      )}
      <div
        ref={ref}
        className={cn(
          treeVariants({ selected: selectedItemId === treeItem.id }),
          treeLineVariants({ last: isLast, hidden: isTopLevel, first: level === 1 }),
          className,
        )}
        onClick={() => {
          onSelectChange?.(treeItem);
          treeItem.onClick?.();
        }}
        style={getLeftPadding(level, isTopLevel)}
        {...rest}
      >
        <HierarchyNodeIcon
          item={treeItem}
          isSelected={selectedItemId === treeItem.id}
          default={defaultLeafIcon}
        />
        <p className="inline-flex select-none flex-col gap-0.5">
          <span className="grow truncate text-[10px] font-normal uppercase leading-[12px] text-grey-700">
            {ReadableHierarchyNodeType[treeItem.type]}
          </span>
          <strong className="grow truncate text-xs font-medium text-grey-950">{treeItem.name}</strong>
        </p>
        {!treeItem.disableActions && (
          <HierarchyNodeActions
            id={treeItem.id}
            isSelected={selectedItemId === treeItem.id}
            onDelete={onNodeDelete}
            onEdit={editClickHandler}
          />
        )}
      </div>
      <ManageNodeDialog
        modalName={treeItem.id.toString()}
        availableTypes={isEditing ? Object.values(HierarchyNodeType) : notUsedTypes}
        existingNames={allTreeExistingNames}
        node={isEditing ? treeItem : null}
        onSubmit={nodeSubmitHandler}
      />
    </>
  );
});
HierarchyLeaf.displayName = 'HierarchyLeaf';
