import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafDetails } from './get-saf-details';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TAdminSafDetailResponse } from './saf.types';

type TUseSafDetailsProperties = {
  safId?: string;
  queryOptions?: TCustomUseQueryOptions<TAdminSafDetailResponse, AxiosError>;
};

export const useSafDetails = ({
  queryOptions,
  safId,
}:
TUseSafDetailsProperties) => {
  const id = safId || '';

  const { isError, error, ...rest } = useQuery<TAdminSafDetailResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.DETAIL, safId],
    queryFn: () => getSafDetails({ safId: id }),
    enabled: queryOptions?.enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch saf details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
