import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionsLIst } from './get-actions-list';
import type { AxiosError } from 'axios';
import type { TStudentCenteredActionList, TStudentCenteredActionsRequestParameters } from './actions.types';

export const useActionsList = (parameters?: TStudentCenteredActionsRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TStudentCenteredActionList, AxiosError>({
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTIONS_LIST, parameters],
    queryFn: () => getActionsLIst(parameters),
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to student centered actions list');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
