import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import { updateSafDetails } from './update-saf-details';
import type { AxiosError, AxiosResponse } from 'axios';
import type { TAdminUpdateSafDetailsPayload } from './saf.types';

export const useUpdateSafDetails = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<void>, AxiosError, { id: string; payload: TAdminUpdateSafDetailsPayload }>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.UPDATE_SAF_DETAILS],
    mutationFn: updateSafDetails,
    onSuccess: () => {
      showSuccessToast('System message', 'SAF was updated successfully');
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.DETAIL] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_SAF_QUERY_KEYS.BASIC_DETAILS] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not update a SAF. Check the provided information and try again');
      };
    },
  });
};
