import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { bulkUpdateFlagCalculations } from './bulk-update-flag-calculations';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerFlagBulkPayload, TVisualizerFlagCalculationsResponse } from './visualizer.types';

export const useBulkUpdateFlagCalculations = (options?: { shouldRevalidate?: boolean }) => {
  const { shouldRevalidate = true } = options || {};
  const queryClient = useQueryClient();

  return useMutation<TVisualizerFlagCalculationsResponse, AxiosError, TVisualizerFlagBulkPayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.BULK_UPDATE_FLAG_CALCULATIONS],
    mutationFn: bulkUpdateFlagCalculations,
    onSuccess: (data) => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_LIST_OF_FLAG_CALCULATIONS, data.id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.id.toString()],
        });
      }
      showSuccessToast('System message', 'List of flag calculations was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update list of flag calculations. Please try again later');
    },
  });
};
