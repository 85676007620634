import { forwardRef, useCallback, useMemo, useState } from 'react';
import { cn } from '@purple/ui';
import { HierarchyItem } from './HierarchyItem';
import type { PurpleIconType } from '@purple/icons';
import type { THierarchyBranch, THierarchyBranchOption, THierarchyNode, THierarchyNodeId, THierarchyUpdateNode } from './types';

export type THierarchyViewProps = React.HTMLAttributes<HTMLDivElement> & {
  data: THierarchyNode[] | THierarchyNode;
  nodeOptions?: THierarchyBranchOption[];
  initialSelectedItemId?: THierarchyNodeId;
  expandAll?: boolean;
  defaultNodeIcon?: PurpleIconType;
  defaultLeafIcon?: PurpleIconType;
  disableCollapse?: boolean;
  onSelectChange?: (item?: THierarchyNode) => void;
  onNodeAdd?: (item: THierarchyBranch) => void;
  onNodeDelete?: (nodeId: THierarchyNodeId) => void;
  onNodeUpdate?: (node: THierarchyUpdateNode) => void;
};

export const HierarchyView = forwardRef<HTMLDivElement, THierarchyViewProps>((props, ref) => {
  const {
    data,
    nodeOptions,
    initialSelectedItemId,
    expandAll = false,
    disableCollapse = false,
    defaultLeafIcon,
    defaultNodeIcon,
    className,
    onSelectChange,
    onNodeAdd,
    onNodeDelete,
    onNodeUpdate,
    ...rest
  } = props;

  const [selectedItemId, setSelectedItemId] = useState<THierarchyNodeId | undefined>(initialSelectedItemId);

  const selectChangeHandler = useCallback(
    (item: THierarchyNode | undefined) => {
      setSelectedItemId(item?.id);
      onSelectChange?.(item);
    },
    [onSelectChange],
  );

  const expandedItemIds = useMemo(() => {
    if (!initialSelectedItemId) {
      return [] as string[];
    }

    const ids: THierarchyNodeId[] = [];

    const walkTreeItems = (items: THierarchyNode[] | THierarchyNode, targetId: THierarchyNodeId): boolean => {
      if (Array.isArray(items)) {
        for (let i = 0; i < items.length; i++) {
          ids.push(items[i]!.id);
          if (walkTreeItems(items[i]!, targetId) && !expandAll) {
            return true;
          }
          if (!expandAll) ids.pop();
        }
      } else if (!expandAll && items.id === targetId) {
        return true;
      } else if (items.children) {
        return walkTreeItems(items.children, targetId);
      }
      return false;
    };

    walkTreeItems(data, initialSelectedItemId);
    return ids;
  }, [data, expandAll, initialSelectedItemId]);

  return (
    <div className={cn('overflow-hidden relative', className)}>
      <HierarchyItem
        ref={ref}
        data={data}
        rawData={Array.isArray(data) ? data : [data]}
        isTopLevel
        nodeOptions={nodeOptions}
        selectedItemId={selectedItemId}
        expandedItemIds={expandedItemIds}
        defaultLeafIcon={defaultLeafIcon}
        defaultNodeIcon={defaultNodeIcon}
        disableCollapse={disableCollapse}
        onSelectChange={selectChangeHandler}
        onNodeAdd={onNodeAdd}
        onNodeDelete={onNodeDelete}
        onNodeUpdate={onNodeUpdate}
        {...rest}
      />
    </div>
  );
},
);
HierarchyView.displayName = 'HierarchyView';
