import { combineReducers } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../white-list';
import { districtSetUpReducer } from './district-set-up';
import { modalsReducer } from './modals';
import { routeHistoryReducer } from './router-history';
import { sidebarReducer } from './sidebar';
// reducers
import { userReducer } from './user';
import { userPermissionsReducer } from './user-permissions';

export const appReducer = combineReducers({
  [AppReduxReducer.USER]: userReducer,
  [AppReduxReducer.MODAL]: modalsReducer,
  [AppReduxReducer.USER_PERMISSIONS]: userPermissionsReducer,
  [AppReduxReducer.SIDEBAR]: sidebarReducer,
  [AppReduxReducer.DISTRICT_SETUP]: districtSetUpReducer,
  [AppReduxReducer.ROUTE_HISTORY]: routeHistoryReducer,
});
