import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { nanoid } from '@reduxjs/toolkit';
import { PurpleIcon } from '@purple/icons';
import { Button, FormField, FormMessage, Heading } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { GradeFormulaFlagType } from '~/services';
import { AddGradeFlagModal } from './AddGradeFlagModal';
import { BaselineField } from './BaselineField';
import { FormulaFieldItem } from './FormulaFieldItem';
import { NumberOfMaxFlagsField } from './NumberOfMaxFlagsField';
import { UpdateFormulaTypeModal } from './UpdateFormulaTypeModal';
import type { TGradeFormulaFlagType } from '~/services';
import type { TGradeNumericalSchema } from './gradeNumericalSchema';

export const GradeNumericalForm: React.FC = () => {
  const { openModal: openAddFlagModal } = useModal(ModalType.ADD_GRADE_FLAG);
  const { openModal: openUpdateTypeModal } = useModal(ModalType.UPDATE_FLAG_FORMULA_TYPE);

  const [updateFieldIndex, setUpdateFieldIndex] = useState<number>(-1);

  const ctx = useFormContext<TGradeNumericalSchema>();

  const { fields, append, remove, update } = useFieldArray({
    control: ctx.control,
    name: 'grade_flag_formulas',
  });

  const updateFieldType = ctx.watch(`grade_flag_formulas.${updateFieldIndex}.type`);

  const createFormulaFlagHandler = (type: TGradeFormulaFlagType, name?: string) => {
    const refinedName = name || `Grade Flag ${
      type === GradeFormulaFlagType.THRESHOLD
        ? 'with Threshold'
        : type === GradeFormulaFlagType.RANGE
          ? 'with Range'
          : ''
    } #${fields.length + 1}`;
    append({
      id: nanoid(),
      name: refinedName,
      type,
    });
  };

  const saveFormulaTypeHandler = (type: TGradeFormulaFlagType) => {
    if (updateFieldIndex === -1) return;
    const fieldValues = ctx.getValues(`grade_flag_formulas.${updateFieldIndex}`);
    update(updateFieldIndex, {
      ...fieldValues,
      type,
    });
    setUpdateFieldIndex(-1);
  };

  const updateFormulaFlagHandler = (index: number) => {
    setUpdateFieldIndex(index);
    openUpdateTypeModal();
  };

  const removeFormulaFlagHandler = (index: number) => {
    remove(index);
  };

  const cancelUpdateTypeHandler = () => {
    setUpdateFieldIndex(-1);
  };

  return (
    <div className="grid w-full grid-cols-2 gap-x-4 gap-y-6">
      <Heading tag="h2" type="heading-600" className="col-span-2 text-center text-base text-grey-950">
        Designate Flags for Numerical Grades Metrics
      </Heading>
      {fields.map((field, index) => (
        <FormulaFieldItem
          key={field.id}
          fieldIndex={index}
          onUpdate={updateFormulaFlagHandler}
          onRemove={removeFormulaFlagHandler}
        />
      ))}
      <Button
        type="button"
        variant="tertiary"
        size="small"
        className="w-fit"
        iconLeft={<PurpleIcon name="plus" />}
        onClick={openAddFlagModal}
      >
        Add Grade Flag
      </Button>
      <FormField
        name={'grade_flag_formulas.root' as 'grade_flag_formulas'}
        control={ctx.control}
        render={() => (
          <FormMessage className="col-span-2" />
        )}
      />
      <NumberOfMaxFlagsField />
      <BaselineField />
      <AddGradeFlagModal onCreate={createFormulaFlagHandler} />
      <UpdateFormulaTypeModal
        type={updateFieldType}
        onSave={saveFormulaTypeHandler}
        onCancel={cancelUpdateTypeHandler}
      />
    </div>
  );
};
