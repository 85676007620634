import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictFilterOptions, TDistrictFilterOptionsRequestParameters } from './districts.types';

export const getDistrictFilterOptions = async (parameters?: TDistrictFilterOptionsRequestParameters) => {
  const response = await axiosInstance.get<TDistrictFilterOptions>(DISTRICTS_ENDPOINTS.FILTER_OPTIONS, {
    params: parameters,
  });
  return response.data;
};
