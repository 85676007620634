import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { SCHOOLS_QUERY_KEYS } from '../schools';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import { userDeleteFromSchool } from './user-delete-from-school';

export const useDeleteUserAccessToSchool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.DELETE_USER_ACCESS_TO_SCHOOL],
    mutationFn: userDeleteFromSchool,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_DETAILS] });
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_BASIC_DETAILS] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_ADMIN_USER_SCHOOLS_LIST] });
      showSuccessToast('System message', 'User was successfully deleted from school');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to delete user from school. Please try again later');
    },
  });
};
