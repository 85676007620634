import { useCallback, useMemo, useState } from 'react';
import { useDebouncedCallback } from '@purple/hooks';
import { cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { useStudents } from '~/services';
import type React from 'react';

type TStudentFilterComboBoxProperties = {
  /**
   * The selected value of the combobox.
   */
  value: string;
  /**
   * Whether the combobox is in an error state.
   * @default false
   */
  isError?: boolean;
  /**
   * Callback that is called when the value of the combobox changes.
   */
  onChange: (value: string) => void;
  /**
   * Callback that is called when the combobox is cleared.
   */
  onClear?: () => void;
  /**
   * Placeholder text for the combobox.
   */
  placeholder?: string;
  /**
   * The maximum number of districts to fetch.
   * @default 10
   */
  limit?: number;
  /**
   * Additional class name for the combobox.
   * @default ''
   */
  className?: string;
  /**
   * Whether the combobox has a clear button.
   */
  hasClearButton?: boolean;
};

export const StudentFilterComboBox: React.FC<TStudentFilterComboBoxProperties> = (props) => {
  const { value, isError = false, onChange, onClear, placeholder = 'Select user', className, limit = 10, hasClearButton = false } = props;

  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');

  const { data: studentsData, isLoading: isStudentsLoading } = useStudents({
    parameters: {
      search: debouncedSearchValue,
      limit,
      offset: 0,
    },
  });

  const students = useMemo(() => studentsData?.results ?? [], [studentsData?.results]);
  const selectedList = useMemo(() => students ? students.find((student) => student.id.toString() === value.toString()) : null, [value, students]);

  const selectValueHandler = useCallback(
    (newValue: string) => {
      onChange(newValue);
    },
    [onChange],
  );

  const debouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDebouncedSearchValue(searchQuery);
  }, 500);

  return (
    <ComboBox modal>
      <ComboBoxTrigger isError={isError} placeholder={placeholder} selectedLabel={selectedList?.full_name} hasClearButton={hasClearButton && Boolean(selectedList)} onClear={hasClearButton ? onClear : () => {}} className={cn(className)} />
      <ComboBoxContent
        loading={isStudentsLoading}
        shouldFilter={false}
        searchPlaceholder="Search student..."
        emptyContent="Student not found."
        onSearchChange={debouncedSearch}
      >
        {students.map(({ id, full_name }) => (
          <ComboBoxItem key={id} value={id.toString()} selected={value.toString() === id.toString()} onSelect={selectValueHandler}>
            {full_name || 'Unidentified student'}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
