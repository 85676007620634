import { Checkbox, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { ColumnDef, RowData } from '@tanstack/react-table';

export const SelectableCell = <TData extends RowData>(): ColumnDef<TData> => ({
  id: 'select',
  header: ({ table }) => (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="inline-block leading-[0]">
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
            disabled={table.getRowModel().rows?.length === 0}
          />
        </span>
      </TooltipTrigger>
      {table.getRowModel().rows?.length > 0 && (
        <TooltipPortal>
          <TooltipContent>{table.getIsAllPageRowsSelected() ? 'Deselect all' : 'Select all'}</TooltipContent>
        </TooltipPortal>
      )}
    </Tooltip>
  ),
  cell: ({ row }) => (
    <Tooltip>
      <TooltipTrigger asChild>
        <span className="inline-block leading-[0]">
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        </span>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent>{row.getIsSelected() ? 'Deselect row' : 'Select row'}</TooltipContent>
      </TooltipPortal>
    </Tooltip>
  ),
  enableSorting: false,
  enableHiding: false,
  size: 52,
  meta: { className: 'w-[52px] min-w-[52px] max-w-[52px]' },
});
