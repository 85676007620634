import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getSisMappingDetails } from './get-mapping-details';
import type { AxiosError } from 'axios';
import type { TDistrictDetailsItem } from './districts.types';

export const useSisMappingDetails = (districtId: string, mappingId: string) => {
  const { isError, error, ...rest } = useQuery<TDistrictDetailsItem, AxiosError>({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_DETAILS, districtId, mappingId],
    queryFn: () => getSisMappingDetails(districtId, mappingId),
    enabled: !!districtId && !!mappingId,
  });

  useEffect(() => {
    if (isError && error.response && error.response?.status < 500) {
      showErrorToast('System message', 'Failed to fetch SIS mapping details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
