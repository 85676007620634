import { forwardRef, useMemo } from 'react';
import { useQueryParameter } from '@purple/hooks';
import { Button } from '@purple/ui';
import { VisualizerStep, VisualizerStepSequence } from '~/constants';
import type { TVisualizerStep } from '~/constants';

type TVisualizerStepFooterProps = {
  formId: string;
  isLoading?: boolean;
  onPreviousStep?: () => void;
  onNextStep?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const VisualizerStepFooter = forwardRef<HTMLDivElement, TVisualizerStepFooterProps>((props, ref) => {
  const { formId, isLoading = false, onPreviousStep, onNextStep } = props;

  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const isFirstStep = useMemo(() => stepQuery
    ? VisualizerStepSequence[stepQuery] === 1
    : false, [stepQuery]);
  const isLastStep = useMemo(() => stepQuery
    ? VisualizerStepSequence[stepQuery] === Object.keys(VisualizerStep).length
    : false, [stepQuery]);

  return (
    <div ref={ref} className="mt-auto flex w-full flex-row items-center justify-end gap-4 border-t border-grey-200 px-6 py-4">
      {!isFirstStep && <Button type="button" variant="tertiary" onClick={onPreviousStep}>Previous Step</Button>}
      {!isLastStep && <Button type="submit" form={formId} isLoading={isLoading} onClick={onNextStep}>Next Step</Button>}
    </div>
  );
});
