import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { exportSafsList } from './export-safs-list';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafsListRequestParameters } from './saf.types';

export const useSafListExportMutation = () => {
  // NOTE: we use useMutation hook to export safs by clicking on the button (or another trigger)
  return useMutation<Blob, AxiosError, TAdminSafsListRequestParameters>({
    mutationKey: [ADMIN_SAF_QUERY_KEYS.LIST_EXPORT],
    mutationFn: exportSafsList,

    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Could not export safs. Check the provided information and try again');
      }
    },
    onSuccess: (data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);

      link.href = url;
      link.download = 'saf-list.xlsx';
      document.body.append(link);
      link.click();
      link.remove();

      // Clean up the URL object after the download is complete to avoid memory leaks
      window.URL.revokeObjectURL(url);
    },
  });
};
