import axiosInstance from '../axios-config';
import { ADMIN_USER_ENDPOINTS } from '../endpoints';
import type { TAppPermissions } from '@purple/permissions';

type TUserPermissionsResponseBody = {
  permissions: TAppPermissions[];
};

export const getUserPermissions = async () => {
  const response = await axiosInstance.get<TUserPermissionsResponseBody>(ADMIN_USER_ENDPOINTS.USER_PERMISSIONS);
  return response.data;
};
