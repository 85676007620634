import { useEffect, useMemo, useState } from 'react';
import { Button, NoDataAvailable, Separator, Text } from '@purple/ui';
import { DistrictFilterComboBox } from '~/components';
import { useDistrictSafProcessOwner, useUpdateDistrictSafProcessOwner } from '~/services';
import { OwnerEdit, OwnerView } from './components';

export const SafTransferProcessOwner = () => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const [selectedDistrict, setSelectedDistrict] = useState<string | null>(null);
  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedDistrict) {
      setSelectedOwner(null);
      setMode('view');
    }
  }, [selectedDistrict]);

  const { data: districtSafOwnerData, isLoading: isSafOwnerLoading } = useDistrictSafProcessOwner({ districtId: selectedDistrict || '', queryOptions: { enabled: !!selectedDistrict } });

  const { mutate: updateOwner, isPending: isOwnerUpdating } = useUpdateDistrictSafProcessOwner();

  const owner = useMemo(() => {
    return districtSafOwnerData?.saf_process_owner;
  }, [districtSafOwnerData]);

  const districtChangeHandler = (districtId: string) => {
    setSelectedDistrict(districtId);
  };

  const districtClearHandler = () => {
    setSelectedDistrict(null);
  };

  const changeOwnerHandler = () => {
    setMode('edit');
  };

  const cancelChangeOwnerHandler = () => {
    setMode('view');
  };

  const saveChangeOwnerHandler = () => {
    if (selectedDistrict && selectedOwner) {
      const payload = {
        saf_process_owner: selectedOwner,
      };

      updateOwner({
        id: selectedDistrict,
        payload,
      }, {
        onSuccess: () => {
          setMode('view');
        },
      });
    }
  };

  const showEmptyBlock = useMemo(() => !selectedDistrict || !districtSafOwnerData, [selectedDistrict, districtSafOwnerData]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-4 pb-4 pt-6">
        <div className="min-w-[200px]">
          <DistrictFilterComboBox placeholder="Select district" value={selectedDistrict || ''} onChange={districtChangeHandler} onClear={districtClearHandler} />
        </div>
        {mode === 'view'
          ? (
              <Button onClick={changeOwnerHandler} variant="secondary" type="button" disabled={!selectedDistrict || isSafOwnerLoading}>Change Owner</Button>
            )
          : (
              <div className="flex gap-4">
                <Button onClick={cancelChangeOwnerHandler} variant="secondary" type="button">Cancel</Button>
                <Button onClick={saveChangeOwnerHandler} variant="primary" type="button" isLoading={isOwnerUpdating}>Save</Button>
              </div>
            )}
      </div>
      {showEmptyBlock
        ? ((
            <NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select district to view data" className="min-h-96" />
          ))
        : (
            <div className="flex min-h-96 flex-col gap-0">
              <div className="flex px-6 py-4">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-0">
                    <Text variant="size-16" type="body-500" className="text-grey-title">SAF Transfer Process Owner</Text>
                    <Text variant="size-16" type="body-400" className="text-grey-600">Person who will receive SAFs without owners, SAFs that need to be routed to the correct school / owner and SAFs that have been transferred by users who don't have access to the new school</Text>
                  </div>
                  <div className="flex items-center gap-[60px] pb-[30px] pl-[37px]">
                    <Text variant="size-16" type="body-500" className="text-grey-800">Transfer Process Owner</Text>
                    {mode === 'view'
                      ? (
                          <OwnerView owner={owner} />
                        )
                      : (
                          <OwnerEdit owner={selectedOwner} onChange={(newOwner) => setSelectedOwner(newOwner)} />
                        )}
                  </div>
                </div>
              </div>
              <Separator className="bg-grey-200" />
            </div>
          )}
    </div>
  );
};
