import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictsListQueryParameters, TDraftedDistrictsResponse } from './districts.types';

export const getDraftedDistricts = async (parameters?: TDistrictsListQueryParameters) => {
  const response = await axiosInstance.get<TDraftedDistrictsResponse>(DISTRICTS_ENDPOINTS.DRAFTED_LIST, {
    params: parameters,
  });
  return response.data;
};
