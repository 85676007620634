import axiosInstance from '~/services/axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';

export const userDeleteFromSchool = async ({ userId, schoolId }: {
  userId: string;
  schoolId: string;
}) => {
  const response = await axiosInstance.post<void>(
    ADMIN_USER_MANAGEMENT.USER_DELETE_FROM_SCHOOL(userId),
    {
      school: schoolId,
    },
  );
  return response.data;
};
