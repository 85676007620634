import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebouncedCallback } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Checkbox,
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Separator,
  Text,
  TipBox,
  useStepper,
} from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useAppDispatch, useAppSelector } from '~/hooks';
import { useUnsavedChanges } from '~/providers';
import { useRoleSearch, useUpdateDraftDistrictLeadersUsers } from '~/services';
import {
  districtIdSelector,
  districtLeaderUsersStepSelector,
  maxDistrictStepSelector,
  setMaxStep,
} from '~/store/features/district-set-up';
import { showErrorToast } from '~/utils/toasts';
import { StepCardContainer } from '../../StepCardContainer';
import { DEFAULT_SEARCH_DELAY } from './constants';
import { leaderUsersSchema } from './schema';
import type { z } from 'zod';

const DistrictUsersStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { nextStep, activeStep } = useStepper();

  const formId = useId();

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const districtId = useAppSelector(districtIdSelector);
  const leaderUsersStep = useAppSelector(districtLeaderUsersStepSelector);
  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const [rolesDebouncedSearchValue, setRolesDebouncedSearchValue] = useState<string>('');

  const { data: allRoles, isLoading: isRolesLoading } = useRoleSearch(
    {
      requestParameters: {
        search: rolesDebouncedSearchValue,
        district: districtId?.toString(),
      },
    },
  );

  const rolesDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setRolesDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const { mutate: updateDraft, isPending: isUpdating } = useUpdateDraftDistrictLeadersUsers();

  const rolesSelectOptions = useMemo(() => {
    return (
      allRoles?.results.map((role) => ({
        ...role,
        label: role.name,
        value: role.id.toString(),
      })) ?? []
    );
  }, [allRoles]);

  const defaultValues = useMemo(() => {
    const { users, no_district_leader_available } = leaderUsersStep;
    return {
      users: users ? [...users] : [],
      no_district_leader_available,
    };
  }, [leaderUsersStep]);

  const form = useForm<z.infer<typeof leaderUsersSchema>>({
    resolver: zodResolver(leaderUsersSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  const formNoDistrictLeaderAvailable = form.watch('no_district_leader_available');

  useEffect(() => {
    if (formNoDistrictLeaderAvailable) {
      form.setValue('users', []);
      form.clearErrors('users');
    }
  }, [form, formNoDistrictLeaderAvailable]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const {
    fields: usersFields,
    remove: removeUser,
    append: appendUser,
  } = useFieldArray({
    name: 'users',
    control: form.control,
  });

  useEffect(() => {
    // NOTE: Add a new user field with Leader role if there are no users
    const leaderRoleId = rolesSelectOptions?.[0]?.value;
    if (usersFields.length === 0 && leaderRoleId && !formNoDistrictLeaderAvailable) {
      appendUser({
        email: '',
        prounitas_roles: +leaderRoleId,
      });
    }
  }, [usersFields, rolesSelectOptions, appendUser, formNoDistrictLeaderAvailable]);

  const submitHandler = (data: z.infer<typeof leaderUsersSchema>, onSaveCallback?: () => void) => {
    if (districtId) {
      const convertedUsers = data.no_district_leader_available
        ? []
        : data.users.map((user) => {
          const defaultUser = defaultValues.users.find((currentUser) => currentUser.id === user.id);

          // NOTE: send user ID if it exists in defaultValues.
          // Otherwise, we know that user is new and BE will create it.
          const userId = defaultUser ? defaultUser?.id : null;

          const oldRoles = defaultUser?.prounitas_roles ? [defaultUser.prounitas_roles] : [];

          return {
            ...(userId ? { id: userId } : {}),
            email: user.email || '',
            roles: user.prounitas_roles ? [+user.prounitas_roles] : [0],
            old_roles: oldRoles,
          };
        });

      const body = {
        users: convertedUsers,
        no_district_leader_available: data.no_district_leader_available,
      };

      updateDraft(
        {
          districtId,
          parameters: body,
        },
        {
          onSuccess: () => {
            setShouldShowUnsaved(false);
            onSaveCallback?.();
            form.reset(data);
            nextStep();
          },
        },
      );
    } else {
      showErrorToast('System message', 'Could not find district id. Check the provided information and try again');
    }
  };

  const navigateToDistrictList = useCallback(() => {
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  const increaseMaxStep = () => {
    if (maxDistrictStep === activeStep) {
      dispatch(setMaxStep(maxDistrictStep + 1));
    }
  };

  const addMoreLeaderUserHandler = () => {
    const leaderRoleId = rolesSelectOptions?.[0]?.value;
    if (leaderRoleId) {
      appendUser({
        email: '',
        prounitas_roles: +leaderRoleId,
      });
    }
  };

  return (
    <StepCardContainer
      isStepValid
      onSave={() => submitHandler(form.getValues(), navigateToDistrictList)}
      formId={formId}
      isLoading={isUpdating}
    >
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col gap-6"
        onSubmit={form.handleSubmit((data) => submitHandler(data, increaseMaxStep))}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Heading variant="size-18" type="heading-500" className="text-grey-950">
                District Leader
              </Heading>
              <Text variant="size-14" type="body-400" className="text-grey-700">
                Invite a District Leader who will oversee and manage district operations.
              </Text>
            </div>
            <FormField
              control={form.control}
              name="no_district_leader_available"
              render={({ field }) => (
                <FormItem className="col-span-2 flex items-center gap-2 space-y-0">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value={field.value.toString()}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel className="text-grey-800 flex flex-nowrap items-center gap-2 text-base">
                    No District Leader available
                    <TipBox
                      text="If no District Leader is available, select the checkbox to skip this step. The Pronunitas Team will manage the district, and you can add a leader later."
                      tooltipClassName="text-center"
                    />
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-1">
              {
                usersFields.map((user, index) => {
                  return (
                    <div key={user.id} className="grid w-full grid-cols-2 gap-4">
                      <FormField
                        control={form.control}
                        name={`users.${index}.email`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel required>Email</FormLabel>
                            <FormControl>
                              <Input
                                {...field}
                                isError={!!form.formState.errors.users?.[index]?.email}
                                placeholder="Enter district leader mail address"
                                type="text"
                                disabled={formNoDistrictLeaderAvailable}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="flex flex-row flex-nowrap gap-2">
                        <FormField
                          control={form.control}
                          name={`users.${index}.prounitas_roles`}
                          render={({ field, fieldState }) => (
                            <FormItem className="flex-1">
                              <FormLabel required>Choose Role</FormLabel>
                              <div className="flex flex-row items-center gap-2">
                                <ComboBox modal>
                                  <FormControl>
                                    <ComboBoxTrigger
                                      isError={!!fieldState.error}
                                      placeholder="Select role"
                                      selectedLabel={
                                        rolesSelectOptions.find(
                                          (option) => option.value.toString() === field.value?.toString(),
                                        )?.label
                                      }
                                      className="max-w-[530px]"
                                      disabled={formNoDistrictLeaderAvailable || isRolesLoading}
                                    />
                                  </FormControl>
                                  <ComboBoxContent shouldFilter={false} searchPlaceholder="Search role..." emptyContent="Role not found." onSearchChange={rolesDebouncedSearch} loading={isRolesLoading}>
                                    {rolesSelectOptions.map(({ label, value }) => (
                                      <ComboBoxItem
                                        key={value}
                                        value={value}
                                        selected={value.toString() === field.value?.toString()}
                                        onSelect={field.onChange}
                                      >
                                        {label}
                                      </ComboBoxItem>
                                    ))}
                                  </ComboBoxContent>
                                </ComboBox>
                                <div className="size-[20px]">
                                  <button
                                    type="button"
                                    onClick={() => removeUser(index)}
                                    className={cn('flex', {
                                      hidden: usersFields.length === 1 || formNoDistrictLeaderAvailable,
                                    })}
                                  >
                                    <PurpleIcon name="trash" className="text-error-main size-[20px]" />
                                  </button>
                                </div>
                              </div>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  );
                })
              }
              <div>
                <Button
                  variant="link"
                  size="link"
                  type="button"
                  onClick={addMoreLeaderUserHandler}
                  disabled={formNoDistrictLeaderAvailable}
                >
                  + Add Additional District Leader
                </Button>
              </div>
            </div>
          </div>
          <Separator />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <Heading variant="size-18" type="heading-500" className="text-grey-950">
                Users
              </Heading>
              <Text variant="size-14" type="body-400" className="text-grey-700">
                Add and invite users to the district platform.
              </Text>
            </div>
            {/* NOTE: this is dummy. This section will be done in next sprints */}
            <div className="h-[100px] w-full" />
          </div>
        </div>
      </Form>
    </StepCardContainer>
  );
};

export { DistrictUsersStep };
