import { useCallback, useId, useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  ScrollArea,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants/modals';
import { useModal } from '~/hooks';
import { FlagCalculationActions } from './FlagCalculationActions';
import { FlagCalculationForm } from './FlagCalculationForm';
import { FlagStepper } from './stepper';
import type React from 'react';
import type { TVisualizerFlag } from '~/services';

type TFlagCalculationModalProps = {
  flagData?: TVisualizerFlag | null;
  flags?: TVisualizerFlag[];
  onClose?: () => void;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const FlagCalculationModal: React.FC<TFlagCalculationModalProps> = (props) => {
  const { flagData = null, flags, onClose, onSubmit, onSubmitError, onSubmitSuccess } = props;

  const formId = useId();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isOpen, openModal, closeModal } = useModal(ModalType.FLAG_CALCULATION);

  const title = useMemo(() => (flagData ? 'Edit Flag Calculation' : 'New Flag Calculation'), [flagData]);

  const cancelClickHandler = useCallback(() => {
    closeModal();
    onClose?.();
  }, [closeModal, onClose]);

  const modalOpenChangeHandler = useCallback(() => {
    if (isOpen) {
      cancelClickHandler();
    } else {
      openModal();
    }
  }, [isOpen, openModal, cancelClickHandler]);

  const submitHandler = useCallback(() => {
    setIsLoading(true);
    onSubmit?.();
  }, [onSubmit]);

  const onSubmitSuccessHandler = useCallback(() => {
    setIsLoading(false);
    onSubmitSuccess?.();
  }, [onSubmitSuccess]);

  const onSubmitErrorHandler = useCallback(() => {
    setIsLoading(false);
    onSubmitError?.();
  }, [onSubmitError]);

  return (
    <Dialog open={isOpen} onOpenChange={modalOpenChangeHandler}>
      <FlagStepper.Scoped>
        <DialogContent className="flex max-h-[calc(100vh-32px)] w-[840px] flex-col">
          <DialogHeader className="flex-row items-center justify-between">
            <DialogTitle className="leading-7 tracking-normal">{title}</DialogTitle>
            <DialogClose asChild>
              <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
            </DialogClose>
          </DialogHeader>
          <Separator />
          <DialogDescription asChild>
            <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
              <div className="w-full p-6 pb-12 pr-[30px]">
                <FlagCalculationForm
                  formId={formId}
                  flags={flags}
                  flagData={flagData}
                  onSubmit={submitHandler}
                  onSubmitSuccess={onSubmitSuccessHandler}
                  onSubmitError={onSubmitErrorHandler}
                />
              </div>
            </ScrollArea>
          </DialogDescription>
          <Separator />
          <FlagCalculationActions formId={formId} flagData={flagData} isLoading={isLoading} onCancel={cancelClickHandler} />
        </DialogContent>
      </FlagStepper.Scoped>
    </Dialog>
  );
};
