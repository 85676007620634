import { useMutation } from '@tanstack/react-query';
import { createCustomDistrictMapping } from './create-custom-district-mapping';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError } from 'axios';

export const useCreateCustomMapping = () => {
  return useMutation<{ id: number }, AxiosError, { districtId: string }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.CREATE_DISTRICT_CUSTOM_MAPPING],
    mutationFn: createCustomDistrictMapping,
  });
};
