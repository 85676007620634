import { useCallback, useState } from 'react';
import { BubbleMenu } from '@tiptap/react';
import { LinkEditBlock } from './LinkEditBlock';
import { LinkInlineControls } from './LinkInlineControls';
import type { EditorState } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import type { Editor } from '@tiptap/react';
import type React from 'react';

type TLinkBubbleMenuProperties = {
  editor: Editor;
};

type TLinkAttributes = {
  href: string;
  target: string;
};

type TShouldShowProperties = {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  oldState?: EditorState;
  from: number;
  to: number;
};

export const LinkBubbleMenu: React.FC<TLinkBubbleMenuProperties> = ({ editor }) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [linkAttributes, setLinkAttributes] = useState<TLinkAttributes>({ href: '', target: '' });
  const [selectedText, setSelectedText] = useState<string>('');

  const updateLinkState = useCallback(() => {
    const { from, to } = editor.state.selection;
    const { href, target } = editor.getAttributes('link') as TLinkAttributes;
    const text = editor.state.doc.textBetween(from, to, ' ');

    setLinkAttributes({ href, target });
    setSelectedText(text);
  }, [editor]);

  const shouldShow = useCallback(
    ({ editor: _editor, from, to }: TShouldShowProperties) => {
      if (from === to) {
        return false;
      }
      const { href } = _editor.getAttributes('link');

      if (href) {
        updateLinkState();
        return true;
      }
      return false;
    },
    [updateLinkState],
  );

  const handleEdit = useCallback(() => {
    setShowEdit(true);
  }, []);

  const onSetLink = useCallback(
    (url: string, text?: string, openInNewTab?: boolean) => {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .insertContent({
          type: 'text',
          text: text || url,
          marks: [
            {
              type: 'link',
              attrs: {
                href: url,
                target: openInNewTab ? '_blank' : '',
              },
            },
          ],
        })
        .setLink({ href: url, target: openInNewTab ? '_blank' : '' })
        .run();
      setShowEdit(false);
      updateLinkState();
    },
    [editor, updateLinkState],
  );

  const onUnsetLink = useCallback(() => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    setShowEdit(false);
    updateLinkState();
  }, [editor, updateLinkState]);

  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      tippyOptions={{
        placement: 'bottom-start',
        onHidden: () => setShowEdit(false),
      }}
    >
      {showEdit
        ? (
            <LinkEditBlock
              defaultUrl={linkAttributes.href}
              defaultText={selectedText}
              defaultIsNewTab={linkAttributes.target === '_blank'}
              onSave={onSetLink}
              className="bg-popover text-popover-foreground w-full min-w-80 rounded-md border p-4 shadow-md outline-none"
            />
          )
        : (
            <LinkInlineControls onClear={onUnsetLink} url={linkAttributes.href} onEdit={handleEdit} />
          )}
    </BubbleMenu>
  );
};
