import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { Button, Heading, Text } from '@purple/ui';
import { DataMappingModal } from '~/components/Modals/District';
import { AdminRoutes, ModalType } from '~/constants';
import { useAppSelector, useModal } from '~/hooks';
import { DISTRICTS_QUERY_KEYS, useCreateCustomMapping, useSisMappingList } from '~/services';
import { districtIdSelector } from '~/store/features/district-set-up';
import { showSuccessToast } from '~/utils/toasts';
import { StepCardContainer } from '../../StepCardContainer';
import { DataItem } from './DataItem';
import { DataMappingSkeleton } from './DataMappingSkeleton';
import type { TDistrictMapping } from '~/services';

export const SisDataMapping = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { openModal } = useModal(ModalType.DATA_MAPPING);

  const currentDistrictId = useAppSelector(districtIdSelector);

  const [selectedData, setSelectedData] = useState<TDistrictMapping | null>(null);

  const { isLoading, data } = useSisMappingList(currentDistrictId?.toString() ?? '');
  const { mutate, isPending } = useCreateCustomMapping();

  const createMappingHandler = (dataItem: TDistrictMapping) => {
    setSelectedData(dataItem);
    openModal();
  };

  const createCustomMappingHandler = () => {
    mutate(
      { districtId: currentDistrictId?.toString() ?? '' },
      {
        onSuccess: (responseData) => {
          setSelectedData({
            id: responseData.id,
            name: '',
            is_mapping_completed: false,
            is_required: false,
            mapping_type: '',
            last_sync_at: null,
            is_last_sync_successful: null,
          });
          queryClient.invalidateQueries({
            queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, currentDistrictId?.toString() ?? ''],
          });
          openModal();
        },
      },
    );
  };

  const saveAsDraftHandler = useCallback(() => {
    showSuccessToast('System message', 'Draft was updated');
    navigate(AdminRoutes.App.Districts.Root.path);
  }, [navigate]);

  return (
    <>
      <StepCardContainer isStepValid isLoading={false} onSave={saveAsDraftHandler}>
        <section className="flex w-full flex-col gap-4">
          <div className="flex flex-col gap-1">
            <Heading variant="size-18" type="heading-500">
              District Data uploading & mapping
            </Heading>
            <Text variant="size-14" type="body-400" className="text-grey-700">
              Map fields from district data files to fields in PurpleSENSE.
            </Text>
          </div>
          {isLoading && !data && <DataMappingSkeleton />}
          {data && !isLoading && (
            <div className="flex w-full flex-col gap-2">
              {data.map((dataItem) => (
                <DataItem
                  key={dataItem.id}
                  dataTitle={dataItem.name}
                  isCompleted={dataItem.is_mapping_completed}
                  isRequired={dataItem.is_required}
                  onClick={() => createMappingHandler(dataItem)}
                />
              ))}
              <Button
                variant="tertiary"
                size="small"
                iconLeft={
                  isPending ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="plus" />
                }
                className="w-fit"
                onClick={createCustomMappingHandler}
              >
                Add Custom Students Data
              </Button>
            </div>
          )}
        </section>
      </StepCardContainer>
      <DataMappingModal selectedMapping={selectedData} districtId={currentDistrictId} />
    </>
  );
};
