import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TUsersDeactivateCheck } from './user.types';

export const checkUsersDeactivation = async ({ usersIds }: { usersIds: string[] }) => {
  const response = await axiosInstance.post<TUsersDeactivateCheck>(ADMIN_USER_MANAGEMENT.CHECK_DEACTIVATION, {
    users: usersIds,
  });
  return response.data;
};
