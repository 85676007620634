import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictFilterOptions } from './get-district-filter-options';
import type { TDistrictFilterOptionsRequestParameters } from './districts.types';

export const useDistrictFilterOptions = (parameters?: TDistrictFilterOptionsRequestParameters) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.FILTER_OPTIONS, parameters],
    queryFn: () => getDistrictFilterOptions(parameters),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch district filter options data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
