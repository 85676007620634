import { useMemo } from 'react';
import { Progress, Text, useStepper } from '@purple/ui';
import { useAppSelector } from '~/hooks';
import { maxDistrictStepSelector } from '~/store/features/district-set-up';
import { SET_UP_STEP_TITLE, SET_UP_STEPS } from '../../constants';

const SetUpProgressBar = () => {
  const { currentStep, activeStep } = useStepper();

  const maxDistrictStep = useAppSelector(maxDistrictStepSelector);

  const stepTitle = useMemo(() => {
    if (currentStep?.id && currentStep?.id in SET_UP_STEP_TITLE) {
      return SET_UP_STEP_TITLE[currentStep.id as keyof typeof SET_UP_STEP_TITLE];
    }
    return '';
  }, [currentStep]);

  // NOTE: we show max progress that user reached ever
  const progressValue = useMemo(() => {
    const stepsCount = SET_UP_STEPS.length;
    return (maxDistrictStep / (stepsCount - 1)) * 100;
  }, [maxDistrictStep]);

  return (
    <div className="flex flex-col gap-3">
      <Text variant="size-16" type="body-400" className="text-grey-950">
        Step
        {' '}
        {activeStep + 1}
        :
        {' '}
        <span className="font-semibold">{stepTitle}</span>
      </Text>
      <Progress value={progressValue} className="h-3" valueClassName="text-[10px] leading-3" />
    </div>
  );
};

export { SetUpProgressBar };
