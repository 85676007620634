import { useFormContext } from 'react-hook-form';
import { cn, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@purple/ui';
import { MAXIMUM_FLAGS_OPTIONS } from '../const';
import type { z } from 'zod';
import type { numberOfMaxFlagsSchema } from '../schema';

type TNumberOfMaxFlagsFieldProps = {
  className?: string;
};

export const NumberOfMaxFlagsField: React.FC<TNumberOfMaxFlagsFieldProps> = (props) => {
  const { className } = props;

  const ctx = useFormContext<z.infer<typeof numberOfMaxFlagsSchema>>();

  return (
    <FormField
      control={ctx.control}
      name="number_of_maximum_flags"
      render={({ field, fieldState }) => (
        <FormItem className={cn('col-span-2', className)}>
          <FormLabel required>Number of Maximum Flags</FormLabel>
          <ComboBox modal>
            <FormControl>
              <ComboBoxTrigger
                isError={!!fieldState.error}
                placeholder="Select number"
                selectedLabel={MAXIMUM_FLAGS_OPTIONS.find((option) => option.value === field.value)?.label}
              />
            </FormControl>
            <ComboBoxContent searchPlaceholder="Search number..." emptyContent="Number not found.">
              {MAXIMUM_FLAGS_OPTIONS.map(({ label, value }) => (
                <ComboBoxItem
                  key={value}
                  keywords={[label, value.toString()]}
                  value={value.toString()}
                  selected={value === field.value}
                  onSelect={(value) => field.onChange(value === 'N/A' ? value : Number(value))}
                >
                  {label}
                </ComboBoxItem>
              ))}
            </ComboBoxContent>
          </ComboBox>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
