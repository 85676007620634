import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { convertSafRuleFilters } from './converter';
import { getSafsRuleFilters } from './get-safs-rule-filters';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TAdminSafRuleFiltersRequestParameters, TAdminSafRuleFiltersResponse } from './saf.types';

export const useSafRuleFilters = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminSafRuleFiltersRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, data, ...rest } = useQuery<TAdminSafRuleFiltersResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.SAF_RULE_LIST_FILTERS, requestParameters],
    queryFn: () => getSafsRuleFilters(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Unable to fetch safs routing rules filters list');
    }
  }, [isError, error]);

  return { isError, error, data: data ? convertSafRuleFilters(data) : data, ...rest };
};
