import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { SchoolDetailsView } from '@purple/shared-types';
import { AccessDenied, AppTabs, Button } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import {
  BasicDetailsTab,
  SafInsightsTab,
  SchoolContactsTab,
  SchoolHierarchyStructureTab,
  SchoolInfo,
  SchoolUsersTab,
} from './components';
import type { TAppTab } from '@purple/ui';

export const SchoolDetailsPage: React.FC = () => {
  const schoolDetailsTabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: SchoolDetailsView.DETAILS,
        className: 'shadow-none',
        content: <BasicDetailsTab />,
        permissions: [],
      },
      {
        label: 'Hierarchy Structure',
        value: SchoolDetailsView.HIERARCHY,
        className: 'shadow-none',
        content: <SchoolHierarchyStructureTab />,
        permissions: [],
      },
      {
        label: 'SAF Insights',
        value: SchoolDetailsView.SAF_INSIGHTS,
        className: 'shadow-none',
        content: <SafInsightsTab />,
        permissions: [],
      },
      {
        label: 'Users',
        value: SchoolDetailsView.USERS,
        className: 'shadow-none',
        content: <SchoolUsersTab />,
        permissions: [],
      },
      {
        label: 'Contacts',
        value: SchoolDetailsView.CONTACTS,
        className: 'shadow-none',
        content: <SchoolContactsTab />,
        permissions: [],
      },
    ],
    [],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs: schoolDetailsTabs,
  });

  return (
    <div className="flex w-full flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Schools.Root.makePath()}>
          Back to Schools
        </Link>
      </Button>
      <SchoolInfo />
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs
              tabs={schoolDetailsTabs}
              listClassName="pl-2"
              value={activeTab}
              onValueChange={tabChangeHandler}
            />
          )}
    </div>
  );
};
