import axiosInstance from '~/services/axios-config';
import { SAF_ENDPOINTS } from '../endpoints';

export const safRuleBulkDelete = async ({ ids }: {
  ids: string[];
}) => {
  const response = await axiosInstance.post<void>(
    SAF_ENDPOINTS.BULK_RULE_DELETE,
    {
      ids,
    },
  );
  return response.data;
};
