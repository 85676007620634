import { Heading } from '@purple/ui';

export const ServerErrorPage: React.FC = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex w-[500px] flex-col items-center justify-center gap-5">
        <Heading tag="h1" variant="size-28" type="heading-700">
          Server Error
        </Heading>
      </div>
    </div>
  );
};
