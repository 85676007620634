import { type FC, useMemo, useState } from 'react';
import React from 'react';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Heading,
  Input,
  Label,
  Text,
} from '@purple/ui';
import { ColumnNameFormItem } from './ColumnNameFormItem';
import type * as z from 'zod';
import type { mappingFormSchema } from './schema';

type TMappingStepControllerProperties = {
  currentStep: number;
  maxSteps: number;
  isCustomData: boolean;
  mappingColumns: string[];
  form: UseFormReturn<z.infer<typeof mappingFormSchema>>;
};
export const ColumMappingForm: FC<TMappingStepControllerProperties> = ({
  form,
  currentStep,
  maxSteps,
  mappingColumns,
  isCustomData,
}) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'mapping',
  });

  const columnsSelectOptions = useMemo(() => {
    return mappingColumns.map((column) => ({
      label: column,
      value: column,
    }));
  }, [mappingColumns]);

  const defaultImportedColumnsCount = useMemo(() => mappingColumns.length, [mappingColumns]);

  const [importedColumnsAmount, setImportedColumnsAmount] = useState(defaultImportedColumnsCount);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Heading variant="size-18" type="heading-600">
            Map Header columns
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-600">
            Confirm each item attribute is matched to the correct column in your import file.
          </Text>
        </div>
        <Text variant="size-16" type="body-400">
          Step:
          {' '}
          <span className="font-semibold">{currentStep + 1}</span>
          {' '}
          of
          {' '}
          <span className="font-semibold">{maxSteps}</span>
        </Text>
      </div>
      <div className="flex flex-col gap-2">
        <Text variant="size-14" type="body-400" className="text-grey-600">
          {importedColumnsAmount}
          {' '}
          columns will be imported
        </Text>
        <div className="grid grid-cols-2 items-center gap-x-6 gap-y-4">
          {isCustomData && (
            <>
              <Label className="text-sm">
                Data File Name
                <span className="text-error-main">*</span>
              </Label>
              <FormField
                control={form.control}
                name="name"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input {...field} placeholder="Input file name here" isError={!!fieldState.error} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <Label className="text-sm">
            File Path
            <span className="text-error-main">*</span>
          </Label>
          <FormField
            control={form.control}
            name="file_path"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Input file path here" isError={!!fieldState.error} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
      <div>
        <div className="flex items-center border-b border-grey-300">
          <Text variant="size-12" type="body-600" className="w-1/2 px-2 py-4 uppercase text-grey-600">
            System Name
          </Text>
          <Text variant="size-12" type="body-600" className="w-1/2 px-2 py-4 uppercase text-grey-600">
            Mapping to column name in file
          </Text>
        </div>
      </div>
      <div className="grid grid-cols-2 items-center gap-x-6 gap-y-4">
        {fields.map((column, index) => (
          <React.Fragment key={column.id}>
            <ColumnNameFormItem
              control={form.control}
              name={`mapping.${index}.display_name`}
              value={column.display_name ?? ''}
              displayName={column.display_name ?? ''}
              isRequired={column.is_required ?? false}
              isCustom={column.is_custom ?? false}
            />
            <div className="flex items-center gap-2">
              <FormField
                control={form.control}
                name={`mapping.${index}.value`}
                render={({ field, fieldState }) => {
                  return (
                    <FormItem className="w-full">
                      <div className="flex w-full items-center gap-2">
                        <PurpleIcon
                          name={field.value ? 'check-circle' : 'exclamation-circle'}
                          className={cn('text-success-main', {
                            'text-error-main': !field.value && column.is_required,
                            'text-brand-yellow': !field.value && !column.is_required,
                          })}
                        />
                        <FormControl>
                          <ComboBox modal>
                            <ComboBoxTrigger
                              {...field}
                              placeholder="Select column"
                              selectedLabel={field.value || undefined}
                              isError={!!fieldState.error}
                              hasClearButton
                              onClear={() => form.setValue(`mapping.${index}.value`, '')}
                            />
                            <ComboBoxContent searchPlaceholder="Search column name..." emptyContent="No columns found.">
                              {columnsSelectOptions.map((item) => (
                                <ComboBoxItem key={item.value} value={item.value} onSelect={field.onChange}>
                                  {item.label}
                                </ComboBoxItem>
                              ))}
                            </ComboBoxContent>
                          </ComboBox>
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              {column.is_custom && (
                <Button
                  type="button"
                  variant="secondary"
                  size="icon_32"
                  className="border-0"
                  onClick={() => {
                    remove(index);
                    setImportedColumnsAmount(importedColumnsAmount - 1);
                  }}
                  iconLeft={<PurpleIcon name="trash" className="shrink-0 text-error-main" />}
                />
              )}
            </div>
          </React.Fragment>
        ))}
        <Button
          type="button"
          variant="tertiary"
          size="small"
          iconLeft={<PurpleIcon name="plus" />}
          className="w-fit"
          onClick={() => {
            append({
              name: `custom_filed_${fields.length + 1}`,
              display_name: `Custom Field ${fields.length + 1}`,
              value: '',
              is_mapped: false,
              is_required: false,
              is_custom: true,
            });
            setImportedColumnsAmount(importedColumnsAmount + 1);
          }}
        >
          Add Custom Filed
        </Button>
      </div>
    </div>
  );
};
