import { useCallback, useMemo } from 'react';
import { Button, DialogFooter, OfflineController } from '@purple/ui';
import { FlagStepper } from './stepper';
import type { TVisualizerFlag } from '~/services';

type TFlagCalculationActionsProps = {
  formId: string;
  flagData?: TVisualizerFlag | null;
  isLoading?: boolean;
  onPreviousStep?: () => void;
  onCancel?: () => void;
};

export const FlagCalculationActions: React.FC<TFlagCalculationActionsProps> = (props) => {
  const { formId, flagData, isLoading = false, onCancel, onPreviousStep } = props;

  const { isFirst, isLast, reset, prev } = FlagStepper.useStepper();

  const isUpdate = useMemo(() => !!flagData, [flagData]);
  const buttonText = useMemo(
    () =>
      isFirst
        ? 'Next Step'
        : isUpdate && !isLoading
          ? 'Update'
          : isUpdate && isLoading
            ? 'Updating...'
            : !isUpdate && isLoading
                ? 'Creating...'
                : 'Create',
    [isFirst, isUpdate, isLoading],
  );

  const cancelClickHandler = useCallback(() => {
    reset();
    onCancel?.();
  }, [onCancel, reset]);

  const previousStepClickHandler = useCallback(() => {
    prev();
    onPreviousStep?.();
  }, [onPreviousStep, prev]);

  return (
    <DialogFooter className="flex w-full shrink-0 flex-row items-center justify-between gap-4">
      <Button type="button" variant="tertiary" onClick={cancelClickHandler}>
        Cancel
      </Button>
      <div className="flex items-center gap-4">
        {isLast && (
          <Button type="button" variant="tertiary" onClick={previousStepClickHandler}>
            Previous Step
          </Button>
        )}
        <OfflineController>
          <Button type="submit" form={formId} isLoading={isLoading}>
            {buttonText}
          </Button>
        </OfflineController>
      </div>
    </DialogFooter>
  );
};
