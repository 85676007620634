import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUserDetails } from './get-user-details';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TUserDetailsInfo } from './user.types';

export const useUserDetails = ({
  enabled = true,
  userId,
}:
{ userId?: string; enabled?: boolean }) => {
  const id = userId || '';

  const { isError, error, ...rest } = useQuery<TUserDetailsInfo, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USER_DETAILS, userId],
    queryFn: () => getUserDetails({ userId: id }),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch user details');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
