import { useState } from 'react';
import { useKeyPress } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { Button, HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger, Input, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { useUpdateSchoolDetails } from '~/services';
import type { TSchoolItem } from '@purple/shared-types';

type TSchoolNameCellProps = {
  school: TSchoolItem;
};

export const SchoolNameCell: React.FC<TSchoolNameCellProps> = (props) => {
  const { school } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [savedValue, setSavedValue] = useState<string>(school.name);
  const [value, setValue] = useState<string>(school.name);

  const { mutate: updateSchool, isPending: isUpdatePending } = useUpdateSchoolDetails();

  const editClickHandler = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation();
    setIsEditing(true);
    setSavedValue(value);
  };

  const saveClickHandler = () => {
    updateSchool({ id: school.id, name: value }, {
      onSuccess: () => {
        setSavedValue(value);
        setIsEditing(false);
      },
    });
  };

  const cancelClickHandler = () => {
    setValue(savedValue);
    setIsEditing(false);
  };

  useKeyPress('Escape', cancelClickHandler);
  useKeyPress('Enter', saveClickHandler);

  return isEditing
    ? (
        <HoverCard open={isEditing}>
          <HoverCardTrigger asChild>
            <Input
              type="text"
              value={value}
              sizeVariant="small"
              placeholder="Enter school name"
              containerClassName="w-full"
              className="w-full min-w-16 max-w-[320px] shrink grow rounded px-3 py-1.5"
              onChange={(evt) => setValue(evt.target.value)}
              onClick={(evt) => evt.stopPropagation()}
            />
          </HoverCardTrigger>
          <HoverCardPortal>
            <HoverCardContent
              align="end"
              sideOffset={0}
              className="flex w-auto gap-1 border-none bg-transparent p-0 shadow-none"
              onClick={(evt) => evt.stopPropagation()}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="primary"
                    size="icon_32"
                    className="shadow-custom-medium"
                    isLoading={isUpdatePending}
                    disabled={value === '' || value === savedValue}
                    iconLeft={<PurpleIcon name="check" className="shrink-0" />}
                    onClick={saveClickHandler}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Save</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="secondary"
                    size="icon_32"
                    className="border-0 shadow-custom-medium"
                    iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                    onClick={cancelClickHandler}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>Cancel</TooltipContent>
                </TooltipPortal>
              </Tooltip>
            </HoverCardContent>
          </HoverCardPortal>
        </HoverCard>
      )
    : (
        <div className="flex w-fit items-center gap-1">
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="line-clamp-1">
                {value || '-'}
              </span>
            </TooltipTrigger>
            {value && (
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {value}
                </TooltipContent>
              </TooltipPortal>
            )}
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="tertiary"
                size="icon_32"
                className="size-6 shrink-0"
                iconLeft={<PurpleIcon name="pencil" className="size-4 shrink-0" />}
                onClick={editClickHandler}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Edit name</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </div>
      );
};
