import { z } from 'zod';
import { SafSubmitterType } from '@purple/shared-types';

export const safProcessStepSchema = z
  .object({
    can_student_submit_anonymously: z.boolean(),
    can_teacher_submit_anonymously: z.boolean(),
    can_parent_submit_anonymously: z.boolean(),
    can_community_member_submit_anonymously: z.boolean(),
    introduction_text: z.string(),
    acknowledgement_text: z.string(),
    disclaimer_text: z.string(),
    special_ed_text: z.string(),
    needs: z.array(
      z.object({
        id: z.string().optional(),
        name: z.string().trim().min(1, { message: 'Name is required.' }),
        submitter_types: z.array(z.string()),
      }),
    ),
    show_city_or_region_hierarchy_field: z.boolean(),
    email: z
      .string()
      .trim()
      .max(128, { message: 'Email must be less than 128 characters.' })
      .refine((value) => value === '' || z.string().email().safeParse(value).success, {
        message: 'Invalid email format.',
      }),
  })
  .superRefine((data, context) => {
    if (data.needs.length === 0) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one need type is required.',
      });
    }
    if (
      Object.values(SafSubmitterType).some((type) => data.needs.every((need) => !need.submitter_types.includes(type)))
    ) {
      context.addIssue({
        path: ['needs'],
        code: z.ZodIssueCode.custom,
        message: 'At least one submitter type is required for each need type.',
      });
    }
  });
