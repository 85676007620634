import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictDetails } from './get-district-details';
import type { TDistrictDetailsPayload } from './districts.types';

export const useDistrict = (parameters: TDistrictDetailsPayload & { enabled?: boolean }) => {
  const { enabled, ...payload } = parameters;

  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.DETAILS, payload.id?.toString()],
    queryFn: () => getDistrictDetails(payload),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch district details', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
