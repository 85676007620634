import { useCallback } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
// components
import { LocalStorageKey } from '@purple/shared-utils';
import { CallToActionModal } from '~/components/Modals/CallToActionModal';
import { ModalType } from '~/constants';
import { useLogOut } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { SheetMenu } from '../SheetMenu';
import { UserDropdown } from '../UserDropdown';
// types
import type React from 'react';

export const MainHeader: React.FC = () => {
  const { mutate, isPending } = useLogOut();

  const logoutHandler = useCallback(() => {
    const refreshToken = localStorage.getItem(LocalStorageKey.Admin.Token.RefreshToken);

    if (refreshToken) {
      return mutate({ refresh: refreshToken });
    }
    showErrorToast('System message', 'Could not log out. You have some issues with your account');
  }, [mutate]);
  return (
    <>
      <header className="sticky top-0 z-10 flex min-h-[68px] w-full items-center justify-between gap-4 border-b border-b-grey-200 bg-white px-8 py-4">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
        </div>
        <div className="flex items-center gap-4">
          {/* TODO: Search bar goes here when time comes :) */}
          {/* TODO: Implement notification with popover and BE logic */}
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="tertiary"
                size="icon_32"
                type="button"
                aria-label="Notifications"
                className="size-9"
                iconLeft={<PurpleIcon name="bell" className="size-5 shrink-0" />}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Notifications</TooltipContent>
            </TooltipPortal>
          </Tooltip>
          <UserDropdown />
        </div>
      </header>
      <CallToActionModal
        modalName={ModalType.LOG_OUT}
        modalTitle="Log Out?"
        modalDescription="Logout from the system modal window"
        modalTextContent="Are you sure you want to log out?"
        primaryButtonText="Yes, Log Out"
        secondaryButtonText="Cancel"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={logoutHandler}
        isLoading={isPending}
      />
    </>
  );
};
