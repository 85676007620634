import { Skeleton } from '@purple/ui';
import { DistrictCustomPagesSkeleton } from './DistrictCustomPagesSkeleton';

export const DistrictNavigationSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-[79px]" />
      </div>
      <div className="flex w-full flex-col gap-4 px-6 py-4">
        <div className="flex flex-col gap-0.5">
          <Skeleton className="h-5 w-64 shrink" />
          <Skeleton className="h-5 w-24 shrink" />
        </div>
        <DistrictCustomPagesSkeleton />
      </div>
    </div>
  );
};
