import axiosInstance from '../axios-config';
import { DASHBOARD_ENDPOINTS } from '../endpoints';
import type { TDashboardBaseRequestParameters, TDashboardTopStatisticResponse } from './dashboard.types';

export const getTopStatistic = async (parameters?: TDashboardBaseRequestParameters) => {
  const response = await axiosInstance.get<TDashboardTopStatisticResponse>(DASHBOARD_ENDPOINTS.TOP_STATISTIC, {
    params: {
      ...parameters,
      districts: parameters?.districts?.join(','),
    },
  });

  return response.data;
};
