import { createSlice } from '@reduxjs/toolkit';
import { DaysOfWeek, DISTRICT_SETUP_STEP, type TDistrictSetupStep, type TDistrictStatus } from '@purple/shared-types';
import { timeStringToDate } from '@purple/shared-utils';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  TDistrictLeaderUserStep,
  TDistrictSafProcess,
  TDraftDistrictBasicInfo,
  TDraftDistrictBranding,
} from '~/services';

const DEFAULT_BUSINESS_DAY_START = timeStringToDate('09:00:00').toISOString();
const DEFAULT_BUSINESS_DAY_END = timeStringToDate('17:00:00').toISOString();

const DEFAULT_BRANDING_COLOR = '#4554A5';

export type TDistrictSetUpState = {
  maxStep: number;
  districtId: number | null;
  status: TDistrictStatus;
  // This is last completed step
  current_setup_step: TDistrictSetupStep;
  responsible_for_setup: string | null;
  // Below can be states for the steps
  basic: TDraftDistrictBasicInfo;
  branding: TDraftDistrictBranding;
  safProcess: Omit<TDistrictSafProcess, 'id'>;
  leaderUsersStep: TDistrictLeaderUserStep;
};

const initialState: TDistrictSetUpState = {
  maxStep: 0,
  districtId: null,
  basic: {
    name: '',
    states: [],
    regions: [],
    subdomain: '',
    school_year_start_datetime: '',
    school_year_end_datetime: '',
    timezone: '',
    business_days: [
      {
        is_active: true,
        day: DaysOfWeek.MONDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: true,
        day: DaysOfWeek.TUESDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: true,
        day: DaysOfWeek.WEDNESDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: true,
        day: DaysOfWeek.THURSDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: true,
        day: DaysOfWeek.FRIDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: false,
        day: DaysOfWeek.SATURDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
      {
        is_active: false,
        day: DaysOfWeek.SUNDAY,
        start_time: DEFAULT_BUSINESS_DAY_START,
        end_time: DEFAULT_BUSINESS_DAY_END,
      },
    ],
    holidays: [
      {
        name: '',
        start_datetime: new Date().toDateString(),
        end_datetime: new Date().toDateString(),
        is_recurring: false,
      },
    ],
  },
  branding: {
    logo: null,
    branding_color: DEFAULT_BRANDING_COLOR,
  },
  safProcess: {
    can_student_submit_anonymously: true,
    can_teacher_submit_anonymously: true,
    can_parent_submit_anonymously: true,
    can_community_member_submit_anonymously: true,
    introduction_text: null,
    acknowledgement_text: null,
    disclaimer_text: null,
    special_ed_text: null,
    needs: [],
    show_city_or_region_hierarchy_field: true,
    email: '',
  },
  leaderUsersStep: {
    no_district_leader_available: false,
    users: [],
  },
  status: 'draft',
  // This is last completed step
  current_setup_step: DISTRICT_SETUP_STEP.INITIAL,
  responsible_for_setup: null,
};

export const districtSetUpSlice = createSlice({
  name: 'districtSetUp',
  initialState,
  reducers: {
    setDistrictId: (state, action: PayloadAction<number>) => {
      state.districtId = action.payload;
    },
    setStatus: (state, action: PayloadAction<TDistrictStatus>) => {
      state.status = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<TDistrictSetupStep>) => {
      state.current_setup_step = action.payload;
    },
    setResponsibleForSetup: (state, action: PayloadAction<string>) => {
      state.responsible_for_setup = action.payload;
    },
    setMaxStep: (state, action: PayloadAction<number>) => {
      state.maxStep = action.payload;
    },
    setBasic: (state, action: PayloadAction<TDraftDistrictBasicInfo>) => {
      state.basic = action.payload;
    },
    setDistrictSetUp: (_state, action: PayloadAction<TDistrictSetUpState>) => {
      return action.payload;
    },
    resetDistrictSetUp: () => initialState,
  },
});

export const {
  setMaxStep,
  setBasic,
  setDistrictId,
  setStatus,
  setCurrentStep,
  setResponsibleForSetup,
  setDistrictSetUp,
  resetDistrictSetUp,
} = districtSetUpSlice.actions;
export const districtSetUpReducer = districtSetUpSlice.reducer;
