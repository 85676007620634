import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback, useQueryParameter, useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { MultiSelect, MultiSelectItem, SearchInput } from '@purple/ui';
import { DataTable } from '~/components';
import { useDataTable } from '~/hooks';
import { useActionTypes, useDistrictsListBasicInfo } from '~/services';
import { ActionsTabContainer } from '../../components';
import { actionTypesColumns } from './actionTypesColumns';
import type { FC } from 'react';

const DEFAULT_SEARCH_DELAY = 500;
const DEFAULT_DISTRICTS_LIMIT = 50;

export const ActionTypes: FC = () => {
  const [searchParameters] = useSearchParams();

  const { query: districts, onQueryChange } = useQueryParameter({
    queryName: 'districts',
    resetOffset: true,
  });

  const [selectedDistricts, setSelectedDistricts] = useState<string[]>(districts ? districts.split(',') : []);
  const [districtsDebouncedSearchValue, setDistrictsDebouncedSearchValue] = useState<string>('');

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { data: allDistricts, isLoading: isAllDistrictsLoading } = useDistrictsListBasicInfo({
    search: districtsDebouncedSearchValue,
    limit: DEFAULT_DISTRICTS_LIMIT,
  });

  const { data: actionTypes, isLoading: isActionTypesLoading } = useActionTypes({
    parameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      districts: selectedDistricts.join(','),
    },
  });

  const { table } = useDataTable({
    columns: actionTypesColumns,
    data: actionTypes?.results || [],
    rowCount: actionTypes?.count || 0,
  });

  const selectDistrictsOptions = useMemo(() => {
    return (
      allDistricts?.results.map((district) => ({
        label: district.name,
        value: district.id.toString(),
      })) || []
    );
  }, [allDistricts?.results]);

  const districtsDebouncedSearch = useDebouncedCallback((searchQuery: string) => {
    setDistrictsDebouncedSearchValue(searchQuery);
  }, DEFAULT_SEARCH_DELAY);

  const handleOptionChange = useCallback((value: string[]) => {
    onQueryChange(value.join(','), {
      onSuccess: () => {
        setSelectedDistricts(value);
      },
    });
  }, [onQueryChange]);

  return (
    <ActionsTabContainer title="Action Types" className="p-0">
      <DataTable table={table} loading={isActionTypesLoading}>
        <div className="flex items-center gap-4 p-4">
          <MultiSelect
            selectedOptions={selectDistrictsOptions.filter((item) => selectedDistricts.includes(item.value))}
            modalPopover
            placeholder="All Districts"
            showSearch
            className="w-max min-w-72"
            shouldFilter={false}
            disabled={!selectedDistricts}
            loading={isAllDistrictsLoading}
            maxCount={1}
            onSearchChange={districtsDebouncedSearch}
            onOptionChange={handleOptionChange}
          >
            {selectDistrictsOptions.map((option) => (
              <MultiSelectItem key={option.value} value={option.value} option={option} isSelected={selectedDistricts.includes(option.value)}>
                {option.label}
              </MultiSelectItem>
            ))}
          </MultiSelect>
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search"
            className="w-72"
          />
        </div>
      </DataTable>
    </ActionsTabContainer>
  );
};
