import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage, Text, Textarea } from '@purple/ui';
import { useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const descriptionSchema = z
  .object({
    description: z.string().trim(),
  });

  type TDescriptionSectionProps = {
    data: TSchoolBasicDetails;
  };

export const DescriptionSection: React.FC<TDescriptionSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();

  const defaultValues: z.infer<typeof descriptionSchema> = useMemo(
    () => ({
      description: data.description ?? '',
    }),
    [data],
  );

  const form = useForm<z.infer<typeof descriptionSchema>>({
    resolver: zodResolver(descriptionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof descriptionSchema>) => {
    updateSchool(
      {
        id: data.id,
        description: formData.description ?? '',
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Description"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="description"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        {...field}
                        isError={!!fieldState.error}
                        placeholder="Enter description here"
                        responsiveHeight
                        className="min-h-[80px] resize-none"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <Text className="text-balance font-primary text-base font-normal text-grey-950">
              {data.description || 'No description set'}
            </Text>
          )}
    </div>
  );
};
