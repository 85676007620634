import { useCallback } from 'react';
import { StepItem } from '~/components';
import { FlagStepper } from './stepper';
import type { TFlagCalculationStep } from './stepper';

type TFlagCalculationNavigationProps = {
  onStepClick?: (cb: (stepId: TFlagCalculationStep) => void, stepId: TFlagCalculationStep) => void;
};

export const FlagCalculationNavigation: React.FC<TFlagCalculationNavigationProps> = (props) => {
  const { onStepClick } = props;

  const stepper = FlagStepper.useStepper();

  const stepClickHandler = useCallback((stepId: string) => {
    onStepClick?.(stepper.goTo, stepId as TFlagCalculationStep);
  }, [onStepClick, stepper]);

  return (
    <ul className="flex w-full justify-center py-6">
      {stepper.all.map(({ id, title }, index) => (
        <li key={id} className="relative flex min-w-44 items-center justify-center">
          <StepItem
            value={id}
            label={title}
            sequenceNumber={index + 1}
            active={stepper.current.id === id}
            completed={stepper.all.findIndex((step) => step.id === id) < stepper.all.findIndex((step) => step.id === stepper.current.id)}
            showDivider={index !== stepper.all.length - 1}
            orientation="vertical"
            className="static"
            onStepClick={stepClickHandler}
          />
        </li>
      ))}
    </ul>
  );
};
