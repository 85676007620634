import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { formateDateShortMonthWithTime } from '@purple/shared-utils';
import {
  Button,
  cn,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  Heading,
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useActivateSchool, useDeactivateSchool, useSchoolDetails } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { SchoolInfoSkeleton } from './SchoolInfoSkeleton';
import { SchoolTags } from './SchoolTags';

export const SchoolInfo: React.FC = () => {
  const { id } = useParams();

  const { openModal: openStatusModal, closeModal: closeStatusModal } = useModal(ModalType.CHANGE_SCHOOL_STATUS);

  const { data: school, isFetching } = useSchoolDetails(id as string);
  const { mutate: deactivateSchool, isPending: isDeactivatePending } = useDeactivateSchool();
  const { mutate: activateSchool, isPending: isActivatePending } = useActivateSchool();

  const isStatusPending = useMemo(() => isDeactivatePending || isActivatePending, [isDeactivatePending, isActivatePending]);

  const schoolStatusUpdateHandler = useCallback(() => {
    if (!school) {
      return showErrorToast('School not found', 'Please select a school to update its status');
    }

    const updateStatus = school.is_active ? deactivateSchool : activateSchool;

    updateStatus(school.id, {
      onSuccess: () => {
        closeStatusModal();
      },
    });
  }, [school, activateSchool, closeStatusModal, deactivateSchool]);

  if (isFetching || !school) {
    return <SchoolInfoSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex size-[60px] shrink-0 items-center justify-center rounded-lg bg-grey-100">
        <PurpleIcon name="School" className="size-6 text-brand-blue-700" />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Heading tag="h1" variant="size-18" type="heading-600" className="text-base">
            {school.name}
          </Heading>
          <SchoolTags tags={school?.tags ?? []} />
        </div>
        <DescriptionList className="flex-col items-start gap-6 lg:flex-row">
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="whitespace-nowrap text-sm">School Status</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant={school.is_active ? 'success-light' : 'danger'} className="rounded-full">
                {school.is_active ? 'Active' : 'Inactive'}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">SAF Status</DescriptionTerm>
            <DescriptionDetails className="m-0">
              <NumberBadge variant={school.is_hidden_on_safs ? 'warning-light' : 'info'} className="rounded-full">
                {school.is_hidden_on_safs ? 'Hidden' : 'Visible'}
              </NumberBadge>
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Phone</DescriptionTerm>
            <DescriptionDetails className="m-0 whitespace-nowrap text-sm">
              {school.phone || '-'}
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Website</DescriptionTerm>
            <DescriptionDetails className="m-0 whitespace-nowrap text-sm">
              {school.website
                ? (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          asChild
                          variant="link"
                          className="inline-flex h-auto max-w-48 justify-start gap-1 p-0"
                          iconLeft={<PurpleIcon name="external-link" className="shrink-0" />}
                        >
                          <a href={school.website} target="_blank" rel="noreferrer">
                            <span className="line-clamp-1">
                              {new URL(school.website).hostname}
                            </span>
                          </a>
                        </Button>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          {school.website}
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )
                : '-'}
            </DescriptionDetails>
          </DescriptionItem>
          <DescriptionItem className="flex-col items-start gap-1">
            <DescriptionTerm className="text-sm">Last Activity</DescriptionTerm>
            <DescriptionDetails className="m-0 whitespace-nowrap text-sm">
              {school.updated_at ? formateDateShortMonthWithTime(school.updated_at) : '-'}
            </DescriptionDetails>
          </DescriptionItem>
        </DescriptionList>
      </div>
      <DropdownRoot>
        <DropdownTrigger asChild>
          <Button
            type="button"
            variant="secondary"
            size="icon_40"
            className="ml-auto size-10"
            iconLeft={<PurpleIcon name="dots-vertical" className="size-5 shrink-0" />}
          />
        </DropdownTrigger>
        <DropdownContent className="max-w-[150px] gap-1" align="end">
          <DropdownItem
            iconName={school.is_active ? 'X' : 'check'}
            className={cn(
              'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
              {
                'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main':
                  school.is_active ?? false,
              },
            )}
            onSelect={openStatusModal}
          >
            {school.is_active ? 'Deactivate' : 'Activate'}
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
      <CallToActionModal
        modalName={ModalType.CHANGE_SCHOOL_STATUS}
        modalTitle={`${school.is_active ? 'Deactivate' : 'Activate'} School`}
        modalDescription="By changing the status of the school, you will change its access on the Purple Sense platform."
        modalTextContent={`Are you sure you want to ${school.is_active ? 'deactivate' : 'activate'} ${school.name} school?`}
        primaryButtonText={school.is_active ? 'Deactivate' : 'Activate'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={schoolStatusUpdateHandler}
        primaryButtonVariant={school.is_active ? 'destructive_primary' : 'primary'}
        isLoading={isStatusPending}
      />
    </section>
  );
};
