import { Link } from 'react-router-dom';
import { formateDateShortMonth } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { AdminRoutes } from '~/constants';
import { PriorityColumn } from './PriorityColumn';
import { StatusColumn } from './StatusColumn';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafItem } from '~/services/saf';

type TSafsColumns = ColumnDef<TAdminSafItem>[];

export const submittedSafsColumns: TSafsColumns = [
  {
    accessorKey: 'saf_number',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Saf Number" />,
    cell: ({ row: { original } }) =>
      original.saf_number
        ? (
            <Link
              to={AdminRoutes.App.Safs.SafDetail.makePath({
                dynamicParameters: { id: original.id },
              })}
              className="cursor-pointer font-primary text-sm font-medium text-brand-blue-700 hover:underline hover:underline-offset-2"
            >
              {original.saf_number}
            </Link>
          )
        : (
            '—'
          ),
    size: 80,
    meta: { label: 'Saf Number' },
  },
  {
    accessorKey: 'subject',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
    cell: ({ row }) => (
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="line-clamp-1 break-all text-grey-950">{row.getValue('subject')}</span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent align="start" withArrow={false}>
            {row.getValue('subject')}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    ),
    size: 180,
    meta: { label: 'Subject' },
    enableSorting: false,
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Priority" />,
    cell: ({ row }) => (
      row.original.priority ? (<PriorityColumn priority={row.original.priority} />) : <span className="line-clamp-1 break-all text-grey-950">-</span>
    ),
    size: 120,
    meta: { label: 'Priority' },
  },
  {
    accessorKey: 'current_owner__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Owner" />,
    cell: ({ row }) => {
      const owner = row.original.current_owner;

      if (!owner) {
        return <span className="line-clamp-1 break-all text-grey-950">-</span>;
      }

      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{owner?.full_name}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {owner?.full_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Owner' },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row }) => (
      row.original.status ? (<StatusColumn status={row.original.status} />) : <span className="line-clamp-1 break-all text-grey-950">-</span>
    ),
    size: 120,
    meta: { label: 'Status' },
  },
  {
    accessorKey: 'student__last_name',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
    cell: ({ row }) => {
      const student = row.original.student;
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            {student ? <span className="line-clamp-1 cursor-pointer break-all text-brand-blue-700">{student?.full_name}</span> : <span className="line-clamp-1 break-all text-grey-950">-</span>}
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {student?.full_name}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      );
    },
    size: 120,
    meta: { label: 'Student' },
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created On" />,
    cell: ({ row }) => {
      const created_at = row.original.created_at;
      const dateString = created_at ? formateDateShortMonth(created_at) : '-';
      return (
        <span className="line-clamp-1 break-all text-grey-950">{dateString}</span>
      );
    },
    size: 120,
    meta: { label: 'Created On' },
  },
];
