import { useNavigate } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AdminRoutes, ModalType } from '~/constants';
import { useModal } from '~/hooks';
import type { FC } from 'react';
import type { TGroupItem } from '~/services';

type TGroupsActions = {
  groupItem: TGroupItem;
  onSelectGroup: (groupId: string) => void;
};

export const GroupsActions: FC<TGroupsActions> = ({
  groupItem,
  onSelectGroup,
}) => {
  const navigate = useNavigate();

  const { openModal } = useModal(ModalType.DELETE_PERMISSION_GROUP);

  const deleteGroupHandler = () => {
    onSelectGroup(groupItem.id.toString());
    openModal();
  };

  return (
    <DropdownRoot>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <DropdownTrigger asChild>
              <Button
                variant="tertiary"
                size="icon_32"
                className="max-w-8 active:bg-grey-100"
                iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
              />
            </DropdownTrigger>
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Group actions</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DropdownContent className="max-w-[200px] gap-1" align="end">
        <Button
          variant="link"
          onClick={() => navigate(AdminRoutes.App.Users.Permissions.ManagePermissions.makePath({ queries: { groupId: groupItem.id.toString() } }))}
          type="button"
          className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={<PurpleIcon name="pencil" className="size-4" />}
        >
          Manage Group
        </Button>
        <Button
          variant="link"
          onClick={deleteGroupHandler}
          className="w-full justify-start text-error-main hover:bg-error-bg hover:text-error-dark active:bg-error-dark active:text-error-bg"
          iconLeft={<PurpleIcon name="trash" className="size-4" />}
        >
          Delete Group
        </Button>
      </DropdownContent>
    </DropdownRoot>
  );
};
