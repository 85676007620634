import axiosInstance from '../axios-config';
import { ACTIONS_ENDPOINTS } from '../endpoints';
import type { TActionTypesOptionsResponse } from './actions.types';

export const getActionTypesOptions = async (safId: string) => {
  const response = await axiosInstance.get<TActionTypesOptionsResponse>(ACTIONS_ENDPOINTS.ACTION_TYPES_OPTIONS, { params: {
    saf: safId,
  } });
  return response.data;
};
