import { memo, useCallback, useId, useState } from 'react';
import { ActionSectionHeader } from '../ActionSectionHeader';
import { TagsContent } from './TagsContent';
import { TagsEditForm } from './TagsEditForm';
import type { FC } from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TTagsContentProperties = {
  action: TActionDetails;
};

export const TagsSection: FC<TTagsContentProperties> = memo(({ action }) => {
  const formId = useId();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prev) => !prev);
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader
        title="Tags"
        editButtonLabel="Apply Tag"
        editButtonIcon="plus"
        editing={isEditMode}
        loading={isLoading}
        onEdit={toggleEditMode}
        formId={formId}
        onCancel={toggleEditMode}
      />
      {isEditMode ? <TagsEditForm action={action} formId={formId} loadingChange={setIsLoading} editModeChange={setIsEditMode} /> : <TagsContent action={action} />}
    </div>
  );
});
