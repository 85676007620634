import axiosInstance from '../axios-config';
import { CONTACTS_ENDPOINTS } from '../endpoints';
import type { TContactListResponse, TContactsListRequestParameters } from './contacts.types';

export const getContactsList = async (parameters?: TContactsListRequestParameters) => {
  const response = await axiosInstance.get<TContactListResponse>(CONTACTS_ENDPOINTS.ROOT, {
    params: parameters,
  });
  return response.data;
};
