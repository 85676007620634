import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const behaviorNumericalSchema = zodAlwaysRefine(
  z.object({
    iss: floatNumber(
      z.number().gte(0, 'ISS must be greater than or equal to 0'),
      2,
    ).nullish(),
    oss: floatNumber(
      z.number().gte(0, 'OSS must be greater than or equal to 0'),
      2,
    ).nullish(),
    daep: floatNumber(
      z.number().gte(0, 'DAEP must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TBehaviorNumericalSchema = z.infer<typeof behaviorNumericalSchema>;

export const behaviorNumericalSchemaSuperRefine = (data: TFlagCalculationBase<typeof behaviorNumericalSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.BEHAVIOR || data.data_type !== MetricDataType.NUMERICAL) return;

  if (!isNumber(data.iss)) {
    ctx.addIssue({
      path: ['iss'],
      code: z.ZodIssueCode.custom,
      message: 'ISS is required',
    });
  }
  if (!isNumber(data.oss)) {
    ctx.addIssue({
      path: ['oss'],
      code: z.ZodIssueCode.custom,
      message: 'OSS is required',
    });
  }
  if (!isNumber(data.daep)) {
    ctx.addIssue({
      path: ['daep'],
      code: z.ZodIssueCode.custom,
      message: 'DAEP is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
