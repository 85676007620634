import { Skeleton } from '@purple/ui';

export const SchoolHierarchyStructureSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Skeleton className="h-[25px] w-full max-w-[170px]" />
      </div>
      <div className="flex w-full flex-col gap-6 px-6 py-8 lg:flex-row">
        <div className="flex w-full flex-col gap-1">
          <div className="flex w-full items-center gap-2">
            <Skeleton className="size-4" />
            <div className="flex w-full flex-col gap-0.5">
              <Skeleton className="h-3 w-full max-w-[46px]" />
              <Skeleton className="h-4 w-full max-w-[94px]" />
            </div>
          </div>

          <Skeleton className="ml-8 h-5 w-full max-w-[95px]" />
          <div className="ml-8 flex w-full items-center gap-2">
            <Skeleton className="size-4" />
            <div className="flex w-full flex-col gap-0.5">
              <Skeleton className="h-3 w-full max-w-[46px]" />
              <Skeleton className="h-4 w-full max-w-[94px]" />
            </div>
          </div>
          <Skeleton className="ml-8 h-5 w-full max-w-[95px]" />
          <div className="ml-8 flex w-full items-center gap-2">
            <Skeleton className="size-4" />
            <div className="flex w-full flex-col gap-0.5">
              <Skeleton className="h-3 w-full max-w-[46px]" />
              <Skeleton className="h-4 w-full max-w-[94px]" />
            </div>
          </div>
          <Skeleton className="ml-8 h-5 w-full max-w-[95px]" />
          <div className="ml-8 flex w-full items-center gap-2">
            <Skeleton className="size-4" />
            <div className="flex w-full flex-col gap-0.5">
              <Skeleton className="h-3 w-full max-w-[46px]" />
              <Skeleton className="h-4 w-full max-w-[94px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
