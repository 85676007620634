import { cva } from 'class-variance-authority';

export const treeVariants = cva('group flex cursor-pointer items-center gap-2 rounded-lg px-2 py-1 pl-[--hierarchy-left-padding] text-left transition-colors duration-200 hover:bg-grey-50 focus:bg-grey-50 focus-visible:bg-grey-50 active:bg-grey-50/50 active:transition-none', {
  variants: {
    selected: {
      true: 'bg-grey-50 text-grey-700',
    },
  },
  defaultVariants: {
    selected: false,
  },
});

export const treeLineVariants = cva(
  '',
  {
    variants: {
      base: {
        true: 'relative before:absolute before:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width)-var(--hierarchy-line-gap))] before:h-4 before:w-[--hierarchy-line-width] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-es-lg before:border-b before:border-l before:border-brand-blue-200 after:absolute after:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width)-var(--hierarchy-line-gap))] after:h-full after:w-[--hierarchy-line-width] after:-translate-x-1/2 after:border-l after:border-brand-blue-200',
      },
      last: {
        true: 'after:top-0 after:h-1/3',
      },
      hidden: {
        true: 'before:hidden after:hidden',
      },
      first: {
        true: 'before:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width))] after:left-[calc(var(--hierarchy-left-padding)-var(--hierarchy-line-width))]',
      },
    },
    defaultVariants: {
      base: true,
      last: false,
      hidden: false,
      first: false,
    },
  },
);
