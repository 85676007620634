import { snakeCaseToCapitalized } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView, TAdminSafSubCategoryView } from '~/services';

type TServiceCategoriesViewProperties = {
  data: TAdminSafBasicDetailsView;
  categories: TAdminSafSubCategoryView[];
};

const ServiceCategoriesView = ({ data, categories }: TServiceCategoriesViewProperties) => {
  const { service_area_subcategories } = data;

  return (
    <div className="flex w-full flex-col gap-4">
      {categories.map((category) => {
        const { name } = category;

        const foundCategory = service_area_subcategories.find((item) => item.id === category.id);

        const categoryNameLabel = snakeCaseToCapitalized(name);
        const subCategoriesLabel = foundCategory && foundCategory.sub_categories.length ? foundCategory.sub_categories.map((sub_category) => snakeCaseToCapitalized(sub_category.name)).join(', ') : '-';

        return (
          <div key={category.id} className="flex w-full gap-2">
            <Text variant="size-16" type="body-400" className="w-full text-grey-600">{categoryNameLabel}</Text>
            <Text variant="size-16" type="body-500" className="w-full text-grey-950">{subCategoriesLabel}</Text>
          </div>
        );
      })}
    </div>
  );
};

export { ServiceCategoriesView };
