import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateAbsencesThresholds } from './update-absences-thresholds';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerAbsenceThresholdsPayload, TVisualizerAbsenceThresholdsResponse } from './visualizer.types';

export const useUpdateAbsencesThresholds = () => {
  const queryClient = useQueryClient();

  return useMutation<TVisualizerAbsenceThresholdsResponse, AxiosError, TVisualizerAbsenceThresholdsPayload & { shouldRevalidate?: boolean }>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_VISUALIZER_ABSENCES_THRESHOLDS],
    mutationFn: ({ shouldRevalidate: _, ...body }) => updateAbsencesThresholds(body),
    retry: 1,
    onSuccess: (data, { shouldRevalidate = true }) => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_VISUALIZER_ABSENCES_THRESHOLDS, data.id.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.id.toString()],
        });
      }
      showSuccessToast('System message', 'Absences thresholds were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update absences thresholds. Please try again later');
    },
  });
};
