import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictStatus } from './update-district-status';
import type { AxiosError } from '@purple/shared-services';
import type { TUpdateDistrictStatusPayload, TUpdateDistrictStatusResponse } from './districts.types';

export const useUpdateDistrictStatus = () => {
  const queryClient = useQueryClient();

  return useMutation<TUpdateDistrictStatusResponse, AxiosError, TUpdateDistrictStatusPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_STATUS],
    mutationFn: updateDistrictStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.PUBLISHED_DISTRICTS] });
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DETAILS, data.id.toString()] });
      showSuccessToast('System message', 'District status was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update district status. Please try again later');
    },
  });
};
