import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafsListRequestParameters } from './saf.types';

export const exportSafsList = async (requestParameters: TAdminSafsListRequestParameters) => {
  const response = await axiosInstance.get<Blob>(SAF_ENDPOINTS.EXPORT, {
    params: requestParameters,
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return response.data;
};
