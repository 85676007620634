import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionType } from './get-action-type';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActionTypeItem } from './actions.types';

type TUseActionTypeProperties = {
  id: string;
  queryOptions?: TCustomUseQueryOptions<TActionTypeItem, AxiosError>;
};

export const useActionType = ({ id, queryOptions }: TUseActionTypeProperties) => {
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPE, id],
    queryFn: () => getActionType(id),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('Status message', 'Unable to retrieve action type details');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
