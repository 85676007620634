import { endOfWeek, startOfWeek } from 'date-fns';
import { CartesianGrid, LabelList, Line, LineChart, XAxis, YAxis } from 'recharts';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  DateRangePicker,
  ReChartContainer,
  ReChartTooltip,
  ReChartTooltipContent,
  Text,
} from '@purple/ui';
import { CustomTick } from './CustomTick';
import type { FC } from 'react';
import type { ChartConfig, DateRange } from '@purple/ui';

const DEFAULT_DATE_FROM = startOfWeek(new Date());
const DEFAULT_DATE_TO = endOfWeek(new Date());

type THomeLineChartProperties = {
  title: string;
  lineChartData: { label: string; value: number; percentage?: number }[];
  lineChartConfig: ChartConfig;
  /**
   * Start date of the date range.
   * Defaults to the start of the current week.
   */
  dateFrom?: Date | string;
  /**
   * End date of the date range.
   * Defaults to the end of the current week.
   */
  dateTo?: Date | string;
  onDateRangeChange?: (range: DateRange) => void;
  onReportView: () => void;
};

export const HomeLineChart: FC<THomeLineChartProperties> = ({
  lineChartData,
  lineChartConfig,
  title,
  dateFrom = DEFAULT_DATE_FROM,
  dateTo = DEFAULT_DATE_TO,
  onDateRangeChange,
  onReportView,
}) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between gap-2 space-y-0 rounded-none">
        <Text variant="size-16" type="body-600">
          {title}
        </Text>
        <DateRangePicker initialDateFrom={dateFrom} initialDateTo={dateTo} onUpdate={onDateRangeChange} />
      </CardHeader>
      <CardContent className="mt-4">
        <ReChartContainer config={lineChartConfig} className="aspect-auto h-[250px] w-full">
          <LineChart
            accessibilityLayer
            data={lineChartData}
            margin={{
              top: 30,
              right: 30,
              bottom: 30,
            }}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" strokeWidth={1} shapeRendering="crispEdges" />
            <ReChartTooltip content={<ReChartTooltipContent hideLabel />} />
            <Line
              dataKey="value"
              stroke="var(--color-label)"
              strokeWidth={2}
              type="bump"
              dot={{
                fill: 'var(--color-label)',
              }}
              activeDot={{
                r: 6,
              }}
            >
              <LabelList
                position="top"
                offset={10}
                className="fill-grey-title font-primary font-semibold"
                fontSize={12}
              />
            </Line>
            <XAxis
              dataKey="label"
              axisLine={false}
              interval={0}
              className="fill-gray-600 font-primary text-xs"
              label={{ value: 'Date', position: 'left', dy: -10, dx: -20 }}
              tick={<CustomTick />}
            />
            <YAxis
              orientation="left"
              axisLine={false}
              tickLine={false}
              dataKey="value"
              type="number"
              className="fill-gray-600 font-primary text-xs"
              label={{ value: 'Qty', position: 'top', dy: -10, dx: 0 }}
              domain={['dataMin', 'dataMax']}
              allowDecimals={false}
            />
            <YAxis
              orientation="right"
              axisLine={false}
              tickLine={false}
              dataKey="percentage"
              yAxisId="percentage"
              className="fill-gray-600 font-primary text-xs"
              tickFormatter={(value) => `${value.toFixed(1)} %`}
              domain={['dataMin', 'dataMax']}
            />
          </LineChart>
        </ReChartContainer>
      </CardContent>
      <CardFooter className="flex items-center justify-end">
        <Button variant="tertiary" size="small" onClick={onReportView}>
          View Report
        </Button>
      </CardFooter>
    </Card>
  );
};
