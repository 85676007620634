import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from '../actions';
import { SCHOOLS_QUERY_KEYS } from '../schools';
import { createCustomTag } from './create-custom-tag';
import { TAGS_QUERY_KEY } from './tags.const';
import type { AxiosError } from 'axios';
import type { TCustomTag } from '@purple/shared-types';
import type { TCustomUseMutationOptions } from '~/types/query';

export const useCreateCustomTag = (options?: TCustomUseMutationOptions<TCustomTag, AxiosError>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: [TAGS_QUERY_KEY.CREATE_CUSTOM_TAG],
    mutationFn: createCustomTag,
    onSuccess: (_, { object_id }) => {
      queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPE, object_id] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BY_ID, object_id] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BASIC_DETAILS, object_id] });
      showSuccessToast('System message', 'Custom tag created successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Unable to create custom tag');
      }
    },
  });
};
