import { memo, useCallback, useId, useState } from 'react';
import { ActionSectionHeader } from '../ActionSectionHeader';
import { DateTimeContent } from './DateTimeContent';
import { DateTimeEditForm } from './DateTimeEditForm';
import type { FC } from 'react';
import type { TActionDetails } from '@purple/shared-types';

type TDateTimeSectionProps = {
  action: TActionDetails;
};
export const DateTimeSection: FC<TDateTimeSectionProps> = memo(({ action }) => {
  const formId = useId();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prev) => !prev);
  }, []);

  return (
    <div className="flex w-full flex-col gap-2">
      <ActionSectionHeader
        title="Date & Time Details"
        editing={isEditMode}
        loading={isLoading}
        onEdit={toggleEditMode}
        formId={formId}
        onCancel={toggleEditMode}
      />
      {isEditMode ? <DateTimeEditForm action={action} formId={formId} loadingChange={setIsLoading} editModeChange={setIsEditMode} /> : <DateTimeContent action={action} />}
    </div>
  );
});
