import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getStudentVisualizerSetupState } from './get-student-visualizer-setup-state';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';

export const useStudentVisualizerSetupState = ({ districtId = null, enabled }: { districtId?: number | string | null; enabled?: boolean }) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, districtId?.toString()],
    queryFn: () => getStudentVisualizerSetupState(districtId!),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch student visualizer setup state', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
