export const SUBDOMAIN_NAME_REGEX = /^[\dA-Z-]+$/i;

/**
 * This regular expression will match only valid URL address.
 * @see https://stackoverflow.com/a/72685666
 * @example
 * URL_REGEX.test('htt:/google.com'); // false
 * URL_REGEX.test('ww,google.com'); // false
 * URL_REGEX.test('https://google.com'); // true
 * URL_REGEX.test('google.com'); // true
 * URL_REGEX.test('www.google.com'); // true
 */
export const URL_REGEX
  = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[\da-z]+([.-][\da-z]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/gim;
