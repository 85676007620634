import axiosInstance from '../axios-config';
import { DASHBOARD_ENDPOINTS } from '../endpoints';
import type { TDashboardLoginFrequencyRequestParameters, TDashboardLoginFrequencyResponse } from './dashboard.types';

export const getLoginFrequency = async (parameters: TDashboardLoginFrequencyRequestParameters) => {
  const response = await axiosInstance.get<TDashboardLoginFrequencyResponse>(DASHBOARD_ENDPOINTS.LOGIN_FREQUENCY, {
    params: {
      ...parameters,
      districts: parameters?.districts?.join(','),
    },
  });
  return response.data;
};
