import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdminRoutes } from '~/constants';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ROLES_QUERY_KEYS } from './roles.const';
import { updateRole } from './update-role';
import type { AxiosError } from 'axios';
import type { TRoleCreatePayload, TRoleCreateResponse } from './roles.types';

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<TRoleCreateResponse, AxiosError, Partial<TRoleCreatePayload> & { id: string }>({
    mutationKey: [ROLES_QUERY_KEYS.UPDATE_ROLE],
    mutationFn: updateRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ROLES_QUERY_KEYS.LIST_ROLES] });
      showSuccessToast('System message', 'Custom role has been updated successfully');
      navigate(AdminRoutes.App.Users.Roles.Root.path);
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to update a custom role. Check provided information and try again');
      }
    },
  });
};
