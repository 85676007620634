import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictBranding } from './update-district-branding';
import type { AxiosError } from '@purple/shared-services';
import type { TUpdateDistrictBrandingPayload, TUpdateDistrictDetailsResponse } from './districts.types';

export const useUpdateDistrictBranding = () => {
  const queryClient = useQueryClient();

  return useMutation<TUpdateDistrictDetailsResponse, AxiosError, TUpdateDistrictBrandingPayload>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_BRANDING],
    mutationFn: updateDistrictBranding,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DETAILS, data.id.toString()] });
      showSuccessToast('System message', 'District branding were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update district branding data. Please try again later');
    },
  });
};
