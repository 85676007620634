import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSafHistoryTimeline } from './get-saf-history';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TSafHistoryResponse } from '@purple/shared-types';
import type { TAdminSafHistoryRequestParameters } from './saf.types';

export const useSafHistory = ({
  enabled = true,
  requestParameters,
  safId,
}:
{
  requestParameters: TAdminSafHistoryRequestParameters;
  safId?: string;
  enabled?: boolean;
}) => {
  const id = safId || '';

  const { isError, error, ...rest } = useQuery<TSafHistoryResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.HISTORY, safId, requestParameters],
    queryFn: () => getSafHistoryTimeline({ safId: id, requestParameters }),
    enabled: enabled && !!safId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch saf history');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
