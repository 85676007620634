import { useMemo } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { getReadableAddress } from '@purple/shared-utils';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger } from '@purple/ui';
import { GOOGLE_MAP_LIBRARIES } from '~/constants';
import { showErrorToast } from '~/utils/toasts';
import type { TLocation } from '@purple/shared-types';

const DEBOUNCE_DELAY_MS = 700;

const getAddressComponent = (
  components: google.maps.GeocoderAddressComponent[],
  type: string,
  nameType: 'long_name' | 'short_name' = 'long_name',
): string => {
  const component = components.find((c) => c.types.includes(type));
  return component ? component[nameType] : '';
};

type TAddressComboBoxProperties = {
  location?: TLocation | null;
  onChange?: (location: TLocation) => void;
  triggerProps?: React.ComponentProps<typeof ComboBoxTrigger>;
  contentProps?: React.ComponentProps<typeof ComboBoxContent>;
};

export const AddressComboBox: React.FC<TAddressComboBoxProperties> = (props) => {
  const { location = null, triggerProps, contentProps, onChange } = props;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API,
    libraries: GOOGLE_MAP_LIBRARIES,
    language: 'en',
  });

  const locationAddress = useMemo(
    () => location
      ? getReadableAddress(location)
      : undefined,
    [location],
  );

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    debounce: DEBOUNCE_DELAY_MS,
    defaultValue: locationAddress,
    initOnMount: isLoaded,
    requestOptions: {
      language: 'en',
    },
  });

  const selectAddressOptionHandler = async (address: string) => {
    setValue(address, false);

    try {
      const results = await getGeocode({ address });

      const [firstResult] = results;
      if (firstResult) {
        const { lat, lng } = getLatLng(firstResult);

        const addressComponents = firstResult.address_components;
        const addressObject: TLocation = {
          street: getAddressComponent(addressComponents, 'route'),
          city: getAddressComponent(addressComponents, 'locality'),
          state: getAddressComponent(addressComponents, 'administrative_area_level_1', 'short_name'),
          postal_code: getAddressComponent(addressComponents, 'postal_code'),
          latitude: lat,
          longitude: lng,
        };
        onChange?.(addressObject);
      }
    } catch {
      showErrorToast(
        'System message',
        'Could not fetch geocode information. Check the provided information and try again',
      );
    }
  };

  return (
    <ComboBox modal>
      <ComboBoxTrigger placeholder="Select address" selectedLabel={locationAddress} disabled={!ready} {...triggerProps} />
      <ComboBoxContent
        loading={!isLoaded || !ready}
        shouldFilter={false}
        searchPlaceholder="Search address..."
        emptyContent="Address not found."
        onSearchChange={setValue}
        {...contentProps}
      >
        {data.map(({ place_id, description }) => (
          <ComboBoxItem
            key={place_id}
            value={place_id}
            selected={value === place_id}
            onSelect={() => selectAddressOptionHandler(description)}
          >
            {description}
          </ComboBoxItem>
        ))}
      </ComboBoxContent>
    </ComboBox>
  );
};
