import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { convertSafDetailDtoToView } from './converter';
import { getSafBasicDetails } from './get-saf-basic-details';
import { ADMIN_SAF_QUERY_KEYS } from './saf.const';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TAdminSafBasicDetailsResponse } from './saf.types';

type TUseSafBasicDetailsProperties = {
  safId?: string;
  queryOptions?: TCustomUseQueryOptions<TAdminSafBasicDetailsResponse, AxiosError>;
};

export const useSafBasicDetails = ({ safId, queryOptions }: TUseSafBasicDetailsProperties) => {
  const id = safId || '';

  const { isError, error, data, ...rest } = useQuery<TAdminSafBasicDetailsResponse, AxiosError>({
    queryKey: [ADMIN_SAF_QUERY_KEYS.BASIC_DETAILS, safId],
    queryFn: () => getSafBasicDetails({ safId: id }),
    enabled: queryOptions?.enabled && !!safId,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch saf basic details');
    }
  }, [isError, error]);

  return { isError, error, data: data ? convertSafDetailDtoToView(data) : data, ...rest };
};
