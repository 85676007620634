import { DISTRICT_STATUS, DistrictUserStatus } from '@purple/shared-types';

export const DistrictStatusToBadgeVariant = {
  [DISTRICT_STATUS.DRAFT]: 'neutral',
  [DISTRICT_STATUS.PUBLISHED]: 'success-light',
  [DISTRICT_STATUS.DEACTIVATED]: 'danger',
} as const;

export const DistrictUserStatusToBadgeVariant = {
  [DistrictUserStatus.ACTIVE]: 'success-light',
  [DistrictUserStatus.INACTIVE]: 'danger',
  [DistrictUserStatus.PENDING]: 'warning-light',
} as const;

export const ReadableDistrictUserStatus = {
  [DistrictUserStatus.ACTIVE]: 'Active',
  [DistrictUserStatus.INACTIVE]: 'Inactive',
  [DistrictUserStatus.PENDING]: 'Pending',
} as const;
