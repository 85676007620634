import { useMemo } from 'react';
import { formatDateToLocalTime } from '@purple/shared-utils';
import { AppTipBox, NumberBadge } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { READABLE_SIS_MAPPING_EVENTS_TYPES } from '~/services';
import { SisEventsStatusBadgeVariant } from './constants';
import type { ColumnDef } from '@tanstack/react-table';
import type { TDistrictMappingEventItem } from '~/services';

type TUseSusMappingEventsColumns = () => ColumnDef<TDistrictMappingEventItem>[];

export const useSisMappingEventsColumns: TUseSusMappingEventsColumns = () => {
  const columns = useMemo(
    () =>
    [
      {
        accessorKey: 'event_type',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Sync type" />,
        cell: ({ row: { original } }) => <span>{READABLE_SIS_MAPPING_EVENTS_TYPES[original.event_type]}</span>,
        size: 240,
        enableHiding: false,
        enableSorting: false,
      },
      {
        accessorKey: 'created_at',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Sync date & time" />,
        cell: ({ row: { original } }) => <span>{formatDateToLocalTime(original.created_at)}</span>,
        size: 200,
        enableHiding: false,
        enableSorting: false,
      },
      {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row: { original } }) => (
          <NumberBadge
            variant={SisEventsStatusBadgeVariant[original.status]}
            className="rounded-full capitalize"
          >
            {original.status}
          </NumberBadge>
        ),
        size: 120,
        enableHiding: false,
        enableSorting: false,
      },
      {
        accessorKey: 'errors_count',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Error" />,
        cell: ({ row: { original } }) => {
          const isErrorCountNumber = typeof original.errors_count === 'number';
          return (
            <div className="flex items-center gap-2">
              <span>{ isErrorCountNumber && original.errors_count ? original.errors_count : '—'}</span>
              {isErrorCountNumber && original.errors_count && original.errors_count > 0
                ? (
                    <AppTipBox
                      iconName="exclamation-circle"
                      className="size-5 text-error-main hover:text-error-main"
                      text="This sync was completed with errors"
                    />
                  )
                : null}
            </div>
          );
        },
        size: 100,
        enableHiding: false,
        enableSorting: false,
      },
    ] satisfies ColumnDef<TDistrictMappingEventItem>[],
    [],
  );
  return columns;
};
