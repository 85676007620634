import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Checkbox, Form, FormControl, FormField, FormItem, FormLabel, Text } from '@purple/ui';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const sectionSchema = z.object({
  can_student_submit_anonymously: z.boolean(),
  can_teacher_submit_anonymously: z.boolean(),
  can_parent_submit_anonymously: z.boolean(),
  can_community_member_submit_anonymously: z.boolean(),
});

type TSafAnonymousSectionProperties = {
  district: TDistrictDetails;
};

export const SafAnonymousSection: React.FC<TSafAnonymousSectionProperties> = (props) => {
  const {
    district: {
      id,
      can_community_member_submit_anonymously,
      can_parent_submit_anonymously,
      can_student_submit_anonymously,
      can_teacher_submit_anonymously,
    },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues: z.infer<typeof sectionSchema> = useMemo(
    () => ({
      can_student_submit_anonymously,
      can_teacher_submit_anonymously,
      can_parent_submit_anonymously,
      can_community_member_submit_anonymously,
    }),
    [
      can_community_member_submit_anonymously,
      can_parent_submit_anonymously,
      can_student_submit_anonymously,
      can_teacher_submit_anonymously,
    ],
  );

  const form = useForm<z.infer<typeof sectionSchema>>({
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof sectionSchema>) => {
    updateDistrictSafProcess(
      {
        id,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader
          title="Anonymous SAF submission"
          editing={isEditing}
          loading={isPending}
          onCancel={cancelClickHandler}
          onEdit={editClickHandler}
          onSave={form.handleSubmit(saveDetailsClickHandler)}
        />
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          By default each submitter type on the SAF form is able to submit an anonymous SAF. You can update to determine
          that specific SAF submitter types will not be able to submit anonymously.
        </Text>
      </div>
      <div className="flex w-full flex-col gap-2">
        <Text tag="strong" type="body-500" variant="size-16" className="text-grey-950">
          Who can submit anonymously?
        </Text>
        {isEditing
          ? (
              <Form providerProps={form} className="flex w-full flex-col gap-2">
                <FormField
                  control={form.control}
                  name="can_student_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-base font-normal text-grey-800">
                        Student (when submitting for another student)
                      </FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="can_teacher_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-base font-normal text-grey-800">Teacher or Staff Member</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="can_parent_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-base font-normal text-grey-800">Parent or Caregiver</FormLabel>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="can_community_member_submit_anonymously"
                  render={({ field }) => (
                    <FormItem className="flex items-center gap-2 space-y-0">
                      <FormControl>
                        <Checkbox
                          {...field}
                          value={field.value.toString()}
                          checked={field.value}
                          onCheckedChange={field.onChange}
                        />
                      </FormControl>
                      <FormLabel className="text-base font-normal text-grey-800">
                        Community Member or Service Provider
                      </FormLabel>
                    </FormItem>
                  )}
                />
              </Form>
            )
          : (
              <ul className="flex w-full flex-col gap-2">
                {can_student_submit_anonymously && (
                  <li>
                    <Text variant="size-16" type="body-400" className="text-grey-800">
                      Student (when submitting for another student)
                    </Text>
                  </li>
                )}
                {can_teacher_submit_anonymously && (
                  <li>
                    <Text variant="size-16" type="body-400" className="text-grey-800">
                      Teacher or Staff Member
                    </Text>
                  </li>
                )}
                {can_parent_submit_anonymously && (
                  <li>
                    <Text variant="size-16" type="body-400" className="text-grey-800">
                      Parent or Caregiver
                    </Text>
                  </li>
                )}
                {can_community_member_submit_anonymously && (
                  <li>
                    <Text variant="size-16" type="body-400" className="text-grey-800">
                      Community Member or Service Provider
                    </Text>
                  </li>
                )}
              </ul>
            )}
      </div>
    </section>
  );
};
