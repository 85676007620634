import { formatDateToLocalTime } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TNote } from '@purple/shared-types';

export const actionNotesColumns: ColumnDef<TNote>[] = [
  {
    accessorKey: 'title',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Title" />,
    cell: ({ row: { original } }) => {
      return (
        original.title && original.title.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.title}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.title}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 400,
    enableHiding: false,
    meta: { label: 'Title' },
  },
  {
    accessorKey: 'created_by',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Created By" />,
    cell: ({ row: { original } }) => {
      return (
        original.created_by && original.created_by.full_name && original.created_by.full_name.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.created_by.full_name}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.created_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableHiding: false,
    meta: { label: 'Created By' },
  },
  {
    accessorKey: 'updated_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified" />,
    cell: ({ row: { original } }) => <span className="text-nowrap">{formatDateToLocalTime(original.updated_at)}</span>,
    meta: { label: 'Last Modified' },
    enableHiding: false,
  },
  {
    accessorKey: 'last_modified_by',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Last Modified By" />,
    cell: ({ row: { original } }) => {
      return (
        original.last_modified_by && original.last_modified_by.full_name && original.last_modified_by.full_name.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.last_modified_by.full_name}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.last_modified_by.full_name}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    enableHiding: false,
    meta: { label: 'Last Modified By' },
  },
];
