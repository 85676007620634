import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { ContactType, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, ReadableContactType, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useContacts } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { useSchoolContactsColumns } from './useSchoolContactsColumns';

export const SchoolContactsTab: React.FC = () => {
  const { id: schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useContacts({
    school: schoolId,
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    type: searchParameters.get('type'),
  });

  const contacts = useMemo(() => data?.results ?? [], [data?.results]);
  const filterConfig = useMemo(
    () => (convertToFilterConfig({
      type: Object.values(ContactType).map((value) => ({ label: ReadableContactType[value], value })),
    }, { snakeCaseValue: false })),
    [],
  );

  const columns = useSchoolContactsColumns();

  const { table } = useDataTable({
    columns,
    data: contacts,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['full_name'],
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  return (
    <SchoolTabContainer title="Contacts" className="p-0">
      <DataTable table={table} loading={isFetching}>
        <div className="flex w-full items-center gap-4 p-4 pt-6">
          <AppFilters config={filterConfig} />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
      </DataTable>
    </SchoolTabContainer>
  );
};
