import { DaysOfWeek } from '@purple/shared-types';

export const DayOfWeekSorter = {
  [DaysOfWeek.MONDAY]: 1,
  [DaysOfWeek.TUESDAY]: 2,
  [DaysOfWeek.WEDNESDAY]: 3,
  [DaysOfWeek.THURSDAY]: 4,
  [DaysOfWeek.FRIDAY]: 5,
  [DaysOfWeek.SATURDAY]: 6,
  [DaysOfWeek.SUNDAY]: 7,
} as const;
