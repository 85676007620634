import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictNavigationPages } from './get-distirct-navigation-pages';

export const useDistrictCustomPages = ({ districtId }: { districtId: string | number }) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_CUSTOM_PAGES, districtId.toString()],
    queryFn: () => getDistrictNavigationPages(districtId),
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch district custom pages data', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
