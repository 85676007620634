import { type FC, memo } from 'react';
import { addMinutes, format } from 'date-fns';
import { DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm } from '@purple/ui';
import type { TActionDetails } from '@purple/shared-types';

type TDateTimeContentProperties = {
  action: TActionDetails;
};

export const DateTimeContent: FC<TDateTimeContentProperties> = memo(({ action }) => {
  return (
    <DescriptionList>
      <DescriptionItem>
        <DescriptionTerm>Start Date & Time</DescriptionTerm>
        <DescriptionDetails>
          {format(new Date(action.date_and_time_details.date_and_time), 'MMMM d, yyyy, h:mm a')}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>End Date & Time</DescriptionTerm>
        <DescriptionDetails>
          {format(
            addMinutes(new Date(action.date_and_time_details.date_and_time), action.date_and_time_details.duration),
            'MMMM d, yyyy, h:mm a',
          )}
        </DescriptionDetails>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionTerm>Duration</DescriptionTerm>
        <DescriptionDetails>{action.date_and_time_details.duration ?? 0}</DescriptionDetails>
      </DescriptionItem>
    </DescriptionList>
  );
});
