import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUsersManagementFilterOptions } from './get-users-management-filter-options';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TUserManagementFilterOptions, TUserManagementFilterOptionsRequestParameters } from './user.types';

export const useUserManagementFilterOptions = (options?: TUserManagementFilterOptionsRequestParameters & { enabled?: boolean }) => {
  const { enabled, ...parameters } = options ?? {};
  const { isError, error, ...queryData } = useQuery<TUserManagementFilterOptions, AxiosError>({
    queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.USERS_FILTER_OPTIONS, parameters],
    queryFn: () => getUsersManagementFilterOptions(parameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('Failed to fetch users filter options data', 'Please try again later');
    }
  }, [isError, error?.response]);

  return { ...queryData, isError };
};
