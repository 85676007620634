import { useParams } from 'react-router-dom';
import { snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Button, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, NumberBadge, Text } from '@purple/ui';
import { useActionType } from '~/services/actions/useActionType';
import { ActionTypeDetailsHeaderSkeleton } from './ActionTypeDetailsHeaderSkeleton';
import type { FC } from 'react';

export const ActionTypeDetailsHeader: FC = () => {
  const { id } = useParams();

  const { data: actionTypeDetails, isLoading } = useActionType({ id: id!, queryOptions: { enabled: !!id } });

  const { type, is_active } = actionTypeDetails || {};

  if (isLoading) {
    return <ActionTypeDetailsHeaderSkeleton />;
  }

  return (
    <section className="flex w-full flex-wrap items-start gap-3 rounded-lg border border-grey-200 bg-white p-4">
      <div className="flex flex-col gap-2">
        <Text variant="size-16" type="body-600">{type ? snakeToCamelWithSpaces(type) : 'Unidentified Action Type' }</Text>
        <div className="flex items-center gap-4">
          <DescriptionList className="flex-col items-start gap-6 md:flex-row">
            <DescriptionItem className="flex-col items-start gap-1">
              <DescriptionTerm className="text-sm">Status</DescriptionTerm>
              <DescriptionDetails className="m-0">
                <NumberBadge variant={is_active ? 'success-light' : 'danger'} className="rounded-full text-sm font-medium capitalize">
                  {is_active ? 'Active' : 'Inactive'}
                </NumberBadge>
              </DescriptionDetails>
            </DescriptionItem>
          </DescriptionList>
          <DescriptionList className="flex-col items-start gap-6 md:flex-row">
            <DescriptionItem className="w-max flex-col items-start gap-1">
              <DescriptionTerm className="text-sm">District</DescriptionTerm>
              <DescriptionDetails className="m-0 line-clamp-1 break-all">
                {actionTypeDetails?.district.name}
              </DescriptionDetails>
            </DescriptionItem>
          </DescriptionList>
        </div>
      </div>
      {/* TODO: Add navigation to the builder page when it will be ready */}
      <Button variant="primary" className="ml-auto">Open in Builder</Button>
    </section>
  );
};
