import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { cn } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useCurrentUser, useSidebar } from '~/hooks';
import { MainHeader } from '../MainHeader';
import { Sidebar } from '../Sidebar';

export const SiteLayout: React.FC<React.PropsWithChildren> = () => {
  const location = useLocation();

  const { isAuthenticated, isLoading, user } = useCurrentUser();

  const { isOpen } = useSidebar();

  // Prevent logged in user from accessing login page
  // We are checking for id because the user object is not null when the user is authenticated
  if (!isAuthenticated && !isLoading && !user.id) {
    return <Navigate to={AdminRoutes.Auth.Login.Root.path} replace state={{ from: location }} />;
  }

  return (
    <>
      <Sidebar />
      <main
        className={cn(
          'flex flex-col min-h-screen bg-grey-50 transition-[margin-left] duration-300 ease-in-out',
          isOpen ? 'lg:ml-[260px]' : 'lg:ml-[76px]',
        )}
      >
        <MainHeader />
        <div className="flex w-full flex-1 flex-col px-7 pb-6 pt-7">
          <Outlet />
        </div>
      </main>
    </>
  );
};
