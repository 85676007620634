import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { ReadableBannerType } from '@purple/shared-types';
import { formateDateShortMonth, formateDateShortMonthWithTime } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { BodyCell } from './BodyCell';
import { BannerTypeToBadgeVariant } from './helpers';
import type { ColumnDef } from '@tanstack/react-table';
import type { TPublishedBanner } from '~/services';

type TUsePublishedBannerColumns = (options?: {
  onDelete?: (banner: TPublishedBanner) => void;
  onEdit?: (banner: TPublishedBanner) => void;
}) => ColumnDef<TPublishedBanner>[];

export const usePublishedBannerColumns: TUsePublishedBannerColumns = (options) => {
  const { onDelete, onEdit } = options ?? {};

  const columns = useMemo(
    () =>
      [
        SelectableCell(),
        {
          accessorKey: 'title',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Title" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.title}</span>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent align="start" withArrow={false}>
                  {row.original.title}
                </TooltipContent>
              </TooltipPortal>
            </Tooltip>
          ),
          size: 220,
        },
        {
          accessorKey: 'type',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Type" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={BannerTypeToBadgeVariant[row.original.type]}
              className="rounded-full capitalize"
            >
              {ReadableBannerType[row.original.type]}
            </NumberBadge>
          ),
          size: 140,
          enableSorting: false,
          meta: { className: 'text-center', label: 'Type' },
        },
        {
          accessorKey: 'body',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Body" />,
          cell: ({ row }) => (
            <BodyCell banner={row.original} />
          ),
          size: 280,
          enableSorting: false,
          meta: { label: 'Body' },
        },
        {
          accessorKey: 'start_date_and_time',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Start Date" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.start_date_and_time ? formateDateShortMonth(row.original.start_date_and_time) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.start_date_and_time && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formateDateShortMonthWithTime(row.original.start_date_and_time)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Start Date' },
        },
        {
          accessorKey: 'end_date_and_time',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Expiration Date" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">
                  {row.original.end_date_and_time ? formateDateShortMonth(row.original.end_date_and_time) : '-'}
                </span>
              </TooltipTrigger>
              {row.original.end_date_and_time && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {formateDateShortMonthWithTime(row.original.end_date_and_time)}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'Expiration Date' },
        },
        {
          accessorKey: 'district',
          header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.district?.name || '-'}</span>
              </TooltipTrigger>
              {row.original.district && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.district.name || '-'}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 160,
          enableSorting: false,
        },
        {
          accessorKey: 'roles',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Role(s)" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.roles?.map(({ name }) => name).join(', ') || '-'}</span>
              </HoverCardTrigger>
              {row.original.roles && row.original.roles.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent align="start" className="inline-flex w-full justify-start whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950">
                    {row.original.roles.map(({ name }) => name).join('\n') || '-'}
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 160,
          enableSorting: false,
        },
        {
          accessorKey: 'groups',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Group(s)" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.groups?.map(({ name }) => name).join(', ') || '-'}</span>
              </HoverCardTrigger>
              {row.original.groups && row.original.groups.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent align="start" className="inline-flex w-full justify-start whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950">
                    {row.original.groups.map(({ name }) => name).join('\n') || '-'}
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 160,
          enableSorting: false,
        },
        {
          accessorKey: 'is_view_more',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Has Link" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <span className="line-clamp-1 min-w-0 break-all">{row.original.is_view_more ? 'Yes' : 'No'}</span>
              </HoverCardTrigger>
              {row.original.is_view_more && row.original.view_more_link && (
                <HoverCardPortal>
                  <HoverCardContent align="start" className="inline-flex w-full justify-start whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950">
                    <Button asChild variant="link" size="small" iconLeft={<PurpleIcon name="external-link" />} className="h-auto p-0">
                      <a href={row.original.view_more_link} target="_blank" rel="noreferrer">
                        {row.original.view_more_link || '-'}
                      </a>
                    </Button>
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 160,
          enableSorting: false,
          meta: { label: 'Has Link' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => (
            <div className="flex items-center justify-end gap-2">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    type="button"
                    variant="tertiary"
                    size="icon_32"
                    className="size-8 shrink-0 active:bg-grey-100"
                    iconLeft={<PurpleIcon name="pencil" className="shrink-0 text-grey-600" />}
                    onFocusCapture={(event) => event.stopPropagation()}
                    onClick={() => onEdit?.(row.original)}
                  />
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Edit</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              {!row.getIsSelected() && (
                <DropdownRoot>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <DropdownTrigger asChild>
                        <Button
                          type="button"
                          variant="tertiary"
                          size="icon_32"
                          className="size-8 shrink-0 active:bg-grey-100"
                          iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
                          onFocusCapture={(event) => event.stopPropagation()}
                        />
                      </DropdownTrigger>
                    </TooltipTrigger>
                    <TooltipPortal>
                      <TooltipContent withArrow={false}>Actions</TooltipContent>
                    </TooltipPortal>
                  </Tooltip>
                  <DropdownContent className="max-w-[150px] gap-1" align="end">
                    <DropdownItem
                      iconName="trash"
                      className={cn(
                        'cursor-pointer transition-colors duration-200 focus-visible:outline-none text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main',
                      )}
                      onSelect={() => onDelete?.(row.original)}
                      onClick={(event) => event.stopPropagation()}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownContent>
                </DropdownRoot>
              )}
            </div>
          ),
          size: 120,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TPublishedBanner>[],
    [onDelete, onEdit],
  );

  return columns;
};
