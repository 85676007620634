import { useParams } from 'react-router-dom';
import { NoDataAvailable } from '@purple/ui';
import { useSchoolSafInsights } from '~/services';
import { GeneralSafData } from './GeneralSafData';
import { SafAccountability } from './SafAccountability';
import { SafDataStatsSkeleton } from './SafDataStatsSkeleton';
import { StaffSafSaturation } from './StaffSafSaturation';

export const SafDataStats: React.FC = () => {
  const { id } = useParams();

  const { data: insights, isFetching } = useSchoolSafInsights(id);

  if (isFetching) {
    return <SafDataStatsSkeleton />;
  }

  if (!insights) {
    return (
      <NoDataAvailable
        iconName="folder-open"
        title="No SAF insights available"
        description="There are no SAF insights available for this school. Please check back later."
      />
    );
  }

  return (
    <div className="flex w-full flex-col gap-6">
      <GeneralSafData insights={insights} />
      <SafAccountability insights={insights} />
      <StaffSafSaturation insights={insights} />
    </div>
  );
};
