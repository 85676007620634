import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditableBlockHeader, Text } from '@purple/ui';
import { type TAdminSafBasicDetailsView, useUpdateSafDetails } from '~/services';
import { updateSubmissionDetailsSchema } from './schema';
import { SubmissionDetailsEdit } from './SubmissionDetailsEdit';
import { SubmissionDetailsView } from './SubmissionDetailsView';
import type * as z from 'zod';
import type { TGrade, TSafSubmitterType } from '@purple/shared-types';

type TSubmissionDetailsAreaProperties = {
  data: TAdminSafBasicDetailsView;
};

const SubmissionDetailsArea = ({ data }: TSubmissionDetailsAreaProperties) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const { mutate, isPending } = useUpdateSafDetails();

  const defaultValues = useMemo(() => {
    const { submitter } = data;

    return {
      submitter_first_name: submitter?.first_name || '',
      submitter_last_name: submitter?.last_name || '',
      submitter_email: submitter?.email || '',
      submitter_phone: submitter?.phone || '',
      submitter_type: data.submitter_type || '',
      submitted_school: data.submitted_school.id || '',
      submitted_student_grade: data.submitted_student_grade || '',
      is_submitted_anonymously: data.is_submitted_anonymously || false,
      submitted_student_first_name: data.submitted_student_first_name || '',
      submitted_student_middle_name: data.submitted_student_middle_name || '',
      submitted_student_last_name: data.submitted_student_last_name || '',
    };
  }, [data]);

  const form = useForm<z.infer<typeof updateSubmissionDetailsSchema>>({
    resolver: zodResolver(updateSubmissionDetailsSchema),
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
    },
  });

  useEffect(() => {
    form.reset({
      ...defaultValues,
    });
  }, [defaultValues, form]);

  const handleSave = (newFormData: z.infer<typeof updateSubmissionDetailsSchema>) => {
    const {
      submitter_first_name,
      submitter_last_name,
      submitter_email,
      submitter_phone,
      submitter_type,
      submitted_school,
      submitted_student_grade,
      is_submitted_anonymously,
      submitted_student_first_name,
      submitted_student_middle_name,
      submitted_student_last_name,
    } = newFormData;

    const phone = submitter_phone.replaceAll('+1 (', '').replaceAll(') ', '-');

    const payload = {
      submitter: {
        first_name: submitter_first_name,
        last_name: submitter_last_name,
        email: submitter_email,
        phone,
      },
      submitter_type: submitter_type as TSafSubmitterType,
      submitted_school,
      submitted_student_grade: submitted_student_grade as TGrade,
      is_submitted_anonymously,
      submitted_student_first_name,
      submitted_student_middle_name,
      submitted_student_last_name,
    };

    mutate({
      id: data.id,
      payload,
    }, {
      onSuccess: () => {
        setMode('view');
      },
    });
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <EditableBlockHeader
        mode={mode}
        onEdit={() => setMode('edit')}
        onCancel={() => setMode('view')}
        onSave={form.handleSubmit(handleSave)}
        isSaving={isPending}
        title={<Text variant="size-16" type="body-500" className="text-grey-title">Submission Details</Text>}
      />
      {mode === 'view' ? <SubmissionDetailsView data={data} /> : <SubmissionDetailsEdit data={data} form={form} />}
    </div>
  );
};

export { SubmissionDetailsArea };
