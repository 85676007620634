import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import defaultLogo from '~/assets/images/default-logo.png';
import { AdminRoutes } from '~/constants';
import { Menu } from '../Menu';

export const SheetMenu: React.FC = () => {
  return (
    <Sheet>
      <Tooltip>
        <TooltipTrigger asChild>
          <SheetTrigger className="lg:hidden" asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              iconLeft={<PurpleIcon name="menu" className="size-5 shrink-0" />}
            />
          </SheetTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Open navigation</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <SheetContent
        className="flex h-full flex-col gap-0 border-0 p-0 sm:w-72"
        side="left"
        onFocusCapture={(event) => event.stopPropagation()}
      >
        <SheetHeader className="flex flex-row items-start justify-between space-y-0 px-3 pb-4 pt-5 text-left">
          <Link
            to={AdminRoutes.App.Home.Root.path}
            className="flex select-none items-center gap-2 font-primary text-base font-semibold text-grey-950 outline-none transition-transform duration-300 ease-in-out hover:text-brand-blue-700 focus-visible:text-brand-blue-700 active:text-brand-blue-400"
          >
            <Avatar size={32} variant="circle">
              <AvatarImage src={defaultLogo} alt="ProUnitas brand logo" />
              <AvatarFallback>PU</AvatarFallback>
            </Avatar>
            <SheetTitle className="text-base font-semibold">ProUnitas</SheetTitle>
          </Link>
          <Tooltip>
            <SheetClose asChild>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  variant="tertiary"
                  size="icon_32"
                  className="size-6 rounded p-0"
                  iconLeft={<PurpleIcon name="X" />}
                />
              </TooltipTrigger>
            </SheetClose>
            <TooltipPortal>
              <TooltipContent>Close navigation</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        </SheetHeader>
        <Menu isOpen />
      </SheetContent>
    </Sheet>
  );
};
