import { useEffect, useId, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  cn,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadixSelect,
  RadixSelectContent,
  RadixSelectItem,
  RadixSelectTrigger,
  RadixSelectValue,
  Separator,
} from '@purple/ui';
import { useModal } from '~/hooks';
import { ReadableHierarchyNodeType } from './helpers';
import { HierarchyNodeType } from './types';
import type React from 'react';
import type { THierarchyBranch, THierarchyNodeType } from './types';

const hierarchyNodeSchema = z
  .object({
    name: z.string().trim().min(1, 'Name is required').max(255, 'Name is too long'),
    type: z.nativeEnum(HierarchyNodeType, { required_error: 'Type is required', invalid_type_error: 'Invalid type' }),
    existingNames: z.array(z.string()),
  })
  .superRefine((data, context) => {
    if (data.existingNames.map((name) => name.trim().toLowerCase()).includes(data.name.trim().toLowerCase())) {
      context.addIssue({
        path: ['name'],
        code: z.ZodIssueCode.custom,
        message: 'Node with this name already exists.',
      });
    }
  });

const DEFAULT_EXISTING_NAMES: string[] = [];
const DEFAULT_AVAILABLE_TYPES: THierarchyNodeType[] = [];

type TManageNodeDialogProperties = {
  modalName: string;
  existingNames?: string[];
  availableTypes?: THierarchyNodeType[];
  node?: THierarchyBranch | null;
  isLoading?: boolean;
  onSubmit?: (data: THierarchyBranch) => void;
};

export const ManageNodeDialog: React.FC<TManageNodeDialogProperties> = (props) => {
  const { modalName, existingNames = DEFAULT_EXISTING_NAMES, availableTypes = DEFAULT_AVAILABLE_TYPES, node = null, isLoading = false, onSubmit } = props;

  const formId = useId();

  const { isOpen, toggleModal, closeModal } = useModal(modalName);

  const [firstType] = availableTypes;
  const defaultValues: Partial<z.infer<typeof hierarchyNodeSchema>> = useMemo(
    () => ({
      name: node?.name || '',
      type: node?.type || (availableTypes.length === 1 ? firstType : undefined),
      existingNames,
    }),
    [node?.name, node?.type, existingNames, availableTypes, firstType],
  );

  const form = useForm<z.infer<typeof hierarchyNodeSchema>>({
    resolver: zodResolver(hierarchyNodeSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
  };

  const submitNodeHandler = (data: z.infer<typeof hierarchyNodeSchema>) => {
    const { existingNames: _existingNames, ...formNodeData } = data;
    onSubmit?.(formNodeData);
    successCallback();
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>
            {node === null ? 'Add New Node' : 'Edit Node'}
          </DialogTitle>
          <DialogDescription className="sr-only">
            Add/update a node in the school hierarchy tree.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(submitNodeHandler)}
        >
          <FormField
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Node Name</FormLabel>
                <FormControl>
                  <Input {...field} isError={!!fieldState.error} placeholder="Enter node name" type="text" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Type</FormLabel>
                <RadixSelect onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <RadixSelectTrigger
                      disabled={node !== null}
                      className={cn({
                        'border-error-main': !!fieldState.error,
                      })}
                    >
                      <RadixSelectValue placeholder="Select type" />
                    </RadixSelectTrigger>
                  </FormControl>
                  <RadixSelectContent>
                    {availableTypes?.map((value) => (
                      <RadixSelectItem key={value} value={value}>
                        {ReadableHierarchyNodeType[value]}
                      </RadixSelectItem>
                    ))}
                  </RadixSelectContent>
                </RadixSelect>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isLoading}>
            {node === null ? 'Create' : 'Update'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
