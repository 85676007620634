import { Skeleton } from '@purple/ui';

export const VisualizerSetupProgressSkeleton: React.FC = () => {
  return (
    <>
      <div className="flex w-full items-baseline justify-start gap-1">
        <Skeleton className="h-6 w-full max-w-[254px]" />
      </div>
      <Skeleton className="h-4 w-full rounded-full" />
    </>
  );
};
