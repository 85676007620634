import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Heading, SearchInput, Separator } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useUserLoginHistory } from '~/services';
import { loginHistoryColumns } from './loginHistoryColumns';

const LoginHistoryTab = () => {
  const { userId } = useParams();

  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: loginData, isLoading: isLoginLoading } = useUserLoginHistory({
    userId,
    requestParameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    },
  });

  const logins = useMemo(() => loginData?.results ?? [], [loginData?.results]);

  const { table } = useDataTable({
    columns: loginHistoryColumns,
    data: logins,
    rowCount: loginData?.count,
  });

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-4">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Login History</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <DataTable
        table={table}
        loading={isLoginLoading}
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <SearchInput
                value={search}
                placeholder="Search"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { LoginHistoryTab };
