import { SIS_MAPPING_EVENTS_STATUSES } from '~/services';

export const SisDataViewType = {
  LIST: 'list',
  DETAILS: 'details',
} as const;

export const SisDataQueryName = {
  VIEW: 'view',
  MAPPING_ID: 'mapping_id',
} as const;

export const SisEventsStatusBadgeVariant = {
  [SIS_MAPPING_EVENTS_STATUSES.SUCCESS]: 'success-light',
  [SIS_MAPPING_EVENTS_STATUSES.PENDING]: 'warning-light',
  [SIS_MAPPING_EVENTS_STATUSES.FAILED]: 'danger',
} as const;
