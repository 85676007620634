import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { formateDateShortMonth, getGMTOffset } from '@purple/shared-utils';
import { ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, DatePicker, DescriptionDetails, DescriptionItem, DescriptionList, DescriptionTerm, Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@purple/ui';
import { useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const timezoneOptions = Intl.supportedValuesOf('timeZone')
  .map((tz) => {
    const gmtOffset = getGMTOffset(tz);
    return {
      label: `(${gmtOffset}) ${tz}`,
      value: tz,
      offset: gmtOffset,
    };
  })
  .sort((a, b) => a.offset.localeCompare(b.offset, 'en'));

const operationalInfoSchema = z
  .object({
    school_year_start_datetime: z.date().optional(),
    school_year_end_datetime: z.date().optional(),
    timezone: z.string().optional(),
  })
  .superRefine((data, context) => {
    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_start_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'Start of fiscal year must be before end of fiscal year.',
      });
    }

    if (
      data.school_year_start_datetime
      && data.school_year_end_datetime
      && data.school_year_end_datetime <= data.school_year_start_datetime
    ) {
      context.addIssue({
        path: ['school_year_end_datetime'],
        code: z.ZodIssueCode.custom,
        message: 'End of fiscal year must be after start of fiscal year.',
      });
    }
  });

type TOperationalInfoSectionProps = {
  data: TSchoolBasicDetails;
};

export const OperationalInfoSection: React.FC<TOperationalInfoSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();

  const defaultValues: z.infer<typeof operationalInfoSchema> = useMemo(
    () => ({
      school_year_start_datetime: data.school_year_start_datetime ? new Date(data.school_year_start_datetime) : undefined,
      school_year_end_datetime: data.school_year_end_datetime ? new Date(data.school_year_end_datetime) : undefined,
      timezone: data.timezone,
    }),
    [data],
  );

  const form = useForm<z.infer<typeof operationalInfoSchema>>({
    resolver: zodResolver(operationalInfoSchema),
    mode: 'onChange',
    defaultValues,
  });
  const startDate = form.watch('school_year_start_datetime');

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof operationalInfoSchema>) => {
    updateSchool(
      {
        id: data.id,
        ...(formData.timezone !== data.timezone && { timezone: formData.timezone }),
        ...(formData.school_year_start_datetime && {
          school_year_start_datetime: formData.school_year_start_datetime.toISOString(),
        }),
        ...(formData.school_year_end_datetime && {
          school_year_end_datetime: formData.school_year_end_datetime.toISOString(),
        }),
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Operational Information"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="school_year_start_datetime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Start of fiscal year (school year)
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <DatePicker
                          mode="single"
                          placeholder="Select date & time"
                          formatterString="PPP"
                          isError={!!fieldState.error}
                          triggerDisabled={field.disabled}
                          selected={field.value}
                          defaultMonth={field.value}
                          captionLayout="dropdown"
                          align="end"
                          onDayClick={field.onChange}
                          triggerClassName="max-h-9"
                          contentClassName="min-w-[320px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_year_end_datetime"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      End of fiscal year (school year)
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <DatePicker
                          mode="single"
                          placeholder="Select date & time"
                          formatterString="PPP"
                          isError={!!fieldState.error}
                          triggerDisabled={field.disabled}
                          selected={field.value}
                          defaultMonth={field.value}
                          captionLayout="dropdown"
                          align="end"
                          disabled={startDate ? { before: startDate } : false}
                          onDayClick={field.onChange}
                          triggerClassName="max-h-9"
                          contentClassName="min-w-[320px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="timezone"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">Time Zone</FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select timezone"
                            selectedLabel={timezoneOptions.find((option) => option.value === field.value)?.label}
                            className="max-h-8"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search timezone..."
                          emptyContent="Timezone not found."
                        >
                          {timezoneOptions.map(({ label, value, offset }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label, offset]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {label}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Fiscal year (school year)</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.school_year_start_datetime ? formateDateShortMonth(data.school_year_start_datetime) : '-'}
                  {' '}
                  -
                  {' '}
                  {data.school_year_end_datetime ? formateDateShortMonth(data.school_year_end_datetime) : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Time Zone</DescriptionTerm>
                <DescriptionDetails className="w-1/2">{data.timezone ?? '-'}</DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
