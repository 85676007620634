import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { deleteGroup } from './delete-group';
import { GROUPS_QUERY_KEYS } from './groups.const';
import type { AxiosError, AxiosResponse } from 'axios';

export const useDeleteGroup = () => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, string>({
    mutationKey: [GROUPS_QUERY_KEYS.DELETE_GROUP],
    mutationFn: deleteGroup,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', ' Unable to delete this permission group. Please try again later.');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GROUPS_QUERY_KEYS.GET_GROUPS_LIST] });
    },
  });
};
