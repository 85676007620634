import { useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { getGradeGroups, getGradeLabel } from '@purple/shared-utils';
import {
  Button,
  cn,
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
  NumberBadge,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { SchoolNameCell } from './SchoolNameCell';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSchoolItem } from '~/services';

type TUseSchoolColumns = (options?: {
  onDeactivate?: (school: TSchoolItem) => void;
}) => ColumnDef<TSchoolItem>[];

export const useSchoolColumns: TUseSchoolColumns = (options) => {
  const { onDeactivate } = options ?? {};

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="School Name" />,
          cell: ({ row }) => (
            <SchoolNameCell school={row.original} />
          ),
          size: 160,
        },
        {
          accessorKey: 'is_active',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
          cell: ({ row }) => (
            <NumberBadge
              variant={row.original.is_active ? 'success-light' : 'danger'}
              className="rounded-full capitalize"
            >
              {row.original.is_active ? 'Active' : 'Inactive'}
            </NumberBadge>
          ),
          size: 120,
          meta: { className: 'text-center', label: 'Status' },
        },
        {
          accessorKey: 'district__name',
          header: ({ column }) => <DataTableColumnHeader column={column} title="District" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">{row.original.district || '-'}</span>
              </TooltipTrigger>
              {row.original.district && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.district}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 140,
          meta: { label: 'District' },
        },
        {
          accessorKey: 'school_code',
          header: ({ column }) => <DataTableColumnHeader column={column} title="School code" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">{row.original.school_code || '-'}</span>
              </TooltipTrigger>
              {row.original.school_code && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.school_code}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'School code' },
        },
        {
          accessorKey: 'school_level',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Level" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">
                  {row.original.school_level.length === 0 ? '-' : row.original.school_level.join(', ')}
                </span>
              </TooltipTrigger>
              {row.original.school_level.length > 0 && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.school_level.join(', ')}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 120,
          meta: { label: 'Level' },
        },
        {
          accessorKey: 'grades',
          header: ({ column }) => <DataTableColumnHeader column={column} title="Grades" />,
          cell: ({ row }) => (
            <HoverCard>
              <HoverCardTrigger asChild>
                <NumberBadge variant="info" className="col-span-1 place-self-center text-nowrap rounded-full px-3 text-center text-sm font-medium text-grey-950">
                  {getGradeLabel(row.original.grades)}
                </NumberBadge>
              </HoverCardTrigger>
              {row.original.grades?.length > 0 && (
                <HoverCardPortal>
                  <HoverCardContent
                    onClick={(evt) => evt.stopPropagation()}
                    className="inline-flex w-full max-w-64 justify-center whitespace-pre-wrap border border-grey-200 p-2 text-xs font-medium leading-5 text-grey-950"
                  >
                    {getGradeGroups(row.original.grades)}
                  </HoverCardContent>
                </HoverCardPortal>
              )}
            </HoverCard>
          ),
          size: 120,
          meta: { className: 'text-center', label: 'Grades' },
        },
        {
          accessorKey: 'address__city',
          header: ({ column }) => <DataTableColumnHeader column={column} title="City" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">{row.original.city || '-'}</span>
              </TooltipTrigger>
              {row.original.city && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.city}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'City' },
        },
        {
          accessorKey: 'address__state',
          header: ({ column }) => <DataTableColumnHeader column={column} title="State" />,
          cell: ({ row }) => (
            <Tooltip>
              <TooltipTrigger asChild>
                <span className="line-clamp-1">{row.original.state || '-'}</span>
              </TooltipTrigger>
              {row.original.state && (
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {row.original.state}
                  </TooltipContent>
                </TooltipPortal>
              )}
            </Tooltip>
          ),
          size: 130,
          meta: { label: 'State' },
        },
        {
          accessorKey: 'actions',
          header: () => null,
          cell: ({ row }) => (
            <DropdownRoot>
              <Tooltip>
                <TooltipTrigger asChild>
                  <DropdownTrigger asChild>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      className="size-8 shrink-0 active:bg-grey-100"
                      iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
                      onFocusCapture={(event) => event.stopPropagation()}
                    />
                  </DropdownTrigger>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent withArrow={false}>Actions</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <DropdownContent className="max-w-[150px] gap-1" align="end">
                <DropdownItem
                  iconName={row.original.is_active ? 'X' : 'check'}
                  className={cn(
                    'cursor-pointer text-brand-blue-700 transition-colors duration-200 hover:bg-brand-blue-100 hover:text-brand-blue-700 focus:bg-brand-blue-100 focus:text-brand-blue-700 focus-visible:bg-brand-blue-100 focus-visible:text-brand-blue-700 focus-visible:outline-none [&>svg]:text-brand-blue-700',
                    {
                      'text-error-main hover:bg-error-bg hover:text-error-main focus:bg-error-bg focus:text-error-main focus-visible:bg-error-bg focus-visible:text-error-main [&>svg]:text-error-main': row.original.is_active ?? false,
                    },
                  )}
                  onSelect={() => onDeactivate?.(row.original)}
                  onClick={(event) => event.stopPropagation()}
                >
                  {row.original.is_active ? 'Deactivate' : 'Activate'}
                </DropdownItem>
              </DropdownContent>
            </DropdownRoot>
          ),
          size: 64,
          enableHiding: false,
          enableSorting: false,
          meta: { className: 'text-right' },
        },
      ] satisfies ColumnDef<TSchoolItem>[],
    [onDeactivate],
  );

  return columns;
};
