import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME } from '@purple/shared-types';
import { Button, Heading, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput, Separator } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useDeleteUserAccessToSchool, useUserSchools } from '~/services';
import { GRADE_OPTIONS } from '../../BasicDetailArea/constants';
import { useSchoolsColumns } from './useSchoolsColumns';

const GRADE_QUERY_NAME = 'grades';

const SchoolsTab = () => {
  const { userId } = useParams();

  const [searchParameters] = useSearchParams();

  const { query: gradeQuery, onQueryChange: onGradeQueryChange, onClearQuery: onGradeQueryClear } = useQueryParameter({ queryName: GRADE_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: schoolsData, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      ...(gradeQuery && { grades: gradeQuery }),
      user: userId,
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
    },
    enabled: !!userId,
  });

  const { mutate: deleteUserFromSchool, isPending: isDeletingUserFromSchool } = useDeleteUserAccessToSchool();

  const schools = useMemo(() => schoolsData?.results ?? [], [schoolsData?.results]);

  const deleteUserFromSchoolHandler = (schoolId: string) => {
    if (userId) {
      deleteUserFromSchool({
        userId,
        schoolId,
      });
    }
  };

  const { table } = useDataTable({
    columns: useSchoolsColumns({ onDelete: deleteUserFromSchoolHandler, isDeleting: isDeletingUserFromSchool }),
    data: schools,
    rowCount: schoolsData?.count,
  });

  const selectedGradeClearHandler = () => {
    onGradeQueryClear();
  };

  const selectedGradeChangeHandler = (value: string) => {
    onGradeQueryChange(value);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-4">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Schools</Heading>
        <Button variant="secondary" iconLeft={<PurpleIcon name="plus" />}>Add School</Button>
      </div>
      <Separator className="bg-grey-200" />
      <DataTable
        table={table}
        loading={isSchoolsLoading}
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <RadixSelect value={gradeQuery || ''} onValueChange={selectedGradeChangeHandler}>
                <RadixSelectTrigger hasClearButton={Boolean(gradeQuery && gradeQuery.length > 0)} onClearCallback={selectedGradeClearHandler}>
                  <RadixSelectValue placeholder="All Grades" />
                </RadixSelectTrigger>
                <RadixSelectContent>
                  {GRADE_OPTIONS.map(({ label, value }) => (
                    <RadixSelectItem key={value} value={value}>
                      {label}
                    </RadixSelectItem>
                  ))}
                </RadixSelectContent>
              </RadixSelect>
              <SearchInput
                value={search}
                placeholder="Search"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { SchoolsTab };
