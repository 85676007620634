import { DaysOfWeek } from '@purple/shared-types';
import { dateToTimeString } from '@purple/shared-utils';
import type { z } from 'zod';
import type { TDraftDistrictBasicInfo, TDraftDistrictBasicInfoRequest } from '~/services';
import type { basicStepSchema } from './schema';

export const convertBasicFormToRequest = (data: z.infer<typeof basicStepSchema>): TDraftDistrictBasicInfoRequest => {
  const holidays
    = data?.holidays && data.holidays.length > 0
      ? data.holidays
      // NOTE: we filter out holidays that don't have a name
      // because they are not valid holidays
      // but user can save as draft an empty holiday
        .filter((holiday) => holiday.name)
        .map((holiday) => {
          // Remove id if it's a string
          // React Hook Form useFieldArray automatically assigns an id to each item new items
          // and this ID is a string
          const newHoliday = {
            ...holiday,
            id: typeof holiday.id === 'string' ? undefined : holiday.id,
            start_datetime: holiday.start_datetime.toISOString(),
            end_datetime: holiday.end_datetime.toISOString(),
          };
          return newHoliday;
        })
      : undefined;

  const businessDays
    = data?.business_days && data?.business_days.length > 0
      ? data.business_days.map((day) => {
        return {
          ...day,
          start_time: dateToTimeString(day.start_time),
          end_time: dateToTimeString(day.end_time),
        };
      })
      : undefined;

  const body = {
    // Name is required
    name: data.name,
    ...(data?.states && data.states.length > 0 && { states: data.states }),
    ...(data?.regions && data.regions.length > 0 && { regions: data.regions }),
    ...(data?.subdomain && { subdomain: data.subdomain }),
    ...(data?.school_year_start_datetime && {
      school_year_start_datetime: data.school_year_start_datetime.toISOString(),
    }),
    ...(data.school_year_end_datetime && {
      school_year_end_datetime: data.school_year_end_datetime.toISOString(),
    }),
    ...(data.timezone && { timezone: data.timezone }),
    ...(businessDays && businessDays?.length > 0 && { business_days: businessDays }),
    ...(holidays && holidays.length > 0 && { holidays }),
  };

  return body;
};

export const convertBasicStateToForm = (basicInfo: TDraftDistrictBasicInfo): z.infer<typeof basicStepSchema> => {
  const daysOrder = Object.values(DaysOfWeek);
  const business_days = [...basicInfo.business_days];
  const sortedBusinessDays = business_days.sort((a, b) => {
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  }).map((day) => {
    return {
      ...day,
      start_time: new Date(day.start_time),
      end_time: new Date(day.end_time),
    };
  });

  return {
    name: basicInfo.name,
    states: basicInfo.states,
    regions: basicInfo.regions,
    subdomain: basicInfo.subdomain,
    school_year_start_datetime: basicInfo.school_year_start_datetime
      ? new Date(basicInfo.school_year_start_datetime)
      : new Date(),
    school_year_end_datetime: basicInfo.school_year_end_datetime
      ? new Date(basicInfo.school_year_end_datetime)
      : new Date(),
    timezone: basicInfo.timezone,
    business_days: sortedBusinessDays,
    holidays: basicInfo.holidays.map((holiday) => {
      return {
        ...holiday,
        start_datetime: new Date(holiday.start_datetime),
        end_datetime: new Date(holiday.end_datetime),
      };
    }),
  };
};
