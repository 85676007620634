import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolById } from './get-school-by-id';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolDetails = (schoolId?: string | null) => {
  const { isError, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BY_ID, schoolId?.toString()],
    queryFn: () => getSchoolById(schoolId!),
    enabled: !!schoolId,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch school details');
    }
  }, [isError]);

  return { isError, ...rest };
};
