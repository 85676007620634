import React from 'react';

export type TSelectedItem = {
  id: string;
  name: string;
};

type TUseSelectedItemsReturn = {
  selectedItems: TSelectedItem[];
  onChangeItem: (item: TSelectedItem) => void;
  onAddBulkItems: (item: TSelectedItem[]) => void;
  onReplaceAllItems: (item: TSelectedItem[]) => void;
  onClearAllItems: () => void;
  onRemoveBulkItems: (item: TSelectedItem[]) => void;
};

export const useSelectedItems = (): TUseSelectedItemsReturn => {
  const [selectedItems, setSelectedItems] = React.useState<TSelectedItem[]>([]);

  const handleReplaceAll = (newItems: TSelectedItem[]) => {
    setSelectedItems(newItems);
  };

  const handleChange = (item: TSelectedItem) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleAddBulk = (items: TSelectedItem[]) => {
    const newItems = items.filter(
      (item) => !selectedItems.some((selectedItem) => selectedItem.id === item.id),
    );
    setSelectedItems([...selectedItems, ...newItems]);
  };

  const handleRemoveBulk = (items: TSelectedItem[]) => {
    const uniqItems = selectedItems.filter(
      (item) => !items.some((selectedItem) => selectedItem.id === item.id),
    );

    setSelectedItems(uniqItems);
  };

  const handleClearAll = () => {
    setSelectedItems([]);
  };

  return {
    selectedItems,
    onChangeItem: handleChange,
    onReplaceAllItems: handleReplaceAll,
    onClearAllItems: handleClearAll,
    onAddBulkItems: handleAddBulk,
    onRemoveBulkItems: handleRemoveBulk,
  };
};
