import type { IBasePaginationRequestParameters, IPaginatedResponse, IUser, TDistrictUserStatus, TUserSchool } from '@purple/shared-types';

export type TAdminUsersRequestParameters = IBasePaginationRequestParameters & {
  school?: string[];
};

export type TAdminUsersManagementRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
  district?: string;
  school?: string;
  status?: string | null;
  role?: string | null;
};

export type TUserManagement = {
  id: string;
  date_joined: string;
  last_login?: string | null;
  email: string;
  full_name: string;
  grades: string[];
  prounitas_roles: string[];
  status: TDistrictUserStatus | null;
  district: string[];
  schools: TUserSchool[];
};

export type TUsersManagementResponse = IPaginatedResponse<TUserManagement[]>;

export type TUserManagementFilterOptionsRequestParameters = {
  district?: number;
};

export type TUserManagementFilterOptions = {
  role: string[];
  status: string[];
  school: {
    id: string;
    name: string;
  }[];
};

// NOTE: key is user id, value is array of school names
export type TUsersRoutingRuleCheck = Record<string, string[]>;

export type TUsersDeactivateCheck = {
  routing_rule_users: TUsersRoutingRuleCheck;
  opened_saf_users: string[];
  valid_users: string[];
};

export type TUserActivatePayload = {
  users: string[];
};

export type TUserRole = {
  id: number;
  name: string;
};

export type TUserInvitedBy = {
  id: string;
  email: string;
  full_name: string;
  avatar: string | null;
  title: string;
};

export type TUserDetailsInfo = {
  id: string;
  avatar: string | null;
  email: string;
  current_status: TDistrictUserStatus;
  roles: TUserRole[];
  date_joined: string;
  invited_by: TUserInvitedBy | null;
  first_name: string;
  last_name: string;
};

export type TUserBasicInfo = TUserDetailsInfo & {
  phone_number: string;
  title: string | null;
  department: string;
  responsible_for_grades: string[];
  is_show_on_scorecard: boolean;
  description: string;
  groups: {
    id: number;
    name: string;
  }[];
  purple_stats: {
    actions_created: 0;
    saf_prioritization_rate: 0;
    log_in: 4;
  };
};

export type TUserBasicDetailUpdatePayload = {
  prounitas_roles?: number[];
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  title?: string;
  department?: string;
  responsible_for_grades?: string[];
  is_show_on_scorecard?: boolean;
  description?: string;
  prounitas_groups?: number[];
};

export type TAdminUserLoginHistoryRequestParameters = IBasePaginationRequestParameters & {
  ordering?: string | null;
};

export const UserLoginHistoryStatus = {
  SUCCESSFUL: 'successful',
  ERROR: 'error',
} as const;
export type TUserLoginHistoryStatus = typeof UserLoginHistoryStatus[keyof typeof UserLoginHistoryStatus];

export const UserLoginHistoryType = {
  STANDARD: 'standard',
  SSO: 'sso',
  MOBILE: 'mobile',
} as const;
export type TUserLoginHistoryType = typeof UserLoginHistoryType[keyof typeof UserLoginHistoryType];

export const ReadableUserLoginHistoryType = {
  [UserLoginHistoryType.STANDARD]: 'Standard',
  [UserLoginHistoryType.SSO]: 'SSO',
  [UserLoginHistoryType.MOBILE]: 'Mobile',
} as const;

export type TUserLoginHistoryItem = {
  id: number;
  user: Pick<IUser, 'id' | 'email' | 'full_name' | 'avatar' | 'title'> | null;
  timestamp: string;
  browser: string;
  login_type: TUserLoginHistoryType;
  country: string;
  status: TUserLoginHistoryStatus;
  ip_address: string;
};

export type TAdminUserLoginHistoryResponse = IPaginatedResponse<TUserLoginHistoryItem[]>;
