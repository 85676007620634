import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { SCHOOLS_QUERY_KEYS } from './schools.const';
import { updateSchoolDetails } from './update-school-details';

export const useUpdateSchoolDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [SCHOOLS_QUERY_KEYS.UPDATE_SCHOOL_DETAILS],
    mutationFn: updateSchoolDetails,
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BY_ID, id] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_BASIC_DETAILS, id] });
      queryClient.invalidateQueries({ queryKey: [SCHOOLS_QUERY_KEYS.GET_ALL_SCHOOLS_LIST] });
      showSuccessToast('System message', 'School details were updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update school details. Please try again later');
    },
  });
};
