import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { bulkUsersActivate } from './bulk-users-activate';
import { ADMIN_USERS_MANAGEMENT_QUERY_KEYS } from './user.const';

export const useBulkActivateUsers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.ACTIVATE_USERS],
    mutationFn: bulkUsersActivate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USERS_MANAGEMENT_QUERY_KEYS.LIST_MANAGEMENT] });
      showSuccessToast('System message', 'User was successfully activated');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to activate users. Please try again later');
    },
  });
};
