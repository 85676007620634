import { type FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { GRADE_CHOICES, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, NoDataAvailable, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, DistrictFilterComboBox, SchoolsFilterComboBox, SelectAllEntitiesRow, SelectedItemsRow } from '~/components';
import { AddNewRuleModal, EditRuleModal } from '~/components/Modals/Safs';
import { ModalType } from '~/constants';
import { useDataTable, useModal, useSelectedItems } from '~/hooks';
import { useSafRuleBulkDelete, useSafRuleFilters, useSafRuleListMutation, useSafsRuleList } from '~/services';
import { ASSIGNED_ROLE_QUERY_NAME, ASSIGNED_USER_QUERY_NAME, DISTRICT_QUERY_NAME, GRADES_QUERY_NAME, NEEDS_QUERY_NAME, SCHOOLS_QUERY_NAME } from './constants';
import { useSafsRuleColumns } from './useSafsRuleColumns';
import type { TAdminSafRuleItem } from '~/services';

export const SafRoutingRules: FC = () => {
  const [searchParameters] = useSearchParams();

  const [clickableSaf, setClickableSaf] = useState<TAdminSafRuleItem | null>(null);

  const { openModal: openDeleteSafModal, closeModal: closeDeleteSafModal } = useModal(
    ModalType.SAF_RULE_DELETE,
  );

  const { openModal: openDeleteSafBulkModal, closeModal: closeDeleteSafBulkModal } = useModal(
    ModalType.SAF_RULE_DELETE_BULK,
  );

  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });
  const { query: schoolsQuery, onQueryChange: onSchoolsQueryChange, onClearQuery: onSchoolsQueryClear } = useQueryParameter({ queryName: SCHOOLS_QUERY_NAME, resetOffset: true });

  useEffect(() => {
    if (!districtQuery) {
      onSchoolsQueryClear();
    }
  }, [districtQuery, onSchoolsQueryClear]);

  const { openModal: openAddNewRuleModal } = useModal(ModalType.ADD_NEW_RULE);
  const { openModal: openEditRuleModal } = useModal(ModalType.EDIT_RULE);

  const { mutate: bulkDelete, isPending: isSafDeleting } = useSafRuleBulkDelete();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: safsData, isLoading: isSafsLoading } = useSafsRuleList({
    requestParameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      district: districtQuery || '',
      schools: schoolsQuery || '',
      assigned_roles: searchParameters.get(ASSIGNED_ROLE_QUERY_NAME) || '',
      assigned_users: searchParameters.get(ASSIGNED_USER_QUERY_NAME) || '',
      grades: searchParameters.get(GRADES_QUERY_NAME) || '',
      needs: searchParameters.get(NEEDS_QUERY_NAME) || '',
    },
  });

  const { data: filtersData } = useSafRuleFilters({
    requestParameters: {
      district: districtQuery || '',
      schools: schoolsQuery || '',
    },
    enabled: Boolean(districtQuery) && Boolean(schoolsQuery),
  });

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { mutate: getAllSafs, isPending: isGetAllSafsPending } = useSafRuleListMutation();

  const {
    selectedItems,
    onChangeItem,
    onReplaceAllItems,
    onClearAllItems,
  } = useSelectedItems();

  const allItemsOnPage = useMemo(() => {
    return (
      safsData?.results?.map((item) => ({
        id: item.id,
        name: item.need,
      })) || []
    );
  }, [safsData]);

  const singleDeleteClickHandler = (item: TAdminSafRuleItem) => {
    setClickableSaf(item);
    openDeleteSafModal();
  };

  const confirmDeleteSafHandler = () => {
    if (clickableSaf) {
      bulkDelete({ ids: [clickableSaf.id] }, {
        onSuccess: () => {
          closeDeleteSafModal();
        },
      });
    }
  };

  const editRuleClickHandler = (item: TAdminSafRuleItem) => {
    setClickableSaf(item);
    openEditRuleModal();
  };

  const filterConfig = useMemo(
    () => {
      const gradeFilters = {
        grades: Object.values(GRADE_CHOICES).map((grade) => ({
          value: grade,
          label: grade,
        })),
      };

      const fetchedFilters = filtersData || {};

      const filters = {
        ...(fetchedFilters || {}),
        ...gradeFilters,
      };

      return convertToFilterConfig(filters, {
        snakeCaseValue: false,
      });
    },
    [filtersData],
  );

  const { table } = useDataTable({
    columns: useSafsRuleColumns({
      onDelete: singleDeleteClickHandler,
      onEdit: editRuleClickHandler,
    }),
    data: safs,
    rowCount: safsData?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (selectedRows) => {
      const newSelectedSafs = Object.keys(selectedRows).filter((id) => {
        const existInSafs = safs.find((saf) => saf.id === id);
        const existInSelectedItems = selectedItems.find((item) => item.id === id);
        return existInSafs || existInSelectedItems;
      }).map((id) => {
        const name = safs.find((saf) => saf.id === id)?.need || selectedItems.find((item) => item.id === id)?.name || '';

        return {
          id,
          name,
        };
      });

      onReplaceAllItems(newSelectedSafs);
    },
  });

  const clearAllSelectedItemsHandler = () => {
    onClearAllItems();
    table.setRowSelection({});
  };

  const confirmBulkDeleteSafHandler = () => {
    const ids = selectedItems.map((item) => item.id);
    if (ids.length) {
      bulkDelete({ ids }, {
        onSuccess: () => {
          closeDeleteSafBulkModal();
          clearAllSelectedItemsHandler();
        },
      });
    }
  };

  // NOTE: update table selection in case if selectedItems were changed
  useEffect(() => {
    const prevSelectionState = table.getState().rowSelection;
    const prevSelectionIds = Object.keys(prevSelectionState);
    const prevSelectionStateLength = prevSelectionIds.length;

    const wasChanged = prevSelectionStateLength !== selectedItems.length || prevSelectionIds.some((id) => !selectedItems.find((item) => item.id === id));

    if (!wasChanged) {
      return;
    }

    const selectedRows = selectedItems.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: true,
      };
    }, {});
    table.setRowSelection(selectedRows);
  }, [selectedItems, table]);

  const selectAllFoundHandler = () => {
    getAllSafs({
      search: debounceSearch,
      limit: safsData?.count || 0,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
      ...(districtQuery && { district: districtQuery }),
      ...(schoolsQuery && { schools: schoolsQuery }),
    }, {
      onSuccess: (allSafsResponse) => {
        const newItems
          = allSafsResponse.results.map((saf) => {
            return {
              id: saf.id,
              name: saf.need,
            };
          }) || [];
        onReplaceAllItems(newItems);
      },
    });
  };

  const selectedSchools = useMemo(() => {
    const array = schoolsQuery?.split(',') || [];
    return array;
  }, [schoolsQuery]);

  const canShowTable = useMemo(() => Boolean(districtQuery) && Boolean(schoolsQuery), [districtQuery, schoolsQuery]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-1">
        <div className="flex w-full items-center justify-between gap-4 p-4">
          <div className="flex items-center gap-4">
            <div className="min-w-[200px]">
              <DistrictFilterComboBox value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} placeholder="Select district" />
            </div>
            <div className="min-w-fit">
              <SchoolsFilterComboBox values={selectedSchools} onChange={(newValue) => onSchoolsQueryChange(newValue.join(','))} onClear={onSchoolsQueryClear} districtId={districtQuery} isDistrictRequired />
            </div>
            <AppFilters config={filterConfig} disabled={!canShowTable} />
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
              disabled={!canShowTable}
            />
          </div>
          <div className="flex flex-row gap-4">
            <Button
              type="button"
              variant="destructive_secondary"
              size="default"
              disabled={!selectedItems.length || !canShowTable}
              onClick={openDeleteSafBulkModal}
            >
              Delete
            </Button>
            <Button variant="secondary" disabled={!canShowTable}>File Upload</Button>
            <Button onClick={openAddNewRuleModal}>Add New Rule</Button>
            {Boolean(canShowTable) && (
              <DataTableViewOptions table={table} />
            )}
          </div>
        </div>
        {Boolean(canShowTable) && (
          <div className="flex flex-col gap-4 px-4 pb-4">
            <SelectAllEntitiesRow totalCount={safsData?.count || 0} selectedItems={selectedItems} allItemsOnPage={allItemsOnPage} onSelectAll={selectAllFoundHandler} isSelectingAll={isGetAllSafsPending} entityName="Saf(s)" />
            <SelectedItemsRow selectedItems={selectedItems} onClearAll={clearAllSelectedItemsHandler} onClose={onChangeItem} />
          </div>
        )}

      </div>
      {canShowTable
        ? (
            <DataTable
              table={table}
              loading={isSafsLoading}
            >
            </DataTable>
          )
        : (<NoDataAvailable iconName="folder-open" title="No Data Found" description="Please select district and school(s) to view data" className="min-h-96" />)}

      <CallToActionModal
        modalName={ModalType.SAF_RULE_DELETE}
        modalTitle="Delete SAF"
        modalDescription="Are you sure you want to delete the selected SAF rule?"
        modalTextContent="Are you sure you want to delete the selected SAF rule?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <CallToActionModal
        modalName={ModalType.SAF_RULE_DELETE_BULK}
        modalTitle="Delete SAFs"
        modalDescription="Are you sure you want to delete the selected SAFs rules?"
        modalTextContent="Are you sure you want to delete the selected SAFs rules?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmBulkDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <AddNewRuleModal />
      <EditRuleModal id={clickableSaf?.id} />
    </div>
  );
};
