import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { updateDataOrder } from '@purple/shared-utils';
import { Checkbox, Form, Input, Label, NoDataAvailable } from '@purple/ui';
import { VisualizerStep } from '~/constants';
import { useUnsavedChanges } from '~/providers';
import { useDataTableView, useUpdateDataTableView, useUpdateDataTableViewOrder } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { CustomDataViewOverlay } from './CustomDataViewOverlay';
import { DataTableViewFieldItem } from './DataTableViewFieldItem';
import { DataTableViewSkeleton } from './DataTableViewSkeleton';
import { dataPointsSchema } from './schema';
import type {
  DragEndEvent,
  DragStartEvent,
} from '@dnd-kit/core';
import type { z } from 'zod';
import type { TVisualizerStep } from '~/constants';
import type { TVisualizerDataTableView } from '~/services';

type TDataTableViewProps = {
  formId: string;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const DataTableView: React.FC<TDataTableViewProps> = (props) => {
  const { formId, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const [search, setSearch] = useState<string>('');
  const [dragActiveId, setDragActiveId] = useState<number | null>(null);

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { setShouldShowUnsaved } = useUnsavedChanges();

  const { data, isFetching } = useDataTableView({
    districtId,
    enabled: stepQuery === VisualizerStep.DATA_TABLE_VIEW && !!districtId,
  });
  const { mutate: updatePoints } = useUpdateDataTableView();
  const { mutate: updateOrder } = useUpdateDataTableViewOrder();

  const defaultValues: z.infer<typeof dataPointsSchema> = useMemo(
    () => ({ points: data?.data_points ?? [] }),
    [data?.data_points],
  );

  const form = useForm<z.infer<typeof dataPointsSchema>>({
    resolver: zodResolver(dataPointsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  useEffect(() => {
    if (form.formState.isDirty) {
      setShouldShowUnsaved(true);
    } else {
      setShouldShowUnsaved(false);
    }
  }, [form.formState.isDirty, setShouldShowUnsaved]);

  const { fields, replace } = useFieldArray({
    control: form.control,
    name: 'points',
    keyName: 'fieldId',
  });

  const tablePoints = form.watch('points');
  const filteredPoints = fields.filter((field) =>
    field.column_header_name.trim().toLowerCase().includes(search.trim().toLowerCase())
    || field.alias.trim().toLowerCase().includes(search.trim().toLowerCase()),
  );
  const sortablePoints = filteredPoints.filter((field) => !field.is_default);

  const isSomePointsSelected = tablePoints
    .filter((field) => !field.is_default)
    .some((field) => field.is_displayed);
  const isAllPointsSelected = tablePoints
    .filter((field) => !field.is_default)
    .every((field) => field.is_displayed);

  const saveDetailsClickHandler = (formData: z.infer<typeof dataPointsSchema>) => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    onSubmit?.();
    updatePoints(
      {
        district_id: districtId,
        data_points: formData.points,
      },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );
  };

  const selectAllPointsClickHandler = (checked: boolean) => {
    form.setValue('points', tablePoints.map(
      (field) =>
        filteredPoints.some((item) => item.id === field.id)
          ? ({ ...field, is_displayed: field.is_default ? true : checked })
          : field,
    ));
  };

  const searchChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const itemDragStartHandler = (event: DragStartEvent) => {
    setDragActiveId(event.active.id as number);
  };

  const itemDragEndHandler = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      if (typeof active.id !== 'number' || typeof over.id !== 'number') return;
      const oldPosition = tablePoints.findIndex((field) => field.id === active.id);
      const newPosition = tablePoints.findIndex((field) => field.id === over.id);
      if (oldPosition === undefined || newPosition === undefined) return;
      const repositionedFields = updateDataOrder<TVisualizerDataTableView>(tablePoints, newPosition, oldPosition);
      replace(repositionedFields);
      if (!districtId) return;
      updateOrder({ district_id: districtId, data_points: repositionedFields }, {
        onSuccess: () => {
          setShouldShowUnsaved(false);
        },
      });
    }
    setDragActiveId(null);
  };

  const itemDragCancelHandler = () => {
    setDragActiveId(null);
  };

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  if (isFetching) return <DataTableViewSkeleton />;

  return (
    <div className="flex size-full flex-col gap-4">
      <div className="relative w-full max-w-[300px]">
        <PurpleIcon name="search" className="absolute left-3 top-2.5 size-5 text-grey-600" />
        <Input name="search" type="text" placeholder="Search by column name" className="pl-10" value={search} onChange={searchChangeHandler} />
      </div>
      <div className="flex w-full flex-1 flex-col">
        <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 py-3">
          <strong className="col-span-1 w-full px-3 text-xs font-semibold uppercase text-grey-600">
            #
          </strong>
          <strong className="col-span-1 w-full px-3 text-xs font-semibold uppercase text-grey-600" />
          <strong className="col-span-3 w-full px-3 text-xs font-semibold uppercase text-grey-600">
            Column Header Name
          </strong>
          <div className="col-span-3 flex w-full items-center gap-2 px-3">
            {filteredPoints.length > 0 && (
              <Checkbox
                id="select-all-views"
                variant={isAllPointsSelected ? 'default' : 'checkedAll'}
                checked={isSomePointsSelected}
                onCheckedChange={selectAllPointsClickHandler}
              />
            )}
            <Label htmlFor="select-all-views" className="text-xs font-semibold uppercase text-grey-600">
              Display on Panel
            </Label>
          </div>
          <strong className="col-span-4 w-full px-3 text-xs font-semibold uppercase text-grey-600">
            District Alias
          </strong>
        </div>
        <Form
          providerProps={form}
          id={formId}
          className="flex w-full flex-1 flex-col"
          onSubmit={form.handleSubmit(saveDetailsClickHandler)}
          onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
        >
          <DndContext
            sensors={sensors}
            onDragEnd={itemDragEndHandler}
            onDragStart={itemDragStartHandler}
            onDragCancel={itemDragCancelHandler}
            collisionDetection={closestCenter}
            modifiers={[restrictToWindowEdges]}
          >
            <SortableContext items={sortablePoints} strategy={verticalListSortingStrategy}>
              <ul className="flex w-full flex-col">
                {filteredPoints.map((field) => (
                  <DataTableViewFieldItem key={field.fieldId} fieldId={field.id} />
                ))}
              </ul>
            </SortableContext>
            <CustomDataViewOverlay fieldId={dragActiveId} />
          </DndContext>
          {filteredPoints.length === 0 && (
            <NoDataAvailable
              iconName="folder-open"
              className="col-span-12 my-auto px-4 py-12"
              title="No table column found"
              description="No column found for the search criteria. Please try again with a different search term."
            />
          )}
        </Form>
      </div>
    </div>
  );
};
