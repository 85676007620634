import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, cn, Input, Label, Switch } from '@purple/ui';

type TLinkEditBlockProperties = React.HTMLAttributes<HTMLDivElement> & {
  defaultUrl?: string;
  defaultText?: string;
  defaultIsNewTab?: boolean;
  onSave: (url: string, text?: string, isNewTab?: boolean) => void;
};

export const LinkEditBlock = forwardRef<HTMLDivElement, TLinkEditBlockProperties>(
  ({ onSave, defaultIsNewTab, defaultUrl, defaultText, className }, reference) => {
    const formReference = useRef<HTMLDivElement>(null);

    const [url, setUrl] = useState<string>(defaultUrl || '');
    const [text, setText] = useState<string>(defaultText || '');
    const [isNewTab, setIsNewTab] = useState<boolean>(defaultIsNewTab || false);

    const applyLinkSettingsHandler = useCallback(
      (event: React.FormEvent) => {
        event.preventDefault();
        if (formReference.current) {
          const isValid = [...formReference.current.querySelectorAll('input')].every((input) => input.checkValidity());

          if (isValid) {
            onSave(url, text, isNewTab);
          } else {
            for (const input of formReference.current.querySelectorAll('input')) {
              if (!input.checkValidity()) {
                input.reportValidity();
              }
            }
          }
        }
      },
      [onSave, url, text, isNewTab],
    );

    useImperativeHandle(reference, () => formReference.current as HTMLDivElement);

    return (
      <div ref={formReference} className="bg-white">
        <div className={cn('space-y-2', className)}>
          <div className="space-y-1">
            <Label>URL</Label>
            <Input
              type="url"
              required
              placeholder="Enter URL"
              value={url}
              onChange={(event) => setUrl(event.target.value)}
            />
          </div>

          <div className="space-y-1">
            <Label>Display Text (optional)</Label>
            <Input
              type="text"
              placeholder="Enter display text"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </div>

          <div className="flex items-center space-x-2">
            <Label>Open in New Tab</Label>
            <Switch checked={isNewTab} onCheckedChange={setIsNewTab} />
          </div>
          <div className="flex justify-end space-x-2">
            <Button type="button" size="small" onClick={applyLinkSettingsHandler}>
              Apply
            </Button>
          </div>
        </div>
      </div>
    );
  },
);
LinkEditBlock.displayName = 'LinkEditBlock';
