import { useMemo } from 'react';
import { convertBooleanToText, cutGradeName, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TSubmissionDetailsViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const SubmissionDetailsView = ({ data }: TSubmissionDetailsViewProperties) => {
  const { submitter, submitter_type, submitted_school, submitted_student_grade, is_submitted_anonymously, submitted_student_first_name, submitted_student_middle_name, submitted_student_last_name } = data;

  const submitterName = useMemo(() => {
    return submitter ? `${submitter.first_name} ${submitter.last_name}` : '';
  }, [submitter]);

  const submittedStudentName = useMemo(() => {
    return `${submitted_student_first_name} ${submitted_student_middle_name} ${submitted_student_last_name}`;
  }, [submitted_student_first_name, submitted_student_middle_name, submitted_student_last_name]);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Name</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitterName || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Type</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitter_type ? snakeToCamelWithSpaces(submitter_type) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Phone</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitter?.phone || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitter Email</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitter?.email || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted School</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitted_school?.name || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Grade</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submitted_student_grade ? cutGradeName(submitted_student_grade) : '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Anonymously</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{convertBooleanToText(is_submitted_anonymously) || '-'}</Text>
      </div>
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-600">Submitted Student Name</Text>
        <Text variant="size-16" type="body-500" className="w-full text-grey-950">{submittedStudentName || '-'}</Text>
      </div>
    </div>
  );
};

export { SubmissionDetailsView };
