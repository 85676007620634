import { PurpleIcon } from '@purple/icons';
import { Banner, BannerDescription, Button, Heading, Text } from '@purple/ui';

type TVisualizerHeaderProps = {
  isBannerVisible?: boolean;
  onBannerClose?: () => void;
};

export const VisualizerHeader: React.FC<TVisualizerHeaderProps> = (props) => {
  const { isBannerVisible = false, onBannerClose } = props;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-1">
        <Heading tag="h1" type="heading-600" variant="size-22" className="text-grey-title">Students Visualizer</Heading>
        <Text type="body-400" variant="size-14" className="text-grey-800">Setup the visualizer, color trends and flag calculations</Text>
      </div>
      {isBannerVisible && (
        <Banner>
          <PurpleIcon name="information-circle" className="size-5" />
          <Button
            type="button"
            aria-label="Close banner"
            variant="primary"
            size="icon_32"
            iconLeft={<PurpleIcon name="X" />}
            className="z-10 bg-brand-blue-500 text-white"
            onClick={onBannerClose}
          />
          <BannerDescription>
            You have selected new district! Customize default settings and add additional data for visualization.
          </BannerDescription>
        </Banner>
      )}
    </div>
  );
};
