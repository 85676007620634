import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getAbsencesThresholds } from './get-absences-thresholds';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';

export const useAbsencesThresholds = ({ districtId = null, enabled }: { districtId?: number | string | null; enabled?: boolean }) => {
  const { isError, ...queryData } = useQuery({
    queryKey: [VISUALIZER_QUERY_KEYS.GET_VISUALIZER_ABSENCES_THRESHOLDS, districtId?.toString()],
    queryFn: () => getAbsencesThresholds(districtId!),
    enabled,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch absences thresholds', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
