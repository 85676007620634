import { useMutation, useQueryClient } from '@tanstack/react-query';
import { capitalizeFirstLetter } from '@purple/shared-utils';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { createDraftDistrict } from './create-draft-district';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError } from 'axios';
import type { TDraftDistrictBasicInfo, TDraftDistrictBasicInfoRequest } from './districts.types';

export const useCreateDraftDistrict = () => {
  const queryClient = useQueryClient();

  return useMutation<TDraftDistrictBasicInfo, AxiosError<{
    responsible_for_setup: string[];
    name: string[];
    subdomain: string[];
  }>, TDraftDistrictBasicInfoRequest>({
    mutationKey: [DISTRICTS_QUERY_KEYS.CREATE_DRAFT_DISTRICT],
    mutationFn: createDraftDistrict,
    onSuccess: () => {
      showSuccessToast('System message', 'Draft was created successfully');
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL] });
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        const { responsible_for_setup, name, subdomain } = error?.response?.data;

        const isErrorMessageExist = Boolean(responsible_for_setup?.length) || Boolean(name?.length) || Boolean(subdomain?.length);

        if (isErrorMessageExist) {
          const nameErrorMessage = name?.length ? name.join(', ') : '';
          const responsibleForSetupErrorMessage = responsible_for_setup?.length ? responsible_for_setup.join(', ') : '';
          const subdomainErrorMessage = subdomain?.length ? subdomain.join(', ') : '';

          const aggregatedErrorMessage = [nameErrorMessage, responsibleForSetupErrorMessage, subdomainErrorMessage].filter(Boolean).map(capitalizeFirstLetter).join(' ');
          showErrorToast('System message', aggregatedErrorMessage);
        } else {
          showErrorToast('System message', 'Could not create a draft. Check the provided information and try again');
        }
      }
    },
  });
};
