import { z } from 'zod';

export const leaderUsersSchema = z
  .object({
    users: z.array(
      z.object({
        id: z.string().optional(),
        email: z.string().trim().min(1, 'Email is required').email('Please enter a valid email address').optional(),
        prounitas_roles: z.union([
          z.number().min(1, 'Role is required'),
          z.string().min(1, 'Role is required'),
          z.null(),
        ]),
      }),
    ),
    no_district_leader_available: z.boolean(),
  })
  .refine((data) => data.no_district_leader_available || data.users.length > 0, {
    message: 'At least one leader is required',
    path: ['users'],
  })
  .refine((data) => data.no_district_leader_available || data.users.every((user) => user.email?.trim() !== ''), {
    message: 'Email is required',
    path: ['users.email'],
  })
  .refine((data) => data.no_district_leader_available || data.users.every((user) => user.prounitas_roles !== null), {
    message: 'Role is required',
    path: ['users.prounitas_roles'],
  });
