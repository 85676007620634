import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { updateDataTableView } from './update-data-table-view';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerDataTableViewPayload, TVisualizerDataTableViewResponse } from './visualizer.types';

export const useUpdateDataTableViewOrder = () => {
  return useMutation<TVisualizerDataTableViewResponse, AxiosError, TVisualizerDataTableViewPayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_DATA_TABLE_VIEW_ORDER],
    mutationFn: updateDataTableView,
    onError: () => {
      showErrorToast('System message', 'Failed to update data table view order. Please try again later');
    },
  });
};
