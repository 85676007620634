import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { NOTE_QUERY_KEYS } from '@purple/shared-utils';
import { showErrorToast } from '~/utils/toasts';
import { getNotesList } from './get-notes-list';
import type { AxiosError } from 'axios';
import type { TNoteListResponse, TNoteQueryParameters } from '@purple/shared-types';
import type { TCustomUseQueryOptions } from '~/types/query';

type TUseNotesListProperties = {
  parameters: TNoteQueryParameters;
  queryOptions?: TCustomUseQueryOptions<TNoteListResponse, AxiosError>;
};

export const useNotesList = ({ parameters, queryOptions }: TUseNotesListProperties) => {
  const { isError, error, ...rest } = useQuery<TNoteListResponse, AxiosError>({
    ...queryOptions,
    queryKey: [NOTE_QUERY_KEYS.GET_NOTES_LIST, parameters],
    queryFn: () => getNotesList(parameters),
    enabled: (!!parameters.student || !!parameters.action_group || !!parameters.action) && queryOptions?.enabled,
  });
  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System error', 'Unable to retrieve notes list');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
