import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getUsersList } from './get-users';
import { ADMIN_CURRENT_USER_QUERY_KEYS } from './user.const';
import type { AxiosError } from 'axios';
import type { TUserSearchResponse } from '@purple/shared-types';
import type { TAdminUsersRequestParameters } from './user.types';

export const useUsersList = ({
  requestParameters,
  enabled = true,
}: {
  requestParameters: TAdminUsersRequestParameters;
  enabled?: boolean;
}) => {
  const { isError, error, ...rest } = useQuery<TUserSearchResponse, AxiosError>({
    queryKey: [ADMIN_CURRENT_USER_QUERY_KEYS.LIST, requestParameters],
    queryFn: () => getUsersList(requestParameters),
    enabled,
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System Message', 'Failed to fetch list of users');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
