import { useWatch } from 'react-hook-form';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Heading,
  Input,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { Control, FieldValues, Path } from 'react-hook-form';

type THolidayFieldItemProperties<TFormValues extends FieldValues = FieldValues> = {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  sequence: number;
  startDateName: Path<TFormValues>;
  endDateName: Path<TFormValues>;
  isRecurringName: Path<TFormValues>;
  disableRemove?: boolean;
  onRemove?: () => void;
};

export const HolidayFieldItem = <TFormValues extends FieldValues = FieldValues>(
  props: THolidayFieldItemProperties<TFormValues>,
): JSX.Element => {
  const {
    control,
    name,
    startDateName,
    endDateName,
    isRecurringName,
    sequence,
    disableRemove = false,
    onRemove,
  } = props;

  const startDate = useWatch({ control, name: startDateName });

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-grey-200 px-6 py-4">
      <div className="flex items-center gap-2">
        <Heading tag="h3" type="heading-600" variant="size-18" className="text-base text-grey-950">
          Holiday / School Closure #
          {sequence}
        </Heading>
        {!disableRemove && (
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="destructive_secondary"
                size="icon_32"
                className="border-0"
                iconLeft={<PurpleIcon name="trash" />}
                onClick={onRemove}
              />
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent>Remove holiday</TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}
      </div>
      <FormField
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormItem>
            <FormLabel required>School Closure Name</FormLabel>
            <FormControl>
              <Input {...field} type="text" placeholder="Enter holiday name" isError={!!fieldState.error} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="flex items-start gap-2">
        <FormField
          control={control}
          name={startDateName}
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormLabel required>Start Date</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  captionLayout="dropdown"
                  contentClassName="w-80"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={endDateName}
          render={({ field, fieldState }) => (
            <FormItem className="w-full">
              <FormLabel required>End Date</FormLabel>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date & time"
                  formatterString="PPP p"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled}
                  defaultMonth={field.value}
                  selected={field.value}
                  disabled={{
                    before: startDate,
                  }}
                  contentClassName="w-80"
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  onTimeChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <FormField
        control={control}
        name={isRecurringName}
        render={({ field }) => (
          <FormItem className="flex flex-row items-center gap-2 space-y-0">
            <FormControl>
              <Checkbox checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormLabel className="text-sm font-medium">Repeat each year</FormLabel>
          </FormItem>
        )}
      />
    </div>
  );
};
