import axiosInstance from '../axios-config';
import { GROUPS_ENDPOINTS } from '../endpoints';
import type { TGroupListResponse, TGroupsListRequestParameters } from './groups.types';

export const getGroupList = async (requestParameters?: TGroupsListRequestParameters) => {
  const response = await axiosInstance.get<TGroupListResponse>(GROUPS_ENDPOINTS.ROOT, {
    params: requestParameters,
  });
  return response.data;
};
