import axiosInstance from '../axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictColumnMappingPayload } from './districts.types';

export const updateMappingColumns = async ({ districtId, mappingId, ...rest }: TDistrictColumnMappingPayload) => {
  const response = await axiosInstance.patch<void>(
    DISTRICTS_ENDPOINTS.MAPPINGS_DETAILS(districtId.toString(), mappingId.toString()),
    rest,
  );
  return response;
};
