import { EmailStatus } from '@purple/shared-types';

export const StatusToBadgeVariantMap = {
  [EmailStatus.New]: 'info',
  [EmailStatus.Sent]: 'success-light',
  [EmailStatus.Acknowledged]: 'success',
  [EmailStatus.Pending]: 'warning-light',
  [EmailStatus.Failed]: 'danger',
} as const;

export const SAF_EMAIL_KEYS = {
  emailId: 'emailId',
} as const;

export const SAF_EMAIL_STATUSES_OPTIONS = [
  { label: 'New', value: EmailStatus.New },
  { label: 'Sent', value: EmailStatus.Sent },
  { label: 'Acknowledged', value: EmailStatus.Acknowledged },
  { label: 'Pending', value: EmailStatus.Pending },
  { label: 'Failed', value: EmailStatus.Failed },
] as const;
