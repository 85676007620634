import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DASHBOARD_QUERY_KEYS } from './dashboard.const';
import { getDocumentedActions } from './get-documented-actions';
import type { AxiosError } from 'axios';
import type {
  TDashboardDocumentedActionsRequestParameters,
  TDashboardDocumentedActionsResponse,
} from './dashboard.types';

export const useDocumentedActions = (parameters: TDashboardDocumentedActionsRequestParameters) => {
  const { isError, error, ...rest } = useQuery<TDashboardDocumentedActionsResponse, AxiosError>({
    queryKey: [DASHBOARD_QUERY_KEYS.GET_DASHBOARD_DOCUMENTED_ACTIONS, parameters],
    queryFn: () => getDocumentedActions(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to get documented actions');
    }
  }, [isError, error]);

  return { isError, error, ...rest };
};
