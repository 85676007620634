import axiosInstance from '~/services/axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TUserManagementFilterOptions, TUserManagementFilterOptionsRequestParameters } from './user.types';

export const getUsersManagementFilterOptions = async (parameters: TUserManagementFilterOptionsRequestParameters) => {
  const response = await axiosInstance.get<TUserManagementFilterOptions>(
    ADMIN_USER_MANAGEMENT.USERS_FILTER_OPTIONS,
    {
      params: parameters,
    },
  );
  return response.data;
};
