import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Placeholder } from '@tiptap/extension-placeholder';
import { TextAlign } from '@tiptap/extension-text-align';
import { Typography } from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { z } from 'zod';
import { isFieldDefined, isObjectEmpty } from '@purple/shared-utils';
import { cn, Form, FormControl, FormField, FormItem, FormMessage, Text } from '@purple/ui';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import { LinkBubbleMenu } from '../../LinkBubbleMenu';
import { SafEditorToolbar } from '../../SafEditorToolbar';
import { SafLinkExtention } from '../../SafLinkExtention';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const sectionSchema = z.object({
  acknowledgement_text: z.string().trim(),
});

type TSafAcknowledgementSectionProperties = {
  district: TDistrictDetails;
};

export const SafAcknowledgementSection: React.FC<TSafAcknowledgementSectionProperties> = (props) => {
  const {
    district: { id, acknowledgement_text },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues: z.infer<typeof sectionSchema> = useMemo(
    () => ({
      acknowledgement_text: typeof acknowledgement_text === 'string' ? acknowledgement_text : '',
    }),
    [acknowledgement_text],
  );

  const form = useForm<z.infer<typeof sectionSchema>>({
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editorConfig = useEditor({
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: {
          HTMLAttributes: {
            class: 'text-node',
          },
        },
        bulletList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        orderedList: {
          HTMLAttributes: {
            class: 'list-node',
          },
        },
        dropcursor: {
          width: 2,
          class: 'ProseMirror-dropcursor border',
        },
      }),
      Underline,
      Typography,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      SafLinkExtention.configure({
        autolink: true,
        defaultProtocol: 'https',
      }),
      Placeholder.configure({
        placeholder: () => 'Enter your acknowledgement text here...',
      }),
    ],
    editorProps: {
      attributes: {
        role: 'textbox',
        class: 'focus:outline-none h-full',
      },
    },
    content:
      form.getValues('acknowledgement_text') === ''
        ? form.getValues('acknowledgement_text')
        : (JSON.parse(form.getValues('acknowledgement_text')) as string),
    editable: isEditing,
    onUpdate: ({ editor }) => {
      const isEmpty = editor.getText().trim().length === 0;
      form.setValue('acknowledgement_text', isEmpty ? '' : JSON.stringify(editor.getJSON()), { shouldValidate: true });
    },
  });

  const editClickHandler = () => {
    setIsEditing(true);
    editorConfig?.setEditable(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    editorConfig?.setEditable(false);
    if (isFieldDefined(acknowledgement_text)) {
      if (typeof acknowledgement_text === 'string' && acknowledgement_text !== '') {
        editorConfig?.commands.setContent(JSON.parse(acknowledgement_text));
      }
      if (isObjectEmpty(acknowledgement_text) || acknowledgement_text === '') {
        editorConfig?.commands.setContent('');
      }
    }
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof sectionSchema>) => {
    updateDistrictSafProcess(
      {
        id,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          editorConfig?.setEditable(false);
          form.reset(formData);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader
          title="Acknowledgement Text"
          editing={isEditing}
          loading={isPending}
          onCancel={cancelClickHandler}
          onEdit={editClickHandler}
          onSave={form.handleSubmit(saveDetailsClickHandler)}
        />
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          This is the last page of the SAF. You can edit the default message.
        </Text>
      </div>
      <div className="flex w-full flex-col gap-2">
        {isEditing
          ? (
              <Form providerProps={form} className="flex w-full flex-col gap-2">
                <FormField
                  control={form.control}
                  name="acknowledgement_text"
                  render={({ field, fieldState }) => (
                    <FormItem className="space-y-2">
                      <SafEditorToolbar editor={editorConfig} />
                      <FormControl>
                        <EditorContent
                          ref={field.ref}
                          editor={editorConfig}
                          className={cn(
                            'minimal-tiptap-editor flex h-full min-h-[80px] w-full cursor-text flex-col rounded-lg border border-grey-300 px-3 py-2.5 font-primary text-sm font-medium text-grey-950 transition-colors duration-200 focus-within:border-brand-blue-700 hover:border-brand-blue-700 focus:border-brand-blue-700',
                            {
                              'border-error-main': !!fieldState.error,
                            },
                          )}
                        />
                      </FormControl>
                      {editorConfig && <LinkBubbleMenu editor={editorConfig} />}
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Form>
            )
          : (
              <EditorContent
                editor={editorConfig}
                disabled
                className="minimal-tiptap-editor flex size-full cursor-text flex-col p-0 font-primary text-sm font-medium text-grey-950"
              />
            )}
      </div>
    </section>
  );
};
