import { Skeleton } from '@purple/ui';

export const VisualizerSetupSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-6 w-full max-w-[124px]" />
          <Skeleton className="h-5 w-full max-w-[600px]" />
        </div>
        <Skeleton className="h-10 w-full max-w-[114px]" />
      </div>
      <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-6 w-full max-w-[124px]" />
          <Skeleton className="h-5 w-full max-w-[600px]" />
        </div>
        <Skeleton className="h-10 w-full max-w-[114px]" />
      </div>
      <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-6 w-full max-w-[124px]" />
          <Skeleton className="h-5 w-full max-w-[600px]" />
        </div>
        <Skeleton className="h-10 w-full max-w-[114px]" />
      </div>
      <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-6 w-full max-w-[124px]" />
          <Skeleton className="h-5 w-full max-w-[600px]" />
        </div>
        <Skeleton className="h-10 w-full max-w-[114px]" />
      </div>
      <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-grey-200 px-4 py-6">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-6 w-full max-w-[124px]" />
          <Skeleton className="h-5 w-full max-w-[600px]" />
        </div>
        <Skeleton className="h-10 w-full max-w-[114px]" />
      </div>
    </div>
  );
};
