import { Text } from '@purple/ui';
import type { TAdminSafBasicDetailsView } from '~/services';

type TSafClosingContextViewProperties = {
  data: TAdminSafBasicDetailsView;
};

const SafClosingContextView = ({ data }: TSafClosingContextViewProperties) => {
  const { closing_context } = data;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full gap-2">
        <Text variant="size-16" type="body-400" className="w-full text-grey-950">{closing_context || '-'}</Text>
      </div>
    </div>
  );
};

export { SafClosingContextView };
