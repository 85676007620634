import * as z from 'zod';

export const updateSubmissionDetailsSchema = z.object({
  submitter_first_name: z.string().trim().min(1, {
    message: 'First Name is required.',
  }),
  submitter_last_name: z.string().trim().min(1, {
    message: 'Last Name is required.',
  }),
  submitter_email: z.string().trim().min(1, 'Email is required').email('Please enter a valid email address'),
  submitter_phone: z.string().trim().min(1, {
    message: 'Phone is required.',
  }),
  submitter_type: z.string().trim().min(1, {
    message: 'Type is required.',
  }),
  submitted_school: z.string().trim().min(1, {
    message: 'School is required.',
  }),
  submitted_student_grade: z.string(),
  is_submitted_anonymously: z.boolean(),
  submitted_student_first_name: z.string().trim().min(1, {
    message: 'First Name is required.',
  }),
  submitted_student_middle_name: z.string().optional(),
  submitted_student_last_name: z.string().trim().min(1, {
    message: 'Last Name is required.',
  }),
});
