import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { APP_PERMISSIONS, Guard } from '@purple/permissions';
import { DistrictUserStatus, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AccessDenied, AppFilters, Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Heading, SearchInput, Text } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, DistrictFilterComboBox, SelectAllEntitiesRow, SelectedItemsRow } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal, useSelectedItems } from '~/hooks';
import { useBulkActivateUsers, useDeleteUser, useDistrictBulkDeactivateUsers, useGetUsersListMutation, useUserManagementFilterOptions, useUsersListManagement } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { useUserManagementColumns } from './userManagementColumns';
import type { TUserManagement, TUsersDeactivateCheck } from '~/services';

const DISTRICT_QUERY_NAME = 'district';

const UsersList = () => {
  const [searchParameters] = useSearchParams();

  const [clickableUser, setClickableUser] = useState<TUserManagement | null>(null);

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.BULK_USERS_DEACTIVATE,
  );
  const { openModal: openDeactivateWithSafModal, closeModal: closeDeactivateWithSafModal } = useModal(
    ModalType.BULK_USERS_WITH_SAF_DEACTIVATE,
  );
  const { openModal: openActivateModal, closeModal: closeActivateModal } = useModal(
    ModalType.USER_ACTIVATE_MODAL,
  );
  const { openModal: openBulkActivateModal, closeModal: closeBulkActivateModal } = useModal(
    ModalType.BULK_USERS_ACTIVATE_MODAL,
  );
  const { openModal: openDeleteUserModal, closeModal: closeDeleteUserModal } = useModal(
    ModalType.USER_DELETE,
  );

  const { query: sortQuery } = useQueryParameter({ queryName: SORT_QUERY_NAME, resetOffset: true });
  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: options, isFetching: isOptionsLoading } = useUserManagementFilterOptions({
    district: districtQuery ? +districtQuery : undefined,
    enabled: true,
  });

  const { data, isFetching: isUsersFetching } = useUsersListManagement({
    ...(districtQuery && { district: districtQuery }),
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: sortQuery,
    school: searchParameters.get('school') || '',
    status: searchParameters.get('status') || '',
    role: searchParameters.get('role') || '',
  });

  const { mutate: getAllUsers, isPending: isGetAllUsersPending } = useGetUsersListMutation();

  const {
    selectedItems,
    onChangeItem,
    onReplaceAllItems,
    onClearAllItems,
  } = useSelectedItems();

  const allItemsOnPage = useMemo(() => {
    return (
      data?.results?.map((item) => ({
        id: item.id,
        name: item.full_name,
      })) || []
    );
  }, [data]);

  const { mutate: bulkUserDeactivate, isPending: isDeactivatePending } = useDistrictBulkDeactivateUsers();

  const { mutate: bulkUserActivate, isPending: isActivatePending } = useBulkActivateUsers();

  const { mutate: userDelete, isPending: isDeletePending } = useDeleteUser();

  const users = useMemo(() => data?.results ?? [], [data?.results]);

  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
          (accumulator, [key, value]) =>
            key === 'status' || key === 'role' ? { ...accumulator, [key]: value } : accumulator,
          {
            status: Object.values(DistrictUserStatus).map((status) => ({
              id: status,
              name: status,
            })),
            ...(options?.school?.length && options?.school?.length > 1 && {
              school: options?.school?.map(({ id, name }) => ({ id, name })),
            }),
          },
        )
        : null,
    [options],
  );

  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const deactivateActionClickHandler = (checkResponse: TUsersDeactivateCheck) => {
    const hasRoutingRuleUsers = Object.keys(checkResponse.routing_rule_users).length > 0;
    const hasOpenedSafUsers = checkResponse.opened_saf_users.length > 0;
    const hasValidUsers = checkResponse.valid_users.length > 0;

    if (hasRoutingRuleUsers) {
      const usersIds = Object.keys(checkResponse.routing_rule_users);
      const users = data?.results.filter((user) => usersIds.includes(user.id));

      if (users && users?.length > 0) {
        const schools = Object.values(checkResponse.routing_rule_users).join(', ');
        const errorText = `User is a part of the SAF routing directory for the following schools: ${schools}. If you are sure you want to deactivate this user, user will need to be removed from SAF routing rules first.`;
        showErrorToast('User error', errorText);
        return;
      }
    }

    if (hasOpenedSafUsers) {
      const users = data?.results.filter((user) => checkResponse.opened_saf_users.includes(user.id));
      if (users && users?.length > 0) {
        // We take the first user from the list because we are only checking one user at a time
        setClickableUser(users[0] || null);
        openDeactivateWithSafModal();
        return;
      }
    }

    if (hasValidUsers) {
      const users = data?.results.filter((user) => checkResponse.valid_users.includes(user.id));
      if (users && users?.length > 0) {
        // We take the first user from the list because we are only checking one user at a time
        setClickableUser(users[0] || null);
        openDeactivateModal();
      }
    }
  };

  const deleteActionClickHandler = (user: TUserManagement) => {
    setClickableUser(user);
    openDeleteUserModal();
  };

  const activateActionClickHandler = (user: TUserManagement) => {
    setClickableUser(user);
    openActivateModal();
  };

  const { table } = useDataTable({
    columns: useUserManagementColumns({
      onDeactivate: deactivateActionClickHandler,
      onActivate: activateActionClickHandler,
      onDelete: deleteActionClickHandler,
    }),
    data: users,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (selectedRows) => {
      const newSelectedUsers = Object.keys(selectedRows).filter((id) => {
        const existInUsers = users.find((user) => user.id === id);
        const existInSelectedItems = selectedItems.find((item) => item.id === id);
        return existInUsers || existInSelectedItems;
      }).map((id) => {
        const name = users.find((user) => user.id === id)?.full_name || selectedItems.find((item) => item.id === id)?.name || '';

        return {
          id,
          name,
        };
      });

      onReplaceAllItems(newSelectedUsers);
    },
  });

  // NOTE: update table selection in case if selectedItems were changed
  useEffect(() => {
    const prevSelectionState = table.getState().rowSelection;
    const prevSelectionIds = Object.keys(prevSelectionState);
    const prevSelectionStateLength = prevSelectionIds.length;

    const wasChanged = prevSelectionStateLength !== selectedItems.length || prevSelectionIds.some((id) => !selectedItems.find((item) => item.id === id));

    if (!wasChanged) {
      return;
    }

    const selectedRows = selectedItems.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: true,
      };
    }, {});
    table.setRowSelection(selectedRows);
  }, [selectedItems, table]);

  const deactivateUsersClickHandler = () => {
    if (clickableUser) {
      bulkUserDeactivate(
        {
          users: [clickableUser.id],
        },
        {
          onSuccess: () => {
            closeDeactivateModal();
            closeDeactivateWithSafModal();
            setClickableUser(null);
          },
        },
      );
    }
  };

  const activateUsers = (usersIds: string[]) => {
    if (users.length) {
      bulkUserActivate(
        {
          users: usersIds,
        },
        {
          onSuccess: () => {
            closeActivateModal();
            closeBulkActivateModal();
            setClickableUser(null);
          },
        },
      );
    }
  };

  const activateUserClickHandler = () => {
    if (clickableUser) {
      activateUsers([clickableUser.id]);
    }
  };

  const activateUsersClickHandler = () => {
    const ids = selectedItems.map((item) => item.id);
    if (ids.length) {
      activateUsers(ids);
    }
  };

  const deleteUsersClickHandler = () => {
    if (clickableUser) {
      userDelete(clickableUser.id, {
        onSuccess: () => {
          closeDeleteUserModal();
          setClickableUser(null);
        },
      });
    }
  };

  const selectAllFoundHandler = () => {
    getAllUsers({
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: data?.count || 0,
      offset: 0,
      ordering: sortQuery,
      school: searchParameters.get('school') || '',
      status: searchParameters.get('status') || '',
      role: searchParameters.get('role') || '',
    }, {
      onSuccess: (allUsers) => {
        const newItems
          = allUsers.results.map((user) => {
            return {
              id: user.id,
              name: user.full_name,
            };
          }) || [];
        onReplaceAllItems(newItems);
      },
    });
  };

  const clearAllSelectedItemsHandler = () => {
    onClearAllItems();
    table.setRowSelection({});
  };

  return (
    <Guard
      requiredPermissions={[APP_PERMISSIONS.CAN_ACCESS_ADMIN_USERS_PERMISSIONS_ROLES]}
      fallback={(
        <AccessDenied
          iconSize={24}
          accessDeniedMessage="You do not have permission to view this page."
          className="size-full rounded-lg border border-grey-200 bg-white p-6 py-[200px]"
        />
      )}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <Heading variant="size-22" type="heading-600" className="text-grey-title">Users</Heading>
            <Text variant="size-14" type="body-400" className="text-grey-950">List of all invited users to PurpleSense and Purple admin platform</Text>
          </div>
          <div>
            <Button type="button" variant="primary" iconRight={<PurpleIcon name="chevron-down" className="size-[20px]" />}>Add & Invite User</Button>
          </div>
        </div>
        <div className="rounded-lg border border-grey-200 bg-white">
          <DataTable
            table={table}
            loading={isUsersFetching}
          >
            <div className="flex flex-col gap-1">
              <div className="flex w-full items-center justify-between gap-4 p-4">
                <div className="flex items-center gap-4">
                  <div className="min-w-[200px]">
                    <DistrictFilterComboBox value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} />
                  </div>
                  <AppFilters config={filterConfig} loading={isOptionsLoading} />
                  <SearchInput
                    value={search}
                    placeholder="Search"
                    className="max-w-[300px]"
                    onChange={onSearchChange}
                    onClear={onClearSearch}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <Button
                    type="button"
                    variant="secondary"
                    size="default"
                    disabled={!selectedItems.length}
                    onClick={openBulkActivateModal}
                  >
                    Activate User(s)
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    size="default"
                    iconLeft={<PurpleIcon name="mail" />}
                    disabled={!selectedItems.length}
                  >
                    Send Email
                  </Button>
                  <div className="min-w-[133px]">
                    <DropdownRoot>
                      <DropdownTrigger disabled asChild>
                        <Button disabled={!selectedItems.length} variant="secondary" iconRight={<PurpleIcon name="chevron-down" />}>
                          Priority List
                        </Button>
                      </DropdownTrigger>
                      <DropdownContent align="end" sideOffset={4} className="min-w-[232px]">
                        <DropdownItem iconName="plus" onClick={() => null}>
                          Some item
                        </DropdownItem>
                      </DropdownContent>
                    </DropdownRoot>
                  </div>
                  <Button type="button" variant="secondary" size="icon_40" iconLeft={<PurpleIcon name="printer" />} disabled={!selectedItems.length} />
                  <DataTableViewOptions table={table} />
                </div>
              </div>

              <div className="flex flex-col gap-4 px-4 pb-4">
                <SelectAllEntitiesRow totalCount={data?.count || 0} selectedItems={selectedItems} allItemsOnPage={allItemsOnPage} onSelectAll={selectAllFoundHandler} isSelectingAll={isGetAllUsersPending} entityName="User(s)" />
                <SelectedItemsRow selectedItems={selectedItems} onClearAll={clearAllSelectedItemsHandler} onClose={onChangeItem} />
              </div>
            </div>
          </DataTable>
          <CallToActionModal
            modalName={ModalType.USER_DELETE}
            modalTitle="Are you sure you want to delete User?"
            modalDescription="Before clicking Yes, make sure that the following actions have been performed:"
            showModalDescription
            modalTextContent="Check if the user is referenced in any SAF routing rules: If the user is found in a routing rule please remove the user from the SAF routing rule and assign another active user to the routing rule in place of the deleted user.
Check Lookup Fields: If the user is found in lookup fields, please remove the user from the relevant ones before deleting."
            primaryButtonText="Delete"
            secondaryButtonText="Cancel"
            onPrimaryButtonClick={deleteUsersClickHandler}
            primaryButtonVariant="destructive_primary"
            isLoading={isDeletePending}
          />
          <CallToActionModal
            modalName={ModalType.BULK_USERS_DEACTIVATE}
            modalTitle="Deactivate User"
            modalDescription="By deactivating user, user will no longer have access to the districts."
            modalTextContent="Are you sure you want to deactivate the selected user?"
            primaryButtonText="Deactivate"
            secondaryButtonText="Cancel"
            onPrimaryButtonClick={deactivateUsersClickHandler}
            primaryButtonVariant="destructive_primary"
            isLoading={isDeactivatePending}
          />
          <CallToActionModal
            modalName={ModalType.BULK_USERS_WITH_SAF_DEACTIVATE}
            modalTitle="The user has open SAFs"
            modalDescription="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
            modalTextContent="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
            primaryButtonText="Yes"
            secondaryButtonText="No"
            onPrimaryButtonClick={deactivateUsersClickHandler}
            primaryButtonVariant="destructive_primary"
            isLoading={isDeactivatePending}
          />
          <CallToActionModal
            modalName={ModalType.USER_ACTIVATE_MODAL}
            modalTitle="Activate User"
            modalDescription="Are you sure you want to activate the selected user?"
            modalTextContent="Are you sure you want to activate the selected user?"
            primaryButtonText="Activate"
            secondaryButtonText="Cancel"
            onPrimaryButtonClick={activateUserClickHandler}
            primaryButtonVariant="primary"
            isLoading={isActivatePending}
          />
          <CallToActionModal
            modalName={ModalType.BULK_USERS_ACTIVATE_MODAL}
            modalTitle="Activate Users"
            modalDescription="Are you sure you want to activate the selected users?"
            modalTextContent="Are you sure you want to activate the selected users?"
            primaryButtonText="Activate"
            secondaryButtonText="Cancel"
            onPrimaryButtonClick={activateUsersClickHandler}
            primaryButtonVariant="primary"
            isLoading={isActivatePending}
          />
        </div>
      </div>
    </Guard>
  );
};

export { UsersList };
