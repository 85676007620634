import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { GRADE_CHOICES } from '@purple/shared-types';
import { cutGradeName } from '@purple/shared-utils';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MultiSelect,
  MultiSelectItem,
  NumberInput,
  Switch,
} from '@purple/ui';
import { useSchoolUpdateChoices, useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const basicDetailsSchema = z
  .object({
    name: z.string().trim().min(1, 'School name is required.'),
    is_active: z.boolean(),
    school_office: z.string().nullish(),
    type: z.string().nullish(),
    subtype: z.string().nullish(),
    super_neighborhood: z.string().nullish(),
    school_code: z.string(),
    school_level: z.array(z.string()),
    grades: z.array(z.string()),
    is_hidden_on_safs: z.boolean(),
    staff_count: z
      .number({
        message: 'Number of staff must be an integer.',
      })
      .int()
      .min(0, {
        message: 'Staff number must be a positive number or zero.',
      })
      .nullish(),
  });

type TDetailsSectionProps = {
  data: TSchoolBasicDetails;
};

export const DetailsSection: React.FC<TDetailsSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();
  const {
    schoolLevelOptions,
    schoolOfficeOptions,
    schoolSubTypeOptions,
    superNeighborhoodOptions,
  } = useSchoolUpdateChoices();

  const defaultValues: z.infer<typeof basicDetailsSchema> = useMemo(
    () => ({
      name: data.name,
      is_active: data.is_active ?? false,
      school_office: data.school_office,
      type: data.type ?? '',
      subtype: data.subtype,
      super_neighborhood: data.super_neighborhood,
      school_code: data.school_code ?? '',
      school_level: data.school_level ?? [],
      grades: data.grades ?? [],
      is_hidden_on_safs: data.is_hidden_on_safs ?? false,
      staff_count: data.staff_count ?? null,
    }),
    [data],
  );

  const form = useForm<z.infer<typeof basicDetailsSchema>>({
    resolver: zodResolver(basicDetailsSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof basicDetailsSchema>) => {
    updateSchool(
      {
        id: data.id,
        name: formData.name,
        is_active: formData.is_active,
        school_office: formData.school_office ?? null,
        type: formData.type ?? null,
        subtype: formData.subtype ?? null,
        super_neighborhood: formData.super_neighborhood ?? null,
        school_code: formData.school_code ?? null,
        school_level: formData.school_level ?? [],
        grades: [...formData.grades].sort((a, b) => a.localeCompare(b, 'en', { numeric: true })),
        is_hidden_on_safs: formData.is_hidden_on_safs,
        staff_count: formData.staff_count ?? null,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="Basic Details"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      School Name
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input {...field} isError={!!fieldState.error} placeholder="Enter name" className="h-9" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="is_active"
                render={({ field }) => (
                  <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      School Status
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Switch checked={field.value} onCheckedChange={field.onChange} className="cursor-pointer" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_office"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      School Office
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select school office"
                            selectedLabel={schoolOfficeOptions.find((option) => option.value === field.value)?.value}
                            className="max-h-9"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search office..."
                          emptyContent="Office not found."
                        >
                          {schoolOfficeOptions.map(({ label, value }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {value}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <DescriptionItem tag="div" className="flex-nowrap">
                <DescriptionTerm tag="p" className="w-1/2 shrink-0">Feeder Pattern</DescriptionTerm>
                <DescriptionDetails tag="strong" className="w-1/2">
                  {data.feeder_pattern || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <FormField
                control={form.control}
                name="type"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Type
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input {...field} value={field.value ?? ''} isError={!!fieldState.error} placeholder="Enter name" className="h-9" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subtype"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Subtype
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select subtype"
                            selectedLabel={schoolSubTypeOptions.find((option) => option.value === field.value)?.value}
                            className="max-h-9"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search subtype..."
                          emptyContent="Subtype not found."
                        >
                          {schoolSubTypeOptions.map(({ label, value }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {value}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="super_neighborhood"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Neighborhood
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select neighborhood"
                            selectedLabel={superNeighborhoodOptions.find((option) => option.value === field.value)?.value}
                            className="max-h-9"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search neighborhood..."
                          emptyContent="Neighborhood not found."
                        >
                          {superNeighborhoodOptions.map(({ label, value }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {value}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_code"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      School Code
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Input {...field} isError={!!fieldState.error} placeholder="Enter code" className="h-9" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="school_level"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      School Level
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <MultiSelect
                          {...field}
                          isError={!!fieldState.error}
                          selectedOptions={schoolLevelOptions.filter((option) => field?.value?.includes(option.value))}
                          placeholder="Select level"
                          classNames={{ trigger: 'min-h-9 py-1' }}
                          modalPopover
                          onOptionChange={field.onChange}
                        >
                          {schoolLevelOptions.map((option) => (
                            <MultiSelectItem
                              key={option.value}
                              value={option.value}
                              option={option}
                              isSelected={field.value?.includes(option.value)}
                            />
                          ))}
                        </MultiSelect>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="grades"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Grades
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <MultiSelect
                          {...field}
                          isError={!!fieldState.error}
                          selectedOptions={Object.values(GRADE_CHOICES).map((grade) => ({ label: grade, value: grade })).filter((option) => field?.value?.includes(option.value))}
                          placeholder="Select grades"
                          classNames={{ trigger: 'min-h-9 py-1' }}
                          modalPopover
                          onOptionChange={field.onChange}
                        >
                          {Object.values(GRADE_CHOICES).map((value) => (
                            <MultiSelectItem
                              key={value}
                              value={value}
                              option={{ label: cutGradeName(value), value }}
                              isSelected={field.value?.includes(value)}
                            />
                          ))}
                        </MultiSelect>
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="is_hidden_on_safs"
                render={({ field }) => (
                  <FormItem className="flex min-h-9 w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      SAF Status
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <Switch checked={!field.value} onCheckedChange={(value) => field.onChange(!value)} className="cursor-pointer" />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="staff_count"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Number of Staff
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          type="number"
                          isError={!!fieldState.error}
                          placeholder="Enter number"
                          className="h-9"
                          min={0}
                          changeOnWheel
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">School Name</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.name || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">School Status</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.is_active ? 'Active' : 'Inactive'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">School Office</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.school_office || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Feeder Pattern</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.feeder_pattern || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Type</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.type || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Subtype</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.subtype || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Neighborhood</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.super_neighborhood || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">School Code</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.school_code || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">School Level</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.school_level.length > 0 ? data.school_level.join(', ') : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Grades</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.grades.length > 0 ? data.grades.map((grade) => cutGradeName(grade)).join(', ') : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">SAF Status</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.is_hidden_on_safs ? 'Hidden' : 'Visible'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">Number of Staff</DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.staff_count || '-'}
                </DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
