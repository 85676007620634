import { createSelector } from '@reduxjs/toolkit';
import { AppReduxReducer } from '../../white-list';
import type { RootState } from '~/store';

const getState = (state: RootState) => state[AppReduxReducer.DISTRICT_SETUP];

export const maxDistrictStepSelector = createSelector(getState, (state) => state.maxStep);
export const districtIdSelector = createSelector(getState, (state) => state.districtId);
export const districtStatusSelector = createSelector(getState, (state) => state.status);
export const districtCurrentStepSelector = createSelector(getState, (state) => state.current_setup_step);
export const responsibleForSetupSelector = createSelector(getState, (state) => state.responsible_for_setup);
export const districtBasicSelector = createSelector(getState, (state) => state.basic);
export const districtBrandingSelector = createSelector(getState, (state) => state.branding);
export const districtSafProcessSelector = createSelector(getState, (state) => state.safProcess);
export const districtLeaderUsersStepSelector = createSelector(getState, (state) => state.leaderUsersStep);
