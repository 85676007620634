import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocalStorage } from 'usehooks-ts';
import { LocalStorageKey } from '@purple/shared-utils';
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@purple/ui';
import { useResetPassword } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { forgotPasswordFormSchema } from '../../forgot-password-form-schema';
import type { FC } from 'react';
import type * as z from 'zod';

type TFormValues = z.infer<typeof forgotPasswordFormSchema>;

type TResetPasswordFormProperties = {
  onResetSuccess?: () => void;
};

export const ForgotPasswordForm: FC<TResetPasswordFormProperties> = ({ onResetSuccess }) => {
  const [_, setUserResetPasswordEmail] = useLocalStorage<string>(LocalStorageKey.Admin.ResetPassword.Email, '');

  const { mutate, isPending } = useResetPassword();

  const form = useForm<TFormValues>({
    mode: 'onChange',
    resolver: zodResolver(forgotPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const forgotPasswordHandler = (data: TFormValues) => {
    mutate(data, {
      onSuccess: () => {
        setUserResetPasswordEmail(data.email);
        onResetSuccess?.();
        showSuccessToast('System message', 'Reset link sent successfully. Check your provided email address');
      },
    });
  };

  return (
    <Form providerProps={form} className="flex flex-col gap-6" onSubmit={form.handleSubmit(forgotPasswordHandler)}>
      <FormField
        name="email"
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormLabel required>Email</FormLabel>
            <FormControl>
              <Input {...field} placeholder="Enter your email" type="email" isError={!!form.formState.errors.email} />
            </FormControl>
            <FormDescription className="font-primary text-sm">
              Enter your email to receive a password reset link.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <Button type="submit" isLoading={isPending}>
        Send Reset Link
      </Button>
    </Form>
  );
};
