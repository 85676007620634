import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { DistrictView } from '@purple/shared-types';
import { AppTabs, Button, Heading, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useDraftedDistricts, usePublishedDistricts } from '~/services';
import { DraftedDistricts } from './DraftedDistricts';
import { PublishedDistricts } from './PublishedDistricts';
import type { TDistrictView } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

export const DistrictsPage: React.FC = () => {
  const { data: published } = usePublishedDistricts();
  const { data: draft } = useDraftedDistricts();

  const districtsTabs: TAppTab<TDistrictView>[] = useMemo(
    () => [
      {
        label: `Published (${published?.count || 0})`,
        value: DistrictView.PUBLISHED,
        content: <PublishedDistricts />,
        permissions: [],
      },
      {
        label: `Draft (${draft?.count || 0})`,
        value: DistrictView.DRAFT,
        content: <DraftedDistricts />,
        permissions: [],
      },
    ],
    [published?.count, draft?.count],
  );

  const { isReady, activeTab, tabChangeHandler } = useTabs<TDistrictView>({
    tabs: districtsTabs,
    defaultTab: DistrictView.PUBLISHED,
  });

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex flex-col gap-1">
          <Heading tag="h1" variant="size-28" type="heading-600" className="text-grey-title">
            All Districts
          </Heading>
          <Text variant="size-14" type="body-400" className="text-grey-950">
            List of all districts invited to Purple Sense Community
          </Text>
        </div>
        <Button asChild>
          <Link to={AdminRoutes.App.Districts.DistrictSetUp.path} target="_blank">
            Add New District
          </Link>
        </Button>
      </div>
      <AppTabs tabs={districtsTabs} listClassName="pl-2" value={activeTab} isReady={isReady} onValueChange={tabChangeHandler} />
    </div>
  );
};
