export const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
} as const;

export const REVERSED_US_STATES = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY',
} as const;

export const US_STATES_REGIONS_MAP = {
  [REVERSED_US_STATES.Texas]: {
    AUSTIN_AREA: 'austin_area',
    BEAUMONT_AREA: 'beaumont_area',
    BRYAN_COLLEGE_STATION_AREA: 'bryan_college_station_area',
    CORPUS_CHRISTI_AREA: 'corpus_christi_area',
    DALLAS_FORT_WORTH_AREA: 'dallas_fort_worth_area',
    EL_PASO_AREA: 'el_paso_area',
    GALVESTON_AREA: 'galveston_area',
    HOUSTON_AREA: 'houston_area',
    LAREDO_AREA: 'laredo_area',
    LUBBOCK_AREA: 'lubbock_area',
    MIDLAND_AREA: 'midland_area',
    ODESSA_AREA: 'odessa_area',
    SAN_ANTONIO_AREA: 'san_antonio_area',
    SOUTH_TEXAS_RIO_GRANDE_VALLEY_AREA: 'south_texas_rio_grande_valley_area',
    WACO_AREA: 'waco_area',
  },
  [REVERSED_US_STATES.Illinois]: {
    CHICAGO_AREA: 'chicago_area',
    SPRINGFIELD_AREA: 'springfield_area',
  },
  [REVERSED_US_STATES.Florida]: {
    TAMPA_BAY_AREA: 'tampa_bay_area',
    JACKSONVILLE_AREA: 'jacksonville_area',
    MIAMI_DADE_AREA: 'miami_dade_area',
    TALLAHASSEE_AREA: 'tallahassee_area',
    ORLANDO_AREA: 'orlando_area',
    FORT_LAUDERDALE_AREA: 'fort_lauderdale_area',
  },
  [REVERSED_US_STATES.Louisiana]: {
    SOUTHERN_LOUISIANA_AREA: 'southern_louisiana_area',
    NEW_ORLEANS_AREA: 'new_orleans_area',
    BATON_ROUGE_AREA: 'baton_rouge_area',
    LAFAYETTE_AREA: 'lafayette_area',
    LAKE_CHARLES_AREA: 'lake_charles_area',
    SHREVEPORT_AREA: 'shreveport_area',
    MONROE_AREA: 'monroe_area',
    ALEXANDRIA_AREA: 'alexandria_area',
  },
  [REVERSED_US_STATES.Ohio]: {
    GREATER_CINCINNATI_AREA: 'greater_cincinnati_area',
    GREATER_CLEVELAND_AREA: 'greater_cleveland_area',
    GREATER_COLUMBUS_AREA: 'greater_columbus_area',
    GREATER_DAYTON_AREA: 'greater_dayton_area',
    GREATER_TOLEDO_AREA: 'greater_toledo_area',
    SOUTHEAST_OHIO: 'southeast_ohio',
  },
};

export const US_REGION_READABLE_NAMES = {
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].AUSTIN_AREA]: 'Austin area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].BEAUMONT_AREA]: 'Beaumont area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].BRYAN_COLLEGE_STATION_AREA]: 'Bryan / College Station area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].CORPUS_CHRISTI_AREA]: 'Corpus Christi area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].DALLAS_FORT_WORTH_AREA]: 'Dallas-Fort Worth area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].EL_PASO_AREA]: 'El Paso area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].GALVESTON_AREA]: 'Galveston area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].HOUSTON_AREA]: 'Houston area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].LAREDO_AREA]: 'Laredo area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].LUBBOCK_AREA]: 'Lubbock area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].MIDLAND_AREA]: 'Midland area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].ODESSA_AREA]: 'Odessa area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].SAN_ANTONIO_AREA]: 'San Antonio area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].SOUTH_TEXAS_RIO_GRANDE_VALLEY_AREA]: 'South Texas/Rio Grande Valley area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Texas].WACO_AREA]: 'Waco area',

  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Illinois].CHICAGO_AREA]: 'Chicago area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Illinois].SPRINGFIELD_AREA]: 'Springfield area',

  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].TAMPA_BAY_AREA]: 'Tampa Bay area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].JACKSONVILLE_AREA]: 'Jacksonville area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].MIAMI_DADE_AREA]: 'Miami-dade area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].TALLAHASSEE_AREA]: 'Tallahassee area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].ORLANDO_AREA]: 'Orlando area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Florida].FORT_LAUDERDALE_AREA]: 'Fort Lauderdale area',

  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].SOUTHERN_LOUISIANA_AREA]: 'Southern Louisiana area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].NEW_ORLEANS_AREA]: 'New Orleans area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].BATON_ROUGE_AREA]: 'Baton Rouge area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].LAFAYETTE_AREA]: 'Lafayette area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].LAKE_CHARLES_AREA]: 'Lake Charles area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].SHREVEPORT_AREA]: 'Shreveport area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].MONROE_AREA]: 'Monroe area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Louisiana].ALEXANDRIA_AREA]: 'Alexandria area',

  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].GREATER_CINCINNATI_AREA]: 'Greater Cincinnati area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].GREATER_CLEVELAND_AREA]: 'Greater Cleveland area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].GREATER_COLUMBUS_AREA]: 'Greater Columbus area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].GREATER_DAYTON_AREA]: 'Greater Dayton area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].GREATER_TOLEDO_AREA]: 'Greater Toledo area',
  [US_STATES_REGIONS_MAP[REVERSED_US_STATES.Ohio].SOUTHEAST_OHIO]: 'Southeast Ohio',
} as const;
