import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTabs } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { AccessDenied, AppTabs, Text } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useGoBack } from '~/hooks';
import { useUserDetails } from '~/services';
import { BasicDetailTab, DetailHeader, DetailHeaderSkeleton, LoginHistoryTab, SchoolsTab, SubmittedSafsTab } from './components';
import { USER_DETAILS_TABS_VALUE } from './constants';
import type { TAppTab } from '@purple/ui';
import type { TUserDetailsInfo } from '~/services';

const UserDetails = () => {
  const { userId } = useParams();

  const { data: headerData, isLoading: isHeaderDataLoading } = useUserDetails({ userId, enabled: !!userId });
  const { onGoBack } = useGoBack({ fallbackPath: AdminRoutes.App.Users.Root.path });

  const tabs: TAppTab[] = useMemo(
    () => [
      {
        label: 'Basic Details',
        value: USER_DETAILS_TABS_VALUE.BASIC_DETAILS,
        content: (<BasicDetailTab />),
        permissions: [],
      },
      {
        label: 'Permissions',
        value: USER_DETAILS_TABS_VALUE.PERMISSIONS,
        content: (<div>Permissions will be done in next sprints</div>),
        permissions: [],
      },
      {
        label: 'Schools',
        value: USER_DETAILS_TABS_VALUE.SCHOOLS,
        content: (<SchoolsTab />),
        permissions: [],
      },
      {
        label: 'Submitted SAFs',
        value: USER_DETAILS_TABS_VALUE.SUBMITTED_SAFS,
        content: (<SubmittedSafsTab />),
        permissions: [],
      },
      {
        label: 'Login History',
        value: USER_DETAILS_TABS_VALUE.LOGIN_HISTORY,
        content: (<LoginHistoryTab />),
        permissions: [],
      },
    ],
    [],
  );

  const { activeTab, tabChangeHandler } = useTabs({
    tabs,
  });

  return (
    <div className="flex flex-col gap-4">
      <button type="button" className="flex flex-row items-center gap-2" onClick={onGoBack}>
        <PurpleIcon name="chevron-left" className="size-4 text-brand-blue-700" />
        <Text variant="size-14" type="body-600" className="text-brand-blue-700">Back to Users</Text>
      </button>
      {isHeaderDataLoading ? <DetailHeaderSkeleton /> : <DetailHeader data={headerData as TUserDetailsInfo} />}
      {activeTab === null
        ? (
            <AccessDenied />
          )
        : (
            <AppTabs tabs={tabs} listClassName="pl-2" value={activeTab} onValueChange={tabChangeHandler} />
          )}
    </div>
  );
};

export { UserDetails };
