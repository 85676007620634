import { useMutation } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { resyncMappingEvents } from './post-mapping-events';
import type { AxiosError, AxiosResponse } from 'axios';

export const useResyncMappingEvents = () => {
  return useMutation<AxiosResponse<void>, AxiosError, { districtId: string; mappingId: string }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.RESYNC_DISTRICT_MAPPING_EVENTS],
    mutationFn: resyncMappingEvents,
    onSuccess: () => {
      showSuccessToast('System Message', 'Event resync has been initiated');
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        showErrorToast('System message', 'Unable to resync data. Your previous request is still in progress. Please wait for it to complete.');
      }
      if (error.response && error.response.status < 500 && error.response.status !== 400) {
        showErrorToast('System message', 'Unable to resync events for this sis mapping');
      }
    },
  });
};
