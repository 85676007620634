import { useCallback, useMemo } from 'react';
import { modalStatesSelector, toggleModalState } from '~/store/features/modals';
import { useAppDispatch, useAppSelector } from '../useRedux';
import type { TModalName } from '~/constants/modals';

export type TUseModalReturn = {
  /**
   * Visibility state for desired modal.
   */
  isOpen: boolean;
  /**
   * Handler function that will close desired modal.
   */
  closeModal: () => void;
  /**
   * Handler function that will open desired modal.
   */
  openModal: () => void;
  /**
   * Handler function that will toggle desired modal.
   */
  toggleModal: (open: boolean) => void;
};

/**
 * Hook that will return modal handlers for desired modal.
 * @param modalName the predefined modal name to get the handlers for.
 */
export const useModal = <TName extends string = TModalName>(modalName: TName): TUseModalReturn => {
  const dispatch = useAppDispatch();
  const modalStates = useAppSelector(modalStatesSelector);

  // Open state for desired modal
  const isOpen: boolean = useMemo(() => {
    return modalStates[modalName] ?? false;
  }, [modalName, modalStates]);

  // Close modal handler
  const openModal = useCallback(() => {
    dispatch(toggleModalState({ name: modalName, state: true }));
  }, [modalName, dispatch]);

  // Close modal handler
  const closeModal = useCallback(() => {
    dispatch(toggleModalState({ name: modalName, state: false }));
  }, [modalName, dispatch]);

  // Toggle modal handler
  const toggleModal = useCallback(
    (open: boolean) => {
      dispatch(toggleModalState({ name: modalName, state: open }));
    },
    [modalName, dispatch],
  );

  return {
    isOpen,
    closeModal,
    openModal,
    toggleModal,
  };
};
