import { HierarchyNodeType } from './types';
import type { THierarchyNode, THierarchyNodeId, THierarchyNodeType } from './types';

/**
 * Retrieves the depth level of a target item within a tree structure.
 *
 * @param data - The tree data represented as an array of `THierarchyNode` objects.
 * @param id - The unique identifier of the target item whose depth level is to be determined.
 * @returns The depth level of the target item if found, otherwise -1.
 */
export const getTargetItemDepthLevel = (data: THierarchyNode[], id: THierarchyNodeId): number => {
  const walkTreeItems = (items: THierarchyNode[], targetId: THierarchyNodeId, level: number): number => {
    for (const item of items) {
      if (item.id === targetId) {
        return level;
      }
      if (item.children) {
        const depth = walkTreeItems(item.children, targetId, level + 1);
        if (depth > -1) {
          return depth;
        }
      }
    }
    return -1;
  };

  return walkTreeItems(data, id, 0);
};

export const getLeftPadding = (level: number, isTopLevel: boolean): React.CSSProperties => {
  return {
    '--hierarchy-left-padding': `${level * 2 + (isTopLevel ? 0.5 : 0)}rem`,
    '--hierarchy-line-width': '12px',
    '--hierarchy-line-gap': '8px',
  } as React.CSSProperties;
};

export const HierarchyNodeOrderMap = {
  [HierarchyNodeType.DISTRICT]: 0,
  [HierarchyNodeType.SYSTEM]: 1,
  [HierarchyNodeType.STATE]: 2,
  [HierarchyNodeType.REGION]: 3,
  [HierarchyNodeType.CITY]: 4,
  [HierarchyNodeType.DIVISION]: 5,
  [HierarchyNodeType.FEEDER_PATTERN]: 6,
  [HierarchyNodeType.CAMPUS]: 7,
  [HierarchyNodeType.SCHOOL]: 8,
} as const;

export const ReadableHierarchyNodeType = {
  [HierarchyNodeType.STATE]: 'State',
  [HierarchyNodeType.REGION]: 'Region',
  [HierarchyNodeType.FEEDER_PATTERN]: 'Feeder Pattern',
  [HierarchyNodeType.CAMPUS]: 'Campus',
  [HierarchyNodeType.CITY]: 'City',
  [HierarchyNodeType.SCHOOL]: 'School',
  [HierarchyNodeType.DISTRICT]: 'District',
  [HierarchyNodeType.SYSTEM]: 'System',
  [HierarchyNodeType.DIVISION]: 'Division',
} as const;

export const getLeafSiblings = (tree: THierarchyNode[], item: THierarchyNode): [THierarchyNode | null, THierarchyNode | null] => {
  const parent = tree.find((parentItem) => parentItem.children?.some((child) => child.id === item.id));
  if (!parent?.children) return [null, null];
  const sortedChildren = [...parent.children].sort((a, b) => HierarchyNodeOrderMap[a.type] - HierarchyNodeOrderMap[b.type]);
  const index = sortedChildren.findIndex((child) => child.id === item.id);
  return [
    index > 0 ? sortedChildren[index - 1] ?? null : null, // Previous sibling
    index < sortedChildren.length - 1 ? sortedChildren[index + 1] ?? null : null, // Next sibling
  ];
};

export const walkTreeItemNames = (items: THierarchyNode[]): string[] =>
  items.flatMap((item) => Array.isArray(item.children)
    ? [item.name, ...walkTreeItemNames(item.children)]
    : [item.name],
  );

export const walkTreeItemTypes = (items: THierarchyNode[]): THierarchyNodeType[] =>
  items.flatMap((item) => Array.isArray(item.children)
    ? [item.type, ...walkTreeItemTypes(item.children)]
    : [item.type],
  );

export const getAllowedBranchTypes = (tree: THierarchyNode[], item: THierarchyNode): THierarchyNodeType[] => {
  const [prevLeafItem, nextLeafItem] = getLeafSiblings(tree, item);

  return [...Object.values(HierarchyNodeType)]
    .filter((type) => {
      if (prevLeafItem && nextLeafItem) {
        return HierarchyNodeOrderMap[type] > HierarchyNodeOrderMap[prevLeafItem.type]
          && HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[nextLeafItem.type] && HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[item.type];
      }
      if (prevLeafItem && !nextLeafItem) {
        return HierarchyNodeOrderMap[type] > HierarchyNodeOrderMap[prevLeafItem.type] && HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[item.type];
      }
      if (!prevLeafItem && nextLeafItem) {
        return HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[nextLeafItem.type] && HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[item.type];
      }
      return HierarchyNodeOrderMap[type] < HierarchyNodeOrderMap[item.type];
    })
    .sort((a, b) => HierarchyNodeOrderMap[a] - HierarchyNodeOrderMap[b]);
};

export const getNotUsedBranchTypes = (tree: THierarchyNode[], item: THierarchyNode): THierarchyNodeType[] => {
  const allowedTypes = getAllowedBranchTypes(tree, item);
  return allowedTypes.filter((type) => !walkTreeItemTypes(tree).includes(type));
};
