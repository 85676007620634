import { PurpleIcon } from '@purple/icons';
import {
  Button,
  DropdownCheckboxItem,
  DropdownContent,
  DropdownLabel,
  DropdownRoot,
  DropdownSeparator,
  DropdownTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { RowData, Table } from '@tanstack/react-table';

type TDataTableViewOptionsProperties<TData> = {
  table: Table<TData>;
};

export const DataTableViewOptions = <TData extends RowData = RowData>({
  table,
}: TDataTableViewOptionsProperties<TData>): JSX.Element => {
  return (
    <DropdownRoot>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownTrigger asChild>
            <Button
              aria-label="Toggle columns"
              variant="secondary"
              size="icon_40"
              iconLeft={<PurpleIcon name="adjustments" className="size-5 rotate-90" />}
              className="ml-auto hidden size-10 md:flex"
              onFocusCapture={(event) => event.stopPropagation()}
            />
          </DropdownTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Show or hide columns in the table</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DropdownContent align="end" className="w-fit min-w-28 max-w-40 border-grey-200">
        <DropdownLabel className="py-1 font-primary text-xs font-semibold text-grey-title">
          Toggle columns
        </DropdownLabel>
        <DropdownSeparator className="bg-grey-200" />
        {table
          .getAllColumns()
          .filter((column) => column.accessorFn !== undefined && column.getCanHide())
          .map((column) => (
            <DropdownCheckboxItem
              key={column.id}
              className="relative flex cursor-default select-none items-center py-1.5 text-sm capitalize text-grey-950 outline-none transition-colors focus:bg-brand-blue-200 focus:text-brand-blue-700 data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
              checked={column.getIsVisible()}
              onCheckedChange={(value) => column.toggleVisibility(!!value)}
            >
              <span className="truncate">{column.columnDef.meta?.label ?? column.id}</span>
            </DropdownCheckboxItem>
          ))}
      </DropdownContent>
    </DropdownRoot>
  );
};
