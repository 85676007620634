import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { getSchoolUpdateChoices } from './get-school-update-choices';
import { SCHOOLS_QUERY_KEYS } from './schools.const';

export const useSchoolUpdateChoices = () => {
  const { isError, data, ...rest } = useQuery({
    queryKey: [SCHOOLS_QUERY_KEYS.GET_SCHOOL_UPDATE_CHOICES],
    queryFn: getSchoolUpdateChoices,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('System Message', 'Failed to fetch school field options');
    }
  }, [isError]);

  const schoolOfficeOptions = useMemo(
    () => (data?.school_office ? Object.entries(data.school_office).map(([value, label]) => ({ value, label })) : []),
    [data?.school_office],
  );

  const schoolSubTypeOptions = useMemo(
    () => (data?.subtype ? Object.entries(data.subtype).map(([value, label]) => ({ value, label })) : []),
    [data?.subtype],
  );

  const superNeighborhoodOptions = useMemo(
    () => (data?.super_neighborhood ? Object.entries(data.super_neighborhood).map(([value, label]) => ({ value, label })) : []),
    [data?.super_neighborhood],
  );

  const schoolLevelOptions = useMemo(
    () => (data?.school_level ? Object.entries(data.school_level).map(([value, label]) => ({ value, label })) : []),
    [data?.school_level],
  );

  const stateAccountabilityRatingOptions = useMemo(
    () => (data?.state_accountability_rating
      ? [{ label: 'N/A', value: 'N/A' }, ...Object.entries(data.state_accountability_rating).map(([value, label]) => ({ value, label }))]
      : []),
    [data?.state_accountability_rating],
  );

  return {
    data,
    schoolOfficeOptions,
    schoolSubTypeOptions,
    superNeighborhoodOptions,
    schoolLevelOptions,
    stateAccountabilityRatingOptions,
    isError,
    ...rest,
  };
};
