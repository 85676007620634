import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { BANNERS_QUERY_KEYS } from './banners.const';
import { publishBanner } from './publish-banner';

export const usePublishBanner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [BANNERS_QUERY_KEYS.PUBLISH_BANNER],
    mutationFn: publishBanner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_DRAFTED_BANNERS] });
      queryClient.invalidateQueries({ queryKey: [BANNERS_QUERY_KEYS.GET_PUBLISHED_BANNERS] });
      showSuccessToast('System message', 'Banner was published successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to publish banner. Please try again later');
    },
  });
};
