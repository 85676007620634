import { BannerType, ReadableBannerType } from '@purple/shared-types';

export const BannerStaticFilters = {
  type: Object.values(BannerType).map((type) => ({
    id: type,
    name: ReadableBannerType[type],
  })),
};

export const BANNER_TYPE_OPTIONS = Object.values(BannerType).map((type) => ({
  value: type,
  label: ReadableBannerType[type],
}));

export const BannerTypeToBadgeVariant = {
  [BannerType.ERROR]: 'danger',
  [BannerType.ALL_CORRECTED]: 'success',
  [BannerType.UPDATES]: 'update',
  [BannerType.INFO]: 'info',
  [BannerType.MISCELLANEOUS]: 'neutral',
} as const;
