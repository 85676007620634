import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType, MetricDataType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const behaviorTextSchema = zodAlwaysRefine(
  z.object({
    contacted_parent: floatNumber(
      z.number().gte(0, 'Contacted parent must be greater than or equal to 0'),
      2,
    ).nullish(),
    parent_or_guardian_conference: floatNumber(
      z.number().gte(0, 'Parent or guardian conference must be greater than or equal to 0'),
      2,
    ).nullish(),
    behavior_contact: floatNumber(
      z.number().gte(0, 'Behavior contact must be greater than or equal to 0'),
      2,
    ).nullish(),
    oss: floatNumber(
      z.number().gte(0, 'OSS must be greater than or equal to 0'),
      2,
    ).nullish(),
    iss: floatNumber(
      z.number().gte(0, 'ISS must be greater than or equal to 0'),
      2,
    ).nullish(),
    partial_day_oss: floatNumber(
      z.number().gte(0, 'Partial day OSS must be greater than or equal to 0'),
      2,
    ).nullish(),
    partial_day_iss: floatNumber(
      z.number().gte(0, 'Partial day ISS must be greater than or equal to 0'),
      2,
    ).nullish(),
    after_school_detention: floatNumber(
      z.number().gte(0, 'After school detention must be greater than or equal to 0'),
      2,
    ).nullish(),
    reverse_suspension: floatNumber(
      z.number().gte(0, 'Reverse suspension must be greater than or equal to 0'),
      2,
    ).nullish(),
    teacher_detention_hall: floatNumber(
      z.number().gte(0, 'Teacher detention hall must be greater than or equal to 0'),
      2,
    ).nullish(),
    peer_mediation: floatNumber(
      z.number().gte(0, 'Peer mediation must be greater than or equal to 0'),
      2,
    ).nullish(),
    saturday_detention: floatNumber(
      z.number().gte(0, 'Saturday detention must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TBehaviorTextSchema = z.infer<typeof behaviorTextSchema>;

export const behaviorTextSchemaSuperRefine = (data: TFlagCalculationBase<typeof behaviorTextSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.BEHAVIOR || data.data_type !== MetricDataType.TEXT) return;

  if (!isNumber(data.contacted_parent)) {
    ctx.addIssue({
      path: ['contacted_parent'],
      code: z.ZodIssueCode.custom,
      message: 'Contacted parent is required',
    });
  }
  if (!isNumber(data.parent_or_guardian_conference)) {
    ctx.addIssue({
      path: ['parent_or_guardian_conference'],
      code: z.ZodIssueCode.custom,
      message: 'Parent or guardian conference is required',
    });
  }
  if (!isNumber(data.behavior_contact)) {
    ctx.addIssue({
      path: ['behavior_contact'],
      code: z.ZodIssueCode.custom,
      message: 'Behavior contact is required',
    });
  }
  if (!isNumber(data.oss)) {
    ctx.addIssue({
      path: ['oss'],
      code: z.ZodIssueCode.custom,
      message: 'OSS is required',
    });
  }
  if (!isNumber(data.iss)) {
    ctx.addIssue({
      path: ['iss'],
      code: z.ZodIssueCode.custom,
      message: 'ISS is required',
    });
  }
  if (!isNumber(data.partial_day_oss)) {
    ctx.addIssue({
      path: ['partial_day_oss'],
      code: z.ZodIssueCode.custom,
      message: 'Partial day OSS is required',
    });
  }
  if (!isNumber(data.partial_day_iss)) {
    ctx.addIssue({
      path: ['partial_day_iss'],
      code: z.ZodIssueCode.custom,
      message: 'Partial day ISS is required',
    });
  }
  if (!isNumber(data.after_school_detention)) {
    ctx.addIssue({
      path: ['after_school_detention'],
      code: z.ZodIssueCode.custom,
      message: 'After school detention is required',
    });
  }
  if (!isNumber(data.reverse_suspension)) {
    ctx.addIssue({
      path: ['reverse_suspension'],
      code: z.ZodIssueCode.custom,
      message: 'Reverse suspension is required',
    });
  }
  if (!isNumber(data.teacher_detention_hall)) {
    ctx.addIssue({
      path: ['teacher_detention_hall'],
      code: z.ZodIssueCode.custom,
      message: 'Teacher detention hall is required',
    });
  }
  if (!isNumber(data.peer_mediation)) {
    ctx.addIssue({
      path: ['peer_mediation'],
      code: z.ZodIssueCode.custom,
      message: 'Peer mediation is required',
    });
  }
  if (!isNumber(data.saturday_detention)) {
    ctx.addIssue({
      path: ['saturday_detention'],
      code: z.ZodIssueCode.custom,
      message: 'Saturday detention is required',
    });
  }

  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
