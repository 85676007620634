import { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PurpleIcon } from '@purple/icons';
import { formateDateShortMonth } from '@purple/shared-utils';
import { Button, cn, Heading, NumberBadge, Switch, Text, Tooltip, TooltipContent, TooltipTrigger } from '@purple/ui';
import { useUpdateCustomPage } from '~/services';
import type React from 'react';
import type { TDistrictCustomPage } from '@purple/shared-types';

type TCustomPageItemProperties = {
  data: TDistrictCustomPage;
  order: number;
  dragging?: boolean;
  disabled?: boolean;
  onEdit?: (page: TDistrictCustomPage) => void;
  onDelete?: (page: TDistrictCustomPage) => void;
};

export const CustomPageItem: React.FC<TCustomPageItemProperties> = (props) => {
  const { data, order, dragging = false, disabled = false, onDelete, onEdit } = props;

  const [isActive, setIsActive] = useState(data.is_active);

  const { mutate: updatePage } = useUpdateCustomPage();

  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging: isPlaceholder,
  } = useSortable({ id: data.id, disabled });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const activatePageChangeHandler = (checked: boolean) => {
    setIsActive(checked);
    updatePage({ id: data.id, is_active: checked });
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className="relative flex min-h-[240px] w-full flex-col items-start justify-between gap-4 overflow-hidden rounded-lg border border-grey-100 bg-white p-4 sm:min-h-[116px] sm:flex-row sm:items-center"
    >
      {isPlaceholder
        ? (
            <div className="absolute inset-0 size-full" />
          )
        : (
            <>
              <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
                <span className="font-primary text-sm font-medium text-grey-600">{order}</span>
                {!disabled && (
                  <Button
                    type="button"
                    variant="tertiary"
                    size="icon_32"
                    className={cn(
                      'size-8 cursor-grab focus:bg-grey-100 focus-visible:bg-grey-100 active:bg-grey-100 active:text-brand-blue-700',
                      {
                        'cursor-grabbing': dragging,
                      },
                    )}
                    iconLeft={<PurpleIcon name="dragdrop" />}
                    {...attributes}
                    {...listeners}
                  />
                )}
                <div className="flex flex-col gap-2">
                  <Heading tag="h4" type="heading-600" variant="size-18" className="text-base leading-[18px] text-grey-950">
                    {data.name}
                  </Heading>
                  <Text type="body-400" variant="size-14" className="text-grey-600">
                    Added at
                    {' '}
                    <span className="font-medium text-grey-950">
                      {data.created_at ? formateDateShortMonth(data.created_at) : '-'}
                    </span>
                    {' '}
                    by
                    {' '}
                    <span className="font-medium text-grey-950">{data.created_by?.full_name ?? '-'}</span>
                  </Text>
                  <NumberBadge variant={data.is_active ? 'success-light' : 'neutral'} className="w-fit rounded-full">
                    {data.is_active ? 'Active' : 'Inactive'}
                  </NumberBadge>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="leading-[0]">
                      <Switch checked={isActive} onCheckedChange={activatePageChangeHandler} />
                    </span>
                  </TooltipTrigger>
                  <TooltipContent>{`${data.is_active ? 'Disable' : 'Activate'} ${data.name} page`}</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="tertiary"
                      size="icon_32"
                      iconLeft={<PurpleIcon name="pencil" />}
                      onClick={() => onEdit?.(data)}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    Edit
                    {data.name}
                  </TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      type="button"
                      variant="destructive_secondary"
                      size="icon_32"
                      className="border-none"
                      iconLeft={<PurpleIcon name="trash" />}
                      onClick={() => onDelete?.(data)}
                    />
                  </TooltipTrigger>
                  <TooltipContent>
                    Delete
                    {data.name}
                  </TooltipContent>
                </Tooltip>
              </div>
            </>
          )}
    </li>
  );
};
