import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@purple/ui';
import PlaceholderImage from '~/assets/images/development-placeholder.svg';
import { AdminRoutes } from '~/constants';

export const Placeholder: React.FC = () => {
  return (
    <Card className="rounded-lg border-none">
      <CardContent>
        <div className="flex size-full items-center justify-center">
          <div className="relative flex grow flex-col items-center gap-4">
            <img
              src={PlaceholderImage}
              alt="Web developers working on this page"
              className="aspect-square size-full max-h-[724px]"
            />
            <Button asChild>
              <Link to={AdminRoutes.App.Home.Root.path}>Home</Link>
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
