import { formatDateToLocalTime, snakeToCamelWithSpaces } from '@purple/shared-utils';
import { NumberBadge, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader } from '~/components';
import { StatusToBadgeVariantMap } from './constants';
import { EmailSubjectRow } from './EmailSubjectRow';
import type { ColumnDef } from '@tanstack/react-table';
import type { TSafCorrespondenceListItem } from '@purple/shared-types';

export const emailsColumns: ColumnDef<TSafCorrespondenceListItem>[] = [
  {
    accessorKey: 'subject',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
    cell: ({ row: { original } }) => <EmailSubjectRow email={original} />,
    size: 280,
    meta: { label: 'Subject' },
  },
  {
    accessorKey: 'from_email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="From" />,
    cell: ({ row: { original } }) => {
      return (
        original.from_email && original.from_email.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.from_email}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.from_email}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 220,
    meta: { label: 'From' },
  },
  {
    accessorKey: 'to_email',
    header: ({ column }) => <DataTableColumnHeader column={column} title="To" />,
    cell: ({ row: { original } }) => {
      return (
        original.to_email && original.to_email.length > 0
          ? (
              <Tooltip>
                <TooltipTrigger asChild className="w-fit">
                  <span className="line-clamp-1 break-all">{original.to_email}</span>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent align="start" withArrow={false}>
                    {original.to_email}
                  </TooltipContent>
                </TooltipPortal>
              </Tooltip>
            )
          : <span>—</span>
      );
    },
    size: 220,
    meta: { label: 'To' },
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Date" />,
    cell: ({ row: { original } }) => <span className="text-nowrap">{formatDateToLocalTime(original.created_at)}</span>,
    size: 150,
    meta: { label: 'Date' },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
    cell: ({ row: { original } }) => (
      <NumberBadge variant={StatusToBadgeVariantMap[original.status]} className="rounded-full">
        {snakeToCamelWithSpaces(original.status)}
      </NumberBadge>
    ),
    size: 100,
    meta: { label: 'Status', className: 'text-center' },
  },
];
