import { useNavigate, useParams } from 'react-router-dom';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { Separator } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useDistrict } from '~/services';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { DistrictSafLinkSection } from './DistrictSafLinkSection';
import { DistrictSafProcessSkeleton } from './DistrictSafProcessSkeleton';
import { SafAcknowledgementSection } from './SafAcknowledgementSection';
import { SafAnonymousSection } from './SafAnonymousSection';
import { SafCityRegionSection } from './SafCityRegionSection';
import { SafDisclaimerSection } from './SafDisclaimerSection';
import { SafEmailAddressSection } from './SafEmailAddressSection';
import { SafIntroductionSection } from './SafIntroductionSection';
import { SafNeedTypesSection } from './SafNeedTypesSection';
import { SafSpecialEdSection } from './SafSpecialEdSection';

export const SafProcessTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: district, isFetching } = useDistrict({ id: id as string });

  if (isFetching || !district) return <DistrictSafProcessSkeleton />;

  if (district.status === DISTRICT_STATUS.DRAFT) {
    navigate(AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: district.id } }), {
      replace: true,
    });
  }

  return (
    <DistrictTabContainer title="SAF Process" className="gap-4">
      <DistrictSafLinkSection district={district} />
      <SafAnonymousSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafIntroductionSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafAcknowledgementSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafDisclaimerSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafSpecialEdSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafNeedTypesSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafCityRegionSection district={district} />
      <Separator orientation="horizontal" className="bg-grey-200" />
      <SafEmailAddressSection district={district} />
    </DistrictTabContainer>
  );
};
