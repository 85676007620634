import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormMessage, Input, Text } from '@purple/ui';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

const sectionSchema = z.object({
  email: z
    .string({
      invalid_type_error: 'Email must be a string.',
    })
    .trim()
    .email({
      message: 'Invalid email format.',
    })
    .min(1, {
      message: 'Email is required.',
    })
    .max(128, {
      message: 'Email must be less than 128 characters.',
    }),
});

type TSafEmailAddressSectionProperties = {
  district: TDistrictDetails;
};

export const SafEmailAddressSection: React.FC<TSafEmailAddressSectionProperties> = (props) => {
  const {
    district: { id, email },
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateDistrictSafProcess, isPending } = useUpdateDistrictSafProcess();

  const defaultValues: z.infer<typeof sectionSchema> = useMemo(
    () => ({
      email: email ?? '',
    }),
    [email],
  );

  const form = useForm<z.infer<typeof sectionSchema>>({
    resolver: zodResolver(sectionSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof sectionSchema>) => {
    updateDistrictSafProcess(
      {
        id,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader
          title="Email Address"
          editing={isEditing}
          loading={isPending}
          onCancel={cancelClickHandler}
          onEdit={editClickHandler}
          onSave={form.handleSubmit(saveDetailsClickHandler)}
        />
        <Text variant="size-14" type="body-400" className="max-w-[700px] text-grey-700">
          District specific email address will be used across all parts of the system, not just for the SAF process.
        </Text>
      </div>
      <div className="flex w-full flex-col gap-2">
        {isEditing
          ? (
              <Form providerProps={form} className="flex w-full flex-col gap-2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Enter district email"
                          type="email"
                          containerClassName="w-full max-w-[530px]"
                          isError={!!form.formState.errors.email}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </Form>
            )
          : (
              <Text type="body-500" variant="size-14" className="text-grey-950">
                {email || 'No email address provided'}
              </Text>
            )}
      </div>
    </section>
  );
};
