import { type FC, useMemo } from 'react';
import { PurpleIcon } from '@purple/icons';
import { Button, Text } from '@purple/ui';

type TDataItemProperties = {
  isCompleted: boolean;
  dataTitle: string;
  isRequired?: boolean;
  onClick: () => void;
};

export const DataItem: FC<TDataItemProperties> = ({ dataTitle, isCompleted, onClick, isRequired = false }) => {
  const buttonLabel = useMemo(() => (isCompleted ? 'Update Mapping' : 'Create Mapping'), [isCompleted]);
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center gap-4">
        {isCompleted
          ? (
              <div className="flex size-6 items-center justify-center rounded-full bg-brand-blue-600">
                <PurpleIcon name="check" className="text-white" />
              </div>
            )
          : (
              <div className="size-6 rounded-full bg-gray-100" />
            )}
        <Text variant="size-14" type="body-500" className="text-grey-950">
          {dataTitle}
          {isRequired && <span className="text-error-main">*</span>}
        </Text>
      </div>
      <Button variant="tertiary" size="small" onClick={onClick}>
        {buttonLabel}
      </Button>
    </div>
  );
};
