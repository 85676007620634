import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TAdminSafsRuleListRequestParameters, TAdminSafsRuleListResponse } from './saf.types';

export const getSafsRuleList = async (parameters: TAdminSafsRuleListRequestParameters) => {
  const response = await axiosInstance.get<TAdminSafsRuleListResponse>(SAF_ENDPOINTS.LIST_ROUTING_RULES, {
    params: parameters,
  });
  return response.data;
};
