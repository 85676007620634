import axiosInstance from '~/services/axios-config';
import { DISTRICTS_ENDPOINTS } from '../endpoints';
import type { TDistrictCustomPageListResponse } from './districts.types';

export const getDistrictNavigationPages = async (districtId: string | number) => {
  const response = await axiosInstance.get<TDistrictCustomPageListResponse>(
    DISTRICTS_ENDPOINTS.CUSTOM_PAGES_BY_DISTRICT(districtId),
  );
  return response.data;
};
