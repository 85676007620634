import { defineStepper } from '@stepperize/react';
import type { ValueOf } from '@purple/shared-types';

export const FlagCalculationStep = {
  METRIC_TYPE: 'metric-type',
  DETAILS: 'details',
} as const;
export type TFlagCalculationStep = ValueOf<typeof FlagCalculationStep>;

export const FlagCalculationSteps = [
  { id: FlagCalculationStep.METRIC_TYPE, title: 'Metric Type' },
  { id: FlagCalculationStep.DETAILS, title: 'Details' },
] as const;

export const FlagStepper = defineStepper(...FlagCalculationSteps);
