import { useMemo } from 'react';
import { DragOverlay } from '@dnd-kit/core';
import { CustomPageItem } from './CustomPageItem';
import type React from 'react';
import type { TDistrictCustomPage } from '@purple/shared-types';

type TCustomPageOverlayProperties = {
  /**
   * Currently dragged active row identifier.
   * @default null
   */
  dragActiveId?: string | number | null;
  /**
   * Array of row objects generated in `react-table` to operate with data and cells.
   */
  data: TDistrictCustomPage[];
};

export const CustomPageOverlay: React.FC<TCustomPageOverlayProperties> = (props) => {
  const { dragActiveId, data } = props;

  const dragSelectedItem: TDistrictCustomPage | null = useMemo(() => {
    if (!dragActiveId) {
      return null;
    }
    const row = data.find(({ id }) => id === dragActiveId);
    return row || null;
  }, [dragActiveId, data]);

  return (
    <DragOverlay>{dragActiveId && dragSelectedItem && <CustomPageItem data={dragSelectedItem} order={data.findIndex((item) => item.id === dragActiveId) + 1} dragging />}</DragOverlay>
  );
};
