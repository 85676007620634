import { Skeleton } from '@purple/ui';

export const ManageRolesFormContentSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg border border-grey-200 bg-white p-6">
      <Skeleton className="h-7 w-[200px]" />
      <div className="grid grid-cols-2 gap-x-4 gap-y-3">
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-10 w-full" />
        <Skeleton className="col-span-2 h-20 w-full" />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <Skeleton className="h-7 w-[200px]" />
          <Skeleton className="h-5 w-full" />
        </div>
        <Skeleton className="h-6 w-[200px]" />
        <div className="flex flex-col gap-3">
          {Array.from({ length: 10 }).map(() => (
            <div key={Math.random().toString(36)} className="grid grid-cols-[350px,120px,1fr,120px] items-center gap-x-4 border-b-grey-200">
              <Skeleton className="h-7 w-full" />
              <Skeleton className="h-7 w-full" />
              <Skeleton className="h-7 w-full" />
              <Skeleton className="h-7 w-full" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
