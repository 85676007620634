import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  DescriptionDetails,
  DescriptionItem,
  DescriptionList,
  DescriptionTerm,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  NumberInput,
} from '@purple/ui';
import { useSchoolUpdateChoices, useUpdateSchoolDetails } from '~/services';
import { SchoolSectionHeader } from '../../SchoolSectionHeader';
import type { TSchoolBasicDetails } from '~/services';

const schoolPerformanceSchema = z
  .object({
    average_teacher_experience: z
      .number({
        message: 'Average teacher experience must be a number.',
      })
      .int()
      .min(0, {
        message: 'Average teacher experience must be a positive number.',
      })
      .max(99, {
        message: 'Average teacher experience must be less than 100.',
      })
      .nullish(),
    teacher_to_student_ratio: z
      .number({
        message: 'Teacher to student ratio must be a number.',
      })
      .int()
      .min(0, {
        message: 'Teacher to student ratio must be a positive number.',
      })
      .max(99, {
        message: 'Teacher to student ratio must be less than 100.',
      })
      .nullish(),
    campus_attendance_rate: z
      .number({
        message: 'Campus attendance rate must be a number.',
      })
      .int()
      .min(0, {
        message: 'Campus attendance rate must be a positive number.',
      })
      .max(999, {
        message: 'Campus attendance rate must be less than 1000.',
      })
      .nullish(),
    state_accountability_rating: z
      .string()
      .trim()
      .nullish()
      .transform((value) => (value === 'N/A' ? null : value)),
    percent_meeting_standards: z
      .number({
        message: 'Percent meeting standards must be a number.',
      })
      .int()
      .min(0, {
        message: 'Percent meeting standards must be a positive number.',
      })
      .max(100, {
        message: 'Percent meeting standards must be less than or equal to 100.',
      })
      .nullish(),
    percent_not_meeting_standards: z
      .number({
        message: 'Percent not meeting standards must be a number.',
      })
      .int()
      .min(0, {
        message: 'Percent not meeting standards must be a positive number.',
      })
      .max(100, {
        message: 'Percent not meeting standards must be less than or equal to 100.',
      })
      .nullish(),
  });

type TSchoolPerformanceSectionProps = {
  data: TSchoolBasicDetails;
};

export const SchoolPerformanceSection: React.FC<TSchoolPerformanceSectionProps> = (props) => {
  const { data } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { mutate: updateSchool, isPending } = useUpdateSchoolDetails();
  const { stateAccountabilityRatingOptions } = useSchoolUpdateChoices();

  const defaultValues: z.infer<typeof schoolPerformanceSchema> = useMemo(
    () => ({
      average_teacher_experience: data.average_teacher_experience ?? null,
      teacher_to_student_ratio: data.teacher_to_student_ratio ?? null,
      campus_attendance_rate: data.campus_attendance_rate ?? null,
      state_accountability_rating: data.state_accountability_rating ?? null,
      percent_meeting_standards: data.percent_meeting_standards ?? null,
      percent_not_meeting_standards: data.percent_not_meeting_standards ?? null,
    }),
    [data],
  );

  const form = useForm<z.infer<typeof schoolPerformanceSchema>>({
    resolver: zodResolver(schoolPerformanceSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const editClickHandler = () => {
    setIsEditing(true);
  };

  const cancelClickHandler = () => {
    setIsEditing(false);
    form.reset(defaultValues);
  };

  const saveDetailsClickHandler = (formData: z.infer<typeof schoolPerformanceSchema>) => {
    updateSchool(
      {
        id: data.id,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <SchoolSectionHeader
        title="School Performance"
        editing={isEditing}
        loading={isPending}
        onCancel={cancelClickHandler}
        onEdit={editClickHandler}
        onSave={form.handleSubmit(saveDetailsClickHandler)}
      />
      {isEditing
        ? (
            <Form providerProps={form} className="flex w-full flex-col gap-2">
              <FormField
                control={form.control}
                name="average_teacher_experience"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Average Teacher Experience
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          type="number"
                          isError={!!fieldState.error}
                          placeholder="Enter experience"
                          className="h-9"
                          min={0}
                          changeOnWheel
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="teacher_to_student_ratio"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Teacher to Student Ratio
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          type="number"
                          isError={!!fieldState.error}
                          placeholder="Enter ratio"
                          className="h-9"
                          min={0}
                          changeOnWheel
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="campus_attendance_rate"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Campus Attendance Rate
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          type="number"
                          isError={!!fieldState.error}
                          placeholder="Enter rate"
                          className="h-9"
                          min={0}
                          changeOnWheel
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="state_accountability_rating"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      State Accountability Rating
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <ComboBox modal>
                        <FormControl>
                          <ComboBoxTrigger
                            isError={!!fieldState.error}
                            placeholder="Select rating"
                            selectedLabel={stateAccountabilityRatingOptions.find((option) => option.value === field.value)?.value}
                            className="max-h-9"
                          />
                        </FormControl>
                        <ComboBoxContent
                          className="min-w-80"
                          align="end"
                          searchPlaceholder="Search rating..."
                          emptyContent="Rating not found."
                        >
                          {stateAccountabilityRatingOptions.map(({ label, value }) => (
                            <ComboBoxItem
                              key={value}
                              keywords={[label]}
                              value={value}
                              selected={value === field.value}
                              onSelect={field.onChange}
                            >
                              {value}
                            </ComboBoxItem>
                          ))}
                        </ComboBoxContent>
                      </ComboBox>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="percent_meeting_standards"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Percent Meeting Standards
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          isError={!!fieldState.error}
                          placeholder="Enter percentage"
                          className="h-9"
                          min={0}
                          changeOnWheel
                          formatter={(value) => value ? `${value}%` : ''}
                          parser={(value) => typeof value === 'string' ? value.replace('%', '') : ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="percent_not_meeting_standards"
                render={({ field, fieldState }) => (
                  <FormItem className="flex w-full flex-row items-center justify-between gap-2 space-y-0">
                    <FormLabel className="w-1/2 font-primary text-base font-normal text-grey-600">
                      Percent Not Meeting Standards
                    </FormLabel>
                    <div className="flex w-1/2 flex-col gap-1">
                      <FormControl>
                        <NumberInput
                          {...field}
                          isError={!!fieldState.error}
                          placeholder="Enter percentage"
                          className="h-9"
                          min={0}
                          changeOnWheel
                          formatter={(value) => value ? `${value}%` : ''}
                          parser={(value) => typeof value === 'string' ? value.replace('%', '') : ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </Form>
          )
        : (
            <DescriptionList className="gap-2">
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Average Teacher Experience
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.average_teacher_experience || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Teacher to Student Ratio
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.teacher_to_student_ratio || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Campus Attendance Rate
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.campus_attendance_rate || '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  State Accountability Rating
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {data.state_accountability_rating === null
                    ? 'N/A'
                    : (data.state_accountability_rating || '-')}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Percent Meeting Standards
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {typeof data.percent_meeting_standards === 'number'
                    ? `${data.percent_meeting_standards}%`
                    : '-'}
                </DescriptionDetails>
              </DescriptionItem>
              <DescriptionItem className="flex-nowrap">
                <DescriptionTerm className="w-1/2 shrink-0">
                  Percent Not Meeting Standards
                </DescriptionTerm>
                <DescriptionDetails className="w-1/2">
                  {typeof data.percent_not_meeting_standards === 'number'
                    ? `${data.percent_not_meeting_standards}%`
                    : '-'}
                </DescriptionDetails>
              </DescriptionItem>
            </DescriptionList>
          )}
    </div>
  );
};
