import axiosInstance from '~/services/axios-config';
import { ROLES_ENDPOINTS } from '../endpoints';
import type { TListRolesRequestParameters, TListRolesResponse } from './roles.types';

export const getListRoles = async (requestParameters?: TListRolesRequestParameters) => {
  const response = await axiosInstance.get<TListRolesResponse>(ROLES_ENDPOINTS.ROOT, {
    params: requestParameters,
  });
  return response.data;
};
