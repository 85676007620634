import { useNavigate, useParams } from 'react-router-dom';
import { DISTRICT_STATUS } from '@purple/shared-types';
import { Separator } from '@purple/ui';
import { AdminRoutes } from '~/constants';
import { useDistrict } from '~/services';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { DistrictBasicDetailsSection } from './BasicDetailsSection';
import { DistrictBusinessHoursSection } from './BusinessHoursSection';
import { DistrictBasicDetailsSkeleton } from './DistrictBasicDetailsSkeleton';
import { DistrictHolidaysSection } from './HolidaysSection';

export const DistrictBasicDetailsTab: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: district, isFetching } = useDistrict({ id: id as string });

  if (isFetching || !district) return <DistrictBasicDetailsSkeleton />;

  if (district.status === DISTRICT_STATUS.DRAFT) {
    navigate(AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: district.id } }), {
      replace: true,
    });
  }

  return (
    <DistrictTabContainer title="Basic Details" className="gap-6 lg:flex-row">
      <div className="flex w-full flex-col gap-4">
        <DistrictBasicDetailsSection district={district} />
        <DistrictBusinessHoursSection district={district} />
      </div>
      <Separator orientation="vertical" className="h-px w-full bg-grey-200 lg:h-auto lg:w-px" />
      <DistrictHolidaysSection district={district} />
    </DistrictTabContainer>
  );
};
