import { type FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { AdminRoutes, ModalType } from '~/constants';
import { useModal } from '~/hooks';
import type { TListRoleItem } from '~/services';

type TRolesActions = {
  role: TListRoleItem;
  onSelectRole: (roleId: string) => void;
};

export const RolesActions: FC<TRolesActions> = (props) => {
  const { role, onSelectRole } = props;

  const { openModal } = useModal(ModalType.DELETE_ROLE);

  const deleteRoleHandler = useCallback(() => {
    onSelectRole(role.id);
    openModal();
  }, [onSelectRole, role.id, openModal]);

  return (
    <DropdownRoot>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="inline-block">
            <DropdownTrigger asChild>
              <Button
                variant="tertiary"
                size="icon_32"
                className="max-w-8 active:bg-grey-100"
                iconLeft={<PurpleIcon name="dots-vertical" className="shrink-0 text-grey-600" />}
              />
            </DropdownTrigger>
          </span>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Roles actions</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <DropdownContent className="max-w-[150px] gap-1" align="end">
        <Button
          variant="link"
          className="w-full justify-start text-grey-600 hover:bg-grey-100 hover:text-grey-950 active:bg-grey-200"
          iconLeft={<PurpleIcon name="pencil" />}
          asChild
        >
          <Link to={AdminRoutes.App.Users.Roles.ManageRoles.makePath({
            queries: { roleId: role.id },
          })}
          >
            Edit
          </Link>
        </Button>
        <Button
          variant="link"
          onClick={deleteRoleHandler}
          className="w-full justify-start text-error-main hover:bg-error-bg hover:text-error-dark active:bg-error-dark active:text-error-bg"
          iconLeft={<PurpleIcon name="trash" />}
        >
          Delete
        </Button>

      </DropdownContent>
    </DropdownRoot>
  );
};
