import { PurpleIcon } from '@purple/icons';
import { DEFAULT_BRAND_COLOR } from '@purple/shared-utils';
import { Button, Heading, Text, Tooltip, TooltipContent, TooltipTrigger } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { BrandColorDialog } from './BrandColorDialog';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

type TBrandingColorProperties = {
  district: TDistrictDetails;
};

export const BrandingColor: React.FC<TBrandingColorProperties> = (props) => {
  const { district } = props;

  const { openModal } = useModal(ModalType.BRAND_COLOR_PICKER);

  return (
    <section className="flex w-full flex-row flex-wrap items-center justify-between gap-4 rounded-lg border border-grey-100 p-4">
      <div className="flex flex-col gap-2">
        <Heading tag="h3" type="heading-500" variant="size-18" className="text-title text-base">
          Branding Color
        </Heading>
        <div className="flex min-h-10 items-center gap-2">
          <span
            className="inline-block size-5 rounded-full"
            style={{ backgroundColor: district.branding_color ?? DEFAULT_BRAND_COLOR }}
          />
          <Text variant="size-14" type="body-500" className="text-grey-950">
            {district.branding_color ?? DEFAULT_BRAND_COLOR}
          </Text>
        </div>
      </div>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            type="button"
            variant="tertiary"
            size="icon_32"
            iconLeft={<PurpleIcon name="pencil" />}
            onClick={openModal}
          />
        </TooltipTrigger>
        <TooltipContent>Change branding color for this district.</TooltipContent>
      </Tooltip>
      <BrandColorDialog district={district} />
    </section>
  );
};
