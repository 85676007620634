import { useEffect, useId, useState } from 'react';
import { Checkbox, Label, Text } from '@purple/ui';
import { useUpdateDistrictSafProcess } from '~/services';
import { DistrictSectionHeader } from '../../DistrictSectionHeader';
import type React from 'react';
import type { TDistrictDetails } from '~/services';

type TSafCityRegionSectionProperties = {
  district: TDistrictDetails;
};

export const SafCityRegionSection: React.FC<TSafCityRegionSectionProperties> = (props) => {
  const {
    district: { id, show_city_or_region_hierarchy_field },
  } = props;

  const fieldId = useId();

  const [isChecked, setIsChecked] = useState<boolean>(show_city_or_region_hierarchy_field);

  const { mutate: updateDistrictSafProcess } = useUpdateDistrictSafProcess({ disableInvalidate: true });

  useEffect(() => {
    setIsChecked(show_city_or_region_hierarchy_field);
  }, [show_city_or_region_hierarchy_field]);

  const fieldChangeHandler = (value: boolean) => {
    setIsChecked(value);
    updateDistrictSafProcess({
      id,
      show_city_or_region_hierarchy_field: value,
    });
  };

  return (
    <section className="flex w-full flex-col gap-2">
      <div className="flex w-full flex-col gap-1">
        <DistrictSectionHeader title="City or Region" disableEditing />
        <Text variant="size-16" type="body-400" className="max-w-[700px] text-grey-600">
          It is the hierarchy field for the SAF.
        </Text>
      </div>
      <div className="flex items-center gap-2">
        <Checkbox id={fieldId} checked={isChecked} onCheckedChange={fieldChangeHandler} />
        <Label htmlFor={fieldId} className="text-base font-normal text-grey-800">
          Show city/region hierarchy field
        </Label>
      </div>
    </section>
  );
};
