import { US_STATES_REGIONS_MAP } from '@purple/shared-types';

export const filterExistedRegionByState = (formStates: string[], formRegions: string[] | undefined) => {
  const filteredRegions = formRegions?.filter((region) => {
    const isRegionExistInSelectedStates = formStates.some((state) => {
      return Object.values(US_STATES_REGIONS_MAP[state as keyof typeof US_STATES_REGIONS_MAP]).includes(region);
    });
    return isRegionExistInSelectedStates;
  });

  return filteredRegions || [];
};
