import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { ACTIONS_QUERY_KEYS } from './actions.const';
import { getActionTypes } from './get-action-types';
import type { AxiosError } from 'axios';
import type { TCustomUseQueryOptions } from '~/types/query';
import type { TActionTypeListRequestParameters, TActionTypeListResponse } from './actions.types';

type TUseActionTypesParameters = {
  parameters?: TActionTypeListRequestParameters;
  queryOptions?: TCustomUseQueryOptions<TActionTypeListResponse, AxiosError>;
};

export const useActionTypes = (params?: TUseActionTypesParameters) => {
  const { parameters, queryOptions } = params || {};
  const { isError, error, ...rest } = useQuery({
    ...queryOptions,
    queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPES_LIST, parameters],
    queryFn: () => getActionTypes(parameters),
  });

  useEffect(() => {
    if (isError && error.response && error.response.status < 500) {
      showErrorToast('System message', 'Unable to retrieve action types');
    }
  }, [isError, error?.response]);

  return { isError, error, ...rest };
};
