import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { addDays, isBefore, isSameDay } from 'date-fns';
import { PurpleIcon } from '@purple/icons';
import { Button, Checkbox, cn, DatePicker, FormControl, FormField, FormItem, FormLabel, FormMessage, Text, TipBox, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import type { z } from 'zod';
import type { pullDatesSchema } from './schema';

type TPullDateFieldItemProps = {
  fieldIndex: number;
  index: number;
  onRemove?: (index: number) => void;
};

export const PullDateFieldItem: React.FC<TPullDateFieldItemProps> = (props) => {
  const { fieldIndex, index, onRemove } = props;

  const ctx = useFormContext<z.infer<typeof pullDatesSchema>>();
  const fieldDate = ctx.watch(`pullDates.${fieldIndex}.pull_date`);
  const previousDate = ctx.watch(`pullDates.${fieldIndex - 1}.pull_date`) ?? false;

  const isDisabled = useMemo(
    () => isBefore(fieldDate, new Date()) && !isSameDay(fieldDate, new Date()),
    [fieldDate],
  );
  const disableDate = useMemo(
    () => previousDate instanceof Date
      ? { before: addDays(previousDate, 1) < new Date() ? new Date() : addDays(previousDate, 1) }
      : { before: new Date() },
    [previousDate],
  );

  return (
    <div className="flex items-center justify-start gap-4">
      <FormField
        control={ctx.control}
        name={`pullDates.${fieldIndex}.pull_date`}
        render={({ field, fieldState }) => (
          <FormItem className="flex flex-col items-start">
            <div className="flex w-full min-w-[320px] max-w-[428px] flex-1 items-center justify-between gap-4">
              <FormLabel className="pl-8">Pull Date</FormLabel>
              {index === 0 && (
                <p className="flex items-center gap-1">
                  <Text tag="span" type="body-500" variant="size-14">Report Card</Text>
                  <TipBox text="Grade flags based on available report card" />
                </p>
              )}
            </div>
            <div className="flex flex-row items-center gap-3">
              <span
                className={cn('text-base font-medium text-grey-950 inline-flex justify-center items-center min-w-5', {
                  'text-grey-400': isDisabled,
                })}
              >
                {index + 1}
              </span>
              <FormControl>
                <DatePicker
                  mode="single"
                  placeholder="Select date"
                  formatterString="PPP"
                  isError={!!fieldState.error}
                  triggerDisabled={field.disabled || isDisabled}
                  selected={field.value}
                  defaultMonth={field.value}
                  captionLayout="dropdown"
                  onDayClick={field.onChange}
                  triggerClassName="w-full min-w-[320px] max-w-[420px] flex-1"
                  contentClassName="min-w-[320px]"
                  disabled={disableDate}
                />
              </FormControl>
              <FormField
                control={ctx.control}
                name={`pullDates.${fieldIndex}.is_report_card_date`}
                render={({ field }) => (
                  <FormItem className="inline-flex min-h-10 items-center justify-center leading-[0]">
                    <FormControl>
                      <Checkbox
                        {...field}
                        value={field.value.toString()}
                        checked={field.value}
                        disabled={isDisabled}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isDisabled
                ? (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span className="inline-flex min-h-10 min-w-8 items-center justify-center leading-[0]">
                          <PurpleIcon name="lock-closed" className="size-5 shrink-0 text-error-main" />
                        </span>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          This date is in the past and cannot be removed.
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )
                : (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <span className="inline-flex min-h-10 items-center justify-center leading-[0]">
                          <Button
                            type="button"
                            variant="destructive_secondary"
                            size="icon_32"
                            className="border-none"
                            iconLeft={<PurpleIcon name="X" className="shrink-0" />}
                            onClick={() => onRemove?.(fieldIndex)}
                          />
                        </span>
                      </TooltipTrigger>
                      <TooltipPortal>
                        <TooltipContent>
                          Remove Date
                        </TooltipContent>
                      </TooltipPortal>
                    </Tooltip>
                  )}
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
