import { Heading, Separator, Skeleton } from '@purple/ui';

const BasicDetailsTabSkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="px-6 py-5">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Basic Details</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <div className="grid grid-cols-2 py-8">
        <div className="flex flex-col gap-4 border-r border-grey-200 px-6 py-0">
          <Skeleton className="h-[344px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[264px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[584px] w-full" />
        </div>
        <div className="flex flex-col gap-4 px-6 py-0">
          <Skeleton className="h-[344px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[144px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[64px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[64px] w-full" />
          <Separator className="bg-grey-200" />
          <Skeleton className="h-[64px] w-full" />
        </div>
      </div>
    </div>
  );
};

export { BasicDetailsTabSkeleton };
