import { useCallback, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import { LinkEditBlock } from './LinkEditBlock';
import type { Editor } from '@tiptap/react';
import type React from 'react';

type TLinkEditPopoverProperties = {
  editor: Editor;
};

export const LinkEditPopover: React.FC<TLinkEditPopoverProperties> = ({ editor }) => {
  const [open, setOpen] = useState<boolean>(false);

  const { from, to } = editor.state.selection;
  const text = editor.state.doc.textBetween(from, to, ' ');

  const setLinkHandler = useCallback(
    (url: string, content?: string, openInNewTab?: boolean) => {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .insertContent({
          type: 'text',
          text: content || url,
          marks: [
            {
              type: 'link',
              attrs: {
                href: url,
                target: openInNewTab ? '_blank' : '',
              },
            },
          ],
        })
        .setLink({ href: url })
        .run();

      editor.commands.enter();
    },
    [editor],
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <Tooltip>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              type="button"
              variant="tertiary"
              size="icon_32"
              className="inline-flex size-6 items-center justify-center rounded-md bg-transparent p-0 text-sm font-medium text-grey-600 transition-colors hover:bg-grey-100 hover:text-grey-600 focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50"
            >
              <PurpleIcon name="link" className="size-5" />
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <TooltipPortal>
          <TooltipContent>Set link</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <PopoverContent className="w-full min-w-80" align="start">
        <LinkEditBlock onSave={setLinkHandler} defaultText={text} />
      </PopoverContent>
    </Popover>
  );
};
