import { getRandomIntegerInRange } from '@purple/shared-utils';
import { cn, Skeleton } from '@purple/ui';

type TSafDataStatsCardSkeletonProps = {
  className?: string;
  hideCompare?: boolean;
};

const SafDataStatsCardSkeleton: React.FC<TSafDataStatsCardSkeletonProps> = (props) => {
  const { className, hideCompare = false } = props;

  return (
    <div className={cn('flex min-h-[120px] w-full flex-col gap-2 rounded-lg border border-grey-200 bg-white px-6 py-4', className)}>
      <Skeleton className="h-5 w-full max-w-[90px]" style={{ maxWidth: getRandomIntegerInRange(60, 150) }} />
      <div className="mt-auto flex w-full flex-wrap items-baseline justify-start gap-1">
        <Skeleton className="h-10 w-full max-w-[60px]" />
        {!hideCompare && (
          <Skeleton className="h-5 w-full max-w-[30px]" />
        )}
      </div>
    </div>
  );
};

export const SafDataStatsSkeleton: React.FC = () => {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-0.5">
          <Skeleton className="h-5 w-full max-w-[130px]" />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
          <SafDataStatsCardSkeleton hideCompare />
          <SafDataStatsCardSkeleton />
          <SafDataStatsCardSkeleton />
          <SafDataStatsCardSkeleton />
          <SafDataStatsCardSkeleton />
          <SafDataStatsCardSkeleton />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-5 w-full max-w-[150px]" />
          <Skeleton className="h-4 w-full max-w-[350px]" />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
          <SafDataStatsCardSkeleton />
          <SafDataStatsCardSkeleton />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="h-5 w-full max-w-[130px]" />
          <Skeleton className="h-4 w-full max-w-[300px]" />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <SafDataStatsCardSkeleton hideCompare />
          <SafDataStatsCardSkeleton hideCompare />
          <SafDataStatsCardSkeleton hideCompare className="md:col-span-2 lg:col-span-1" />
        </div>
      </div>
    </div>
  );
};
