import { z } from 'zod';
import { floatNumber, isNumber, zodAlwaysRefine } from '@purple/shared-utils';
import { ColorTrendMetricType } from '~/services';
import { FlagCalculationStep } from '../stepper';
import type { TFlagCalculationBase } from '../schema';

export const assessmentsSchema = zodAlwaysRefine(
  z.object({
    option1: floatNumber(
      z.number().gte(0, 'Option 1 must be greater than or equal to 0'),
      2,
    ).nullish(),
    option2: floatNumber(
      z.number().gte(0, 'Option 2 must be greater than or equal to 0'),
      2,
    ).nullish(),
    option3: floatNumber(
      z.number().gte(0, 'Option 3 must be greater than or equal to 0'),
      2,
    ).nullish(),
    option4: floatNumber(
      z.number().gte(0, 'Option 4 must be greater than or equal to 0'),
      2,
    ).nullish(),
  }),
);

export type TAssessmentsSchema = z.infer<typeof assessmentsSchema>;

export const assessmentsSchemaSuperRefine = (data: TFlagCalculationBase<typeof assessmentsSchema>, ctx: z.RefinementCtx) => {
  if (data.currentStep !== FlagCalculationStep.DETAILS || data.type !== ColorTrendMetricType.ASSESSMENTS) return;

  if (!isNumber(data.option1)) {
    ctx.addIssue({
      path: ['option1'],
      code: z.ZodIssueCode.custom,
      message: 'Option 1 is required',
    });
  }
  if (!isNumber(data.option2)) {
    ctx.addIssue({
      path: ['option2'],
      code: z.ZodIssueCode.custom,
      message: 'Option 2 is required',
    });
  }
  if (!isNumber(data.option3)) {
    ctx.addIssue({
      path: ['option3'],
      code: z.ZodIssueCode.custom,
      message: 'Option 3 is required',
    });
  }
  if (!isNumber(data.option4)) {
    ctx.addIssue({
      path: ['option4'],
      code: z.ZodIssueCode.custom,
      message: 'Option 4 is required',
    });
  }
  if (data.number_of_maximum_flags === undefined || data.number_of_maximum_flags === null) {
    ctx.addIssue({
      path: ['number_of_maximum_flags'],
      code: z.ZodIssueCode.custom,
      message: 'Number of Max Flags is required',
    });
  }
};
