import axiosInstance from '../axios-config';
import { SAF_ENDPOINTS } from '../endpoints';
import type { TSafHistoryResponse } from '@purple/shared-types';
import type { TAdminSafHistoryRequestParameters } from './saf.types';

export const getSafHistoryTimeline = async ({ safId, requestParameters }: { safId: string; requestParameters: TAdminSafHistoryRequestParameters }) => {
  const response = await axiosInstance.get<TSafHistoryResponse>(SAF_ENDPOINTS.HISTORY(safId), {
    params: requestParameters,
  });

  return response.data;
};
