import { type FC, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  AppFileDropZone,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Heading,
  Text,
  UploadedFilesPreview,
} from '@purple/ui';
import { DISTRICTS_QUERY_KEYS, useDeleteMappingFile } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import type { UseFormReturn } from 'react-hook-form';
import type * as z from 'zod';
import type { mappingFormSchema } from './schema';

const ACCEPTED_FILE_TYPES = {
  'text/csv': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};
// 10MB
const MAX_FILE_SIZE = 1024 * 1024 * 10;

type TUploadMappingFileFormProperties = {
  mappingId: string;
  currentStep: number;
  maxSteps: number;
  initialUploadedFile: string | null;
  districtId: string;
  form: UseFormReturn<z.infer<typeof mappingFormSchema>>;
};

export const UploadMappingFileForm: FC<TUploadMappingFileFormProperties> = ({
  mappingId,
  form,
  currentStep,
  maxSteps,
  districtId,
  initialUploadedFile,
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useDeleteMappingFile();

  const uploadedFile = form.watch('uploaded_file');

  const [file] = uploadedFile || [];

  const initialFileName = useMemo(() => initialUploadedFile?.split('/').pop(), [initialUploadedFile]);
  const initialFileType = useMemo(() => initialUploadedFile?.split('.').pop(), [initialUploadedFile]);

  const deleteMappingFileHandler = () => {
    if (initialFileName && districtId) {
      return mutate(
        {
          districtId: districtId.toString(),
          mappingId,
        },
        {
          onSuccess: () => {
            showSuccessToast('System Message', 'Uploaded file has been removed');
            queryClient.invalidateQueries({
              queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_DETAILS, districtId.toString(), mappingId],
            });
            queryClient.invalidateQueries({
              queryKey: [DISTRICTS_QUERY_KEYS.GET_DISTRICT_SIS_MAPPING_LIST, districtId.toString()],
            });
            queryClient.invalidateQueries({
              queryKey: [DISTRICTS_QUERY_KEYS.DRAFTED_DISTRICT_DETAIL],
            });
          },
        },
      );
    }

    form.setValue('uploaded_file', undefined);
  };
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <Heading variant="size-18" type="heading-600">
            Upload File
          </Heading>
          <Text variant="size-14" type="body-500" className="text-grey-600">
            Upload the file that you would like to map the columns to.
          </Text>
        </div>
        <Text variant="size-16" type="body-400">
          Step:
          {' '}
          <span className="font-semibold">{currentStep + 1}</span>
          {' '}
          of
          {' '}
          <span className="font-semibold">{maxSteps}</span>
        </Text>
      </div>
      {(initialUploadedFile || (uploadedFile && uploadedFile.length > 0)) && (
        <UploadedFilesPreview
          fileName={initialFileName || file?.name}
          fileType={initialFileType || file?.type}
          onRemove={deleteMappingFileHandler}
          isLoading={isPending}
        />
      )}
      {(!uploadedFile || uploadedFile?.length === 0) && !initialUploadedFile && (
        <FormField
          control={form.control}
          name="uploaded_file"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AppFileDropZone
                  value={field.value}
                  onValueChange={(files) => files && form.setValue('uploaded_file', files, { shouldValidate: true })}
                  accept={ACCEPTED_FILE_TYPES}
                  maxSize={MAX_FILE_SIZE}
                  description="The uploaded file should be in XLSX or CSV format and less than 10BM."
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </div>
  );
};
