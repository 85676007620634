import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { updateFlagCalculation } from './update-flag-calculation';
import { VISUALIZER_QUERY_KEYS } from './visualizer.const';
import type { AxiosError } from '@purple/shared-services';
import type { TVisualizerFlag, TVisualizerFlagUpdatePayload } from './visualizer.types';

export const useUpdateFlagCalculation = (options?: { shouldRevalidate?: boolean }) => {
  const { shouldRevalidate = true } = options || {};
  const queryClient = useQueryClient();

  return useMutation<TVisualizerFlag, AxiosError, TVisualizerFlagUpdatePayload>({
    mutationKey: [VISUALIZER_QUERY_KEYS.UPDATE_FLAG_CALCULATION],
    mutationFn: updateFlagCalculation,
    onSuccess: (data) => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_LIST_OF_FLAG_CALCULATIONS, data.district.toString()],
        });
        queryClient.invalidateQueries({
          queryKey: [VISUALIZER_QUERY_KEYS.GET_STUDENT_VISUALIZER_SETUP_STATE, data.district.toString()],
        });
      }
      showSuccessToast('System message', 'Flag calculation was updated successfully');
    },
    onError: () => {
      showErrorToast('System message', 'Failed to update flag calculation. Please try again later');
    },
  });
};
