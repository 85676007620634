import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { getDistrictsListBasicInfo } from './get-districts-list-basic-info';
import type { TDistrictsListBasicInfoRequestParameters } from './districts.types';

export const useDistrictsListBasicInfo = (
  parameters: TDistrictsListBasicInfoRequestParameters & { enabled?: boolean },
) => {
  const { enabled, ...payload } = parameters;

  const { isError, ...queryData } = useQuery({
    queryKey: [DISTRICTS_QUERY_KEYS.DISTRICTS_LIST_BASIC_INFO, payload],
    queryFn: () => getDistrictsListBasicInfo(payload),
    enabled,
  });

  useEffect(() => {
    if (isError) {
      showErrorToast('Failed to fetch districts', 'Please try again later');
    }
  }, [isError]);

  return { ...queryData, isError };
};
