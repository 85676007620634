import { PurpleIcon } from '@purple/icons';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, ScrollArea, Separator, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';

type TUserRolesModalProperties = {
  userName: string;
  roles: string[];
};

const UserRolesModal = ({ userName, roles }: TUserRolesModalProperties) => {
  const { isOpen, closeModal } = useModal(ModalType.USER_ROLES);

  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-[564px] flex-col">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle>
              {userName}
              {' '}
              Roles
            </DialogTitle>
            <DialogDescription className="sr-only">User's Roles list</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <ScrollArea type="auto" className="flex max-h-[640px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
          <div className="flex flex-col gap-6 p-8">
            <Text variant="size-12" type="body-600" className="uppercase text-grey-600">Role Title</Text>
            <div className="flex flex-col gap-[20px]">
              {roles.map((role) => (
                <Text key={`${role}-${Math.random()}`} variant="size-14" type="body-500" className="text-grey-950">{role}</Text>
              ))}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export { UserRolesModal };
