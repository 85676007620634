import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, DistrictFilterComboBox } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useBannersBulkDelete, usePublishedBanners } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { BannerStaticFilters } from './helpers';
import { ManageBannerDialog } from './ManageBannerDialog';
import { usePublishedBannerColumns } from './usePublishedBannerColumns';
import type { TPublishedBanner } from '~/services';

export const PublishedBanners: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const [selectedBanner, setSelectedBanner] = useState<TPublishedBanner | null>(null);

  const { query: selectedDistrict, onQueryChange, onClearQuery } = useQueryParameter({ queryName: 'district' });

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.DELETE_BANNER);
  const { openModal: openBulkDeleteModal, closeModal: closeBulkDeleteModal } = useModal(ModalType.BULK_DELETE_BANNERS);
  const { openModal: openEditModal } = useModal(ModalType.MANAGE_BANNER);
  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data, isFetching } = usePublishedBanners({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    type: searchParameters.get('type'),
  });
  const { mutate: deleteBanners, isPending: isDeletePending } = useBannersBulkDelete();

  const publishedBanners = useMemo(() => data?.results ?? [], [data?.results]);

  const editBannerClickHandler = useCallback((banner: TPublishedBanner) => {
    setSelectedBanner(banner);
    openEditModal();
  }, [openEditModal]);

  const deleteBannerClickHandler = useCallback((banner: TPublishedBanner) => {
    setSelectedBanner(banner);
    openDeleteModal();
  }, [openDeleteModal]);

  const columns = usePublishedBannerColumns({
    onEdit: editBannerClickHandler,
    onDelete: deleteBannerClickHandler,
  });

  const { table } = useDataTable({
    columns,
    data: publishedBanners,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
        right: ['actions'],
      },
      columnVisibility: {
        district: false,
        roles: false,
        groups: false,
        is_view_more: false,
      },
    },
    getRowId: (originalRow) => originalRow.id,
  });

  const districtChangeHandler = useCallback(
    (districtId: string | null) => {
      if (districtId === null) {
        onClearQuery();
      } else {
        onQueryChange(districtId);
      }
    },
    [onQueryChange, onClearQuery],
  );

  const deleteBannersConfirmHandler = useCallback(() => {
    if (!selectedBanner) {
      return showErrorToast('Banner not found', 'Please select a banner to delete');
    }
    deleteBanners({ ids: [selectedBanner.id] }, {
      onSuccess: () => {
        closeDeleteModal();
        setSelectedBanner(null);
      },
    });
  }, [selectedBanner, deleteBanners, closeDeleteModal]);

  const deleteSelectedBannersClickHandler = useCallback(() => {
    openBulkDeleteModal();
  }, [openBulkDeleteModal]);

  const deleteSelectedBannersConfirmHandler = useCallback(() => {
    if (!table || table.getSelectedRowModel().rows.length === 0) {
      return showErrorToast('Banners not found', 'Please select banners to delete');
    }
    const selectedRows = table.getSelectedRowModel().rows;
    const ids = selectedRows.map((row) => row.original.id);
    deleteBanners({ ids }, {
      onSuccess: () => {
        closeBulkDeleteModal();
        setSelectedBanner(null);
      },
    });
  }, [table, deleteBanners, closeBulkDeleteModal]);

  const closeEditModalHandler = useCallback(() => {
    setSelectedBanner(null);
  }, []);

  return (
    <DataTable table={table} loading={isFetching}>
      <div className="flex w-full items-center justify-between gap-4 p-4 pt-6">
        <div className="flex items-center gap-4">
          <DistrictFilterComboBox
            value={selectedDistrict}
            status={DISTRICT_STATUS.PUBLISHED}
            align="start"
            className="w-fit min-w-40 max-w-72 gap-8"
            contentClassName="min-w-80"
            onChange={districtChangeHandler}
            onClear={onClearQuery}
          />
          <AppFilters config={convertToFilterConfig(BannerStaticFilters, { snakeCaseValue: false })} />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
        </div>
        <div className="flex items-center gap-4">
          {table.getSelectedRowModel().rows.length > 0 && (
            <Button type="button" variant="destructive_secondary" onClick={deleteSelectedBannersClickHandler}>
              Delete banner(s)
            </Button>
          )}
          <DataTableViewOptions table={table} />
        </div>
      </div>
      <CallToActionModal
        modalName={ModalType.DELETE_BANNER}
        modalTitle="Delete Banner"
        modalDescription="By deleting this banner, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this banner?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteBannersConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_DELETE_BANNERS}
        modalTitle="Delete all selected banner(s)"
        modalDescription="By deleting selected banner(s), it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete all selected banner(s)?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSelectedBannersConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
      <ManageBannerDialog banner={selectedBanner} onClose={closeEditModalHandler} />
    </DataTable>
  );
};
