export const PERMISSION_GROUP_PARAMETERS_KEY = {
  groupId: 'groupId',
} as const;

export const PAGE_MEASUREMENTS = {
  HEADER: 61,
  CONTAINER_PADDING: 64,
  BORDER_HEIGHT: 1,
  PROGRESS_BAR_HEIGHT: 76,
  CONTENT_PADDING: 32,
  FOOTER_HEIGHT: 73,
} as const;

export const offsetHeight = PAGE_MEASUREMENTS.HEADER + PAGE_MEASUREMENTS.BORDER_HEIGHT + PAGE_MEASUREMENTS.PROGRESS_BAR_HEIGHT + PAGE_MEASUREMENTS.CONTAINER_PADDING + PAGE_MEASUREMENTS.FOOTER_HEIGHT + PAGE_MEASUREMENTS.CONTENT_PADDING;
