import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryParameter } from '@purple/hooks';
import { Form } from '@purple/ui';
import { VisualizerStep } from '~/constants';
import { useUnsavedChanges } from '~/providers';
import { useAbsencesThresholds, useUpdateAbsencesThresholds } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { AbsencesVisualizerSkeleton } from './AbsencesVisualizerSkeleton';
import { AbsencesVisualizerThresholdItem } from './AbsencesVisualizerThresholdItem';
import { absenceThresholdSchema } from './schema';
import type { z } from 'zod';
import type { TVisualizerStep } from '~/constants';

type TAbsencesVisualizerProps = {
  formId: string;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: () => void;
};

export const AbsencesVisualizer: React.FC<TAbsencesVisualizerProps> = (props) => {
  const { formId, onSubmit, onSubmitSuccess, onSubmitError } = props;

  const { query: districtId } = useQueryParameter<string>({ queryName: 'districtId' });
  const { query: stepQuery } = useQueryParameter<TVisualizerStep>({ queryName: 'step' });

  const { checkUnsaved, setShouldShowUnsaved } = useUnsavedChanges();

  const { data: absenceThresholds, isFetching } = useAbsencesThresholds({
    districtId,
    enabled: stepQuery === VisualizerStep.ABSENCES_VISUALIZER && !!districtId,
  });
  const { mutate: updateThresholds } = useUpdateAbsencesThresholds();

  const defaultValues: z.infer<typeof absenceThresholdSchema> = useMemo(
    () => ({
      thresholds: absenceThresholds?.absence_thresholds ?? [],
    }),
    [absenceThresholds],
  );

  const form = useForm<z.infer<typeof absenceThresholdSchema>>({
    resolver: zodResolver(absenceThresholdSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { fields } = useFieldArray({
    control: form.control,
    name: 'thresholds',
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const saveDetailsClickHandler = (formData: z.infer<typeof absenceThresholdSchema>) => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    onSubmit?.();
    updateThresholds(
      {
        district_id: districtId,
        absence_thresholds: formData.thresholds,
      },
      {
        onSuccess: onSubmitSuccess,
        onError: onSubmitError,
      },
    );
  };

  const saveItemHandler = () => {
    if (!districtId) {
      return showErrorToast('District ID is missing', 'Please try again later');
    }
    updateThresholds({
      district_id: districtId,
      absence_thresholds: form.getValues('thresholds'),
      shouldRevalidate: false,
    }, {
      onSuccess: () => {
        setShouldShowUnsaved(false);
      },
    });
  };

  if (isFetching) return <AbsencesVisualizerSkeleton />;

  return (
    <div className="flex w-full flex-col">
      <div className="grid h-12 w-full grid-cols-12 items-center border-b border-grey-300 p-3">
        <strong className="col-span-6 w-full text-xs font-semibold uppercase text-grey-600">
          Month
        </strong>
        <strong className="col-span-5 w-full place-self-center text-center text-xs font-semibold uppercase text-grey-600">
          Absence Threshold Value
        </strong>
      </div>
      <Form
        providerProps={form}
        id={formId}
        className="flex w-full flex-col"
        onSubmit={form.handleSubmit((data) => checkUnsaved(() => saveDetailsClickHandler(data)))}
        onKeyDown={(evt) => evt.key === 'Enter' && evt.preventDefault()}
      >
        {fields.map((field, index) => (
          <AbsencesVisualizerThresholdItem key={field.id} fieldIndex={index} onItemSave={saveItemHandler} />
        ))}
      </Form>
    </div>
  );
};
