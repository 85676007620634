import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { BannerStatus } from '@purple/shared-types';
import { AppTabs, Button, Heading, Text } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { DraftedBanners } from './DraftedBanners';
import { PublishedBanners } from './PublishedBanners';
import type { TBannerStatus } from '@purple/shared-types';
import type { TAppTab } from '@purple/ui';

export const BannersPage: React.FC = () => {
  const bannersTabs: TAppTab<TBannerStatus>[] = useMemo(
    () => [
      {
        label: 'Banner Notifications',
        value: BannerStatus.PUBLISHED,
        content: <PublishedBanners />,
        permissions: [],
      },
      {
        label: 'Drafts',
        value: BannerStatus.DRAFT,
        content: <DraftedBanners />,
        permissions: [],
      },
    ],
    [],
  );

  const { isReady, activeTab, tabChangeHandler } = useTabs<TBannerStatus>({
    tabs: bannersTabs,
    defaultTab: BannerStatus.PUBLISHED,
  });

  const { toggleModal: toggleCreateBannerModal } = useModal(ModalType.MANAGE_BANNER);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex flex-col gap-1">
          <Heading tag="h1" variant="size-22" type="heading-600" className="text-grey-title">
            Banner Notifications
          </Heading>
          <Text type="body-400" variant="size-14" className="text-grey-800">
            Notifications for your PurpleSENSE users in banner format
          </Text>
        </div>
        <Button type="button" variant="primary" onClick={() => toggleCreateBannerModal(true)}>
          Create New
        </Button>
      </div>
      <AppTabs tabs={bannersTabs} listClassName="pl-2" value={activeTab} isReady={isReady} onValueChange={tabChangeHandler} />
    </div>
  );
};
