import { useQueryParameter } from '@purple/hooks';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { SisDataQueryName, SisDataViewType } from './constants';
import { SisDataDetails } from './SisDataDetails';
import { SisDataList } from './SisDataList';
import type { ValueOf } from '@purple/shared-types';

export const SisDataTab = () => {
  const { query: viewQuery } = useQueryParameter<ValueOf<typeof SisDataViewType>>({
    queryName: SisDataQueryName.VIEW,
    defaultValue: SisDataViewType.LIST,
  });

  const { query: mappingId } = useQueryParameter({
    queryName: SisDataQueryName.MAPPING_ID,
  });

  return (
    <DistrictTabContainer title="SIS Data">
      {viewQuery === SisDataViewType.DETAILS && mappingId !== undefined && <SisDataDetails /> }
      {viewQuery === SisDataViewType.LIST && <SisDataList />}
    </DistrictTabContainer>
  );
};
