import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { PurpleIcon } from '@purple/icons';
import { TAGS_TYPES } from '@purple/shared-types';
import { invertColor } from '@purple/shared-utils';
import { Button, NumberBadge, Skeleton, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { CallToActionModal, CreateTagModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { ACTIONS_QUERY_KEYS, useActionType, useDeleteTag } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ActionsTabContainer } from '../../components';
import type { TCustomTag } from '@purple/shared-types';

export const CustomTags = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();

  const { openModal: openCreateTagModal } = useModal(ModalType.CREATE_CUSTOM_TAG);
  const { openModal: openDeleteTagModal, closeModal: closeDeleteTagModal } = useModal(ModalType.DELETE_CUSTOM_TAG);

  const [selectedTag, setSelectedTag] = useState<TCustomTag | null>(null);

  const { data, isLoading } = useActionType({ id: id!, queryOptions: { enabled: !!id } });
  const { mutate: deleteTag, isPending } = useDeleteTag();

  const deleteTagModalOpenHandler = useCallback((tag: TCustomTag) => {
    setSelectedTag(tag);
    openDeleteTagModal();
  }, [openDeleteTagModal]);

  const closeDeleteTagModalHandler = useCallback(() => {
    setSelectedTag(null);
    closeDeleteTagModal();
  }, [closeDeleteTagModal]);

  const deleteTagHandler = useCallback(() => {
    if (!selectedTag) {
      return showErrorToast('System message', 'Unable to delete tag with provided id');
    }
    deleteTag(selectedTag.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [ACTIONS_QUERY_KEYS.GET_ACTION_TYPE, id] });
        showSuccessToast('System message', 'Custom Tag deleted successfully');
        closeDeleteTagModalHandler();
      },
    });
  }, [closeDeleteTagModalHandler, deleteTag, id, queryClient, selectedTag]);

  return (
    <>
      <ActionsTabContainer
        title="Custom Tags"
      >
        {isLoading && !data && (
          <div className="flex flex-wrap items-center gap-2">
            {Array.from({ length: 10 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton key={index} className="h-7 w-1/5" />
            ))}
          </div>
        )}
        {data && !isLoading && (
          <div className="flex flex-wrap items-center gap-2">
            {data?.tags.map((tag) => (
              <NumberBadge key={tag.id} style={{ background: tag.color, color: invertColor(tag.color, true) }} className="flex max-w-[300px] items-center gap-2 rounded-full shadow-md">
                <span className="line-clamp-1">{tag.name}</span>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button variant="tertiary" size="small" className="h-auto rounded border-none p-0 hover:bg-transparent active:bg-transparent" onClick={() => deleteTagModalOpenHandler(tag)}>
                      <PurpleIcon name="X" className="size-4 shrink-0 text-grey-title" style={{ color: invertColor(tag.color, true) }} />
                    </Button>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent>
                      Remove Tag
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </NumberBadge>
            ))}
            <Button variant="tertiary" size="small" iconLeft={<PurpleIcon name="plus" className="size-4" />} onClick={openCreateTagModal} className="w-fit">
              Create Custom Tag
            </Button>
          </div>
        )}
      </ActionsTabContainer>
      <CreateTagModal tagsType={TAGS_TYPES.RECORD_ACTION_TYPE} relatedEntityId={id as string} relatedDistrictId={data?.district.id as number} />
      <CallToActionModal
        modalName={ModalType.DELETE_CUSTOM_TAG}
        modalTitle="Delete Custom Tag"
        modalDescription="Modal for deleting custom tags"
        showModalDescription={false}
        modalTextContent={`You are about to delete the custom tag "${selectedTag?.name}". Are you sure you want to proceed?`}
        onPrimaryButtonClick={deleteTagHandler}
        primaryButtonText="Yes, delete"
        primaryButtonVariant="destructive_primary"
        isLoading={isPending}
        secondaryButtonText="Cancel"
        onSecondaryButtonClick={closeDeleteTagModalHandler}
      />
    </>
  );
};
