import { useMutation } from '@tanstack/react-query';
import { showErrorToast } from '~/utils/toasts';
import { deleteMappingFile } from './delete-mapping-file';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import type { AxiosError, AxiosResponse } from 'axios';

export const useDeleteMappingFile = () => {
  return useMutation<AxiosResponse, AxiosError, { districtId: string; mappingId: string }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.DELETE_MAPPING_FILE],
    mutationFn: deleteMappingFile,
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to delete mapping file');
      }
    },
  });
};
