import axiosInstance from '../axios-config';
import { SCHOOLS_ENDPOINTS } from '../endpoints';
import type { TSchoolHierarchyChoiceList, TSchoolHierarchyChoiceParameters } from './schools.types';

export const getSchoolsHierarchyChoices = async ({ schoolId, ...parameters }: TSchoolHierarchyChoiceParameters) => {
  const response = await axiosInstance.get<TSchoolHierarchyChoiceList>(SCHOOLS_ENDPOINTS.HIERARCHY_CHOICES(schoolId), { params: {
    node_types: parameters.node_types?.join(','),
  } });
  return response.data;
};
