import type { ValueOf } from '../utils';

export const BannerStatus = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
} as const;
export type TBannerStatus = ValueOf<typeof BannerStatus>;

export const BannerType = {
  ERROR: 'error',
  ALL_CORRECTED: 'all_corrected',
  UPDATES: 'updates',
  INFO: 'info',
  MISCELLANEOUS: 'miscellaneous',
} as const;
export type TBannerType = ValueOf<typeof BannerType>;

export const ReadableBannerType = {
  [BannerType.ERROR]: 'Error',
  [BannerType.ALL_CORRECTED]: 'All Clear',
  [BannerType.UPDATES]: 'Updates',
  [BannerType.INFO]: 'Info',
  [BannerType.MISCELLANEOUS]: 'Miscellaneous',
} as const;

export type TBanner = {
  id: string;
  title: string;
  type: TBannerType;
  status: TBannerStatus;
  start_date_and_time: string;
  end_date_and_time: string;
  body: string;
  is_view_more: boolean;
  view_more_link: string | null;
  roles: number[];
  groups: number[];
  district: number;
  is_apply_for_all_districts: boolean;
  created_at: string;
  updated_at: string;
};
