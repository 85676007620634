import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import type React from 'react';

const UNSAVED_MESSAGE
  = 'You have unsaved changes. If you leave now, your updates will be lost. Do you want to save your changes before exiting?';

type UnsavedChangesContextType = {
  shouldShowUnsaved: boolean;
  setShouldShowUnsaved: (value: boolean) => void;
  checkUnsaved: (callback: () => void) => void;
};

const UnsavedChangesContext = createContext<UnsavedChangesContextType | undefined>(undefined);

export const UnsavedChangesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { openModal: openUnsavedChangesModal, closeModal: closeUnsavedChangesModal } = useModal(
    ModalType.UNSAVED_CHANGES,
  );

  const [shouldShowUnsaved, setShouldShowUnsaved] = useState(false);
  const [currentCallback, setCurrentCallback] = useState<(() => () => void) | null>(null);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = UNSAVED_MESSAGE;
      event.returnValue = message;
      return message;
    };

    if (shouldShowUnsaved) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldShowUnsaved]);

  const checkUnsaved = useCallback(
    (callback: () => void) => {
      if (shouldShowUnsaved) {
        setCurrentCallback(() => callback);
        openUnsavedChangesModal();
      } else {
        callback();
        setCurrentCallback(null);
      }
    },
    [openUnsavedChangesModal, shouldShowUnsaved],
  );

  const continueEditingClickHandler = useCallback(() => {
    closeUnsavedChangesModal();
  }, [closeUnsavedChangesModal]);

  const leaveAnywayClickHandler = useCallback(() => {
    setShouldShowUnsaved(false);
    closeUnsavedChangesModal();
    if (currentCallback) {
      currentCallback();
    }
  }, [closeUnsavedChangesModal, currentCallback]);

  const values = useMemo(
    () => ({ shouldShowUnsaved, setShouldShowUnsaved, checkUnsaved }),
    [checkUnsaved, shouldShowUnsaved],
  );

  return (
    <UnsavedChangesContext.Provider value={values}>
      <CallToActionModal
        modalName={ModalType.UNSAVED_CHANGES}
        modalTitle="Unsaved Changes Detected"
        modalDescription={UNSAVED_MESSAGE}
        modalTextContent={UNSAVED_MESSAGE}
        primaryButtonText="Continue Editing"
        secondaryButtonText="Leave Anyway"
        primaryButtonVariant="primary"
        onPrimaryButtonClick={continueEditingClickHandler}
        onSecondaryButtonClick={leaveAnywayClickHandler}
      />
      {children}
    </UnsavedChangesContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);
  if (!context) {
    throw new Error('useUnsavedChanges must be used within an UnsavedChangesProvider');
  }
  return context;
};
