import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { DISTRICTS_QUERY_KEYS } from './districts.const';
import { updateDistrictSafProcessOwner } from './update-district-saf-process-owner';
import type { AxiosError } from '@purple/shared-services';
import type { TUpdateDistrictSafProcessOwnerPayload } from './districts.types';

export const useUpdateDistrictSafProcessOwner = () => {
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, {
    id: string;
    payload: TUpdateDistrictSafProcessOwnerPayload;
  }>({
    mutationKey: [DISTRICTS_QUERY_KEYS.UPDATE_STATUS],
    mutationFn: updateDistrictSafProcessOwner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [DISTRICTS_QUERY_KEYS.GET_SAF_PROCESS_OWNER] });
      showSuccessToast('System message', 'District saf owner was updated successfully');
    },
    onError: (error) => {
      if (error.response && error.response.status < 500) {
        showErrorToast('System message', 'Failed to update district saf owner. Please try again later');
      }
    },
  });
};
